<template>
  <section class="forms">
    <cb-admin-view-title v-if="dataLoaded" :title="$t('formsBuilder.formsAndContent.title')" class="cb-admin-view-title">
      <b-button
          class="forms-route-add-form"
          variant="green"
          @click="gotoFormsAdd"
      >{{ $t('formsBuilder.formsAndContent.addButton') }}
      </b-button>
    </cb-admin-view-title>
    <div class="no-data-container" v-if="noData && dataLoaded">
      <h2>{{ $t('formsBuilder.formsAndContent.noContent.title') }}</h2>
      <div class="clipboard-pencil-icon"></div>
      <p class="no-data-message">{{ $t('formsBuilder.formsAndContent.noContent.message') }}</p>
      <button
          class="cb-btn secondary forms-route-add-form"
          :class="{'mx-auto': !forms.length }"
          @click="gotoFormsAdd"
          :title="$t('formsBuilder.formsAndContent.addButton')"
      >{{ $t('formsBuilder.formsAndContent.addButton') }}
      </button>
    </div>
    <cb-view-section type="secondary" class="pt-0" v-else-if="dataLoaded" :includePrimaryMessages="!modalShow">
      <div class="toolbar">
        <div class="filter-group">
          <div class="search-item">
              <b-form-input
                  class="search-input small-input"
                  :placeholder='$t("formsBuilder.formsAndContent.searchPlaceholder")'
                  v-model="searchTerm"
                  @update="searchAsync"
                  :debounce="500"
              ></b-form-input>
          </div>
          <multi-select v-if="formsTypeFilterList.length > 1"
                        class="ml-3"
                        :options="formsTypeFilterList"
                        :value="formsTypeFilter"
                        :search-placeholder="$t('common.filterSearchPlaceholder', [$t('formsBuilder.formsAndContent.columnHeaders.type').toLowerCase()])"
                        :close-on-select="false"
                        :title="$t('formsBuilder.formsAndContent.columnHeaders.type')"
                        @select="selectTypeFilter($event)"
          >
            <template v-slot:option="{ option, isSelected }">
              <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
              </b-form-checkbox>
              <span>{{ option }}</span>
            </template>
          </multi-select>
          <multi-select v-if="formsStatusFilterList.length > 1"
                        class="ml-3"
                        :options="formsStatusFilterList"
                        :value="formsStatusFilter"
                        :search-placeholder="$t('common.filterSearchPlaceholder', [$t('formsBuilder.formsAndContent.columnHeaders.status').toLowerCase()])"
                        :close-on-select="false"
                        :title="$t('formsBuilder.formsAndContent.columnHeaders.status')"
                        @select="selectStatusFilter($event)"
          >
            <template v-slot:option="{ option, isSelected }">
              <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
              </b-form-checkbox>
              <span>{{ option }}</span>
            </template>
          </multi-select>
        </div>
        <b-button class="small-button ml-3 clear-filters-forms"
                  variant="outline-green"
                  @click="clearFilters"
        >
          {{ $t('common.clearFilters') }}
        </b-button>
      </div>

      <b-table class="new-table"
               v-bind="tableSettings"
               :per-page="itemsPerOnePage"
               :current-page="currentPage"
               sort-by="formattedIdentifier"
               :items="filteredForms"
               :sort-compare="sortCompare"
               :fields="[
        { key: 'formattedIdentifier', label: $t('formsBuilder.formsAndContent.columnHeaders.identifier'), thAttr: { 'aria-sort': 'descending' }, sortable: true, sortDirection: 'desc' },
        { key: 'type', sortByFormatted: true, formatter: getFormType, label: $t('formsBuilder.formsAndContent.columnHeaders.type'), thStyle: { minWidth: '5rem' }, class: 'hide-sm-down', sortable: true },
        { key: 'status', label: $t('formsBuilder.formsAndContent.columnHeaders.status'), class: 'hide-sm-down', sortable: true },
        { key: 'updateDate', label: $t('formsBuilder.formsAndContent.columnHeaders.modified'), class: ['date-column', 'hide-sm-down'], sortable: true },
        { key: 'actions', label: '', class: 'action-menu-column', thAttr: { 'aria-label': $t('formsBuilder.formsAndContent.columnHeaders.actionsScreenReader') } }
      ]">
        <template v-slot:cell(status)="{ item }">
          <span style="white-space: nowrap;">
            <svg aria-hidden="true" width="14" height="14">
              <use :xlink:href="getFormStatusIconPath(item)"/>
            </svg> {{ item.revisionStatus.label }}
          </span>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <action-menu :toggle-orientation="'landscape'" :menu-position="'bottom-left'" :items="buildActionMenu(item)"/>
        </template>
      </b-table>
      <div v-if="showPagination" class="pagination-container">
        <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm" class="per-page-options">
            <b-form-select
                id="per-page-select"
                v-model="itemsPerOnePage"
                :options="paginationSettings.pageOptions"
                size="sm">
            </b-form-select>
        </b-form-group>
        <b-pagination
            v-model="currentPage"
            :total-rows="formsCount"
            :per-page="itemsPerOnePage"
            :current-page="currentPage"
            v-bind="paginationSettings">
        </b-pagination>
      </div>
    </cb-view-section>
    <form-rename
        @ok="handleRenameComplete"
        @close="modalShow = false"
        @cancel="modalShow = false"
        @hide="modalShow = false"
        :dataUrl="renameDataUrl"
        v-if="modalShow"
    />
  </section>
</template>
<script>
import {IconProvider} from "common/icon.module";
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import CbViewSection from "general/cb-view-section.vue";
import AppSettings from "appSettings";
import ArchiveSmallIcon from "forms/archive-small.svg"
import RenameIcon from '@clickboarding/assets/icons/nova/line/files-basic/file-edit-2.svg';
import {mapActions, mapGetters} from 'vuex';
import ActionMenu from 'common/components/action-menu.vue';
import FormRename from "forms/form-rename.vue";
import MultiSelect from 'common/components/multi-select.vue'
import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../constants/defaultTableSettings";

export default {
  name: "forms-route",
  components: {
    CbAdminViewTitle,
    CbViewSection,
    ActionMenu,
    FormRename,
    MultiSelect
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('forms', ['persistedSearchTerm']),
    filteredForms() {
      return this.forms.filter(form => {
        const formsTypeFilter = this.formsTypeFilter.length ? this.formsTypeFilter.indexOf(form.typeEnum.label) > -1 : true
        const formsStatusFilter = this.formsStatusFilter.length ? this.formsStatusFilter.indexOf(form.revisionStatus.statusEnum) > -1 : true
        return formsTypeFilter && formsStatusFilter
      })
    },
    formsCount() {
      return this.forms && this.filteredForms.length
    },
    showPagination() {
      return this.formsCount > PAGINATION_SETTINGS.pageOptions[0]
    }
  },
  watch: {
    dataUrl: {
      immediate: true,
      async handler(newDataUrl, oldDataUrl) {
        if (newDataUrl && newDataUrl !== oldDataUrl) {
          this.searchTerm = this.persistedSearchTerm
          this.formsUrl = newDataUrl
          await this.loadPageDataAsync(newDataUrl)
          this.dataLoaded = true
          this.noData = this.forms.length === 0 && !this.searchTerm
        }
      }
    }
  },
  methods: {
    ...mapActions('forms', ['persistSearchTerm']),
    async clearFilters () {
      this.formsTypeFilter = []
      this.formsStatusFilter = []
      this.currentPage = 1
      await this.searchAsync('')
      },
    sortCompare(a, b, key, sortDesc) {
      if (key === 'updateDate') {
        // Assuming the date field is a `Date` object, subtraction
        // works on the date serial number (epoch value)
        var d1 = this.dateFromString(a.updateDate);
        var d2 = this.dateFromString(b.updateDate);
              
        var diff = d1.getTime() - d2.getTime();
              
        return diff;
      }
      else if (key === 'status') {
        var v1 = a.revisionStatus.label;
        var v2 = b.revisionStatus.label;
              
        if (v1 < v2) return -1;
        if (v1 > v2) return 1;

        return 0;
      }
      else {
        // Let b-table handle sorting other fields (other than `updateDate` and `status` fields)
        return false;
      }
    },
    dateFromString(stringValue) {
      var stringSplitValues = stringValue.split("/");

      var month = +stringSplitValues[0];
      var day = +stringSplitValues[1];
      var year = +stringSplitValues[2];

      var date = new Date(year, month - 1, day, 0, 0, 0);

      return date;
    },
    async handleRenameComplete() {
      await this.loadPageDataAsync(this.formsUrl)
      this.modalShow = false
    },
    urlWithSearchTerm(url) {
      return url + (this.searchTerm ? '&searchTerm=' + encodeURIComponent(this.searchTerm) : '')
    },
    async searchAsync(searchTerm) {
      this.searchTerm = searchTerm
      if (searchTerm) {
        let response = await this.$http.get(this.urlWithSearchTerm(this.searchUrl))
        this.updateForms(response.data.forms)
      } else {
        await this.loadPageDataAsync(this.formsUrl)
      }
      this.persistSearchTerm(searchTerm)
      this.resetCurrentPage()
    },
    gotoFormsAdd() {
      this.$_cb.router.changeView(AppSettings.viewFormsAdd, this.addButtonUrl)
    },
    getFormType(value, key, item) {
      return item.typeEnum.label
    },
    getFormStatusIconPath(item) {
      let status = item.revisionStatus.statusEnum

      return status === "Published" ? IconProvider.getPath("Form-Publish-Icon")
          : status === "Draft" ? IconProvider.getPath("Form-Edit-Icon")
              : null
    },
    async loadPageDataAsync(url) {
      let response = await this.$http.get(this.urlWithSearchTerm(url))
      if (response.data.searchTermIgnored) {
        this.searchTerm = null
        this.persistSearchTerm(null)
      }

      this.addButtonUrl = response.data.add.url
      this.searchUrl = response.data.search.url

      this.buildFilterLists(response.data.forms || [])
      this.updateForms(response.data.forms)
    },
    updateForms(newForms) {
      this.forms = newForms || []
    },
    buildFilterLists(forms) {
      let types = new Set()
      let statuses = new Set()
      forms.forEach(form => {
        statuses.add(form.revisionStatus.statusEnum)
        types.add(form.typeEnum.label)
      })

      this.formsStatusFilterList = [...statuses].sort()
      this.formsTypeFilterList = [...types].sort()
    },
    selectStatusFilter(item) {
      this.currentPage = 1

      const itemIndex = this.formsStatusFilter.indexOf(item)
      if (itemIndex > -1) {
        this.formsStatusFilter.splice(itemIndex, 1)
      } else {
        this.formsStatusFilter.push(item)
      }

      sessionStorage.formsStatusFilter = JSON.stringify(this.formsStatusFilter)
    },
    selectTypeFilter(item) {
      this.currentPage = 1

      const itemIndex = this.formsTypeFilter.indexOf(item)
      if (itemIndex > -1) {
        this.formsTypeFilter.splice(itemIndex, 1)
      } else {
        this.formsTypeFilter.push(item)
      }

      sessionStorage.formsTypeFilter = JSON.stringify(this.formsTypeFilter)
    },
    resetCurrentPage () {
      this.currentPage = 1
    },
    buildActionMenu(item) {
      item.actions.forEach(action => {
        if (action.typeEnum === "Edit") {
          action.icon = "Action-Edit-Icon"
          action.action = "changeRoute"
          action.route = AppSettings.viewFormsEdit
          action.routeParams = {type: item.typeEnum.value}
          action.label = this.$t('formsBuilder.formsAndContent.actionMenuItems.edit')
        } else if (action.typeEnum === "View") {
          action.icon = "Action-View-Icon"
          action.action = "changeRoute"
          action.route = AppSettings.viewFormsView
          action.routeParams = {type: item.typeEnum.value}
          action.label = this.$t('formsBuilder.formsAndContent.actionMenuItems.view')
        } else if (action.typeEnum === "Copy") {
          action.icon = "Action-Copy-Icon"
          action.action = "changeRoute"
          action.route = AppSettings.viewFormsEdit
          action.routeParams = {type: item.typeEnum.value}
          action.label = this.$t('formsBuilder.formsAndContent.actionMenuItems.copy')
        } else if (action.typeEnum === "Publish") {
          action.icon = "Action-Publish-Icon"
          action.action = async () => {
            await this.$http.put(action.url)
            await this.loadPageDataAsync(this.formsUrl)
          }
          action.label = this.$t('formsBuilder.formsAndContent.actionMenuItems.publish')
        } else if (action.typeEnum === "Delete") {
          action.icon = "Action-Delete-Icon"
          action.action = async () => {
            await this.$http.delete(action.url)
            await this.loadPageDataAsync(this.formsUrl)
          }
          action.label = this.$t('formsBuilder.formsAndContent.actionMenuItems.delete')
        } else if (action.typeEnum === "Archive") {
          action.icon = ArchiveSmallIcon.id
          action.action = async () => {
            await this.$http.put(action.url)
            await this.loadPageDataAsync(this.formsUrl)
          }
          action.label = this.$t('formsBuilder.formsAndContent.actionMenuItems.archive')
        } else if (action.typeEnum === "Rename") {
          action.icon = RenameIcon.id
          action.action = () => {
            this.renameDataUrl = action.url
            this.modalShow = true
          }
          action.label = this.$t('formsBuilder.formsAndContent.actionMenuItems.rename')
        }
      })
      return item.actions
    }
  },
  created() {
    this.formsTypeFilter = sessionStorage.formsTypeFilter ? JSON.parse(sessionStorage.formsTypeFilter) : []
    this.formsStatusFilter = sessionStorage.formsStatusFilter ? JSON.parse(sessionStorage.formsStatusFilter) : []
  },
  data() {
    return {
      forms: null,
      addButtonUrl: null,
      formsUrl: null,
      searchUrl: null,
      dataLoaded: false,
      noData: true,
      searchTerm: null,
      modalShow: false,
      formsTypeFilter: [],
      formsStatusFilter: [],
      formsStatusFilterList: [],
      formsTypeFilterList: [],
      renameDataUrl: "",
      tableSettings: TABLE_SETTINGS,
      paginationSettings: PAGINATION_SETTINGS,
      currentPage: 1,
      itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0]
    }
  }
}
</script>
<style scoped lang="scss">
@import "@clickboarding/style/mixins";
.toolbar {
  display: flex;
  align-items: baseline;
  margin-bottom: 1rem;
}

.no-data-container {
  text-align: center !important;
  @include cb-view-padding;
}

.no-data-message {
  width: 16.25rem;
  margin: 0 auto 2rem;
  line-height: 1.25rem;
}

.search-box {
  max-width: 225px;
}

.forms-route-add-form {
  margin-left: auto;
}

/deep/ .form-data-grid-container > table {
  table-layout: auto;

  td:nth-of-type(1) {
    word-break: break-all;
  }

  td:not(:nth-of-type(1)) {
    word-break: keep-all;
    white-space: nowrap;
  }

  th {
    word-break: keep-all;
    white-space: nowrap;
  }
}
</style>
