import * as VideoMapper from 'forms/element-list-item-mappers/video';
import * as HtmlMapper from 'forms/element-list-item-mappers/html';
import * as ImageMapper from 'forms/element-list-item-mappers/image';
import * as GroupMapper from 'forms/element-list-item-mappers/group';
import * as GroupArrayMapper from 'forms/element-list-item-mappers/group-array';
import * as DataEntryMapper from 'forms/element-list-item-mappers/basic-data-entry';
import * as UnsupportedMapper from 'forms/element-list-item-mappers/unsupported';

/*

These mappers configure how an existing element is rendered in the list of existing 
elements when adding/editing a form via the forms-builder.

Expected implementation of a an element-list-item-mapper:

{
  label: "", // String: The text that will be displayed for the element
  actions: [], // Array: The actions that will be sent to the action menu component
  icon: "" // String: The icon path that will be used to represent the element in the list (should typically be a valid href pointing to the icon sprite)
}

*/

const mappers = [
  VideoMapper,
  HtmlMapper,
  ImageMapper,
  GroupMapper,
  GroupArrayMapper,
  DataEntryMapper,
];

function map (element, index, editElementHandler, deleteElementHandler) {
  let match = null;

  for (let mapper of mappers) {
    if (mapper.canHandle(element)) {
      match = mapper;
      break;
    }
  }

  return {
    // map with the matched mapper or with the unsupported mapper if no match was found
    ...(match || UnsupportedMapper).map(element, index, editElementHandler, deleteElementHandler),
    element
  };
}

export { map };