<template>
  <section>
    <cb-admin-view-title :title="$t('locations.title')" class="cb-admin-view-title">
      <b-button v-if="showAddBtn"
                class="create-location-button"
                variant="green"
                @click="addEditLocation(undefined)"
      >{{ $t("locations.createLocation") }}
      </b-button>
    </cb-admin-view-title>
    <cb-view-section
        v-if="data"
        type="secondary"
        class="pt-0"
        :includePrimaryMessages="false"
    >
      <div class="toolbar" v-if="filters.length > 0">
        <div class="filter-group">
          <div class="search-item mr-3">
            <b-form-input
                v-if="searchFilterKey && selectedFilter[searchFilterKey]"
                class="search-input small-input"
                v-model="selectedFilter[searchFilterKey][0].value"
                :debounce="500"
                @update="searchHandler"
                :placeholder='$t("locations.filter.placeholder.search")'
            ></b-form-input>
        </div>
        <template v-for="filter in filters">
          <multi-select v-if="filter.items.length > 1"
                    class="mr-3"
                    :options="filter.items"
                    :value="selectedFilter[filter.filterKey]"
                    :search-placeholder="$t('common.filterSearchPlaceholder', [filter.title.toLowerCase()])"
                    :close-on-select="false"
                    track-by="value"
                    search-by="label"
                    :title="filter.title"
                    :key="filter.filterKey"
                    @select="selectFilter(filter.filterKey, $event, true)"
          >
            <template v-slot:option="{ option, isSelected }">
              <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
              </b-form-checkbox>
              <span v-text-middle-ellipsis="option.label"></span>
            </template>
          </multi-select>
        </template>
      </div>
      <b-button class="small-button clear-filters-locations"
                variant="outline-green"
                @click="clearFilters"
      >
        {{ $t('common.clearFilters') }}
      </b-button>
    </div>
    <div v-if="error">
      <p class="mt-3">{{ error }}</p>
    </div>
    <div v-else>
      <b-table v-if="listLocations.locations"
               class="locations-list-container new-table new-table--without-first-cell-border"
               v-bind="tableSettings"
               :items="listLocations.locations"
                no-local-sorting
                @sort-changed="sortLocations"
               sort-by="name"
               :fields="[
      { key: 'select', label: '', thStyle: { width: '50px' }, sortable: false },
      { key: 'name', label: data.nameHeader, thAttr: { 'aria-sort': 'descending' }, thStyle: { maxWidth: '250px' },
        sortable: true },
      { key: 'identifier', label: data.idHeader, sortable: true },
      { key: 'address.city', label: data.cityHeader, sortable: true },
      { key: 'address.stateProvinceRegion', label: data.stateHeader, thStyle: { minWidth: '15%' }, sortable: true },
      { key: 'view', label: '', class: 'link-column' }
    ]">
          <template v-slot:cell(select)="{ item }">
            <b-form-checkbox class="location-checkbox"
                             :checked="isLocationSelected(item.id)"
                             @change="selectLocation(item.id)">
            </b-form-checkbox>
          </template>
          <template v-slot:cell(name)="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:cell(view)="{ item }">
            <svg class="edit-icon" @click="addEditLocation(item)">
              <use
                  v-bind="{ 'xlink:href': getIconPath('Action-Edit-Icon') }"
              ></use>
            </svg>
          </template>
        </b-table>
        <p class="mt-3" v-else>
          {{ noLocationsText }}
        </p>
        <div v-if="showPagination" class="pagination-container">
          <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm"
                        class="per-page-options">
            <b-form-select
                id="per-page-select"
                :value="pagination.itemsPerOnePage"
                :options="paginationSettings.pageOptions"
                size="sm"
                @change="handlePageSizeChange">
            </b-form-select>
          </b-form-group>
          <b-pagination
              :value="pagination.currentPage"
              :total-rows="listLocations.totalCount"
              :per-page="pagination.itemsPerOnePage"
              :current-page="pagination.currentPage"
              v-bind="paginationSettings"
              @change="handlePageChange">
          </b-pagination>
        </div>
        <div>
          <b-form-checkbox
               class="inactive-toggle"
               :vslue="showInactive"
               @change="handleShowInactiveLocationsChange">
            {{ data.showInactiveLabel }}
          </b-form-checkbox>
        </div>
      </div>
      <multi-edit v-if="allSelectedLocationsIds.length > 0"
                  :batch="data.batch"
                  :selected-ids="allSelectedLocationsIds"
                  :button-titles="{
                  'Cancel': $t('candidates.multiEditCancelTitle')
                }"
                  @update="updateLocations"
      ></multi-edit>
    </cb-view-section>
    <add-location :data="data.addEdit"
                  :edit-item="editItem"
                  @ok="updateLocations"
                  @close="modalShow = false"
                  @cancel="modalShow = false"
                  @hide="modalShow = false"
                  v-if="modalShow"></add-location>
  </section>
</template>

<script>
import CbViewSection from 'general/cb-view-section.vue';
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import MultiSelect from 'common/components/multi-select.vue'
import locationsManagementService from 'api/locationsManagementService';
import {IconProvider} from 'common/icon.module';
import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../constants/defaultTableSettings";
import AddLocation from "./add-location";
import MultiEdit from 'general/multi-edit'
import ellipsisText from "../directives/text-middle-ellipsis";
import AppSettings from 'appSettings';

export default {
  name: "locations",
  components: {
    AddLocation,
    CbViewSection,
    CbAdminViewTitle,
    MultiSelect, MultiEdit
  },
  props: {
    data: Object
  },
  directives: {
    textMiddleEllipsis: {
      bind(el, {value}) {
        ellipsisText(el, {value})
      },
      update(el, {value}) {
        el.innerHTML = ''
        ellipsisText(el, {value})
      }
    }
  },
  data() {
    return {
      listLocations: {
        locations: null,
        totalCount: 0
      },
      editItem: null,
      modalShow: false,
      showInactive: false,
      error: null,
      showAddBtn: false,
      filters: [],
      selectedFilter: {},
      tableSettings: TABLE_SETTINGS,
      paginationSettings: PAGINATION_SETTINGS,
      selectedLocations: {
        1: []
      },
      sorting: {
        SortBy: undefined,
        SortDir: undefined
      },
      pagination: {
        currentPage: 1,
        itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0]
      },
      searchFilterKey: ''
    }
  },
  computed: {
    selectedLocsCount() {
      return (
        this.hasLocations &&
        this.listLocations.locations.filter((location) => location.checked).length
      );
    },
    storage() {
      return `cb${AppSettings.appType}locationFilter`
    },
    noLocationsText() {
      return this.showInactive
          ? this.data.noInactiveLabel
          : this.data.noActiveLabel;
    },
    hasLocations() {
      return !!(this.data && this.listLocations.locations && this.listLocations.locations.length);
    },
    showPagination() {
      return this.hasLocations && this.listLocations.totalCount > PAGINATION_SETTINGS.pageOptions[0];
    },
    allSelectedLocationsIds() {
      return Object.keys(this.selectedLocations).reduce((ids, key) => {
        ids = ids.concat(this.selectedLocations[key])

        return ids
      }, [])
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      async handler(data) {
        if (data) {
          if (!data.addEdit.settings.brands) {
            this.error = data.addEdit.settings.brandsNoData;
            this.showAddBtn = false;
          } else if (!data.addEdit.company.contacts) {
            this.error = data.addEdit.company.contactsNoData;
            this.showAddBtn = false;
          } else if (!data.listLocations.locations) {
            this.error = data.noDataLabel;
            this.showAddBtn = true;
          } else {
            this.error = null;
            this.showAddBtn = true;
          }

          this.listLocations = data.listLocations

          this.searchFilterKey = (data && data.searchFilterKey) || '';
          if (data && data.getFiltersUrl) {
            await this.loadFilters(data.getFiltersUrl)
          }
        }
      },
    },
  },
  methods: {
    addEditLocation(item) {
      this.modalShow = true
      this.editItem = item
    },
    async loadLocations() {
      let listLocations = await locationsManagementService.getLocations(this.data.getLocationsUrl, this.generateQuery(this.selectedFilter || []))
      this.listLocations = listLocations
    },
    generateQuery(filtersSelected) {
      if (!filtersSelected) {
        filtersSelected = this.selectedFilter
      }

      let params = {}
      Object.keys(filtersSelected).forEach(selectedFilter => {
        if (filtersSelected[selectedFilter].length) {
          params[selectedFilter] = filtersSelected[selectedFilter].map(elem => {
            return elem.value
          }).join(",")

          if (!params[selectedFilter]) {
            delete params[selectedFilter]
          }
        }
      })

      params['incudeinactive'] = this.showInactive;

      // Sorting
      if (this.sorting.SortBy) {
        params['SortBy'] = this.sorting.SortBy
        params['SortDir'] = this.sorting.SortDir
      }
      // Pagination
      params['skip'] = this.pagination.itemsPerOnePage * (this.pagination.currentPage - 1)
      params['take'] = this.pagination.itemsPerOnePage


      return params
    },
    clearFilters () {
      this.setDefaultFilters()
      this.saveFilters()
      this.resetCurrentPage()
      this.loadLocations()
    },
    searchHandler() {
      this.resetCurrentPage()
      this.saveFilters()
      this.loadLocations()
    },
    sortLocations(options) {
      this.sorting.SortBy = options.sortBy.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(".", '');//options.sortBy[0].toUpperCase() + options.SortBy.slice(1)
      this.sorting.SortDir = options.sortDesc ? 'Desc' : 'Asc'
      this.loadLocations()
    },
    handlePageChange(value) {
      this.pagination.currentPage = value
      if (!this.selectedLocations[value]) {
        this.$set(this.selectedLocations, value, [])
      }
      this.loadLocations()
    },
    handlePageSizeChange(value) {
      this.pagination.itemsPerOnePage = value
      this.resetCurrentPage()
      this.loadLocations()
    },
    handleShowInactiveLocationsChange (value) {
      this.showInactive = value
      this.resetCurrentPage()
      this.loadLocations()
    },
    getIconPath(icon) {
      return IconProvider.getPath(icon);
    },
    resetCurrentPage() {
      this.pagination.currentPage = 1
      this.selectedLocations = {1: []}
    },
    isLocationSelected(locationId) {
      return this.selectedLocations[this.pagination.currentPage].indexOf(locationId) > -1
    },
    async updateLocations (data) {
      this.resetCurrentPage()

      if (data) {
        await this.loadLocations()
      }
    },
    setDefaultFilters () {
      this.filters.forEach(filter => {
        this.$set(this.selectedFilter, filter.filterKey, [])
      })

      this.$set(this.selectedFilter, this.searchFilterKey, [{value: ''}])
    },
    async loadFilters(filtersUrl) {
      const response = await locationsManagementService.getFilters(filtersUrl)
      this.filters = response.filters
      this.setDefaultFilters()
      try {
        if (typeof (sessionStorage) !== 'undefined') {
          const savedFilter = JSON.parse(sessionStorage.getItem(this.storage) || "{}")
          this.selectedFilter = savedFilter ? Object.assign({}, this.selectedFilter, savedFilter) : this.selectedFilter
        }
      } catch (e) {
        console.log(e)
      }
    },
    saveFilters() {
      if (Modernizr && Modernizr.sessionstorage) sessionStorage.setItem(this.storage, JSON.stringify(this.selectedFilter))
    },
    selectFilter(filterKey, selectedItem, updateNow) {
      let currentFilter = this.selectedFilter[filterKey]

      const index = currentFilter.findIndex(item => item.value === selectedItem.value)

      if (index > -1) {
        currentFilter.splice(index, 1)
      } else {
        currentFilter.push(selectedItem)
      }

      this.resetCurrentPage()

      if (updateNow) {
        this.saveFilters()
        this.loadLocations()
      }
    },
    selectLocation(locationId) {
      const selectedLocationIndex = this.selectedLocations[this.pagination.currentPage].indexOf(locationId)
      if (selectedLocationIndex > -1) {
        this.selectedLocations[this.pagination.currentPage].splice(selectedLocationIndex, 1)
      } else {
        this.selectedLocations[this.pagination.currentPage].push(locationId)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-icon {
  width: 15px;
  height: 15px;

  &:hover {
    cursor: pointer;
    fill: #3a5b98;
  }
}

.toolbar {
  display: flex;
  align-items: baseline;
}

.create-location-button {
  margin-left: auto;
}

.locations-list-container {
  margin-top: 1rem;
}
</style>
