
import FormsContentBuildVideo from 'forms/forms-content-build-video.vue';
import FormsContentBuildHtml from 'forms/forms-content-build-html.vue';
import FormsContentBuildImage from 'forms/forms-content-build-image.vue';

export default class formsContentService {
  static getElementTypeDesignerComponent(elementType) {
    const mapping = {
      'Video': FormsContentBuildVideo,
      'Html': FormsContentBuildHtml,
      'Image': FormsContentBuildImage
    };

    if (!(elementType in mapping)) {
      console.error('Unexpected element type.');
    }

    return mapping[elementType];
  }
};
