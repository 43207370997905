import urlService from "api/urlService.js";
import { AxiosLocal as axios } from "common/services/axiosLocal.service";

export default {
  async list(clientId, type) {
    const apiUrl = await urlService.getConnectionsListUrl(clientId, type);
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async get(clientId, connectionId) {
    const apiUrl = await urlService.getConnectionUrl(clientId, connectionId);
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async getSettings(clientId, connectionId) {
    const apiUrl = await urlService.getConnectionSettingsUrl(clientId, connectionId);
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async updateSettings(clientId, connectionId, connectionSettings) {
    const apiUrl = await urlService.getConnectionSettingsUrl(clientId, connectionId);

    await axios.put(apiUrl, connectionSettings);
  },
  async getActivityLog(clientId, connectionId) {
    const apiUrl = await urlService.getConnectionsActivityLogUrl(clientId, connectionId);
    const response = await axios.get(apiUrl);

    return response.data;
  },
};