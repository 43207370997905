<template>
  <section>
    <cb-admin-view-title :title="$t('users.title')" class="cb-admin-view-title">
      <b-button v-if="showAddBtn"
                class="create-user-button"
                variant="green"
                @click="addUser"
      >{{ $t('users.createUser') }}
      </b-button>
    </cb-admin-view-title>
  <cb-view-section v-if="data"
                   type="secondary"
                   class="pt-0"
                   :includePrimaryMessages="false">
    <div class="toolbar">
      <div class="filter-group">
        <div class="search-item">
              <b-form-input
                  v-if="viewData.search && selectedFilter[viewData.search.filterKey]"
                  class="search-input users-filter small-input"
                  :placeholder="viewData.search.placeholder"
                  :debounce="500"
                  v-model="selectedFilter[viewData.search.filterKey][0].value"
                  @update="searchHandler"
              ></b-form-input>
        </div>
      </div>
      <multi-select v-for="filter in filters"
                    :options="filter.items"
                    :value="selectedFilter[filter.filterKey]"
                    :search-placeholder="$t('common.filterSearchPlaceholder', [filter.title.toLowerCase()])"
                    :close-on-select="false"
                    track-by="value"
                    search-by="label"
                    :title="filter.title"
                    :key="filter.filterKey"
                    @select="selectFilter(filter.filterKey, $event, true)"
                    @newSearchValue="doSearch"
                    @clickAwayOnSearch="reloadClearedFilters('')"                                    
      >
        <template v-slot:option="{ option, isSelected }">
          <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
          </b-form-checkbox>
          <span v-text-middle-ellipsis="option.label"></span>
        </template>
      </multi-select>
      <b-button class="ml-3 small-button clear-filters-users"
              variant="outline-green"
              @click="clearFilters"
      >
        {{ $t('common.clearFilters') }}
      </b-button>
    </div>
    <div v-if="error">
      <p class="mt-3">{{ error }}</p>
    </div>
    <div v-else>
      <b-table v-if="users"
               class="users-list-container new-table new-table--without-first-cell-border"
               v-bind="tableSettings"
               ref="usersTable"
               :items="users.users"
               show-empty
               no-local-sorting
               :empty-text="emptyText"
               @sort-changed="sortUsers"
               :fields="[
      { key: 'select', label: '', thStyle: { width: '50px' }, sortable: false },
      { key: 'name', label: viewData.nameHeader, thAttr: { 'aria-sort': 'descending' },
        thStyle: { maxWidth: '250px' }, sortable: true, sortDirection: 'desc'},
      { key: 'accessLevel', label: viewData.accessLevelHeader, sortable: true },
      { key: 'view', label: '', class: 'link-column' }
    ]">
        <template v-slot:head(select)>
          <div class="multi-checkbox"
              :class="multiCheckboxStatus"
              @click="multiSelectUsers"></div>
        </template>
        <template v-slot:cell(select)="{ item }">
          <b-form-checkbox
              :checked="isUserChecked(item.id)"
              class="user-checkbox"
              @change="selectUser(item.id)"
          >
          </b-form-checkbox>
        </template>
        <template v-slot:cell(name)="{ item }">
          {{ item.formattedName }}
        </template>
        <template v-slot:cell(view)="{ item }">
          <svg class="edit-icon"
               @click="editUser(item)">
            <use v-bind="{'xlink:href': getIconPath('Action-Edit-Icon') }"></use>
          </svg>
        </template>
      </b-table>
      <div v-if="showPagination" class="pagination-container">
        <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm" class="per-page-options">
            <b-form-select
                id="per-page-select"
                :value="pagination.itemsPerOnePage"
                :options="paginationSettings.pageOptions"
                @change="handlePageSizeChange"
                size="sm">
            </b-form-select>
        </b-form-group>
        <b-pagination
            :value="pagination.currentPage"
            :total-rows="totalRows"
            :per-page="pagination.itemsPerOnePage"
            :current-page="pagination.currentPage"
            v-bind="paginationSettings"
            @change="handlePageChange">
        </b-pagination>
      </div>
      <div>
        <b-form-checkbox
            @change="handleInactiveChange"
            v-model="showInactive"
            class="inactive-toggle"
        >
          {{ viewData.inactiveFilter.label }}
        </b-form-checkbox>
      </div>
    </div>
    <multi-edit v-if="allSelectedUsersIds.length > 0"
                :batch="viewData.batch"
                :selected-ids="allSelectedUsersIds"
                :button-titles="{
                  'Cancel': $t('candidates.multiEditCancelTitle')
                }"
                @update="updateUsers"
    ></multi-edit>
  </cb-view-section>
  <user-add :data="viewData.addEdit"
                 :edit-item="editItem"
                 :edit-settings="editSettings"
                 @ok="handleOk"
                 @close="handleClose"
                 @cancel="handleClose"
                 @hide="handleClose"
                 v-if="modalShow">>
  </user-add>
  </section>
</template>

<script>
import AppSettings from 'appSettings';
import CbViewSection from "../general/cb-view-section";
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import MultiSelect from 'common/components/multi-select.vue';
import UserAdd  from './user-add.vue';
import ellipsisText from "../directives/text-middle-ellipsis";
import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../constants/defaultTableSettings";
import usersManagementService from 'api/usersManagementService';
import MultiEdit from 'general/multi-edit'
import {IconProvider} from 'common/icon.module'

export default {
  name: "users-list",
  components: {
    CbViewSection,
    CbAdminViewTitle,
    MultiSelect,
    UserAdd,
    MultiEdit
  },
  props: {
    data: Object
  },
  directives: {
    textMiddleEllipsis: {
      bind(el, {value}) {
        ellipsisText(el, {value})
      },
      update(el, {value}) {
        el.innerHTML = ''
        ellipsisText(el, {value})
      }
    }
  },
  data() {
    return {
      viewData: null,
      users: null,
      filters: [],
      originalFilters :[],
      selectedFilter: {},
      tableSettings: TABLE_SETTINGS,
      paginationSettings: PAGINATION_SETTINGS,
      totalRows: 0,
      sorting: {
        SortBy: undefined,
        SortDir: undefined
      },
      selectedUsers: {},
      pagination: {
        currentPage: 1,
        itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0]
      },
      showInactive: false,
      error: null,
      showAddBtn: true,
      modalShow: false,
      editSettings: null,
      locationGroups: null,
      editItem: null
    }
  },
  computed: {
    multiCheckboxStatus() {
      if (this.selectedUsers[this.pagination.currentPage].length === this.allUserIds.length && this.allUserIds.length !== 0) {
        return 'full'
      } else if (this.selectedUsers[this.pagination.currentPage].length > 0) {
        return 'half'
      } else {
        return 'empty'
      }
    },
    storage() {
      return `cb${AppSettings.appType}userFilter`
    },
    emptyText() {
      return !this.hasUsers && this.showInactive ? this.viewData.noInactiveLabel : this.viewData.noActiveLabel
    },
    hasUsers() {
      return !!(this.users && this.users.users && this.users.users.length)
    },
    showPagination() {
      return this.hasUsers && this.totalRows > PAGINATION_SETTINGS.pageOptions[0]
    },
    allUserIds() {
      if (this.users && this.users.users) {
        return this.users.users.map(user => user.id)
      } else {
        return []
      }
    },
    allSelectedUsersIds() {
      return Object.keys(this.selectedUsers).reduce((ids, key) => {
        ids = ids.concat(this.selectedUsers[key])

        return ids
      }, [])
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      async handler(data) {
        this.viewData = data
        if (this.validateData()) {
          await this.loadFilters()

          this.resetCurrentPage()

          try {
            if (typeof (sessionStorage) !== 'undefined') {
              const savedFilter = JSON.parse(sessionStorage.getItem(this.storage) || "{}")
              this.selectedFilter = savedFilter ? Object.assign({}, this.selectedFilter, savedFilter) : this.selectedFilter
            }
          } catch (e) {
            console.log(e)
          }

          await this.loadUsers()
        }
      }
    }
  },
  methods: {
    multiSelectUsers() {
      if (this.selectedUsers[this.pagination.currentPage].length === this.allUserIds.length) {
        this.selectedUsers[this.pagination.currentPage] = []
      } else {
        this.selectedUsers[this.pagination.currentPage] = this.allUserIds
      }
    },
    isUserChecked(userId) {
      const selectedUsersOnPage = this.selectedUsers && this.selectedUsers[this.pagination.currentPage]
      return selectedUsersOnPage && selectedUsersOnPage.findIndex(id => id === userId) > -1
    },
    validateData() {
      if (this.viewData) {
        if (this.viewData.addEdit.settings.brandsNoData) {
          this.error = this.viewData.addEdit.settings.brandsNoData
          this.showAddBtn = false
          return false
        } else {
          this.error = null
          this.showAddBtn = true
          return true
        }
      }
      return false
    },
    async loadUsers() {
      this.users = await usersManagementService.getUsers(this.viewData.getUsersUrl, this.generateQuery(this.selectedFilter || []))
      this.totalRows = this.users.totalCount
    },
    setDefaultFilters () {
      this.filters.forEach(filter => {
        this.$set(this.selectedFilter, filter.filterKey, [])
      })

      this.$set(this.selectedFilter, this.viewData.search.filterKey, [{value: ''}])
    },
    async loadFilters() {
      const response = await usersManagementService.getUsersFilters(this.viewData.getFiltersUrl)
      this.filters = response.filters
      this.originalFilters = response.filters[0].items;
      this.setDefaultFilters()
    },
    generateQuery(filtersSelected) {
      if (!filtersSelected) {
        filtersSelected = this.selectedFilter
      }

      let filters = {}
      Object.keys(filtersSelected).forEach(selectedFilter => {
        if (filtersSelected[selectedFilter].length) {
          filters[selectedFilter] = filtersSelected[selectedFilter].map(elem => {
            return elem.value
          }).join(",")

          if (!filters[selectedFilter]) {
            delete filters[selectedFilter]
          }
        }
      })

      //Inactive
      if (this.showInactive) {
        filters[this.viewData.inactiveFilter.filterKey] = true
      }

      // Sorting
      if (this.sorting.sortBy) {
        filters['sortBy'] = this.sorting.sortBy
        filters['SortDir'] = this.sorting.SortDir
      }
      // Pagination
      filters['skip'] = this.pagination.itemsPerOnePage * (this.pagination.currentPage - 1)
      filters['take'] = this.pagination.itemsPerOnePage


      return filters
    },
    handleOk (data) {
      this.editItem = null
      this.resetCurrentPage()
      this.loadUsers()
    },
    handleClose () {
      this.editItem = null
      this.modalShow = false
    },
    async editUser (user) {
      await this.setEditSettings()
      this.editItem = user
      this.modalShow = true
    },
    async addUser () {
      await this.setEditSettings()
      this.modalShow = true
    },
    async setEditSettings() {      
      if (!this.editSettings) {
        this.editSettings = await usersManagementService.getEditSettings(this.viewData.getEditSettingsUrl)
        this.locationGroups=this.editSettings.locationGroupSetting.locationGroups;
      }      
      else{
        this.editSettings.locationGroupSetting.locationGroups=this.locationGroups;
      }
    },
    sortUsers(options) {
      this.sorting.sortBy = options.sortBy[0].toUpperCase() + options.sortBy.slice(1)
      this.sorting.SortDir = options.sortDesc ? 'Desc' : 'Asc'
      this.loadUsers()
    },
    handleInactiveChange() {
      this.showInactive = !this.showInactive
      this.resetCurrentPage()
      this.loadUsers()
    },
    clearFilters () {
      this.setDefaultFilters()
      this.resetCurrentPage()
      this.loadUsers()
      this.reloadClearedFilters();
    },
    searchHandler() {
      this.resetCurrentPage()
      this.saveFilters()
      this.loadUsers()
    },
    resetCurrentPage() {
      this.pagination.currentPage = 1
      this.selectedUsers = {1: []}
      this.resetCheckedUsers()
    },
    resetCheckedUsers(){
      if (this.users && this.users.users) {
        this.users.users.forEach(user => {
          if (user.checked) {
            user.checked = false
          }
        })
      }
    },
    saveFilters() {
      if (Modernizr && Modernizr.sessionstorage) sessionStorage.setItem(this.storage, JSON.stringify(this.selectedFilter))
    },
    selectFilter(filterKey, selectedItem, updateNow) {
      let currentFilter = this.selectedFilter[filterKey]

      const index = currentFilter.findIndex(item => item.value === selectedItem.value)

      if (index > -1) {
        currentFilter.splice(index, 1)
      } else {
        currentFilter.push(selectedItem)
      }

      this.resetCurrentPage()

      if (updateNow) {
        this.saveFilters()
        this.loadUsers()
      }
    },
    doSearch(searchData) {       
       this.filters[0].items=this.originalFilters.filter(item => {  
        if(item.label.toLowerCase().includes(searchData.searchValue.toLowerCase())){         
          return item;
        }      
      })           
    },
    reloadClearedFilters(){      
     this.filters[0].items=this.originalFilters;
    },
    handlePageChange(value) {
      this.pagination.currentPage = value
      if (!this.selectedUsers[value]) {
        this.$set(this.selectedUsers, value, [])
      }
      this.loadUsers()
    },
    handlePageSizeChange(value) {
      this.pagination.itemsPerOnePage = value
      this.resetCurrentPage()
      this.loadUsers()
    },
    getIconPath(icon) {
      return IconProvider.getPath(icon);
    },
    selectUser(userId) {
      const selectedUserIndex = this.selectedUsers[this.pagination.currentPage].indexOf(userId)
      if (selectedUserIndex > -1) {
        this.selectedUsers[this.pagination.currentPage].splice(selectedUserIndex, 1)
      } else {
        this.selectedUsers[this.pagination.currentPage].push(userId)
      }
    },
    updateUsers () {
      this.resetCurrentPage()
      this.loadUsers()
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-icon {
  width: 15px;
  height: 15px;

  &:hover {
    cursor: pointer;
    fill: #3A5B98;
  }
}

.toolbar {
  display: flex;
  margin-bottom: 1rem;
  align-items: baseline;

  & > div {
    margin-right: 1rem;
  }

  & > div:last-of-type {
    margin-right: 0;
  }

  .filter-group {
    display: block;
    flex-grow: 1;
    margin-right: 1rem;

    .filter-item {
      input::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #838383;
      }
    }
  }
}

.create-user-button {
  margin-left: auto;
}

.users-list-container {
  margin-top: 1rem;
}
</style>
