import * as ContentActionsProvider from "forms/element-list-item-providers/actions/content";
import * as ContentIconProvider from "forms/element-list-item-providers/icons/content";
import has from 'lodash.has';

function canHandle (element) {
  return element.type === "Image";
}

function map (element, index, editElementHandler, deleteElementHandler) {
  return {
    label: has(element, 'designer.label') 
    ? `${element.designer.label}: ${element.description}` 
    : element.description,
    actions: ContentActionsProvider.get(element, index, editElementHandler, deleteElementHandler),
    icon: ContentIconProvider.get(element)
  };
}

export { canHandle, map }