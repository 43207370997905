<template>
  <div class="cb-search">
    <cb-search-control @cb-search="search" :search-term="searchTerm" />
    <div class="cb-search-results-container" v-if="searchResult && searchResult.searchTerm">
      <i18n :path="searchLabelKey" tag="div">
        <b>{{searchResult.searchTerm}}</b>
        <span>{{searchResult.count}}</span>
      </i18n>
    </div>
  </div>
</template>
<script>
  import {SavageFormLabeledField, SavageForm} from '@clickboarding/savage';
  import SearchIconSvg from 'general/icons/search.svg';
  import CbViewSection from 'general/cb-view-section.vue';
  import CbSearchControl from 'general/cb-search-control.vue';

  export default {
    name: 'cb-search-container',
    components: {
      SavageFormLabeledField,
      SavageForm,
      CbViewSection,
      CbSearchControl
    },
    props: {
      searchTerm: {
        type: String,
        require: false,
        default: ''
      },
      searchResult: {
        type: Object,
        required: false,
        default: null
      }
    },
    computed: {
      arrowClass() {
        return {
          'cb-triangle-up': this.open,
          'cb-triangle-down': !this.open,
          'toggle-button-triangle-up': this.open,
          'toggle-button-triangle-down': !this.open,
        }
      },
      searchIconSvg() {
        return '#' + SearchIconSvg.id;
      },
      searchLabelKey () {
        return "general.searchResultsFormat." + 
          (this.searchResult.count === 0 ? "none" :
          this.searchResult.count === 1 ? "one" : "many")
      }
    },
    methods:{
      search(searchTerm){
        this.$emit("cb-search", searchTerm);
      },
      toggle() {
        this.open = !this.open;
      },
    },
    data() {
      return {
        formstate: {},
        open: false,
      }
    }
  }
</script>
<style scoped lang="scss">
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';
  @import 'common/css/components';

  .cb-search-toggle-container {
    display: flex;
    justify-content: center;
  }

  .cb-search-toggle-button {
    @include font-size-content-root;

    display: flex;
    color: $cb-brand-blue-1;
    background-color: $cb-white;
    border-left: solid 1px $cb-light-grey-1;
    border-right: solid 1px $cb-light-grey-1;
    border-bottom: solid 1px $cb-light-grey-1;
    border-top: 0;
    vertical-align: top;
    padding: .25rem .5rem;

    &:active, &:focus, &:hover {
      color: $cb-dark-blue-1;
    }
  }
  
  .cb-search-toggle-button-content {
    display: flex;
    padding: .25rem;
  }

  .cb-search-results-container {
    margin-top: 1.5rem;
    text-align: center;
  }

  .toggle-text {
    padding-right: 6rem;
    padding-left: 6rem;
    font-weight: bold;
  }

  .arrow-container {
    padding-top: .25rem;
  }

  .toggle-button-triangle-up {
    border-color: transparent transparent $cb-brand-blue-1 transparent;
    border-width: 0px 6px 6px 6px;
  }

  .toggle-button-triangle-down {
    border-color: $cb-brand-blue-1 transparent transparent transparent;
    border-width: 6px 6px 0px 6px;
  }
</style>
