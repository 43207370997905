var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('cb-admin-view-title',{staticClass:"cb-admin-view-title",attrs:{"title":_vm.$t('locations.title')}},[(_vm.showAddBtn)?_c('b-button',{staticClass:"create-location-button",attrs:{"variant":"green"},on:{"click":function($event){return _vm.addEditLocation(undefined)}}},[_vm._v(_vm._s(_vm.$t("locations.createLocation"))+"\n    ")]):_vm._e()],1),(_vm.data)?_c('cb-view-section',{staticClass:"pt-0",attrs:{"type":"secondary","includePrimaryMessages":false}},[(_vm.filters.length > 0)?_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"filter-group"},[_c('div',{staticClass:"search-item mr-3"},[(_vm.searchFilterKey && _vm.selectedFilter[_vm.searchFilterKey])?_c('b-form-input',{staticClass:"search-input small-input",attrs:{"debounce":500,"placeholder":_vm.$t("locations.filter.placeholder.search")},on:{"update":_vm.searchHandler},model:{value:(_vm.selectedFilter[_vm.searchFilterKey][0].value),callback:function ($$v) {_vm.$set(_vm.selectedFilter[_vm.searchFilterKey][0], "value", $$v)},expression:"selectedFilter[searchFilterKey][0].value"}}):_vm._e()],1),_vm._l((_vm.filters),function(filter){return [(filter.items.length > 1)?_c('multi-select',{key:filter.filterKey,staticClass:"mr-3",attrs:{"options":filter.items,"value":_vm.selectedFilter[filter.filterKey],"search-placeholder":_vm.$t('common.filterSearchPlaceholder', [filter.title.toLowerCase()]),"close-on-select":false,"track-by":"value","search-by":"label","title":filter.title},on:{"select":function($event){return _vm.selectFilter(filter.filterKey, $event, true)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
var isSelected = ref.isSelected;
return [_c('b-form-checkbox',{staticClass:"pointer-none",attrs:{"checked":isSelected(option)}}),_c('span',{directives:[{name:"text-middle-ellipsis",rawName:"v-text-middle-ellipsis",value:(option.label),expression:"option.label"}]})]}}],null,true)}):_vm._e()]})],2),_c('b-button',{staticClass:"small-button clear-filters-locations",attrs:{"variant":"outline-green"},on:{"click":_vm.clearFilters}},[_vm._v("\n      "+_vm._s(_vm.$t('common.clearFilters'))+"\n    ")])],1):_vm._e(),(_vm.error)?_c('div',[_c('p',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.error))])]):_c('div',[(_vm.listLocations.locations)?_c('b-table',_vm._b({staticClass:"locations-list-container new-table new-table--without-first-cell-border",attrs:{"items":_vm.listLocations.locations,"no-local-sorting":"","sort-by":"name","fields":[
    { key: 'select', label: '', thStyle: { width: '50px' }, sortable: false },
    { key: 'name', label: _vm.data.nameHeader, thAttr: { 'aria-sort': 'descending' }, thStyle: { maxWidth: '250px' },
      sortable: true },
    { key: 'identifier', label: _vm.data.idHeader, sortable: true },
    { key: 'address.city', label: _vm.data.cityHeader, sortable: true },
    { key: 'address.stateProvinceRegion', label: _vm.data.stateHeader, thStyle: { minWidth: '15%' }, sortable: true },
    { key: 'view', label: '', class: 'link-column' }
  ]},on:{"sort-changed":_vm.sortLocations},scopedSlots:_vm._u([{key:"cell(select)",fn:function(ref){
  var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"location-checkbox",attrs:{"checked":_vm.isLocationSelected(item.id)},on:{"change":function($event){return _vm.selectLocation(item.id)}}})]}},{key:"cell(name)",fn:function(ref){
  var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.name)+"\n        ")]}},{key:"cell(view)",fn:function(ref){
  var item = ref.item;
return [_c('svg',{staticClass:"edit-icon",on:{"click":function($event){return _vm.addEditLocation(item)}}},[_c('use',_vm._b({},'use',{ 'xlink:href': _vm.getIconPath('Action-Edit-Icon') },false))])]}}],null,false,668943183)},'b-table',_vm.tableSettings,false)):_c('p',{staticClass:"mt-3"},[_vm._v("\n        "+_vm._s(_vm.noLocationsText)+"\n      ")]),(_vm.showPagination)?_c('div',{staticClass:"pagination-container"},[_c('b-form-group',{staticClass:"per-page-options",attrs:{"label":"Per page","label-for":"per-page-select","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","value":_vm.pagination.itemsPerOnePage,"options":_vm.paginationSettings.pageOptions,"size":"sm"},on:{"change":_vm.handlePageSizeChange}})],1),_c('b-pagination',_vm._b({attrs:{"value":_vm.pagination.currentPage,"total-rows":_vm.listLocations.totalCount,"per-page":_vm.pagination.itemsPerOnePage,"current-page":_vm.pagination.currentPage},on:{"change":_vm.handlePageChange}},'b-pagination',_vm.paginationSettings,false))],1):_vm._e(),_c('div',[_c('b-form-checkbox',{staticClass:"inactive-toggle",attrs:{"vslue":_vm.showInactive},on:{"change":_vm.handleShowInactiveLocationsChange}},[_vm._v("\n          "+_vm._s(_vm.data.showInactiveLabel)+"\n        ")])],1)],1),(_vm.allSelectedLocationsIds.length > 0)?_c('multi-edit',{attrs:{"batch":_vm.data.batch,"selected-ids":_vm.allSelectedLocationsIds,"button-titles":{
                'Cancel': _vm.$t('candidates.multiEditCancelTitle')
              }},on:{"update":_vm.updateLocations}}):_vm._e()],1):_vm._e(),(_vm.modalShow)?_c('add-location',{attrs:{"data":_vm.data.addEdit,"edit-item":_vm.editItem},on:{"ok":_vm.updateLocations,"close":function($event){_vm.modalShow = false},"cancel":function($event){_vm.modalShow = false},"hide":function($event){_vm.modalShow = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }