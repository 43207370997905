<template>    
    <span>
        <ag-grid-vue style="width: 100%; height: 92%;"
                     @grid-ready="onGridReady"
                     :rowModelType="rowModelType"
                     :serverSideInfiniteScroll="true"
                     :pagination="true"
                     :paginationPageSize="paginationPageSize"
                     :cacheBlockSize="cacheBlockSize"
                     :animateRows="true"
                     class="ag-theme-alpine"
                     :rowClassRules="rowClassRules"
                     :onFilterChanged="onFilterChanged"
                     :columnDefs="columnDefs"
                     :defaultColDef="defaultColDef"
                     :suppressContextMenu="false"
                     :getContextMenuItems="getContextMenuItems"
                     :suppressMenuHide="true"
                     :getRowId="getRowId"
                     :sideBar="sideBar"
                     :rowSelection="rowSelection"
                     :rowGroupPanelShow="rowGroupPanelShow"
                     :autoGroupColumnDef="autoGroupColumnDef"
                     :getServerSideGroupLevelParams="getServerSideGroupLevelParams"
                     :serverSideFilterAllLevels="true"
                     :getChildCount="getChildCount"
                     @first-data-rendered='onFirstDataRendered'>
        </ag-grid-vue>

        <personal-information :firstName="firstName"
                              :middleName="middleName"
                              :lastName="lastName"
                              :email="email"
                              :phone="phone"
                              :ssoIdentifier="ssoIdentifier"
                              :sourceSystemId="sourceSystemId"
                              :showSourceSystemId="true" />

        <complete-action :actionItem="currentActionItem"
                         :detailsRequest="currentActionItem.instance"
                         @action-item-details-loaded="showCompleteActionModal()"
                         @action-item-status-changed="actionItemStatusChanged()"
                         @closed="clearCurrentActionItemData()" />

        <action-center-revamped-hrform v-if="showCompleteForm"
                                       :instanceRequest="currentFormItem.instance"
                                       v-on:complete-form-closed="clearCurrentActionItemData"
                                       v-on:action-item-details-loaded="handleDetailsLoaded"
                                       v-on:action-item-update-error="handleUpdateError"
                                       v-on:action-item-status-changed="actionItemStatusChanged" />

        <action-center-revamped-i9everify v-if="showCompleteI9eVerify"
                                       :instanceRequest="currentI9eVerifyItem.instance"
                                       :wasCompleted="currentI9eVerifyItem.isCompleted"
                                       v-on:complete-form-closed="clearCurrentActionItemData"
                                       v-on:action-item-details-loaded="handleDetailsLoaded"
                                       v-on:action-item-update-error="handleUpdateError"
                                       v-on:action-item-status-changed="actionItemStatusChanged" />

        <current-step :detailsUrl="currentStepDetailsUrl" :showDetail="showCurrentStepDetails" @closed="clearCurrentStepData()" />

        <b-modal no-close-on-backdrop
                 centered
                 ok-only
                 static
                 id="error-export-threshold">
            Exports cannot exceed {{this.exportThreshold}} rows.
        </b-modal>
    </span>
</template>


<script>
    import "ag-grid-enterprise";
    import { AgGridVue } from "ag-grid-vue";
    import ActionCenterService from "./action-center-revamped.service";
    import * as _ from "lodash";
    import ActionButton from './action-button.vue';
    import CandidateNameHyperlink from './candidate-name-hyperlink.vue';
    import PersonalInformation from './personal-information-modal.vue';
    import CompleteAction from './complete-action-modal.vue';
    import ActionType from "./actionType";
    import ActionCenterHRForm from './action-center-revamped-hrform.vue';
    import ActionCenterI9eVerify from './action-center-revamped-i9everify.vue';
    import CurrentStep from './current-step-modal.vue';
    import CurrentStepHyperlink from './current-step-hyperlink.vue';
    import appSettings from "../appSettings";

    let gridApi = null;
    let gridColumnApi = null;
    let latestRequestParams = null;

    function resetFilterSortState() {
        const columnState = {
            state: [
                {
                    colId: 'dueDate',
                    sort: 'asc'
                }
            ]
        }
        gridColumnApi.applyColumnState(columnState);
        gridApi.setFilterModel(null); //clears all filters  
    }

    export default {
        name: "action-center-base",
        data() {
            return {
                columnDefs: null,
                rowData: null,
                defaultColDef: null,
                filter: false,
                rowModelType: 'serverSide',
                paginationPageSize: ActionCenterService.getPageSize(),
                cacheBlockSize: ActionCenterService.getPageSize(),
                startRow: 0,
                endRow: ActionCenterService.getPageSize(),
                sideBar: null,
                rowSelection: null,
                rowGroupPanelShow: null,
                autoGroupColumnDef: null,
                getServerSideGroupLevelParams: null,
                getChildCount: null,
                getRowId: null,
                firstName: '',
                middleName: '',
                lastName: '',
                email: '',
                phone: '',
                ssoIdentifier: '',
                sourceSystemId: '',
                rowClass: '',
                rowClassRules: {
                    'due-date-expired': function (params) {
                        if (params.data) {
                            var dueDate = new Date(params.data.dueDate);
                            var today = new Date();

                            if (dueDate < today) {
                                return true;
                            }
                        }
                        return false;
                    },
                },
                currentActionItem: {
                    instance: null,
                    actionType: ActionType.ACTION_TYPE_TASK_UI_SIDE
                },
                currentFormItem: {
                    instance: null,
                    actionType: ActionType.ACTION_TYPE_FORM_UI_SIDE
                },
                showCompleteForm: false,
                currentI9eVerifyItem: {
                    instance: null,
                    actionType: ActionType.ACTION_TYPE_I9EVERIFY_UI_SIDE,
                    isCompleted: false
                },
                showCompleteI9eVerify: false,
                currentStepDetailsUrl: undefined,
                showCurrentStepDetails: false,
                totalResultCount: 0,
            };
        },
        props: {
            dataUrl: {
                type: String,
                required: true
            },
            clearFilter: { type: Date, required: false },
            downloadFile: { type: Object, required: false },
            exportThreshold: {
                type: Number,
                required: true
            },
        },
        created() {
            this.setDefaultColDef();
            this.rowSelection = 'multiple';
            this.rowGroupPanelShow = 'always';
            this.rowModelType = 'serverSide';
            this.getChildCount = (data) => {
                return data ? data.childCount : undefined;
            };

            this.columnDefs = this.getColumnDefs();
            this.getRowId = (params) => {
                //Report API will add unique identifier to RowId property to use here
                return params.data.rowId;
            };
            this.sideBar = {
                toolPanels: [
                    {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                            suppressRowGroups: true,
                            suppressPivots: true,
                            suppressPivotMode: true,
                            suppressValues: true
                        }
                    },
                    {
                        id: 'filters',
                        labelDefault: 'Filters',
                        labelKey: 'filters',
                        iconKey: 'filter',
                        toolPanel: 'agFiltersToolPanel',
                    }
                ],
                position: 'right',
                defaultToolPanel: ''
            };
            this.$watch('clearFilter', () => { resetFilterSortState() });
            this.$watch('downloadFile', async (obj) => {
                if (this.totalResultCount >= this.exportThreshold) {
                    this.showErrorModal();
                } else {
                    // get current col definitions into array
                    let currCols = _.filter(gridColumnApi.getAllGridColumns(),
                        x => x.visible === true && x.colDef.headerName !== 'Group' && x.colDef.headerName !== 'Action') //when using the grouping feature, the grouping header column is included in the list of returned columns and needs to be filtered out
                        .map((c) => c.colDef.field);
                    let response = await ActionCenterService.getExport(latestRequestParams, currCols, obj.type);
                    const fileName = response.headers["content-disposition"].split("filename=")[1];
                    const mimeType = response.headers["Content-Type"];
                    saveFile(response.data, fileName, mimeType);
                }
            });
            this.getServerSideGroupLevelParams = (params) => {
                var noGroupingActive = params.rowGroupColumns.length == 0;
                var res;
                if (noGroupingActive) {
                    res = {
                        // infinite scrolling
                        infiniteScroll: true,
                        // 100 rows per block
                        cacheBlockSize: 100,
                        // purge blocks that are not needed
                        maxBlocksInCache: 2,
                    };
                } else {
                    res = {
                        infiniteScroll: true,
                        cacheBlockSize: params.level == 0 ? 25 : params.level == 1 ? 5 : 2,
                        maxBlocksInCache: -1, // never purge blocks
                    };
                }
                return res;
            };
        },
        methods: {
            async onGridReady(params) {
                this.hideOldActionCenterPanel();
                gridApi = params.api;
                gridColumnApi = params.columnApi;

                resetFilterSortState();
                gridApi.sizeColumnsToFit(params)

                await this.loadDataSource();
            },
            async loadDataSource() {
                const updateData = async (data) => {
                    // create datasource with a reference to the service
                    var datasource = this.createServerSideDatasource(ActionCenterService);
                    // register the datasource with the grid
                    gridApi.setServerSideDatasource(datasource);
                };

                await updateData([]);
            },
            createServerSideDatasource(service) {
                return {
                    getRows: async (params) => {
                        latestRequestParams = params.request; //wasn't sure how else to get this from gridapi
                        // get data for request
                        let response = await service.getData(params.request);
                        params.success({
                            rowData: response.data || [],
                            rowCount: response.totalCount,
                        });
                        if (response.totalResultCount !== null) {
                            this.totalResultCount = response.totalResultCount;
                        }
                    },
                };
            },
            async handleDetailsLoaded() {
                await this.loadDataSource();
            },
            async handleUpdateError() {
                await this.loadDataSource();
            },
            async onCandidateNameBtnClick(candidateId) {
                await ActionCenterService.getPersonalInfo(candidateId).then((response) => {
                    if (response.candidate) {
                        this.setPersonalInfo(response.candidate);
                        this.showPersonalInfo();
                    }
                });
            },
            async onCurrentStepBtnClick(data) {
                var currentStepDetailUrl = await ActionCenterService.getCurrentStepDetailUrl(data.candidateId, data.subjectRequisitionId);
                this.currentStepDetailsUrl = currentStepDetailUrl;
                this.showCurrentStepDetails = true;                
            },
            async clearCurrentStepData() {
                this.currentStepDetailsUrl = null;
                this.showCurrentStepDetails = false;
            },
            onFilterChanged(params) {
                const setFilterEName = params.api.getFilterInstance('candidateName');
                setFilterEName.refreshFilterValues();
                const setFilterProcessFlow = params.api.getFilterInstance('processFlowName');
                setFilterProcessFlow.refreshFilterValues();
                const setFilterCurrentStep = params.api.getFilterInstance('currentStepName');
                setFilterCurrentStep.refreshFilterValues();
                const setFilterLocation = params.api.getFilterInstance('locationName');
                setFilterLocation.refreshFilterValues();
                const setFilterActivityType = params.api.getFilterInstance('activityType');
                setFilterActivityType.refreshFilterValues();
                const setFilterAssignedToName = params.api.getFilterInstance('assignedToName');
                setFilterAssignedToName.refreshFilterValues();
                const setFilterDueDate = params.api.getFilterInstance('dueDate');
                setFilterDueDate.refreshFilterValues();
                //if grouped reloadgrouping
            },
            async onFirstDataRendered(params) {
                params.api.sizeColumnsToFit();
            },
            setDefaultColDef: function () {
                this.defaultColDef = ActionCenterService.getDefaultColumnDef();
            },
            hideOldActionCenterPanel: function () {
                var $navItem = document.querySelector('.nav-item.toggle');
                if ($navItem) {
                    $navItem.click();
                }
            },
            setCurrentActionItemData: function (actionUrl, taskType) {
                this.currentActionItem.instance = {
                    method: 'GET',
                    url: actionUrl
                };

                this.currentActionItem.actionType = taskType;
            },
            setCurrentFormItemData (actionUrl, taskType) {
                this.currentFormItem.instance = {
                    method: 'GET',
                    url: actionUrl
                };

                this.currentFormItem.actionType = taskType;
            },
            setCurrentI9eVerifyItemData(actionUrl, isCompleted) {
                this.currentI9eVerifyItem.instance = {
                    method: 'GET',
                    url: actionUrl
                };

                this.currentI9eVerifyItem.isCompleted = isCompleted;
                this.currentI9eVerifyItem.actionType = ActionType.ACTION_TYPE_I9EVERIFY_UI_SIDE;
            },
            async clearCurrentActionItemData () {
                this.currentActionItem.instance = null;
                this.currentActionItem.actionType = ActionType.ACTION_TYPE_TASK_UI_SIDE;

                this.showCompleteForm = false;
                this.currentFormItem.instance = null;

                this.showCompleteI9eVerify = false;
                this.currentI9eVerifyItem.instance = null;
                this.currentI9eVerifyItem.isCompleted = false;

                await this.loadDataSource();
            },
            async onCompleteActionBtnClick(data) {
                var actionUrl = '';
                
                if (data.activityType == ActionType.ACTION_TYPE_TASK_SERVER_SIDE) {
                    actionUrl = await ActionCenterService.getCandidateTaskUrl(data.candidateId, data.subjectRequisitionId, data.activityId);
                    
                    this.setCurrentActionItemData(actionUrl, ActionType.ACTION_TYPE_TASK_UI_SIDE);                    
                }
                else if (data.activityType == ActionType.ACTION_TYPE_FORM_SERVER_SIDE) {
                    actionUrl = await ActionCenterService.getCandidateFormUrl(data.candidateId, data.subjectRequisitionId, data.activityId);

                    this.setCurrentFormItemData(actionUrl, ActionType.ACTION_TYPE_FORM_UI_SIDE); 
                    this.showCompleteForm = true;
                } else if (data.activityType == ActionType.ACTION_TYPE_I9_SERVER_SIDE || data.activityType == ActionType.ACTION_TYPE_EVERIFY_SERVER_SIDE) {
                    actionUrl = await ActionCenterService.getCandidateI9eVerifyUrl(data.candidateId, data.subjectRequisitionId, data.activityId);

                    let formIsCompleted = false;

                    if (data.activityStatus === 'Completed') {
                        formIsCompleted = true;
                    }

                    this.setCurrentI9eVerifyItemData(actionUrl, formIsCompleted);
                    this.showCompleteI9eVerify = true;
                }  
            }, 
            async actionItemStatusChanged() {
                this.hideCompleteActionModal();  

                await this.loadDataSource();
            },
            hideCompleteActionModal () {
                this.$root.$emit("bv::hide::modal", "complete-action-modal");
            },
            showCompleteActionModal () {
                this.$root.$emit("bv::show::modal", "complete-action-modal");
            },
            showErrorModal() {
                this.$root.$emit("bv::show::modal", "error-export-threshold");
            },
            showPersonalInfo () {
                this.$root.$emit("bv::show::modal", "personal-information-modal");
            },
            setPersonalInfo (candidate) {
                this.firstName = candidate.firstName;
                this.middleName = candidate.middleName;
                this.lastName = candidate.lastName;
                this.email = candidate.email;
                this.phone = candidate.phone;
                this.ssoIdentifier = candidate.ssoIdentifier;
                this.sourceSystemId = candidate.sourceSystemId;
            },
            getContextMenuItems() {
                var result = [
                    'copy',
                    'copyWithHeaders',
                ];
                return result;
            },
            getColumnDefs() {
                var colDefs = [
                    {
                        field: 'candidateName',
                        headerName: 'Employee Name',
                        headerTooltip: 'Employee Name',
                        minWidth: 155,
                        cellRenderer: 'CandidateNameHyperlink',
                        cellRendererParams: {
                            clicked: this.onCandidateNameBtnClick.bind(this),
                        },
                        ...ActionCenterService.buildTextFilterParams({ field: 'candidateName' }),
                    },
                    {
                        field: 'emailAddress',
                        headerName: 'Employee Email',
                        headerTooltip: 'Employee Email',
                        minWidth: 150,
                        ...ActionCenterService.buildTextFilterParams({ field: 'emailAddress' })
                    },
                    {
                        field: 'sourceSystemId',
                        headerName: 'Source System Id',
                        headerTooltip: 'Source System Id',
                        minWidth: 160,
                        hide: appSettings.sourceSystemIdFeatureToggle.toLowerCase() != "true",
                        ...ActionCenterService.buildTextFilterParams({ field: 'sourceSystemId' })
                    },
                    {
                        field: 'processFlowName',
                        headerName: 'Process Flow',
                        headerTooltip: 'Process Flow',
                        minWidth: 135,
                        ...ActionCenterService.buildSetFilterParams({ field: 'processFlowName' })
                    },
                    {
                        field: 'currentStepName',
                        headerName: 'Employee Current Step',
                        headerTooltip: 'Employee Current Step',
                        minWidth: 195,
                        cellRenderer: 'CurrentStepHyperlink',
                        cellRendererParams: {
                            clicked: this.onCurrentStepBtnClick.bind(this),
                        },
                        ...ActionCenterService.buildSetFilterParams({ field: 'currentStepName' })
                    },
                    {
                        field: 'locationName',
                        headerName: 'Location',
                        headerTooltip: 'Location',
                        minWidth: 80,
                        ...ActionCenterService.buildSetFilterParams({ field: 'locationName' })
                    },
                    {
                        field: 'activityName',
                        headerName: 'HR Activity',
                        headerTooltip: 'HR Activity',
                        minWidth: 110,
                        ...ActionCenterService.buildSetFilterParams({ field: 'activityName' })
                    },
                    {
                        field: 'assignedToName',
                        headerName: 'Assigned To',
                        headerTooltip: 'Assigned To',
                        minWidth: 100,
                        ...ActionCenterService.buildSetFilterParams({ field: 'assignedToName' })
                    },
                    {
                        field: 'dueDate',
                        headerName: 'Due Date',
                        headerTooltip: 'Due Date',
                        maxWidth: 140,
                        valueFormatter: (params) => (params.value == ' ' ? 'No Date' : formatDate(params.value)),
                        ...ActionCenterService.buildDateFilterParams()
                    },
                    {
                        field: 'activityType',
                        headerName: 'Action',
                        headerTooltip: 'Action',
                        filter: false,
                        sortable: false,
                        cellRenderer: 'ActionButton',
                        minWidth: 140,
                        maxWidth: 170,
                        cellRendererParams: {
                            clicked: this.onCompleteActionBtnClick.bind(this),
                        },
                        enableRowGroup: false,
                    }
                ];

                return colDefs;
            },            
        },
        components: {
            AgGridVue,
            ActionButton,
            PersonalInformation,
            CandidateNameHyperlink,
            CompleteAction,
            CurrentStep,
            CurrentStepHyperlink,
            'action-center-revamped-hrform': ActionCenterHRForm,
            'action-center-revamped-i9everify': ActionCenterI9eVerify,
        }
    };

    function saveFile(data, filename, objUrlType) {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(new Blob([data],
                { type: objUrlType }));

            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }

</script>
<style lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

.due-date-expired {
  color:red;
}
</style>

