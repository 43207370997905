if (!Number.MAX_SAFE_INTEGER) {
  Number.MAX_SAFE_INTEGER = 9007199254740991;
}

if (!Number.MIN_SAFE_INTEGER) {
  Number.MIN_SAFE_INTEGER = -9007199254740991;
}

Number.isInteger = Number.isInteger || function(value) {
  return typeof value === 'number' && 
    isFinite(value) && 
    Math.floor(value) === value;
};

// this isSafeInteger polyfill is contingent on the abs of the 
// MIN_SAFE_INTEGER and MAX_SAFE_INTEGER polyfills (defined above) to be equal
Number.isSafeInteger = Number.isSafeInteger || function (value) {
  return Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER;
};