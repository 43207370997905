import DatafieldsController from "datafields/datafields.controller";
import DatafieldsAddController from "datafields/datafields-add.controller";
import DatafieldsComponent from "datafields/datafields.vue";
import DatafieldsAddTypeSelectController from "datafields/datafields-add-type-select.controller";
import DatafieldsAddTypeSelectComponent from "datafields/datafields-add-type-select.vue";
import DatafieldAddComponent from "datafields/datafields-add.vue";

export default angular
    .module("datafields-module", [])
    .controller("datafieldsController", DatafieldsController)
    .controller("datafieldsAddTypeSelectController", DatafieldsAddTypeSelectController)
    .controller("datafieldsAddController", DatafieldsAddController)
    .value("datafields", DatafieldsComponent)
    .value("datafields-add-type-select", DatafieldsAddTypeSelectComponent)
    .value("datafields-add", DatafieldAddComponent)
    .name;
