export default function dashboardCard ($http, appSettings, DashService) {
	var link = function (scope, element, attrs) {
		scope.actionDisplayed = false;

		function dataHasProperty(propertyName) {
			return scope.data && scope.data.data && scope.data.data[propertyName];
		}

		function configSnoozeCard(isSnoozed) {
			scope.sleepConfig = {
				snooze: {
					unit: dataHasProperty('snoozeUnits') ? scope.data.data.snoozeUnits[0] : null,
					value: null
				}
			};

			if (isSnoozed) {
				scope.sleepConfig.snoozeObj = scope.data.data;
				scope.sleepConfig.snooze.unit = null;
			}

			scope.sleepActions = {
				primary: function(values) {
					var _data = {
						typeEnum: values.unit.typeLabel,
						snoozeValue: values.value
					};
					var endpoint = scope.data.data.action.url;
					var method = scope.data.data.action.method;

					DashService.doSnooze(method, endpoint, scope.data.id, true, _data).then(function(data) {
						scope.data = data.data;
						scope.sleepConfig.snoozeObj = data.data.data;
						scope.sleepConfig.snooze.unit = null;
					});
				},
				revoke: function() {
					var endpoint = scope.data.data.action.url;
					var method = scope.data.data.action.method;
					DashService.doSnooze(method, endpoint, scope.data.id, false).then(function(data) {
						scope.data = data.data;
						scope.sleepConfig.snoozeObj = null;
						scope.sleepConfig.snooze.value = null;
						// currently needed to reinstantiate scope.$apply
						// larger scale arch work needed
						scope.sleepConfig.snooze.unit = scope.data.data.snoozeUnits[0];
					});
				}
			};
		}

		var listen = scope.$watch('url', function () {
			if (typeof scope.url == 'undefined') return;

			$http.get(scope.url).then(function (response) {
					var data = response.data;
					if (data.data) scope.actionDisplayed = true;
					
					scope.data = data;
					configSnoozeCard(dataHasProperty('snoozeDetail'));
					listen();
			});
		});
	};

	return {
		restrict: "E",
		replace: true,
		link: link,
		template: require('dashboard/dashboardCard.html'),
		scope: {
			url: "="
		}
	};
};

dashboardCard.$inject = ['$http', 'appSettings', 'DashService']
