var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"candidate-personal-info-inputs"},[_c('div',{staticClass:"row"},[_c('validation-provider',{attrs:{"slim":"","rules":"required|max:100|noHtml"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"col-md-6",attrs:{"label-for":_vm.$id('firstName'),"state":passed,"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.$t(_vm.labelKey('firstName')))+"\n          "),_c('span',{staticClass:"text-danger",attrs:{"aria-hidden":""}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":_vm.$id('firstName'),"state":failed ? false : null,"required":"","maxlength":"100","noHtml":""},model:{value:(_vm.localFirstName),callback:function ($$v) {_vm.localFirstName=$$v},expression:"localFirstName"}})],1)]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"max:100|noHtml"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"col-md-6",attrs:{"label":_vm.$t(_vm.labelKey('middleName')),"label-for":_vm.$id('middleName'),"state":passed,"invalid-feedback":errors[0]}},[_c('b-form-input',{attrs:{"id":_vm.$id('middleName'),"state":failed ? false : null,"maxlength":"100","noHtml":""},model:{value:(_vm.localMiddleName),callback:function ($$v) {_vm.localMiddleName=$$v},expression:"localMiddleName"}})],1)]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"required|max:100|noHtml"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"col-md-6",attrs:{"label-for":_vm.$id('lastName'),"state":passed,"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n          "+_vm._s(_vm.$t(_vm.labelKey('lastName')))+"\n          "),_c('span',{staticClass:"text-danger",attrs:{"aria-hidden":""}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":_vm.$id('lastName'),"state":failed ? false : null,"required":"","maxlength":"100","noHtml":""},model:{value:(_vm.localLastName),callback:function ($$v) {_vm.localLastName=$$v},expression:"localLastName"}})],1)]}}])}),(_vm.lockIsTest)?_c('div',[(_vm.isTest)?_c('div',{staticClass:"w-100 margin-medplus-T margin-med-L font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.labelKey('isTest'))))]):_vm._e()]):_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.localIsTest),expression:"localIsTest"}],staticClass:"margin-med-L margin-lrg-T",attrs:{"type":"checkbox","id":_vm.$id('isTest'),"unchecked-value":false},domProps:{"value":true,"checked":Array.isArray(_vm.localIsTest)?_vm._i(_vm.localIsTest,true)>-1:(_vm.localIsTest)},on:{"change":function($event){var $$a=_vm.localIsTest,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=true,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.localIsTest=$$a.concat([$$v]))}else{$$i>-1&&(_vm.localIsTest=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.localIsTest=$$c}}}}),_c('label',{staticClass:"font-weight-bold margin-sm-L",attrs:{"for":"$id('isTest')"}},[_vm._v(_vm._s(_vm.$t(_vm.labelKey('isTest'))))])])],1),_c('hr'),_c('validation-provider',{attrs:{"slim":"","rules":"required|email|max:100|noHtml"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":_vm.$id('email'),"state":passed,"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t(_vm.labelKey('emailAddress')))+"\n        "),_c('span',{staticClass:"text-danger",attrs:{"aria-hidden":""}},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"email","id":_vm.$id('email'),"state":failed ? false : null,"required":"","maxlength":"100","noHtml":""},model:{value:(_vm.localEmail),callback:function ($$v) {_vm.localEmail=$$v},expression:"localEmail"}})],1)]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"min:10|max:20|noHtml"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t(_vm.labelKey('phoneNumber')),"label-for":_vm.$id('phone'),"state":passed,"invalid-feedback":errors[0]}},[_c('b-form-input',{attrs:{"type":"tel","id":_vm.$id('phone'),"state":failed ? false : null,"minlength":"10","maxlength":"20","noHtml":""},model:{value:(_vm.localPhone),callback:function ($$v) {_vm.localPhone=$$v},expression:"localPhone"}})],1)]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"max:250|noHtml"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":_vm.$id('ssoIdentifier'),"state":passed,"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t(_vm.labelKey('ssoIdentifier')))+"\n      ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":_vm.$id('ssoIdentifier'),"state":failed ? false : null,"maxlength":"250","noHtml":""},model:{value:(_vm.localSsoIdentifier),callback:function ($$v) {_vm.localSsoIdentifier=$$v},expression:"localSsoIdentifier"}})],1)]}}])}),_c('validation-provider',{attrs:{"slim":"","rules":"max:250|noHtml"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [(_vm.showSourceSystemId)?_c('b-form-group',{attrs:{"label-for":_vm.$id('sourceSystemId'),"state":passed,"invalid-feedback":errors[0]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t(_vm.labelKey('sourceSystemId')))+"\n      ")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":_vm.$id('sourceSystemId'),"state":failed ? false : null,"maxlength":"250","noHtml":""},model:{value:(_vm.localSourceSystemId),callback:function ($$v) {_vm.localSourceSystemId=$$v},expression:"localSourceSystemId"}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }