  <template>
    <actionCenterRevampedRoute :dataUrl="dataUrl" />
  </template>



<script>
import ActionCenterRevampedRoute from 'action-center-revamped/action-center-revamped-route.vue';


export default {
  name: 'action-center-route',
  components: {
    ActionCenterRevampedRoute
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    }
  }
}
</script>
