<template>
  <forms-builder :dataUrl="dataUrl" :isReadOnly="isReadOnly" :onBack="onBack" :canPreview="true" :onLoad="formsBuilderOnLoad" :beforeSave="formsBuilderBeforeSave">
    <template slot-scope="formsBuilderProps">
      <cb-view-section type="secondary">
        <savage-form-text-input
          name="formName"
          :label="formsBuilderProps.formLabels.nameLabel"
          :placeholder="formsBuilderProps.formLabels.namePlaceholder"
          :validation="buildFormNameValidation(formsBuilderProps)"
          v-model="formsBuilderProps.form.name" />
        <savage-form-text-input
          name="formTitle"
          :label="formsBuilderProps.formLabels.titleLabel"
          :placeholder="formsBuilderProps.formLabels.titlePlaceholder"
          :validation="buildFormTitleValidation(formsBuilderProps)"
          v-model="formsBuilderProps.form.title" />
      </cb-view-section>
      <cb-view-section type="secondary" :withFlushTop="true" v-if="upload">
        <savage-form-validated-container
          fieldName="content-container"
          :customValidation="buildFileRequiredValidation(upload.validation.minFileCount.message)"
          :value="uploadedFiles"
          >
          <cb-file-upload name="uploadfiles"
            @input="updateFiles"
            v-model="uploadedFiles"
            :upload-url="upload.url"
            :max-files="upload.validation.maxFileCount.value"
            :max-file-size-mb="upload.validation.maxFileSizeMB.value"
            :max-file-size-mb-error-message="upload.validation.maxFileSizeMB.message"
            :allowed-content-types="upload.validation.allowedContentTypes.value"
            :allowed-content-types-error-message="upload.validation.allowedContentTypes.message"
            :drop-area-label="upload.message"
            :button-label="upload.label"
            :view-label="upload.viewLabel"
            :delete-label="upload.deleteLabel">
          </cb-file-upload>
        </savage-form-validated-container>
      </cb-view-section>
    </template>
  </forms-builder>
</template>

<script>
import FormsBuilder from 'forms/forms-builder.vue';
import { SavageFormTextInput, SavageFormMixin, SavageFormValidatedContainer } from '@clickboarding/savage';
import CbFileUpload from 'common/components/cb-file-upload.vue';
import CbViewSection from 'general/cb-view-section.vue';

export default {
  name: 'form-policy-agreement',
  components: {
    FormsBuilder,
    SavageFormTextInput,
    CbFileUpload,
    CbViewSection,
    SavageFormValidatedContainer
  },
  mixins: [
    // provides the validation directive required by 
    // savage to validate the slot content of validated-container
    SavageFormMixin
  ],
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    onBack: {
      type: Function,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      upload: null, // labels / validation for the upload component
      uploadedFiles: null
    }
  },
  methods: {
    formsBuilderOnLoad (responseBody) {
      const form = responseBody.form;
      this.upload = responseBody.upload;

      if (form.contentPath) {
        this.uploadedFiles = [{
          contentFilename: form.contentFilename,
          contentPath: form.contentPath,
          contentType: form.contentType,
          contentIsNew: form.contentIsNew
        }]
      } else {
        this.uploadedFiles = []
      }
    },
    formsBuilderBeforeSave (form) {
      form.contentPath = this.uploadedFiles[0].contentPath;
      form.contentIsNew = this.uploadedFiles[0].contentIsNew;
      form.contentFilename = this.uploadedFiles[0].contentFilename;
    },
    updateFiles(files) {
      this.$forceUpdate();
    },
    buildFormNameValidation (props) {
      return {
        required: {
          value: true,
          label: props.formLabels.nameLabel,
          message: props.validationMessages.required
        },
        maxlength: {
          value: 100,
          label: props.formLabels.nameLabel,
          message: props.formLabels.nameLabel + ' must be 100 characters or less.'
        }
      };
    },
    buildFormTitleValidation (props) {
      return {
        maxlength: {
          value: 100,
          label: props.formLabels.titleLabel,
          message: props.formLabels.titleLabel + ' must be 100 characters or less.'
        }
      };
    },
    buildFileRequiredValidation (message) {
      return {
        'has-file-uploaded' : {
          value: this.uploadedFiles != null && this.uploadedFiles.length > 0,
          message: message
        }
      };
    }
  }
}
</script>
