<template>
  <div>
    <cb-list class="step-tile-activities-list" :items.sync="localActivities" :canReorder="true" :canReorderItem="canReorderItem" :onReorderComplete="onReorderComplete">
      <template slot="icon" slot-scope="listProps">
        <svg>
          <use v-bind="{'xlink:href': getIcon(listProps.item) }" />
        </svg>
      </template>
      <template slot-scope="listProps">
        <div class="step-tile-activities-list-identifier">
            {{getDisplayName(listProps.item)}}
        </div>
        <div class="step-tile-activities-list-action-text" :title="getActionTextTitle(listProps.item)">
            {{getLimitedActionText(listProps.item)}}
        </div>
      </template>
      <template slot="action" slot-scope="listProps">
        <action-menu :disabled="!canEdit(listProps.item)" :toggle-orientation="'portrait'" :menu-position="'middle-left'" :items="getActionMenuItems(listProps.item)" />
      </template>
    </cb-list>
    <b-modal
      :id="modalId"
      hide-footer
      scrollable
      no-close-on-backdrop
      :modal-class="['full-screen-modal', 'no-padding-modal','slate-background-modal']"
      @hide="activePreview = null">
      <activity-preview ref="preview" v-if="activePreview" :activePreview="activePreview" />
    </b-modal>
  </div>
</template>

<script>
  import ActionMenu from 'common/components/action-menu.vue';
  import CbList from 'common/components/cb-list.vue';
  import ActivityPreview from 'processes/steps/activities/activity-preview.vue';
  import HrFormSvg from 'icons/hr-team-form-sequential.svg';
  import HrFormLockedSvg from 'icons/hr-team-form-sequential-locked.svg';
  import CandidateFormSvg from 'icons/candidate-form-sequential.svg';
  import CandidateFormLockedSvg from 'icons/candidate-form-sequential-locked.svg';
  import ProcessFlowSvg from 'common/assets/icons/general/clipboard-pencil-icon.svg';
  import EmailSvg from 'processes/steps/activities/notification/email.svg'
  import TaskSvg from 'processes/steps/activities/task/task.svg';
  import PreviewSvg from 'processes/steps/activities/preview.svg';
  import uuid from 'uuid/v4';

  export default {
    name: 'step-tile-activities',
    components: {
        ActionMenu,
        CbList,
        ActivityPreview
    },
    props: {
      activities: {
        type: Array,
        required: true
      },
      parentId: {
        type: Number,
        required: true
      },
      onActivityReorder: {
        type: Function,
        required: true
      },
      onActivityDeleteSend: {
        type: Function,
        required: true
      },
      onActivityEdit: {
        type: Function,
        required: true
      }
    },
    beforeCreate () {
      this.candidateFormIcon = '#' + CandidateFormSvg.id;
      this.candidateFormLockedIcon = '#' + CandidateFormLockedSvg.id;
      this.hrFormIcon = '#' + HrFormSvg.id;
      this.hrFormLockedIcon = '#' + HrFormLockedSvg.id;
      this.processFlowIcon = '#' + ProcessFlowSvg.id;
      this.notificationIcon = '#' + EmailSvg.id;
      this.taskIcon = '#' + TaskSvg.id;
      // This component is used in a list of similar components.  In order
      // to open the correct modal, we need to give it a unqiue id so that
      // the $bvModal.show() function knows which modal to open
      this.modalId = `step-tile-activities-preview-modal-${uuid()}`;
    },
    computed: {
      localActivities: {
        get () {
          return this.activities;
        },
        set (v) {
          this.$emit('update:activities', v)
        }
      }
    },
    methods: {
      canReorderItem(item) {
        return !item.isSealed;
      },
      async onReorderComplete (event) {
        if(event.newIndex === event.oldIndex) return;
        let itemUrl = event.item.reorder.url;
        let itemIndex = event.newIndex + 1;
        
        await this.onActivityReorder(itemUrl, itemIndex, this.parentId);
      },
      getActionMenuItems (item) {
        let items = item.actions.map(action => {
          if (action.typeEnum === 'Edit') {
            return {
              icon: 'Action-Edit-Icon',
              label: action.label,
              action: () => { this.onActivityEdit(action); }
            };
          }
          else if (action.typeEnum === 'Delete') {
            return {
              icon: 'Action-Delete-Icon',
              label: action.label,
              action: () => { this.onActivityDeleteSend(this.parentId, item.displayName, action); }
            };
          }
          else if (action.typeEnum === 'Preview') {
            return {
              icon: PreviewSvg.id,
              label: action.label,
              action: () => { 
                this.activePreview = action; 
                this.$bvModal.show(this.modalId);
              }
            };
          }
        });

        return items;
      },
      getActionTextTitle(activity) {
        let actionText = this.getActionText(activity);

        if(actionText.length > 100) {
          return actionText;
        }
        else {
          return null;
        }
      },
      getLimitedActionText(activity) {
        let actionText = this.getActionText(activity);

        if(actionText.length > 100) {
          return actionText.substring(0, 97) + '...';
        }
        else {
          return actionText;
        }
      },
      getActionText(activity) {
        switch(activity.typeEnum) {
          case 'Notification': 
            return this.$t('processBuilder.activities.notification.actionText', {recipient: this.getNotificationRecipientText(activity.recipients, activity.isCandidate)});
          case 'Task':
            return this.$t('processBuilder.activities.task.actionText', {assignTo: this.getNotificationRecipientText([activity.assignTo], false)});
          case 'Form':
            return this.$t('processBuilder.activities.task.actionText', {assignTo: this.getNotificationRecipientText([activity.assignTo], activity.isCandidate)});
          case 'RetainTouchpoint':
            return this.$t('processBuilder.activities.task.actionText', {assignTo: this.getNotificationRecipientText([activity.assignTo], activity.isCandidate)});
          default:
            return `${activity.assignTo.label}: ${activity.assignTo.value}`;
        }
      },
      getNotificationRecipientText(recipients, isCandidate) {
        if(recipients.length === 1) {
          let recipient = recipients[0];
          switch(recipient.typeEnum) {
            case 'EmailAddress':
              return recipient.emailAddress;
            case  'Candidate':
              return this.$t('processBuilder.activities.notification.recipientTypeEnumMap.Candidate');
            case 'Role':
              return this.$t(`processBuilder.activities.notification.roleTypeEnumMap.${recipient.role}`);
            case 'User':
            case 'UserWithAccessGrant':
              return recipient.userName;
            case 'None':
              return isCandidate 
                ? this.$t('processBuilder.activities.notification.recipientTypeEnumMap.Candidate') 
                : this.$t('processBuilder.activities.notification.recipientTypeEnumMap.HrTeam');
          }
        }
        else {
          return this.$t('processBuilder.activities.notification.multipleRecipients');
        }
      },
      getIcon(activity) {
        switch(activity.typeEnum) {
          case 'Process':
            return this.processFlowIcon;
          case 'Notification':
            return this.notificationIcon;
          case 'Task':
            return this.taskIcon;
          case 'Form': {
            if(activity.isCandidate) {
              if(activity.isSealed)
                return this.candidateFormLockedIcon;
              else 
                return this.candidateFormIcon;
            }
            else {
              if(activity.isSealed)
                return this.hrFormLockedIcon;
              else
                return this.hrFormIcon;
            }
          }
        }
      },
      getDisplayName(activity) {
        //notifications can be deleted even if they in a process.
        //In that case, it won't have a name returned from the api.
        if(activity.typeEnum === 'Notification') {
          return activity.displayName || this.$t('processBuilder.activities.add.activityTypeEnumMap.Notification.deleted')
        }
        else {
          return activity.displayName;
        }        
      },
      canReorder(activity) {
        return !activity.isSealed;
      },
      canEdit(activity) {
        return !activity.isAssignmentLocked || !activity.isSkipLogicLocked;
      }
    },
    data () {
      return {
        activePreview: null
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .step-tile-activities-list-identifier,
  .step-tile-activities-list-action-text {
    word-break: normal;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  
  .step-tile-activities-list /deep/ {
    // all immediate children selector is used
    // to avoid referencing cb-list specific
    // class or element types, helping to decouple 
    // this component from cb-list
    > * {
      padding: .5rem !important;
    }
  }
</style>
