import { AxiosLocal as axios } from "common/services/axiosLocal.service";
import { each, map } from "lodash";
import dateFns from "date-fns";
import actionCenterUrlHelper from './actionCenterUrlHelper';
import candidateUrlHelper from './candidateUrlHelper';

const pageSize = 50;
const filterLists = { 'foo': 'bar' };
const filterIdColumns = { 'processDueDate': 'dueDateId' };
//Can be a filterObj or filterModel
function transformAGFilter(filterObj) {
    //transform filters into an object from dictionary oriented shape
    let filters = new Array();
    let actionCenterAGServerSideRequestPto = {
        startRow: 0,
        endRow: pageSize,
        filterModel: [],
        sortModel: [],
        groupModel: [],
        rowGroupColumns: [],
        groupKeys: []
    };
    if (filterObj) {
        console.log(filterObj);
        actionCenterAGServerSideRequestPto.startRow = filterObj.startRow || 0;
        actionCenterAGServerSideRequestPto.endRow = filterObj.endRow || pageSize;
        actionCenterAGServerSideRequestPto.skip = filterObj.startRow > 0 ? filterObj.startRow : 0;
        actionCenterAGServerSideRequestPto.take = filterObj.endRow ? (filterObj.endRow - filterObj.startRow) : pageSize;
        actionCenterAGServerSideRequestPto.sortModel = filterObj.sortModel;
        if (filterObj.rowGroupCols) {
            var groups = filterObj.rowGroupCols.map(function (obj) {
                return obj.id;
            }
            );
        }
        actionCenterAGServerSideRequestPto.groupModel = groups;
        actionCenterAGServerSideRequestPto.rowGroupColumns = filterObj.rowGroupCols;
        actionCenterAGServerSideRequestPto.groupKeys = filterObj.groupKeys;

        const filterModelTemp = filterObj.filterModel || filterObj;
        if (filterModelTemp) {
            each(filterModelTemp, (value, key) => {
                let curFilter = { "colName": key, filter: value };
                if (filterIdColumns[key] && value.values && value.values.length > 0) {
                    let a = filterLists[key];
                    //translate set list from string to ID
                    let ids = value.values.map(x => a.find(y => y.name == x || y.id == x).id)
                    curFilter.filter.values = ids;
                    curFilter.colName = filterIdColumns[key];
                }
                filters.push(curFilter);
            });
            actionCenterAGServerSideRequestPto.filterModel = filters;
        }
    }
    return actionCenterAGServerSideRequestPto;
}

export default {
    getPageSize() { return pageSize },
    async getData(filterObj) {
        var reportingUrl = await actionCenterUrlHelper.getDataUrl();
        let reportingAGServerSideRequestPto = transformAGFilter(filterObj);
        const response = await axios.post(reportingUrl, reportingAGServerSideRequestPto);
        return response.data;
    },
    async getPersonalInfo(candidateId) {
        var candidateInfoUrl = await candidateUrlHelper.getCandidateInfo(candidateId);
        const response = await axios.get(candidateInfoUrl);
        return response.data;
    },
    async getFilteredList(fm, field) {
        let reportingAGServerSideRequestPto = transformAGFilter(fm);
        var reportingUrl = await actionCenterUrlHelper.getFilteredListByColumn(field);
        const response = await axios.post(reportingUrl, reportingAGServerSideRequestPto);
        return map(response.data, (x) => {
            return x.name || x;
        });
    },
    async getFilteredIdList(fm, field) {
        let reportingAGServerSideRequestPto = transformAGFilter(fm);
        var reportingUrl = await actionCenterUrlHelper.getFilteredListByColumn(field);
        const response = await axios.post(reportingUrl, reportingAGServerSideRequestPto);
        return response.data;
    },
    async getCandidateTask(candidateId, candidateProcessId, taskId) {
        var candidateTaskUrl = await actionCenterUrlHelper.getCandidateTaskUrl(candidateId, candidateProcessId, taskId);
        const response = await axios.get(candidateTaskUrl);
        return response.data;
    },
    async getCandidateTaskUrl(candidateId, candidateProcessId, taskId) {
        var candidateTaskUrl = await actionCenterUrlHelper.getCandidateTaskUrl(candidateId, candidateProcessId, taskId);
        return candidateTaskUrl;
    },
    async getCandidateFormUrl(candidateId, candidateProcessId, formId) {
        var candidateActionUrl = await actionCenterUrlHelper.getCandidateFormUrl(candidateId, candidateProcessId, formId);
        return candidateActionUrl;
    },
    async getCandidateI9eVerifyUrl(candidateId, candidateProcessId, formId) {
        var candidateActionUrl = await actionCenterUrlHelper.getCandidateI9eVerifyUrl(candidateId, candidateProcessId, formId);
        return candidateActionUrl;
    },
    async getCurrentStepDetailUrl(candidateId, subjectRequisitionId) {
        var candidateActionUrl = await actionCenterUrlHelper.getCurrentStepDetailUrl(candidateId, subjectRequisitionId);
        return candidateActionUrl;
    },
    async getExport(filterObj, colOrder, downloadType) {
        var candidateActionUrl = await actionCenterUrlHelper.getExport(downloadType);
        let reportingAGServerSideRequestPto = transformAGFilter(filterObj);
        reportingAGServerSideRequestPto = { ...reportingAGServerSideRequestPto, colOrder };
        return await axios.post(candidateActionUrl, reportingAGServerSideRequestPto, { responseType: 'blob' });
    },
    async getBuildNewAdminUiMagicLinkUrl() {
        var newAdminUiMagicLink = await actionCenterUrlHelper.getBuildNewAdminUiMagicLinkUrl();
        const response = await axios.get(newAdminUiMagicLink);
        return response.data;
    },
    getDefaultColumnDef() {
        //later if needed this can be report specific by passing in report type
        return {
            flex: 1,
            sortable: true,
            filter: true,
            resizable: true,
            enableRowGroup: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }
    },
    buildTextFilterParams(filterParams = {}) {
        return this.getAgFilterParamDefs({ filter: 'agTextColumnFilter', filterParams: filterParams })
    },
    buildSetFilterParams({ filterParams, field, bUseIdField }) {
        return this.getAgFilterParamDefs({ filter: 'agSetColumnFilter', filterParams: filterParams, field: field });
    },
    buildSetIdFilterParams({ filterParams, field, bUseIdField }) {
        return this.getAgFilterParamDefs({ filter: 'agSetColumnIdFilter', filterParams: filterParams, field: field });
    },
    buildDateFilterParams(filterParams = {}) {
        return this.getAgFilterParamDefs({ filter: 'agDateColumnFilter', filterParams: filterParams });
    },
    filterFormatter(param) {
        return param.data.name;
    },
    getAgFilterParamDefs({ filter, filterParams, field }) {
        const filterTypes = {
            'agTextColumnFilter': {
                filter: filter,
                filterParams: {
                    ...filterParams,
                    suppressAndOrCondition: true,
                    buttons: ['clear'],
                    filterOptions: ['contains']
                }
            },
            'agSetColumnFilter': {
                filter: filter,
                filterParams: {
                    ...filterParams,
                    values: async params => {
                        const fm = params.api.getFilterModel();
                        let list = await this.getFilteredList(fm, field);
                        params.success(list);
                    },
                    applyMiniFilterWhileTyping: true,
                    buttons: ['clear'],
                    refreshValuesOnOpen: true
                }
            },
            'agSetColumnIdFilter': {
                filter: 'agSetColumnFilter',
                filterParams: {
                    ...filterParams,
                    values: async params => {
                        const fm = params.api.getFilterModel();
                        let list = await this.getFilteredIdList(fm, field);
                        filterLists[field] = list;
                        params.success(list.map(x => x.name || x));
                    },
                    applyMiniFilterWhileTyping: true,
                    buttons: ['clear'],
                    refreshValuesOnOpen: true
                }
            },
            'agDateColumnFilter': {
                filter: filter,
                filterParams: {
                    ...filterParams,
                    buttons: ['clear'],
                    suppressAndOrCondition: true,
                }
            }
        }
        return filterTypes[filter];
    },
}

window.formatDate = function formatDate(dateString) {
    if (dateString)
        return dateFns.format(dateString, 'MM/DD/YYYY');
    else return null;
}