<template>
  <section class="vertical-flex">
    <template v-if="apiData">
      <div class="d-flex flex-row">
        <cb-view-header class="vertical-flex-min w-50" :title="$t('candidate.viewCandidateHeading')" :return-link="returnLink" />
        <div id="header-button" class="w-50 margin-lrgplus-T margin-lrg-R text-right" >

          <div v-if="this.apiData.isTest">
            <div v-if="newUIImpersonateEnabled">
              <b-dropdown  id="dropdown-1" right variant="outline-green" text="Impersonate" class="small-button">
                  <div class="narrow-dropdown">
                  <b-dropdown-item @click="magicLinkClicked('candidate')">Current UI</b-dropdown-item>
                  <b-dropdown-item @click="magicLinkClicked('candidateNew')">New UI</b-dropdown-item>
                  </div>
              </b-dropdown>
            </div>
            <div v-else>
              <b-button class="small-button ml-3" variant="outline-green" @click="magicLinkClicked('candidate')">
                Impersonate
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <b-card-group class="primary-section vertical-flex-fill">
        <b-card>
          <b-card-primary-section-header slot="header" :title="$t('candidate.accountInformationHeading')" />
          <cb-readonly-data-list :items="readonlyAccountInformation">
            <template v-slot:value="{ item, value }">
              <template v-if="item.type === 'password'">
                <template v-if="value !== true">
                  <b-link :aria-describedby="resetPasswordLinkInstructionsId" :class="['b-link', 'with-icon']" href="javascript:void(0)" @click="showSendPasswordResetEmailModal">
                    <b-icon icon="envelope" aria-hidden="true" />{{$t('candidate.accountInformationFields.sendResetLabel')}}
                  </b-link>
                  <div class="reset-password-instructions-container"><b-form-text :id="resetPasswordLinkInstructionsId">{{$t('candidate.accountInformationFields.sendResetDescription')}}</b-form-text></div>
                </template>
                <span v-else>
                  {{$t("candidate.accountInformationFields.candidateIsSso")}}
                </span>
              </template>
            </template>
          </cb-readonly-data-list>
        </b-card>
        <b-card>
          <b-card-primary-section-header slot="header" :title="$t('candidate.personalInformationHeading')">
            <b-link v-if="canEditPersonalInformation" slot="additional-content" slot-scope="{ variant }" :class="['b-link', 'with-icon', `text-${variant}`]" href="javascript:void(0)" @click="showEditPersonalInfoModal">
              <b-icon icon="pencil" aria-hidden="true" />{{$t('common.edit')}}
            </b-link>
          </b-card-primary-section-header>
          <cb-readonly-data-list :items="readOnlyPersonalInformation" />
        </b-card>
        <b-card no-body>
          <b-card-primary-section-header slot="header" :title="$t('candidate.processFlowsHeading')">
            <b-button slot="additional-content"
                v-if="canAddProcesses"
                slot-scope="{ variant }"
                :variant="variant"
                :class="['b-link']"
                href="javascript:void(0)"
                @click="showAddProcessFlow">
              {{$t("candidate.addProcessFlowButtonLabel")}}
            </b-button>
          </b-card-primary-section-header>
          <b-table sort-icon-left hover small :head-variant="null"
                               :items="apiData.processFlows"
                               :fields="processFlowTableFields"
                               :show-empty="true"
                               sort-by="dueDate"
                               :sort-desc="false"
                               :empty-text="$t('candidate.noProcessFlowsAssignedLabel')">
            <template v-slot:cell(status)="{ item }">
              {{item.status.label}}
            </template>
            <template v-slot:cell(view)="{ item }">
              <b-link href="javascript:void(0)" @click="viewProcess(item.candidateProcessId)">{{$t('common.view')}}</b-link>
            </template>
          </b-table>
        </b-card>
        <b-modal
          :id="$id('edit-personal-info-modal')"
          v-if="canEditPersonalInformation"
          size="lg"
          static
          lazy
          no-close-on-backdrop
          centered
          hide-footer
          :title="$t('candidate.editPersonalInformationHeading')"
          @hidden="resetEditPersonalInfoModal">
            <template v-slot="{ cancel }">
              <validation-observer slim v-slot="{ handleSubmit }">
                <b-form novalidate @submit.prevent.stop="handleSubmit(submitEditPersonalInfoModal)">
                  <cb-primary-message />
                  <candidate-personal-info-inputs
                    :firstName.sync="editPersonalInfoModel.firstName"
                    :middleName.sync="editPersonalInfoModel.middleName"
                    :lastName.sync="editPersonalInfoModel.lastName"
                    :email.sync="editPersonalInfoModel.email"
                    :phone.sync="editPersonalInfoModel.phone"
                    :ssoIdentifier.sync="editPersonalInfoModel.ssoIdentifier"
                    :sourceSystemId.sync="editPersonalInfoModel.sourceSystemId"
                    :showSourceSystemId="showSourceSystemId"
                    :isTest="editPersonalInfoModel.isTest"
                    :lockIsTest="true"
                    >
                  </candidate-personal-info-inputs>
                  <div class="modal-footer p-0 pt-3 mt-3">
                    <b-button variant="secondary" @click="cancel">{{$t('common.cancel')}}</b-button>
                    <b-button type="submit" variant="primary">{{$t('common.update')}}</b-button>
                  </div>
                </b-form>
            </validation-observer>
            </template>
        </b-modal>
        <b-modal
          :id="$id('add-process-modal')"
          v-if="canAddProcesses"
          size="lg"
          static
          lazy
          no-close-on-backdrop
          centered
          hide-footer
          :title="$t('candidate.addProcessFlowHeading')"
          @hidden="resetAddProcessModel">
            <template v-slot="{ cancel }">
              <validation-observer slim v-slot="{ handleSubmit }">
                <b-form novalidate @submit.prevent.stop="handleSubmit(submitAddProcessModal)">
                  <cb-primary-message />
                  <candidate-process-inputs
                    :isLocationRequired="true"
                    :locationId.sync="addProcessModel.locationId"
                    :processId.sync="addProcessModel.processId"
                    :candidateDueDate.sync="addProcessModel.candidateDueDate"
                    :dueDate.sync="addProcessModel.dueDate"
                    :recruiterUserId.sync="addProcessModel.recruiterUserId"
                    :hiringManagerUserId.sync="addProcessModel.hiringManagerUserId"
                    :coordinatorUserId.sync="addProcessModel.coordinatorUserId" />
                  <div class="modal-footer p-0 pt-3 mt-3">
                    <b-button variant="secondary" @click="cancel">{{$t('common.cancel')}}</b-button>
                    <b-button type="submit" variant="primary">{{$t('common.add')}}</b-button>
                  </div>
                </b-form>
              </validation-observer>
            </template>
          </b-modal>
        <b-modal
          :id="$id('send-password-reset-modal')"
          v-if="canEditPersonalInformation"
          size="lg"
          static
          lazy
          no-close-on-backdrop
          centered
          @ok="submitPasswordResetModal"
          :ok-title="$t('common.submit')"
          :title="$t('candidate.resetPasswordMessage.title')">
              <cb-primary-message />
              <b-alert show variant="warning" class="with-icon w-100 resetPasswordWarning">
                <b-icon aria-hidden="true" icon="alert-triangle" />
                <div>
                  <span class="font-weight-bold">{{$t('candidate.resetPasswordMessage.warningEmphasis')}}</span><br/>
                  {{$t('candidate.resetPasswordMessage.warningExample')}}
                </div>
              </b-alert>
          </b-modal>
      </b-card-group>
    </template>
  </section>
</template>

<script>
import CbViewHeader from 'general/cb-view-header.vue';
import CbPrimaryMessage from "components/cb-primary-message.vue";
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import { BCardPrimarySectionHeader } from '@clickboarding/vue-components';
import candidatesManagementService from "api/candidatesManagementService";
import { mapGetters } from 'vuex';
import AppSettings from 'appSettings';
import CbReadonlyDataList from 'common/components/cb-readonly-data-list.vue';
import CandidatePersonalInfoInputs from 'candidates/candidate-personal-info-inputs.vue';
import CandidateProcessInputs from 'candidates/candidate-process-inputs.vue';
import isnil from "lodash.isnil";
import { dispatchFormSubmit } from 'common/form-helpers';
import uuid from "uuid/v4";
import store from '../store';

export default {
  name: 'candidate-view',
  components: {
    CbViewHeader,
    BCardPrimarySectionHeader,
    CbReadonlyDataList,
    CandidatePersonalInfoInputs,
    CandidateProcessInputs,
    CbPrimaryMessage
  },
  props: {
    candidateId: {
      type: String,
      required: true
    },
    showSourceSystemId: {
      type: Boolean,
      required: false
    },

  },
  watch: {
    apiArgs: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (newVal != null && newVal !== oldVal) {
          await this.refreshApiData();
        }
      }
    },
  },
  created() {
    this.resetPasswordLinkInstructionsId = `resetPasswordLinkInstructions_${uuid()}`;
    this.showSourceSystemId = AppSettings.sourceSystemIdFeatureToggle === "true";
  },
  computed: {
    ...mapGetters(['clientId']),
  
    returnLink () {
      return {
        label: this.$t("candidate.returnToCandidatesList"),
        handler: this.backToCandidatesList
      };
    },
    canEditPersonalInformation () {
      return this.apiData.userPermissions.canEditCandidateDetails;
    },
    canAddProcesses () {
      return this.apiData.userPermissions.canAddProcessesToCandidate;
    },
    readonlyAccountInformation () {
      return [{
        label: this.$t(this.getAccountInformationLabelKey('username')),
        value: this.apiData.username
      },... this.canEditPersonalInformation ? [{
        label: this.$t(this.getAccountInformationLabelKey('password')),
        value: this.apiData.isSso,
        type: 'password'
      }] : []];
    },
    readOnlyPersonalInformation () {
      var rv =[{
        label: this.$t(this.getPersonalInformationLabelKey('firstName')),
        value: this.apiData.firstName
      }, {
        label: this.$t(this.getPersonalInformationLabelKey('middleName')),
        value: this.apiData.middleName
      }, {
        label: this.$t(this.getPersonalInformationLabelKey('lastName')),
        value: this.apiData.lastName
      }, {
        label: this.$t(this.getPersonalInformationLabelKey('emailAddress')),
        value: this.apiData.email
      }, {
        label: this.$t(this.getPersonalInformationLabelKey('phoneNumber')),
        value: this.apiData.phone
      }, {
        label: this.$t(this.getPersonalInformationLabelKey('ssoIdentifier')),
        value: this.apiData.ssoIdentifier
      }];
      if(this.showSourceSystemId) {
        rv.push({
          label: this.$t(this.getPersonalInformationLabelKey('sourceSystemId')),
          value: this.apiData.sourceSystemId
          })
      };
     if(this.apiData.isTest) {
        rv.push({
        label: this.$t(this.getPersonalInformationLabelKey('isTest')),
        value: this.apiData.isTest
          })
      };
      return rv;
    },
    apiArgs () {
      // This computed is used by a watcher to trigger a refresh of the api data when
      // the component is loaded, or when the api parameters change.
      // This negates the need for separate watchers, as well as logic in the 'created'
      // lifecycle hook.
      // This should only return a value once all api arguments are valid
      return (isnil(this.clientId) || isnil(this.candidateId)) ?
        null :
        [ this.clientId, this.candidateId ];

    },
    processFlowTableFields () {
      return [
        { key: 'processFlow', label: this.$t('candidate.processFields.processLabel'), sortable: true },
        { key: 'location', label: this.$t('candidate.processFields.locationLabel'), sortable: true },
        { key: 'candidateDueDate', label: this.$t('candidate.processFields.candidateDueDateLabel'), class: 'date-column', sortable: true },
        { key: 'dueDate', label: this.$t('candidate.processFields.dueDateLabel'), class: 'date-column', thAttr: { 'aria-sort': 'descending' },
          sortable: true, sortDirection: 'desc' },
        { key: 'status', label: this.$t('candidate.processFields.statusLabel'), sortable: true },
        { key: 'view', label: '', class: 'link-column', thAttr: { 'aria-label': this.$t('common.actionsColumnHeaderScreenReader') } }
      ];
    },
    newUIImpersonateEnabled() {
      return store.getters['features/hasNewUiImpersonateFeature']();
    },
  },
  methods: {
    backToCandidatesList () {
      this.$_cb.router.changeView(AppSettings.viewCandidates, null);
    },
    async refreshApiData () {
      const response = await candidatesManagementService.getCandidate(this.clientId, this.candidateId);
      this.apiData = response.candidate;
    },
    getAccountInformationLabelKey (fieldName) {
      return `candidate.accountInformationFields.${fieldName}Label`;
    },
    getPersonalInformationLabelKey (fieldName) {
      return `candidate.personalInfoFields.${fieldName}Label`;
    },
    resetEditPersonalInfoModal () {
      this.editPersonalInfoModel = {};
    },
    showEditPersonalInfoModal () {
      this.editPersonalInfoModel = {
        firstName: this.apiData.firstName,
        middleName: this.apiData.middleName,
        lastName: this.apiData.lastName,
        email: this.apiData.email,
        phone: this.apiData.phone,
        ssoIdentifier: this.apiData.ssoIdentifier,
        sourceSystemId: this.apiData.sourceSystemId,
        isTest: this.apiData.isTest
      };
      this.$bvModal.show(this.$id('edit-personal-info-modal'));
    },
    async submitEditPersonalInfoModal () {
      PrimaryMessageBus.$emit('clear-all-messages');
      let closeModal = true;
      try {
        await candidatesManagementService.putCandidate(...this.apiArgs, this.editPersonalInfoModel);
      } catch (error) { closeModal = false; }

      if (closeModal) {
        await this.refreshApiData();
        this.$bvModal.hide(this.$id('edit-personal-info-modal'));
      }
    },
    showSendPasswordResetEmailModal () {
      PrimaryMessageBus.$emit('clear-all-messages');
      this.$bvModal.show(this.$id('send-password-reset-modal'));
    },
    async submitPasswordResetModal(bvModalEvt) {
      bvModalEvt.preventDefault();
      PrimaryMessageBus.$emit('clear-all-messages');
      let closeModal = false;
      try {
        await candidatesManagementService.postPasswordAndSecurityQuestionResetInvite(...this.apiArgs);
        closeModal = true;
      } catch (error) { closeModal = false; }

      if (closeModal) {
        await this.refreshApiData();
        this.$bvModal.hide(this.$id('send-password-reset-modal'));
        this.$bvToast.toast(this.$t('candidate.resetPasswordMessage.linkSent'), {
          title: this.$t('common.success'),
          autoHideDelay: 5000,
          variant: 'success'
        });
      }
    },
    viewProcess (processId) {
      this.$_cb.router.changeView(AppSettings.viewCandidatesProcessView, { candidateId: this.candidateId, processId: processId }, null);
    },
    resetAddProcessModel () {
      this.addProcessModel = {};
    },
    async submitAddProcessModal () {
      PrimaryMessageBus.$emit('clear-all-messages');
      let closeModal = true;
      try {
        await candidatesManagementService.postCandidateProcess(...this.apiArgs, {
          locationId: this.addProcessModel.locationId,
          processFlowId: this.addProcessModel.processId,
          candidateDueDate: this.addProcessModel.candidateDueDate,
          dueDate: this.addProcessModel.dueDate,
          recruiterId: this.addProcessModel.recruiterUserId,
          hiringManagerId: this.addProcessModel.hiringManagerUserId,
          coordinatorId: this.addProcessModel.coordinatorUserId,
        });
      } catch (error) { closeModal = false; }

      if (closeModal) {
        await this.refreshApiData();
        this.$bvModal.hide(this.$id('add-process-modal'));
      }
    },
    showAddProcessFlow () {
      this.addProcessModel = {
        locationId: null,
        processId: null,
        candidateDueDate: null,
        dueDate: null,
        recruiterUserId: null,
        hiringManagerUserId: null,
        coordinatorUserId: null
      }
      this.$bvModal.show(this.$id('add-process-modal'));
    },
    async magicLinkClicked(linkType){
       window.open(await candidatesManagementService.gotoCandidateUI(...this.apiArgs, linkType), "_blank");
    }
  },
  data () {
    return {
      apiData: null,
      resetPasswordLinkInstructionsId: null,
      editPersonalInfoModel: {},
      addProcessModel: {}
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@clickboarding/style/mixins';

  .reset-password-instructions-container {
    // cb-readonly-data-list reduces font-size
    // and the b-form-text component used for
    // these instructions uses percentage
    // based font-size that is meant to be
    // based off the larger root font size
    @include font-size-reset;
  }
  .narrow-dropdown {
    min-width: 5em;
  }
  #header-button{
    border-bottom: 1px solid $cb-dark-grey-6;
  }
</style>
