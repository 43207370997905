import MenuService from 'general/menu.service';

export default class CandidatesController {
    constructor ($http, $scope, $route, $timeout, locationSvc, appSettings) {
        this.addCandidate = false;
        this.activeNavTitle = MenuService.activeTitle;
    }
}

CandidatesController.$inject = ['$http', '$scope', '$route', '$timeout', 'locationSvc', 'appSettings'];
