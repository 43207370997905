<template>
    <savage-form name="cb-search" :formstate="formstate" :on-submit="search" aria-labelledby="label_search_expand" id="cb-search-expand">
        <savage-form-labeled-field :label="$t('general.searchDrawer.inputLabel')">
          <div class="cb-search-bar-container">
            <input class="input-text cb-search-bar-search-term" type="text" ref="searchTerm" v-model="searchTermModel" :title="$t('general.searchDrawer.inputLabel')">
            <button type="button" class="cb-btn primary" @click="search" :title="$t('general.searchDrawer.searchButtonTitle')">
              <svg class="cb-search-button-icon">
                <use v-bind="{'xlink:href': searchIconSvg }" ></use>
              </svg>
            </button>
          </div>
        </savage-form-labeled-field>
    </savage-form>
</template>
<script>
  import {SavageFormLabeledField, SavageForm} from '@clickboarding/savage';
  import SearchIconSvg from 'general/icons/search.svg';

  export default {
    name: 'cb-search-control',
    components: {
      SavageFormLabeledField,
      SavageForm,
    },
    props: {
      searchTerm: {
        type: String,
        require: false,
        default: ''
      }
    },
    watch: {
        searchTerm: {
            immediate: true,
            handler(newSearchTerm) {
              this.localSearchTerm = newSearchTerm;
            }
        }
    },
    computed: {
      searchIconSvg() {
        return '#' + SearchIconSvg.id;
      },
      searchTermModel: {
        get() {
          return this.localSearchTerm;
        },
        set(newValue) {
          this.localSearchTerm = newValue;
        }
      }
    },
    mounted() {
      this.setFocus();
    },
    methods:{
      search(){
        this.$emit("cb-search", this.localSearchTerm);
      },
      setFocus() {
        this.$refs.searchTerm.focus();
      }
    },
    data() {
      return {
        formstate: {},
        localSearchTerm: null
      }
    }
  }
</script>
<style scoped lang="scss">
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';
  @import 'common/css/components';

  .cb-search-bar-container {
    display:flex;
  }

  .cb-search-bar-container > input.input-text.cb-search-bar-search-term {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .cb-search-bar-container > .cb-btn.primary {
    border-right: 1px solid $cb-dark-grey-4;
    border-top: 1px solid $cb-dark-grey-4;
    border-bottom: 1px solid $cb-dark-grey-4;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
  }

  .cb-search-button-icon {
    height: 1rem;
    width: 1rem;
    color: $cb-white;
  }
</style>
