<template>
  <div>
    <cb-view-title :title="title" :link="link" :subtitle="subtitle" :infoDescription="infoDescription"/>
    <slot></slot>
  </div>
</template>

<script>
    import CbViewTitle from 'common/components/cb-view-title-with-info-tooltip.vue';

export default {
  name: 'cb-admin-view-title-with-info-tooltip',
  components: {
    CbViewTitle
  },
  props: {
    title: {
      type: String,
      required: false
      },
    link: {
      type: String,
      required: false
      },
    infoDescription: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';

  .cb-admin-view-title {
    display: flex;
    text-align: left;
    padding: 0 1.5rem 1.5rem;
  }
</style>
