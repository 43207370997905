<template>
  <section>
    <savage-form 
        name="candidate-portal-form" 
        ref="formInstance" 
        v-if="elements && model" 
        :formstate="formstate" 
        :on-submit="save"
        @submit-started="$emit('submit-started', $event)"
        @submit-completed="$emit('submit-completed', $event)">
      <savage-form-fields
        v-if="elements && model"
        :model="model"
        :elements="elements"
        :formState="formstate"
        :dateLocale="sharedLocaleState.dateLocale"
        @update="val => { model = val }" />
    </savage-form>
  </section>
</template>
<script>
  import FormNavigationService from 'common/forms/form-navigation.service';
  import { SavageForm } from '@clickboarding/savage';
  import SavageFormFields from 'common/savage/savage-form-fields.vue';
  import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
  import CbLocaleStore from "common/components/cb-locale-store";

  export default {
    name: 'savage-form-loader',
    components: {
      SavageForm,
      SavageFormFields
    },
    props: {
      contentUrl: {
        required: true,
        type: String
      },
      eventUrl: {
        required: true,
        type: String
      },
      onNextSuccess: {
        required: false,
        type: Function
      },
      onLoad: {
        required: false,
        type: Function
      }
    },
    destroyed () {
      FormNavigationService.deleteNextClickHandler();
    },
    async mounted () {
      FormNavigationService.setNextClickHandler(() => {
        this.$refs.formInstance.submit();
      });
      
      const response = await this.$http.get(this.contentUrl);
      
      const responseBody = response.body;
      this.elements = responseBody.elements;
      this.model = responseBody.model;
      
      if (this.onLoad) this.onLoad();
    },
    methods: {
      async save () {
        const response = await this.$http.post(this.eventUrl, this.model);
        
        if (this.onNextSuccess) this.onNextSuccess(response.body);
        PrimaryMessageBus.$emit('clear-all-messages');

        return response.body;
      },
    },
    data () {
      return {
        elements: null,
        model: {},
        formstate: {},
        sharedLocaleState: CbLocaleStore.state
      };
    }
  }
</script>
