import FormsContentService from 'forms/forms-content-service';

function get (element, index, editElementHandler, deleteElementHandler) {
  const actionMenuItems = [];
  if(element &&
    element.designer &&
    element.designer.actions &&
    element.designer.actions.length > 0) {
    element.designer.actions.forEach((action) => {
      let actionMenuItem = null;

      if (action.typeEnum === 'Edit') {
        actionMenuItem = {
          label: action.label,
          icon: 'Action-Edit-Icon',
          action: () => {
            editElementHandler({
              component: FormsContentService.getElementTypeDesignerComponent(element.type),
              dataUrl: action.url,
              wrappedElement: {
                index: index,
                element: element
              }
            });
          }
        };
      } else if (action.typeEnum === 'Delete') {
        actionMenuItem = {
          label: action.label,
          icon: 'Action-Delete-Icon',
          action: () => { deleteElementHandler(index); }
        };
      }

      if (actionMenuItem != null)
        actionMenuItems.push(actionMenuItem);
    });
  }
  return actionMenuItems;
};

export { get }