<template>
  <section v-if="form" class="vertical-flex forms-builder-container">
    <cb-view-header class="vertical-flex-min" v-show="showForm" :title="title" :subtitle="form.status.label"
      :return-link="returnLink" />
    <b-alert v-if="hasConditionalFields() && isFormLayoutValid" v-show="showForm" show
      class="element-success-message with-icon" variant="success">
      <b-icon aria-hidden="true" icon="check" />
      The form definition is valid!
    </b-alert>
    <savage-form class="vertical-flex-fill" :class="{ 'vertical-flex': showForm }" v-show="showForm" ref="savageform"
      name="forms-builder" v-disable-form="isReadOnly" :formstate="formstate" :on-submit="saveAndRedirect">
      <cb-view-section class="vertical-flex-min" type="secondary" :includePrimaryMessages="true">
        <forms-builder-identifierinput :label="formLabels.identifierLabel"
          :placeholder="formLabels.identifierPlaceholder" v-model="form.identifier"
          :identifierValidation="identifierValidation" :validationMessages="validationMessages"
          :disabled="!isNewForm" />
        <button type="button" v-if="showPreviewButton" class="cb-btn secondary preview" @click="previewForm()">{{
    preview.label }}</button>
      </cb-view-section>
      <cb-view-section class="vertical-flex-fill" type="primary">
        <slot v-if="form" v-bind="defaultSlotScope" />
        <div class="mt-4 d-flex">
          <button type="button" v-if="back" class="cb-btn primary cancel flex-fill" @click="handleBack()">
            {{ back.label }}
          </button>
          <button type="submit" v-if="submit" class="cb-btn primary save flex-fill">
            {{ submit.label }}
          </button>
        </div>
      </cb-view-section>
    </savage-form>
    <section class="vertical-flex-fill" v-if="shouldShowAddElement">
      <slot name="add-element" :componentProps="addElementProps" />
    </section>
    <section class="vertical-flex-fill" v-if="shouldShowEditElement">
      <slot name="edit-element" :componentProps="editElementProps" />
    </section>
    <b-modal v-model="showPreview" hide-footer scrollable no-close-on-backdrop
      :modal-class="['full-screen-modal', 'no-padding-modal', 'slate-background-modal']">
      <forms-preview v-if="preview" ref="preview" :data-url="preview.url" />
    </b-modal>
    <b-modal v-if="deleteValidationMessage" no-close-on-backdrop centered ok-only v-model="showDeleteValidation">
      {{ deleteValidationMessage }}
    </b-modal>
    <cb-exit-confirmation ref="exitConfirmation" />
  </section>
</template>
<script>
import AppSettings from 'appSettings';
import CbViewSection from 'general/cb-view-section.vue';
import CbViewHeader from 'general/cb-view-header.vue';
import DisableForm from 'common/directives/disable-form';
import { SavageForm } from '@clickboarding/savage';
import FormsPreview from 'forms/forms-preview.vue';
import FormsBuilderIdentifierinput from 'forms/forms-builder-identifierinput.vue';
import CbExitConfirmation from 'common/components/cb-exit-confirmation.vue';
import FormsBuilderBus from 'forms/forms-builder-bus';
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';
import isarray from 'lodash.isarray';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import * as ElementListItemMapper from 'forms/element-list-item-mapper';

export default {
  name: 'forms-builder',
  components: {
    CbViewSection,
    CbViewHeader,
    SavageForm,
    FormsPreview,
    FormsBuilderIdentifierinput,
    CbExitConfirmation
  },
  directives: {
    DisableForm
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    onBack: {
      type: Function,
      required: false
    },
    onLoad: {
      type: Function,
      required: false
    },
    beforeSave: {
      type: Function,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    canPreview: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    defaultSlotScope() {
      return {
        form: this.form,
        hasElements: this.hasElements,
        getElementListItems: this.getElementListItems,
        getElementsForConditionalLogic: this.getElementsForConditionalLogic,
        updateElementsFromList: this.updateElementsFromList,
        formLabels: this.formLabels,
        validationMessages: this.validationMessages,
        validateLayout: this.validateLayout,
        hasConditionalFields: this.hasConditionalFields
      };
    },
    showPreview: {
      get() {
        return this.canPreview && this.preview && this.showPreviewModal;
      },
      set(value) {
        this.showPreviewModal = value;
      }
    },
    showPreviewButton() {
      return this.canPreview && this.preview
    },
    buttonCount() {
      let total = 0;
      if (this.back) total++;
      if (this.submit) total++;
      return total;
    },
    hasOneButton() {
      return this.buttonCount === 1;
    },
    hasTwoButtons() {
      return this.buttonCount === 2;
    },
    showForm() {
      return !this.showPreview
        && !this.shouldShowAddElement
        && !this.shouldShowEditElement
    },
    hasAddElementSlot() {
      return !!this.$scopedSlots['add-element'];
    },
    hasEditElementSlot() {
      return !!this.$scopedSlots['edit-element'];
    },
    shouldShowAddElement() {
      return this.hasAddElementSlot && this.addElementProps !== null;
    },
    shouldShowEditElement() {
      return this.hasEditElementSlot && this.editElementProps !== null;
    },
    childReturnLabel() {
      const fallbackLabel = this.return ? this.return.label : '';
      return (this.form.identifier && this.form.identifier.length) ? this.form.identifier : fallbackLabel;
    },
    addElementReturnLink() {
      return {
        label: this.childReturnLabel,
        handler: this.hideAddElement
      };
    },
    editElementReturnLink() {
      return {
        label: this.childReturnLabel,
        handler: this.hideEditElement
      };
    },
    isNewForm() {
      return !!(this.form && this.form.isNewForm);
    },
  },
  watch: {
    form: {
      handler: function (newValue, oldValue) {
        FormsBuilderBus.updateParentForm(newValue);
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    this.destroyOnUpdateForm = FormsBuilderBus.onUpdateBuilderForm(this.updateForm);
  },
  destroyed() {
    if (this.destroyOnUpdateForm) this.destroyOnUpdateForm();
    PrimaryMessageBus.$emit('clear-all-messages');
  },
  mounted() {
    const getData = this.$http.get(this.dataUrl);
    const handleResponse = (response) => {
      const responseBody = response.body;
      this.preview = responseBody.preview;
      this.form = responseBody.form;
      this.originalForm = clonedeep(this.form);
      this.formLabels = responseBody.formLabels;
      this.validationMessages = responseBody.validationMessages;
      this.identifierValidation = responseBody.identifierValidation;
      this.title = responseBody.title;
      this.submit = responseBody.submit;
      this.back = responseBody.back;
      this.return = responseBody.return;
      this.typeEnum = responseBody.typeEnum

      this.returnLink = {
        label: responseBody.return.label,
        handler: this.handleReturn
      };
      this.showPreviewModal = this.$_cb.route.query.showPreview ? this.$_cb.route.query.showPreview : false;
      return responseBody;
    };

    if (this.onLoad) {
      getData
        .then(handleResponse)
        .then(this.onLoad);
    } else {
      getData
        .then(handleResponse);
    }
  },
  methods: {
    updateForm(newform) {
      this.form = newform;
    },
    isFormDirty() {
      return !isequal(this.form, this.originalForm);
    },
    previewForm() {
      // validate the form
      if (this.$refs.savageform.validate() && (!this.hasConditionalFields || this.validateLayout())) {
        // check to see if view/update/add
        if (this.isReadOnly) {
          this.doShowPreview()
        } else if (this.submit.method === 'POST') {
          // create (add) scenario
          this.createAndPreview()
        } else if (this.isFormDirty()) {
          // update (edit) scenario
          this.updateAndPreview();
        } else {
          // edit without form updates
          this.doShowPreview();
        }
      }
    },
    save() {
      if (this.beforeSave) {
        this.beforeSave(this.form);
      }

      this.cleanupForm();

      return this.submit.method === 'PUT'
        ? this.$http.put(this.submit.url, this.form)
        : this.$http.post(this.submit.url, this.form);
    },
    saveAndRedirect() {
      if (!this.hasConditionalFields || this.validateLayout()) {
        this.save().then((response) => {
          this.$_cb.router.changeView(AppSettings.viewFormsDraftSplashView, response.body.onStatusChanged.url);
        });
      } else {
        return new Error('form layout is invalid');
      }
    },
    updateAndPreview() {
      this.save().then((response) => {
        this.originalForm = clonedeep(this.form);
        this.doShowPreview();
      });
    },
    createAndPreview() {
      this.save().then((response) => {
        this.$_cb.router.changeView(AppSettings.viewFormsEdit, { type: this.typeEnum }, { showPreview: true }, response.body.onEdit.url);
      });
    },
    doShowPreview() {
      this.showPreviewModal = true;
    },
    handleReturn() {
      const returnAction = () => { this.$_cb.router.changeView(AppSettings.viewForms, this.return.url) };
      this.handleExit(returnAction);
    },
    handleBack() {
      if (this.onBack) {
        this.handleExit(this.onBack);
      } else {
        this.handleReturn();
      }
    },
    async handleExit(action) {
      if (this.isFormDirty()) {
        var confirmed = await this.$refs.exitConfirmation.open();
        if (confirmed) {
          action();
        }
      } else {
        action();
      }
    },
    addElements(formElements) {
      this.ensureFormHasRevision();

      const existingElements = this.form.revision.sections[0].pages[0].elements || [];

      this.form.revision.sections[0].pages[0].elements = existingElements.concat(formElements);
    },
    updateElements(editElements) {
      this.ensureFormHasRevision();

      // expecting editElements to be an array of {index: indexOfElementToEdit, element: updatedFormElement}.
      // we clone the elements and update the copy so that we only trigger one update parent form message
      // instead of one per element
      let existingElements = clonedeep(this.form.revision.sections[0].pages[0].elements);

      for (var i = 0, len = editElements.length; i < len; i++) {
        existingElements[editElements[i].index] = editElements[i].element;
      }

      this.form.revision.sections[0].pages[0].elements = existingElements;
    },
    deleteElement(index) {
      this.ensureFormHasRevision();
      // delete validation
      const element = this.form.revision.sections[0].pages[0].elements[index];
      if (element.hasDependencies) {
        this.showDeleteValidation = !this.showDeleteValidation;
        const isSingular = !element.dependentFields.includes(',') && !element.dependentFields.includes('and');
        this.deleteValidationMessage = `${element.dependentFields} ${isSingular ? 'is' : 'are'} conditionally shown based on ${element.label}. You cannot delete ${element.label} until ${isSingular ? 'that' : 'those'} condition${isSingular ? '' : 's'} ${isSingular ? 'is' : 'are'} removed.`;
      } else {
        this.deleteValidationMessage = null;
        this.form.revision.sections[0].pages[0].elements.splice(index, 1);
      }
    },
    ensureFormHasRevision() {
      if (!('revision' in this.form)) {
        console.error('form has no revision available');
      }
    },
    showAddElement(addElementProps) {
      this.saveScrollPosition();

      if (!('returnLink' in addElementProps)) addElementProps.returnLink = this.addElementReturnLink;
      if (!('onComplete' in addElementProps)) addElementProps.onComplete = this.showAddElementCallback;
      if (!('availableConditionalFields' in addElementProps)) addElementProps.availableConditionalFields = this.getElementsForConditionalLogic();

      this.addElementProps = addElementProps;
    },
    showEditElement(editElementProps) {
      this.saveScrollPosition();

      if (!('returnLink' in editElementProps)) editElementProps.returnLink = this.editElementReturnLink;
      if (!('onBack' in editElementProps)) editElementProps.onBack = this.hideEditElement;
      if (!('onComplete' in editElementProps)) editElementProps.onComplete = this.showEditElementCallback;
      if (!('availableConditionalFields' in editElementProps)) editElementProps.availableConditionalFields = this.getElementsForConditionalLogic();

      this.editElementProps = editElementProps;
    },
    hideAddElement() {
      this.addElementProps = null;
      this.resetScrollPosition();
    },
    hideEditElement() {
      this.editElementProps = null;
      this.resetScrollPosition();
    },
    showAddElementCallback(value) {
      // allow for either a single element or an array of elements
      if (!isarray(value)) value = [value];

      this.addElements(value);
      this.hideAddElement();
    },
    showEditElementCallback(value) {
      // allow for either a single element or an array of elements
      if (!isarray(value)) value = [value];

      this.updateElements(value);
      this.hideEditElement();
    },
    saveScrollPosition() {
      this.lastScrollPosition = {
        x: window.scrollX,
        y: window.scrollY
      };
    },
    resetScrollPosition() {
      this.$nextTick(() => {
        window.scrollTo(this.lastScrollPosition.x, this.lastScrollPosition.y);
      });
    },
    hasElements() {
      const page = this.form.revision.sections[0].pages[0];
      return 'elements' in page && page.elements.length > 0;
    },
    getElementListItems() {
      const page = this.form.revision.sections[0].pages[0];
      const condFields = this.getElementsForConditionalLogic();
      return page.elements.map((element, index) => {
        let result = ElementListItemMapper.map(element, index, this.showEditElement, this.deleteElement);
        result.element.hasDependencies = false;
        result.element.dependentFields = '';
        let filterSelfOut = condFields.filter((field) => field.label !== result.element.label);
        if (filterSelfOut) {
          let checkCircularDeps = page.elements.reduce((prev, ele) => (ele.conditionalLogic && ele.conditionalLogic.field && ele.conditionalLogic.field === result.element.label
            && prev.push(ele.label ? ele.label : ele.itemTitle ? ele.itemTitle : ele.title), prev), []);
          if (checkCircularDeps.length > 0) {
            result.element.availableConditionalFields = filterSelfOut.filter((field) => !checkCircularDeps.includes(field.label));
            result.element.hasDependencies = true;
            const formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
            result.element.dependentFields = formatter.format(checkCircularDeps);
          } else {
            result.element.availableConditionalFields = filterSelfOut;
          }
        }
        return result;
      });
    },
    updateElementsFromList(elementListItems) {
      const page = this.form.revision.sections[0].pages[0];
      page.elements = elementListItems.map(elementListItem => elementListItem.element);
    },
    getElementsForConditionalLogic() {
      const page = this.form.revision.sections[0].pages[0];
      const fields = page.elements.filter((element) => element.type === 'Select');
      return !fields ? [] : fields.map((element) => {
        return { label: element.label, options: element.options.map(opt => { return { label: opt.label ? opt.label : opt.value, value: opt.value }; }) };
      });
    },
    cleanupForm() {
      if (this.form.revision && this.form.revision.sections && this.form.revision.sections.length > 0 && this.form.revision.sections[0].pages[0].elements)
        this.form.revision.sections[0].pages[0].elements.forEach((element) => {
          delete element.availableConditionalFields;
          delete element.hasDependencies;
          delete element.dependentFields;
          delete element.errorMessage;
        });
    },
    validateLayout() {
      let isLayoutValid = true;

      if (this.hasConditionalFields()) {
        const page = this.form.revision.sections[0].pages[0];
        let updatedElements = [];
        page.elements.forEach((element, index, elements) => {
          if (index === 0 && element.isConditionallyShown) {
            element.errorMessage = 'The first data field in a form cannot be conditionally shown.';
            isLayoutValid = false;
          } else if (element.isConditionallyShown && element.conditionalLogic && element.conditionalLogic.field && (index + 1) < elements.length) {
            for (let idx = index + 1; idx < elements.length; idx++) {
              let field = elements[idx].label ? elements[idx].label : elements[idx].itemTitle ? elements[idx].itemTitle : elements[idx].title;
              if (element.conditionalLogic.field === field) {
                element.errorMessage = `${element.label ? element.label : element.itemTitle ? element.itemTitle : element.title} must be after ${element.conditionalLogic.field} to be conditionally shown.`;
                isLayoutValid = false;
                break;
              } else {
                element.errorMessage = null;
              }
            }
          } else {
            element.errorMessage = null;
          }
          updatedElements.push(element);
        });

        if (!isLayoutValid) {
          page.elements = updatedElements;
        }
      }

      this.isFormLayoutValid = isLayoutValid;
      return isLayoutValid;
    },
    hasConditionalFields() {
      if (this.form.revision && this.form.revision.sections && this.form.revision.sections.length > 0 && this.form.revision.sections[0].pages[0].elements) {
        const page = this.form.revision.sections[0].pages[0];
        return page.elements.some((element) => element.isConditionallyShown === true);
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      formstate: {},
      preview: null,
      typeEnum: null,
      showPreviewModal: false,
      formLabels: null,
      form: null,
      originalForm: null,
      submit: null,
      back: null,
      return: null,
      validationMessages: null,
      identifierValidation: null,
      title: null,
      returnLink: null,
      addElementProps: null,
      editElementProps: null,
      lastScrollPosition: {
        x: 0,
        y: 0
      },
      deleteValidationMessage: null,
      showDeleteValidation: false,
      isFormLayoutValid: null,
    }
  }
}
</script>
<style scoped lang="scss">
@import '@clickboarding/style/mixins';
@import '@clickboarding/style/colors';

.cb-btn.preview {
  margin-top: 1rem;
  width: 100%;
}

.forms-builder-container {
  @include font-size-reset;
  @include box-sizing-reset;
}

.element-success-message {
  padding: 0.75rem;
  margin: 1rem 1.5rem 0rem;
  font-weight: bold;
}
</style>