import { render, staticRenderFns } from "./cb-primary-message.vue?vue&type=template&id=0d6eaadb&scoped=true&"
import script from "./cb-primary-message.vue?vue&type=script&lang=js&"
export * from "./cb-primary-message.vue?vue&type=script&lang=js&"
import style0 from "./cb-primary-message.vue?vue&type=style&index=0&id=0d6eaadb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d6eaadb",
  null
  
)

export default component.exports