import store from '../store'
import appSettings from "../appSettings";

function baseUrl() {
    let clientId = store.getters.clientId;
    if (!clientId) {
        throw new Error(
            "The clientId parameter is required."
        );
    }
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/ActionCenterRevamped/`;
}

export default {
    async getDataUrl() {
        return `${baseUrl()}GetData`;
    },
    async getDataTaskOnlyUrl() {
        return `${baseUrl()}GetTaskOnlyData`;
    },
    async getFilteredListByColumn(colName) {
        return `${baseUrl()}/filterList/${colName}`;
    },
    async getFilteredTaskOnlyListByColumn(colName) {
        return `${baseUrl()}/FilterTaskOnlyList/${colName}`;
    },
    async getCandidateTaskUrl(candidateId, candidateProcessId, taskId) {
        return `${baseUrl()}Candidates/${encodeURIComponent(candidateId)}/Processes/${encodeURIComponent(candidateProcessId)}/Tasks/${encodeURIComponent(taskId)}`;
    },
    async getCandidateFormUrl(candidateId, candidateProcessId, taskId) {
        return `${baseUrl()}Candidates/${encodeURIComponent(candidateId)}/Processes/${encodeURIComponent(candidateProcessId)}/HrForm/${encodeURIComponent(taskId)}`;
    },
    async getCandidateI9eVerifyUrl(candidateId, candidateProcessId, taskId) {
        return `${baseUrl()}Candidates/${encodeURIComponent(candidateId)}/Processes/${encodeURIComponent(candidateProcessId)}/i9Everify/${encodeURIComponent(taskId)}`;
    },
    async getCurrentStepDetailUrl(candidateId, subjectRequisitionId) {
        return `${baseUrl()}Candidates/${encodeURIComponent(candidateId)}/ProcessFlows/${encodeURIComponent(subjectRequisitionId)}`;
    },
    async getExport(downloadType) {
        return `${baseUrl()}Export/${downloadType}`;
    },
    async getBuildNewAdminUiMagicLinkUrl() {
        return `${baseUrl()}BuildNewAdminUiMagicLink`;
    }
}
