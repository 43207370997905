<template>
    <cb-view-section type="secondary" :withFlushTop="true">
      <span class="group-label">{{$t('processBuilder.activities.add.labels.sendTo')}}</span>
      <section v-savage-grid-columns="2">
        <savage-form-field-wrapper label :fieldName="'candidate-field'">
          <label class="cb-checkbox-container checkbox-label">
            <input
              type="checkbox"
              name="candidate-field"
              v-model="notification.recipients"
              :value="{ typeEnum: 'Candidate' }"
            />
            <span
              class="cb-checkbox"
              :class="{'cb-checkbox-checked': candidate, 'cb-checkbox-unchecked': !candidate }"
            ></span>
            {{getRecipientDisplayText({typeEnum: 'Candidate'})}}
          </label>
        </savage-form-field-wrapper>
        <savage-form-field-wrapper label :fieldName="'recruiter-field'">
          <label class="cb-checkbox-container checkbox-label">
            <input
              type="checkbox"
              name="recruiter-field"
              v-model="notification.recipients"
              :value="{ typeEnum: 'Role', role: 'Recruiter' }"
            />
            <span
              class="cb-checkbox"
              :class="{'cb-checkbox-checked': recruiter, 'cb-checkbox-unchecked': !recruiter }"
            ></span>
            {{getRecipientDisplayText({typeEnum: 'Role', role: 'Recruiter'})}}
          </label>
        </savage-form-field-wrapper>
        <savage-form-field-wrapper label :fieldName="'coordinator-field'">
          <label class="cb-checkbox-container checkbox-label">
            <input
              type="checkbox"
              name="coordinator-field"
              v-model="notification.recipients"
              :value="{ typeEnum: 'Role', role: 'Coordinator' }"
            />
            <span
              class="cb-checkbox"
              :class="{'cb-checkbox-checked': coordinator, 'cb-checkbox-unchecked': !coordinator }"
            ></span>
            {{getRecipientDisplayText({typeEnum: 'Role', role: 'Coordinator'})}}
          </label>
        </savage-form-field-wrapper>
        <savage-form-field-wrapper label :fieldName="'hiringManager-field'">
          <label class="cb-checkbox-container checkbox-label">
            <input
              type="checkbox"
              name="hiringManager-field"
              v-model="notification.recipients"
              :value="{ typeEnum: 'Role', role: 'HiringManager' }"
            />
            <span
              class="cb-checkbox"
              :class="{'cb-checkbox-checked': hiringManager, 'cb-checkbox-unchecked': !hiringManager }"
            ></span>
            {{getRecipientDisplayText({typeEnum: 'Role', role: 'HiringManager'})}}
          </label>
        </savage-form-field-wrapper>
      </section>
      <savage-form-labeled-field label
        :validation="getEmailAddressValidation()">
          <div class="cb-add-email-address-container">
            <savage-form-validated-field
              class="email-address-input"
              :fieldName="'emailAddress'"
              :validation="getEmailAddressValidation()"
              :customValidation="emailCustomValidation">
                <input type="text"
                  name="emailAddress"
                  ref="emailAddress"
                  @keydown.enter.prevent
                  @keyup.enter.prevent="addEmailAddressIfValid"
                  :placeholder="$t('processBuilder.activities.add.placeholders.emailAddress')"
                  :title="$t('processBuilder.activities.add.placeholders.emailAddress')"
                  :maxlength="getEmailAddressValidation().maxLength.value"
                  class="input-text"
                  v-model="emailAddress"
                />
            </savage-form-validated-field>
            <button type="button" 
              class="cb-btn primary"
              @click="addEmailAddressIfValid"
              :title="$t('common.add')">
              <svg class="cb-add-button-icon">
                <use v-bind="{'xlink:href': plusSvg }" ></use>
              </svg>
            </button>
          </div>
      </savage-form-labeled-field>
      <div aria-live="polite">
        <span class="no-recipients-text" v-if="notification.recipients.length === 0">
          <em>{{$t('processBuilder.activities.notification.noRecipients')}}</em>
        </span>
        <cb-tag-list :items="notification.recipients" v-else>
          <template slot-scope="{item}">
            <span class="cb-email-address-tag">
              {{getRecipientDisplayText(item)}}
                <button class="cb-btn" type="button" 
                  @click="removeRecipient(item)" 
                  :title="$t('common.remove')" 
                  :aria-label="$t('processBuilder.activities.notification.ariaRemoveFromListLabel', [item.emailAddress])">
                  <svg class="cb-remove-button-icon">
                    <use v-bind="{'xlink:href': closeSvg }" ></use>
                  </svg>
                </button>
            </span>
          </template>
        </cb-tag-list>
      </div>
    </cb-view-section>
</template>

<script>
import CbViewSection from "general/cb-view-section.vue";
import CbTagList from "components/cb-tag-list.vue";
import PlusSvg from '@clickboarding/assets/icons/plus-icon.svg';
import CloseSvg from '@clickboarding/assets/icons/x-small.svg';
import { compileToFunctions } from 'vue-template-compiler';
import {
  SavageFormFieldWrapper,
  SavageGridColumnsDirective,
  SavageFormMixin,
  SavageFormValidatedField,
  SavageFormLabeledField
} from "@clickboarding/savage";
import emailValidation from "general/email-validation.js";

let createCheckboxComputedGet = function(predicate) {
  return function() {
    let recipient = this.notification.recipients.filter(predicate).shift();
    return recipient ? true : false;
  };
};

export default {
  name: "notification-configure",
  components: {
    CbViewSection,
    CbTagList,
    SavageFormFieldWrapper,
    SavageFormValidatedField,
    SavageFormLabeledField
  },
  directives: {
    SavageGridColumns: SavageGridColumnsDirective
  },
  mixins: [
    // provides the validation directive required by
    // savage to validate the slot content of validated field
    SavageFormMixin
  ],
  created() {
    if (this.notification.referenceIdentifier == null) {
      this.notification.referenceIdentifier = this.notification.value;
    }
    if (!this.notification.recipients) {
      this.$set(this.notification, 'recipients', []);
    }
  },
  model: {
    prop: 'activity'
  },
  props: {
    activity: {
      type: Object,
      required: true
    }
  },
  methods: {
    addEmailAddressIfValid: function() {
      if(this.currentEmailAddressIsValid) {
        this.notification.recipients.push({
          typeEnum: "EmailAddress",
          emailAddress: this.emailAddress
        });
        this.emailAddress = null;
        this.submitAttempted = false;
      }
      else {
        this.submitAttempted = true;

        //re-focus to trigger validation
        this.$refs.emailAddress.blur();
        this.$refs.emailAddress.focus()
      }
    },
    removeRecipient: function(item) {
      this.notification.recipients = this.notification.recipients.filter(recipient => recipient !== item);
    },
    getEmailAddressValidation: function() {
      return {
        maxLength: {
          value: 200,
          message: this.$t('common.validation.maxLengthFormat', [200])
        }
      };
    },
    getRecipientDisplayText(recipient) {
      if(recipient.typeEnum === 'Candidate') {
        return this.$t('processBuilder.activities.notification.recipientTypeEnumMap.Candidate');
      }
      else if(recipient.typeEnum === 'Role') {
        return this.$t(`processBuilder.activities.notification.roleTypeEnumMap.${recipient.role}`);
      }
      else if(recipient.typeEnum === 'EmailAddress') {
        return recipient.emailAddress;
      }
    }
  },
  watch: {
    notification: {
      deep: true,
      handler: function(val, oldVal) {
        this.$emit('input', val);
      }
    }
  },
  computed: {
    notification() {
      return this.activity;
    },
    candidate: {
      get: createCheckboxComputedGet(e => e.typeEnum === "Candidate")
    },
    recruiter: {
      get: createCheckboxComputedGet(
        e => e.typeEnum === "Role" && e.role === "Recruiter"
      )
    },
    coordinator: {
      get: createCheckboxComputedGet(
        e => e.typeEnum === "Role" && e.role === "Coordinator"
      )
    },
    hiringManager: {
      get: createCheckboxComputedGet(
        e => e.typeEnum === "Role" && e.role === "HiringManager"
      )
    },
    emailCustomValidation() {
      let emailAddressValidation = emailValidation(this.emailAddress, this.$t("processBuilder.activities.add.validation.invalidEmail"));

      return {
        'valid-email': {
          label: '', //validation requires a label even though it isn't displayed.
          value: emailAddressValidation['valid-email'].value || !this.submitAttempted, //only invalid if they've attempted to submit.
          message: emailAddressValidation['valid-email'].message
        }
      };
    },
    currentEmailAddressIsValid() {
      return emailValidation(this.emailAddress)['valid-email'].value;
    },
    plusSvg() {
      return '#' + PlusSvg.id;
    },
    closeSvg() {
      return '#' + CloseSvg.id;
    }
  },
  data() {
    return {
      emailAddress: null,
      submitAttempted: false
    }
  }
};
</script>

<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";
@import "css/cb-checkbox";

.group-label {
  font-weight: bold;
  display: inline-block;
  @include font-size-content-root;

  &:not(:first-of-type) {
    margin-top: 1rem;
  }

  margin-bottom: 1rem;
}

.checkbox-label, .no-recipients-text {
  @include font-size-content-root;
}

.email-address-input {
  flex: 1 1;
  margin-bottom: 0;

  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
  }
}

.cb-add-email-address-container {
  display: flex;
  align-items: stretch;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;

}

.cb-btn.primary {
  border: 1px solid $cb-dark-grey-4;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
}

.cb-add-button-icon {
  height: 1rem;
  width: 1rem;
  color: $cb-white;
}

.cb-remove-button-icon {
  height: .5rem;
  width: .5rem;
  display: flex;
  fill: $cb-medium-blue-1;
}

.cb-email-address-tag {
  display: flex;
  align-items: center;

  button {
    margin-left: .25rem;
    margin-right: -.25rem;
    padding: .25rem !important;
    background-color: inherit;
  }
}

</style>
