import { i18n } from 'common/lang-config';

function map (element, index, editElementHandler, deleteElementHandler) {
  return {
    label: element.type,
    // only allow deletion of unsupported elements in the forms builder
    actions: [{
      label: i18n.t('common.delete'),
      icon: 'Action-Delete-Icon',
      action: () => { deleteElementHandler(index); }
    }],
    icon: null
  };
}

export { map }