<template>
  <div>
    <b-button v-if="showButton" class="small-button" style="height: 1.8rem; font-size: 11px" variant="outline-green"
      @click="btnClickedHandler()" :title='title' :id="btnName"><i class="fa fa-download"
        aria-hidden="true"></i></b-button>
    <div v-if="!showButton"></div>
  </div>
</template>

<script>
import ImportStatus from "./importStatus";

export default {
  name: "LogButton",
  mounted() { },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params);
    },
  },
  computed: {
    showButton() {
      if (this.params.colDef.field === 'validationResults') {
        var validationBlobId = this.params.data.validationBlobId;
        switch (this.params.value) {
          case ImportStatus.VALIDATION_READY:
          case ImportStatus.VALIDATION_STARTED:
            return false;
          default:
            if (validationBlobId) {
              return true;
            }
            return false;
        }
      } else if (this.params.colDef.field === 'importReport') {
        switch (this.params.value) {
          case ImportStatus.IMPORT_COMPLETE:
          case ImportStatus.IMPORT_COMPLETE_WITH_ERRORS:
          case ImportStatus.IMPORT_FAILED:
            return true;
          default:
            return false;
        }
      } else {
        return false;
      }

    },
    title() {
      return "Download";
    },
    btnName() {
      if (this.params.colDef.field === 'validationResults') {
        return "Download Validation Results";
      } else if (this.params.colDef.field === 'importReport') {
        return "Download Import Report";
      } else {
        return "Download";
      }
    }
  },
};
</script>