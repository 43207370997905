<template>
  <b-modal
      v-model="modalShow"
      :title="data.title"
      static
      size="sm"
      no-close-on-backdrop
      :ok-title="data.buttonOK"
      :cancel-title="data.buttonClose"
      @ok="okClick"
      @close="closeClick"
      @hide="closeClick"
  >
    <div v-html="data.message"></div>
  </b-modal>
</template>

<script>
export default {
  name: 'cb-modal',
  props: {
    data: {
      required: true,
      type: Object
    },
    okClick: {
      required: true,
      type: Function
    },
    closeClick: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      modalShow: true
    }
  }
}
</script>