<template>
    <section v-if="hasItems" class="action-menu">
      <div class="action-menu-wrapper" v-if="!disabled">
        <svg class="action-menu-toggle" :class="toggleClass" @click="toggleActionMenu" @blur="hideActionMenu">
            <use v-bind="{'xlink:href': toggleIconPath }"></use>
        </svg>
        <ul v-if="menuOpen" class="action-menu-list" :class="menuClass">
            <li class="action-menu-list-item" :key="index" v-for="(item, index) in items" @mousedown="performAction($event, item)">
                <!--
                    span must stay on the same line as the svg closing tag
                    to prevent issue with text underline on hover
                    that is caused by the inline-block styling on the action-menu
                -->
                <svg v-if="item.icon">
                    <use v-bind="{'xlink:href': getMenuItemIconPath(item.icon) }"></use>
                </svg><a data-name-id="item.label">{{item.label}}</a>
            </li>
        </ul>
      </div>
      <div class="action-menu-wrapper" v-if="disabled">
        <svg class="action-menu-toggle disabled" :class="toggleClass">
            <use v-bind="{'xlink:href': toggleIconPath }"></use>
        </svg>
      </div>
    </section>
</template>

<script>
import isFunction from 'lodash.isfunction';
import { IconProvider } from 'common/icon.module';

export default {
  name: 'cb-action-menu',
  props: {
    items: {
        /* Expected Shape: [ { label: 'Menu Item Label', icon: 'Edit||Delete||etc...', action: Function or RouteUrl and optional route params } ] */
        type: Array,
        default: () => []
    },
    toggleOrientation: {
        /* (portrait|landscape) */
        type: String,
        default: "portrait",
        validator (value) {
            return ["portrait", "landscape"].filter(setting => setting === value).length === 1;
        }
    },
    menuPosition: {
        /* (top-left|top-middle|top-right|middle-left|middle-right|bottom-left|bottom-middle|bottom-right) */
        type: String,
        default: "bottom-right",
        validator (value) {
            return ["top-left", "top-middle", "top-right", "middle-left", "middle-right", "bottom-left", "bottom-middle", "bottom-right"].filter(setting => setting === value).length === 1;
        }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    toggleIconPath () {
        return this.toggleOrientation === "portrait"
            ? IconProvider.getPath('Small-Menu-Portrait-Toggle-Icon')
            : IconProvider.getPath('Small-Menu-Landscape-Toggle-Icon');
    },
    toggleClass () {
        let classes = this.toggleOrientation;
        if(this.menuOpen) { classes += ' menu-open'; }
        return classes;
    },
    menuClass () {
        return `${this.toggleOrientation} ${this.menuPosition}`;
    },
    hasItems () {
      return this.items && this.items.length;
    }
  },
  methods: {
    toggleActionMenu () {
      this.menuOpen = !this.menuOpen;
    },
    hideActionMenu () {
      this.menuOpen = false;
    },
    getMenuItemIconPath (icon) {
      return IconProvider.getPath(icon);
    },
    async performAction (event, item) {
      if (isFunction(item.action)) {
        await item.action(event);
      } else {
        let changeViewArgs;

        if (item.routeParams) {
          changeViewArgs = [ item.route, item.routeParams, item.url ];
        } else {
          changeViewArgs = [ item.route, item.url ];
        }

        this.$_cb.router.changeView.apply(null, changeViewArgs);
      }
      this.hideActionMenu();
    }
  },
  data () {
    return {
      menuOpen: false
    };
  }
}
</script>

<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    .action-menu {
        position: relative;
        display: inline-block;
        box-sizing: content-box;
        @include font-size-reset;

        * {
            box-sizing: content-box;
        }
    }

    .action-menu-wrapper {
        @include font-size-content;
    }

    .action-menu-toggle {
        position: relative;
        float: left;
        display: block;
        top: 0; right: 0; bottom: 0; left: 0;
        border: none;
        outline: none;
        margin: auto;
        // this pushes list heights larger than normal
        // cb-list overwrites it to
        padding: 5px;
        cursor: pointer;

        &.landscape {
            height: 4px;
            width: 20px;
        }

        &.portrait {
            height: 20px;
            width: 4px;
        }

        use {
            fill: $cb-medium-blue-1;
        }
        &.disabled {
          cursor: not-allowed !important;
        }
        &.disabled use,
        &.disabled:hover use{
          fill: $cb-light-grey-1;
        }
    }

    .action-menu-toggle:hover,
    .action-menu-toggle.menu-open {
        use {
            fill: $cb-brand-blue-1;
        }
    }

    .action-menu-list {
        position: absolute;
        margin: 0;
        list-style: none;
        white-space: nowrap;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background: $cb-white;
        border: .15em solid $cb-light-grey-1;
        padding: .5em;
        text-align: left;
        z-index: 1000;

        @mixin relative-box-shadow($x, $y) {
            box-shadow: $x $y 1px 0px rgba(0,0,0,.15);
        }

        &.top-left {
            @include relative-box-shadow(-1px, -1px);
        }

        &.top-middle {
            @include relative-box-shadow(0px, -1px);
        }

        &.top-right {
            @include relative-box-shadow(1px, -1px);
        }

        &.middle-left {
            @include relative-box-shadow(-1px, 0px);
        }

        &.middle-right {
            @include relative-box-shadow(1px, 0px);
        }

        &.bottom-left {
            @include relative-box-shadow(-1px, 1px);
        }

        &.bottom-middle {
            @include relative-box-shadow(0px, 1px);
        }

        &.bottom-right {
            @include relative-box-shadow(1px, 1px);
        }

        @mixin action-menu-arrow($side, $offset-type, $offset-value) {
            &:after, &:before {
                /*
                    99.9% instead of 100% seems to eliminate a line
                    that appears at the bottom of the arrow in some
                    zoom levels without messing up the connection
                */
                @if $side == "top" {
                    bottom: 99.9%;
                } @else if $side == "right" {
                    left: 99.9%;
                } @else if $side == "bottom" {
                    top: 99.9%;
                } @else if $side == "left" {
                    right: 99.9%;
                }
                #{$offset-type}: $offset-value;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                box-sizing: content-box;
            }

            &:after {
                border-width: .4em;
                @if $side == "top" {
                    border-bottom-color: $cb-white;
                    margin-left: -.4em;
                } @else if $side == "right" {
                    border-left-color: $cb-white;
                    margin-top: -.4em;
                } @else if $side == "bottom" {
                    border-top-color: $cb-white;
                    margin-left: -.4em;
                } @else if $side == "left" {
                    border-right-color: $cb-white;
                    margin-top: -.4em;
                }
            }

            &:before {
                border-width: .625em;
                @if $side == "top" {
                    border-bottom-color: $cb-light-grey-1;
                    margin-left: -.625em;
                } @else if $side == "right" {
                    border-left-color: $cb-light-grey-1;
                    margin-top: -.625em;
                } @else if $side == "bottom" {
                    border-top-color: $cb-light-grey-1;
                    margin-left: -.625em;
                } @else if $side == "left" {
                    border-right-color: $cb-light-grey-1;
                    margin-top: -.625em;
                }
            }
        }

        $toggle-end-spacing: 32px;
        $toggle-side-spacing: 16px;
        $menu-extension: -5px;

        &.portrait.top-left {
            bottom: 0; right: 0;
            margin-right: $toggle-side-spacing;
            margin-bottom: $menu-extension;
            @include action-menu-arrow('right', top, 80%);
        }

        &.portrait.top-middle {
            bottom: 0; right: 50%;
            transform: translate(50%, 0);
            margin-bottom: $toggle-end-spacing;
            @include action-menu-arrow('bottom', left, 50%);
        }

        &.portrait.top-right {
            bottom: 0; left: 0;
            margin-left: $toggle-side-spacing;
            margin-bottom: $menu-extension;
            @include action-menu-arrow('left', top, 80%);
        }

        &.portrait.middle-left {
            bottom: 50%; right: 0;
            transform: translate(0, 50%);
            margin-right: $toggle-side-spacing;
            @include action-menu-arrow('right', top, 50%);
        }

        &.portrait.middle-right {
            bottom: 50%; left: 0;
            transform: translate(0, 50%);
            margin-left: $toggle-side-spacing;
            @include action-menu-arrow('left', top, 50%);
        }

        &.portrait.bottom-left {
            top: 0; right: 0;
            margin-right: $toggle-side-spacing;
            margin-top: $menu-extension;
            @include action-menu-arrow('right', top, 20%);
        }

        &.portrait.bottom-middle {
            top: 0; right: 50%;
            transform: translate(50%, 0);
            margin-top: $toggle-end-spacing;
            @include action-menu-arrow('top', left, 50%);
        }

        &.portrait.bottom-right {
            top: 0; left: 0;
            margin-left: $toggle-side-spacing;
            margin-top: $menu-extension;
            @include action-menu-arrow('left', top, 20%);
        }

        &.landscape.top-left {
            bottom: 0; right: 0;
            margin-bottom: $toggle-side-spacing;
            margin-right: $menu-extension;
            @include action-menu-arrow('bottom', left, calc(100% - 1.125rem));
        }

        &.landscape.top-middle {
            bottom: 0; right: 50%;
            transform: translate(50%, 0);
            margin-bottom: $toggle-side-spacing;
            @include action-menu-arrow('bottom', left, 50%);
        }

        &.landscape.top-right {
            bottom: 0; left: 0;
            margin-bottom: $toggle-side-spacing;
            margin-left: $menu-extension;
            @include action-menu-arrow('bottom', left, 1.125rem);
        }

        &.landscape.middle-left {
            bottom: 50%; right: 0;
            transform: translate(0, 50%);
            margin-right: $toggle-end-spacing;
            @include action-menu-arrow('right', top, 50%);
        }

        &.landscape.middle-right {
            bottom: 50%; left: 0;
            transform: translate(0, 50%);
            margin-left: $toggle-end-spacing;
            @include action-menu-arrow('left', top, 50%);
        }

        &.landscape.bottom-left {
            top: 0; right: 0px;
            margin-top: $toggle-side-spacing;
            margin-right: $menu-extension;
            @include action-menu-arrow('top', left, calc(100% - 1.125rem));
        }

        &.landscape.bottom-middle {
            top: 0; right: 50%;
            transform: translate(50%, 0);
            margin-top: $toggle-side-spacing;
            @include action-menu-arrow('top', left, 50%);
        }

        &.landscape.bottom-right {
            top: 0; left: 0;
            margin-top: $toggle-side-spacing;
            margin-left: $menu-extension;
            @include action-menu-arrow('top', left, 1.125rem);
        }
    }

    .action-menu-list-item {
        padding: .75em;
        cursor: pointer;
        @include cb-link;

        svg {
            vertical-align: middle;
            height: 16px;
            width: 16px;
            padding-right: 5px;

            use {
                fill: $cb-brand-blue-2;
            }
        }

        span {
            line-height: 16px;
            vertical-align: middle;
        }
    }

    .action-menu-list-item:hover {
        svg use {
            fill: $cb-brand-blue-1;
        }
    }
</style>
