var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('section',{staticClass:"processes"},[_c('cb-admin-view-title',{staticClass:"cb-admin-view-title",attrs:{"title":_vm.data.title}},[_c('b-button',{staticClass:"create-process-button",attrs:{"variant":"green"},on:{"click":_vm.toggleProcessAddModal}},[_vm._v("\n      "+_vm._s(_vm.data.addLabel)+"\n    ")])],1),_c('cb-view-section',{staticClass:"pt-0",attrs:{"type":"secondary"}},[(_vm.data.noData)?_c('div',{staticClass:"text-c margin-lrg",attrs:{"id":"process-list-no-data"}},[_c('h2',[_vm._v(_vm._s(_vm.data.noData.title))]),_c('div',{staticClass:"clipboard-pencil-icon"}),_c('p',{staticClass:"no-data-message"},[_vm._v(_vm._s(_vm.data.noData.message))])]):_vm._e(),_c('div',{staticClass:"toolbar",attrs:{"id":"process-list-add-process"}},[_c('div',{staticClass:"filter-group"},[_c('div',{staticClass:"search-item mr-3"},[_c('b-form-input',{staticClass:"search-input small-input",attrs:{"debounce":500,"placeholder":_vm.$t("processes.filter.placeholder.search")},on:{"update":_vm.resetCurrentPage},model:{value:(_vm.searchCriteria),callback:function ($$v) {_vm.searchCriteria=$$v},expression:"searchCriteria"}})],1),(_vm.processFilterList.length > 1)?_c('multi-select',{attrs:{"options":_vm.processFilterList,"value":_vm.processFilter,"search-placeholder":_vm.$t('common.filterSearchPlaceholder', [_vm.$t('processes.columnHeaders.employeeExperience').toLowerCase()]),"close-on-select":false,"title":_vm.$t('processes.columnHeaders.employeeExperience')},on:{"select":function($event){return _vm.selectProcessFilter($event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
var isSelected = ref.isSelected;
return [_c('b-form-checkbox',{staticClass:"pointer-none",attrs:{"checked":isSelected(option)}}),_c('span',[_vm._v(_vm._s(option))])]}}],null,false,3664405485)}):_vm._e()],1)]),(_vm.processFlowsCount)?_c('b-table',_vm._b({staticClass:"process-list-container new-table",attrs:{"per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage,"sort-by":"name","items":_vm.filteredProcessFlows,"fields":[
      { key: 'name', label: _vm.data.processHeader, thAttr: { 'aria-sort': 'descending' }, sortable: true, sortDirection: 'desc' },
      { key: 'tags', label: _vm.$t('processes.columnHeaders.employeeExperience'), formatter: _vm.tagFormatter, sortable: true },
      { key: 'icons', label: _vm.$t('processes.columnHeaders.event') },
      { key: 'view', label: '', class: 'link-column', thAttr: { 'aria-label': _vm.$t('processes.columnHeaders.actionsScreenReader') } }
    ]},scopedSlots:_vm._u([{key:"cell(icons)",fn:function(ref){
    var item = ref.item;
return [_c('cb-event-icon-display',{attrs:{"events":_vm.itemEvents(item)}})]}},{key:"cell(view)",fn:function(ref){
    var item = ref.item;
return [_c('b-link',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewProcess(item)}}},[_vm._v(_vm._s(_vm.$t('common.view')))])]}}],null,false,1942953369)},'b-table',_vm.tableSettings,false)):_vm._e(),(_vm.error)?_c('div',[_c('p',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.error))])]):_vm._e(),(_vm.showPagination)?_c('div',{staticClass:"pagination-container"},[_c('b-form-group',{staticClass:"per-page-options",attrs:{"label":"Per page","label-for":"per-page-select","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.paginationSettings.pageOptions,"size":"sm"},model:{value:(_vm.itemsPerOnePage),callback:function ($$v) {_vm.itemsPerOnePage=$$v},expression:"itemsPerOnePage"}})],1),_c('b-pagination',_vm._b({attrs:{"total-rows":_vm.processFlowsCount,"per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},'b-pagination',_vm.paginationSettings,false))],1):_vm._e()],1),(_vm.modalShow)?_c('process-add',{attrs:{"data":_vm.data.addEdit},on:{"ok":_vm.handleOk,"close":function($event){_vm.modalShow = false},"cancel":function($event){_vm.modalShow = false},"hide":function($event){_vm.modalShow = false}}},[_vm._v(">\n  ")]):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }