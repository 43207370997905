<template>
  <div :id="`multiselect-container-${id}`" class="multiselect-picker-wrapper position-relative">
    <label v-if="label" :label-for="id">
      {{ label }}
      <span v-if="required" aria-hidden class="text-danger">*</span>
    </label>
    <div :id="id" class="multiselect-picker-button" :class="{ 'full-width': fullWidth, 'error-state': state }"
      @click="togglePicker">
      <p class="multiselect-picker-button-text">{{ buttonText }}</p>
      <div class="multiselect-picker-arrow" :class="{ 'open': showPicker }">
      </div>
    </div>
    <b-popover :target="id" triggers="click" :custom-class="fullWidth ? 'full-width' : ''" :show.sync="showPicker"
      :offset="fullWidth ? 0 : offset" :container="`multiselect-container-${id}`" :placement="placement"
      :ref="`popover${id}`">
      <div class="multiselect-picker-popover" :class="{ 'no-search': hideSearch }" v-on-clickaway="togglePicker">
        <div v-if="!hideSearch" class="multiselect-picker-filter-wrapper">
          <input class="multiselect-picker-filter" :placeholder="searchPlaceholder" @input="debounceSearch" />
        </div>
        <div class="multiselect-picker-list" v-if="filteredOptions.length && useVirtualScroll == true">
          <cb-virtual-scroll :items="filteredOptions">
            <section slot-scope="{ visibleItems }">
              <div class="multiselect-picker-item" v-for="(option, index) in visibleItems" :key="index"
                @click.stop="select(option)">
                <slot name="option" :option="option" :isSelected="isSelected" :index="index">
                  {{ option }}
                </slot>
              </div>
            </section>
          </cb-virtual-scroll>
        </div>
        <div class="multiselect-picker-no-results" v-if="filteredOptions.length <= 0 && useVirtualScroll == true">
          {{ $t('common.multiselect.noSearchResult', [searchValue]) }}
        </div>
        <div class="multiselect-picker-list" v-if="filteredOptionsInfinite.length && useVirtualScroll == false"
          :ref="`multiselectPickerList${id}`">
          <cb-infinite-scroll :items="filteredOptionsInfinite" :min-items-scroll-threshold="totalItemsCount">
            <section slot-scope="{ visibleItems }">
              <div class="multiselect-picker-item" v-for="(option, index) in visibleItems" :key="index"
                @click.stop="select(option)">
                <slot name="option" :option="option" :isSelected="isSelected" :index="index">
                  {{ option }}
                </slot>
              </div>
            </section>
          </cb-infinite-scroll>
          <div v-if="showLoader"><cb-loading height="1.5rem" width="1.5rem" /></div>
        </div>
        <div class="multiselect-picker-no-results"
          v-if="filteredOptionsInfinite.length <= 0 && useVirtualScroll == false">
          {{ $t('common.multiselect.noSearchResult', [searchValueInfiniteScroll]) }}
        </div>
      </div>
    </b-popover>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import { BPopover } from 'bootstrap-vue';
import { CbVirtualScroll } from '@clickboarding/vue-components';
import CbInfiniteScroll from 'common/components/cb-infinite-scroll.vue';
import CbLoading from 'common/components/cb-loading.vue';

export default {
  name: 'MultiSelect',
  mixins: [clickaway],
  model: {
    label: 'value',
    event: 'input'
  },
  components: {
    BPopover,
    CbVirtualScroll,
    CbInfiniteScroll,
    CbLoading
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [Array, String, Object]
    },
    searchBy: {
      type: String
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    state: {
      type: [Boolean, null],
      default: null
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    trackBy: {
      type: String
    },
    searchPlaceholder: {
      type: String
    },
    placement: {
      type: String,
      default: 'bottom'
    },
    dataUrl: {
      type: String
    },
    useVirtualScroll: {
      type: Boolean,
      default: false
    },
    totalItemsCount: {
      type: Number,
      default: 500
    },
    hideSearch: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showPicker: false,
      searchValue: '',
      searchValueInfiniteScroll: '',
      showLoader: false,
      search_timeout: null,
      isChecked: false
    }
  },
  emits: ["atBottomOfList", "clickAwayOnSearch"],
  computed: {
    internalValue() {
      return this.value || this.value === 0
        ? Array.isArray(this.value) ? this.value : [this.value]
        : [];
    },
    valueKeys() {
      if (this.trackBy) {
        return this.internalValue.map(element => element[this.trackBy]);
      } else {
        return this.internalValue;
      }
    },
    filteredOptions() {
      return Array.isArray(this.options) && this.options.filter(option => {
        let optionIdentifier = this.searchBy ? option[this.searchBy] : option;
        return optionIdentifier && optionIdentifier.toLowerCase().includes(this.searchValue.toLowerCase());
      });
    },
    filteredOptionsInfinite() {
      const arr = Array.isArray(this.options) && this.options.filter(option => {
        let optionIdentifier = this.searchBy ? option[this.searchBy] : option;
        return optionIdentifier && optionIdentifier.toLowerCase().includes(this.searchValue.toLowerCase());
      });
      return arr;
    },
    buttonText() {
      if (this.hideSearch) {
        if (this.value && Array.isArray(this.value) && this.value.length > 0) {
          if (this.value.length > 3) {
            return `${this.label}: ${this.value.map(val => { return this.options.find(opt => opt.value === val).label; }).splice(0,3).join(", ")}...`;
          }
          return `${this.label}: ${this.value.map(val => { return this.options.find(opt => opt.value === val).label; }).join(", ")}`;
        } else if (this.value && Array.isArray(this.value) && this.value.length === 0) {
          return `${this.label}`;
        } else if (this.value) {
          return this.value[this.searchBy];
        } else {
          return this.label;
        }
      } else {
        if (this.value && Array.isArray(this.value) && this.value.length > 0) {
          return `${this.title}: ${this.value.length}`;
        } else if (this.value && Array.isArray(this.value) && this.value.length === 0) {
          return `${this.title}: All`;
        } else if (this.value) {
          return this.value[this.searchBy];
        } else {
          return this.title;
        }
      }
    },
    id() {
      return this.title.replace(/[^\w]/g, '').toLowerCase();
    },
    offset() {
      return this.placement === 'bottomright' ? 13 : -59;
    }
  },
  methods: {
    select(option) {
      if (option) {
        this.$emit('select', option);
        if (this.closeOnSelect) {
          this.showPicker = false;
        }
      }
    },
    togglePicker() {
      this.showPicker = !this.showPicker;

      if (this.showPicker && this.hideSearch) {
        let popover = document.getElementsByClassName('multiselect-picker-popover')[0];
        if (popover) {
          popover.classList.add('no-search');
        }
      }

      if (this.showPicker && !this.useVirtualScroll) {
        this.$nextTick(() => {
          let currMultiSelect = `multiselectPickerList${this.id}`;
          this.$refs[currMultiSelect] && this.$refs[currMultiSelect].addEventListener(
            "scroll",
            this.handleScroll,
            true
          );
          this.$emit('clickAwayOnSearch');
        });
      }
    },
    handleScroll() {
      let currMultiSelect = `multiselectPickerList${this.id}`;
      let element = this.$refs[currMultiSelect];
      let isAtBottom = element.scrollHeight - element.scrollTop <= element.clientHeight + 10;
      if (isAtBottom) {
        this.showLoader = false;
        this.$emit('atBottomOfList', this.id);
      }
      else
        this.showLoader = false;
    },
    clearSearchValue() {
      this.searchValue = '';
    },
    isSelected(option, selectBy) {
      const opt = this.trackBy
        ? option[this.trackBy]
        : selectBy ? option[selectBy] : option;
      const _isSlected = this.valueKeys.indexOf(opt) > -1;
      this.isChecked = _isSlected;
      return _isSlected;
    },
    atBottom() {
      // need to make an api call here
    },
    debounceSearch(event) {
      if (this.useVirtualScroll) {
        this.searchValue = event.target.value;
      }
      else {
        clearTimeout(this.search_timeout);
        this.search_timeout = setTimeout(() => {
          this.typing = null;
          this.searchValueInfiniteScroll = event.target.value;
          let searchData = {
            searchValue: this.searchValueInfiniteScroll,
            id: this.id
          };
          this.showLoader = false;
          this.$emit('newSearchValue', searchData);
        }, 900);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.multiselect-picker {
  &-wrapper {
    label {
      font-weight: bold;
    }
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 156px;
    height: 32px;
    background: #ffffff;
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 13px 0 8px;

    &.error-state {
      border-color: #B12737;
    }

    &.full-width {
      width: 100%;
    }

    &.disabled {
      cursor: default;
    }

    &-text {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #454545;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-right: 5px;
      margin: 0;
    }
  }

  &-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;

    border-top: 5px solid #6A6A6A;

    transition: all 0.3s;

    &.open {
      transform: rotate(180deg);
    }
  }

  &-popover {
    height: 276px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &-popover.no-search {
    height: auto;
  }

  &-list {
    width: 100%;
    padding: 0;
    height: 100%;
    overflow: auto;
  }

  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 11px 16px;
    font-size: 14px;
    line-height: 16px;
    color: #454545;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.disabled {
      color: #6f6f6f96;
      pointer-events: none;
    }

    &.selected,
    &:hover {
      background: #F6F6F6;
    }
  }

  &-filter {
    height: 31px;
    background: #FFFFFF;
    border: 1px solid #D3D3D3;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 6px 10px;
    margin: 0.5rem;
    width: calc(100% - 1rem);

    &:focus {
      border: 1px solid #CBD6E0;
      box-shadow: none;
      outline: none;
    }

    &::placeholder {
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 18px;
      color: #6D6D6D;
    }

    &-wrapper {
      position: relative;

      .clear-filter {
        position: absolute;
        top: 14px;
        right: 14px;
        cursor: pointer;
      }
    }
  }

  &-no-results {
    padding-left: 1rem;
    padding-top: 1rem;
    font-family: Lato, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #2D2D2D;
    opacity: 0.9;
  }
}

.popover {
  padding: 0;
  background: #FFFFFF;
  border: 1px solid #D3D3D3;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 296px;

  /deep/ &.full-width {
    width: 100%;
    max-width: 100%;
  }

  /deep/ .popover-body {
    padding: 0;
  }

  /deep/ &>.arrow {
    display: none;
  }
}

.cb-virtual-scroll {
  max-height: 229px;
}

.cb-loader {
  margin: 0
}
</style>
