<template>
  <section>
    <savage-form 
        name="form-review" 
        ref="formInstance" 
        v-if="content" 
        :formstate="formstate" 
        :on-submit="save"
        @submit-started="$emit('submit-started', $event)"
        @submit-completed="$emit('submit-completed', $event)">
      <div v-for="(section, sectionIndex) in content.sections" :key="sectionIndex" class="cb-review-section">
        <h3 class="cb-review-section-title" v-show="content.sections.length > 1">{{section.title}}</h3>
        <div v-for="(group, groupIndex) in section.groups" :key="groupIndex" class="cb-review-group" :class="{'multi': section.groups.length > 1}">
          <h4 class="cb-review-group-title" v-if="group.title">{{group.title}}</h4>
          <div v-for="(row, rowIndex) in group.items" :key="rowIndex">
            <div class="cb-review-item" v-if="isRowHtml(row)">
              <span class="cb-review-item-html-content" v-html="row.value"></span>
            </div>
            <div class="cb-review-item" v-else>
              <span class="cb-review-item-label">{{row.label}}: </span>
              <span class="cb-review-item-value">{{textDisplayValue(row)}}</span>
              <button type="button" class="cb-review-item-button"
                @click="toggleShowValue(row)"
                v-if="row.maskedValue"
                :title="hideShowButtonText(row)"
                :aria-label="hideShowButtonAriaLabel(row)">{{hideShowButtonText(row)}}</button>
            </div>
          </div>
        </div>
        <div v-show="section.groups.length == 0">
          {{content.labels.noDataOnPageLabel}}
          </div>
        <button type="button" class="cb-btn secondary" @click="onGoToPage(sectionIndex)" :title="content.labels.editButtonLabel">{{content.labels.editButtonLabel}}</button>
      </div>
      <section class="cb-review-preview">
        <a href="#" @click.prevent="onPreviewPdf(content.previewPdfUrl)" :title="content.labels.previewDocumentLabel"><strong>{{content.labels.previewDocumentLabel}}</strong></a>
      </section>
      <section v-if="content.eSignature">
        <savage-form-agreement-radio-group
          :agree="content.agree"
          :disagree="content.disagree"
          :signatureImageString="content.eSignature.imageString"
          :signatureDescription="content.eSignature.imageDescription"
          :legal="content.labels.legal"
          v-model="reviewAgreeDisagreeProp"
          :validation="content.validation"
        />
      </section>
    </savage-form>
  </section>
</template>

<script>
import { SavageForm } from '@clickboarding/savage';
import SavageFormAgreementRadioGroup from 'common/savage/savage-form-agreement-radio-group.vue';
import FormNavigationService from 'common/forms/form-navigation.service';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';

export default {
  name: 'form-review',
  components: {
    SavageForm,
    SavageFormAgreementRadioGroup
  },
  props: {
    contentUrl: {
      required: true,
      type: String
    },
    eventUrl: {
      required: true,
      type: String
    },
    onNextSuccess: {
      required: false,
      type: Function
    },
    onLoad: {
      required: false,
      type: Function
    },
    onPreviewPdf: {
      required: true,
      type: Function
    },
    onGoToPage: {
      required: true,
      type: Function
    }
  },
  destroyed () {
    FormNavigationService.deleteNextClickHandler();
  },
  async mounted () {
    FormNavigationService.setNextClickHandler(() => {
      this.$refs.formInstance.submit();
    });

    const response = await this.$http.get(this.contentUrl);
    this.content = response.data;
    if (this.onLoad) await this.onLoad();
  },
  methods: {
    async save () {
      // note that if there is no esignature they do not need to explicitly agree or disagree
      if (!this.content.eSignature || this.reviewAgreeDisagreeProp !== null) {
        const response = await this.$http.post(this.eventUrl, {
            formDetails: this.content.formDetails,
            agreeDisagree: this.reviewAgreeDisagreeProp
          });
        
        if (this.onNextSuccess) await this.onNextSuccess(response.data);
        PrimaryMessageBus.$emit('clear-all-messages');
      }
    },
    textDisplayValue (textReviewItem) {
      const realValue = textReviewItem.value || '-';
      return textReviewItem.showValue
        ? realValue
        : textReviewItem.maskedValue || realValue;
    },
    toggleShowValue (textReviewItem) {
      if (!('showValue' in textReviewItem)) {
        this.$set(textReviewItem, 'showValue', false); // Default to false since we will toggle below
      }
      textReviewItem.showValue = !textReviewItem.showValue;
    },
    hideShowButtonText (textReviewItem) {
      return textReviewItem.showValue
        ? this.$t('common.form.sensitiveHide')
        : this.$t('common.form.sensitiveShow');
    },
    hideShowButtonAriaLabel (textReviewItem) {
      return this.hideShowButtonText(textReviewItem) + ' ' + textReviewItem.label;
    },
    isRowHtml (textReviewItem) {
      return textReviewItem.typeEnum === 'Html';
    }
  },
  data () {
    return {
      content: null,
      formstate: {},
      reviewAgreeDisagreeProp: null
    };
  }
};
</script>

<style scoped lang="scss">
@import '@clickboarding/style/mixins';
@import '@clickboarding/style/colors';

.cb-review-section {
  border-bottom: solid 1px $cb-light-grey-1;
  margin-bottom: 1rem;
}

.cb-review-group-title {
  margin: 0;
  padding-bottom: .5rem;
  font-weight: bold;
}

.cb-review-group.multi + .cb-review-group.multi {
  border-top: 1px solid #D5D5D5;
  padding-top: 1rem;
  margin-top: 1rem;
}

.cb-review-item {
  @include font-size-content-root;
  display: block;
}

.cb-review-item-label {
  font-weight: bold;
}

.cb-review-item-value {
  white-space: pre-wrap;
}

.cb-review-item-button {
  @include cb-link;
  background-color: transparent;
  font-weight: bold;
  // Set padding to keep 'active' border small
  padding: 0 .25rem;
  // Set margin to give more spacing between value and button
  margin-left: .25rem;
}

.cb-btn.secondary {
  margin: 1rem 0;
  width: 100%;
}

.cb-review-preview {
  text-align: center;
  margin-bottom: 1rem;

  a {
    @include font-size-content-root;
    @include cb-link;
  }
}

/deep/ .cb-review-item .cb-review-item-html-content {
  @import 'common/css/html-editor-content';
}
</style>
