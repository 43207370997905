<template>
    <div class="action-section" id="action-center-revamped-i9everify-section" v-if="apiData">
        <b-modal modal-class=""
                 class="complete-action-modal"
                 size="lg"
                 hide-footer
                 id="i9everify-revamped-after-modal"
                 static
                 centered
                 @ok="onModalClose"
                 @hidden="onModalClose"
                 title="">

            <p class="action-center-everify-notice" v-if="isCompleted">{{ $t('actionCenter.form.completeNotice') }}</p>
            <p class="action-center-everify-notice" v-if="apiData.isEverify">{{$t("actionCenter.i9everify.everifyNotice")}}</p>
            <div class="status-icon">
                <svg>
                    <use v-bind="{'xlink:href': statusIconPath }"></use>
                </svg>
            </div>

            <button :disabled="buttonDisabled" type="button" class="cb-btn primary action-button" @click="onModalClose">
                {{buttonLabel}}
            </button>
        </b-modal>
        
        <section >
            <b-modal :id="modalI9eVerifyId"
                     hide-footer
                     static
                     no-close-on-backdrop
                     :modal-class="['full-screen-modal', 'no-padding-modal']"
                     body-class="action-center-i9modal-body"
                     header-class="action-center-i9modal-header"
                     :title="$t('actionCenter.i9everify.employmentVerificationTitle')"
                     @hide=handleClose>
                <iframe :src="apiData.contentUrl" class="action-center-i9overlay-iframe"></iframe>
            </b-modal>
            <cb-exit-confirmation ref="exitConfirmation" />
        </section>
    </div>
</template>
<script>
    import ActivityCompletedIcon from 'common/assets/icons/activities/activity-completed.svg';
    import ActivityGetStartedIcon from 'common/assets/icons/activities/activity-get-started.svg';
    import ActivityPendingIcon from 'common/assets/icons/activities/activity-pending.svg';
    import CbExitConfirmation from 'common/components/cb-exit-confirmation.vue';

    export default {
        name: 'action-center-revamped-i9everify',
        components: {
            CbExitConfirmation
        },
        props: {
            instanceRequest: {
                type: Object,
                required: true,
                validator: function (value) {
                    return 'method' in value && 'url' in value;
                }
            },
            wasCompleted: {
                type: Boolean,
                required: true,                
            }
        },
        data() {
            return {
                apiData: null,
                modalId: null
            };
        },
        mounted() {
            // This component is used in a list of similar components.  In order
            // to open the correct modal, we need to give it a unqiue id so that
            // the $bvModal.show() function knows which modal to open
            this.modalI9eVerifyId = `action-center-revamped-i9everify-modal`;
        },
        computed: {
            statusIconPath() {
                let iconId = null;
                switch (this.apiData.status.value) {
                    case 'Completed':
                        iconId = ActivityCompletedIcon.id;
                        break;
                    case 'Due':
                    case 'Overdue':
                        iconId = ActivityGetStartedIcon.id;
                        break;
                    case 'Pending':
                        iconId = ActivityPendingIcon.id;
                        break;
                }
                return '#' + iconId;
            },
            buttonLabel() {
                let labelId = null;
                switch (this.apiData.status.value) {
                    case 'Completed':
                        if (this.wasCompleted) {
                            labelId = 'actionCenter.form.review';
                        } else {
                            labelId = 'actionCenter.form.close';
                        }
                        break;
                    case 'Due':
                    case 'Overdue':
                        labelId = 'actionCenter.form.get-started';
                        break;
                    case 'Pending':
                        labelId = 'actionCenter.form.pending';
                        break;
                }
                return this.$t(labelId);
            },
            buttonDisabled() {
                return this.apiData.status.value === 'Pending';
            },
            isCompleted() {
                return this.apiData.status.value === 'Completed';
            }
        },
        watch: {
            instanceRequest: {
                immediate: true,
                deep: true,
                handler: async function (newVal, oldVal) {
                    if (newVal) {
                        await this.refreshApiData(true);
                        await this.processApiData(true);
                    }                    
                }
            }
        },
        methods: {
            refreshApiData: async function (shouldGetForm) {
                const response = await this.$http(this.instanceRequest);

                if (response) {
                    this.apiData = response.body;                    
                }               
            },
            isCompletedRelatedForm: async function () {
                if (this.apiData.relatedInstance) {
                    const relatedResponse = await this.$http(this.apiData.relatedInstance);
                    
                    if (relatedResponse && relatedResponse.body.status.value === 'Completed') {
                        return true;
                    }
                }

                return false;
            },
            processApiData: async function (shouldGetForm) {
                if (this.apiData) {
                    switch (this.apiData.status.value) {
                        case 'Completed':
                            if (this.wasCompleted) {
                                if (shouldGetForm) {
                                    this.showI9everifyModal();
                                } else if (await this.isCompletedRelatedForm()) {
                                    this.showCompletedModal();
                                } 
                            } else {
                                this.showCompletedModal();
                            }                            
                            break;
                        case 'Due':
                        case 'Overdue':
                            if (shouldGetForm) {
                                this.showI9everifyModal();
                            };
                            break;
                        case 'Pending':
                            this.showCompletedModal();
                            break;
                    }
                }
            },
            showCompletedModal() {
                this.$root.$emit("bv::show::modal", "i9everify-revamped-after-modal");
            },
            showI9everifyModal: function () {
                this.$bvModal.show(this.modalI9eVerifyId);
            },
            handleClose: async function (bvModalEvent) {
                // Because this component uses the exit confirmation, we need to look for
                // events that are coming from the user like the header close click or the
                // escape key being pressed and prevent the modal from closing.  If the trigger
                // for the event is "event" instead this means that the user has confirmed that
                // they're leaving the page from the exit confirmation.
                if (bvModalEvent.trigger !== 'event') {
                    bvModalEvent.preventDefault();
                    var confirmed = await this.$refs.exitConfirmation.open();
                    if (confirmed) {
                        this.$bvModal.hide(this.modalI9eVerifyId);
                        await this.refreshApiData();
                        await this.processApiData();
                        this.onModalClose();
                    }
                }
            },
            emitForStatusChange: async function (instanceRequest, actionItem) {
                const status = {
                    value: actionItem.status.value
                };

                if (actionItem.status.date) {
                    status.date = actionItem.status.date;
                }

                this.$emit('action-item-status-changed', instanceRequest, {
                    status: status
                });
            },
            onModalClose(bvModalEvent) {
                this.apiData = null;
                this.$emit('complete-form-closed');
            }
        }
    };
</script>
<style scoped lang="scss">
    @import "@clickboarding/style/colors";
    @import '@clickboarding/style/mixins';

    .action-section {
        background-color: $cb-white;
        position: relative;
        border: solid 1px $cb-light-grey-1;
    }

    .action-center-everify-notice {
        @include font-size-content-root;
        text-align: center;
        padding-left: 1rem;
        padding-right: 1rem;
    }

    // IE 11 requires a deterministic header height so that we can calculate a deterministic *body* height and allow the iframe to fill the modal
    $header-height: 3.5rem;

    /deep/ .action-center-i9modal-body {
        height: calc(100vh - #{$header-height});
        display: flex;
        flex-flow: column;
        // ensures that the iframe is scrollable in IOS browsers
        overflow: auto;
    }

    /deep/ .action-center-i9modal-header {
        height: $header-height;
    }

    .action-center-i9overlay-iframe {
        background: $cb-white;
        width: 100%;
        flex: 1 1 auto;
        border: 0;
    }

    .status-icon {
        text-align: center;
        padding: 1.5rem;
    }

    .action-button {
        width: 100%;
    }
</style>