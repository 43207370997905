<template>
    <section>
      <cb-admin-view-title class="cb-admin-view-title" :title="title">
        <b-button v-if="button.label"
                  class="cb-btn secondary companies-add-button"
                  variant="green"
                  :class="{ 'disabled': buttonDisabled }"
                  :disabled="buttonDisabled"
                  @click="callAction">
          {{ button.label }}
        </b-button>
      </cb-admin-view-title>
        <cb-view-section v-if="hasCompanies || noDataContent" class="companies-view-banner"
                        :includePrimaryMessages="false">
            <div v-if="showSearch"
                class="filter-group"
            >
            <div class="search-item">
                <b-form-input
                    class="search-input company-filter small-input"
                    v-model="searchCriteria"
                    :debounce="300"
                    :placeholder='$t("companies.filter.placeholder.company")'
                ></b-form-input>
            </div>
            </div>
        </cb-view-section>
        <div class="no-data-container" v-if="!hasCompanies && noDataContent">
            <h2>{{noDataContent.title}}</h2>
            <div class="clipboard-pencil-icon"></div>
            <p class="no-data-message">{{noDataContent.message}}</p>
        </div>
        <cb-view-section class="companies-list-section" type="secondary" :includePrimaryMessages="true">
          <b-table v-if="hasCompanies"
                   class="new-table"
                   v-bind="tableSettings"
                   :per-page="itemsPerOnePage"
                   :current-page="currentPage"
                   :items="companies"
                   sort-by="name"
                   :filter="searchCriteria"
                   :filter-included-fields="['name', 'referenceName']"
                   show-empty
                   :fields="[
                { key: 'name', label: columnHeaders.name, thAttr: { 'aria-sort': 'descending' }, sortable: true, sortDirection: 'desc' },
                { key: 'referenceName', label: columnHeaders.referenceName, sortable: true },
                { key: 'createDate', label: columnHeaders.createDate, class: 'date-column', sortable: true },
                { key: 'actions', label: '', class: 'action-menu-column', thAttr: { 'aria-label': $t('companies.actionsColumnHeaderScreenReader') }  }
            ]"
                   @filtered="onFiltered"
          >
                <template v-slot:cell(actions)="{ item }">
                    <action-menu :toggle-orientation="'landscape'" :menu-position="'bottom-left'" :items="item.actions" />
                </template>
            </b-table>
            <div v-if="showPagination" class="pagination-container">
                <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm" class="per-page-options">
                    <b-form-select
                        id="per-page-select"
                        v-model="itemsPerOnePage"
                        :options="paginationSettings.pageOptions"
                        size="sm">
                    </b-form-select>
                </b-form-group>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="itemsPerOnePage"
                    :current-page="currentPage"
                    v-bind="paginationSettings">
                </b-pagination>
            </div>
        </cb-view-section>
    </section>
</template>
<script>
    import AppSettings from 'appSettings';
    import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
    import CbViewSection from 'general/cb-view-section.vue';
    import ActionMenu from 'common/components/action-menu.vue';
    import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../constants/defaultTableSettings";

    export default {
        name: 'companies-route',
        components: {
            CbAdminViewTitle,
            CbViewSection,
            ActionMenu
        },
        props: {
            dataUrl: {
                type: String,
                required: true
            },
            changeCompany: {
                type: Function,
                required: true
            }
        },
        watch: {
            dataUrl: {
                immediate: true,
                handler(newDataUrl, oldDataUrl) {
                    if (newDataUrl && (newDataUrl !== oldDataUrl)) {
                        this.getCompanies(newDataUrl);
                    }
                }
            }
        },
        methods: {
            getCompanies(url) {
                this.$http.get(url).then((response) => {
                    this.title = response.data.title;
                    this.companies = response.data.companies;
                    this.columnHeaders = response.data.listHeaderLabels;
                    this.button = this.buildButton(response.data.createCompany, AppSettings.viewCompaniesAdd);
                    this.noDataContent = response.data.noData;

                    this.companies.forEach((item) => {
                        item.actions = this.buildActionMenu(item);
                    });

                    this.totalRows = this.companies.length
                });
            },
            buildButton(item, view) {
                let button = {
                    label: item.label,
                };
                if (item.url){
                    button.action = () => {
                        this.$_cb.router.changeView(view, item.url);
                    }
                }
                return button;
            },
            buildActionMenu(item) {
                item.actions.forEach((action) => {
                    switch(action.typeEnum) {
                        case 'View':
                            action.icon = 'Action-View-Company-Icon';
                            action.action = async () => {
                                await this.changeCompany(action.url);
                            }
                        break;
                        case 'Edit':
                            action.icon = 'Action-Edit-Icon';
                            action.action = () => {
                                this.$_cb.router.changeView(AppSettings.viewCompaniesAdd, action.url);
                            }
                        break;
                    }
                });
                return item.actions;
            },
            callAction() {
                if (this.button.action) {
                    this.button.action();
                }
            },
            onFiltered (filteredItems) {
                if(this.totalRows !== filteredItems.length) {
                    this.currentPage = 1
                    this.totalRows = filteredItems.length
                }
            }
        },
        data() {
            return {
                title: null,
                companies: null,
                dataGridHeaders: null,
                noDataContent: null,
                button: {},
                tableSettings: TABLE_SETTINGS,
                paginationSettings: PAGINATION_SETTINGS,
                currentPage: 1,
                itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0],
                searchCriteria: '',
                totalRows: 0
            };
        },
        computed: {
            hasCompanies() {
                return !!(this.companies && this.companies.length);
            },
            buttonDisabled() {
                return this.button == null || this.button.action == null;
            },
            showSearch () {
              return this.companies.length > PAGINATION_SETTINGS.pageOptions[0]
            },
            showPagination() {
                return this.hasCompanies && this.totalRows > PAGINATION_SETTINGS.pageOptions[0];
            }
        }
    }
</script>
<style scoped lang="scss">
    @import '@clickboarding/style/mixins';

    .no-data-container {
        text-align: center !important;
        @include cb-view-padding;
    }

    .no-data-message {
        width: 16.25rem;
        margin: 0 auto 2rem;
        line-height: 1.25rem;
    }

    .cb-view-section.companies-list-section.secondary {
        padding-top: 0;
        border-bottom: none;
    }

    .companies-view-banner {
      display: flex;
      margin-bottom: 1rem;
      padding: 0 1.5rem;
    }

    .companies-add-button {
      margin-left: auto;
    }
</style>
