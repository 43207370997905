<template>
  <div class="candidate-personal-info-inputs">
    <div class="row">
      <validation-provider slim rules="required|max:100|noHtml" v-slot="{ passed, failed, errors }">
        <b-form-group
          :label-for="$id('firstName')"
          class="col-md-6"
          :state="passed"
          :invalid-feedback="errors[0]">
          <template v-slot:label>
            {{ $t(labelKey('firstName')) }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-input
            :id="$id('firstName')"
            v-model="localFirstName"
            :state="failed ? false : null"
            required
            maxlength="100"
            noHtml />
        </b-form-group>
      </validation-provider>
      <validation-provider slim rules="max:100|noHtml" v-slot="{ passed, failed, errors }">
        <b-form-group
          :label="$t(labelKey('middleName'))"
          :label-for="$id('middleName')"
          class="col-md-6"
          :state="passed"
          :invalid-feedback="errors[0]">
          <b-form-input
            :id="$id('middleName')"
            v-model="localMiddleName"
            :state="failed ? false : null"
            maxlength="100"
            noHtml />
        </b-form-group>
      </validation-provider>
      <validation-provider slim rules="required|max:100|noHtml" v-slot="{ passed, failed, errors }">
        <b-form-group
          :label-for="$id('lastName')"
          class="col-md-6"
          :state="passed"
          :invalid-feedback="errors[0]">
          <template v-slot:label>
            {{ $t(labelKey('lastName')) }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-input
            :id="$id('lastName')"
            v-model="localLastName"
            :state="failed ? false : null"
            required
            maxlength="100"
            noHtml />
        </b-form-group>
      </validation-provider>
      <div v-if="lockIsTest">
        <div v-if="isTest" class="w-100 margin-medplus-T margin-med-L font-weight-bold">{{ $t(labelKey('isTest')) }}</div>
      </div>
      <div v-else>
      <input type = "checkbox" class = "margin-med-L margin-lrg-T"
            :id="$id('isTest')"
            v-model="localIsTest"
            :value=true 
            :unchecked-value=false        
            >
      <label for = "$id('isTest')" class="font-weight-bold margin-sm-L">{{ $t(labelKey('isTest')) }}</label>
      </div>
    </div>
    <hr />
    <validation-provider slim rules="required|email|max:100|noHtml" v-slot="{ passed, failed, errors }">
      <b-form-group
        :label-for="$id('email')"
        :state="passed"
        :invalid-feedback="errors[0]">
        <template v-slot:label>
          {{ $t(labelKey('emailAddress')) }}
          <span aria-hidden class="text-danger">*</span>
        </template>
        <b-form-input
          type="email"
          :id="$id('email')"
          v-model="localEmail"
          :state="failed ? false : null"
          required
          maxlength="100"
          noHtml />
      </b-form-group>
    </validation-provider>
    <validation-provider slim rules="min:10|max:20|noHtml" v-slot="{ passed, failed, errors }">
      <b-form-group
        :label="$t(labelKey('phoneNumber'))"
        :label-for="$id('phone')"
        :state="passed"
        :invalid-feedback="errors[0]">
        <b-form-input
          type="tel"
          :id="$id('phone')"
          v-model="localPhone"
          :state="failed ? false : null"
          minlength="10"
          maxlength="20"
          noHtml>
        </b-form-input>
      </b-form-group>
    </validation-provider>
    <validation-provider slim rules="max:250|noHtml" v-slot="{ passed, failed, errors }">
      <b-form-group
        :label-for="$id('ssoIdentifier')"
        :state="passed"
        :invalid-feedback="errors[0]">
        <template v-slot:label>
          {{ $t(labelKey('ssoIdentifier')) }}
        </template>
        <b-form-input
          :id="$id('ssoIdentifier')"
          v-model="localSsoIdentifier"
          :state="failed ? false : null"
          maxlength="250"
          noHtml />
      </b-form-group>
      </validation-provider>
      <validation-provider slim rules="max:250|noHtml" v-slot="{ passed, failed, errors }">
      <b-form-group
        v-if="showSourceSystemId"
        :label-for="$id('sourceSystemId')"
        :state="passed"
        :invalid-feedback="errors[0]">
        <template v-slot:label>
          {{ $t(labelKey('sourceSystemId')) }}
        </template>
        <b-form-input
          :id="$id('sourceSystemId')"
          v-model="localSourceSystemId"
          :state="failed ? false : null"
          maxlength="250"
          noHtml />
      </b-form-group>
      </validation-provider>
  </div>
</template>
<script>
import { mapPropsForSync } from 'common/form-helpers';
import { mapGetters } from 'vuex';


export default {
  name: "candidate-personal-info-inputs",
  props: {
    firstName: {
      type: String,
    },
    middleName: {
      type: String,
    },
    lastName: {
      type: String,
    },
    email: {
      type: String,
    },
    phone: {
      type: String,
    },
    ssoIdentifier: {
      type: String,
    },
    sourceSystemId: {
      type: String,
    },
    showSourceSystemId: {
      type: Boolean
    },
    isTest: {
      type: Boolean
    },
    lockIsTest: {
      type: Boolean
    }
  },
  computed: {
    ...mapPropsForSync('local', [
      'firstName',
      'middleName',
      'lastName',
      'email',
      'phone',
      'ssoIdentifier',
      'sourceSystemId',
      'isTest'
    ])
  },
  methods: {
    labelKey(fieldName) {
      return `candidate.personalInfoFields.${fieldName}Label`;
    }
  }
};
</script>