<template>
  <cb-view-section type="secondary" :withFlushTop="true">
    <savage-form-select
      :label="$t('processBuilder.activities.add.labels.taskAssignedTo')"
      :validation="buildValidation()"
      :items="this.userTypes"
      :emptyItemLabel="$t('common.select.emptyOption')"
      v-model="assignedRole"
      />

    <savage-form-select
      v-if="showUsersDropdown()"
      :label="$t('processBuilder.activities.notification.roleTypeEnumMap.TeamMember')"
      :validation="buildValidation()"
      :items="this.clientUsers"
      :emptyItemLabel="$t('processBuilder.activities.add.labels.teamMemberSelectPrompt')"
      v-model="assignedUserId"
      />
  </cb-view-section>
</template>

<script>
  import { SavageFormSelect, SavageFormMixin } from '@clickboarding/savage';
  import CbViewSection from 'general/cb-view-section.vue';
  import { mapActions, mapGetters } from 'vuex';
  
  export default {
    name: 'task-configure',
    components: {
      CbViewSection,
      SavageFormSelect
    },
    mixins: [
      // provides the validation directive required by 
      // savage to validate the slot content of validated-container
      SavageFormMixin
    ],
    created() {
      this.task.apiIdentifier = this.task.value;

      if (!this.task.taskAssignment) {
        this.$set(this.task, 'taskAssignment', {
          typeEnum: ''  
        });
      }
    },
    model: {
      prop: 'activity'
    },
    props: {
      activity: {
        type: Object,
        required: true
      }
    },
    watch: {
      task: {
        deep: true,
        handler: function(val, oldVal) {
          this.$emit('input', val);
        }
      }
    },
    async mounted() {
      await this.getUserListAsync(this.task.userList.url);
    },
    computed: {
      ...mapGetters('activities', ['clientUsers']),
      task() {
        return this.activity;
      },
      assignedRole: {
        get: function() {
          let taskAssignmentObj;
          taskAssignmentObj = JSON.parse(JSON.stringify(this.task.taskAssignment));
          if (taskAssignmentObj.typeEnum === 'User') {
            taskAssignmentObj.userId = null;
            delete taskAssignmentObj.userName;
          } else if (taskAssignmentObj.typeEnum === '') {
            taskAssignmentObj = null;
          }
          return taskAssignmentObj;
        },
        set: function(value) {
          if(value !== null) {
            this.task.taskAssignment = JSON.parse(JSON.stringify(value));
          }
        }
      },
      assignedUserId: {
        get: function() {
          const taskAssignmentObj = JSON.parse(JSON.stringify(this.task.taskAssignment));
          return taskAssignmentObj.userId;
        },
        set: function(value) {
          if(value !== null) {
            this.task.taskAssignment = { typeEnum: 'User', userId: JSON.parse(JSON.stringify(value)) };
          }
          // If there are options in the dropdown but nothing is selected,
          // set userId to null to trigger the validations on the Savage dropdown.
          else if(value === null && this.clientUsers.length > 0) {
            this.task.taskAssignment.userId = null;
          }
        }
      }
    },
    methods: {
      ...mapActions('activities', ['getUserListAsync']),
      buildValidation() {
        return {
          required: {
            value: true,
            message: this.$t('common.validation.required')
          }
        };
      },
      showUsersDropdown() {
        return this.assignedRole && this.assignedRole.typeEnum && this.assignedRole.typeEnum === 'User';
      }
    },
    data() {
      return {
        userTypes: [
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.Coordinator'), value: { typeEnum: 'Role', role: 'Coordinator' } },
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.HiringManager'), value: { typeEnum: 'Role', role: 'HiringManager' }  },
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.Recruiter'), value: { typeEnum: 'Role', role: 'Recruiter' } },
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.TeamMember'), value: { typeEnum: 'User', userId: null } }
        ]
      }
    }
  }
</script>
