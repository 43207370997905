class CbLocaleStore {
  constructor() {
    this.state = {
      dateLocale: 'en-US'
    }
    this.setDateLocale = this.setDateLocale.bind(this);
    // this.clearDateLocale = this.clearDateLocale.bind(this);
  }

  setDateLocale (newLocale) {
    this.state.dateLocale = newLocale;
  }

  // resetDateLocale () {
  //   this.state.dateLocale = 'en-US';
  // }
}

export default new CbLocaleStore();
