<template>
  <cb-view-section type="secondary" :withFlushTop="isGrouped" class="forms-data-entry-group-container">
    <group-container-wrapper :isGrouped="isGrouped">
      <cb-view-section type="secondary" :withFlushTop="true" class="forms-data-entry-group-container-label">
        <h2>{{title}}</h2>
      </cb-view-section>
      <cb-view-section type="secondary" :withFlushTop="true">
        <slot name="fields" />
      </cb-view-section>
      <slot />
    </group-container-wrapper>
  </cb-view-section>
</template>

<script>
import CbViewSection from 'general/cb-view-section.vue';

export default {
  name: 'forms-data-entry-group-container',
  components: {
    CbViewSection,
    GroupContainerWrapper: {
      functional: true,
      props: {
        isGrouped: Boolean
      },
      render(createElement, context) {
        return context.props.isGrouped
          // if group is nested within another group, wrap it in 
          // additional cb-view-section elements to visually "nest" it
          ? createElement(CbViewSection, { props: { type: "secondary" } }, [ 
              createElement(CbViewSection, { props: { type: "primary" } }, 
                context.children
              )
            ])
          // else if a root group element, just return the children 
          // with no container around them
          : context.children;
      },
    }
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isGrouped: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';

  .forms-data-entry-group-container {
    // the padding and border styles collapse the 
    // secondary cb-view-section at the root of this component
    // so we don't get the extra white container around
    // the group. The reason we still use the secondar
    // section is so it still styles correctly in conjunction
    // its sibling secondary cb-view-section instances.
    padding: 0 !important;
    border: none !important;

    > .cb-view-section.secondary:first-child {
      // This elimiates the double border at the 
      // nested groups.
      border-top: none;
    }
  }

  .forms-data-entry-group-container-label {
    padding: 1rem;

    h2 {
      font-size: 1rem;
      font-weight: normal;
      width: 100%;
      text-align: center;
      margin: 0;
      color: $cb-dark-grey-1;
    }
  }
</style>
