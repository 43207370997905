<template>
  <section class="forms-document-upload-container">
    <forms-builder :dataUrl="dataUrl" :isReadOnly="isReadOnly" :onBack="onBack">
      <div slot-scope="formsBuilderProps" class="document-upload-form">
        <savage-form-text-input
          name="formName"
          :label="formsBuilderProps.formLabels.nameLabel"
          :placeholder="formsBuilderProps.formLabels.namePlaceholder"
          :validation="buildFormNameValidation(formsBuilderProps)"
          v-model="formsBuilderProps.form.name" />
        <savage-form-text-input
          name="formTitle"
          :label="formsBuilderProps.formLabels.titleLabel"
          :placeholder="formsBuilderProps.formLabels.titlePlaceholder"
          :validation="buildFormTitleValidation(formsBuilderProps)"
          v-model="formsBuilderProps.form.title" />
        <savage-form-text-area
          name="uploadInstructions"
          :label="formsBuilderProps.formLabels.uploadInstructionsLabel"
          :placeholder="formsBuilderProps.formLabels.uploadInstructionsPlaceholder"
          :validation="buildUploadInstructionsValidation(formsBuilderProps)"
          v-model="formsBuilderProps.form.uploadInstructions" />
        <savage-form-radio-group
          name="isUploadRequired"
          :label="formsBuilderProps.formLabels.uploadRequired.label"
          :items="formsBuilderProps.formLabels.uploadRequired.items"
          v-model="formsBuilderProps.form.isUploadRequired" />
      </div>
    </forms-builder>
  </section>
</template>
<script>
import FormsBuilder from 'forms/forms-builder.vue';
import { SavageFormTextInput } from '@clickboarding/savage';
import { SavageFormRadioGroup, SavageFormTextArea } from '@clickboarding/savage';

export default {
  name: 'forms-document-upload',
  components: {
    FormsBuilder,
    SavageFormTextInput,
    SavageFormTextArea,
    SavageFormRadioGroup
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    onBack: {
      type: Function,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    buildFormNameValidation (props) {
      return {
        required: {
          value: true,
          label: props.formLabels.nameLabel,
          message: props.validationMessages.required
        },
        maxlength: {
          value: 100,
          label: props.formLabels.nameLabel,
          message: props.formLabels.nameLabel + ' must be 100 characters or less.'
        }
      };
    },
    buildFormTitleValidation (props) {
      return {
        maxlength: {
          value: 100,
          label: props.formLabels.titleLabel,
          message: props.formLabels.titleLabel + ' must be 100 characters or less.'
        }
      };
    },
    buildUploadInstructionsValidation (props) {
      return {
        required: {
          value: true,
          label: props.formLabels.uploadInstructionsLabel,
          message: props.validationMessages.required
        },
        maxlength: {
          value: 1000,
          label: props.formLabels.uploadInstructionsLabel,
          message: props.formLabels.uploadInstructionsLabel + ' must be 1000 characters or less.'
        }
      };
    }
  }
}
</script>
<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    .document-upload-form {
        border: 1px solid $cb-light-grey-1;
        background: $cb-white;
        padding: 1.5em;
    }
</style>
