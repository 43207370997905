<template>
  <section>
    <p v-if="instructions" class="form-document-upload-instructions">
      {{instructions}}
    </p>
    <savage-form 
        v-if="upload" 
        name="form-document-upload" 
        ref="savageFormInstance"
        :formstate="formstate" 
        :on-submit="save"
        @submit-started="$emit('submit-started', $event)"
        @submit-completed="$emit('submit-completed', $event)">
      <savage-form-validated-container
          fieldName="form-document-upload-container"
          :validation="fileUploadValidation"
          :value="files">
        <!-- 
          v-model cannot be used on the cb-file-upload in this context
          or else the validation library will mistakenly run validation 
          against it instead of the proxy instance that is running in 
          savage-form-validated-container
        -->
        <cb-file-upload
          @input="updateFiles"
          :value="files"
          :upload-url="upload.url"
          :max-files="upload.validation.maxFileCount.value"
          :max-files-error-message="upload.validation.maxFileCount.message"
          :max-file-size-mb="upload.validation.maxFileSizeMB.value"
          :max-file-size-mb-error-message="upload.validation.maxFileSizeMB.message"
          :allowed-content-types="upload.validation.allowedContentTypes.value"
          :allowed-content-types-error-message="upload.validation.allowedContentTypes.message"
          :drop-area-label="upload.message"
          :button-label="upload.label"
          :view-label="upload.viewLabel"
          :delete-label="upload.deleteLabel" />
      </savage-form-validated-container>
    </savage-form>
  </section>
</template>

<script>
  import CbFileUpload from 'common/components/cb-file-upload.vue';
  import FormNavigationService from 'common/forms/form-navigation.service';
  import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
  import { SavageForm, SavageFormMixin, SavageFormValidatedContainer } from '@clickboarding/savage';

  export default {
    name: 'form-document-upload',
    components: {
      CbFileUpload,
      SavageForm,
      SavageFormValidatedContainer
    },
    mixins: [ 
      // provides the validation directive required by 
      // savage to validate the slot content of validated-container
      SavageFormMixin 
    ], 
    props: {
      contentUrl: {
        required: false,
        type: String
      },
      eventUrl: {
        required: false,
        type: String
      },
      onNextSuccess: {
        required: false,
        type: Function
      },
      onLoad: {
        required: true,
        type: Function
      }
    },
    destroyed () {
      FormNavigationService.deleteNextClickHandler();
    },
    async mounted () {
      FormNavigationService.setNextClickHandler(() => {
        this.$refs.savageFormInstance.submit();
      });

      const response = await this.$http.get(this.contentUrl);
      
      this.upload = response.body.upload;
      this.instructions = response.body.instructions;
      this.files = response.body.model.files;
      await this.onLoad();
    },
    computed: {
      fileUploadValidation () {
        return {
          required: {
            value: this.upload.validation.minFileCount.value > 0,
            message: this.upload.validation.minFileCount.message
          }
        };
      }
    },
    methods: {
      updateFiles(files) {
        this.files = files;
      },
      async save () {
        const response = await this.$http.post(this.eventUrl, {files: this.files});

        await this.onNextSuccess(response.body);
        PrimaryMessageBus.$emit('clear-all-messages');
      }
    },
    data () {
      return {
        formstate: {},
        upload: null,
        instructions: null,
        files: null
      }
    }
  }
</script>
<style lang="scss" scoped>
  .form-document-upload-instructions {
    margin-top: 1rem;
    text-align: center;
  }
</style>
