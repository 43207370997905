<template>
  <forms-content-build-element
    :dataUrl="dataUrl"
    :wrappedElement="wrappedElement"
    :returnLink="returnLink"
    :onBack="onBack"
    :onComplete="onComplete">
      <cb-view-section type="secondary" slot-scope="buildElementContext">
        <div class="video-header">
          <strong>{{$t('formsBuilder.video.title')}}</strong>
          <p>{{$t('formsBuilder.video.message')}}</p>
        </div>
        <forms-add-video
          name="addVideo"
          v-model="buildElementContext.element"
          :default-provider="buildElementContext.component.control.defaultValue"
          :validation="buildElementContext.component.control.validation"
          :label="$t('formsBuilder.video.label')"
          :providers="buildElementContext.component.control.items"
          :placeholder="$t('formsBuilder.video.placeholder')" />
      </cb-view-section>
  </forms-content-build-element>
</template>

<script>
import FormsContentBuildElement from 'forms/forms-content-build-element.vue';
import FormsAddVideo from 'forms/forms-add-video.vue';
import CbViewSection from 'general/cb-view-section.vue';

export default {
  name: 'forms-content-build-video',
  components: {
    FormsContentBuildElement,
    FormsAddVideo,
    CbViewSection
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    wrappedElement: {
      type: Object,
      required: true
    },
    returnLink: {
      type: Object,
      required: true
    },
    onBack: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';

  .video-header {
    text-align: center;
    border-bottom: 1px solid $cb-light-grey-1;
    margin: 0 -1.5rem 1rem;
    padding: 0 1rem;

    p {
      padding-top: .5rem;
      @include font-size-content-root;
    }
  }
</style>
