import clonedeep from 'lodash.clonedeep';
import 'common/polyfills/array.find.polyfill';
import { AxiosLocal as axios } from "common/services/axiosLocal.service";
import { getByType } from 'forms/data-entry-mappers/element-types/all';

async function getPresentationElement (url) {
  const response = await axios.get(url);
  return response.data;
}

async function map (modelElement) {
  const performMap = function (modelElement, presentationElement) {
    let matchedPresentation = presentationElement.availablePresentations[modelElement.designer.presentationKey];
    if (matchedPresentation) {
      // Since we found a match, update the defaultPresentationKey to the selected presentation
      presentationElement.defaultPresentationKey = modelElement.designer.presentationKey;

      // Clone modelELement so we can remove properties that don't belong in the presentation 
      // element without impacting the original modelElement
      const presentationModel = clonedeep(modelElement);
      delete presentationModel.designer;    
      
      const elementType = getByType(modelElement.type);
      const childElementsKey = elementType && elementType.getChildElementsKey ? elementType.getChildElementsKey() : null;
      
      if (childElementsKey) {
        delete presentationModel[childElementsKey];

        if (modelElement[childElementsKey] && matchedPresentation[childElementsKey]) {
          // ForEach child element in the matchedPresentation, we need to repeat this process
          matchedPresentation[childElementsKey].forEach(childPresentationElement => {
            // Find the matching element from the model (by datafield)
            const matchedModelElement = modelElement[childElementsKey].find(element => element.datafield === childPresentationElement.datafield);
            if (matchedModelElement) {
              childPresentationElement = performMap(matchedModelElement, childPresentationElement);
            }
          });
        }
      }

      Object.assign(matchedPresentation, presentationModel);
    }
  
    return presentationElement;
  }

  // Fetch presentableDatafield from the API
  const presentationElement = await getPresentationElement(modelElement.designer.getPresentableDatafield.url);
  
  return performMap(modelElement, presentationElement);
}

export { map }