"use strict"

const createEmptySpan = () => document.createElement('span');
const ellipsisText = (el, {value}) => {
    if (value) {
        const indexOfLastSpace = value.lastIndexOf(' ')

        el.classList.add('middle-ellipsis')
        el.setAttribute('title', value)

        if (indexOfLastSpace > 0) {
            let firstPart = value.substring(0, indexOfLastSpace + 1)           
            let lastPart = value.substring(indexOfLastSpace, value.length).trim()
            
            const firstPartElement = createEmptySpan()
            const lastPartElement = createEmptySpan()
            
            firstPartElement.innerText = firstPart
            lastPartElement.innerText = lastPart

            firstPartElement.classList.add('first-part')
            lastPartElement.classList.add('last-part')

            el.appendChild(firstPartElement)
            el.appendChild(lastPartElement)
            el.setAttribute('title', value)
        } else {
            el.innerText = value
        }
    }
}

export default ellipsisText
