import ViewController from 'view.controller';

export default class UsersController extends ViewController {
    constructor ($http, $scope, $route, $timeout, locationSvc, $window, appSettings, aaNotify) {
        super($http, $scope, $route, $timeout, locationSvc, $window, appSettings, aaNotify);

        this.aaNotify = aaNotify;
        this.$scope = $scope;
        aaNotify.clearAll();
    }
}

UsersController.$inject = ['$http', '$scope', '$route', '$timeout', 'locationSvc', '$window', 'appSettings', 'aaNotify'];
