export default function sleepCard ($http, appSettings, DashService) {
	return {
		restrict: "E",
		replace: true,
		template: require('dashboard/cards/sleepCard.html'),
		scope: {
			data: "="
		},
		link: function(scope, element, attrs) {
			scope.saveSnooze = {};


			scope.$watch('data', function(newVal, oldVal) {
				if (newVal === oldVal) return;
				scope.obj = scope.data.data;
				scope.saveSnooze.unit = scope.obj.snoozeUnits[0];
			});

			scope.doSnooze = function(values) {
				var _data = {
					unit: values.unit.typeLabel,
					value: values.value
				};
				var endpoint = scope.obj.snoozeAction.url;

				DashService.snooze(_data, endpoint, scope.data.id).then(function(data) {
					scope.itemSnoozed = data;
					scope.snooze = true;
				});
			};

			scope.deleteSnooze = function() {
				var endpoint = scope.obj.snoozeAction.url;
				DashService.deleteSnooze(endpoint, scope.data, scope.data.id).then(function(data) {
					scope.itemSnoozed = false;
					scope.data = data;
					scope.saveSnooze.value = null;
					// have to reset form after removing value
					
				});
			};
		}
	};
};

sleepCard.$inject = ['$http', 'appSettings', 'DashService'];
