<template>
  <div class="importButton">
    <div v-if="showButton">
      <b-button class="small-button" style="height: 1.8rem; font-size: 11px" variant="green"
        @click="btnClickedHandler()"> {{ title }}</b-button>
    </div>
    <div v-if="!showButton">
      <div v-if="loading" class="spinner-border spinner-border-sm text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-if="!loading" class="importIcon" :title='titleOnHover' >
        <span v-html="icon" />
      </div>
    </div>
  </div>
</template>

<script>
import ImportStatus from "./importStatus";

export default {
  name: "ImportButton",
  mounted() { },
  methods: {
    btnClickedHandler() {
      this.params.clicked(this.params);
    }
  },
  computed: {
    showButton() {
      switch (this.params.value) {
        case ImportStatus.VALIDATION_COMPLETE:
        case ImportStatus.VALIDATION_COMPLETE_WITH_ERRORS:
          return true;
        default:
          return false;
      }
    },
    loading() {
      switch (this.params.value) {
        case ImportStatus.VALIDATION_READY:
        case ImportStatus.VALIDATION_STARTED:
        case ImportStatus.IMPORT_STARTED:
        case ImportStatus.IMPORT_READY:
          return true;
      }
    },
    icon() {
      switch (this.params.value) {
        case ImportStatus.IMPORT_COMPLETE:
          return '<i class="fa fa-check" style="color: #0AA991" aria-hidden="true"></i>';
        case ImportStatus.VALIDATION_FAILED:
        case ImportStatus.IMPORT_FAILED:
          return '<i class="fa fa-exclamation-circle" style="color: red" aria-hidden="true"></i>';
        case ImportStatus.IMPORT_COMPLETE_WITH_ERRORS:
          return '<i class="fa fa-check" style="color: orange" aria-hidden="true"></i>';
      }
    },
    title() {
      return "Start Import";
      },
    titleOnHover() {
        switch (this.params.value) {
            case ImportStatus.IMPORT_COMPLETE:
                return 'Import completed';
            case ImportStatus.VALIDATION_FAILED:
                return 'Validation failed, check template.';
            case ImportStatus.IMPORT_FAILED:
                return 'Import failed.';
            case ImportStatus.IMPORT_COMPLETE_WITH_ERRORS:
                return 'Import completed with errors, check import file.';
        }
    }
  }
};
</script>
<style scoped lang="scss">
div.importButton div.importIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.importButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>