<template>
  <section>
    <forms-data-entry-element-simplefield
      :label.sync="localLabel"
      :isRequired.sync="localIsRequired"
      :hidePlaceholder="true"
      :isAlwaysRequired="isAlwaysRequired"
      :isConditionallyShown.sync="localIsConditionallyShown"
      :availableConditionalFields="availableConditionalFields"
      :conditionalLogicField.sync="localConditionalLogicField"
      :conditionalLogicOperator.sync="localConditionalLogicOperator"
      :conditionalLogicValues.sync="localConditionalLogicValues"
      :hasDependencies="hasDependencies" />
    <!--
      mt-3 to replace the bottom margin that is removed
      from the last field in forms-data-entry-element-simplefield
    -->
    <b-form-group
      :label-for="$id('sort')"
      class="mt-3">
      <!-- size sm so it matches the savage inputs -->
      <b-form-checkbox
        :id="$id('sort')"
        size="sm"
        :value="SortOptions.Alpha"
        :unchecked-value="SortOptions.Ordinal"
        v-model="localSort"
        switch
        :disabled="hasDependencies">
          {{$t('formsBuilder.dataEntry.selectPresentation.sortLabel')}}
      </b-form-checkbox>
    </b-form-group>

    <cb-view-section type="secondary" class="forms-data-entry-element-select-options-grid">

      <div class="forms-data-entry-element-select-header-container d-flex">
        <div class="forms-data-entry-element-select-header-description flex-fill">
          {{$t("formsBuilder.dataEntry.selectPresentation.description")}}
        </div>
        <b-link :disabled="hasDependencies"  class="forms-data-entry-element-select-header-bulk-edit-link" href="#" @click="showBulkEdit">{{$t('formsBuilder.dataEntry.selectPresentation.bulkEdit.title')}}</b-link>
      </div>

      <div>
        <savage-form-group-array
            v-model="localOptions"
            :validation="optionsGridValidation"
            :customValidation="optionsGridCustomValidation"
            :itemTitle="$t('formsBuilder.dataEntry.selectPresentation.optionTitleText')"
            :addButtonClass="getButtonClasses()"
            :addButtonText="$t('formsBuilder.dataEntry.selectPresentation.addOptionLabel')"
            :deleteButtonClass="getButtonClasses()"
            :deleteButtonText="$t('formsBuilder.dataEntry.selectPresentation.deleteOptionLabel')"
            :minimumInitialItems="1">
          <section slot-scope="{ item }" v-savage-grid-columns="2">
            <savage-form-text-input
              :disabled="hasDependencies" 
              :label="$t('formsBuilder.dataEntry.selectPresentation.optionValueLabel')"
              :validation="valueValidation"
              v-model="item.value" />
            <savage-form-text-input
              :disabled="hasDependencies" 
              :label="$t('formsBuilder.dataEntry.selectPresentation.optionLabelLabel')"
              :validation="labelValidation"
              v-model="item.label" />
          </section>
        </savage-form-group-array>
      </div>

    </cb-view-section>

    <b-modal
        :id="bulkEditModalId"
        size="lg"
        no-close-on-backdrop
        centered
        no-stacking
        :title="$t('formsBuilder.dataEntry.selectPresentation.bulkEdit.title')"
        :ok-title="$t('common.update')"
        @ok="updateFromBulkEdit"
        @hide ="onBulkEditHide">
      <forms-data-entry-element-select-bulk-edit ref="bulk-edit-form" :options="localOptions" />
    </b-modal>
  </section>
</template>

<script>
import uuid from 'uuid/v4';
import 'common/polyfills/array.includes.polyfill';
import clonedeep from 'lodash.clonedeep';
import FormsDataEntryElementSimplefield from 'forms/forms-data-entry-element-simplefield.vue';
import FormsDataEntryElementSelectBulkEdit from 'forms/forms-data-entry-element-select-bulk-edit.vue';
import {
  SavageFormTextInput,
  SavageFormMixin,
  SavageGridColumnsDirective,
  SavageFormGroupArray
} from '@clickboarding/savage';
import CbViewSection from 'general/cb-view-section.vue';
import { mapPropsForSync } from 'common/form-helpers';
import { SortOptions } from 'forms/data-entry-mappers/element-types/select'

export default {
  name: 'forms-data-entry-element-select',
  components: {
    FormsDataEntryElementSimplefield,
    FormsDataEntryElementSelectBulkEdit,
    SavageFormGroupArray,
    SavageFormTextInput,
    CbViewSection
  },
  directives: {
    SavageGridColumns: SavageGridColumnsDirective
  },
  mixins: [
      // provides the validation directive required by
      // savage to validate the slot content of validated-container
      SavageFormMixin
  ],
  props: {
    label: {
      type: String,
      required: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
    },
    isConditionallyShown: {
      type: Boolean,
      required: false,
    },
    availableConditionalFields: {
      type: Array,
      required: false,
    },
    conditionalLogicField: {
      type: String,
      required: false
    },
    conditionalLogicOperator: {
      type: String,
      required: false
    },
    conditionalLogicValues: {
      type: Array,
      required: false
    },
    options: {
      type: Array,
      required: false,
    },
    sort: {
      type: String,
      required: false,
    },
    isAlwaysRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionValueValidation: {
      type: Object,
      required: false,
      default: null
    },
    hasDependencies: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.hasDependencies) {
      let addButtons = document.getElementsByClassName('savage-form-group-array-item-add-button disabled');
      if (addButtons.length > 0) {
        for (let button of addButtons) {
          button.disabled = true;
        }
      }
      let deleteButtons = document.getElementsByClassName('savage-form-group-array-item-delete-button disabled');
      if (deleteButtons.length > 0) {
        for (let button of deleteButtons) {
          button.disabled = true;
        }
      }
    }
  },
  computed: {
    ...mapPropsForSync('local', [
      'label',
      'isRequired',
      'isConditionallyShown',
      'conditionalLogicField',
      'conditionalLogicOperator',
      'conditionalLogicValues',
      'options',
      'sort',
    ]),
    valueValidation () {
      return {
        ...(clonedeep(this.optionValueValidation) || {}),
        // Enforce option value limits if any;
        ...{
          // "required" is special since we want to enforce options always have values
          required: {
            value: true,
            message: this.$t('common.validation.required')
          }
        }
      };
    }
  },
  methods: {
    hasUniqueOptionValues () {
      let hasDuplicates = false;
      // If there are 0 or 1 options, then the values cannot be duplicates
      if (this.localOptions && this.localOptions.length > 1) {
        const uniqueValues = new Set(); // Intentional case-sensitive compare
        hasDuplicates = this.localOptions
          .some(function(option) {
            if (option.value) {
              const previousSize = uniqueValues.size;
              uniqueValues.add(option.value); // Set.add will not add a new entry if it's a duplicate
              return previousSize === uniqueValues.size;
            } else {
              return true
            }
          });
      }

      return !hasDuplicates;
    },
    showBulkEdit () {
      this.$bvModal.show(this.bulkEditModalId);
    },
    updateFromBulkEdit () {
      this.localOptions = this.$refs['bulk-edit-form'].getUpdatedOptions();
    },
    async onBulkEditHide (evt) {
      const shouldConfirm = [
        'ok', // don't confirm if they aren't cancelling
        'event' // don't confirm if we have manually hidden after confirming
      ].includes(evt.trigger) === false;
      if (shouldConfirm) {
        const bulkEditHasChanges = this.$refs['bulk-edit-form'].hasChanges();

        if (bulkEditHasChanges) {
          evt.preventDefault();

          const shouldClose = await this.$bvModal.msgBoxConfirm(this.$t('common.lostChanges'), {
            title: this.$t('common.confirmSure'),
            size: 'sm',
            centered: true,
            okTitle: this.$t('common.yes'),
            cancelTitle: this.$t('common.no')
          });

          if (shouldClose) {
            this.$bvModal.hide(this.bulkEditModalId);
          }
        }
      }
    },
    getButtonClasses() {
      let classes = ['cb-btn', 'secondary'];
      if (this.hasDependencies) {
        classes.push('disabled');
      }
      return classes;
    },
  },
  created () {
    this.SortOptions = SortOptions;

    this.bulkEditModalId = `bulk-edit-modal-${uuid()}`;

    this.optionsGridValidation = {
      minItems: {
        value: 1,
        message: this.$t('formsBuilder.dataEntry.selectPresentation.optionRequiredValidationMessage')
      }
    };

    this.optionsGridCustomValidation = {
      'unique-option-values': {
        value: this.hasUniqueOptionValues,
        message: this.$t('formsBuilder.dataEntry.selectPresentation.optionRequireUniqueValuesValidationMessage')
      }
    };

    this.labelValidation = {
      maxLength: {
        value: 1000,
        message: this.$t('common.validation.maxLengthFormat', [1000])
      }
    };
  }
}
</script>
<style lang="scss">
  @import '@clickboarding/style/mixins';

  .forms-data-entry-element-select-options-grid {
    margin-top: 1rem !important;
  }

  .forms-data-entry-element-select-header-container {
    @include font-size-content-root;
    margin-bottom: 1rem;
  }

  .forms-data-entry-element-select-header-description {
    margin-right: 1rem;
  }

  .forms-data-entry-element-select-header-bulk-edit-link {
    white-space: nowrap;
  }
</style>
