<template>
  <div v-if="data" data-name-id="candidates.mainTitle">
    <cb-admin-view-title :title="$t('candidates.mainTitle')" class="cb-admin-view-title">
      <div class="ml-auto candidate-heading-buttons">
        <b-button v-if="data.downloadReport && data.downloadReport.event.url"
                  class="mr-3"
                  variant="outline-green"
                  @click="downloadReport">
          {{ $t('candidates.downloadButtonLabel') }}
        </b-button>
        <b-button v-if="data.userPermissions && data.userPermissions.canAddCandidate"
                  class="add-candidate-button"
                  variant="green"
                  @click="handleShowAdd">
          {{ $t('candidates.addCandidateButtonLabel') }}
        </b-button>
      </div>
    </cb-admin-view-title>
    <cb-view-section type="secondary" class="pt-0">
      <div>
        <b-form-checkbox
            @change="handleTestOnlyChange"
            v-model="showTestOnly"
            class="showTestOnly-toggle"
            style="margin-bottom: 10px;"
        >
          {{ data.showTestOnlyFilter.label }}
        </b-form-checkbox>
      </div>
    <div class="candidate-filters">
      <div class="filter-group">
        <div class="filter-item">
          <b-form-input
              v-if="data.search && selectedFilter[data.search.filterKey]"
              class="filter-input small-input"
              :placeholder="$t('candidates.searchPlaceholder')"
              :debounce="500"
              v-model="selectedFilter[data.search.filterKey][0].value"
              @update="searchHandler"
              data-name-id="$t('candidates.searchPlaceholder')"
          ></b-form-input>
        </div>
      </div>
      <!-- For infinite scroll, need to pass it...
      - data url
      - search param name
      - search value from input
      - flag to specify infinite (maybe that's just the url itself being defined) -->
      <multi-select v-for="filterUrl in filterUrls" v-if="filterUrl.items !=undefined"
                    :options="filterUrl.items.items"
                    :value="selectedFilter[filterUrl.filterKey]"
                    :search-placeholder="$t('common.filterSearchPlaceholder', [filterUrl.title.toLowerCase()])"
                    :close-on-select="false"
                    track-by="value"
                    search-by="label"
                    :title="filterUrl.title"
                    :key="filterUrl.filterKey"
                    @select="selectFilter(filterUrl.filterKey, $event, true)"
                    :dataUrl="filterUrl.url"
                    @atBottomOfList="atBottomOfMultiSelect"
                    @newSearchValue="doSearch"
                    :use-virtual-scroll="false"
                    :total-items-count="filterUrl.totalCount"
                    @clickAwayOnSearch="reloadClearedFilters('')"
                    data-name-id="filterUrl.title"
      >
        <template v-slot:option="{ option, isSelected }">
          <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
          </b-form-checkbox>
          <span v-text-middle-ellipsis="option.label"></span>
        </template>
      </multi-select>
      <multi-select v-for="filter in filtersWithoutUrlList"
                    :options="filter.items"
                    :value="selectedFilter[filter.filterKey]"
                    :search-placeholder="$t('common.filterSearchPlaceholder', [filter.title.toLowerCase()])"
                    :close-on-select="false"
                    track-by="value"
                    search-by="label"
                    :title="filter.title"
                    :key="filter.filterKey"
                    @select="selectFilter(filter.filterKey, $event, true)"
                    :dataUrl="filter.url"
                    :use-virtual-scroll="true"
      ><!--on the search-by need a way to get data from api instead of current items-->
        <template v-slot:option="{ option, isSelected }">
          <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
          </b-form-checkbox>
          <span v-text-middle-ellipsis="option.label"></span>
        </template>
      </multi-select>
      <b-button class="clear-filter-button small-button ml-3"
                variant="outline-green"
                @click="clearFilters">
        {{ $t('common.clearFilters') }}
      </b-button>
    </div>
    <b-table v-if="candidates"
             class="candidates-list-container new-table new-table--without-first-cell-border"
             ref="candidatesTable"
             v-bind="tableSettings"
             :items="candidates.candidates"
             show-empty
             no-local-sorting
             :empty-text="emptyText"
             @sort-changed="sortCandidates"
             :fields="[
      { key: 'select', label: '', thStyle: { width: '50px' }, sortable: false },
      { key: 'name', label: data.nameHeader, class: 'name-header-column', thStyle: { width: '35%' }, sortable: true },
      { key: 'processFlow', label: data.processFlowHeader, class: 'process-header-column', thStyle: { width: '35%' }, sortable: false },
      { key: 'location', label: data.locationHeader, class: 'location-header-column', thStyle: { width: '25%' }, sortable: false },
      { key: 'dueDate', label: data.dueDateHeader, thStyle: { width: '100px' }, sortable: true },
      { key: 'status', label: data.statusHeader, thStyle: { width: '145px' }, sortable: false }
    ]">
      <template v-slot:head(select)="data">
        <div class="multi-checkbox"
             :class="multiCheckboxStatus"
             @click="multiSelectProcessFlow"></div>
      </template>
      <template v-slot:cell(select)="{ item }">
        <b-checkbox :checked="isProcessFlowSelected(item.processFlows[0].subjectRequisitionId)"
                    :disabled="item.isAnonymized || !item.processFlows[0].subjectRequisitionId"
                    @change="selectProcessFlow(item.processFlows[0].subjectRequisitionId)"
        >
        </b-checkbox>
      </template>
      <template v-slot:cell(name)="{ item }">
        <a v-text-middle-ellipsis="item.name"
             class="candidate-name-cell"
             :class="{ 'anonymized': item.isAnonymized }"
             @click="showCandidateDetails(item)" data-name-id="item.name" style="color: #2F72AC !important;">
      </a>
      </template>
      <template v-slot:cell(dueDate)="{ item }">
        {{ displayTableCell(item.processFlows[0].dueDate) }}
      </template>
      <template v-slot:cell(location)="{ item }">
        <div v-text-middle-ellipsis="displayTableCell(item.processFlows[0].location)">
        </div>
      </template>
      <template v-slot:cell(processFlow)="row">
        <div class="process-cell">
          <div v-if="row.item.processFlows[0].processFlow"
               class="process-cell-title"
               v-text-middle-ellipsis="row.item.processFlows[0].processFlow"
               @click="handleShowDetail(row.item.processFlows[0])"></div>
          <div v-else>
            -
          </div>
          <b-button v-if="row.item.processFlows.length > 1"
                    class="show-process-flows"
                    variant="outline-primary"
                    size="sm"
                    @click="row.toggleDetails">
            {{ row.detailsShowing ? 'hide' : 'show' }} all
          </b-button>
        </div>
      </template>
      <template v-slot:cell(status)="{ item }">
        <div class="status-label"
             :class="`status-label--${item.processFlows[0].status.statusEnum}`"
             v-if="item.processFlows[0].status">
          {{ item.processFlows[0].status.label }}
        </div>
        <span v-else>
          -
        </span>
      </template>
      <template v-slot:row-details="{ item }">
        <div class="detailed-row"
             v-for="flow in item.processFlows.slice(1, item.processFlows.length + 1)">
          <div class="select-column">
            <b-checkbox :checked="isProcessFlowSelected(flow.subjectRequisitionId)"
                        @change="selectProcessFlow(flow.subjectRequisitionId)">
            </b-checkbox>
          </div>
          <div class="name-column"
               :style="`width: ${tableColumnWidth.name}px`">
          </div>
          <div class="process-column"
               :style="`width: ${tableColumnWidth.process}px`">
            <p class="process-cell-title"
               v-text-middle-ellipsis="flow.processFlow"
               @click="handleShowDetail(flow)">
            </p>
          </div>
          <div class="location-column"
               :style="`width: ${tableColumnWidth.location}px`"
               v-text-middle-ellipsis="flow.location">
          </div>
          <div class="due-date-column">
            {{ flow.dueDate }}
          </div>
          <div class="status-column">
            <div class="status-label"
                 :class="`status-label--${flow.status.statusEnum}`"
                 v-if="flow.status">
              {{ flow.status.label }}
            </div>
            <span v-else>-</span>
          </div>
        </div>
      </template>
    </b-table>
    <div v-if="showPagination" class="pagination-container">
      <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm"
                    class="per-page-options">
        <b-form-select
            id="per-page-select"
            :value="pagination.itemsPerOnePage"
            :options="paginationSettings.pageOptions"
            @change="handlePageSizeChange">
        </b-form-select>
      </b-form-group>
      <b-pagination
          :value="pagination.currentPage"
          :total-rows="totalRows"
          :per-page="pagination.itemsPerOnePage"
          v-bind="paginationSettings"
          @change="handlePageChange">
      </b-pagination>
    </div>
    
    <multi-edit v-if="allSelectedProcessFlowIds.length"
                :batch="data.batch"
                :selected-ids="allSelectedProcessFlowIds"
                :button-titles="{
                  'Cancel': $t('candidates.multiEditCancelTitle')
                }"
                :generate-query="generateQuery"
                @update="updateCandidates"
    ></multi-edit>
    </cb-view-section>
  </div>
</template>
<script>
import AppSettings from 'appSettings';
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import CbViewSection from "general/cb-view-section.vue";
import candidatesManagementService from 'api/candidatesManagementService';
import {mapGetters} from 'vuex';
import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../constants/defaultTableSettings";
import MultiSelect from 'common/components/multi-select.vue'
import MultiEdit from "../general/multi-edit.vue"
import ellipsisText from "../directives/text-middle-ellipsis";
import {throttle} from 'lodash'

export default {
  name: 'candidates-list',
  props: {
    handleShowDetail: {
      required: true,
      type: Function
    }
  },
  directives: {
    textMiddleEllipsis: {
      bind(el, {value}) {
        ellipsisText(el, {value})
      },
      update(el, {value}) {
        el.innerHTML = ''
        ellipsisText(el, {value})
      }
    }
  },
  components: {
    MultiSelect,
    MultiEdit,
    CbAdminViewTitle,
    CbViewSection
  },
  data() {
    return {
      data: null,
      candidates: null,
      filters: [],
      filterUrls: [],
      filtersUrlList: [],
      filtersWithoutUrlList: [],
      currFilterKey: "",
      selectedFilter: {},
      selectedProcessFlows: {1: []},
      tableSettings: TABLE_SETTINGS,
      paginationSettings: PAGINATION_SETTINGS,
      totalRows: 0,
      takeLocations: 11,
      takeTeamMembers: 11,
      skipLocations: 0,
      skipTeamMembers: 0,
      currListSize: 10,
      showAnonymized: false,
      showTestOnly: false,
      showAnonymizedFilter: {},
      showTestOnlyFilter: {},
      sorting: {
        sortBy: undefined,
        sortDir: undefined
      },
      pagination: {
        currentPage: 1,
        itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0]
      },
      tableColumnWidth: {
        name: null,
        process: null,
        location: null
      }
    }
  },
  methods: {
    clearFilters () {
      this.setDefaultFilters()
      this.resetCurrentPage()
      this.loadCandidates()
      this.reloadClearedFilters()
    },
    searchHandler() {
      this.resetCurrentPage()
      this.saveFilters()
      this.loadCandidates()
    },
    handleAnonymizedChange() {
      this.showAnonymized = !this.showAnonymized
      this.resetCurrentPage()
      this.loadCandidates()
    },
    handleTestOnlyChange() {
      this.showTestOnly = !this.showTestOnly
      this.resetCurrentPage()
      this.loadCandidates()
    },
    sortCandidates(options) {
      this.sorting.sortBy = options.sortBy[0].toUpperCase() + options.sortBy.slice(1)
      this.sorting.sortDir = options.sortDesc ? 'Desc' : 'Asc'
      this.loadCandidates()
    },
    multiSelectProcessFlow() {
      if (this.selectedProcessFlows[this.pagination.currentPage].length === this.allProcessFlowIds.length) {
        this.selectedProcessFlows[this.pagination.currentPage] = []
      } else {
        this.selectedProcessFlows[this.pagination.currentPage] = this.allProcessFlowIds
      }
    },
    showCandidateDetails(candidate) {
      if (candidate.isAnonymized) return
      this.$_cb.router.changeView(AppSettings.viewCandidatesView, {candidateId: candidate.id}, null)
    },
    downloadReport() {
      if (this.data.downloadReport.event.url) {
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = this.data.downloadReport.event.url
        link.target = '_blank'
        this.$el.append(link)
        link.click()
        link.parentNode.removeChild(link)
      }
    },
    isProcessFlowSelected(processFlowId) {
      return this.selectedProcessFlows[this.pagination.currentPage].indexOf(processFlowId) > -1
    },
    selectProcessFlow(processFlowId) {
      const selectedProcessFlowIndex = this.selectedProcessFlows[this.pagination.currentPage].indexOf(processFlowId)
      if (selectedProcessFlowIndex > -1) {
        this.selectedProcessFlows[this.pagination.currentPage].splice(selectedProcessFlowIndex, 1)
      } else {
        this.selectedProcessFlows[this.pagination.currentPage].push(processFlowId)
      }
    },
    handleShowAdd() {
      this.$_cb.router.changeView(AppSettings.viewCandidatesAdd, null);
    },
    async loadCandidates() {
      this.candidates = await candidatesManagementService.getCandidates(this.data.getCandidatesUrl, this.generateQuery(this.selectedFilter || []))
      this.totalRows = this.candidates.totalCount
    },
    setDefaultFilters () {
      this.filters.forEach(filter => {
        this.$set(this.selectedFilter, filter.filterKey, [])
      })

      // Set search filter
      this.$set(this.selectedFilter, this.data.search.filterKey, [{value: ''}])
    },
    async loadFilters() {
      const response = await candidatesManagementService.getCandidatesFilters(this.data.getFiltersUrl)
      // separate filters into url type and itemlist type
      this.filters = response.filters//.filter(x => x.typeEnum.toLowerCase() !== "urllist");
      this.filtersWithoutUrlList = response.filters.filter(x => x.typeEnum.toLowerCase() !== "urllist");
      this.filtersUrlList = response.filters.filter(x => x.typeEnum.toLowerCase() === "urllist");
      // This should be done by the infinite scroll component
      const firstPageFetches = response.filters
        .filter(f => f.typeEnum == "UrlList" && f.url)
        .map(async f => {
          let skip = f.filterKey == this.FILTER_KEY_LOCATION ? this.skipLocations : this.skipTeamMembers
          let take = f.filterKey == this.FILTER_KEY_LOCATION ? this.takeLocations : this.takeTeamMembers
          let url = f.url + `&skip=${skip}&take=${take}`
          f.items = await candidatesManagementService.getCandidateFilterPage(url)
          if(f.items.totalCount>0){
          f.items.items = f.items.items.map((x) => {
            return {
              "label": x.name,
              "value": f.filterKey == this.FILTER_KEY_LOCATION ? x.id : x.clientUserId
            }
          });
        }
          return f
        })

      const filtersWithFirstPage = await Promise.all(firstPageFetches);
      this.filterUrls = filtersWithFirstPage
      this.setDefaultFilters()
    },
    async loadMoreFilters(filterKey, searchValue = null) {
      const nextPageFetches = this.filtersUrlList
        .map(async f => {
          if(f.filterKey === filterKey) {
            let skip = f.filterKey == this.FILTER_KEY_LOCATION ? this.skipLocations : this.skipTeamMembers
            let take = f.filterKey == this.FILTER_KEY_LOCATION ? this.takeLocations : this.takeTeamMembers
            let url = f.url + `&skip=${skip}&take=${take}`
            
            if(searchValue !== null && searchValue !== undefined){
              url = url + `&name=${searchValue}`
            }

            let newItems = await candidatesManagementService.getCandidateFilterPage(url)
            let newItemsMapped = Array.isArray(newItems.items) ? newItems.items.map((x) => {
              return {
                "label": x.name,
                "value": f.filterKey == this.FILTER_KEY_LOCATION ? x.id : x.clientUserId
              }
            }) : [];
            
            f.items.items = (searchValue !== null) ? newItemsMapped : [...f.items.items, ...newItemsMapped]
          }
          return f
        })

      const filtersWithNextPage = await Promise.all(nextPageFetches);
      this.filterUrls = filtersWithNextPage;    
    },
    generateQuery(filtersSelected) {
      if (!filtersSelected) {
        filtersSelected = this.selectedFilter
      }

      let filters = {}
      Object.keys(filtersSelected).forEach(selectedFilter => {
        if (filtersSelected[selectedFilter].length) {
          filters[selectedFilter] = filtersSelected[selectedFilter].map(elem => {
            return elem.value
          }).join(",")

          if (!filters[selectedFilter]) {
            delete filters[selectedFilter]
          }
        }
      })

      // Sorting
      if (this.sorting.sortBy) {
        filters['sortBy'] = this.sorting.sortBy
        filters['sortDir'] = this.sorting.sortDir
      }
      // Pagination
      filters['skip'] = this.pagination.itemsPerOnePage * (this.pagination.currentPage - 1)
      filters['take'] = this.pagination.itemsPerOnePage

      //Anonymized
      if (this.showAnonymized) {
        filters[this.data.showAnonymizedFilter.filterKey] = true
      }

      if (this.showTestOnly) {
        filters[this.data.showTestOnlyFilter.filterKey] = true
      }

      return filters
    },
    resetCurrentPage() {
      this.pagination.currentPage = 1

      this.selectedProcessFlows = {1: []}
    },
    saveFilters() {
      if (Modernizr && Modernizr.sessionstorage) sessionStorage.setItem(this.storage, JSON.stringify(this.selectedFilter))
    },
    selectFilter(filterKey, selectedItem, updateNow) {      
      let currentFilter = this.selectedFilter[filterKey]
      const index = currentFilter.findIndex(item => item.value === selectedItem.value)

      if (index > -1) {
        currentFilter.splice(index, 1)
      } else {
        currentFilter.push(selectedItem)
      }

      this.resetCurrentPage()

      if (updateNow) {
        this.saveFilters()
        this.loadCandidates()
      }
    },
    async updateCandidates(data) {
      if (data) {
        await this.loadCandidates()
      }
      this.selectedProcessFlows = {1: []}
    },
    displayTableCell(value) {
      return value || '-'
    },
    handlePageChange(value) {
      this.pagination.currentPage = value
      if (!this.selectedProcessFlows[value]) {
        this.$set(this.selectedProcessFlows, value, [])
      }
      this.loadCandidates()
    },
    handlePageSizeChange(value) {
      this.pagination.itemsPerOnePage = value
      this.resetCurrentPage()
      this.loadCandidates()
    },
    updateTableColumnWidth() {
      try {
        this.tableColumnWidth.name = document.querySelector('.name-header-column') ?  document.querySelector('.name-header-column').getBoundingClientRect().width : 0
        this.tableColumnWidth.process = document.querySelector('.process-header-column') ? document.querySelector('.process-header-column').getBoundingClientRect().width : 0
        this.tableColumnWidth.location = document.querySelector('.location-header-column') ? document.querySelector('.location-header-column').getBoundingClientRect().width : 0
      } catch (e) {
        console.log(e)
      }
    },
    async atBottomOfMultiSelect(id) {
      let filterKey = "";
      if(id == this.FILTER_ID_LOCATION) {
        this.skipLocations = this.skipLocations + this.takeLocations;
        this.takeLocations = this.currListSize;
        filterKey = this.FILTER_KEY_LOCATION;
      } else if(id == this.FILTER_ID_TEAMMEMBER){
        this.skipTeamMembers = this.skipTeamMembers + this.takeTeamMembers;
        this.takeTeamMembers = this.currListSize;
        filterKey = this.FILTER_KEY_TEAMMEMBER;
      }
      await this.loadMoreFilters(filterKey);
    },
    async doSearch(searchData) {
      if(searchData == null || searchData == undefined) return;
       let filterKey = "";

       clearTimeout(this.search_timeout);
        this.search_timeout = setTimeout(async() => {
          if(searchData.id == this.FILTER_ID_LOCATION) {
            this.skipLocations = 0;
            this.takeLocations = this.currListSize;
            filterKey = this.FILTER_KEY_LOCATION;
          } else if(searchData.id == this.FILTER_ID_TEAMMEMBER){
            this.skipTeamMembers = 0;
            this.takeTeamMembers = this.currListSize;
            filterKey = this.FILTER_KEY_TEAMMEMBER;
          }
          this.currFilterKey = filterKey;

          await this.loadMoreFilters(filterKey, searchData.searchValue);
      }, 500);
    },
    async reloadClearedFilters(searchValue = null) {
      if(this.currFilterKey === "") return;
      if(this.currFilterKey === this.FILTER_KEY_LOCATION) {
        this.skipLocations = 0;
        this.takeLocations = this.currListSize;
      }
      if(this.currFilterKey === this.FILTER_KEY_TEAMMEMBER) {
        this.skipTeamMembers = 0;
        this.takeTeamMembers = this.currListSize;
      }
      await this.loadMoreFilters(this.currFilterKey,searchValue);
    }
  },
  watch: {
    clientId: { // Watch the clientId in the store, to reload the Candidates View Tab if it changes
      immediate: true,
      deep: false,
      async handler(newValue) {
        if (this.clientId) {
          const response = await candidatesManagementService.getViewTab(this.clientId);
          this.data = response

          await this.loadFilters()

          try {
            if (typeof (sessionStorage) !== 'undefined') {
              const savedFilter = JSON.parse(sessionStorage.getItem(this.storage) || "{}")
              this.selectedFilter = savedFilter ? Object.assign({}, this.selectedFilter, savedFilter) : this.selectedFilter
            }
          } catch (e) {
            console.log(e)
          }

          await this.loadCandidates()
          this.$nextTick(() => {
            this.updateTableColumnWidth()
          })
        }
      }
    }
  },
  computed: {
    ...mapGetters(['clientId']),
    multiCheckboxStatus() {
      if (this.selectedProcessFlows[this.pagination.currentPage].length === this.allProcessFlowIds.length && this.allProcessFlowIds.length !== 0) {
        return 'full'
      } else if (this.selectedProcessFlows[this.pagination.currentPage].length > 0) {
        return 'half'
      } else {
        return 'empty'
      }
    },
    allProcessFlowIds() {
      if (this.candidates.candidates) {
        return this.candidates.candidates.reduce((ids, candidate) => {
          if (!candidate.isAnonymized) {
            ids = ids.concat(candidate.processFlows.map(process => process.subjectRequisitionId))
          }
          return ids
        }, []).filter(id => id)
      } else {
        return []
      }
    },
    allSelectedProcessFlowIds() {
      return Object.keys(this.selectedProcessFlows).reduce((ids, key) => {
        ids = ids.concat(this.selectedProcessFlows[key])

        return ids
      }, [])
    },
    storage() {
      return `cb${AppSettings.appType}candidateFilter`
    },
    hasCandidates() {
      return !!(this.candidates && this.candidates.candidates && this.candidates.candidates.length);
    },
    showPagination() {
      return this.hasCandidates && this.totalRows > PAGINATION_SETTINGS.pageOptions[0];
    },
    showFilters() {
      return this.filters.length > 0 && this.data.search && this.selectedFilter[this.data.search.filterKey] && !this.hasCandidates && !this.candidates.isFiltered
    },
    emptyText() {
      return !this.hasCandidates && this.candidates.isFiltered ? this.data.filterNoDataLabel : this.data.noDataLabel
    }
  },
  created() {
    this.FILTER_KEY_LOCATION = "lgids";
    this.FILTER_KEY_TEAMMEMBER = "cuids";
    this.FILTER_ID_LOCATION = "locations";
    this.FILTER_ID_TEAMMEMBER = "teammembers";
  },
  mounted() {
    this.throttledUpdateTableColumnWidth = throttle(this.updateTableColumnWidth, 16)
    window.addEventListener('resize', this.updateTableColumnWidth, true)
  },
  updated(){
    console.log("FILTERS:updated",this.filters)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttledUpdateTableColumnWidth)
  }
}
</script>

<style lang="scss">
.candidate-filters {
  display: flex;
  margin-bottom: 1rem;

  & > div {
    margin-right: 1rem;
  }

  & > div:last-of-type {
    margin-right: 0;
  }

  .filter-group {
    display: block;
    flex-grow: 1;
    margin-right: 1rem;

    .filter-item {
      input::placeholder {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 18px;
        color: #838383;
      }
    }
  }
}

.selected {
  background: #2a5ea0;
}

.candidate-name-cell {
  display: flex;
  font-weight: bold;
  color: #3656A6;

  &:hover {
    cursor: pointer;
  }

  &.anonymized {
    color: #454545;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.detailed-row {
  display: flex;
  background: #F6F6F6;

  &:hover {
    background-color: #F0F0F0;
  }

  .select-column {
    padding: 0.75rem;
    width: 50px;
  }

  .name-column {
    padding: 0.75rem;
    width: 35%;
  }

  .due-date-column {
    padding: 0.75rem;
    min-width: 100px;
  }

  .location-column {
    padding: 0.75rem;
    width: 25%;
  }

  .process-column {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.75rem;
    width: 35%;

    .process-cell-title {
      margin: 0;
    }
  }

  .status-column {
    padding: 0.75rem;
    min-width: 145px;
  }
}

.status-label {
  padding: 5px 10px;
  border-radius: 12px;
  display: inline-block;

  &--Completed {
    color: #0B9BA7;
    background: #D5F4F8;
  }

  &--Canceled {
    color: #454545;
    background: #EBEBEB;
  }

  &--Pending {
    color: #4C593F;
    background: #EAF3E1;
  }

  &--Started {
    color: #003EE5;
    background: #E3EEFF;
  }

  &--NeedsAttention {
    color: #C46A00;
    background: #F8EFD5;
  }
}

.process-cell {
  display: flex;
  align-items: center;

  &-title {
    display: inline;
    margin: 0 0.5rem 0 0;
    border-bottom: 1px dashed #B3B3B3;

    &:hover {
      cursor: pointer;
    }
  }
}

.middle-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;

  .first-part {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
  }

  .last-part {
    white-space: nowrap;
  }
}

.multi-checkbox {
  position: relative;
  width: 16px;
  height: 16px;
  background: #FFFFFF;
  border: 1px solid #CBD6E0;
  box-sizing: border-box;
  border-radius: 4px;

  &.half {
    background: #3384CA;

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: 10px;
      height: 2px;
      background: #FFFFFF;
      border-radius: 50px;
    }
  }

  &.full {
    background: #3384CA;

    &:before {
      position: absolute;
      top: 6px;
      left: 4px;
      transform: rotate(-45deg);
      content: '';
      width: 9px;
      height: 2px;
      background: #FFFFFF;
      border-radius: 50px;
    }

    &:after {
      position: absolute;
      top: 7px;
      left: 2px;
      transform: rotate(45deg);
      content: '';
      width: 5px;
      height: 2px;
      background: #FFFFFF;
      border-radius: 50px;
    }
  }
}

.pointer-none {
  pointer-events: none;
}
</style>
