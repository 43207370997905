import get from 'lodash.get';
import set from 'lodash.set';
import * as PresentationToSimplefieldDesigner from 'forms/data-entry-mappers/element-types/base/presentation-to-simplefield-designer';
import FormsDataEntryElementSelect from 'forms/forms-data-entry-element-select.vue';
import { i18n } from 'common/lang-config';
import 'common/polyfills/object.assign.polyfill';

const SortOptions = {
  Alpha: "Alpha",
  Ordinal: "Ordinal"
};

function canHandlePresentation (presentationKey) {
  return presentationKey === "Select";
}

function canHandleType (type) {
  return type === "Select";
}

function mapPresentationToDesigner (element, presentationKey, updateModelFn) {
  const model = element.availablePresentations[presentationKey];
  // if the model already has this set, let's not overwrite it with the element level values
  // this is really just dealing with adding a new element vs editing an existing element
  if (model && !model.availableConditionalFields) {
    model.availableConditionalFields = element.availableConditionalFields;
  }
  const optionsSource = get(model, 'designer.presentationOptions.optionsSource', null);
  const label = i18n.t('formsBuilder.dataEntry.designerLabels.select');

  const simpleFieldDesigner = PresentationToSimplefieldDesigner.map(...arguments);
  set(simpleFieldDesigner, 'fields.props.hidePlaceholder', true);

  const designer = {
    key: presentationKey,
    label,
    container: simpleFieldDesigner.container
  };

  if (optionsSource === "PredefinedList") {
    return {
      ...designer,
      fields: simpleFieldDesigner.fields
    };
  } else {
    return {
      ...designer,
      fields: {
        component: FormsDataEntryElementSelect,
        props: {
          label: get(model, 'label', null),
          isRequired: get(model, 'validation.required.value', false),
          isConditionallyShown: get(model, 'isConditionallyShown', false),
          availableConditionalFields: get(model, 'availableConditionalFields', []),
          conditionalLogicField: get(model, 'conditionalLogic.field', ''),
          conditionalLogicOperator: get(model, 'conditionalLogic.operator', null),
          conditionalLogicValues: get(model, 'conditionalLogic.values', []),
          options: get(model, 'options', null),
          sort: get(model, 'sort', SortOptions.Ordinal),
          isAlwaysRequired: get(model, 'designer.presentationOptions.isAlwaysRequired', false),
          optionValueValidation: get(model, 'designer.presentationOptions.valueValidation', null),
          hasDependencies: get(model, 'hasDependencies', false),
        },
        events: {
          'update:label': (v) => updateModelFn('label', v),
          'update:isRequired': (v) => updateModelFn('validation.required.value', v),
          'update:options': (v) => updateModelFn('options', v),
          'update:sort': (v) => updateModelFn('sort', v),
          'update:isConditionallyShown': (v) => updateModelFn('isConditionallyShown', v),
          'update:conditionalLogicField': (v) => updateModelFn('conditionalLogic.field', v),
          'update:conditionalLogicOperator': (v) => updateModelFn('conditionalLogic.operator', v),
          'update:conditionalLogicValues': (v) => updateModelFn('conditionalLogic.values', v),
        },
      }
    };
  }
}



export { SortOptions, canHandlePresentation, canHandleType, mapPresentationToDesigner }