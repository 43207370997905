<template>
  <ag-grid-vue 
    style="width: 100%; height: 95%;" 
    @grid-ready="onGridReady" 
    :rowModelType="rowModelType"
    :serverSideInfiniteScroll="true" 
    :pagination="true" 
    :paginationPageSize="paginationPageSize"
    :cacheBlockSize="cacheBlockSize" 
    :animateRows="true" 
    class="ag-theme-alpine" 
    :onFilterChanged="onFilterChanged"
    :columnDefs="columnDefs" 
    :defaultColDef="defaultColDef" 
    :suppressContextMenu="false"
    :getContextMenuItems="getContextMenuItems"
    :suppressMenuHide="true" 
    :getRowId="getRowId"
    :sideBar="sideBar" 
    :rowSelection="rowSelection"
    :rowGroupPanelShow="rowGroupPanelShow" 
    :autoGroupColumnDef="autoGroupColumnDef" 
    :getServerSideGroupLevelParams="getServerSideGroupLevelParams" 
    :serverSideFilterAllLevels="true"
    :getChildCount="getChildCount">
  </ag-grid-vue> 
</template>
<script>
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import ReportsService from "./reports.service";
import * as _ from "lodash";

let gridApi = null;
let gridColumnApi = null;
let latestRequestParams = null;

function resetFilterSortState() {
  const columnState = {
    state: [
      {
        colId: 'processDueDate',
        sort: 'desc'
      }
    ]
  }
  gridColumnApi.applyColumnState(columnState);
  gridApi.setFilterModel(null); //clears all filters  
}

export default {
  name: "report-base",
  data() {
    return {
      columnDefs: null,
      rowData: null,
      defaultColDef: null,
      filter: true,
      rowModelType: 'serverSide',
      paginationPageSize: ReportsService.getPageSize(),
      cacheBlockSize: ReportsService.getPageSize(),
      startRow: 0,
      endRow: ReportsService.getPageSize(),
      sideBar: null,
      rowSelection: null,
      rowGroupPanelShow: null,
      autoGroupColumnDef: null,
      getServerSideGroupLevelParams: null,
      getChildCount: null,
      getRowId: null,
      totalResultCount: 0,
    };
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    },
    exportThreshold: {
      type: Number,
      required: true
    },
    clearFilter: { type: Date, required: false },
    downloadFile: { type: Object, required: false }
  },
  created() {
    this.setDefaultColDef();

    if (this.reportType == 'ProcessFlow' || this.reportType == 'Forms') {
      this.columnDefs = ReportsService.getColumnDefs(this.reportType)
    }
    else {
      var $this = this;

      ReportsService.getDynamicColumnDefs(this.reportType)
        .then(function (response) {
            $this.columnDefs = response;
        });
    }
      
    this.rowSelection = 'multiple';
    this.rowGroupPanelShow = 'always';
    this.rowModelType = 'serverSide';
    this.getChildCount = (data) => {
      return data ? data.childCount : undefined;
    };
    this.getRowId = (params) => {
      //Report API will add unique identifier to RowId property to use here
      return params.data.rowId;
    };
    this.sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressValues: true
          }
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        }
      ],
      position: 'right',
      defaultToolPanel: ''
    };
    this.$watch('clearFilter', () => { resetFilterSortState() });
    this.$watch('downloadFile', async (obj) => {
      if(this.totalResultCount >= this.exportThreshold) {
        this.$emit('exportLimitExceeded', true);
      } else {
        // get current col definitions into array
        let currCols = _.filter(gridColumnApi.getAllGridColumns(),
          x => x.visible === true && x.colDef.headerName !== 'Group') //when using the grouping feature, the grouping header column is included in the list of returned columns and needs to be filtered out
          .map((c) => c.colDef.field);
        this.$emit('loadingExportResults', true);
        let response = await ReportsService.getExport(latestRequestParams, currCols, this.reportType, obj.type);
        const fileName = response.headers["content-disposition"].split("filename=")[1];
        const mimeType = response.headers["Content-Type"];
        saveFile(response.data, fileName, mimeType);
        this.$emit('loadingExportResults', false);
      }
    });
    this.getServerSideGroupLevelParams = (params) => {
      var noGroupingActive = params.rowGroupColumns.length == 0;
      var res;
      if (noGroupingActive) {
        res = {
          // infinite scrolling
          infiniteScroll: true,
          // 100 rows per block
          cacheBlockSize: 100,
          // purge blocks that are not needed
          maxBlocksInCache: 2,
        };
      } else {
        res = {
          infiniteScroll: true, 
          cacheBlockSize: 25,
          maxBlocksInCache: -1, // never purge blocks
        };
      }
      return res;
    };
  },
  methods: {    
      async onGridReady(params) {
      gridApi = params.api;
      gridColumnApi = params.columnApi;

      resetFilterSortState();

      function setColumnsToFit() {
        gridApi.sizeColumnsToFit(params)
      }

      const updateData = async (data) => {
        // create datasource with a reference to the service
        var datasource =  this.createServerSideDatasource(ReportsService, this.reportType);
        // register the datasource with the grid
        params.api.setServerSideDatasource(datasource);

        setTimeout(setColumnsToFit, 2000);
      };

      await updateData([]);
    },
    createServerSideDatasource(service, reportType) {
      return {
        getRows: async (params) => {
          latestRequestParams = params.request; //wasn't sure how else to get this from gridapi
          // get data for request
          let response = await service.getData(params.request, reportType);
          params.success({
            rowData: response.data || [],
            rowCount: response.totalCount,
          });
          if(response.totalResultCount !== null) {
            this.totalResultCount = response.totalResultCount;
          }
        },
      };
    },
      onFilterChanged(params) {
      const setFilterPf = params.api.getFilterInstance('processFlowName');
      setFilterPf.refreshFilterValues();
      const setFilterStep = params.api.getFilterInstance('currentStep');
      setFilterStep.refreshFilterValues();
      const setFilterLoc = params.api.getFilterInstance('locationName');
      setFilterLoc.refreshFilterValues();
      const setFilterStatus = params.api.getFilterInstance('processFlowStatus');
      setFilterStatus.refreshFilterValues();
      const setFilterExp = params.api.getFilterInstance('employeeExperience');
      setFilterExp.refreshFilterValues();
      const setFilterDue = params.api.getFilterInstance('processDueDate');
      setFilterDue.refreshFilterValues();
      const setFilterComp = params.api.getFilterInstance('completionDate');
      setFilterComp.refreshFilterValues();
      //if grouped reloadgrouping
    },
    setDefaultColDef: function () {
      this.defaultColDef = ReportsService.getDefaultColumnDef();
    },
    getContextMenuItems() {
      var result = [
        'copy',
        'copyWithHeaders',
      ];
      return result;
    }
  },
  components: {
    AgGridVue,
  }
};

function saveFile(data, filename, objUrlType) {
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(new Blob([data],
      { type: objUrlType }));

    a.href = url;
    a.download = filename;
    a.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0)
  }
}

</script>
<style lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
</style>