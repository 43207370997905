<template>
    <div v-if="status">
        <div>
            <svg class="action-center-item-status-svg" v-bind:class="icon.class">
              <use v-bind="{'xlink:href': icon.id }" ></use>
            </svg>
        </div>
        <div>{{ dateLabel ? dateLabel : getDateLabel() }}: {{ friendlyDate(date) }}</div>
    </div>
</template>
<script>
import GetStartedSvg from "@clickboarding/assets/icons/messages/get-started-icon.svg";
import PendingSvg from "@clickboarding/assets/icons/messages/pending.svg";
import SuccessSvg from "@clickboarding/assets/icons/messages/success.svg";
import ErrorSvg from "@clickboarding/assets/icons/messages/error-warning.svg";
import DateFns from "date-fns";
import moment from "moment";

export default {
  name: "action-center-item-status",
  props: {
    status: {
      type: String,
      required: true
    },
    dateLabel: {
      type: String,
      required: false
    },
    date: {
      type: String,
      required: true
    }
  },
  created() {
    this.icons = {
      success: "#" + SuccessSvg.id,
      pending: "#" + PendingSvg.id,
      getStarted: "#" + GetStartedSvg.id,
      overdue: "#" + ErrorSvg.id
    };
  },
  computed: {
    icon: function() {
      const baseClass = "action-center-item-status-";

      switch (this.status) {
        case "Completed": return { id: this.icons.success, class: baseClass + "success" }
        case "Due": return { id: this.icons.getStarted, class: baseClass + "getstarted" }
        case "Pending":  return { id: this.icons.pending, class: baseClass + "pending" }
        case "Overdue": return { id: this.icons.overdue, class: baseClass + "overdue" }
      }
    },
  },
  methods: {
    getDateLabel() {
      switch (this.status) {
        case "Completed": return this.$i18n.t("actionCenter.itemRows.completedDateLabel");
        case "Pending": return this.$i18n.t("actionCenter.itemRows.pendingDateLabel");
        case "Overdue":
        case "Due": return this.$i18n.t("actionCenter.itemRows.dueDateLabel");
        default: return this.$i18n.t("actionCenter.itemRows.dueDateLabel");
      }
    },
    friendlyDate(date) {
      if (DateFns.isToday(date)) {
        return this.$i18n.t("friendlyDate.todayLabel");
      } else if (DateFns.isTomorrow(date)) {
        return this.$i18n.t("friendlyDate.tomorrowLabel");
      } else {
        return moment.utc(date).format(this.$i18n.t("friendlyDate.otherFormat.format"));
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";

.action-center-item-status-svg {
  display: block;
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.5rem;
}

.action-center-item-status-pending {
  color: $cb-slate-2;
}

.action-center-item-status-getstarted {
  color: $cb-orange-2;
}

.action-center-item-status-success {
  color: $cb-green-2;
}

.action-center-item-status-overdue {
  color: $cb-red-1;
}
</style>