<script>
import { 
  SavageFormDateInput,
  SavageFormConditionalElement, 
  SavageFormGroup,
  SavageFormGroupArray,
  SavageFormPhoneInput,
  SavageFormSelect,
  SavageFormTextInput,
  SavageFormTextArea,
  SavageGridColumnsDirective
} from "@clickboarding/savage";
import SavageFormNormalizedTextinput from "common/savage/savage-form-normalized-textinput.vue";
import SavageFormAutosuggest from "common/savage/savage-form-autosuggest.vue";
import SavageFormPdfViewer from "common/savage/savage-form-pdf-viewer.vue";
import SavageFormVideo from "common/savage/savage-form-video.vue";
import SavageFormHtml from "common/savage/savage-form-html.vue";
import SavageFormImage from "common/savage/savage-form-image.vue";
import SavageFormAgreementRadioGroup from "common/savage/savage-form-agreement-radio-group.vue";
import clonedeep from "lodash.clonedeep";
import isequal from "lodash.isequal";
import get from "lodash.get";

// Directives can't be registered locally in functional components
// so we are forced to register it globally to use it in the render
// function below. Registering it here because with the next version
// of Vue, this component can likely be migrated away from a functional
// component and we can then register it locally again.
import Vue from 'vue';
Vue.directive('savage-grid-columns', SavageGridColumnsDirective);

export default {
  name: "savage-form-fields-elements",
  functional: true,
  props: {
    elements: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: false,
      default: () => ({})
    },
    onUpdateModel: {
      type: Function,
      required: true
    },
    onUpdateModelProperty: {
      type: Function,
      required: true
    },
    keyPrefix: {
      type: String,
      required: false,
      default: 'root'
    },
    dateLocale: {
      type: String,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  render (createElement, context) {
    const elementsToRender = [];
    
    context.props.elements.forEach((element, index) => {
      function createKeyedElement(component, definition, children) {
        definition.key = `${context.props.keyPrefix}-${index}`;

        return children
          ? createElement(component, definition, children)
          : createElement(component, definition);
      }

      function createInputElement (component, definition) {
        definition.on = {
          input: function (val) {
            context.props.onUpdateModelProperty(element.datafield, val);
          }
        };

        return createKeyedElement(component, definition);
      }

      let newElement = null;

      switch (element.type) {
        case 'TextInput':
          let componentToCreate = SavageFormTextInput;
          let textInputProps = {
              label: element.label,
              placeholder: element.placeholder,
              title: element.title,
              validation: element.validation,
              options: {},
              value: context.props.model[element.datafield]
          };

          // prop existence check here to ensure both truthy and falsey values
          // are passed through if specified, otherwise let the component own
          // the default value of sensitive
          if ('sensitive' in element) {
            textInputProps.options.sensitive = element.sensitive;
          }

          if (element.normalization) {
            componentToCreate = SavageFormNormalizedTextinput;
            textInputProps.formState = context.props.formState;
            textInputProps.options.normalization = element.normalization;
          }

          newElement = createInputElement(
            componentToCreate, 
            { props: textInputProps }
          );
          break;
        case 'TextArea':
          newElement = createInputElement(SavageFormTextArea, {
            props: {
              label: element.label,
              placeholder: element.placeholder,
              title: element.title,
              validation: element.validation,
              value: context.props.model[element.datafield]
            }
          });
          break;
        case 'Select':
          newElement = createInputElement(SavageFormSelect, {
            props: {
              label: element.label,
              title: element.title,
              validation: element.validation,
              items: element.options,
              emptyItemLabel: context.parent.$t('common.select.emptyOption'),
              defaultValue: element.defaultValue,
              value: context.props.model[element.datafield]
            }
          });
          break;
        case 'AutoCompleteFixed':
          newElement = createInputElement(SavageFormAutosuggest, {
            props: {
              label: element.label,
              placeholder: element.placeholder,
              title: element.title,
              validation: element.validation,
              items: element.items,
              defaultValue: element.defaultValue,
              value: context.props.model[element.datafield]
            }
          });
          break;
        case 'DateInput':
          newElement = createInputElement(SavageFormDateInput, {
            props: {
              label: element.label,
              title: element.title,
              validation: element.validation,
              dateLocale: context.props.dateLocale,
              value: context.props.model[element.datafield]
            }
          });
          break;
        case 'PhoneInput':
          newElement = createInputElement(SavageFormPhoneInput, {
            props: {
              label: element.label,
              title: element.title,
              validation: element.validation,
              phoneValidationMessage: context.parent.$t('common.validation.phone'),
              ...(element.defaultCountryCode ? { initialCountry: element.defaultCountryCode } : {} ),
              value: context.props.model[element.datafield],
            }
          });
          break;
        case 'AgreementRadioGroup':
          newElement = createInputElement(SavageFormAgreementRadioGroup, {
            props: {
              agree: element.agree,
              disagree: element.disagree,
              legal: element.legal,
              validation: element.validation,
              signatureImageContent: context.props.model[element.signature.contentDatafield],
              signatureImageContentType: context.props.model[element.signature.contentTypeDatafield],
              signatureDescription: element.signature.description,
              value: context.props.model[element.datafield]
            }
          });
          break;
        case 'PDF':
          newElement = createKeyedElement(SavageFormPdfViewer, {
            props: {
              url: element.url
            }
          });
          break;
        case 'Html':
          newElement = createKeyedElement(SavageFormHtml, {
            props: {
              content: element.value,
            }
          });
          break;
        case 'Video':
          newElement = createKeyedElement(SavageFormVideo, {
            props: {
              provider: element.provider,
              url: element.url
            }
          });
          break;
        case 'Image':
          newElement = createKeyedElement(SavageFormImage, {
            props: {
              description: element.description,
              url: element.url
            }
          });
          break;
        case 'Group':
          newElement = createKeyedElement(SavageFormGroup, {
              props: {
                title: element.title
              }
            }, [
              createElement('div', {
                directives: [{
                  name: 'savage-grid-columns',
                  value: '2'
                }]
              },
                [
                  createElement(
                    'savage-form-fields-elements', {
                      key: `${context.props.keyPrefix}-${index}`,
                      props: {
                        keyPrefix: `${context.props.keyPrefix}-${index}`,
                        elements: element.elements,
                        model: context.props.model[element.datafield],
                        formState: context.props.formState,
                        dateLocale: context.props.dateLocale,
                        onUpdateModel: function (val) {
                          context.props.onUpdateModelProperty(element.datafield, val);
                        },
                        onUpdateModelProperty: function (datafield, val) {
                          const existingModel = context.props.model[element.datafield];
                          const localModel = existingModel ? clonedeep(existingModel) : {};
                          localModel[datafield] = val;
                          context.props.onUpdateModelProperty(element.datafield, localModel);
                        }
                      }
                    })]
              )
            ]
          );
          break;
        case 'Conditional':
          newElement = createKeyedElement(SavageFormConditionalElement, {
            props: {
              conditionals: element.conditionalElements,
              model: context.props.model,
              onUpdateModelProperty: context.props.onUpdateModelProperty
            },
            scopedSlots: {
              default (slotData) {
                return [
                  createElement('savage-form-fields-elements', {
                    key: `${context.props.keyPrefix}-${index}-${slotData.conditionalKey}`,
                    props: {
                      keyPrefix: `${context.props.keyPrefix}-${index}-${slotData.conditionalKey}`,
                      elements: slotData.conditionalElements,
                      model: context.props.model,
                      formState: context.props.formState,
                      dateLocale: context.props.dateLocale,
                      onUpdateModel: context.props.onUpdateModel,
                      onUpdateModelProperty: context.props.onUpdateModelProperty
                    }
                  })
                ];
              }
            }
          });
          break;
        case 'GroupArray':
          const isRequired = get(element.validation, 'minItems.value', 0) > 0;
          newElement = createInputElement(SavageFormGroupArray, {
            props: {
              items: context.props.model[element.datafield],
              title: element.title,
              itemTitle: element.itemTitle,
              minimumInitialItems: isRequired ? 1 : 0,
              validation: element.validation,
              addButtonClass: ['cb-btn', 'secondary'],
              addButtonText: context.parent.$t('common.groupArray.addButtonText.format', { itemTitle: element.itemTitle }),
              deleteButtonClass: ['cb-btn', 'secondary'],
              deleteButtonText: context.parent.$t('common.groupArray.deleteButtonText.format', { itemTitle: element.itemTitle }),
            },
            scopedSlots: {
              default (slotData) {
                return [
                  createElement('div', {
                    directives: [ 
                      {
                        name: 'savage-grid-columns',
                        value: '2'
                      }
                    ]
                  }, [
                    createElement('savage-form-fields-elements', {
                      key: `${context.props.keyPrefix}-${index}`,
                      props: {
                        keyPrefix: `${context.props.keyPrefix}-${index}-${slotData.index}`,
                        elements: element.itemElements,
                        model: slotData.item,
                        formState: context.props.formState,
                        dateLocale: context.props.dateLocale,
                        onUpdateModel: function (val) {
                          context.props.onUpdateModelProperty(element.datafield, val);
                        },
                        onUpdateModelProperty: function (datafield, val) {
                          const existingArrayModel = context.props.model[element.datafield];
                          const localModel = existingArrayModel ? clonedeep(existingArrayModel) : [];
                          if (localModel.length > slotData.index)
                            localModel[slotData.index][datafield] = val;
                          
                          context.props.onUpdateModelProperty(element.datafield, localModel);
                        }
                      }
                    })
                  ]),
                ];
              }
            }
          }
        );
        break;
        default:
          // ignore nothing if type is not recognized
      }

      // only push if type was supported and a new element was rendered
      if (newElement !== null) elementsToRender.push(newElement);
    });
    
    return elementsToRender;
  }
};
</script>
