<template>
  <div>
    <section v-show="pageRendered && !preview.show">
      <cb-view-title
        :title="apiData.formTitle"
        :icon="formIcon"
        :subtitle="apiData.pageTitle" />
      <div class="h-line"></div>

      <cb-primary-message />

      <savage-form-loader
        v-if="apiData.pageType === 'SavagePage'"
        :content-url="apiData.contentUrl"
        :event-url="apiData.next.url"
        :on-next-success="handleNextSuccess"
        :on-load="handlePageRendered"
        @submit-started="onSubmitStarted" 
        @submit-completed="onSubmitCompleted"
      />
      <form-document-upload
        v-else-if="apiData.pageType === 'DocumentUpload'"
        :content-url="apiData.contentUrl"
        :event-url="apiData.next.url"
        :on-next-success="handleNextSuccess"
        :on-load="handlePageRendered"
        @submit-started="onSubmitStarted" 
        @submit-completed="onSubmitCompleted"
      />
      <form-review v-else-if="apiData.pageType === 'Review'"
        :content-url="apiData.contentUrl"
        :event-url="apiData.next.url"
        :on-next-success="handleNextSuccess"
        :on-preview-pdf="handlePreviewPdf"
        :on-go-to-page="goToPage"
        :on-load="handlePageRendered"
        @submit-started="onSubmitStarted" 
        @submit-completed="onSubmitCompleted"
      />
      <div class="button-bar">
        <button class="cb-btn primary back" :disabled="disableBack" @click="handleBackClick()">
          <svg>
            <use v-bind="{'xlink:href': icons.leftArrow }"></use>
          </svg>
          {{$t('common.back')}}
        </button>
        <button class="cb-btn primary next" :disabled="disableNext"  @click="handleNextClick()">
          {{nextText}}
          <svg>
            <use v-bind="{'xlink:href': icons.rightArrow }"></use>
          </svg>
        </button>
      </div>
    </section>
    <section v-if="preview.show">
      <cb-pdf-viewer class="forms-standard-preview-pdf" :url="preview.contentUrl" :labels="preview.labels" />
      <div class="button-bar">
        <button type="button" class="cb-btn primary back" @click="hidePreview">
          <svg>
            <use v-bind="{'xlink:href': icons.leftArrow }"></use>
          </svg>
          {{$t('general.form.previewDone')}}
        </button>
      </div>
    </section>
  </div>
</template>

<script>
import LeftArrow from '@clickboarding/assets/icons/left-arrow.svg';
import RightArrow from '@clickboarding/assets/icons/right-arrow.svg';
import GenericFormTitleIcon from 'common/assets/icons/activity-title/generic-form-title-icon.svg';
import FormNavigationService from 'common/forms/form-navigation.service';
import FormReview from 'common/forms/form-review.vue';
import FormDocumentUpload from 'common/forms/form-document-upload.vue';
import SavageFormLoader from 'common/savage/savage-form-loader.vue';
import CbViewTitle from 'common/components/cb-view-title.vue';
import CbPdfViewerDataProvider from "common/components/pdf/cb-pdf-viewer-data-provider";
import CbPrimaryMessage from 'components/cb-primary-message.vue';

export default {
  name: 'forms-standard',
  components: {
    FormReview,
    FormDocumentUpload,
    SavageFormLoader,
    CbViewTitle,
    CbPdfViewer: () => import('@clickboarding/pdf-viewer'),
    CbPrimaryMessage
  },
  props: {
    contentUrl: {
      type: String,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  async created() {
    this.formIcon = GenericFormTitleIcon;
    this.icons = {
      leftArrow: "#" + LeftArrow.id,
      rightArrow: "#" + RightArrow.id
    };
    await this.loadApiData(this.contentUrl);

    // Kick off the async label load
    CbPdfViewerDataProvider.getData()
      .then(data => {
        this.preview.labels = data;
      })
      .catch(error => { 
        // If this errors, the cb-pdf-viewer is still functional
        // and will use the default labels
        console.warn('Failed to retrieve the CbPdfViewerLabels.', error);
      });
  },
  computed: {
    hasPreviousPages() {
      return this.apiData.previousPages && this.apiData.previousPages.length ? true : false;
    },
    disableBack() {
      return this.submitInProgress || !this.hasPreviousPages;
    },
    nextText() {
      let useNextText = (!this.apiData.isLastPage) ||
        // DocumentUpload forms are SubmitOnly, but we want to show submit
        (this.apiData.completionType === 'SubmitOnly' && this.apiData.pageType !== 'DocumentUpload');

      return this.$t(`common.${useNextText  ? 'next' : 'submit'}`);
    },
    disableNext() {
      return this.submitInProgress;
    }
  },
  methods: {
    async loadApiData(url) {
      this.pageRendered = false;
      const response = await this.$http.get(url);
      this.apiData = response.body;
    },
    shouldConfirmExit() {
      const isFormCompleted = this.apiData && this.apiData.isFormCompleted;
      return !isFormCompleted;
    },
    async handleBackClick() {
      await this.goToPage(this.apiData.previousPages.length - 1);
    },
    handleNextClick() {
      FormNavigationService.handleNextClick();
    },
    async goToPage(pageIndex) {
      await this.loadApiData(this.apiData.previousPages[pageIndex].url);
    },
    handlePreviewPdf(pdfContentUrl) {
      this.preview.contentUrl = pdfContentUrl;
      this.preview.show = true;
    },
    hidePreview() {
      this.preview.show = false;
      this.preview.contentUrl = null;
    },
    async handleNextSuccess() {
      if (this.apiData.isLastPage) await this.onComplete();
      else await this.loadApiData(this.contentUrl);
    },
    handlePageRendered() {
      this.pageRendered = true;
    },
    onSubmitStarted() {
      this.submitInProgress = true;
    },
    onSubmitCompleted() {
      this.submitInProgress = false;
    }
  },
  data() {
    return {
      apiData: {},
      pageRendered: false,
      preview: {
        show: false,
        labels: null,
        contentUrl: null
      },
      submitInProgress: false
    };
  }
};
</script>

<style lang="scss" scoped>
@import '@clickboarding/style/mixins';

.button-bar {
  display: flex;
  margin: $standard-cb-view-padding 0 0;

  .cb-btn {
    flex: 1 1 auto;
    margin: 0;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}
.forms-standard-preview-pdf {
  height: 40rem !important; // !important overrides the 100% height in cb-pdf-viewer
}
</style>
