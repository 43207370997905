<template>
  <section>
    <div v-savage-grid-columns="2">
      <savage-form-text-input :label="$t('formsBuilder.dataEntry.groupArray.itemTitleLabel')"
        :placeholder="$t('formsBuilder.dataEntry.groupArray.itemTitlePlaceholder')" :validation="{
          ...requiredValidation,
          ...titleMaxLengthValidation
        }" v-model="localItemTitle" />
      <savage-form-text-input :label="$t('formsBuilder.dataEntry.groupArray.titleLabel')"
        :placeholder="$t('formsBuilder.dataEntry.groupArray.titlePlaceholder')" :validation="titleMaxLengthValidation"
        v-model="localTitle" />
      <savage-form-integer-input :label="$t('formsBuilder.dataEntry.groupArray.minimumItemsLabel')"
        :placeholder="$t('formsBuilder.dataEntry.groupArray.minimumItemsPlaceholder')"
        :integerValidationMessage="$t('common.validation.integer')" :validation="minimumItemsValidation"
        :customValidation="minimumItemsCustomValidation" v-model="localMinimumItems" />
      <savage-form-integer-input :label="$t('formsBuilder.dataEntry.groupArray.maximumItemsLabel')"
        :placeholder="$t('formsBuilder.dataEntry.groupArray.maximumItemsPlaceholder')"
        :integerValidationMessage="$t('common.validation.integer')" :validation="maximumItemsValidation"
        :customValidation="maximumItemsCustomValidation" v-model="localMaximumItems" />
    </div>
    <section v-savage-grid-columns="gridColumns">
      <b-form-group class="mb-0 conditional-toggle">
        <!-- size sm so it matches the savage inputs -->
        <b-form-checkbox v-if="conditionalLogicFeatureEnabled" :id="$id('is-conditionally-shown')" size="sm"
          v-model="localIsConditionallyShown" switch @change="setDefaults">
          Conditionally Shown
        </b-form-checkbox>
      </b-form-group>
    </section>
    <section v-if="conditionalLogicFeatureEnabled" v-savage-grid-columns="gridColumns">
      <div v-show="showConditionalLogicConfig" :id="$id('conditional-logic-configuration')"
        class="conditional-logic-config-wrapper">
        <div class="conditional-logic-title"><strong>Conditional Logic Rules</strong></div>
        <div class="conditional-logic-config">
          <div class="conditional-logic-title">Show <strong><em>{{ localItemTitle }}</em></strong> when</div>
          <div>
            <b-form-group class="dropdown-flex">
              <savage-form-select label="Field" :id="$id('conditional-fields')" class="conditional-dropdown full-width"
                :items="conditionalFields" v-model="localConditionalLogicField" :validation="conditionalLogicValidation()"
                @input="loadValues" />
              <savage-form-select label="Operator" :id="$id('conditional-operators')" class="conditional-dropdown full-width"
                :items="operatorFields" v-model="localConditionalLogicOperator"
                :validation="conditionalLogicValidation()" />
              <div :id="$id('conditional-values')" class="conditional-values form-field conditional-dropdown full-width">
                <div class="form-field-element-wrapper">
                  <multi-select v-model="localConditionalLogicValues" :options="conditionalValues"
                    :close-on-select="false" label="Values" :title="itemTitle ? itemTitle : $id('values')" search-by="label" :fullWidth="true"
                    @select="onSelect($event)" :hideSearch="true" :required="showConditionalLogicConfig">
                    <template v-slot:option="{ option, isSelected }">
                      <b-form-checkbox :checked="isSelected(option, 'value')" class="pointer-none" />
                      <span v-text-middle-ellipsis="option.label"></span>
                    </template>
                  </multi-select>
                  <div v-show="conditionalLogicValues.length === 0" :id="$id('conditional-values-validation-message')"
                    class="savage-form-validation-message">
                    <strong>Values</strong>
                    <p>{{ this.$t('common.validation.required') }}</p>
                  </div>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import get from "lodash.get";
import { SavageFormRadioGroup, SavageFormTextInput, SavageFormIntegerInput, SavageGridColumnsDirective, SavageFormSelect } from '@clickboarding/savage';
import store from '../store';
import MultiSelect from 'common/components/multi-select.vue';
import ellipsisText from "../directives/text-middle-ellipsis";

export default {
  name: 'forms-data-entry-element-grouparray',
  components: {
    SavageFormTextInput,
    SavageFormIntegerInput,
    SavageFormRadioGroup,
    SavageFormSelect,
    MultiSelect,
  },
  directives: {
    SavageGridColumns: SavageGridColumnsDirective,
    textMiddleEllipsis: {
      bind(el, { value }) {
        ellipsisText(el, { value });
      },
      update(el, { value }) {
        el.innerHTML = '';
        ellipsisText(el, { value });
      }
    }
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    itemTitle: {
      type: String,
      required: false,
    },
    minimumItems: {
      type: Number,
      required: false,
    },
    maximumItems: {
      type: Number,
      required: false,
    },
    isConditionallyShown: {
      type: Boolean,
      required: false,
    },
    availableConditionalFields: {
      type: Array,
      required: false,
    },
    conditionalLogicField: {
      type: String,
      required: false,
    },
    conditionalLogicOperator: {
      type: String,
      required: false,
    },
    conditionalLogicValues: {
      type: Array,
      required: false,
    },
  },
  beforeMount() {
    if (this.availableConditionalFields) {
      this.showConditionalLogicConfig = this.isConditionallyShown;
      this.conditionalFields = this.availableConditionalFields.map((field) => { return { label: field.label, value: field.label }; });
      if (this.conditionalLogicField) {
        this.conditionalValues = this.availableConditionalFields.find((field) => field.label === this.conditionalLogicField).options;
      }
    }
  },
  mounted() {
    let vals = document.getElementById('values');
    if (vals && vals.classList) {
      vals.classList.add('savage-form-select', 'savage-form-field-element');
      if (this.conditionalLogicValues.length === 0) {
        vals.classList.add('vf-invalid');
      }
    }
  },
  methods: {
    buildMinMaxValidation(min, max) {
      return {
        minimum: {
          value: min,
          message: this.$t('common.validation.minimumFormat', [min]),
        },
        maximum: {
          value: max,
          message: this.$t('common.validation.maximumFormat', [max]),
        }
      };
    },
    setDefaults() {
      if (this.localConditionalLogicOperator === null) {
        this.localConditionalLogicOperator = 'Equals'; // setting this here in case user does not change from the default value
      }
    },
    loadValues(event) {
      this.conditionalValues = this.availableConditionalFields.find((field) => field.label === event).options;
      this.localConditionalLogicValues = [];
      let vals = document.getElementById(this.itemTitle.replace(/[^\w]/g, '').toLowerCase());
      if (vals) {
        vals.classList.add('vf-invalid');
        vals.classList.remove('vf-dirty');
      }
    },
    onSelect(event) {
      const index = this.localConditionalLogicValues.findIndex((item) => item === event.value);
      if (index > -1) {
        this.localConditionalLogicValues.splice(index, 1);
      } else {
        this.localConditionalLogicValues.push(event.value);
      }
      this.$emit('update:conditionalLogicValues', this.localConditionalLogicValues);
      let vals = document.getElementById(this.itemTitle.replace(/[^\w]/g, '').toLowerCase());
      if (vals) {
        if (this.conditionalLogicValues.length > 0) {
          vals.classList.remove('vf-invalid');
        } else {
          vals.classList.add('vf-invalid', 'vf-dirty');
        }
      }
    },
    conditionalLogicValidation() {
      return {
        required: {
          value: this.isConditionallyShown,
          message: this.$t('common.validation.required'),
        },
      };
    },
  },
  computed: {
    localTitle: {
      get() { return this.title; },
      set(v) { this.$emit('update:title', v); },
    },
    localItemTitle: {
      get() { return this.itemTitle; },
      set(v) { this.$emit('update:itemTitle', v); },
    },
    localMinimumItems: {
      get() { return this.minimumItems; },
      set(v) { this.$emit('update:minimumItems', v); },
    },
    localMaximumItems: {
      get() { return this.maximumItems; },
      set(v) { this.$emit('update:maximumItems', v); },
    },
    localIsConditionallyShown: {
      get() { return this.isConditionallyShown; },
      set(v) { this.showConditionalLogicConfig = v; this.$emit('update:isConditionallyShown', v); },
    },
    localConditionalLogicField: {
      get() { return this.conditionalLogicField; },
      set(v) { this.$emit('update:conditionalLogicField', v); },
    },
    localConditionalLogicOperator: {
      get() { return this.conditionalLogicOperator; },
      set(v) { this.$emit('update:conditionalLogicOperator', v); },
    },
    localConditionalLogicValues: {
      get() { return this.conditionalLogicValues; },
      set(v) { let vals = v.map((val) => { return val.value; }); this.$emit('update:conditionalLogicValues', vals); },
    },
    titleMaxLengthValidation() {
      const maxLength = 100;
      return {
        maxLength: {
          value: maxLength,
          message: this.$t('common.validation.maxLengthFormat', [maxLength]),
        }
      };
    },
    requiredValidation() {
      return {
        required: {
          value: true,
          message: this.$t('common.validation.required'),
        }
      };
    },
    hasConfiguredMinimumItemsGreaterThanMaximumItems() {
      return get(this, 'minimumItems', null) === null  // if no minimumItems, it's valid
        || get(this, 'maximumItems', null) === null // if no maximumItems, it's valid
        || this.minimumItems <= this.maximumItems;
    },
    minimumItemsValidation() {
      return {
        // These values are based on the constraints for 
        // GroupArray datafields in our schema. These values
        // should never exceed the schema values.
        ...this.buildMinMaxValidation(0, 100)
      }
    },
    minimumItemsCustomValidation() {
      return {
        minimumItemsGreaterThanMaximumItems: {
          value: this.hasConfiguredMinimumItemsGreaterThanMaximumItems,
          message: this.$t('formsBuilder.dataEntry.groupArray.minimumItemsGreaterThanMaximumItemsValidationMessage'),
        }
      };
    },
    maximumItemsValidation() {
      return {
        // These values are based on the constraints for 
        // GroupArray datafields in our schema. These values
        // should never exceed the schema values.
        ...this.buildMinMaxValidation(1, 100)
      }
    },
    maximumItemsCustomValidation() {
      return {
        maximumItemsLessThanMinimumItems: {
          value: this.hasConfiguredMinimumItemsGreaterThanMaximumItems,
          message: this.$t('formsBuilder.dataEntry.groupArray.maximumItemsLessThanMinimumItemsValidationMessage'),
        }
      };
    },
    gridColumns() {
      // This component has a maximum of three fields (Label, Placeholder, Required)
      // When Placeholder is displayed we want a 2 column layout, but we always
      // want to keep the Required toggle on the left side (UX decision)
      return this.showPlaceholder ? 2 : 1;
    },
    conditionalLogicFeatureEnabled() {
      return store.getters['features/hasConditionalLogicFeature']() && this.conditionalFields.length > 0;
    },
  },
  data() {
    return {
      showConditionalLogicConfig: false,
      conditionalFields: [],
      operatorFields: [{ label: "Equals", value: "Equals" }, { label: "Not Equals", value: "Not Equals" }],
      conditionalValues: []
    }
  },
}
</script>
<style lang="scss">
.conditional-logic-config {
  border: 1px solid #DDDDDD;
  background: #FFFFFF;
  padding: 1.5rem;
}

.conditional-logic-config-wrapper {
  margin-top: 1rem;
}

.conditional-dropdown {
  margin-right: 1rem;
}

.conditional-logic-title {
  padding-bottom: 5px;
}

.dropdown-flex>div {
  display: -webkit-flex;
  display: flex;
}

.full-width {
  width: 100%;
}

.conditional-values .multiselect-picker-wrapper label {
  font-size: 14px;
  display: block;
}

.conditional-values div.multiselect-picker-arrow {
  border: none;
}

.conditional-values p.multiselect-picker-button-text {
  font-weight: normal;
  font-size: 0.875rem;
  padding: 0px 2px 1px;
}
</style>
<style scoped lang="scss">
.multiselect-picker-popover {
  height: auto !important;
}
</style>
