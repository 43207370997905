import FormsDataEntryElementGroup from 'forms/forms-data-entry-element-group.vue';
import FormsDataEntryGroupContainer from 'forms/forms-data-entry-group-container.vue';
import FormsDataEntryElements from 'forms/forms-data-entry-elements.vue';
import get from 'lodash.get';
import 'common/polyfills/array.includes.polyfill';
import { i18n } from 'common/lang-config';

const supportedTypes = [
  'Group',
  'Address'
];

function canHandlePresentation (presentationKey) {
  return supportedTypes.includes(presentationKey);
}

function canHandleType (type) {
  return supportedTypes.includes(type);
}

function getChildElementsKey () {
  return 'elements';
}

function mapPresentationToDesigner (element, presentationKey, updateModelFn) {
  const model = element.availablePresentations[presentationKey];
  const childElementsKey = getChildElementsKey();
  // if the model already has this set, let's not overwrite it with the element level values
  // this is really just dealing with adding a new element vs editing an existing element
  if (model && !model.availableConditionalFields) {
    model.availableConditionalFields = element.availableConditionalFields;
  }

  return {
    label: i18n.t('formsBuilder.dataEntry.designerLabels.group'),
    key: presentationKey,
    container: {
      component: FormsDataEntryGroupContainer,
      props: {
        title: get(element, 'label', null),
      },
    },
    fields: {
      component: FormsDataEntryElementGroup,
      props: {
        title: get(model, 'title', null),
        isConditionallyShown: get(model, 'isConditionallyShown', false),
        availableConditionalFields: get(model, 'availableConditionalFields', []),
        conditionalLogicField: get(model, 'conditionalLogic.field', ''),
        conditionalLogicOperator: get(model, 'conditionalLogic.operator', null),
        conditionalLogicValues: get(model, 'conditionalLogic.values', []),
      },
      events: {
        'update:title': (v) => updateModelFn('title', v),
        'update:isConditionallyShown': (v) => updateModelFn('isConditionallyShown', v),
        'update:conditionalLogicField': (v) => updateModelFn('conditionalLogic.field', v),
        'update:conditionalLogicOperator': (v) => updateModelFn('conditionalLogic.operator', v),
        'update:conditionalLogicValues': (v) => updateModelFn('conditionalLogic.values', v),
      },
    },
    children: {
      component: FormsDataEntryElements,
      props: {
        isGrouped: true,
        elements: get(model, childElementsKey, null),
      },
      events: {
        'update:elements': (v) => updateModelFn(childElementsKey, v),
      },
    }
  };
}

export { canHandlePresentation, canHandleType, getChildElementsKey, mapPresentationToDesigner }