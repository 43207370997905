import AppSettings from 'appSettings';
import CbAdminMenu from "general/cb-admin-menu.vue";
import ConnectionsRoute from "connections/connections-route.vue";
import ConnectionEdit from "connections/connection-edit.vue";
import EditScreening from "setup/edit-screening.vue";
import ConnectionActivityLog from "connections/connection-activity-log.vue";
import CandidateProcessView from "candidates/candidate-process-view.vue";
import CandidateView from "candidates/candidate-view.vue";
import ReportsRoute from "reports/reports-route.vue";

//The intent of this configuration is to follow the same format of vue router.
//Currently supported functionality:
// - path is used as angular's route matching pattern
// - default component is registered as the component for path
// - menu method is used to denote which admin menu nav/subnav should be selected when navigating to this route
// - menu within component is not used, but is present as the menu method for props exists.
export default
[
  {
    path: AppSettings.viewConnections,
    components: {
      default: ConnectionsRoute,
      menu: CbAdminMenu,
    },
    props: {
      default: true,
      menu: (route) => ({
        selectedNav: "Settings",
        selectedSubnav: `connections/${route.params.connectionType}`
      }),
    }
  },
  {
    path: AppSettings.viewReports,
    components: {
      default: ReportsRoute,
      menu: CbAdminMenu,
    },
    props: {
      default: true,
      menu: (route) => ({
        selectedNav: "Reports",
        selectedSubnav: `reports/${route.params.reportType}`
      }),
    }
  },
  {
    path: AppSettings.viewConnectionEdit,
    components: {
      default: ConnectionEdit,
      menu: CbAdminMenu,
    },
    props: {
      default: true,
      menu: (route) => ({
        selectedNav: "Settings",
        selectedSubnav: `connections/${route.params.connectionType}`
      }),
    }
  },
  {
    path: AppSettings.viewConnectionLog,
    components: {
      default: ConnectionActivityLog,
      menu: CbAdminMenu,
    },
    props: {
      default: true,
      menu: (route) => ({
        selectedNav: "Settings",
        selectedSubnav: `connections/${route.params.connectionType}`
      }),
    }
  },
  {
    path: AppSettings.viewScreening,
    components: {
      default: EditScreening,
      menu: CbAdminMenu,
    },
    props: {
      default: true,
      menu: (route) => ({
        selectedNav: "Settings",
        selectedSubnav: `settings-screening`
      }),
    }
  },
  {
    path: AppSettings.viewCandidatesView,
    components: {
      default: CandidateView,
      menu: CbAdminMenu,
    },
    props: {
      default: true,
      menu: (route) => ({
        selectedNav: "Candidates",
        selectedSubnav: "candidates"
      }),
    }
  },
  {
    path: AppSettings.viewCandidatesProcessView,
    components: {
      default: CandidateProcessView,
      menu: CbAdminMenu,
    },
    props: {
      default: true,
      menu: (route) => ({
        selectedNav: "Candidates",
        selectedSubnav: "candidates"
      }),
    }
  }
]