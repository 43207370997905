<template>
  <cb-view-section type="secondary" :withFlushTop="isGrouped">
    <span class="forms-data-entry-field-title">{{title}}</span>
    <cb-view-section type="primary">
      <slot name="fields" />
    </cb-view-section>
  </cb-view-section>
</template>

<script>
import CbViewSection from 'general/cb-view-section.vue';
export default {
  name: 'forms-data-entry-field-container',
  components: {
    CbViewSection,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    isGrouped: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';

  .forms-data-entry-field-title {
    font-weight: bold;
    display: inline-block;
    @include font-size-content-root;

    &:not(:first-of-type) {
      margin-top: 1rem;
    }

    margin-bottom: 1rem;
  }
</style>
