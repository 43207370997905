<!--
WARNING: This component was ported verbatim from angular to vue. Because of this
it uses old patterns and standards and should not be used as a reference.

Any changes to this component should result in movement toward our new
patterns and practices.
-->
<template>
  <section v-if="candidateDetail" class="cb-candidate-detail">
    <div id="dDetails" class="adminModal modalDoc modal-candidate-detail text-c addDialog">
      <h1 class="text-l">{{candidateName}}</h1>
      <div class="closeModal" @click="closeClick">×</div>
      <div>
        <!--Process-->
        <section v-if="candidateDetail.processFlows" class="text-c scroller">
          <i v-if="hasProcessScroller" @click="scroll('#dScroller', 'left')" class="blockMiddle scroller fa fa-chevron-left"></i>
          <div id="dScroller" class="blockMiddle process-flows" :class="candidateDetail.processFlows.length === 1 ? 'singleProcess' : 'multiProcess'">
            <div v-for="(process, index) in candidateDetail.processFlows" :key="index" class="blockMiddle pfMenu">
              <input type="radio" name="pfMenu" :id="'PF' + index" :checked="setSelectedProcess(process)" @click="handleFlowChange(process)" />
              <label :for="'PF' + index" class="blockMiddle process-block text-c" >
                <i :class="getStatusIcon(process.status)" class="status-icon" :title="process.status.label"></i>
                <h2 class="date">{{process.dueDate}}</h2>
                {{process.name}}
              </label>
            </div>
          </div>
          <i v-if="hasProcessScroller" @click="scroll('#dScroller', 'right')" class="blockMiddle scroller fa fa-chevron-right"></i>
        </section>

        <!-- Grapevine -->
        <section v-if="candidateDetail.data" class="grapevine text-c margin-lrg">
          <div class="titleTxt" v-if="!loading">{{candidateDetail.progressLabel}}</div>
          <div v-if="!candidateDetail.data.steps && !loading">{{candidateDetail.noStepsLabel}}</div>
          <div v-if="candidateDetail.data.steps">
            <i v-if="hasStepScroller" @click="scroll('#dStepScroller', 'left')" class="blockMiddle stepScrollLeft scroller fa fa-chevron-left"></i>
            <div id="dStepScroller" class="steps blockMiddle">
              <div class="step" v-for="(step, index) in candidateDetail.data.steps" :key="index">
                  <p :class="getStepText(step)" class="step-label">{{step.name}}</p>
                  <div class="container">
                    <div class="line" :class="(index !== candidateDetail.data.steps.length - 1) ? getStepLine(step) : 'lineLast'" @click="stepClicked(step, index)"></div>
                    <div class="circle" :class="getStepIcon(step)" :title="step.status.label" @click="stepClicked(step, index)"><div>{{step.ordinal}}</div></div>

                    <div class="shadow-step-container" :class="step.shadow ? 'visible':'invisible'" @click="stepClicked(step.shadow, index)">
                      <div v-if="step.shadow" class="lineShadow" :class="getStepLine(step.shadow)"></div>
                      <br v-if="step.shadow" />
                      <div v-if="step.shadow" :class="getStepShadowIcon(step.shadow)" class="stepShadow" :title="step.shadow.status.label">
                        <div>{{step.shadow.ordinal}}a</div>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <i v-if="hasStepScroller" @click="scroll('#dStepScroller', 'right')" class="blockMiddle stepScrollRight scroller fa fa-chevron-right"></i>
          </div>
        </section>

        <!--Step Detail-->
        <section v-if="showStepDetail && currentStep" class="padding-lrg-T">
          <h2 class="margin-none text-c">{{currentStep.ordinal}}{{currentStep.isShadow ? 'a':''}}.  {{currentStep.name}}</h2>

          <div class="step-detail">
            <div class="margin-med-L" v-if="currentStep.noDataLabel">
              {{currentStep.noDataLabel}}
            </div>
            <!--forms-->
            <div v-if="currentStep.forms">
              <div v-for="(form, index) in currentStep.forms" :key="index" class="step-forms margin-sm-B vert-c">
                <div class="step-container">
                  <div class="step-item form-name">
                    <i :class="getStatusIcon(form.status)" class="status" :title="form.status.label"></i>
                    {{form.name}}
                  </div>
                  <div class="step-item display-status">
                    <span v-if="form.lastUpdateDate">{{form.displayStatus}} - {{form.lastUpdateDate}}</span>
                  </div>
                  <div class="step-item actions">
                    <button v-if="form.resetConfirmation"
                      type="submit"
                      @click="openFormResetConfirmation(form)"
                      class="cb-btn secondary form-reset-btn"
                    >    {{form.resetConfirmation.reset.label}}
                    </button>
                    <i v-if="form.content" @click="handleDocClick(form.content.url)"
                      :class="form.content.isCompleted ? 'fa-file-text-o ' : 'fa-file-o'" class="fa pull-right file"
                      :title="form.content.label">
                    </i>
                  
                    <a download class="attachment-download" v-if="form.attachment" :href="form.attachment.url"
                      :title="form.attachment.label">
                      <i class="fa fa-paperclip file"></i>
                    </a>                
                  </div>
                </div>
              </div>
            </div>
            <!--third party integrations-->
            <div
              v-if="currentStep.thirdPartyIntegrations"
            >
              <div
                v-for="(integration, index) in currentStep.thirdPartyIntegrations"
                :key="index"  class="step-detail-invite"
                 >
                <div v-if="integration.hrwcUserData">
                  <span >{{ integration.hrwcUserData.hrwcEventStatus }}</span> 
                </div>
              </div>
            </div>

            <!-- screening -->
            <div v-for="(invite, index) in currentStep.invites" :key="index" class="step-detail-invite">
              <div>
                <span v-if="invite.status"
                  :class="{'red': invite.status.statusEnum == 'CandidateDeclined',
                    'blue': invite.status.statusEnum == 'CandidatePending' || invite.status.statusEnum == 'Pending',
                    'green': invite.status.statusEnum == 'CandidateInProgress' || invite.status.statusEnum == 'ScreeningInProgress' }"
                  class="blockMiddle statusBox pull-right cb-status font-sm">
                    {{invite.status.label}}
                </span>

                <p class="blockMiddle">
                  {{invite.packageLabel}}: {{invite.packageName}}
                    <a v-if="invite.details && invite.details.url" class="blockMiddle cb-link padding-med-L" @click="handleScreeningInviteDetailsClick(invite)">
                      <i class="fa fa-external-link" :title="invite.details.label"></i>
                      {{invite.details.label}}
                    </a>
                    <a v-if="invite.resend && invite.resend.url" class="blockMiddle cb-link padding-med-L" @click="handleScreeningInviteRetryClick(invite.resend)">{{invite.resend.label}}</a>
                </p>

                <cb-message-bar :type="'error'" v-if="invite.errorMessage" :message="invite.errorMessage"></cb-message-bar>
              </div>
              <ul v-if="invite.orders" class="invite-order-list padding-med">
                <li v-for="(order, orderIndex) in invite.orders" :key="orderIndex">
                  <div class="top margin-sm-B">
                    <a v-if="order.content" @click="handleScreeningOrderClick(order)" class="pull-right">
                        <i class="fa fa-file-text-o" :title="order.content.label"></i>
                    </a>
                    <span class="blockMiddle vert-c">
                      <i :class="getStatusIcon(order.status)" :title="order.status.label"></i>
                      {{order.orderLabel}} {{order.statusDate}} &ndash; {{order.idLabel}}: {{order.id}}
                    </span>
                  </div>
                  <div v-for="(check, checkIndex) in order.checks" :key="checkIndex" class="invite-order-checks">
                    <div class="padding-sm padding-lrg-L vert-c">
                      <i :class="getStatusIcon(check.status)" :title="check.status.label"></i>
                      {{check.name}}
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <!--tasks-->
            <div v-if="currentStep.tasks" class="step-detail-tasks text-l">
              <div v-for="(task, index) in currentStep.tasks" :key="index">
                <div>
                  <i class="fa" @click="$set(task, 'showItems', !task.showItems)" :class="task.showItems ? 'fa-caret-down' :'fa-caret-right'"></i>
                  <i class="status-icon" :class="getStatusIcon(task.status)" :title="task.status.label"></i>

                  <span class="name">{{task.name}}</span>
                  <span v-if="task.lastUpdateDate"> {{task.displayStatus}} - {{task.lastUpdateDate}}</span>
                </div>

                <div v-if="task.showItems" class="padding-xlg-L">
                  <strong>{{task.assignedTo}}</strong>
                  <a v-if="task.reassign" class="link margin-sm-L" @click="handleShowReassign(task)">{{task.reassign.label}}</a>
                  <div v-for="(activity, activityIndex) in task.activities" :key="activityIndex" class="vert-c">
                    <i class="fa margin-sm-R" :class="activity.isCompleted ? 'fa-check-circle-o' : 'fa-circle-o'"></i>
                    {{activity.description}}
                  </div>
                </div>


                <div v-if="task.showItems">
                  <div v-if="task.showReassigned" class="boxDrawer">
                    <div class="labelContent">
                      <label>
                        {{task.assignData.assigneeLabel}}
                        <select class="selectPlaceholder" v-model="task.assignedUser">
                          <option v-for="(user, userIndex) in task.assignData.users" :key="userIndex" :value="user">{{user.name}}</option>
                        </select>
                      </label>
                    </div>
                    <div class="buttonRow text-c">
                      <input type="button" :value="task.assignData.confirm.label" @click="handleReassignClick(task)" class="margin-med-R" />
                      <input type="button" :value="task.assignData.cancelLabel" @click="handleReassignCancelClick(task)" class="backBtn" />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    <div>
      <b-modal 
      v-if="formResetItem"
      no-close-on-backdrop 
      centered 
      :ok-title="formResetItem.resetConfirmation.confirmLabel"
      :cancel-title="formResetItem.resetConfirmation.cancelLabel"
      @ok="handleFormReset()" 
      v-model="formResetModal"> 
      {{ formResetItem.resetConfirmation.message }}
      <span class="taxPrompt"
        v-if="formResetTaxForm">Completing this action requires the *Fed/StateW4 form(s) to also be reset.</span>
        Do you wish to continue?
    </b-modal>
    </div>
      <div class="buttonRow" style="text-align:center; margin-top:3em;">
        <input type="button" :value="candidateDetail.doneLabel" @click="closeClick">
      </div>
    </div>
    <form-doc v-if="showFormDoc" :url="currentStep.currentDocUrl" :dialogHeight="dialogHeight" :closeClick="handleCloseDoc"></form-doc>
    <div class="modalBackground"><img src="images/printBkgrnd.png" /></div>
 </section>   
</template>

<script>
import { CbMessageBar } from '@clickboarding/vue-components';
import aaNotifyMessageBus from "components/aanotify-message-bus";
import FormDoc from "candidates/forms/form-doc.vue";

export default {
  name: "candidate-detail",
  components: {
    CbMessageBar,
    FormDoc
  },
  mixins: [],
  props: {
    detailUrl: {
      type: String
    },
    closeClick: {
      type: Function
    }
  },
  data() {
    return {
      currentProcess: null,
      candidateDetail: null,
      hasProcessScroller: null,
      hasStepScroller: null,
      showFormDoc: null,
      currentStep: null,
      showStepDetail: null,
      candidateName: null,
      dialogHeight: null,
      loading: false,
      formResetModal: false,
      formResetItem: null,
      formResetTaxForm: null
    };
  },
  computed: {},
  methods: {
    setSelectedProcess(process) {
      if(process.isSelected) {
        this.currentProcess = process;
      }
      return process.isSelected;
    },
    openFormResetConfirmation(form){
      this.formResetItem = form;
      this.formResetModal = !this.formResetModal;
      this.formResetTaxForm = form.name.includes("TaxFormInfo") || form.name.includes("Tax Information");
    },
    refreshDataAndCurrentStep: function (candidateDetail) {
      this.candidateDetail = candidateDetail;
      this.currentStep = this.candidateDetail.data.steps.filter(step => step.ordinal === this.currentStep.ordinal)[0];
    },
    handleDocClick: function(contentUrl) {
      this.currentStep.currentDocUrl = contentUrl;
      this.showFormDoc = true;
    },
    handleCloseDoc: function (value) {
      this.showFormDoc = false;
      if (value) {
        this.refreshDataAndCurrentStep(value);
        window.scrollTo(0, 0);
      }
      this.currentStep.currentDocUrl = null;
    },
    handleScreeningInviteRetryClick: async function (details) {
      var response = await this.$http({
        method: details.method,
        url: details.url,
      });
      this.refreshDataAndCurrentStep(response.data);
    },
    handleScreeningInviteDetailsClick: async function(invite) {
      window.open(invite.details.url, '_blank');
      var response = await this.$http.get(invite.details.refreshUrl);
      this.$set(invite, 'details', response.data);
    },
    handleScreeningOrderClick: async function(order) {
      window.open(order.content.url, '_blank');
      var response = await this.$http.get(order.content.refreshUrl);
      this.$set(order, 'content', response.data);
    },
    handleReassignCancelClick: function (task) {
      this.$set(task, 'showReassigned', false);
      aaNotifyMessageBus.$emit("clear-all-messages");
    },
    handleReassignClick: async function (task) {
      await this.$http.post(task.assignData.confirm.url, { id:task.assignedUser.id });
      this.$set(task, 'showReassigned', false);
      this.$set(task, 'assignedTo' , task.assignedUser.displayName);
    },
    handleShowReassign: async function (task) {
      var response = await this.$http.get(task.reassign.url);
      var reassignResponseData = response.data;
      this.$set(task, 'assignedUser', reassignResponseData.users.filter(user => user.isAssigned)[0]);
      this.$set(task, 'assignData', reassignResponseData);
      this.$set(task, 'showReassigned', true);
    },
    handleFormReset : async function() {
      await this.$http.put(this.formResetItem.resetConfirmation.reset.url);
      this.handleFlowChange(this.currentProcess)
    },
    stepClicked: function(step) {
      this.currentStep = (this.currentStep !== step) ? step : null;
      this.showStepDetail = this.currentStep;
      setTimeout(() => {
        this.dialogHeight = document.getElementById('dDetails').clientHeight;
      });
    },
    getStepLine: function(step) {
      switch(step.status.statusEnum){
        case 'Completed':
          return 'lineCompleted';
        default:
          return '';
      }
    },
    getStepText: function(step) {
      var css = 'stepTextOther';
      if(step.isCurrent){
        css = 'stepTextCurrent';
        if (step.status.statusEnum === "NeedsAttention")
          css = 'stepTextNeedsAttention';
        else if (step.status.statusEnum === "Canceled")
          css = 'stepTextCancel';
      }
      return css;
    },
    getStepShadowIcon: function (step) {
      var css;
      switch (step.status.statusEnum) {
        case 'Started':
          css = 'stepShadowStarted';
          break;
        case 'Pending':
          css = 'stepShadowPending';
          break;
        case 'NeedsAttention':
          css = 'stepShadowNeedsAttention';
          break;
        case 'Canceled':
          css = 'stepShadowCanceled';
          break;
        // case 'Completed':
        default:
          css = 'stepShadowCompleted';
      }
      return css;
    },
    getStepIcon: function(step) {
      var css;
      switch(step.status.statusEnum) {
        case 'Started':
          css = 'stepStarted';
          break;
        case 'Pending':
          css = 'stepPending';
          break;
        case 'NeedsAttention':
          css = 'stepNeedsAttention';
          break;
        case 'Canceled':
          css = 'stepCanceled';
          break;
        // case 'Completed':
        default:
          css = 'stepCompleted';
      }
      if (step.isCurrent)
        css += " stepCurrent";

      return css ;
    },
    scroll: function(id, direction) {
      var scroller = document.querySelector(id);
      if (direction === 'right')
        scroller.scrollTo({ left: scroller.scrollLeft + 150 });
      else if ( direction === 'left')
        scroller.scrollTo({ left: scroller.scrollLeft - 150 });
    },
    getStatusIcon: function(status) {
      if (status){
        switch(status.statusEnum) {
          case 'Pending':
            return 'fa fa-circle-o statusPending';
          case 'Started':
            return 'fa fa-adjust statusStarted';
          case 'NeedsAttention':
            return 'fa fa-exclamation-circle statusNeedsAttention';
          case 'Completed':
            return 'fa fa-check-circle statusCompleted';
          case 'Canceled':
            return 'fa fa-ban statusCanceled';
        }
      }
    },
    handleFlowChange: async function (process) {
      this.candidateDetail.data = null;
      this.currentStep = null;
      this.showStepDetail = false;
      this.loading = true;
      this.currentProcess = null;
      await this.updateData(process.url);
    },
    updateData: async function(url) {
      var response = await this.$http.get(url);
      this.candidateDetail = response.data;
      this.candidateName = this.candidateDetail.candidateIsAnonymized ?
        `${this.candidateDetail.candidateAnonymizedLabel} (${this.candidateDetail.candidateId})` : this.candidateDetail.candidateName;
      this.currentStep = null;
      this.showStepDetail = false;
      this.loading = false;
      this.$nextTick(() => {
        this.determineScrollbars();
      })
    },
    defaultTabDisplay: function(elem) {
      this.$nextTick(() => {
        elem.scrollIntoView({inline: "center"});
      })
    },
    determineScrollbars: function() {
      if (this.candidateDetail){
        var processScroller = document.getElementById('dScroller');
        if (this.candidateDetail.processFlows && processScroller )
          this.hasProcessScroller = this.candidateDetail.processFlows.length > 1 && processScroller.scrollWidth > processScroller.clientWidth;
        var stepScroller = document.getElementById('dStepScroller');
        if (this.candidateDetail.data && this.candidateDetail.data.steps && stepScroller)
          this.hasStepScroller = this.candidateDetail.data.steps.length > 1 && stepScroller.scrollWidth > (stepScroller.clientWidth + 2); //padding is off
      }
      Array.from(document.querySelectorAll('#dScroller div')).forEach((elem) => {
        const inputElement = elem.querySelector("input");
        if (inputElement && inputElement.checked) {
          this.defaultTabDisplay(elem);
        }
      });
    }
  },
  created() {
    document.addEventListener("resize", this.determineScrollbars);
  },
  destroyed() {
    document.removeEventListener("resize", this.determineScrollbars);
  },
  watch: {
    detailUrl: {
      immediate: true,
      async handler (newDetailUrl) {
        if (typeof newDetailUrl != 'undefined') {
          await this.updateData(newDetailUrl);
        }
      }
    }
  }
}
</script>

<style scoped>
.cb-candidate-detail .pfMenu { white-space: normal; position: relative;}
.cb-candidate-detail .pfMenu:not(:last-child){ border-right: solid 1px #fff; }
.cb-candidate-detail .pfMenu input[type="radio"] { position: absolute; top: -9999px;}
.cb-candidate-detail .pfMenu input[type="radio"] + label { background-color:#eee; cursor: pointer;}
.cb-candidate-detail .pfMenu input[type="radio"]:checked + label { background-color:#fff; cursor:pointer;}

.cb-candidate-detail .scroller { white-space: nowrap; }
.cb-candidate-detail .scroller * {
  box-sizing: content-box; /* override bootstrap box-sizing */
}
.cb-candidate-detail .scroller.fa {
  font-size: 24px;
  color: #e7e7e7;
}
.cb-candidate-detail .process-flows {
  max-width:80%;
  overflow-x:hidden;
  overflow-y:hidden;
  white-space:nowrap;
}
.cb-candidate-detail .process-flows .status-icon {
  position: absolute;
  padding: 0; margin: 0;
  left: .5em; top: .5em;
}
.cb-candidate-detail .process-flows .status-icon.statusNeedsAttention { color: #DD7C45; }
.cb-candidate-detail .process-flows .status-icon.statusCompleted { color: #0CA57D; }
.cb-candidate-detail .process-flows .status-icon.statusPending { color: #0CA57D; }
.cb-candidate-detail .process-flows .status-icon.statusStarted { color: #B1B1B1; }
.cb-candidate-detail .process-flows .status-icon.statusCanceled { color: #B1B1B1; }

.cb-candidate-detail .process-flows .date {
  font-size: 130%;
  margin-bottom: 0;
  line-height: 1.6; /* override bootstrap heading line-height */
}
.cb-candidate-detail .process-flows .process-block {
  display: inline-block;
  padding: .5em 1em;
  min-width: 12em;
  margin: 0;
  line-height: 1.6;
}

.cb-candidate-detail .grapevine .titleTxt {
  line-height: 1.6; /* title text size pushes into process block above it */
}

/**/
/* CANDIDATE GRAPEVINE STEPS */
/**/
.cb-candidate-detail .grapevine {
  position: relative;
  white-space: nowrap;
  max-width: 600px;
  margin: 0 auto;
}
.cb-candidate-detail .grapevine .steps {
  overflow: visible;
  overflow-x: hidden;
  max-width: 600px;
  border-spacing:0;
  display: block;
}
.cb-candidate-detail .grapevine .stepScrollLeft {
	position: absolute;
	bottom: 54px; left: -10px;
}
.cb-candidate-detail .grapevine .stepScrollRight {
	position: absolute;
	bottom: 54px; right: -28px;
}
.cb-candidate-detail .grapevine .step {
    height: 110px;
    position: relative;
    display: inline-block;
    width: 6em;
    text-align:center;
    line-height:20px;
}
.cb-candidate-detail .grapevine .step .step-label {
  position: relative;
  top: 1em;
  white-space: pre-line;
  line-height: 13px;
}
.cb-candidate-detail .grapevine .step .container {
    min-height: 35px;
    position: relative;
    vertical-align: middle;
  }
.cb-candidate-detail .grapevine .step .shadow-step-container {
    cursor: pointer;
    position: absolute;
    bottom: -100%; left: 50%;
    width: 2.5em;
    margin-left: -1.25em;
  }
.cb-candidate-detail .grapevine .step .circle {
    cursor: pointer;
    width:24px; height:24px;
    border-radius: 50%;
    position:absolute;
    top:0; bottom:0; left:0; right:0;
    margin: auto;
  }
.cb-candidate-detail .grapevine .step .line {
    background-color:#ccc;
    height:4px;
    position:absolute;
    left:50%; top:0; bottom:0;
    margin:auto;
    width:110%;
    z-index:-1;
  }
.cb-candidate-detail .grapevine .step .lineCompleted {
    background-color:#456cba !important;
  }
.cb-candidate-detail .grapevine .step .lineLast {
    background-color:#fff; width:49%;
  }
.cb-candidate-detail .stepShadowNeedsAttention.circle,
.cb-candidate-detail .stepCurrent.circle {
    width: 30px; height: 30px;
  }
  /* step dots/ICONS */
.cb-candidate-detail .grapevine .stepCompleted {
    border:solid 2px #456cba;
    background-color:#456cba;
}
.cb-candidate-detail .grapevine .stepCanceled {
    border:solid 2px #b1b1b1;
    background-color:#b1b1b1;
}
.cb-candidate-detail .grapevine .stepPending {
    background-color:#eee !important;
    border:solid 2px #ccc;
    color:#7f7f7f;
}
.cb-candidate-detail .stepPending div { color:#58585B; }
.cb-candidate-detail .grapevine .stepNeedsAttention {
    background-color:#fff;
    border:solid 2px #DD7C45;
}
.cb-candidate-detail .grapevine .stepNeedsAttention div { color:#DD7C45; }
.cb-candidate-detail .grapevine .stepStarted {
    background-color:#fff;
    border:solid 2px #3656A6;
    width:30px; height:30px;
}
.cb-candidate-detail .grapevine .stepStarted div {
    line-height:20px;
    color:#3656A6;
    font-size:150%;
}
.cb-candidate-detail .grapevine .step .stepCurrent {
    width:34px; height:34px;
}
.cb-candidate-detail .grapevine .step .stepCurrent div {
    line-height:30px;
    font-size:150%;
}
.cb-candidate-detail .grapevine .stepTextCurrent { color:#3d60a5; }
.cb-candidate-detail .grapevine .stepTextCancel,
.cb-candidate-detail .grapevine .stepTextNeedsAttention { color:#7f7f7f; }
.cb-candidate-detail .grapevine .stepTextOther {
  color:#7f7f7f;
  font-size:85%;
}
.cb-candidate-detail .grapevine .stepShadow {
  display:inline-block;
  background-image:url('../../Images/ShadowPending.svg?background');
  background-position:center;
  background-repeat:no-repeat;
  width:34px;
  z-index:1;
  margin:auto;
}
.cb-candidate-detail .grapevine .stepShadow div {
  line-height:25px;
  text-align:center;
  width:100%;
  font-size:92%;
}
.cb-candidate-detail .grapevine .shadow-step-container .lineShadow {
  position:absolute;
  text-align:center;
  height:3em; width:4px;
  background-color:#ccc;
  top:-8px; bottom:-8px; left:50%;
  margin-left:-1.5px;
  z-index:-1;
}
.cb-candidate-detail .grapevine .stepShadowStarted { background-image:url('../../Images/ShadowStarted.svg?background'); }
.cb-candidate-detail .grapevine .stepShadowNeedsAttention { background-image:url('../../Images/ShadowAttention.svg?background'); }
.cb-candidate-detail .grapevine .stepShadowCanceled { background-image:url('../../Images/ShadowCanceled.svg?background'); }
.cb-candidate-detail .grapevine .stepShadowCompleted { background-image:url('../../Images/ShadowCompleted.svg?background'); }

.cb-candidate-detail .step-detail {
  max-width:60%; min-width:400px;
  border: solid 1px #ccc;
  margin: 0 auto;
  padding: 1em 1em .5em;
  text-align: left;
}
.cb-candidate-detail .step-detail .fa.file { color: #3656A6;}
.cb-candidate-detail .step-detail-tasks .fa.fa-caret-down,
.cb-candidate-detail .step-detail-tasks .fa.fa-caret-right {
  font-size: 165%;
  color: #3656A6;
}
.cb-candidate-detail .step-detail-tasks .fa.fa-square-o,
.cb-candidate-detail .step-detail-tasks .fa.fa-check-square-o { font-size: 165%; padding-right: 4px; }
.cb-candidate-detail .step-detail-tasks .status-icon { font-size: 150%; padding: 0 6px;}
.cb-candidate-detail .step-detail-invite { margin-bottom: 1.5em; }
.cb-candidate-detail .step-detail-invite .invite-order-list .top .fa {
    font-size: 200%;
    margin-right: .15em;
}
.cb-candidate-detail .step-detail-invite .invite-order-checks .fa { margin-right: .25em;}
.cb-candidate-detail .step-forms { overflow: auto; }

.cb-candidate-detail .step-forms .view-doc {
    font-size: 170%;
    position: relative;
    top: -.25em;
}
.cb-candidate-detail .step-forms .status { margin-right: .25em; }
.cb-candidate-detail .step-forms .fa.file {
  font-size: 159%;
  margin-left: auto;
  vertical-align: middle;
}
.cb-candidate-detail .step-detail .step-forms a.attachment-download {
  margin: 0px 5px;
}

.cb-candidate-detail .step-detail .step-forms .step-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  width:100%;
  line-height: 1.6;
}

.cb-candidate-detail .step-detail .step-forms .step-item.form-name {
  flex: 0 1 63%;
}

.cb-candidate-detail .step-detail .step-forms .step-item.display-status {
  flex: 0 30%;
}

.cb-candidate-detail .step-detail .step-forms .step-item.actions {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex: 0 1 6rem;
}

.cb-candidate-detail .singleProcess {
  font-size:135%;
  margin:-1em 0;
}
.cb-candidate-detail .multiProcess {
  border-left:solid 1px #ccc;
  border-right:solid 1px #ccc
}

.cb-candidate-detail .stepCompleted div,
.cb-candidate-detail .stepCanceled div,
.cb-candidate-detail .stepShadowCompleted div,
.cb-candidate-detail .stepShadowCanceled div {
  color:#fff;
}
.cb-candidate-detail .orderTable td { padding:1em 1.5em 0px; }
.cb-candidate-detail .step-detail-tasks { position: relative; }

.form-reset-btn {
  padding: .28em .3em 
}
.taxPrompt {
  color: red;
  font-weight: bold;
}
</style>
