<template>
  <ul class="elements-list">
    <li>{{header}}</li>
    <li v-for="(item, index) in items" :key="index">
      <input
        type="checkbox"
        name="selected-elements"
        :id="'selected-elements-' + index"
        @change="change(item.value)"
        :value="item.value"
        :checked="isChecked(item.value)"
      />
      <label :for="'selected-elements-' + index">
        <span
          class="cb-checkbox"
          :class="{'cb-checkbox-checked': isChecked(item.value), 'cb-checkbox-unchecked': !isChecked(item.value) }"
        ></span>
        <slot v-bind:item="item">
          <span>{{item.label}}</span>
        </slot>
      </label>
    </li>
  </ul>
</template>
<script>
import findindex from "lodash.findindex";
import isequal from "lodash.isequal";

export default {
  name: "cb-check-list",
  methods: {
    change(item) {
      const index = findindex(this.value, valueItem => isequal(item, valueItem));
      let newArray = [];
      if (index !== -1) {
        newArray = this.value
          .slice(0, index)
          .concat(this.value.slice(index + 1, this.value.length));
      } else {
        newArray = this.value.concat(item);
      }
      // allows for v-model binding
      this.$emit("input", newArray);
    },
    isChecked(item) {
      return findindex(this.value, valueItem => isequal(item, valueItem)) !== -1;
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    header: {
      type: String,
      required: true
    },
    value: {
      /*
      This control will mimic the behavior of v-bind with checkboxes. The value property
      is expected to be an array (optionally) filled with the .values you want pre-checked (or empty works too.)
      The control will emit and "input" event every time a check status changes and the event parameter is the new
      value of that array (e.g., it doesn't mutate the array, it sends a new one). You can get this behavior by using v-bind on this control
      e.g., <cb-check-list v-bind:"selectedElements" :items:"elements" /> and as items are checked and unchecked selectedElements
      with be populated with the .value of the elements that are currently checked
      */
      type: Array
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/mixins";
@import "@clickboarding/style/colors";
@import "css/cb-checkbox";

.elements-list {
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid $cb-light-grey-1;
  }

  li:first-child {
    @include cb-view-padding;
    padding-left: calc(
      0.625rem + 1rem + 0.625rem
    ); // (list-item-padding-left + icon-width + icon-padding-right)
    background-color: $cb-light-grey-3;
    font-weight: bold;
  }

  li:not(:first-child) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;

    label {
      @include font-size-content-root;
      display: flex;
      align-items: center;
      align-content: center;
      padding: 0.625rem;
      margin: 0;

      span {
        display: inline-block;
        vertical-align: middle;
      }
    }

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;
    }

    label:hover {
      margin-left: -2px;
      border-left: 2px solid $cb-brand-blue-1;
      background-color: $cb-light-blue-6;
    }

    input:focus + label {
      margin-left: -2px;
      border-left: 2px solid $cb-brand-blue-1;
      background-color: $cb-light-blue-6;
    }
  }
}
</style>