import NotificationEdit from 'processes/steps/activities/notification/notification-configure.vue';
import ProcessEdit from 'processes/steps/activities/process/process-configure.vue';
import FormEdit from 'processes/steps/activities/form/form-configure.vue';
import TaskEdit from 'processes/steps/activities/task/task-configure.vue';
import TouchpointEdit from 'processes/steps/activities/touchpoint/touchpoint-configure.vue';

import FormSvg from 'processes/steps/activities/form/form.svg';
import ProcessSvg from '../../../common/assets/icons/general/clipboard-pencil-icon.svg';
import EmailSvg from 'processes/steps/activities/notification/email.svg';
import TaskSvg from 'processes/steps/activities/task/task.svg';

//component used for editing a single instance of an activity
//object properties should be named after the type.
//property names here should match the typeEnum returned from the api.
export const ActivityEditComponents =
{
    Notification: NotificationEdit,
    Process: ProcessEdit,
    Form: FormEdit,
    Task: TaskEdit,
    RetainTouchpoint: TouchpointEdit
};

export const ActivityConfig =
{
  Notification: {
    searchEnabled: false,
    configureOnAdd: function(stepIsCandidate) {
      return true;
    },
    allowDuplicateActivities: false,
    activityMatchesExisting: function(existingActivity, newActivity) {
      return existingActivity.typeEnum === 'Notification'
        && existingActivity.referenceIdentifier === newActivity.value;
    }
  },
  Process: {
    searchEnabled: false,
    configureOnAdd: function(stepIsCandidate) {
      return true;
    },
    allowDuplicateActivities: false,
    activityMatchesExisting: function(existingActivity, newActivity) {
      return existingActivity.typeEnum === 'Process'
        && existingActivity.apiIdentifier === newActivity.value;
    }
  },
  Form: {
    searchEnabled: true,
    configureOnAdd: function(stepIsCandidate) {
      // allow configuring the form if the step is not candidate facing
      return !stepIsCandidate;
    },
    allowDuplicateActivities: false,
    activityMatchesExisting: function(existingActivity, newActivity) {
      return existingActivity.typeEnum === 'Form'
        && existingActivity.formGuid === newActivity.value;
    }
  },
  Task: {
    searchEnabled: false,
    configureOnAdd: function(stepIsCandidate) {
      return true;
    },
    allowDuplicateActivities: true,
    activityMatchesExisting: function(existingActivity, newActivity) {
      return existingActivity.typeEnum === 'Task'
        && existingActivity.apiIdentifier === newActivity.value;
    }
  },
  RetainTouchpoint: {
    searchEnabled: true,
    configureOnAdd: function(stepIsCandidate) {
      return true;
    },
    allowDuplicateActivities: false,
    activityMatchesExisting: function(existingActivity, newActivity) {
      return existingActivity.typeEnum === 'RetainTouchpoint'
        && existingActivity.formGuid === newActivity.value;
    }
  }
}

export const ActivityIcons = 
{
    Form: FormSvg,
    Notification: EmailSvg,
    Process: ProcessSvg,
    Task: TaskSvg,
    RetainTouchpoint: TaskSvg
}