<template>
  <section>
    <div v-if="hasSteps" class="step-tiles-container container-fluid">
      <div class="row no-gutter">
        <div v-for="(step, index) in steps.steps" :key="index" class="step-tile-container col-sm-12 col-md-6 col-lg-6 col-xl-4">
            <step-tile :step="step"
                       :add-activities-label="stepLabels.addActivitiesLabel"
                       :edit-label="stepLabels.addEdit.editLabel"
                       :delete-label="stepLabels.addEdit.deleteLabel"
                       :on-edit-open="onStepEditOpen"
                       :on-delete-send="onStepDeleteSend"
                       @on-activity-add="onActivityAdd"
                       :on-activity-delete-send="onActivityDeleteSend"
                       :on-activity-edit="onActivityEdit"
                       :on-reorder="onStepReorder"
                       :on-form-reorder="onFormReorder"
                       :is-first="isFirstStep(step)"
                       :is-last="isLastStep(step)">
            </step-tile>
        </div>
      </div>
    </div>
    <div class="no-step-tiles-container padding-xlg" v-else>
        {{stepLabels.noDataLabel}}
    </div>
  </section>
</template>

<script>
  import StepTile from 'processes/builder/step-tile.vue';

  export default {
    name: 'step-tile-list',
    components: {
      StepTile
    },
    props: {
      steps: Object,
      stepLabels: Object,
      onStepEditOpen: Function,
      onStepDeleteSend: Function,
      onActivityDeleteSend: Function,
      onActivityEdit: Function,
      onStepReorder: Function,
      onFormReorder: Function
    },
    computed: {
      hasSteps () {
        return this.steps
          && this.steps.steps
          && this.steps.steps.length > 0;
      }
    },
    methods: {
      isFirstStep (step) {
        return this.hasSteps && (step.ordinal === 1);
      },
      isLastStep (step) {
        return this.hasSteps && (step.ordinal === this.steps.steps.length);
      },
      onActivityAdd(process) {
        this.$emit('on-activity-add', process);
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';

  .step-tiles-container {
    background-color: $cb-light-grey-3;
  }

  .step-tile-container {
    background-color: $cb-light-grey-3;
    padding: .75rem;
  }

</style>
