<template>
    <b-modal modal-class=""
             class="complete-action-modal"
             size="lg"
             hide-footer
             id="complete-action-modal"
             centered
             static
             @ok="onModalClose"
             @hidden="onModalClose"
             title="">

        <div class="action-details-area">
            <div>Review your to-do items and check once completed. Once all to-do items are checked, your task will no longer appear in the Action Center.</div>
            <br />
            <component v-if="showActionDetails" :is="actionItemDetailsComponentType" slot="details"
                       :instanceRequest="actionItem.instance"
                       v-on:action-item-details-loaded="handleDetailsLoaded"
                       v-on:action-item-update-error="handleUpdateError"
                       v-on:action-item-status-changed="handleActionItemStatusChanged" />

            <div v-if="showIsNotImplemented">It Is Not Implemented Now. Please Use Comlete Task</div>
        </div>
    </b-modal>
</template>

<script>
    import ActionCenterItemRows from "action-center/item-group/action-center-item-rows.vue";
    import ActionCenterTask from "action-center/task/action-center-task.vue";
    import ActionCenterI9EVerify from "action-center/i9everify/action-center-i9everify.vue";
    import ActionCenterHrForm from "action-center/hrform/action-center-hrform.vue";

    export default {
        name: "complete-action",
        components: {
            ActionCenterItemRows,
            //the name of the component registered here matters,
            //and should match the output of getActionItemDetailsComponent
            'action-center-task': ActionCenterTask,
            'action-center-i9everify': ActionCenterI9EVerify,
            'action-center-hrform': ActionCenterHrForm,
        },
        props: {
            detailsRequest: {
                type: Object,
                required: false,
                validator: function (value) {
                    return "method" in value && "url" in value;
                }
            },
            detailsPrefetch: {
                type: Object,
                required: false
            },
            actionItem: {
                type: Object,
                required: false
            }
        },
        computed: {
            actionItemDetailsComponentType() {
                const baseActionName = "action-center-";

                if (this.showActionDetails && this.actionItem) {
                    return baseActionName + this.actionItem.actionType.toLowerCase();
                }
                
                return "";
            },            
        },
        data() {
            return {
                apiData: null,
                showActionDetails: false,
                showIsNotImplemented: false
            };
        },
        methods: {
            async updateApiData() {
                if (this.detailsRequest) {
                    this.showIsNotImplemented = false;
                    this.showActionDetails = true;
                } else {
                    this.showIsNotImplemented = true;
                }
            },
            setApiData(newApiData) {
                if (newApiData) {
                    this.apiData = newApiData;
                }
            },
            async handleDetailsLoaded() {
                this.$emit('action-item-details-loaded');
            },
            async handleUpdateError() {
                await this.updateApiData();
            },
            cleanResources() {
                this.apiData = null;
                this.showActionDetails = false;

                if (this.actionItem) {
                    this.actionItem.instance = null;
                }
            },
            handleActionItemStatusChanged(actionItemInstance, actionItem) {
                this.cleanResources();

                this.$emit('action-item-status-changed');
            },
            onModalClose(bvModalEvent) {
                this.cleanResources();
                
                this.$emit('closed');
            }
        },
        watch: {
            detailsRequest: {
                immediate: true,
                deep: true,
                async handler(newValue, oldValue) {
                    if (newValue) {
                        await this.updateApiData();
                    }                    
                }
            }
        }
    };
</script>

<style>
    .fullscreen {
        min-width: 100%;
        min-height: 100%;
    }

    .action-details-area {
        min-height: 200px;
    }

    .cb-exit-confirmation-open .complete-action-modal {
        overflow: hidden;
        z-index: 999999 !important;
    }
</style>