<template>
  <section class="forms-splash">
    <h2 class="cb-splash-title">{{title}}</h2>

    <div class="icon">
      <svg>
        <title>{{title}}</title>
        <use v-bind="{'xlink:href': icon }" ></use>
      </svg>
    </div>

    <p class="cb-splash-content" v-if="message">
      {{message}}
    </p>

    <div class="action-buttons" v-if="hasActionSlot">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: 'cb-splash',
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: false
    }
  },
  computed: {
    hasActionSlot () {
      return !!this.$slots['default'];
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .forms-splash {
    text-align: center;
  }
  .cb-splash-title {
    color: $cb-black;
    font-weight: normal;
    margin: 0 0 2rem .5rem;
    font-size: 1.25rem;
  }
  .cb-splash-content {
    max-width: 37.5rem;
    padding: 1.5rem 1rem 2rem;
    margin: 0 auto;
    @include font-size-content-root;
  }
  .icon {
    width: 16.5rem;
    height: 16.5rem;
    margin: 0 auto;
  }
  .icon svg {
    width: 100%; height: 100%;
  }
</style>
