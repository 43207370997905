<template>
  <ul class="cb-tag-list">
    <li class="cb-tag-list-item" :class="getTagClasses(item)" v-for="(item, index) in items" :key="index">
      <slot :item="item">{{getTagLabel(item)}}</slot>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'cb-tag-list',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    getTagClasses (item) {
      const classes = {};

      // if passed a simple array of strings, default to info style
      if ((!item.type) || (item.type === 'info')) classes['cb-tag-list-item-info'] = true;
      else if (item.type === 'success') classes['cb-tag-list-item-success'] = true;
      else if (item.type === 'warning') classes['cb-tag-list-item-warning'] = true;
      else if (item.type === 'error') classes['cb-tag-list-item-error'] = true;

      return classes;
    },
    getTagLabel (item) {
      return item.label || item; // support complex items with types or just a simple array of strings
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@clickboarding/style/colors';

  .cb-tag-list {
    margin: 0;
    padding: 0;
    margin-bottom: -.5rem;
  }

  .cb-tag-list-item {
    display: inline-block;

    font-size: .75rem; // 12px
    padding: .3125rem .5rem; // when combined with 12px font size, adds 5px top/bottom padding and 8px left/right padding
    margin: 0;
    margin-bottom: .5rem;
    margin-right: .5rem;
  }

  .cb-tag-list-item-info {
    background-color: $cb-medium-blue-6;
  }

  .cb-tag-list-item-success {
    background-color: $cb-teal-6;
  }

  .cb-tag-list-item-warning {
    background-color: $cb-yellow-6;
  }

  .cb-tag-list-item-error {
    background-color: $cb-red-6;
  }
</style>