<template>
  <forms-content-build-element
    :dataUrl="dataUrl"
    :wrappedElement="wrappedElement"
    :returnLink="returnLink"
    :onBack="onBack"
    :onComplete="onComplete">
      <template slot-scope="buildElementContext">
        <cb-view-section type="secondary" class="forms-builder-image-instructions">
          <h1 class="headline">{{buildElementContext.component.title}}</h1>
          <div>{{buildElementContext.component.message}}</div>
        </cb-view-section>
        <cb-view-section type="secondary" :withFlushTop="true">
          <savage-form-text-area
            name="imageDescription"
            :label="buildElementContext.component.description.label"
            :placeholder="buildElementContext.component.description.placeholder"
            :validation="buildElementContext.component.description.validation"
            v-model="buildElementContext.element.description" />
          <savage-form-validated-container
            field-name="uploadImageFile"
            :validation="buildValidation(buildElementContext.component.upload.validation)"
            :value="buildElementContext.element.designer.file">
            <cb-single-file-upload name="uploadImageFile" class="upload-image"
              slot="form-field"
              v-model="buildElementContext.element.designer.file"
              :upload-url="buildElementContext.component.upload.url"
              :required="isRequired(buildElementContext.component.upload.validation)"
              :max-files="buildElementContext.component.upload.validation.maxFileCount.value"
              :max-file-size-mb="buildElementContext.component.upload.validation.maxFileSizeMB.value"
              :max-file-size-mb-error-message="buildElementContext.component.upload.validation.maxFileSizeMB.message"
              :allowed-content-types="buildElementContext.component.upload.validation.allowedContentTypes.value"
              :allowed-content-types-error-message="buildElementContext.component.upload.validation.allowedContentTypes.message"
              :drop-area-label="buildElementContext.component.upload.message"
              :button-label="buildElementContext.component.upload.label"
              :view-label="buildElementContext.component.upload.viewLabel"
              :delete-label="buildElementContext.component.upload.deleteLabel" />
          </savage-form-validated-container>
        </cb-view-section>
      </template>
  </forms-content-build-element>
</template>

<script>
import FormsContentBuildElement from 'forms/forms-content-build-element.vue';
import CbSingleFileUpload from 'common/components/cb-single-file-upload.vue';
import { SavageFormTextArea, SavageFormMixin, SavageFormValidatedContainer } from '@clickboarding/savage';
import CbViewSection from 'general/cb-view-section.vue';
import clonedeep from 'lodash.clonedeep';

export default {
  name: 'forms-content-build-image',
  components: {
    FormsContentBuildElement,
    SavageFormTextArea,
    CbSingleFileUpload,
    SavageFormValidatedContainer,
    CbViewSection
  },
  mixins: [
    // provides the validation directive required by 
    // savage to validate the slot content of validated-container
    SavageFormMixin
  ],
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    wrappedElement: {
      type: Object,
      required: true
    },
    returnLink: {
      type: Object,
      required: true
    },
    onBack: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  methods: {
    buildValidation (validation) {
      let result = {};

      if(this.isRequired(validation)) {
        result.required = {
          value: true,
          message: validation.minFileCount.message
        }
      }

      return result;
    },
    isRequired(validation) {
      return validation.minFileCount.value > 0;
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';

  .forms-builder-image-instructions {
    text-align: center;

    .headline {
      font-size: 1rem;
      font-weight: bold;
    }
  }
</style>
