<template>
  <component v-if="designer" :is="designer.container.component" v-bind="designer.container.props" :isGrouped="isGrouped">
    <template slot="fields">
      <b-card-group class="primary-section" v-if="localElement.availablePresentations[localElement.defaultPresentationKey].hasDependencies">
        <b-card no-body>
          <b-alert show class="with-icon w-100" variant="warning">
            <b-icon aria-hidden="true" icon="alert-triangle" />
            {{ `This field is used to conditionally show ${dependentFields}. Please remove the Conditional Logic Rule from
                        ${dependentFields} to edit this field.` }}
          </b-alert>
        </b-card>
      </b-card-group>
      <savage-form-select v-if="hasMultipleDesignerOptions"
        :label="$t('formsBuilder.dataEntry.presentationSelection.label')" :items="designerOptions"
        :validation="designerOptionsValidation" v-model="localElement.defaultPresentationKey"
        :disabled="localElement.availablePresentations[localElement.defaultPresentationKey].hasDependencies" />
      <component :is="designer.fields.component" v-bind="designer.fields.props" v-on="designer.fields.events" />
    </template>
    <template v-if="designer.children">
      <component :is="designer.children.component" v-bind="designer.children.props" v-on="designer.children.events" />
    </template>
  </component>
</template>

<script>
import set from "lodash.set";
import clonedeep from 'lodash.clonedeep';
import isequal from 'lodash.isequal';
import { SavageFormSelect } from '@clickboarding/savage';
import * as PresentationToDesignersMapper from 'forms/data-entry-mappers/presentation-to-designers';

export default {
  name: 'forms-data-entry-element',
  components: {
    SavageFormSelect
  },
  props: {
    element: {
      type: Object,
      required: true
    },
    isGrouped: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  computed: {
    hasMultipleDesignerOptions() {
      return this.designers.length > 1;
    },
    designerOptions() {
      return this.designers.map(designer => {
        return {
          label: designer.label,
          value: designer.key
        };
      });
    },
    designers() {
      return PresentationToDesignersMapper.map(this.localElement, this.updateModel);
    },
    selectedModel: {
      get() {
        return this.localElement.availablePresentations[this.localElement.defaultPresentationKey];
      },
      set(v) {
        // We need to clone the value before setting it, because if the value is an obect and our value
        // is the original reference, it doesn't seem to trigger reactivity updates in the other components;
        this.localElement.availablePresentations[this.localElement.defaultPresentationKey] = clonedeep(v);
      }
    },
    designer() {
      return this.designers.find(designer => designer.key === this.localElement.defaultPresentationKey);
    },
    dependentFields() {
      return this.localElement.availablePresentations[this.localElement.defaultPresentationKey].dependentFields;
    }
  },
  methods: {
    updateModel(path, value) {
      this.selectedModel = set(this.selectedModel, path, value);
    }
  },
  data() {
    return {
      localElement: null,
    };
  },
  created() {
    this.designerOptionsValidation = {
      required: {
        value: true,
        message: this.$t('common.validation.required')
      }
    };
  },
  watch: {
    element: {
      immediate: true,
      deep: true,
      handler() {
        // When we are configuring the designer elements of a 
        // group element, it results in a new clone of all the 
        // child elements, causing extra replacements of the 
        // element. The deep equality check here ensures if the
        // updated element is identical to the existing one, 
        // we won't incur the cost of re-evaluating everything
        // in this component.
        if (!isequal(this.localElement, this.element)) {
          this.localElement = clonedeep(this.element);
        }
      }
    },
    localElement: {
      immediate: true,
      deep: true,
      handler() {
        if (!isequal(this.element, this.localElement)) {
          this.$emit('update:element', this.localElement);
        }
      }
    },
  }
}
</script>
<style lang="scss">
div.read-only-message {
  padding: 0.5rem;
  border: 1px black solid;
  border-radius: 5px;
  margin-bottom: 1rem;
  width: fit-content;
  font-weight: bold;
}
</style>
<style scoped lang="scss">
.card-group.primary-section {
  width: fit-content !important;
  padding: 0px !important;
}
</style>