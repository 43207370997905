<template>
  <forms-data-entry-collect-data-category
    v-if="state && state.showDataCategorySelector"
    :data-url="dataUrl"
    :return-link="returnLink"
    :on-next="selectDataCategory" />
  <forms-data-entry-collect-data-category-elements
    v-else-if="state && state.showDataCategoryElementsSelector"
    :data-url="state.dataCategoryElementsUrl"
    :selected-elements="state.selectedDataCategoryElements"
    :return-link="returnLink"
    :on-back="reset"
    :on-next="selectDataCategoryElements" />
  <forms-data-entry-elements-loader
    v-else-if="state && state.showDataCategoryElementsConfiguration"
    :elements="state.configurationElements"
    :return-link="returnLink"
    :on-back="backToDataCategoryElementsSelector"
    :on-complete="extractElementsAndComplete"
    :availableConditionalFields="availableConditionalFields" />
</template>

<script>
import FormsDataEntryCollectDataCategory from 'forms/forms-data-entry-collect-data-category.vue';
import FormsDataEntryCollectDataCategoryElements from 'forms/forms-data-entry-collect-data-category-elements.vue';
import FormsDataEntryElementsLoader from 'forms/forms-data-entry-elements-loader.vue';

function initialData () {
  return {
    showDataCategorySelector: true,
    dataCategoryElementsUrl: null,
    showDataCategoryElementsSelector: false,
    selectedDataCategoryElements: null,
    configurationElements: null,
    showDataCategoryElementsConfiguration: false
  };
}

export default {
  name: 'forms-data-entry-collect',
  components: {
    FormsDataEntryCollectDataCategory,
    FormsDataEntryCollectDataCategoryElements,
    FormsDataEntryElementsLoader
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    returnLink: {
      type: Object,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    },
    availableConditionalFields: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      state: initialData()
    };
  },
  methods: {
    reset () {
      this.state = initialData();
    },
    selectDataCategory (dataCategory) {
      //hide data category selector (step 1)
      this.state.showDataCategorySelector = false;
      //set data url for data category elements selector (step 2)
      this.state.dataCategoryElementsUrl = dataCategory.value.url;
      //show data category elements selector (step 2)
      this.state.showDataCategoryElementsSelector = true;
    },
    selectDataCategoryElements (elements) {
      //hide data category elements selector (step 2)
      this.state.showDataCategoryElementsSelector = false;
      //store the selected elements so we can restore them if going back
      this.state.selectedDataCategoryElements = elements;
      //reconfigure the elements array to be compatible with the expected shape for addOrUpdateElements on FormsDataEntry
      this.state.configurationElements = elements.map((element) => { element.availableConditionalFields = this.availableConditionalFields; return { index: null, element: element } });
      //show elements configuration (step 3)
      this.state.showDataCategoryElementsConfiguration = true;
    },
    backToDataCategoryElementsSelector () {
      //hide elements configuration (step 3)
      this.state.showDataCategoryElementsConfiguration = false;
      //show data category elements selector (step 2)
      this.state.showDataCategoryElementsSelector = true;
    },
    extractElementsAndComplete (configurationElements) {
      // because the forms builder add method used to modify the form takes in an array
      // of form elements without the index property, use a map to flatten the array
      // and return just a collection of the form elements.  This is the opposite
      // of what is being done in selectDataCategoryElements
      let formElements = configurationElements.map(e => e.element);
      this.onComplete(formElements);
    }
  }
}
</script>
