<template>
  <div :class="{'cb-infinite-scroll': applyInfinteScroll}" ref="root">
    <div class="cb-infinite-scroll-container" :style="viewportStyle">
      <div class="cb-infinite-scroll-item-spacer" ref="spacer" :style="spacerStyle">
        <!-- @slot Use this slot to render list of items that you what to display with infinite scroll -->
        <slot :visibleItems="visibleItems" :startIndex="startIndex"/>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * This renders a list with infinite scroll.
 */
export default {
  name: 'cb-infinite-scroll',
  props: {
    /**
     * Full list of items.
     */
    items: {
      type: Array,
      required: false
    },
    /**
     * Number of items after which infinite scroll mechanism will apply.
     */
    minItemsScrollThreshold: {
      type: Number,
      default: 500
    }
  },
  computed: {
    applyInfinteScroll() {
      return this.itemCount > this.minItemsScrollThreshold;
    },
    itemCount() {
      return this.items ? this.items.length : 0;
    },
    viewportHeight() {
      return this.itemCount * this.rowHeight;
    },
    startIndex() {
      if (this.applyInfinteScroll) {
        const startNode = Math.floor(this.scrollTop / this.rowHeight);

        return Math.max(0, startNode);
      } else {
        return 0;
      }
    },
    visibleNodeCount() {
      const count = Math.ceil(this.rootHeight / this.rowHeight);

      return Math.min(this.itemCount - this.startIndex, count);
    },
    visibleItems() {
      if (this.applyInfinteScroll) {
        return this.items.slice(
            this.startIndex,
            this.startIndex + this.visibleNodeCount
        );
      } else {
        return this.items;
      }
    },
    offsetY() {
      return this.startIndex * this.rowHeight;
    },
    spacerStyle() {
      if (this.applyInfinteScroll) {
        return {
          transform: "translateY(" + this.offsetY + "px)"
        };
      }
    },
    viewportStyle() {
      if (this.applyInfinteScroll) {
        return {
          overflow: "hidden",
          height: this.viewportHeight + "px",
          position: "relative"
        };
      }
    }
  },
  methods: {
    handleScroll() {
      this.scrollTop = this.$refs.root.scrollTop;
    },
    updateRowHeight() {
      // Select rows from slot
      const rows = (this.$refs.spacer && this.$refs.spacer.children[0].children.length) ? this.$refs.spacer.children[0].children : undefined
      if (rows) {
        // Finding row with the biggest height
        this.rowHeight = Math.max.apply(null, [...rows].map(item => {
          // Calculating row height and margins
          const styles = window.getComputedStyle(item);
          const margin = parseFloat(styles['marginTop']) +
              parseFloat(styles['marginBottom']);

          return  item.offsetHeight + margin;
        }))
      }
    },
    doesBrowserSupportPassiveScroll() {
      let passiveSupported = false;

      try {
        const options = {
          get passive() {
            passiveSupported = true;

            return false;
          }
        };

        window.addEventListener("test", null, options);
        window.removeEventListener("test", null, options);
      } catch (err) {
        passiveSupported = false;
      }

      return passiveSupported;
    },
    applyInfiniteScroll() {
       window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.$emit('atBottom')
        }
      }
    },
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        const rowHeightNeedToUpdate = val && oldVal && val.length !== oldVal.length;

        if (rowHeightNeedToUpdate) {
          this.updateRowHeight();
        }
      }
    },
  },
  data() {
    return {
      scrollTop: 0,
      rowHeight: 30,
      rootHeight: 500
    };
  },
  mounted() {
    this.$refs.root.addEventListener(
        "scroll",
        this.handleScroll,
        {passive: this.doesBrowserSupportPassiveScroll()}
    );
    this.updateRowHeight();
    this.applyInfiniteScroll();
  },
}
</script>
<style lang="scss">
.cb-infinite-scroll {
  max-height: 500px;
  overflow: auto;
}
</style>

// <docs>
// ## Examples

// infinite scroll without infinite scroll 🤷‍♂️

// ```vue
// <cb-infinite-scroll :items="[...Array(50).keys()]">
//   <div slot-scope="{ visibleItems }">
//     <div v-for="item in visibleItems" :key="item">
//       <span>Line #{{ item }}</span>
//     </div>
//   </div>
// </cb-infinite-scroll>
// ```

// infinite scroll with infinite scroll

// ```vue
// <cb-infinite-scroll :items="[...Array(50).keys()]" :min-items-scroll-threshold="40">
//   <div slot-scope="{ visibleItems }">
//     <div v-for="item in visibleItems" :key="item">
//       <span>Line #{{ item }}</span>
//     </div>
//   </div>
// </cb-infinite-scroll>
// ```
// </docs>
