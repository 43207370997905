import importTypes from './importTypes';
import { AxiosLocal as axios } from 'common/services/axiosLocal.service';
import appSettings from 'appSettings';
import CandidateSpecs from './imports-candidateSpecs';
import TeamMemberSpecs from './imports-teamMemberSpecs';
import moment from 'moment';
import ImportStatus from './importStatus';
const pageSize = 50;

export default {
    async loadImports(clientId, offset, limit) {
        let data = [];
        const url = `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(
            clientId
        )}/Sections/Imports?offset=${offset}&limit=${limit}`;
        const response = await axios.get(url);
        const importData = response.data.imports;

        if (importData) {
            const finalRowData = [];
            importData.forEach((element) => {
                finalRowData.push({
                    fileName: element.fileName,
                    type: element.importTypeId,
                    records:
                        element.statusId == ImportStatus.VALIDATION_READY ||
                        element.statusId == ImportStatus.VALIDATION_STARTED
                            ? 'Pending'
                            : element.totalRecords,
                    validationErrors:
                        element.statusId == ImportStatus.VALIDATION_READY ||
                        element.statusId == ImportStatus.VALIDATION_STARTED
                            ? ''
                            : element.failedValidationRecords,
                    processingErrors:
                        element.statusId == ImportStatus.VALIDATION_READY ||
                        element.statusId == ImportStatus.VALIDATION_STARTED
                            ? ''
                            : element.failedImportRecords,
                    statusName: element.statusName,
                    userName: element.userName,
                    importId: element.importId,
                    uploadDate: element.createdOn.includes(
                        '0001-01-01T00:00:00+00:00'
                    )
                        ? ' '
                        : moment(String(element.createdOn)).format(
                              'MM/DD/YYYY  h:mm A'
                          ),
                    action: element.statusId,
                    validationResults: element.statusId,
                    importReport: element.statusId,
                    typeName:
                        element.typeName === 'Employee'
                            ? 'Candidate'
                            : element.typeName,
                });
            });

            data = finalRowData;
        }
        return data;
    },
    async downloadTemplate(importType) {
        const importName = getImportName(importType);
        const response = await axios.get(
            `${appSettings.apiBaseUrl}Downloads/${importName}ImportTemplate`
        );
        const type = response.headers['content-type'];
        const blob = new Blob([response.data], {
            type: type,
            encoding: 'UTF-8',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${importName}_ImportTemplate.csv`;
        link.click();
    },
    getImportSpecs(importType) {
        switch (importType) {
            case importTypes.EMPLOYEE:
                return this.getCandidateSpecs();
            case importTypes.TEAM_MEMBER:
                return this.getTeamMemberSpecs();
            default:
                return [];
        }
    },
    shouldShowSourceSystemId() {
        if (appSettings.hasOwnProperty("sourceSystemIdFeatureToggle")) {
          return JSON.parse(appSettings.sourceSystemIdFeatureToggle);
        }
        return false;
      },
    getCandidateSpecs() {
        const specs = new CandidateSpecs();
        if (!this.shouldShowSourceSystemId()) {
            specs.specs = specs.specs.filter(s => s.field !== 'Source System Identifier')
          }
        return specs.specs;
    },
    getTeamMemberSpecs() {
        const specs = new TeamMemberSpecs();
        if (!this.shouldShowSourceSystemId()) {
            specs.specs = specs.specs.filter(s => s.field !== 'Source System Identifier')
          }
        return specs.specs;
    },
    getSpecOptions() {
        return [
            { value: importTypes.EMPLOYEE, text: 'Candidate' },
            { value: importTypes.TEAM_MEMBER, text: 'Team Member' },
        ];
    },
    getImportTypeByName(name) {
        switch (name) {
            case 'candidate':
                return importTypes.EMPLOYEE;
            case 'teammember':
                return importTypes.TEAM_MEMBER;
            case 'locations':
                return importTypes.LOCATION;
            default:
                return '';
        }
    },
    getPageSize() {
        return pageSize;
    },
    async putImport(url, body) {
        const response = await axios.put(url, body);
        return true;
    },
};

function getImportName(importType) {
    switch (importType) {
        case importTypes.EMPLOYEE:
            return 'Candidate';
        case importTypes.LOCATION:
            return 'Location';
        case importTypes.TEAM_MEMBER:
            return 'TeamMember';
        default:
            return '';
    }
}
