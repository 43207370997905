export default class ProcessFlowSpecs {
  constructor() {
    this.specs = [
      {
        key: "emailAddress",
        field: "Email Address",
        dataType: "Text",
        required: "Yes",
        description: { text: "Candidate's email address.", info: "" },
        validValues: "Text up to 100 characters.",
      },
      {
        key: "location",
        field: "Location",
        dataType: "Text",
        required: "Yes",
        description: { text: "Location Name listed exactly how it is in your Portal.", info: "" },
        validValues: [],
      },
      {
        key: "processFlowName",
        field: "Process Flow Name",
        dataType: "Text",
        required: "Yes",
        description: { text: "Process Flow Name listed exactly how it is in your Portal.", info: "" },
        validValues: [],
      },
      {
        key: "candidateDueDate",
        field: "Candidate Due Date",
        dataType: "Date",
        required: "Yes",
        description: { text: "Candidate's Due Date.", info: "*Must be prior to or same day as Process Flow Due Date." } ,
        validValues: "Use this format: mm/dd/yyyy",
      },
      {
        key: "dueDate",
        field: "Due Date",
        dataType: "Date",
        required: "Yes",
        description: { text: "Process Flow Due Date.", info: "" },
        validValues: "Use this format: mm/dd/yyyy",
      },
      {
        key: "recruiter",
        field: "Recruiter",
        dataType: "Text",
        required: "No",
        description: { text: "List the Email Address of the Team Member you wish to assign.", info: "" },
        validValues: [],
      },
      {
        key: "hiringManager",
        field: "Hiring Manager",
        dataType: "Text",
        required: "No",
        description: { text: "List the Email Address of the Team Member you wish to assign.", info: "" },
        validValues: [],
      },
      {
        key: "coordinator",
        field: "Coordinator",
        dataType: "Text",
        required: "No",
        description: { text: "List the Email Address of the Team Member you wish to assign.", info: "" },
        validValues: [],
      },
    ];
  }
}
