<template>
  <section class="forms-data-entry">
    <forms-builder :dataUrl="dataUrl" :isReadOnly="isReadOnly" :onBack="onBack" :can-preview="true"
      ref="formsBuilderInstance">
      <template slot-scope="formsBuilderProps">
        <cb-view-section type="secondary">
          <cb-view-sub-section>
            <savage-form-text-input name="formName" :label="formsBuilderProps.formLabels.nameLabel"
              :placeholder="formsBuilderProps.formLabels.namePlaceholder"
              :validation="buildFormNameValidation(formsBuilderProps)"
              v-model="formsBuilderProps.form.revision.sections[0].name" />
            <savage-form-text-input name="formTitle" :label="formsBuilderProps.formLabels.titleLabel"
              :placeholder="formsBuilderProps.formLabels.titlePlaceholder"
              :validation="buildFormTitleValidation(formsBuilderProps)"
              v-model="formsBuilderProps.form.revision.sections[0].pages[0].label" />
          </cb-view-sub-section>
          <cb-view-sub-section v-if="formsBuilderProps.hasElements()">
            <cb-list class="forms-data-entry-cb-list" :items="formsBuilderProps.getElementListItems()"
              @update:items="formsBuilderProps.updateElementsFromList($event)" :useSingleLineLabels="true"
              :canReorder="!isReadOnly">
              <template slot="icon" slot-scope="{ item }">
                <svg>
                  <use v-bind="{ 'xlink:href': item.icon }"></use>
                </svg>
              </template>
              <template slot-scope="{ item }">
                <span class="element-label">{{ item.label }}</span>
                <b-alert v-if="item.element.errorMessage" show class="element-error-message with-icon w-100"
                  variant="warning">
                  <b-icon aria-hidden="true" icon="alert-triangle" />
                  {{ item.element.errorMessage }}
                </b-alert>
                <b-alert v-show="item.element.isConditionallyShown" show class="conditionally-shown-tag" variant="info">
                  Conditionally Shown
                </b-alert>
              </template>
              <template v-if="!isReadOnly" slot="action" slot-scope="{ item }">
                <action-menu :toggle-orientation="'portrait'" :menu-position="'middle-left'" :items="item.actions" />
              </template>
            </cb-list>
          </cb-view-sub-section>
          <cb-view-sub-section v-if="!isReadOnly">
            <div class="forms-data-entry-collect-button-wrapper">
              <button type="button"
                @click="collectNewElements(formsBuilderProps.formLabels.content.url, 'addContent', null)"
                class="cb-btn secondary addContentButton">{{ $t('formsBuilder.addContentButton') }}</button>
              <button type="button"
                @click="collectNewElements(formsBuilderProps.formLabels.collect.url, 'collectInfo', formsBuilderProps.getElementsForConditionalLogic)"
                class="cb-btn secondary collectInfoButton">{{ formsBuilderProps.formLabels.collect.label }}</button>
              <button v-if="formsBuilderProps.hasConditionalFields()" type="button"
                @click="formsBuilderProps.validateLayout()" class="cb-btn secondary validateLayoutButton">Validate
                Layout</button>
            </div>
          </cb-view-sub-section>
        </cb-view-section>
      </template>
      <template slot="add-element" slot-scope="addElementScope">
        <component :is="addElementScope.componentProps.component" v-bind="addElementScope.componentProps" />
      </template>
      <template slot="edit-element" slot-scope="editElementScope">
        <component :is="editElementScope.componentProps.component" v-bind="editElementScope.componentProps" />
      </template>
    </forms-builder>
  </section>
</template>
<script>
import FormsBuilder from 'forms/forms-builder.vue';
import { SavageFormTextInput } from '@clickboarding/savage';
import CbList from 'common/components/cb-list.vue';
import ActionMenu from 'common/components/action-menu.vue';
import CbViewSection from 'general/cb-view-section.vue';
import FormsDataEntryCollect from 'forms/forms-data-entry-collect.vue';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import FormsContentBuild from 'forms/forms-content-build.vue';
import CbViewSubSection from 'general/cb-view-sub-section.vue';

export default {
  name: 'forms-data-entry',
  components: {
    FormsBuilder,
    SavageFormTextInput,
    CbList,
    ActionMenu,
    CbViewSection,
    CbViewSubSection
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    onBack: {
      type: Function,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    collectNewElements(url, mode, propsFunc) {
      PrimaryMessageBus.$emit('clear-all-messages');

      if (propsFunc) {
        this.$refs.formsBuilderInstance.showAddElement({
          dataUrl: url,
          component: mode === 'addContent' ? FormsContentBuild : FormsDataEntryCollect,
          availableConditionalFields: propsFunc()
        });
      } else {
        this.$refs.formsBuilderInstance.showAddElement({
          dataUrl: url,
          component: mode === 'addContent' ? FormsContentBuild : FormsDataEntryCollect
        });
      }

    },
    buildFormNameValidation(props) {
      return {
        required: {
          value: true,
          label: props.formLabels.nameLabel,
          message: props.validationMessages.required
        },
        maxlength: {
          value: 100,
          label: props.formLabels.nameLabel,
          message: props.formLabels.nameLabel + ' must be 100 characters or less.'
        }
      };
    },
    buildFormTitleValidation(props) {
      return {
        maxlength: {
          value: 100,
          label: props.formLabels.titleLabel,
          message: props.formLabels.titleLabel + ' must be 100 characters or less.'
        }
      };
    },
    // showEditContentElement and deleteContentElement are needed because 
    // the forms-builder ref is not yet available when getElementListItmes
    // maps the elements to elementListItems
    showEditElement() {
      this.$refs.formsBuilderInstance.showEditElement(...arguments);
    },
    deleteElement() {
      this.$refs.formsBuilderInstance.deleteElement(...arguments);
    },
  }
}
</script>
<style scoped lang="scss">
@import '@clickboarding/style/mixins';
@import '@clickboarding/style/colors';

.forms-data-entry-collect-button-wrapper {
  display: flex;

  .addContentButton {
    margin-right: .5rem;
    flex: 1;
  }

  .collectInfoButton {
    margin-left: .5rem;
    flex: 1;
  }

  .validateLayoutButton {
    margin-left: 1rem;
    flex: 1;
  }
}

.conditionally-shown-tag {
  margin-bottom: 0;
  padding: 0.9rem;
  font-weight: bold;
  margin-left: 5%
}

.card-group.primary-section {
  width: fit-content !important;
  padding: 0px !important;
}

span.element-label {
  width: 13.858%;
}

div.element-error-message {
  margin-bottom: 0;
  padding: 0px 0px 0px 5px;
  font-weight: bold;
}
</style>
<style lang="scss">
div.cb-list-item-label.cb-list-item-label-single-line {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}
</style>