<template>
  <section class="task-complete-section">
    <div class="task-complete-section-content">
        <h4 class="task-complete-title">{{$t("actionCenter.task.complete.title")}}</h4>

        <div class="icon">
            <svg>
                <use v-bind="{'xlink:href': TaskIconPath }" ></use>
            </svg>
        </div>

        <p class="task-complete-message">
            {{$t("actionCenter.task.complete.description")}}
        </p>
    </div>
    <div class="task-complete-buttons">
        <button type="button" class="cb-btn primary" @click="$emit('canceled')">
            {{$t("actionCenter.task.complete.cancelLabel")}}
        </button>
        <button type="button" class="cb-btn primary" @click="$emit('confirmed')">
            {{$t("actionCenter.task.complete.completeLabel")}}
        </button>
    </div>
  </section>
</template>

<script>
  import TaskIcon from 'common/assets/icons/action-center/task.svg';

  export default {
    name: 'action-center-task-complete',
    computed: {
      TaskIconPath () {
        return '#' + TaskIcon.id;
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .task-complete-section {
    text-align: center;
    background-color: $cb-white;
    position: relative;
    border: solid 1px $cb-light-grey-1;
  }
  .task-complete-title {
    color: $cb-black;
    padding-top: 1rem;
    padding-bottom: .5rem;
    font-size: 1rem;
    font-weight: normal;
  }
  .task-complete-message {
    padding: 1rem 1.5rem;
    margin: 0 auto;
    max-width: 28rem;
    @include font-size-content-root;
  }
  .icon {
    width: 9.5rem;
    height: 9.5rem;
    margin: 0 auto;
  }
  .icon svg {
    width: 100%; height: 100%;
  }
  .task-complete-buttons {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    
    button {
      width: 100%;
    }
  }

  .task-complete-section-content {
      margin-bottom: 2.5rem;
  }
</style>
