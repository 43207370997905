<template>
  <div>
    <div :id="textAreaInstructionsId">
      <div class="mb-3">
        {{$t("formsBuilder.dataEntry.selectPresentation.bulkEdit.instructions")}}
      </div>
      <b-card-group class="mb-3" deck>
        <b-card bg-variant="light" class="border-0">
          <b-card-title class="m-0" :title="$t('formsBuilder.dataEntry.selectPresentation.bulkEdit.example1Title')" />
          <ul class="list-unstyled m-0">
            <li 
              v-for="(exampleContent, index) in $t('formsBuilder.dataEntry.selectPresentation.bulkEdit.exampleContent')" 
              :key="`example-1-${index}`">
                <span class="forms-data-entry-element-bulk-edit-example-text">{{ exampleContent.label }}</span>
            </li>
          </ul>
        </b-card>
        <b-card bg-variant="light" class="border-0">
          <b-card-title class="m-0" :title="$t('formsBuilder.dataEntry.selectPresentation.bulkEdit.example2Title')" />
          <ul class="list-unstyled m-0">
            <li
              v-for="(exampleContent, index) in $t('formsBuilder.dataEntry.selectPresentation.bulkEdit.exampleContent')" 
              :key="`example-2-${index}`">
                <span class="forms-data-entry-element-bulk-edit-example-text">{{ exampleContent.value }}&#9;{{ exampleContent.label }}</span>
            </li>
          </ul>
        </b-card>
      </b-card-group>
    </div>
    <b-form-group :label-for="allowTabsId">
      <!-- size is small to match parent form savage sizing -->
      <b-form-checkbox :id="allowTabsId" v-model="allowTabs" switch size="sm">
        {{$t("formsBuilder.dataEntry.selectPresentation.bulkEdit.allowTabsLabel")}}
      </b-form-checkbox>
      <template slot="description">
        <span role="alert" aria-atomic="true" v-html="allowTabsInstructionsCurrentBehavior" />
      </template>
    </b-form-group>
    <!-- size is small to match parent form savage sizing -->
    <b-form-textarea 
      :placeholder="$t('formsBuilder.dataEntry.selectPresentation.bulkEdit.inputPlaceholder')"
      rows="22"
      size="sm"
      v-model="bulkOptions"
      @[textAreaTabEvent].tab.exact.prevent="bulkEditTabHandler"
      :aria-describedby="textAreaInstructionsId" />
  </div>
</template>

<script>
  import uuid from "uuid/v4";
  import isnil from "lodash.isnil";

  export default {
    name: "forms-data-entry-element-select-bulk-edit",
    props: {
      options: {
        type: Array,
        required: false,
        default: null
      }
    },
    created() {
      this.allowTabsId = `allowTabsChckbox_${uuid()}`;
      this.textAreaInstructionsId = `textAreaInstructions_${uuid()}`;
      document.addEventListener("keyup", this.handleKeyboardAllowTabsToggle);
    },
    destroyed() {
      document.removeEventListener("keyup", this.handleKeyboardAllowTabsToggle);
    },
    methods: {
      hasChanges () {
        return this.bulkOptions !== this.originalBulkOptions;
      },
      getUpdatedOptions() {
        return this.bulkOptions
          .replace(/\r\n/g, "\n")
          .split("\n")
          .reduce((updatedOptions, optionText) => {
            if (optionText.trim().length) {
              const optionTextParts = optionText.split(/\t(.+)/);
              
              updatedOptions.push({
                label:
                  optionTextParts.length > 1 ? optionTextParts[1].trim() : "",
                value: optionTextParts[0].trim()
              });
            }
            
            return updatedOptions;
          }, []);
      },
      handleKeyboardAllowTabsToggle(evt) {
        if ((evt.key === "m" || evt.key === "M") && evt.ctrlKey) {
          this.allowTabs = !this.allowTabs;
        }
      },
      bulkEditTabHandler(evt) {
        let text = evt.target.value,
          originalSelectionStart = evt.target.selectionStart,
          textStart = text.slice(0, originalSelectionStart),
          textEnd = text.slice(originalSelectionStart);

        this.bulkOptions = `${textStart}\t${textEnd}`;
        evt.target.value = this.bulkOptions; // required to make the cursor stay in place.
        const selectionPosition = originalSelectionStart + 1;
        evt.target.selectionEnd = selectionPosition;
        evt.target.selectionStart = selectionPosition;
      }
    },
    computed: {
      allowTabsInstructionsCurrentBehavior() {
        const translationKey = this.allowTabs
          ? "allowTabsStatusEnabledHtml"
          : "allowTabsStatusDisabledHtml";
        return this.$t(`formsBuilder.dataEntry.selectPresentation.bulkEdit.${translationKey}`, [
          '<strong>TAB</strong>', 
          '<strong class=\'text-nowrap\'>CTRL + M</strong>'
        ]);
      },
      textAreaTabEvent() {
        return this.allowTabs ? "keydown" : null;
      }
    },
    watch: {
      options: {
        immediate: true,
        handler() {
          const options = this.options || [];
          
          const bulkOptionsText = options
            .reduce((optionsText, option) => {
              if (!isnil(option)) {
                const optionValue = !isnil(option.value) ? option.value : '';
                const optionLabel = !isnil(option.label) ? option.label : '';

                const optionText = optionLabel !== ''
                  ? `${optionValue}\t${optionLabel}`
                  : optionValue;
                
                if (optionText.trim().length) {
                  optionsText.push(optionText);
                }
              }

              return optionsText;
            }, []).join('\n');

          this.originalBulkOptions = bulkOptionsText;
          this.bulkOptions = bulkOptionsText;
        }
      }
    },
    data() {
      return {
        originalBulkOptions: null,
        bulkOptions: null,
        allowTabs: true
      };
    }
  };
</script>

<style lang="scss" scoped>
  .forms-data-entry-element-bulk-edit-example-text {
    white-space: pre;
  }
</style>
