<template>
  <div class="cb-exit-confirmation" v-if="showConfirmation">
    <div class="cb-exit-confirmation-content">
      <button class="cb-exit-confirmation-close" @click="doCancel()">
        <svg>
          <use v-bind="{'xlink:href': this.closeIcon }" />
        </svg>
      </button>
      <div class="cb-exit-confirmation-title">{{labels.title}}</div>
      <div class="cb-exit-confirmation-message">
        <svg>
          <use v-bind="{'xlink:href': this.messageIcon }" />
        </svg>
        <p class="cb-exit-confirmation-message-text">
          {{labels.message}}
        </p>
      </div>
      <div class="cb-exit-confirmation-footer d-flex">
        <button class="cb-btn primary cancel flex-fill" @click="doCancel()">
          {{labels.cancelLabel}}
        </button>
        <button class="cb-btn primary confirm flex-fill" @click="doConfirm()">
          {{labels.confirmLabel}}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { IconProvider } from 'common/icon.module';
import DataProvider from 'common/components/cb-exit-confirmation-data-provider';

export default {
  name: 'cb-exit-confirmation',
  props: {},
  mounted () {
    DataProvider.getData().then((response) => {
      this.labels = response
    });
  },
  methods: {
    open () {
      return new Promise ((resolve, reject) => {
        document.body.classList.add('cb-exit-confirmation-open');
        this.showConfirmation = true;
        this.action = resolve;
      });
    },
    close (result) {
      if (this.action) this.action(result);
      this.action = null;
      this.showConfirmation = false;
      document.body.classList.remove('cb-exit-confirmation-open');
    },
    doCancel() {
      const cancelled = false;
      this.close(cancelled);
    },
    doConfirm () {
      const confirmed = true;
      this.close(confirmed);
    }
  },
  data () {
    return {
      showConfirmation: false,
      labels: {},
      messageIcon: IconProvider.getPath('Activity-Warning-Icon'),
      closeIcon: IconProvider.getPath('Ui-X-Icon'),
      action: null
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  .cb-exit-confirmation {
    background: rgba(0,0,0,.75);
    width: 100%; height: 100%;
    position: fixed;
    top: 0; left: 0;
    z-index: 9999;
  }

  .cb-exit-confirmation-content {
    text-align: center;
    background: $cb-white;
    position: absolute;
    top: 5.625rem; left: 50%;
    z-index: 9999;
    width: 22.5rem;
    max-height: calc(100vh - 11.25rem); /* view height minus top*2 (vertically centered on the screen */
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    overflow: auto;
  }

  .cb-exit-confirmation-close {
    position: absolute;
    top: .75rem; right: .75rem;
    padding: 0;
    height: .75rem;
    width: .75rem;
    display: flex;
    border: none;
    background-color: transparent;
    svg {
      flex: 1 1 auto;
      fill: $cb-medium-blue-1;
      height: .75rem; width: .75rem;
    }
  }

  .cb-exit-confirmation-title {
    padding: 1.25rem 4rem 1.25rem 4rem;
    font-size: 1.125rem;
  }
  
  .cb-exit-confirmation-message {
    text-align: center;
  }
  
  .cb-exit-confirmation-message-text {
    font-size: .875rem;
    padding: 1rem 0 1rem 0;
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    .cb-exit-confirmation-content {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      max-height: 100%;
      margin-left: 0;
      max-width: 100%;
      -webkit-transform: none;
      -ms-transform: none;
      transform: none;
    }
    
    .cb-exit-confirmation-footer {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
</style>
<style lang="scss">
  .cb-exit-confirmation-open {
    overflow: hidden;
  }
</style>
