<template>
  <div v-if="!componentLoadError" ref="externalComponent" />
  <div v-else>
    {{componentLoadError}}
  </div>
</template>
<script>
export default {
  name: "cb-vue-component-loader",
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      required: true,
    },
    integrity: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true,
    }
  },
  watch: {
    $attrs: function() {
      if(this.componentLoaded) {
        this.mapAttrsToComponentProps();
      }
    }
  },  
  data() {
    return {
      componentLoadError: null,
      componentLoaded: false,
      externalComponent: null
    };
  },
  async created() {
    this.addScript(this.src, this.integrity, this.onScriptLoaded, this.onScriptError);
  },
  methods: {
    addScript(src, integrity, onLoad, onError) {
      const script = document.createElement('script');
      script.src = src;
      script.onload = onLoad;
      script.onerror = onError;

      //The bypass of the integrity check should only happen with 
      //manual setup on the database and not in any production scenarios.
      //This is to assist with iterative development of external components
      //and testing integration with the admin portal, i.e. not having
      //to update the integrity with every change in the external component.
      if (integrity) {
        script.crossOrigin = "anonymous";
        script.integrity = integrity;
      }

      this.$refs.script = script;
      document.head.appendChild(script);
    },
    async onScriptLoaded() {
      //the external component is expected to create a new Vue on its own,
      //in order to isolate it from vue used within the app. Doing this
      //requires us to manually bind the data to the component instead though.
      this.externalComponent = this.getComponentAfterScriptLoad();

      // These attributes need to be mapped prior to mounting the component
      // so they are available in the external components mounted() hook.
      this.mapAttrsToComponentProps();
      
      this.externalComponent.$mount(this.$refs.externalComponent);
      this.componentLoaded = true;
      this.removeScript();
    },
    mapAttrsToComponentProps() {
      //this in conjuction with inheritAttrs=false causes input
      //properties to this component to be mapped to the props
      //in the external component.
      Object.assign(this.externalComponent, this.$attrs);
    },
    onScriptError(event) {
      this.componentLoadError = this.$i18n.t("general.componentLoadError", [event.message]);
      this.componentLoaded = false;
      this.removeScript();
    },
    getComponentAfterScriptLoad() {
      return window[this.name];
    },
    removeScript() {
      this.$refs.script.remove();
    }
  }
};
</script>

<style scoped lang="scss">
</style>