<template>

</template>
  
  <script>
    import { SavageFormSelect, SavageFormTextArea, SavageFormMixin } from '@clickboarding/savage';
    import CbViewSection from 'general/cb-view-section.vue';
    
    export default {
      name: 'touchpoint-configure',
      components: {
        CbViewSection,
        SavageFormSelect,
        SavageFormTextArea
      },
      created() {
        // handles the case of loading from the list
        // if the form object comes from the form edit link
        // all of this is filled in already
        if (this.touchpoint.touchpointTemplateId == null) {
            this.touchpoint.touchpointName = this.touchpoint.label;
            this.touchpoint.touchpointTemplateId = this.touchpoint.value;
        }

      },
      model: {
        prop: 'activity'
      },
      props: {
        activity: {
          type: Object,
          required: true
        }
      },
      watch: {
        touchpoint: {
          deep: true,
          handler: function(val, oldVal) {
            this.$emit('input', val);
          }
        }
      },
      computed: {
        touchpoint() {
          return this.activity;
        }
      }
    }
  </script>
  