<template>
    <div class="cb-grid-selector-item-content">
      <div class="cb-grid-selector-item-icon">
        <slot name="icon" :item="item">
          <svg>
              <use class="cb-grid-selector-item-icon-use" v-bind="{'xlink:href': getIconPath(item) }" />
          </svg>
        </slot>
      </div>
      <slot name="label" :item="item">
        <span class="cb-grid-selector-item-label">
          {{item.label}}
        </span>
      </slot>
    </div>
</template>
<script>
import { IconProvider } from "common/icon.module";

export default {
  name: "cb-grid-selector-item-content",
  props: {
    item: {
      type: Object,
      required: true
    },
    iconNameProvider: {
      type: Function,
      required: false,
      default: () => {}
    }
  },
  methods: {
    getIconPath(icon) {
      return IconProvider.getPath(this.iconNameProvider(icon));
    }
  }
};
</script>
<style scoped lang="scss">

.cb-grid-selector-item-content {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.cb-grid-selector-item-icon {
  padding-right: 1em;

  svg {
    width: 3em;
    height: 3em;
  }
}
</style>
