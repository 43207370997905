export default function DashService ($http, $q) {
		return {
			data: {
				itemSnoozed: {
					id: null,
					bool: false
				}
			},
		doSnooze: function(method, endpoint, id, isSnoozed, data) {
		  var deferred = $q.defer();
				var _data = this.data;

				$http({
					method: method,
					url: endpoint,
					data: data
				}).then(function(data) {
			deferred.resolve(data);
					_data.itemSnoozed.id = id;
					_data.itemSnoozed.bool = isSnoozed;
				}, function(err) {
			deferred.reject(err);
				});

		  return deferred.promise;
		}
	};
};

DashService.$inject = ['$http','$q']