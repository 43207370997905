<template>
    <user-menu v-if="header" ref="userMenu"
      :greeting="'Hello'"
      :name="header.firstName"
      :supportTitle="header.support.title"
      :logoutLabel="header.logout.label"
      :logout="logoutAndResetApp"
      :header="header">
        <template slot="support" v-if="header.support">
          <div v-if="header.support.phone" class="user-menu-contact-section">
            <svg class="cb-icon">
                <use :xlink:href="iconPaths.phone" />
            </svg>
            <a :href="'tel:' + header.support.phone" class="tel">{{header.support.phone}}</a>
          </div>

          <div v-if="header.support.email" class="user-menu-contact-section">
            <svg class="cb-icon">
                <use :xlink:href="iconPaths.email" />
            </svg>
            <a :href="'mailto:' + header.support.email" class="mail">{{header.support.email}}</a>
          </div>

          <div v-if="header.support.help" class="vert-c resourceCenter">
            <svg class="cb-icon">
                <use :xlink:href="iconPaths.resource" />
            </svg>
            <a class="cb-link" :href="zendeskHelpCenterUrl" target="_blank">{{header.support.help.label}}</a>
          </div>

          <div class="vert-c ideasPortal">
            <svg class="cb-icon">
                <use :xlink:href="iconPaths.idea" />
            </svg>
            <a class="cb-link" :href="ideasUrl" target="_blank">{{$t('header.support.ideas.label')}}</a>
          </div>

          <div class="h-line"></div>

          <p v-if="header.support.hours" class="support-details">{{header.support.hours.label}}: {{header.support.hours.value}}</p>
          <p v-if="header.support.systemLabel" class="support-details">{{header.support.systemLabel}}: {{navigator.browser.name}} {{navigator.browser.version}} {{navigator.os.name}} {{navigator.os.version}}</p>
        </template>
        <template slot="client" v-if="header && header.clientSelection && header.clientSelection.client">
          <div class="clientname">{{clientSelection.client.name}}</div>
          <div class="pt-2 support-details" v-text="clientSelection.client.clientApiIdentifier" />
          <div class="cb-link view-all-companies" v-show="showClientSelect" @click="beforeCompanySelect()">
            {{clientSelection.title}}
          </div>
          <div class="h-line"></div>
        </template>
    </user-menu>
</template>

<script>
import DeviceDetector from 'ua-parser-js';
import UserMenu from 'common/components/header/cb-user-menu.vue'
import AppSettings from 'appSettings';
import { adminAppEventBus, AdminAppEvents } from 'admin-app-event-bus';
import phoneIcon from '@clickboarding/assets/icons/nova/line/phone/phone-outgoing.svg';
import emailIcon from '@clickboarding/assets/icons/nova/line/email/email-read.svg';
import resourceIcon from '@clickboarding/assets/icons/nova/line/content/content-book-3.svg';
import ideaIcon from '@clickboarding/assets/icons/nova/line/lights/lightbulb-4.svg';

export default {
  name: 'cb-user-menu',
  components: {
    UserMenu
  },
  props: {
    header: {
      type: Object,
      required: false
    },
    logout: {
      type: Function,
      required: true
    },
    companySelect: {
      type: Function,
      required: true
    },
    clientSelection: {
      type: Object
    }
  },
  created () {
    let deviceDetector = new DeviceDetector();
    this.navigator = deviceDetector.getResult();

    this.iconPaths = {
      email:'#' + emailIcon.id,
      phone:'#' + phoneIcon.id,
      resource:'#' + resourceIcon.id,
      idea:'#' + ideaIcon.id
    }

    this.zendeskHelpCenterUrl = AppSettings.zendeskHelpCenterUrl;
    this.ideasUrl = AppSettings.ideasUrl;
  },
  methods: {
    async beforeCompanySelect () {
      this.$refs.userMenu.showPopout(null);

      const response = await this.companySelect();

      await this.$store.dispatch('setClient', {
          id: response.clientSelection.client.clientApiIdentifier
      });
    },
    async logoutAndResetApp() {
      await this.$store.dispatch('logout');
      adminAppEventBus.emit(AdminAppEvents.UserSignedOut, {});
      this.logout();
    }
  },
  computed: {
    showClientSelect () {
      return this.clientSelection.hasMultiClientAccess && this.clientSelection.client.clientId
    }
  }
}
</script>

<style scoped lang="scss">
  .cb-icon {
    width: 3rem; height: 3rem;
    margin-right: .2rem;
  }
  .support-details {
    font-size: .75rem;
  }
  .user-menu-contact-section {
    display: flex;
    align-items: center;
    padding-bottom: .25rem;
  }
  .resourceCenter {
    padding-top: .25rem;
  }
  .ideasPortal {
    padding-top: .25rem;
  }
</style>
