<template>
  <section>
    <b-form-group class="w-50">
      <template v-slot:label>
        {{ labels.milestoneLabel }}
        <span aria-hidden class="text-danger">*</span>
      </template>
      <b-form-input
          class="milestone-name"
          v-model="model.referenceName"
          @change="model.description = model.referenceName"
          :state="validateState(['referenceName'])"/>
    </b-form-group>
    <b-form-group class="w-50">
      <template v-slot:label>
        {{ labelsTriggers.stepTriggerLabel }}
        <span aria-hidden class="text-danger">*</span>
      </template>
      <b-form-select
          class="milestone-trigger"
          required
          text-field="name"
          value-field="typeEnum"
          v-model="model.trigger.typeEnum"
          :state="validateState(['trigger', 'typeEnum'])"
          :options="[{ typeEnum: null, name: labelsTriggers.defaultTrigger} ,...labelsTriggers.triggers]"
      >
      </b-form-select>
    </b-form-group>
    <b-button class="d-block ml-auto mr-auto"
              variant="primary"
              @click="saveEvent">
      {{ saveButtonTitle }}
    </b-button>
  </section>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import {validationMixin} from "vuelidate"
import {required, maxLength} from "vuelidate/lib/validators"

export default {
  name: "event-milestone",
  mixins: [validationMixin],
  props: {
    labels: {
      required: true,
      type: Object
    },
    labelsTriggers: {
      required: true,
      type: Object
    },
    editData: {
      type: Object
    }
  },
  data() {
    return {
      model: {
        referenceName: '',
        description: '',
        typeEnum: 'Milestone',
        trigger: {
          typeEnum: null
        }
      }
    }
  },
  computed: {
    saveButtonTitle() {
      return this.editData ? this.$t('common.done') : this.$t('common.add')
    }
  },
  validations() {
    return {
      model: {
        referenceName: {
          required,
          maxLength: maxLength(35)
        },
        trigger: {
          typeEnum: {
            required
          }
        }
      }
    }
  },
  methods: {
    resetModel() {
      this.model = {
        referenceName: '',
        description: '',
        typeEnum: 'Milestone',
        trigger: {
          typeEnum: null
        }
      }
    },
    saveEvent() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.$emit('saveEvent', this.model)
    },
    validateState(namesArray) {
      const temp = namesArray.reduce((result, currentValue) => {
        if (result) {
          return result[currentValue]
        } else {
          return this.$v.model[currentValue]
        }
      }, undefined)

      const {$dirty, $error} = temp
      return $dirty ? !$error : null
    }
  },
  watch: {
    editData: {
      handler(newValue) {
        if (newValue) {
          this.model = cloneDeep(newValue)
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (!this.editData) {
      this.resetModel()
    }
  }
}
</script>

<style scoped>

</style>
