<template>
  <section class="vertical-flex">
    <cb-view-header class="vertical-flex-min" :title="title" :return-link="returnLink" />
    <cb-view-section class="vertical-flex-fill" type="primary" :includePrimaryMessages="true">
      <savage-form :formstate="formstate" name="forms-data-entry-elements" ref="formInstance" :on-submit="save">
        <forms-data-entry-elements :elements.sync="localElements" />
      </savage-form>
      <div class="d-flex mt-4">
        <button type="button" @click="onBack()" class="cb-btn back primary flex-fill">{{$t('common.back')}}</button>
        <button type="submit" @click="submit()" class="cb-btn save primary flex-fill">{{$t('common.update')}}</button>
      </div>
    </cb-view-section>
  </section>
</template>

<script>
  import { SavageForm } from '@clickboarding/savage';
  import CbViewSection from 'general/cb-view-section.vue';
  import CbViewHeader from 'general/cb-view-header.vue';
  import FormsDataEntryElements from 'forms/forms-data-entry-elements.vue';
  import clonedeep from 'lodash.clonedeep';
  import * as PresentationToModelMapper from 'forms/data-entry-mappers/presentation-to-model';

  export default {
    name: 'forms-data-entry-elements-loader',
    components: {
      SavageForm,
      CbViewHeader,
      CbViewSection,
      FormsDataEntryElements
    },
    props: {
      elements: {
        type: Array,
        required: true
      },
      returnLink: {
        type: Object,
        required: true
      },
      onBack: {
        type: Function,
        required: true
      },
      onComplete: {
        type: Function,
        required: true
      },
      availableConditionalFields: {
        type: Array,
        required: false
      }
    },
    watch: {
      elements: {
        immediate: true,
        handler (newvalue) {
          this.localElementIndices = [];
          
          const clonedElements = clonedeep(newvalue).map(element => {
            this.localElementIndices.push(element.index);
            if (!('availableConditionalFields' in element.element)) element.element.availableConditionalFields = this.availableConditionalFields;
            return element.element;
          });

          this.localElements = clonedElements;
        }
      }
    },
    data () {
      return {
        localElements: null,
        localElementIndices: [],
        formstate: {}
      }
    },
    computed: {
      title () {
        // Less than ideal, but because the API returns an array directly and we didn't want 
        // to fabricate an object just so we could out one additional property, we have chosen
        // to attach the "editTitle" redundantly to each element, hence us grabbing it from 
        // localElements[0] in the cb-view-header component below.
        
        return this.localElements && this.localElements.length ? this.localElements[0].editTitle : null;
      }
    },
    methods: {
      submit () {
        this.$refs.formInstance.submit();
      },
      save () {
        const elementModels = this.localElements.map(element => PresentationToModelMapper.map(element));
        // conditional logic validation
        const index = elementModels.findIndex(el => el.isConditionallyShown && (el.conditionalLogic === null || el.conditionalLogic.values.length === 0));
        if (index !== -1) {
          const element = elementModels[index];
          const identifier = (element.label ? element.label : element.itemTitle ? element.itemTitle : element.title ? element.title : '').replace(/[^\w]/g, '').toLowerCase();
          let vals = document.getElementById(identifier);
          let msg = document.querySelector(`div#multiselect-container-${identifier}`).nextElementSibling;
          vals.classList.add('vf-submitted');
          msg.classList.add('savage-form-validated-field-invalid');
          vals.focus();
          msg.scrollIntoView();
          return new Error('conditional logic validation failed'); // will be caught by savage form submit
        } else {
          const wrappedElements = elementModels.map((element, index) => {
            // cleaning up conditional logic related fields
            delete element.availableConditionalFields;
            if (!element.isConditionallyShown) {
              element.conditionalLogic = null;
              element.errorMessage = null;
            }

            return {
              index: this.localElementIndices[index],
              element
            };
          });

          this.onComplete(wrappedElements);
        }
      },
    }
  }
</script>