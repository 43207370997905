import { AxiosLocal as axios } from 'common/services/axiosLocal.service';

let menuService = {
	activeTitle: null,
	setActiveTitle: (val) => {
		menuService.activeTitle = val;
	},
	clickMagicLink: async (url) => {
		const result = await axios.get(url);
		return result.data;
	}
}

export default menuService;
