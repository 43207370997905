import ViewController from 'view.controller';

export default class ProcessesController extends ViewController {
    constructor ($http, $scope, $route, $timeout, locationSvc, appSettings) {
        super($http, $scope, $route, $timeout, locationSvc);

    }
}

ProcessesController.$inject = ['$http', '$scope', '$route', '$timeout', 'locationSvc', 'appSettings'];
