<!--
WARNING: This component was ported verbatim from angular to vue. Because of this
it uses old patterns and standards and should not be used as a reference.

Any changes to this component should result in movement toward our new
patterns and practices.
-->
<template>
  <section v-if="formData" class="cb-candidate-form-doc">
    <div class="adminModal modalDoc addDialog">
      <h1 class="text-l">{{formData.title}}</h1>
      <div class="closeModal">
        <div class="blockMiddle" @click="handleClose()">×</div>
      </div>
      <div v-if="formData">
        <div id="dError" v-if="formData.error" v-show="!formData.contentUrl" class="dError text-c" :style="dialogErrorStyle">
          {{formData.error.message}}
        </div>
        <div v-if="formData.typeEnum === 'Document'" :style="dialogStyle">
          <cb-pdf-viewer :url="formData.contentUrl" :labels="pdfViewerLabels" v-if="formData.contentUrl" />
        </div>
        <div v-if="formData.contentUrl">
          <form-i9 v-if="formData.typeEnum === 'FramedFormI9'" :url="formData.contentUrl"></form-i9>
          <div v-if="formData.typeEnum === 'RoseAcknowledgement'" id="dFramed" :style="dialogStyle" class="framedStandard w100 " >
            <iframe :src="formData.contentUrl" frameborder="0" class="w100 borderless" style="height:100%;"></iframe>
          </div>
        </div>
      </div>
      <div v-if="formData.showDone" class="buttonRow text-c margin-lrg-T">
        <input type="button" :value="formData.doneLabel" @click="handleClose()">
      </div>
    </div>
  </section>
</template>

<script>
import CbPdfViewerDataProvider from "common/components/pdf/cb-pdf-viewer-data-provider";
import FormI9 from "candidates/forms/form-i9.vue";

export default {
  name: "form-doc",
  components: {
    CbPdfViewer: () => import('@clickboarding/pdf-viewer'),
    FormI9
  },
  mixins: [],
  props: {
    url: {
      type: String
    },
    dialogHeight: {
      type: Number
    },
    typeEnum: {
      type: String
    },
    closeClick: {
      type: Function
    }
  },
  data() {
    return {
      pdfViewerLabels: null,
      contentUrl: null,
      formData: null,
      dialogErrorStyle: null,
      dialogStyle: null
    };
  },
  methods: {
    handleClose: async function (keepOpen) {
      if (this.formData.eventUrl) {
        var response = await this.$http.get(this.formData.eventUrl);
        var eventResponseData = response.data;
        await this.closeClick(eventResponseData);
      }
      else
        await this.closeClick();
    },
    handleRecruiterClose: async function (e) {
      switch (e && e.data) {
        case 'recruiterHandleClose':
          await this.handleClose();
        break;
      }
    }
  },
  watch: {
    url: {
      immediate: true,
      handler: async function () {
        if (typeof this.url != 'undefined') {
          var response = await this.$http.get(this.url);
          this.formData = response.data;
          var saneDialogHeight = Math.max(this.dialogHeight + 20, 650) + "px";//min 'parent' dialog height or sane value
          this.dialogErrorStyle = {
            height: saneDialogHeight,
          };
          this.dialogStyle = {
            height: saneDialogHeight,
            minHeight: saneDialogHeight,
          };
        }
      }
    }
  },
  async created() {
    window.addEventListener("message", this.handleRecruiterClose);
    this.pdfViewerLabels = await CbPdfViewerDataProvider.getData();
  },
  destroyed() {
    window.removeEventListener("message", this.handleRecruiterClose);
  }
}
</script>

<style scoped>
.cb-candidate-form-doc .formI9 {
  height:750px !important;
  width:100%;
  margin:0 auto;
  -webkit-overflow-scrolling:touch;
  overflow-y:auto;
}
.cb-candidate-form-doc .framedStandard {
  min-height:650px;
  margin:0 auto;
  -webkit-overflow-scrolling:touch;
  /*overflow-y:auto;*/
  overflow: hidden;
}
.cb-candidate-form-doc .dError {
  max-width: 50%;
  margin:2.5em auto;
}
</style>