<template>
  <section class="savage-form-vimeo-video">
    <section v-if="embedHtml" v-html="embedHtml" />
  </section>
</template>

<script>
  import VimeoService from 'common/services/vimeo.service'
  export default {
    name: 'savage-form-vimeo-video',
    props: {
      url: {
        type: String,
        required: true
      }
    },
    created () {
      VimeoService.getEmbedHtml(this.url)
        .then((response) => {
          this.embedHtml = response;
        });
    },
    data () {
      return {
        embedHtml: null
      };
    }
  }
</script>
<style scoped lang="scss">
  .savage-form-vimeo-video {
    overflow:hidden;
    padding-bottom: 56.25%; //16:9 aspect ratio (9 / 16 = .5625)
    position:relative;

    // /deep/ necessary because embedded iframe is outside of our scope
    /deep/ iframe {
      width: 100%;
      height:100%;
      position:absolute;
    }
  }

</style>
