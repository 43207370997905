import KeyboardModule from 'components/keyboard.module';
import AppRoutingModule from 'common/app-routing.module';
import candidate from 'candidates/candidate';
import CandidatesListComponent from 'candidates/candidates-list.vue';
import CandidateAddComponent from 'candidates/candidate-add.vue';
import candidateDetail from 'candidates/candidateDetail';
import FiltersModule from 'common/filters.module';
import LinkService from 'services/link.service';
import CandidatesCtrl from 'candidates/candidates.controller';
import CandidateDetailComponent from "candidates/candidate-detail.vue";
import {BButton} from 'bootstrap-vue';

export default angular.module('candidates-module', [AppRoutingModule, FiltersModule, KeyboardModule])
    .directive('candidate', candidate)
    .directive('candidateDetail', candidateDetail)
    .controller('CandidatesCtrl', CandidatesCtrl)
    .factory('LinkService', LinkService)
    .value("candidate-detail", CandidateDetailComponent)
    .value("candidate-add", CandidateAddComponent)
    .value("candidates-list", CandidatesListComponent)
    .value("b-button", BButton)
    .run(['$rootScope', 'appSettings', function ($rootScope, appSettings) {
        // Clear filters when goes away from the Candidates
        $rootScope.$on('$routeChangeStart', function ($event, next, current) {
            if (!next.$$route.originalPath.startsWith(appSettings.viewCandidates)) {
                sessionStorage[`cb${appSettings.appType}candidateFilter`] = '';
            }
        });
    }])
    .name;
