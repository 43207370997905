<template>
    <section v-if="loadedData">
        <cb-admin-view-title class="brands-header cb-admin-view-title" :title="title">
          <b-button v-if="loadedData.add.url"
                    variant="green"
                    class="back create-brand-button"
                    @click="navigateToAdd">
            {{ loadedData.add.label }}
          </b-button>
        </cb-admin-view-title>
        <cb-view-section type="secondary" class="pt-0" :includePrimaryMessages="true">
            <b-table v-if="hasMultipleBrands"
                     class="brand-list-table new-table"
                     sort-by="name"
                     sort-icon-left
                     hover
                     small
                     outlined
                     :items="brands"
                     :fields="[
                { key: 'logo', label: columnHeaders.logo, thStyle: { minWidth: '20%' } },
                { key: 'name', label: columnHeaders.name, class: 'hide-sm-down', sortable: true, sortDirection: 'desc' },
                { key: 'isCompanyDefault', label: '', class: 'primary-indicator', thStyle: { minWidth: '15%' }, thAttr: { 'aria-label': $t('brands.primaryColumnHeaderScreenReader') } },
                { key: 'actions', label: '', class: 'action-menu-column', thAttr: { 'aria-label': $t('brands.actionsColumnHeaderScreenReader') } }
            ]">
                <template v-slot:cell(logo)="{ item }">
                    <img height="40" :alt="item.logoAlt" :src="item.logoUrl" />
                </template>
                <template v-slot:cell(isCompanyDefault)="{ item }">
                    <div v-if="item.isCompanyDefault">
                        <svg>
                            <use :xlink:href="MarkPrimaryIconPath" />
                        </svg>
                        <span>{{$t("brands.primaryLabel")}}</span>
                    </div>
                </template>
                <template v-slot:cell(actions)="{ item }">
                    <action-menu :toggle-orientation="'landscape'" :menu-position="'bottom-left'" :items="item.actions" />
                </template>
            </b-table>

            <single-brand-component v-else :brand="brands[0]" :edit-label="loadedData.editSingleBrandLabel" />
        </cb-view-section>
    </section>
</template>
<script>
    import AppSettings from 'appSettings';
    import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
    import CbViewSection from 'general/cb-view-section.vue';
    import SingleBrandComponent from 'brands/single-brand-component.vue';
    import ActionMenu from 'common/components/action-menu.vue';

    import primaryIcon from '@clickboarding/assets/icons/nova/line/votes-and-rewards/vote-star.svg';

    export default {
        name: 'brands-route',
        components: {
            CbAdminViewTitle,
            CbViewSection,
            ActionMenu,
            SingleBrandComponent
        },
        props: {
            dataUrl: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                title: null,
                loadedData: null,
                hasMultipleBrands: null,
                brands: null
            }
        },
        computed: {
            MarkPrimaryIconPath () {
                return '#' + primaryIcon.id;
            },
            MarkPrimaryIconId() {
                return primaryIcon.id;
            }
        },
        methods: {
            async loadListAsync(dataUrl) {
                var response = await this.$http.get(dataUrl);
                this.title = response.data.title;

                this.loadedData = response.data;

                this.columnHeaders = {
                    logo: response.data.logoHeader,
                    name: response.data.nameHeader
                };

                await this.updateBrandsList(this.loadedData.brands);
            },
            updateBrandsList(brands) {
                this.hasMultipleBrands = brands.length > 1;
                brands.forEach((item) => {
                    item.actions = this.buildActionMenu(item);
                });
                this.brands = brands;
            },
            navigateToEdit(url) {
                this.$_cb.router.changeView(AppSettings.viewBrandsEdit, url);
            },
            navigateToAdd() {
                this.$_cb.router.changeView(AppSettings.viewBrandsAdd, this.loadedData.add.url);
            },
            async deleteBrandAsync(url) {
                await this.$http.delete(url);
                await this.loadListAsync(this.dataUrl);
            },
            async markBrandDefaultAsync(url, brandId) {
                await this.$http.put(url, {id: brandId});
                await this.loadListAsync(this.dataUrl);
            },
            buildActionMenu(item) {
                item.actions.forEach((action) => {
                    switch(action.typeEnum) {
                        case 'Edit':
                            action.icon = 'Action-Edit-Icon';
                            action.action = () => this.navigateToEdit(action.url);
                        break;
                        case 'Delete':
                            action.icon = 'Action-Delete-Icon';
                            action.action = () => this.deleteBrandAsync(action.url);
                        break;
                        case 'MakeDefault':
                            action.icon = this.MarkPrimaryIconId;
                            action.action = () => this.markBrandDefaultAsync(action.url, item.id);
                        break;
                    }
                });
                return item.actions;
            }
        },
        watch: {
            dataUrl: {
                immediate: true,
                handler(newDataUrl, oldDataUrl) {
                    if (newDataUrl && (newDataUrl !== oldDataUrl)) {
                        this.loadListAsync(newDataUrl);
                    }
                }
            }
        }
    }
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.create-brand-button {
    margin-left: auto;
}

.brand-list-table {
    margin-top: 1rem;
}

.brand-list-table /deep/ .primary-indicator {
    @include font-size-content-root;
    white-space: nowrap;
    text-align: center;

    div {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 1rem;
            width: 1rem;
            margin-right: 5px;
        }
    }
}
</style>
