export default class TeamMemberSpecs {
    constructor() {
        this.specs = [
            {
                field: 'Email Address',
                dataType: 'Text',
                required: 'Yes',
                description:
                    "Team Member's email address. Work email is recommended.",
                validValues: 'Text up to 100 characters.',
            },
            {
                field: 'First Name',
                dataType: 'Text',
                required: 'Yes',
                description: "Team Member's first name.",
                validValues:
                    'Text up to 35 characters. Cannot contain html characters.',
            },
            {
                field: 'Last Name',
                dataType: 'Text',
                required: 'Yes',
                description: "Team Member's last name.",
                validValues:
                    'Text up to 100 characters. Cannot contain html characters.',
            },
            {
                field: 'Username',
                dataType: 'Text',
                required: 'No',
                description:
                    "Team Member's username. Username must be unique. If blank, email will be used.",
                validValues:
                    'Text up to 254 characters. Cannot contain html characters.',
            },
            {
                field: 'SSO Identifier',
                dataType: 'Text',
                required: 'No',
                description:
                    'Used to identify a user if using SAML SSO.',
                validValues:
                    'Text up to 100 characters. Cannot contain html characters.',
            },
            {
                field: 'Source System Identifier',
                dataType: 'Text',
                required: 'No',
                description:
                    'Unique client provided ID Number.',
                validValues:
                    'Text up to 100 characters. Cannot contain html characters.',
            },
            {
                field: 'Access Level',
                dataType: 'Text',
                required: 'No',
                description: "Team Member's assigned access level.",
                validValues: 'Text up to 30 characters',
            },
            {
                field: 'Brand',
                dataType: 'Text',
                required: 'No',
                description: "Team Member's assigned brand. If blank, Primary brand will be used.",
                validValues: 'Text up to 32 characters',
            },
            {
                field: 'Access to All Locations',
                dataType: 'Text',
                required: 'No',
                description:
                    'Determines whether Team Member has access to all locations.',
                validValues: 'Must be either "Yes", "No" or blank.',
            },
            {
                field: 'Locations',
                dataType: 'Text',
                required: 'No',
                description: "Team Member's assigned locations.",
                validValues:
                    'A semicolon (;) delimited list of locations.',
            },
            {
                field: 'Location Groups',
                dataType: 'Text',
                required: 'No',
                description: "Team Member's assigned location groups.",
                validValues:
                    'A semicolon (;) delimited list of location groups.',
            },
            {
                field: 'Send First Time Login Link',
                dataType: 'Text',
                required: 'No',
                description:
                    'Determines whether welcome email is sent upon first login.',
                validValues: 'Must be either "Yes", "No" or blank.',
            },
        ];
    }
}
