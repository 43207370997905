<template>
  <forms-content-build-element
    :dataUrl="dataUrl"
    :wrappedElement="wrappedElement"
    :returnLink="returnLink"
    :onBack="onBack"
    :onComplete="onComplete">
      <cb-view-section type="secondary" slot-scope="buildElementContext">
        <savage-form-html-editor
          v-if="buildElementContext"
          name="forms-content-html-editor"
          v-model="buildElementContext.element.value"
          :validation="buildElementContext.component.control.validation"
          :placeholder="buildElementContext.component.control.placeholder"
          :hiddenToolbarItems="buildElementContext.component.control.hiddenToolbarItems" />
      </cb-view-section>
  </forms-content-build-element>
</template>

<script>
import FormsContentBuildElement from 'forms/forms-content-build-element.vue';
import SavageFormHtmlEditor from 'common/savage/savage-form-html-editor.vue';
import CbViewSection from 'general/cb-view-section.vue';

export default {
  name: 'forms-content-build-html',
  components: {
    FormsContentBuildElement,
    SavageFormHtmlEditor,
    CbViewSection
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    wrappedElement: {
      type: Object,
      required: true
    },
    returnLink: {
      type: Object,
      required: true
    },
    onBack: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  }
}
</script>
