<template>
  <section class="h-100 connections-route">
    <section v-if="connections" class="vertical-flex">
      <cb-admin-view-title class="vertical-flex-min connections-header cb-admin-view-title" :title="displayTitle" />
      <cb-view-section class="vertical-flex-fill" type="secondary" :includePrimaryMessages="true">
        <p class="no-connections" v-if="connections.length === 0">
            {{displayNoConnectionsMessage}}
        </p>
        <cb-expandable-rows 
          v-else
          class="connection-rows"
          v-for="(connection, index) in connections"
          :key="index"
          :details="connection">
          <template slot="summary">
            <div class="connection-name">
              {{connection.name}}
            </div>
            <div v-if="connection.isConfigured" class="connection-status">
              {{$t('connections.connected')}}
            </div>
            <div v-else class="connection-status">
              {{$t('connections.available')}}
            </div>
          </template>
          <connection-description-details
            slot="details"
            :connection="connection"
            @logs-opened="onViewLog(connection, connectionType)"
            @connection-opened="onView(connection, connectionType)" />
        </cb-expandable-rows>
      </cb-view-section>
    </section>
  </section>
</template>
<script>
import AppSettings from "appSettings";
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import CbViewSection from "general/cb-view-section.vue";
import CbExpandableRows from "general/cb-expandable-rows.vue";
import ConnectionDescriptionDetails from "connections/connection-description-details.vue";
import ConnectionsService from "api/connectionsService.js";
import { mapGetters } from 'vuex';

export default {
  name: "connections-route",
  components: {
    CbAdminViewTitle,
    CbViewSection,
    CbExpandableRows,
    ConnectionDescriptionDetails,
  },
  props: {
    connectionType: {
      type: String,
      required: true
    }
  },
  async created() {
    await this.getConnections(this.connectionType);
  },
  methods: {
    getConnections: async function(type) {
      const response = await ConnectionsService.list(this.clientId, type);
      this.connections = response.connections || [];
    },
    onViewLog(connection, connectionType) {
      this.$_cb.router.changeView(AppSettings.viewConnectionLog, {
        connectionType: connectionType,
        connectionIdentifier: connection.identifier
      }, null);
    },
    onView(connection, connectionType) {
      this.$_cb.router.changeView(AppSettings.viewConnectionEdit, {
        connectionType: connectionType,
        connectionIdentifier: connection.identifier
      }, null);
    }
  },
  data() {
    return {
      connections: null
    };
  },
  computed: {
    ...mapGetters(['clientId']),
    hasConnections() {
      return Boolean(
        Array.isArray(this.connections) && this.connections.length
      );
    },
    hasDataWithoutConnections() {
      return Boolean(this.connectionType && !this.hasConnections);
    },
    displayTitle() {
      return this.$t(`connections.connectionTypeEnumMap.${this.connectionType}.title`);
    },
    displayNoConnectionsMessage() {
      return this.$t(`connections.connectionTypeEnumMap.${this.connectionType}.noData`);
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";

.connections-route .connections-header {
  /deep/ .header-content {
    margin-top: 0.625rem;
    padding: 1.25rem;
    border-bottom: 0rem;
  }
}
section.connections-route {
  div.cb-expandable-rows {
    margin-top: 0;
    font-size: 0.875rem;
    &:last-child {
      border-bottom: 1px solid $cb-dark-grey-6;
    }
  }

  div.connection-name {
    padding: 1.5rem;
  }

  div.connection-status {
    margin-left: auto;
  }

  .cb-view-section {
    border-bottom: 0rem;
  }

  p.no-connections {
    border-top: 1px solid $cb-dark-grey-6;
    padding: 3rem;
    text-align: center;
    font-size: 0.8rem;
  }
}
</style>
    