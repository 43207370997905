import Vue from 'vue';
import Base64Service from 'common/services/base64.service'; //used by locationSvc
import LocationService from 'common/location.service';

export default angular.module('app-routing-module', [])
  .factory('locationSvc', LocationService)
	.factory('Base64', Base64Service)
  .run(['$rootScope', '$location', '$route', 'locationSvc', '$routeParams', function ($rootScope, $location, $route, locationSvc, $routeParams) {

    let VueLocationService = {
      install: function (Vue, options) {
        Vue.prototype.$_cb = {
          route: {
            get path() {
              return $location.path();
            },
            get query() {
              return $location.search();
            },
            get params() {
              return $routeParams;
            },
          },
          router: {
            getDefaultComponent: function() {
              if($route.current.$_cb) {
                return $route.current.$_cb.component;
              }
            },
            getMenuProps: function() {
              return $route.current.$_cb.menuSettings({
                params: $routeParams
              });
            },
            reloadView: function() {
              $route.reload();
            },
            // supports the following:
            //    (route, dataUrl)
            //    (route, routeParameters, dataUrl)
            //    (route, routeParameters, queryParameters, dataUrl)
            changeView: function (route) {
              let routeParameters,
                  queryParameters,
                  dataUrl;
    
              // (route, dataUrl)
              if (arguments.length === 2) {
                routeParameters = null;
                queryParameters = null;
                dataUrl = arguments[1];
              // (route, routeParameters, dataUrl)
              } else if (arguments.length === 3) {
                queryParameters = null;
                routeParameters = arguments[1];
                dataUrl = arguments[2];
              // (route, routeParameters, queryParameters, dataUrl)
              } else if (arguments.length === 4) {
                routeParameters = arguments[1];
                queryParameters = arguments[2]
                dataUrl = arguments[3];
              }
    
              const routeParts = routeParameters
                  ? locationSvc.buildRouteParts(route, routeParameters, dataUrl)
                  : locationSvc.buildRouteParts(route, dataUrl);
    
              if (queryParameters) {
                routeParts.params = queryParameters
              }

              locationSvc.goToView(routeParts);    
              $rootScope.$apply();
            },
            getDataUrl: function() {
              return locationSvc.fetchQuery();
            }
          }
        }
      }
    }

    Vue.use(VueLocationService);
	}])
	.name;
