function datafieldsAddController ($routeParams, $scope) {
  // schemaFieldId is an optional route parameter.
  // If provided, load the page at the specified datafield
  $scope.parentSchemaFieldId = $routeParams.schemaFieldId;
  $scope.type = $routeParams.type;
}

export default datafieldsAddController;

datafieldsAddController.$inject = ['$routeParams', '$scope'];
