<template>
  <section>
    <b-form-group v-if="labels.processActions.length > 1"
                  class="w-50">
      <template v-slot:label>
        {{ labels.processActionLabel }}
        <span aria-hidden class="text-danger">*</span>
      </template>
      <b-form-select
          class="process-action"
          required
          text-field="displayName"
          value-field="id"
          v-model="model.processAction.id"
          :state="validateState(['processAction', 'id'])"
          @change="handleActionChange"
          :options="[{ id: null, displayName: labels.defaultProcessAction} ,...labels.processActions]"
      >
      </b-form-select>
    </b-form-group>
    <div class="mb-3" v-if="labels.processActions.length === 1">
      <p class="col-form-label font-weight-bold">
        {{ labels.processActionLabel }}
      </p>
      {{ labels.processActions[0].displayName }}
    </div>
    <div>
      <div class="d-flex">
        <b-form-group class="w-50 mr-3">
          <template v-slot:label>
            {{ labelsTriggers.stepTriggerLabel }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-select
              class="process-trigger"
              required
              text-field="name"
              value-field="typeEnum"
              v-model="model.trigger.typeEnum"
              :state="validateState(['trigger', 'typeEnum'])"
              :options="[{ typeEnum: null, name: labelsTriggers.defaultTrigger} ,...labelsTriggers.triggers]"
              @change="selectTrigger"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group v-if="model.trigger.typeEnum && model.trigger.typeEnum.indexOf('Overdue') > -1"
                      class="overdue-day-input">
          <template v-slot:label>
            {{ model.trigger.overdueAfterLabel }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-input
              v-model="model.trigger.daysOverdue"
              :state="validateState(['trigger', 'daysOverdue'])"/>
          <span class="overdue-day-text">{{ model.trigger.daysLabel }}</span>
        </b-form-group>
      </div>
    </div>
    <b-button class="d-block ml-auto mr-auto"
              variant="primary"
              @click="saveEvent">
      {{ saveButtonTitle }}
    </b-button>
  </section>
</template>

<script>
import {validationMixin} from "vuelidate";
import {maxLength, numeric, required} from "vuelidate/lib/validators";
import cloneDeep from "lodash.clonedeep";

export default {
  name: "event-process-action",
  mixins: [validationMixin],
  props: {
    labels: {
      required: true,
      type: Object
    },
    labelsTriggers: {
      required: true,
      type: Object
    },
    editData: {
      type: Object
    }
  },
  data() {
    return {
      model: {
        processAction: {
          id: null
        },
        eventGuid: "",
        typeEnum: "ProcessAction",
        description: "",
        trigger: {
          typeEnum: null
        }
      }
    }
  },
  computed: {
    saveButtonTitle() {
      return this.editData ? this.$t('common.done') : this.$t('common.add')
    }
  },
  validations() {
    const validation = {
      model: {
        trigger: {
          typeEnum: {
            required
          }
        }
      }
    }

    if (this.labels.processActions.length > 1) {
      validation.model.processAction = {
        id: {
          required
        }
      }
    }

    if (this.model.trigger.typeEnum && this.model.trigger.typeEnum.includes('Overdue')) {
      validation.model.trigger.daysOverdue = {
        required,
        maxLength: maxLength(4)
      }
    }

    return validation
  },
  methods: {
    validateState(namesArray) {
      const temp = namesArray.reduce((result, currentValue) => {
        if (result) {
          return result[currentValue]
        } else {
          return this.$v.model[currentValue]
        }
      }, undefined)

      const {$dirty, $error} = temp
      return $dirty ? !$error : null
    },
    selectTrigger(trigger) {
      this.model.trigger = cloneDeep(this.labelsTriggers.triggers.find(item => item.typeEnum === trigger))
    },
    handleActionChange(item) {
      this.model.processAction = this.labels.processActions.find(processAction => processAction.id === item)
      this.model.description = this.model.processAction.description
    },
    saveEvent() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.$emit('saveEvent', this.model)
    },
    resetModel() {
      this.model = {
        processAction: {
          id: null
        },
        eventGuid: "",
        typeEnum: "ProcessAction",
        description: "",
        trigger: {
          typeEnum: null
        }
      }

      if (this.labels.processActions.length === 1) {
        this.model.processAction = this.labels.processActions[0]
        this.model.description = this.model.processAction.description
      }
    },
  },
  watch: {
    editData: {
      handler(newValue) {
        if (newValue) {
          this.model = cloneDeep(newValue)
          if (this.labels.processActions.length === 1) {
            this.model.processAction = this.labels.processActions[0]
            this.model.description = this.model.processAction.description
          }
          this.model.trigger = cloneDeep(this.labelsTriggers.triggers.find(item => item.typeEnum === this.model.trigger.typeEnum))
          if ('daysOverdue' in newValue.trigger) {
            this.$set(this.model.trigger, 'daysOverdue', newValue.trigger.daysOverdue)
          }
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    if (!this.editData) {
      this.resetModel()
    }
  }
}
</script>

<style lang="scss" scoped>
.overdue-day {
  &-input {
    position: relative;
    max-width: 100px;
  }

  &-text {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
  }
}
</style>
