<template>
  <section class="h-100 forms-content-build-element">
    <savage-form class="vertical-flex" v-if="apiData"  name="forms-content-build-element-form" :formstate="formstate" :on-submit="save">
      <cb-view-header  class="vertical-flex-min" :title="apiData.title" :return-link="returnLink" />
      <cb-view-section class="vertical-flex-fill" type="primary" :includePrimaryMessages="true">
        <slot :component="apiData.component" :element="localWrappedElement.element" />
        <div class="d-flex mt-4">
          <button type="button" class="cb-btn primary flex-fill" @click="onBack()">
            {{apiData.back.label}}
          </button>
          <button type="submit" class="cb-btn primary flex-fill">
            {{apiData.submit.label}}
          </button>
        </div>
      </cb-view-section>
    </savage-form>
  </section>
</template>

<script>
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import { SavageForm } from '@clickboarding/savage';
import clonedeep from 'lodash.clonedeep';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';

export default {
  name: 'forms-content-build-element',
  components: {
    CbViewSection,
    CbViewHeader,
    SavageForm
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    wrappedElement: {
      type: Object,
      required: true
    },
    returnLink: {
      type: Object,
      required: true
    },
    onBack: {
      type: Function,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  created () {
    window.scrollTo(0, 0);
    this.$http.get(this.dataUrl).then((response) => {
      this.apiData = response.body;
    });
  },
  destroyed () {
    PrimaryMessageBus.$emit('clear-all-messages');
  },
  watch: {
    wrappedElement: {
      immediate: true,
      deep: true,
      handler (newvalue) {
        this.localWrappedElement = clonedeep(newvalue);
      }
    }
  },
  methods: {
    save () {
      this.onComplete(this.localWrappedElement);
    }
  },
  data () {
    return {
      apiData: null,
      formstate: {},
      localWrappedElement: null
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '@clickboarding/style/mixins';

  .forms-content-build-element-footer {
    padding: $standard-cb-view-padding;
  }
</style>