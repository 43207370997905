<template>
  <div v-if="currentItem" class="vertical-flex">
    <cb-admin-view-title class="vertical-flex-min cb-admin-view-title" :title="headerTitle" :subtitle="headerSubtitle">
      <b-button v-if="userCanCreate && inRoot"
                variant="green"
                class="datafields-create-button"
                :disabled="!allowsNewDatafields"
                @click="create">
        {{ $t("datafields.createButton") }}
      </b-button>
    </cb-admin-view-title>
    <div v-if="!inRoot" class="h-line"></div>
    <cb-view-section class="vertical-flex-min pt-0" type="secondary" v-if="!inRoot">
      <datafield-crumbs :schemaFieldId="currentItem.schemaFieldId" />
    </cb-view-section>
    <!-- Root datafields page will remain consistent with other landing pages for the time being. -->
    <template v-if="inRoot">
      <cb-view-section class="vertical-flex-fill pt-0" type="secondary">
        <div class="toolbar">
          <div class="filter-group">
              <div class="search-item mr-3">
                <b-form-input
                    class="search-input small-input"
                    v-model="searchCriteria"
                    @input="resetCurrentPage"
                    :placeholder='$t("common.filterSearchPlaceholder", ["..."])'
                ></b-form-input>
              </div>       
              <multi-select v-if="typeFilterList.length > 1"
                            :options="typeFilterList"
                            :value="typeFilter"
                            :search-placeholder="$t('common.filterSearchPlaceholder', [$t('datafields.typeColumnHeader').toLowerCase()])"
                            :close-on-select="false"
                            :title="$t('datafields.typeColumnHeader')"
                            @select="selectTypeFilter($event)">
                <template v-slot:option="{ option, isSelected }">
                  <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
                  </b-form-checkbox>
                  <span>{{ option }}</span>
                </template>
              </multi-select>
              <b-button class="small-button ml-3 clear-filters-datafields"
                variant="outline-green"
                @click="clearFilters">
                {{ $t('common.clearFilters') }}
              </b-button>
          </div>
        </div>
        <b-table class="datafield-list-table new-table"
                 v-bind="tableSettings"
                 :per-page="itemsPerOnePage"
                 :current-page="currentPage"
                 :items="sortedCurrentItemList"
                 :fields="datafieldsTableFields"
                 sort-by="title">
          <template v-slot:cell(title)="{ item }">
            {{item.metadata.displayName}}
          </template>
          <template v-slot:cell(type)="{ item }">
            {{item.metadata.displayType}}
          </template>
          <template v-slot:cell(view)="{ item }">
            <b-link href="#" @click="viewDatafield(item)">{{$t('common.view')}}</b-link>
          </template>
        </b-table>
        <div v-if="showPagination" class="pagination-container">
          <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm" class="per-page-options">
              <b-form-select
                  id="per-page-select"
                  v-model="itemsPerOnePage"
                  :options="paginationSettings.pageOptions"
                  size="sm">
              </b-form-select>
          </b-form-group>
          <b-pagination
              v-model="currentPage"
              :total-rows="datafieldsCount"
              :per-page="itemsPerOnePage"
              :current-page="currentPage"
              v-bind="paginationSettings">
          </b-pagination>
        </div>
      </cb-view-section>
    </template>
    <!-- When viewing datafields page render the updated design for viewing a datafield and its nested datafields.. -->
    <template v-else>
      <datafield-view class="vertical-flex-min" :item="currentItem" />
      <b-card-group class="vertical-flex-fill primary-section">
        <b-card v-if="isOneOfObject || allowsDynamicContent" no-body>
          <b-alert v-if="isOneOfObject" show class="with-icon w-100" variant="info">
            <b-icon icon="alert-circle" />
            {{$t('datafields.isOneOfMessage')}}
          </b-alert>
          <b-alert v-if="allowsDynamicContent" show class="with-icon w-100" variant="info">
            <b-icon icon="alert-circle" />
            {{$t('datafields.allowsDynamicContentMessage')}}
          </b-alert>
        </b-card>
        <b-card no-body v-if="showDatafields">
          <b-card-primary-section-header slot="header" :title="$t('datafields.nestedDataFieldsHeading')">
            <b-button
              v-if="userCanCreate"
              slot="additional-content"
              slot-scope="{ variant }"
              href="javascript:void(0)"
              @click="create"
              :variant="variant"
              :class="['b-link']"
              :disabled="!allowsNewDatafields">
                {{$t("datafields.createButton")}}
            </b-button>
          </b-card-primary-section-header>
          <div class="toolbar mb-3 mt-2">
            <div class="filter-group">
                <div class="search-item mr-3">
                  <b-form-input
                      class="search-input small-input"
                      v-model="searchCriteria"
                      @input="resetCurrentPage"
                      :placeholder='$t("common.filterSearchPlaceholder", ["..."])'
                  ></b-form-input>
                </div>       
                <multi-select v-if="typeFilterList.length > 1"
                              :options="typeFilterList"
                              :value="typeFilter"
                              :search-placeholder="$t('common.filterSearchPlaceholder', [$t('datafields.typeColumnHeader').toLowerCase()])"
                              :close-on-select="false"
                              :title="$t('datafields.typeColumnHeader')"
                              @select="selectTypeFilter($event)">
                  <template v-slot:option="{ option, isSelected }">
                    <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
                    </b-form-checkbox>
                    <span>{{ option }}</span>
                  </template>
                </multi-select>
                <b-button class="small-button ml-3 clear-filters-datafields"
                  variant="outline-green"
                  @click="clearFilters">
                  {{ $t('common.clearFilters') }}
                </b-button>
            </div>
          </div>
          <b-table v-bind="tableSettings"
                   class="new-table"
                   :head-variant="null"
                   sort-by="title"
                   :per-page="itemsPerOnePage"
                   :current-page="currentPage"
                   :items="sortedCurrentItemList"
                   :fields="datafieldsTableFields">
            <template v-slot:cell(title)="{ item }">
              {{item.metadata.displayName}}
            </template>
            <template v-slot:cell(type)="{ item }">
              {{item.metadata.displayType}}
            </template>
            <template v-slot:cell(view)="{ item }">
              <b-link href="#" @click="viewDatafield(item)">{{$t('common.view')}}</b-link>
            </template>
          </b-table>
          <div v-if="showPagination" class="pagination-container">
            <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm" class="per-page-options">
                <b-form-select
                    id="per-sub-page-select"
                    v-model="itemsPerOnePage"
                    :options="paginationSettings.pageOptions"
                    size="sm">
                </b-form-select>
            </b-form-group>
            <b-pagination
                v-model="currentPage"
                :total-rows="datafieldsCount"
                :per-page="itemsPerOnePage"
                :current-page="currentPage"
                v-bind="paginationSettings">
            </b-pagination>
          </div>
        </b-card>
      </b-card-group>
    </template>
  </div>
</template>
<script>
import AppSettings from 'appSettings';
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import CbViewSection from 'general/cb-view-section.vue';
import CbViewSubSection from 'general/cb-view-sub-section.vue';
import { CbMessageBar, BCardPrimarySectionHeader } from '@clickboarding/vue-components';
import DatafieldView from 'datafields/datafield-view.vue';
import DatafieldCrumbs from 'datafields/datafield-crumbs.vue';
import MultiSelect from 'common/components/multi-select.vue'
import { mapActions, mapGetters } from 'vuex';
import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../constants/defaultTableSettings";

export default {
  name: 'datafields',
  components: {
    CbAdminViewTitle,
    CbViewSection,
    CbViewSubSection,
    DatafieldView,
    DatafieldCrumbs,
    CbMessageBar,
    BCardPrimarySectionHeader,
    MultiSelect
  },
  props: {
    schemaFieldId: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      typeFilterList: [],
      typeFilter: [],
      tableSettings: TABLE_SETTINGS,
      paginationSettings: PAGINATION_SETTINGS,
      currentPage: 1,
      itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0],
      searchCriteria: ''
    }
  },
  methods: {
    ...mapActions('datafields', [
      'reset',
      'load',
      'loadIfNeeded'
    ]),
    isReadOnlyDatafield (item) {
      return !!(item && item.flags && item.flags.some((flag) => flag === 'ReadOnly'));
    },
    create() {
      this.$_cb.router.changeView(AppSettings.viewDatafieldsAddTypeSelect, { schemaFieldId: this.currentItem.schemaFieldId }, null);
    },
    viewDatafield (item) {
      this.$_cb.router.changeView(AppSettings.viewDatafields, { schemaFieldId: item.schemaFieldId }, null);
    },
    resetCurrentPage () {
      this.currentPage = 1
    },
    clearFilters () {
      this.typeFilter = []
      this.searchCriteria = ''
      this.currentPage = 1
    },
    selectTypeFilter (item) {
      this.currentPage = 1

      const itemIndex = this.typeFilter.indexOf(item)

      if (itemIndex > -1) {
        this.typeFilter.splice(itemIndex, 1)
      } else {
        this.typeFilter.push(item)
      }
    },
    buildTypeFilterList (datafields) {
      let result = new Set()
      datafields.forEach(field => {
        result.add(field.metadata.displayType)
      })
      return [...result].sort()
    }
  },
  computed: {
    ...mapGetters('datafields', [
      'findBySchemaFieldId',
      'enrichedDefinition',
      'userCanCreate'
    ]),
    currentItem () {
      const datafield = this.findBySchemaFieldId(this.schemaFieldId);
      return datafield || this.enrichedDefinition;
    },
    currentItemList () {
      return (this.currentItem && this.currentItem.datafields) || [];
    },
    showDatafields () {
      return ['Object','Array'].some((val) => { // only show Data Fields in non-scalar fields
        return this.currentItem.type === val;
      });
    },
    allowsNewDatafields () {
      return !!(this.currentItem.allowsNewDatafields);
    },
    allowsDynamicContent () {
      return !!(this.currentItem.allowsDynamicDatafields);
    },
    isOneOfObject() {
      return !!(this.currentItem.isOneOfObject);
    },
    headerTitle () {
      return this.inRoot ? this.$t('datafields.title'): this.currentItem.metadata.displayName;
    },
    headerSubtitle () {
      return this.inRoot ? null : this.currentItem.metadata.displayType;
    },
    inRoot () {
      return !(this.currentItem && this.currentItem.metadata && this.currentItem.metadata.parent);
    },
    sortedCurrentItemList () {
      // Array.from creates a shallow copy so that we
      // don't change order of the original, but retain
      // the original pointers to the item objects
      // within the array
      const sortedCurrentItemList = Array.from(this.currentItemList || []);

      sortedCurrentItemList.sort((a, b) => {
        return a.metadata.displayName.localeCompare(b.metadata.displayName);
      });
      
      const searchCriteriaLowerCase = this.searchCriteria.toLowerCase();

      // ReadOnly fields should not be displayed
      return sortedCurrentItemList.filter(item => {
        return !this.isReadOnlyDatafield(item)
          && (!searchCriteriaLowerCase || item.metadata.displayName.toLowerCase().includes(searchCriteriaLowerCase))
          && (this.typeFilter.length === 0 || this.typeFilter.indexOf(item.metadata.displayType) > -1);
      });
    },
    datafieldsTableFields () {
      return [
        { key: 'title', label: this.$t('datafields.titleColumnHeader'), thAttr: { 'aria-sort': 'ascending' },
          sortable: true, sortDirection: 'asc' },
        { key: 'type', label: this.$t('datafields.typeColumnHeader'), sortable: true },
        { key: 'view', label: '', class: 'link-column', thAttr: { 'aria-label': this.$t('datafields.actionsColumnHeaderScreenReader') } }
      ];
    },
    datafieldsCount() {
      return this.sortedCurrentItemList && this.sortedCurrentItemList.length;
    },
    showPagination() {
      return this.datafieldsCount > PAGINATION_SETTINGS.pageOptions[0];
    }
  },
  // While Vue doesn't await lifecycle events, the methods in here
  // will each be awaiting (run synchronously)
  async created () {
    if (!this.schemaFieldId) {
      // When this page is loaded at the root (no schemaFieldId)
      // reset and load the datafields
      await this.reset();
      await this.load();
    } else {
      // Otherwise only load if not present
      await this.loadIfNeeded();
    }
    this.typeFilterList = this.buildTypeFilterList(this.sortedCurrentItemList)
  }
}
</script>
<style lang="scss" scoped>
   .toolbar {
     display: flex;
     align-items: baseline;
   }

  .datafields-create-button {
    margin-left: auto;
  }

  .datafield-list-table {
    margin-top: 1rem;
  }
</style>
