<template>
  <cb-expandable-rows class="action-center-item-rows" 
    :details="actionItem.instance" >
    <template slot="summary">
      <div class="action-center-item-description">{{ actionItem.name }}</div>
      <action-center-item-status class="action-center-item-status" :date="actionItem.status.date" :status="actionItem.status.value" />
      <div class="action-center-item-assignment">{{ actionItem.assignment.label }}</div>
    </template>
    <template slot="details">
      <slot name="details"></slot>
    </template>
  </cb-expandable-rows>
</template>
<script>
import CbExpandableRows from "general/cb-expandable-rows.vue";
import ActionCenterItemStatus from "action-center/item-group/action-center-item-status.vue";

export default {
  name: "action-center-item",
  components: {
    CbExpandableRows,
    ActionCenterItemStatus
  },
  props: {
    actionItem: {
      type: Object,
      required: true
    }
  }
};
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.action-center-item-rows {

  .action-center-item-description, 
  .action-center-item-status, 
  .action-center-item-assignment {
    padding: 0.75rem 1rem 0.75rem 0.75rem;
    @include font-size-content-root;

    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-center-item-description,
  .action-center-item-status,
  .action-center-item-assignment {
    flex: 1;
  }

  .action-center-item-status {
    display: flex;
    align-items: center;
  }
}
</style>