<template>
  <b-modal
      v-model="modalShow"
      :title="title"
      :ok-title="okTitle"
      :cancel-title="data.closeLabel"
      centered
      static
      footer-class="add-location-footer"
      size="lg"
      no-close-on-backdrop
      @ok="handleOk"
      @cancel="$emit('cancel')"
      @close="$emit('close')"
      @hide="handleHide"
  >
    <template v-slot:modal-header="{ close }">
      <h1 class="text-l m-0">
        {{ title }}
        <a v-if="editItem && editItem.deleteUrl"
           href=""
           @click="handleDelete($event)"
           :title="data.deleteLabel"
           style="margin-left:.75em;">
          <i class="fa fa-trash-o"></i>
        </a>
      </h1>
      <b-button class="close ml-auto"
                variant="close"
                @click="close()"
      ></b-button>
    </template>
    <cb-primary-message></cb-primary-message>
    <div class="d-flex flex-column">
      <h2>{{ data.company.title }}</h2>
      <div class="d-flex">
        <b-form-group class="mr-2 flex-fill">
          <template v-slot:label>
            {{ data.company.identifierLabel }} <span class="text-secondary">*</span>
          </template>
          <b-form-input
              class="location-identifier"
              required
              :state="validateState(['identifier'])"
              v-model="$v.model.identifier.$model"/>
        </b-form-group>
        <b-form-group class="ml-2 flex-fill">
            <template v-slot:label>
                {{ data.company.nameLabel }}
                <span class="text-secondary">*</span>
            </template>
            <b-form-input class="location-name"
                          required
                          :state="validateState(['name'])"
                          aria-describedby="input-name-live-feedback"
                          v-model="$v.model.name.$model" />
            <b-form-invalid-feedback class="location-name" id="input-name-live-feedback">
                {{ errorMessage }}
            </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div class="d-flex">
        <b-form-group class="mr-2 flex-fill">
          <template v-slot:label>
            {{ data.company.address.street1Label }} <span class="text-secondary">*</span>
          </template>
          <b-form-input
              class="location-streetLine1"
              required
              :state="validateState(['address','streetLine1'])"
              v-model="$v.model.address.streetLine1.$model"/>
        </b-form-group>
        <b-form-group class="ml-2 flex-fill"
                      :label="data.company.address.street2Label">
          <b-form-input
              :state="validateState(['address','streetLine2'])"
              v-model="$v.model.address.streetLine2.$model"/>
        </b-form-group>
      </div>
      <div class="d-flex">
        <div class="d-flex mr-2 flex-fill w-100">
          <b-form-group class="flex-fill align-self-end">
            <template v-slot:label>
              {{ data.company.address.cityLabel }} <span class="text-secondary">*</span>
            </template>
            <b-form-input
                class="location-city"
                required
                :state="validateState(['address','city'])"
                v-model="$v.model.address.city.$model"/>
          </b-form-group>
        </div>
        <div class="d-flex ml-2 flex-fill w-100 justify-content-between">
          <b-form-group class="location-state-wrapper"
                        :label="data.company.address.stateLabel">
            <b-form-input
                :state="validateState(['address','stateProvinceRegion'])"
                v-model="$v.model.address.stateProvinceRegion.$model"/>
          </b-form-group>
          <b-form-group class="location-postal-code-wrapper">
            <template v-slot:label>
              {{ data.company.address.postalCodeLabel }} <span class="text-secondary">*</span>
            </template>
            <b-form-input
                class="location-post-code"
                required
                :state="validateState(['address','postalCode'])"
                v-model="$v.model.address.postalCode.$model"/>
          </b-form-group>
          <b-form-group class="location-country-wrapper align-self-end">
            <template v-slot:label>
              {{ data.company.address.countryLabel }} <span class="text-secondary">*</span>
            </template>
            <b-form-input
                class="location-country"
                required
                :state="validateState(['address','countryCode'])"
                v-model="$v.model.address.countryCode.$model"/>
          </b-form-group>
        </div>
      </div>

      <div class="w-50">
        <b-form-group class="mr-2">
          <template v-slot:label>
            {{ data.company.contactNameLabel }} <span class="text-secondary">*</span>
          </template>
          <b-form-select
              class="location-contact-name"
              v-model="$v.model.primaryContactId.$model"
              required
              text-field="name"
              value-field="id"
              :state="validateState(['primaryContactId'])"
              :options="activeContacts"
          >
          </b-form-select>
        </b-form-group>
      </div>
      <h2 style="border-bottom:solid 1px #ccc; margin-top:1em;">{{ data.settings.title }}</h2>
      <div class="w-50">
        <b-form-group class="mr-2"
                      :label="data.settings.fi9CompanyLabel">
          <b-form-input
              class="task-name"
              :state="validateState(['fi9Company'])"
              v-model="$v.model.fi9Company.$model"/>
        </b-form-group>
      </div>
      <div class="w-50">
        <b-form-group class="mr-2">
          <template v-slot:label>
            {{ data.settings.brandsLabel }} <span class="text-secondary">*</span>
          </template>
          <b-form-select
              class="location-brand"
              v-model="$v.model.brandId.$model"
              :state="validateState(['brandId'])"
              text-field="name"
              value-field="id"
              :options="data.settings.brands"
          >
          </b-form-select>
        </b-form-group>
      </div>
      <div v-if="data.settings.associateLocationGroup.locationGroups"
           class="d-flex align-items-baseline">
        <multi-select
            class="assign-location-group"
            :options="data.settings.associateLocationGroup.locationGroups"
            :value="selectedLocationGroup"
            track-by="id"
            search-by="name"
            placement="bottomright"
            :search-placeholder="$t('common.filterSearchPlaceholder', [$t('locations.searchLocationGroupPlaceholder')])"
            :close-on-select="true"
            :title="data.settings.associateLocationGroup.label"
            @select="selectLocationGroup($event)"
        >
          <template v-slot:option="{ option }">
            {{ option.name }}
          </template>
        </multi-select>
        <b-button class="add-location-group ml-3 small-button"
                  variant="primary"
                  @click="assignLocationGroup(selectedLocationGroup)"
        >{{ $t('common.add') }}
        </b-button>
        <div class="d-flex flex-wrap ml-3">
          <div v-for="(locationGroup, index) in model.locationGroups"
               class="location-group-tag"
               :title="locationGroup.name"
               :key="locationGroup.id"
          ><span>
            {{ locationGroup.name }}
          </span>
            <b-button class="close"
                      variant="close"
                      @click="removeLocationGroup(index)"
            ></b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MultiSelect from 'common/components/multi-select.vue'
import CbPrimaryMessage from "../components/cb-primary-message"
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import { noSemicolon } from "./locationCustomValidators";

export default {
  name: "add-location",
  mixins: [validationMixin],
  components: {
    MultiSelect,
    CbPrimaryMessage
  },
  props: {
    editItem: {
      type: Object,
      required: false
    },
    data: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      modalShow: true,
      selectedLocationGroup: null,
      errorMessage: '',
      model: {
        identifier: '',
        name: '',
        primaryContactId: '',
        fi9Company: '',
        brandId: '',
        locationGroups: [],
        address: {
          streetLine1: '',
          streetLine2: '',
          city: '',
          stateProvinceRegion: '',
          postalCode: '',
          countryCode: 'US'
        }
      }
    }
  },
  computed: {
    okTitle() {
      return this.editItem ? this.data.saveLabel : this.data.add.label
    },
    title() {
      return this.editItem ? this.data.editTitle : this.data.addTitle
    },
    activeContacts() {
      return this.data && this.data.company.contacts.filter(contact => contact.isActive)
    }
  },
  validations() {
    return {
      model: {
        identifier: {
          required,
          maxLength: maxLength(36)
        },
        name: {
          required,
          maxLength: maxLength(100),
          noSemicolon
        },
        fi9Company: {
          maxLength: maxLength(25)
        },
        primaryContactId: {
          required
        },
        brandId: {
          required
        },
        address: {
          streetLine1: {
            required,
            maxLength: maxLength(500)
          },
          streetLine2: {
            maxLength: maxLength(100)
          },
          city: {
            required,
            maxLength: maxLength(500)
          },
          stateProvinceRegion: {
            maxLength: maxLength(60)
          },
          postalCode: {
            required,
            maxLength: maxLength(16)
          },
          countryCode: {
            required,
            maxLength: maxLength(2)
          }
        }
      }
    }
  },
  methods: {
    validateState(namesArray) {
      const temp = namesArray.reduce((result, currentValue) => {
        if (result) {
          return result[currentValue]
        } else {
          return this.$v.model[currentValue]
        }
      }, undefined)

      const {$dirty, $error} = temp

      if (namesArray[0] == "name" && $error) {
        if (temp.required === false) {
                  this.errorMessage = this.$t("common.validation.required");
        }

        if (temp.maxLength === false) {
           this.errorMessage = this.$t("common.validation.maxLengthFormat", [temp.$params.maxLength.max]);
        }

        if (temp.noSemicolon === false) {
             this.errorMessage = this.$t("common.validation.noSemicolon");
           }
        }

      return $dirty ? !$error : null
    },
    assignLocationGroup(item) {
      if (item) {
        const exists = this.model.locationGroups.find(locationGroup => item.id === locationGroup.id)

        if (!exists) {
          this.model.locationGroups.push(item)
        }

        this.selectedLocationGroup = null
      }
    },
    selectLocationGroup(item) {
      this.selectedLocationGroup = item
    },
    removeLocationGroup(index) {
      this.model.locationGroups.splice(index, 1)
    },
    async handleDelete(event) {
      event.preventDefault();
      const {data} = await this.$http.delete(this.editItem.deleteUrl)
      this.$emit('ok', data.locations)
      this.modalShow = false
    },
    handleHide(bvModelEvent) {
      if (bvModelEvent.trigger !== 'ok') {
        // hide happen on esc, but it also happens whenever the window gets
        // closed (e.g., hitting the ok button), ok is handled differently (saveAndClose)
        // don't forward this event in that scenario
        this.$emit('hide');
      }
    },
    async handleOk(bvModelEvent) {
      // according to the docs (https://bootstrap-vue.js.org/docs/components/modal#prevent-closing), async isn't supported
      // so always prevent the default and then handle closing it ourselves if needed
      bvModelEvent.preventDefault();

      await this.saveAndClose();
    },
    async saveAndClose() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      let payload = {...this.model}

      payload.locationGroups = payload.locationGroups.map(locationGroup => locationGroup.id)

      let url, method;
      if (this.editItem) {
        url = this.editItem.editUrl
        method = 'put'
      } else {
        url = this.data.add.url
        method = 'post'
      }

      const {data} = await this.$http[method](url, payload)
      this.$emit('ok', data)
      this.modalShow = false
    }
  },
  mounted() {
    if (this.editItem) {
      this.model = {
        identifier: this.editItem.identifier,
        name: this.editItem.name,
        primaryContactId: this.editItem.primaryContactId,
        fi9Company: this.editItem.fi9Company,
        brandId: this.editItem.brandId,
        locationGroups: this.data.settings.associateLocationGroup.locationGroups && this.editItem.locationGroups ? this.data.settings.associateLocationGroup.locationGroups.filter(
            function (locationGroup) {
              return this.indexOf(locationGroup.id) > -1
            },
            this.editItem.locationGroups
        ) : [],
        address: {
          streetLine1: this.editItem.address.streetLine1,
          streetLine2: this.editItem.address.streetLine2,
          city: this.editItem.address.city,
          stateProvinceRegion: this.editItem.address.stateProvinceRegion,
          postalCode: this.editItem.address.postalCode,
          countryCode: this.editItem.address.countryCode
        }
      }

      this.data.company.contacts.find((x) => { //inactive contact, want to still see in list.
        if (x.id === this.editItem.primaryContactId && x.isActive === false)
          x.isActive = true
        return true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.location-state-wrapper {
  width: 40%;
}

.location-postal-code-wrapper {
  width: 30%;
}

.location-country-wrapper {
  width: 20%;
}

.assign-location-group::v-deep {
  width: 100%;
  max-width: 250px;

  .multiselect-picker-button {
    width: 100%;
    max-width: 250px;
  }
}
</style>
