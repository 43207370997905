<script>
import BoltIconSvg from "general/icons/bolt-solid.svg";
import EnvelopeIconSvg from "general/icons/envelope-regular.svg";
import CheckIconSvg from "general/icons/check-circle-regular.svg";
import MapMarkerIconSvg from "general/icons/map-marker-alt-solid.svg";
import 'common/polyfills/array.includes.polyfill';

export default {
  functional: true,
  props: {
    events: {
      type: Array,
      required: true
    }
  },
  render: function(createElement, context) {
    const createSvg = (iconSvg, actionType) => {
      const svg = createElement(
        "svg",
        {
          attrs: {
            role: 'presentation',
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 0 448 512',
            'data-action-type': actionType
          },
          style: {
            height: iconSize(actionType),
            width: iconSize(actionType),
            'vertical-align': 'middle'
          }
        },
        [
          createElement('title', `${actionTypeText(actionType)}`),
          createElement("use", {
            attrs: {
              'xlink:href': `#${iconSvg.id}`
            }
          })
        ]
      );
      return showIcon(actionType) ? svg : null;
    };

    const actionTypeText = (actionType) => {
      return context.parent.$t(`actionIcons.${actionType}`);
    }

    // The SVG being used for the Email and Task icons are shorter than the other icons
    // to the point where they look differently sized, this is to compensate and make them
    // appear more uniformly sized.
    const iconSize = (actionType) => {
      return actionType === 'Email' || actionType === 'Task' ? '1rem' : '0.875rem';
    };

    const showIcon = (type) =>
      context.props && context.props.events && context.props.events.map(item =>
        ('typeEnum' in item) ? item.typeEnum : 'UnrecognizedAction'
      ).includes(type)

    const svgItems = [
      createSvg(EnvelopeIconSvg, 'Email'),
      createSvg(BoltIconSvg, 'ProcessAction'),
      createSvg(CheckIconSvg, 'Task'),
      createSvg(MapMarkerIconSvg, 'Milestone'),
    ].filter(item => item !== null);

    return svgItems && svgItems.length ? 
      createElement(
        'ul', 
        {
          attrs: {
            'aria-label': context.parent.$t('actionCenter.processList.eventActionTypes')
          },
          style: {
            'list-style-type': 'none',
            'padding': '0',
            'margin': '0'
          }
        },
        svgItems.map(
          (svgItem, index) => 
            createElement(
              'li',
              {
                attrs: {
                  'aria-label': `${actionTypeText(svgItem.data.attrs['data-action-type'])}`
                },
                style: {
                  display: 'inline'
                }
              },
              [svgItem]
            )
        )
      ) : 
      null;
  }
};
</script>