<template>
  <section class="cb-primary-message" v-if="showMessage">
    <ul class="list">
      <li class="message -info padding-med" v-if="infoMessage">
        <div class="icon">
          <svg>
              <use v-bind="{'xlink:href': formIconPath('Message-Info-Icon') }"></use>
          </svg>
        </div>
        {{infoMessage}}
      </li>
      <li class="message -warning padding-med" v-if="warningMessage">
        <div class="icon">
          <svg>
              <use v-bind="{'xlink:href': formIconPath('Message-Error-Warning-Icon') }"></use>
          </svg>
        </div>
        {{warningMessage}}
      </li>
      <li class="message -error padding-med" v-if="errorMessage">
        <div class="icon">
          <svg>
              <use v-bind="{'xlink:href': formIconPath('Message-Error-Warning-Icon') }"></use>
          </svg>
        </div>
        {{errorMessage}}
      </li>
      <li class="message -success padding-med" v-if="successMessage">
        <div class="icon">
          <svg>
              <use v-bind="{'xlink:href': formIconPath('Message-Success-Icon') }"></use>
          </svg>
        </div>
        {{successMessage}}
      </li>
    </ul>
  </section>
</template>

<script>
  import MessageBus from 'common/components/cb-primary-message-bus';
  import { IconProvider } from 'common/icon.module';

  export default {
    name: 'cb-primary-message',
    created () {
      MessageBus.$on('send-info-message', this.onInfoMessage);
      MessageBus.$on('send-warning-message', this.onWarningMessage);
      MessageBus.$on('clear-warning-message', this.onClearWarningMessage);
      MessageBus.$on('send-error-message', this.onErrorMessage);
      MessageBus.$on('send-success-message', this.onSuccessMessage);
      MessageBus.$on('clear-all-messages', this.onClearAllMessages);
    },
    destroyed () {
      MessageBus.$off('send-info-message', this.onInfoMessage);
      MessageBus.$off('send-warning-message', this.onWarningMessage);
      MessageBus.$off('clear-warning-message', this.onClearWarningMessage);
      MessageBus.$off('send-error-message', this.onErrorMessage);
      MessageBus.$off('send-success-message', this.onSuccessMessage);
      MessageBus.$off('clear-all-messages', this.onClearAllMessages);
    },
    methods: {
      onInfoMessage (value) {
        window.scrollTo(0,0);
        this.infoMessage = value;
      },
      onWarningMessage (value) {
        window.scrollTo(0,0);
        this.warningMessage = value;
      },
      onClearWarningMessage () {
        this.warningMessage = null;
      },
      onErrorMessage (value) {
        window.scrollTo(0,0);
        this.errorMessage = value;
      },
      onSuccessMessage (value) {
        window.scrollTo(0,0);
        this.successMessage = value;
      },
      onClearAllMessages () {
        this.infoMessage = null;
        this.warningMessage = null;
        this.errorMessage = null;
        this.successMessage = null;
      },
      formIconPath (path) {
        return IconProvider.getPath(path);
      }
    },
    computed: {
      showMessage () {
        if (this.errorMessage || this.infoMessage || this.warningMessage || this.successMessage) {
          return true;
        } else {
          return false;
        }
      }
    },
    data () {
      return {
        infoMessage: null,
        warningMessage: null,
        errorMessage: null,
        successMessage: null
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .cb-primary-message {
    @include box-sizing-reset;
    margin-bottom: 1.25rem;
    width: 100%;

    .list {
        margin: 0; padding: 0;
    }

    .message {
      background: $cb-white;
      border: 1px solid $cb-light-grey-1;
      border-radius: 2px 2px 0 0;
      border-top-width: 3px;
      display: flex;
      align-items: center;


      .icon {
        margin-right: 1rem;

        svg {
          width: 36px; height: 36px;
        }
      }

      &.-info {
        border-top-color: $cb-brand-blue-1;

        .icon svg {
          fill:$cb-brand-blue-1;
        }
      }
      &.-warning {
        border-top-color: $cb-yellow-orange-1;

        .icon svg {
          fill:$cb-yellow-orange-1;
        }
      }
      &.-error {
        border-top-color: $cb-red-1;

        .icon svg {
          fill:$cb-red-1;
        }
      }
      &.-success {
        border-top-color: $cb-green-1;

        .icon svg {
          fill:$cb-green-1;
        }
      }
    }
  }

</style>
