var OnEscDirective = function () {
	return function (scope, elm, attr) {
		elm.bind('keydown', function (e) {
			if (e.keyCode === 27) {
				scope.$apply(attr.onEsc);
				e.preventDefault() ;
			}
		});
	};
};

var OnEnterDirective = function () {
	return function (scope, elm, attr) {
		elm.bind('keypress', function (e) {
			if (e.keyCode === 13) {
				scope.$apply(attr.onEnter);
			}
		});
	};
};

var OnTabDirective = function () {
	return function (scope, elm, attr) {
		elm.bind('keydown', function (e) {
			if (e.keyCode === 9) {
				scope.$apply(attr.onTab);
			}
		});
	};
};

export default angular.module('keyboard-module', [])
    .directive('onEsc', OnEscDirective)
    .directive('onEnter', OnEnterDirective)
    .directive('onTab', OnTabDirective)
    .name;