import CloneDeep from 'lodash.clonedeep';
import ProcessesController from 'processes/processes.controller';

export default class ProcessController extends ProcessesController {
	constructor ($http, $scope, $filter, $window, $route, $timeout, $rootScope, appSettings, locationSvc, ProcessServices, aaNotify) {
		super($http, $scope, $route, $timeout, locationSvc, appSettings);

		var deregisterInitialDataLoad = $scope.$watch(() => this.content, (newVal) => {
			if (newVal) {
				this.currentItem = newVal;
				this.activeNavTitle = this.currentItem.detail.name;
				deregisterInitialDataLoad();
			}
		});

		aaNotify.clearAll();
		this.aaNotify = aaNotify;
		this.locationSvc = locationSvc;
		this.appSettings = appSettings;
		this.$http = $http;
		this.$window = $window;
		this.$scope = $scope;
		this.$rootScope = $rootScope;
		this.isCopy = false;

		this.updateProcess = this.updateProcess.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleAddStepTemplate = this.handleAddStepTemplate.bind(this);
    this.handleStepClose = this.handleStepClose.bind(this);
    this.handleStepUpdate = this.handleStepUpdate.bind(this);
    this.handleShowStepEditEvent = this.handleShowStepEditEvent.bind(this);
    this.handleShowStepEdit = this.handleShowStepEdit.bind(this);
    this.handleStepDelete = this.handleStepDelete.bind(this);
    this.handleDeleteFormSend = this.handleDeleteFormSend.bind(this);
    this.handleActivityEdit = this.handleActivityEdit.bind(this);
    this.onStepReorder = this.onStepReorder.bind(this);
    this.onFormReorder = this.onFormReorder.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleAddFormUpdate = this.handleAddFormUpdate.bind(this);

    $scope.$on('stepItem:edit', this.handleShowStepEditEvent);
  }

  updateProcess (data) {
    if (data) {
      this.currentItem = data;
      this.activeNavTitle = this.currentItem.detail.name
    }
  }

  updateSteps (data) {
    if(data.steps && data.stepTemplates && data.detail) {
      this.currentItem.steps = data.steps;
      this.currentItem.stepTemplates = data.stepTemplates;
      this.currentItem.detail = data.detail;
    }
  }

  handleBack () {
    this.locationSvc.changeView(this.appSettings.viewProcesses, this.content.processes.url);
    this.$scope.$apply();
  }

  handleDelete (event) {
    event.preventDefault();
    var deleteConfirmation = this.currentItem.deleteConfirmation;
    var msg = { 'title': '', 'message': deleteConfirmation.message, 'buttonOK': deleteConfirmation.confirm.label, 'buttonClose': deleteConfirmation.cancelLabel };
    this.$rootScope.$broadcast('cbAppCtrl:alertShow', msg, () => {
      this.$http.delete(deleteConfirmation.confirm.url).then((response) => {
        this.locationSvc.changeView(this.appSettings.viewProcesses, this.content.processes.url);
        this.handleClose(null);
      });
    });
    this.$scope.$apply();
  };

  handleClose (value) {
    this.aaNotify.clearAll();
    this.updateProcess(value);
    this.showAdd = false;
  }

  handleAddStepTemplate (item) {
    this.$http.get(item.url).then((response) => {
      this.currentStepItem = response.data;
      this.showAddStep = true;
    });
  }

  handleStepClose (value) {
    this.aaNotify.clearAll();
    this.updateSteps(value);

    this.currentStepItem = null;
    this.showAddStep = false;
  }

  handleStepUpdate (value) {
    this.updateProcess(value);
  }

  handleStepDelete (event, deleteUrl, labels) {
    event.preventDefault();
    var msg = {
      'title': labels.title,
      'message': labels.message,
      'buttonOK': labels.okLabel,
      'buttonClose': labels.closeLabel
    };

    this.$rootScope.$broadcast('cbAppCtrl:alertShow', msg, (e) => {
        this.$http.delete(deleteUrl).then((response) => {
          this.handleStepClose(response.data);
        });
      });
    this.$scope.$apply();
  };

  handleShowStepEditEvent (e, data) {
    this.handleShowStepEdit(data);
  }

  handleShowStepEdit (step) {
    this.currentStepItem = step;
    this.showAddStep = true;
    this.$scope.$apply();
  }

  onStepReorder(item, newValue) {
    if (newValue >= 1 && newValue <= this.currentItem.steps.steps.length) {
      this.$http.put(item.reorder.url, { ordinal: newValue })
        .then((response) => {
          this.updateSteps(response.data);
      });
    }
  }

  handleAddFormUpdate (data) {
    if (data) { this.updateSteps(data); }
  };

  handleDeleteFormSend (parentId, displayName, action){
    event.preventDefault();

    var msg = {
      'title': '',
      'message': 'Are you sure you want to delete ' + displayName + ' from this step?',
      'buttonOK': 'Delete',
      'buttonClose': 'Cancel'
    };

    this.$rootScope.$broadcast('cbAppCtrl:alertShow', msg, (e) => {
      this.$http.delete(action.url).then((response) => {
        this.updateSteps(response.data);
      });
    });
    this.$scope.$apply();
  }

  handleActivityEdit (action) {
    this.locationSvc.changeView(this.appSettings.viewActivityEdit, action.url);
    this.$scope.$apply();
  }

  onFormReorder(itemUrl, index, parentId) {
    return this.$http.put(itemUrl, {ordinal: index})
      .then((response) => {
        this.updateSteps(response.data);
    });
  }
}

ProcessController.$inject = ['$http', '$scope', '$filter', '$window', '$route', '$timeout', '$rootScope', 'appSettings', 'locationSvc', 'ProcessServices', 'aaNotify'];
