import MenuBus from 'general/cb-admin-menu-bus';
import { adminAppEventBus, AdminAppEvents } from 'admin-app-event-bus';
import { headerServiceEventBus, HeaderServiceEvents } from 'header/header.service';
import addEventListenerForBodySelectPlaceholder from 'general/select-placeholder';
import store from 'store';
import aaNotifyMessageBus from 'components/aanotify-message-bus';

async function cbAppController($scope, $rootScope, $http, $window, $filter, $location, $timeout, aaNotify, appSettings, locationSvc, HeaderService, deviceDetector) {
    $scope.defaultMessage = {
        'title': 'We\'re sorry.',
        'message': 'Something unexpected happened while displaying this page. If you continue to have problems, please contact us.',
        'btnLabel': 'OK',
        'url': ''
    };
    //The application is temporarily unavailable.  Our Engineers have been notified and are working quickly to resolve the issue.  If you continue to have problems please contact us.

    let expandedMenu = function (val) {
        $scope.navExpanded = val
        $timeout(function () {
            $scope.$apply();
        });
    }

    $scope.header = HeaderService.headerContent;

    MenuBus.onUpdateMenuExpanded(expandedMenu)

    $scope.deviceDetector = deviceDetector;

    addEventListenerForBodySelectPlaceholder();

    $scope.$on('$viewContentLoaded', function (event) {
        aaNotify.clearAll();
    });

    headerServiceEventBus.on(HeaderServiceEvents.HeaderChanged, async (event) => {
        // If the header ever returns a different authenticated user id,
        // then implicitly trust that.  This is necessary because if session
        // storage is unavailable, we may not have a userId passed along
        // from the login app and this might be the first time we encounter it.

        let currentUserId = store.getters['auth/getAuthenticatedUserId'];
        if (event && event.header && event.header.authenticatedUserId !== currentUserId) {
            await store.dispatch('auth/setAuthenticatedUserId', event.header.authenticatedUserId);
        }
    });

    $scope.$watch('header.clientSelection', function () {
        if ($scope.header && typeof $scope.header.clientSelection !== "undefined") {
            $scope.showClientSelect = $scope.header.clientSelection.hasMultiClientAccess;
            if (!$scope.header.clientSelection.client || !$scope.header.clientSelection.client.menuUrl) {//no clients
                locationSvc.changeView(appSettings.viewAccessDenied);
            } else {
                // null clientId means they are in an area that provides access to the clientSelect already
                $scope.showClientSelect = $scope.showClientSelect && $scope.header.clientSelection.client.clientId != null;
                $scope.currentClient = $scope.header.clientSelection.client;

                MenuBus.updateDataUrl($scope.currentClient.menuUrl)
            }
        }
    });

    /* brings the selected client into focus when opening the picker */
    $scope.$watch('showClient', function () {
        $timeout(function () {
            if ($scope.showClient && typeof $scope.currentClient !== 'undefined') {
                var selectedClientOptions = document.getElementsByClassName('clientOption-' + $scope.currentClient.clientId);
                if (selectedClientOptions.length) {
                    selectedClientOptions[0].focus();
                }
            }
        });
    });

    $rootScope.$on('cfpLoadingBar:error', showErrorDirective);

    function showErrorDirective(e, data) {
        if (data && data.length == 1 && data[0].message && data[0].typeEnum === 'Soft') {
            $scope.errorMsg = aaNotify.danger(data[0].message, { showClose: true, ttl: 0 });
        } else {
            $scope.message = (data && data.message && data.typeEnum === 'Hard') ? data : $scope.defaultMessage;
            $scope.showError = true;
        }
    };

    $scope.handleError = function (e, value) {
        $scope.showError = false;
        locationSvc.changeView(appSettings.viewLogin);
        $scope.$apply();
    };

    let alertContinueCallback;

    function showAlertDirective(e, data, callback) {
        alertContinueCallback = callback;
        $scope.showalert = true;
        $scope.alertcontent = data;
    }

    $scope.handleAlertClose = function (e, value) {
        $scope.showalert = false;
        $scope.$apply();
    };
    $scope.handleAlertOK = function (e, value) {
        $scope.showalert = false;
        alertContinueCallback();
        $scope.$apply();
    };

    $rootScope.$on('cbAppCtrl:alertShow', showAlertDirective);

    $scope.handleDocClose = function (e, value) {
        $scope.showDoc = false;
    };

    $scope.handleSelectClientClosed = function (e, value) {
        if (value != null)
            $scope.currentClient = value;
        $scope.showClient = false;
    };

    // if headerUrl is null, don't try and load it as we will
    // eventually be logged out.
    if (HeaderService.getHeaderUrl()) {
        const response = await HeaderService.reloadHeaderUrl();

        await store.dispatch('setClient', {
            id: response.clientSelection.client ? response.clientSelection.client.clientApiIdentifier : null
        });
    }

    /* Shim to forward aaNotify-message-bus messages to the aaNotify service (subscribing) */
    aaNotifyMessageBus.$on('send-info-message', (message) => {
        aaNotify.info(message);
    });
    aaNotifyMessageBus.$on('send-warning-message', (message) => {
        aaNotify.warning(message);
    });
    aaNotifyMessageBus.$on('send-error-message', (message) => {
        aaNotify.error(message);
    });
    aaNotifyMessageBus.$on('send-success-message', (message) => {
        aaNotify.success(message);
    });
    aaNotifyMessageBus.$on('clear-all-messages', () => aaNotify.clearAll());

    $scope.$on('$destroy', function () {
        /* Shim to forward aaNotify-message-bus messages to the aaNotify service (unsubscribing) */
        aaNotifyMessageBus.$off('send-info-message');
        aaNotifyMessageBus.$off('send-warning-message');
        aaNotifyMessageBus.$off('send-error-message');
        aaNotifyMessageBus.$off('send-success-message');
        aaNotifyMessageBus.$off('clear-all-messages');
    });
};

export default cbAppController;

cbAppController.$inject = ['$scope', '$rootScope', '$http', '$window', '$filter', '$location', '$timeout', 'aaNotify', 'appSettings', 'locationSvc', 'HeaderService', 'deviceDetector'];
