var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('cb-admin-view-title',{staticClass:"cb-admin-view-title",attrs:{"title":_vm.$t('users.title')}},[(_vm.showAddBtn)?_c('b-button',{staticClass:"create-user-button",attrs:{"variant":"green"},on:{"click":_vm.addUser}},[_vm._v(_vm._s(_vm.$t('users.createUser'))+"\n    ")]):_vm._e()],1),(_vm.data)?_c('cb-view-section',{staticClass:"pt-0",attrs:{"type":"secondary","includePrimaryMessages":false}},[_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"filter-group"},[_c('div',{staticClass:"search-item"},[(_vm.viewData.search && _vm.selectedFilter[_vm.viewData.search.filterKey])?_c('b-form-input',{staticClass:"search-input users-filter small-input",attrs:{"placeholder":_vm.viewData.search.placeholder,"debounce":500},on:{"update":_vm.searchHandler},model:{value:(_vm.selectedFilter[_vm.viewData.search.filterKey][0].value),callback:function ($$v) {_vm.$set(_vm.selectedFilter[_vm.viewData.search.filterKey][0], "value", $$v)},expression:"selectedFilter[viewData.search.filterKey][0].value"}}):_vm._e()],1)]),_vm._l((_vm.filters),function(filter){return _c('multi-select',{key:filter.filterKey,attrs:{"options":filter.items,"value":_vm.selectedFilter[filter.filterKey],"search-placeholder":_vm.$t('common.filterSearchPlaceholder', [filter.title.toLowerCase()]),"close-on-select":false,"track-by":"value","search-by":"label","title":filter.title},on:{"select":function($event){return _vm.selectFilter(filter.filterKey, $event, true)},"newSearchValue":_vm.doSearch,"clickAwayOnSearch":function($event){return _vm.reloadClearedFilters('')}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
var isSelected = ref.isSelected;
return [_c('b-form-checkbox',{staticClass:"pointer-none",attrs:{"checked":isSelected(option)}}),_c('span',{directives:[{name:"text-middle-ellipsis",rawName:"v-text-middle-ellipsis",value:(option.label),expression:"option.label"}]})]}}],null,true)})}),_c('b-button',{staticClass:"ml-3 small-button clear-filters-users",attrs:{"variant":"outline-green"},on:{"click":_vm.clearFilters}},[_vm._v("\n      "+_vm._s(_vm.$t('common.clearFilters'))+"\n    ")])],2),(_vm.error)?_c('div',[_c('p',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.error))])]):_c('div',[(_vm.users)?_c('b-table',_vm._b({ref:"usersTable",staticClass:"users-list-container new-table new-table--without-first-cell-border",attrs:{"items":_vm.users.users,"show-empty":"","no-local-sorting":"","empty-text":_vm.emptyText,"fields":[
    { key: 'select', label: '', thStyle: { width: '50px' }, sortable: false },
    { key: 'name', label: _vm.viewData.nameHeader, thAttr: { 'aria-sort': 'descending' },
      thStyle: { maxWidth: '250px' }, sortable: true, sortDirection: 'desc'},
    { key: 'accessLevel', label: _vm.viewData.accessLevelHeader, sortable: true },
    { key: 'view', label: '', class: 'link-column' }
  ]},on:{"sort-changed":_vm.sortUsers},scopedSlots:_vm._u([{key:"head(select)",fn:function(){return [_c('div',{staticClass:"multi-checkbox",class:_vm.multiCheckboxStatus,on:{"click":_vm.multiSelectUsers}})]},proxy:true},{key:"cell(select)",fn:function(ref){
  var item = ref.item;
return [_c('b-form-checkbox',{staticClass:"user-checkbox",attrs:{"checked":_vm.isUserChecked(item.id)},on:{"change":function($event){return _vm.selectUser(item.id)}}})]}},{key:"cell(name)",fn:function(ref){
  var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.formattedName)+"\n      ")]}},{key:"cell(view)",fn:function(ref){
  var item = ref.item;
return [_c('svg',{staticClass:"edit-icon",on:{"click":function($event){return _vm.editUser(item)}}},[_c('use',_vm._b({},'use',{'xlink:href': _vm.getIconPath('Action-Edit-Icon') },false))])]}}],null,false,429458412)},'b-table',_vm.tableSettings,false)):_vm._e(),(_vm.showPagination)?_c('div',{staticClass:"pagination-container"},[_c('b-form-group',{staticClass:"per-page-options",attrs:{"label":"Per page","label-for":"per-page-select","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","value":_vm.pagination.itemsPerOnePage,"options":_vm.paginationSettings.pageOptions,"size":"sm"},on:{"change":_vm.handlePageSizeChange}})],1),_c('b-pagination',_vm._b({attrs:{"value":_vm.pagination.currentPage,"total-rows":_vm.totalRows,"per-page":_vm.pagination.itemsPerOnePage,"current-page":_vm.pagination.currentPage},on:{"change":_vm.handlePageChange}},'b-pagination',_vm.paginationSettings,false))],1):_vm._e(),_c('div',[_c('b-form-checkbox',{staticClass:"inactive-toggle",on:{"change":_vm.handleInactiveChange},model:{value:(_vm.showInactive),callback:function ($$v) {_vm.showInactive=$$v},expression:"showInactive"}},[_vm._v("\n        "+_vm._s(_vm.viewData.inactiveFilter.label)+"\n      ")])],1)],1),(_vm.allSelectedUsersIds.length > 0)?_c('multi-edit',{attrs:{"batch":_vm.viewData.batch,"selected-ids":_vm.allSelectedUsersIds,"button-titles":{
                'Cancel': _vm.$t('candidates.multiEditCancelTitle')
              }},on:{"update":_vm.updateUsers}}):_vm._e()],1):_vm._e(),(_vm.modalShow)?_c('user-add',{attrs:{"data":_vm.viewData.addEdit,"edit-item":_vm.editItem,"edit-settings":_vm.editSettings},on:{"ok":_vm.handleOk,"close":_vm.handleClose,"cancel":_vm.handleClose,"hide":_vm.handleClose}},[_vm._v(">\n")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }