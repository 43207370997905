<template>
  <cb-view-section type="secondary" :withFlushTop="true">
    <section v-savage-grid-columns="2" v-if="this.locationsLoaded">
      <savage-form-text-input
        :label="$t('processBuilder.activities.add.labels.candidateDueDateOffset')"
        :placeholder="$t('processBuilder.activities.add.placeholders.dueDateOffset')"
        :validation="buildValidation()"
        :customValidation="buildCustomCandidateValidation()"
        v-model="process.candidateDueDateOffset"
      />
      <savage-form-text-input
        :label="$t('processBuilder.activities.add.labels.processDueDateOffset')"
        :placeholder="$t('processBuilder.activities.add.placeholders.dueDateOffset')"
        :validation="buildValidation()"
        :customValidation="buildCustomProcessValidation()"
        v-model="process.processDueDateOffset"
      />
      <savage-form-select
        :label="$t('processBuilder.activities.add.labels.locationAssignment')"
        :items="this.locations"
        :emptyItemLabel="$t('processBuilder.activities.add.placeholders.locationSelectionPrompt')"
        :validation="buildCustomLocationValidation()"
        v-model="process.locationApiIdentifier"
        @input="getUsersAsync"
      />
      <savage-form-select
        :label="$t('processBuilder.activities.add.labels.hiringManagerAssignment')"
        :items="this.clientUsers"
        :emptyItemLabel="$t('processBuilder.activities.add.placeholders.hiringManagerSelectionPrompt')"
        v-model="process.hiringManagerId"
      />
      <savage-form-select
        :label="$t('processBuilder.activities.add.labels.coordinatorAssignment')"
        :items="this.clientUsers"
        :emptyItemLabel="$t('processBuilder.activities.add.placeholders.coordinatorSelectionPrompt')"
        v-model="process.coordinatorId"
      />
      <savage-form-select
        :label="$t('processBuilder.activities.add.labels.recruiterAssignment')"
        :items="this.clientUsers"
        :emptyItemLabel="$t('processBuilder.activities.add.placeholders.recruiterSelectionPrompt')"
        v-model="process.recruiterId"
      />
    </section>
  </cb-view-section>
</template>

<script>
  import CbViewSection from 'general/cb-view-section.vue';
  import { SavageFormTextInput, SavageFormSelect, SavageFormMixin, SavageGridColumnsDirective } from '@clickboarding/savage';
  
  export default {
    name: 'process-configure',
    components: {
      CbViewSection,
      SavageFormTextInput,
      SavageFormSelect,
    },
    directives: {
      SavageGridColumns: SavageGridColumnsDirective
    },
    mixins: [
      // provides the validation directive required by 
      // savage to validate the slot content of validated-container
      SavageFormMixin
    ],
    async mounted() {
      await this.getLocationsAndUsersAsync();
    },
    created() {
      if (this.process.processId == null) {
        this.process.processId = this.process.value;
      }
      if (!this.process.candidateDueDateOffset && this.process.candidateDueDateOffset !== 0) {
        this.process.candidateDueDateOffset = 7;
      }

      if (!this.process.processDueDateOffset && this.process.processDueDateOffset !== 0) {
        this.process.processDueDateOffset = 14;
      }
    },
    methods: {
      async getLocationsAndUsersAsync() {
        let url = this.buildUrlWithIdentifier(this.process.locations.url, this.process.locationApiIdentifier);
        var response = await this.$http.get(url);
        this.locations = response.body.locations || [];
        this.clientUsers = response.body.users || [];
        
        this.preSelectLocationIfRequiredAndOnlyOneOption();
        this.locationsLoaded = true;
      },
      async getUsersAsync() {
        let url =  this.buildUrlWithIdentifier(this.process.userList.url, this.process.locationApiIdentifier);
        var response = await this.$http.get(url);
        this.clientUsers = response.data || [];
        
        if (!this.userIdInClientUsers(this.process.coordinatorId)) {
          this.process.coordinatorId = null;
        }
        if (!this.userIdInClientUsers(this.process.hiringManagerId)) {
          this.process.hiringManagerId = null;
        }
        if (!this.userIdInClientUsers(this.process.recruiterId)) {
          this.process.recruiterId = null;
        }
      },
      preSelectLocationIfRequiredAndOnlyOneOption() {
        if(this.process.locationRequired && this.locations.length === 1) {
          this.process.locationApiIdentifier = this.locations[0].value;
        }
      },
      isNullOrInteger(value) {
        return value === null ? true : !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10))
      },
      userIdInClientUsers(userId) {
        return this.clientUsers.filter(_ => _.value === userId).length > 0;
      },
      buildValidation() {
        return {
          required: {
            value: true,
            message: this.$t('common.validation.required')
          }
        };
      },
      buildCustomProcessValidation() {
        return {
          'process-integer-validation': {
            value: this.isNullOrInteger(this.process.processDueDateOffset),
            message: this.$t('processBuilder.activities.add.validation.wholeNumberFormat')
          }
        }
      },
      buildCustomCandidateValidation() {
        const candidateOffset = parseInt(this.process.candidateDueDateOffset) || 0;
        const processOffset = parseInt(this.process.processDueDateOffset) || 0;
        return {
          'candidate-integer-validation': {
            value: this.isNullOrInteger(this.process.candidateDueDateOffset),
            message: this.$t('processBuilder.activities.add.validation.wholeNumberFormat')
          },
          'candidate-offset-validation': {
            value: candidateOffset <= processOffset,
            message: this.$t('processBuilder.activities.add.validation.candidateDueDateOffset')
          }
        }
      },
      buildCustomLocationValidation() {
        return {
          required: {
            value: this.process.locationRequired,
            message: this.$t('common.validation.required')
          }
        }
      },
      buildUrlWithIdentifier(url, identifier) {
        return url + (identifier ? '&locationApiIdentifier=' + encodeURIComponent(identifier) : '');
      }
    },
    model: {
      prop: 'activity'
    },
    props: {
      activity: {
        type: Object,
        required: true
      }
    },
    watch: {
      process: {
        deep: true,
        handler: function(val, oldVal) {
          // The underlying system of controls really wants the same object emitted back
          // so assign our properties on to that object
          this.$emit('input', Object.assign(this.activity, this.process));
        }
      }
    },
    data() {
      return {
        clientUsers: [],
        locations: [],
        locationsLoaded: false,
        process: Object.assign({ 
            candidateDueDateOffset: null,
            processDueDateOffset: null,
            hiringManagerId: null,
            coordinatorId: null,
            recruiterId: null,
            locationApiIdentifier: null,
          }, this.activity)
      }
    }
  }
</script>
