<template>
  <section class="savage-form-content-element">
    <section class="savage-form-video">
      <savage-form-youtube-video v-if="provider === 'YouTube'" :url="this.url" />
      <savage-form-vimeo-video v-else-if="provider === 'Vimeo'" :url="this.url" />
    </section>
  </section>
</template>

<script>
import SavageFormYoutubeVideo from 'common/savage/savage-form-youtube-video.vue';
import SavageFormVimeoVideo from 'common/savage/savage-form-vimeo-video.vue';

export default {
  name: 'savage-form-video',
  components: {
    SavageFormYoutubeVideo,
    SavageFormVimeoVideo
  },
  props: {
    provider: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  }
}
</script>
