<template>
  <section class="savage-form-youtube-video">
    <div v-if="videoId">
      <iframe :src="embedUrl"
          frameborder="0"
          allow="encrypted-media"
          allowfullscreen>
      </iframe>
    </div>
  </section>
</template>

<script>
  import YoutubeService from 'common/services/youtube.service'
  export default {
    name: 'savage-form-youtube-video',
    props: {
      url: {
        type: String,
        required: true
      }
    },
    created () {
      this.videoId = YoutubeService.getVideoId(this.url);
    },
    data () {
      return {
        videoId: null
      }
    },
    computed: {
      embedUrl () {
        return 'https://www.youtube-nocookie.com/embed/' + this.videoId
          + '?rel=0' // no related videos
          + '&autoplay=0'
        ;
      }
    }
  }
</script>
<style scoped lang="scss">
  .savage-form-youtube-video {
    overflow:hidden;
    padding-bottom: 56.25%; //16:9 aspect ratio (9 / 16 = .5625)
    position:relative;
    height:0;
  }
  .savage-form-youtube-video iframe {
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
</style>
