import MenuService from 'general/menu.service';

export default class ViewController {
  constructor($http, $scope, $route, $timeout, locationSvc) {
    this.content = null;
    this.MenuService = MenuService;
    this.$http = $http;
    const currentDataUrl = locationSvc.fetchQuery();
    this.backUrl = currentDataUrl;

    if (currentDataUrl) {
      $http.get(currentDataUrl).then(response => {
        var data = response.data;
        this.activeNavTitle = MenuService.activeTitle;

        this.content = data;

      // onPageLoad needs to be late bound to this after this constructor has completed its execution
      // to ensure this becomes the child class as opposed to the view.controller.js instance.
      // This one works without setTimeout because it is already after an http promise
      }).then(this.onPageLoad.bind(this));
    }
  }

  onPageLoad() {
    //solely here to provide an extension point in controllers extending this class
  }
}

ViewController.$inject = ["$http", "$scope", "$route", "$timeout", "locationSvc"];
