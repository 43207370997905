import AddressIcon from 'forms/address.svg';
import CompanyInformationIcon from 'forms/company-information.svg';
import NameIcon from 'forms/name.svg';
import PersonalInformationIcon from 'forms/personal-information.svg';
import GovernmentIssuedIdIcon from 'forms/government-issued-id.svg';
import CertificationIcon from 'forms/certification.svg';

import { IconProvider } from 'common/icon.module';

const mapping = {
  'Address': AddressIcon.id,
  'Company': CompanyInformationIcon.id,
  'Name': NameIcon.id,
  'PersonalInformation': PersonalInformationIcon.id,
  'GovernmentIssuedId': GovernmentIssuedIdIcon.id,
  'Certification': CertificationIcon.id
};

function get (element) {
  return IconProvider.getPath(mapping[element.designer.dataCategory]);
};

export { get }