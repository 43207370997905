<template>
  <div v-if="params.value">
    <b-button
      class="small-button"
      style="height: 1.8rem; font-size: 11px; width:120px;"
      :variant="buttonStyle"
      @click="btnClickedHandler()"
      :disabled="!canComplete"
      v-b-tooltip.hover :title="toolTipText"
      > {{ title }}
    </b-button>
  </div>
</template>

<script>

export default {
  name: "ActionButton",
  mounted() {},
  methods: {
    btnClickedHandler() {
          this.params.clicked(this.params.data);
    }
  },
   computed: {
       title() {
           let formIsCompleted = false;

           if (this.params.data.activityStatus === 'Completed') {
               formIsCompleted = true;
           }

           return `${formIsCompleted ? "Review" : "Complete"} ${this.params.value || ""}`;
      },
      canComplete(){
        return this.params.data.canComplete;
      },
      buttonStyle() {
        return (this.params.data.canComplete) ? "green": "";
      },
      toolTipText(){
        return (!this.params.data.canComplete) ? "Access Not Available" : "";
      }
    }
  };
</script>

<style lang="scss">

button:disabled {
  cursor: not-allowed;
}
</style>

