<template>
    <section>
        <cb-admin-view-title class="cb-admin-view-title title-margin" title="Import Specifications">
            <div class="ml-auto candidate-heading-buttons">
                <b-button class="ml-auto" variant="green" @click="downloadTemplate()">
                    <i class="fa fa-download"></i> Download Template
                </b-button>
            </div>
        </cb-admin-view-title>
        <div class="text-muted instructions">
            <div>
                For a successful import, please download and use the provided
                templates. Complete the templates according to the information
                provided below.
            </div>
        </div>
        <cb-admin-view-title class="cb-admin-view-title title-margin">
            <div>
                <b-form-select id="specTypeDropDown" :options="specOptions" @change="onSpecChange($event)"
                    v-model="selected"></b-form-select>
            </div>
        </cb-admin-view-title>
        <section>
            <b-table class="specsTable" :fields="[
                {
                    key: 'field',
                    label: 'Field',
                    thStyle: { width: '50px' },
                    sortable: false,
                },
                {
                    key: 'dataType',
                    label: 'Data Type',
                    thStyle: { width: '50px' },
                    sortable: false,
                },
                {
                    key: 'required',
                    label: 'Required',
                    thStyle: { width: '50px' },
                    sortable: false,
                },
                {
                    key: 'description',
                    label: 'Description',
                    thStyle: { width: '50px' },
                    sortable: false,
                },
                {
                    key: 'validValues',
                    label: 'Valid Values',
                    thStyle: { width: '50px' },
                    sortable: false,
                    tdClass: 'validValues',
                },
            ]" :items="specs">
            </b-table>
        </section>
    </section>
</template>
<script>
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import importsService from './imports.service';
import importTypes from './importTypes';

export default {
    name: 'bulk-imports-specs',
    components: {
        CbAdminViewTitle,
    },
    data() {
        return {
            selected: importTypes.EMPLOYEE,
            specs: importsService.getImportSpecs(importTypes.EMPLOYEE),
            specOptions: importsService.getImportSpecOptions(importTypes.EMPLOYEE),
        };
    },
    methods: {
        onSpecChange(importTypeId) {
            this.specs = importsService.getImportSpecs(importTypeId);
        },
        async downloadTemplate() {
            await importsService.downloadTemplate(this.selected);
        },
    },
};
</script>
<style scoped lang="scss">
.title-margin {
    margin-left: 5px;
}

.instructions {
    margin: -15px 0px 30px 30px;
}

.instructions>div {
    margin-bottom: 1rem;
}

.candidate-heading-buttons {
    &>button {
        margin-right: 1rem;
    }
}

.specsTable {
    width: 97%;
    margin-left: 30px;
}
</style>
