<template>
  <div>
    <validation-provider name="candidate.processFields.locationLabel" slim :rules="{ required: isLocationRequired }"
                         v-slot="{ passed, failed, errors }">
      <b-form-group
          :state="passed"
          :invalid-feedback="errors[0]"
          label-for="location"
      >
        <multi-select
            class="assign-location-group"
            :options="locationOptions || []"
            :value="localLocation"
            :full-width="true"
            :label="$t('candidate.processFields.locationLabel')"
            :required="isLocationRequired"
            :state="failed"
            track-by="id"
            search-by="name"
            :search-placeholder="$t('common.filterSearchPlaceholder', [$t('locations.title').toLowerCase()])"
            :close-on-select="true"
            :title="$t('candidate.processFields.locationLabel')"
            @select="setLocalLocation"
            @newSearchValue="doSearch"
            @clickAwayOnSearch="reloadClearedFilters('')"
        >
          <template v-slot:option="{ option }">
            {{ option.name }}
          </template>
        </multi-select>
      </b-form-group>
    </validation-provider>
    <template v-if="!loadingCreateInfo && !hasLocationOptions">
      <b-alert show class="with-icon w-100" variant="warning">
        <b-icon aria-hidden="true" icon="alert-triangle"/>
        {{ $t('candidate.addProcessFlowNoLocationsWarning') }}
      </b-alert>
    </template>
    <template v-if="hasSelectedLocation">
      <template>
        <hr/>
        <validation-provider name="candidate.processFields.processLabel" slim rules="required"
                             v-slot="{ passed, failed, errors }">
          <b-form-group
              :label-for="$id('processId')"
              :state="passed"
              :invalid-feedback="errors[0]"
          >
            <template v-slot:label>
              {{ $t("candidate.processFields.processLabel") }}
              <span aria-hidden class="text-danger">*</span>
            </template>
            <b-form-select :id="$id('processId')"
                           :options="processOptions || []"
                           :text-field="'name'"
                           :value-field="'id'"
                           v-model="localProcessId"
                           :state="failed ? false : null"
                           required>
              <template v-slot:first>
                <b-form-select-option :value="null">{{ $t('candidate.processFields.processEmptyOptionLabel') }}
                </b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
        </validation-provider>
        <template v-if="!hasProcessOptions && !locationLoading">
          <b-alert show class="with-icon w-100" variant="warning">
            <b-icon aria-hidden="true" icon="alert-triangle"/>
            {{ $t('candidate.addProcessFlowNoProcessesWarning') }}
          </b-alert>
        </template>
        <candidate-process-details-inputs
            :coordinatorUsers="locationUsers || []"
            :hiringManagerUsers="locationUsers || []"
            :recruiterUsers="locationUsers || []"
            :candidateDueDate.sync="localCandidateDueDate"
            :dueDate.sync="localDueDate"
            :recruiterUserId.sync="localRecruiterUserId"
            :hiringManagerUserId.sync="localHiringManagerUserId"
            :coordinatorUserId.sync="localCoordinatorUserId"
        />
      </template>
    </template>
  </div>
</template>

<script>
import CandidateProcessDetailsInputs from 'candidates/candidate-process-details-inputs.vue';
import candidatesManagementService from "api/candidatesManagementService";
import {mapGetters} from 'vuex';
import {mapPropsForSync} from 'common/form-helpers';
import dateFns from "date-fns";
import debounce from 'lodash.debounce';
import MultiSelect from 'common/components/multi-select.vue'

export default {
  name: 'candidate-process-inputs',
  components: {
    CandidateProcessDetailsInputs,
    MultiSelect
  },
  props: {
    locationId: {
      type: String,
      required: false
    },
    processId: {
      type: String,
      required: false
    },
    candidateDueDate: {
      type: Date,
      required: false,
      default: null
    },
    dueDate: {
      type: Date,
      required: false,
      default: null
    },
    recruiterUserId: {
      type: String,
      required: false,
      default: null
    },
    hiringManagerUserId: {
      type: String,
      required: false,
      default: null
    },
    coordinatorUserId: {
      type: String,
      required: false,
      default: null
    },
    isLocationRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['clientId']),
    ...mapPropsForSync('local', [
      'locationId',
      'processId',
      'candidateDueDate',
      'dueDate',
      'recruiterUserId',
      'hiringManagerUserId',
      'coordinatorUserId',
    ]),
    hasSelectedLocation() {
      return this.localLocationId;
    },
    hasLocationOptions() {
      return this.locationOptions && this.locationOptions.length;
    },
    hasProcessOptions() {
      return this.processOptions && this.processOptions.length;
    }
  },
  methods: {
    getSelectableProcessOptions(options) {
      // Filter out the non-selectable (inactive) process flows
      return !options ? []
          : options.filter(processFlow => {
            return processFlow.isSelectable;
          });
    },
    setLocalLocation(location) {
      this.localLocation = location
      this.localLocationId = location.id
    },
     doSearch(searchData) {
       this.locationOptions=this.originallocationoptions.filter(item => {       
        if(item.name.toLowerCase().includes(searchData.searchValue.toLowerCase())){         
          return item;
        }      
      })           
    },
    reloadClearedFilters(){      
      if(this.originallocationoptions !=undefined)
      this.locationOptions= this.originallocationoptions;
    },
  },
  data() {
    return {
      loadingCreateInfo: true,
      locationOptions: null,
      processOptions: null,
      locationLoading: false,
      locationUsers: [],
      localLocation: null     
    }
  },
  watch: {
    clientId: {
      immediate: true,
      handler: async function (newval, oldval) {
        if (newval !== oldval) {
          this.loadingCreateInfo = true;
          try {
            const response = await candidatesManagementService.getProcessFlowCreateInfo(newval);

            this.locationOptions = response.locations;
            this.originallocationoptions = response.locations;
            if (this.localCandidateDueDate === null) {
              this.localCandidateDueDate = dateFns.parse(response.defaultCandidateDueDate);
            }
            if (this.localDueDate === null) {
              this.localDueDate = dateFns.parse(response.defaultDueDate);
            }
          } finally {
            this.loadingCreateInfo = false;
          }
        }
      }
    },
    'localLocationId': {
      handler: debounce(async function (newval, oldval) {
        if (newval !== oldval) {
          const locationSelected = newval !== null;

          if (locationSelected) {
            try {
              this.locationLoading = true;
              const response = await candidatesManagementService.getLocation(this.clientId, newval);
              // The localLocationId can change frequently, and the async requests to the candidatesManagementService
              // may finish out-of-order. If the currently selected locationId doesn't match what this invocation of the
              // watcher fired on, discard the results. We assume that a different watcher invocation will run to completion
              if (this.localLocationId !== newval) return;

              this.processOptions = this.getSelectableProcessOptions(response.processFlows);
              this.locationUsers = response.users.filter(user => user.isSelectable);

              const idExistsInList = function (list, id) {
                return list.find(listItem => listItem.id === id);
              }

              const selectedProcessExists = idExistsInList(this.processOptions, this.localProcessId);
              const selectedRecruiterExists = idExistsInList(this.locationUsers, this.localRecruiterUserId);
              const selectedHiringManagerExists = idExistsInList(this.locationUsers, this.localHiringManagerUserId);
              const selectedCoordinatorExists = idExistsInList(this.locationUsers, this.localCoordinatorUserId);

              if (!selectedProcessExists) {
                this.localProcessId = null;
              }

              if (!selectedRecruiterExists) {
                this.localRecruiterUserId = null;
              }

              if (!selectedHiringManagerExists) {
                this.localHiringManagerUserId = null;
              }

              if (!selectedCoordinatorExists) {
                this.localCoordinatorUserId = null;
              }
            } finally {
              this.locationLoading = false;
            }
          } else {
            this.processOptions = null;
            this.locationUsers = null;
            this.localProcessId = null;
            this.localRecruiterUserId = null;
            this.localHiringManagerUserId = null;
            this.localCoordinatorUserId = null;
          }
        }
      }, 250, {leading: true, trailing: true}) // debounce options
    }
  }
}
</script>
