<template>
    <span>
        <ag-grid-vue style="width: 100%; height: 92%;" @grid-ready="onGridReady" :rowModelType="rowModelType"
                    :serverSideInfiniteScroll="true" :pagination="true" :paginationPageSize="paginationPageSize"
                    :cacheBlockSize="cacheBlockSize" :animateRows="true" class="ag-theme-alpine" :rowClassRules="rowClassRules"
                    :onFilterChanged="onFilterChanged" :columnDefs="columnDefs" :defaultColDef="defaultColDef"
                    :suppressContextMenu="false" :getContextMenuItems="getContextMenuItems" :suppressMenuHide="true"
                    :getRowId="getRowId" :sideBar="sideBar" :rowSelection="rowSelection"
                    :serverSideFilterAllLevels="true"
                    :getChildCount="getChildCount" @first-data-rendered='onFirstDataRendered'>
                </ag-grid-vue>

        <complete-action :actionItem="currentActionItem" :detailsRequest="currentActionItem.instance"
            @action-item-details-loaded="showCompleteActionModal()" @action-item-status-changed="actionItemStatusChanged()"
            @closed="clearCurrentActionItemData()" />
      
    </span>
</template>


<script>
import "ag-grid-enterprise";
import { AgGridVue } from "ag-grid-vue";
import ActionCenterTaskOnlyService from "./action-center-task-only.service";
import * as _ from "lodash";
import ActionButton from './action-button.vue';
import CompleteAction from './complete-action-modal.vue';
import ActionType from "./actionType";

let gridApi = null;
let gridColumnApi = null;
let latestRequestParams = null;

function resetFilterSortState() {
    const columnState = {
        state: [
            {
                colId: 'dueDate',
                sort: 'asc'
            }
        ]
    }
    gridColumnApi.applyColumnState(columnState);
    gridApi.setFilterModel(null); //clears all filters  
}

export default {
    name: "action-center-base-task-only-user",
    data() {
        return {
            columnDefs: null,
            rowData: null,
            defaultColDef: null,
            filter: false,
            rowModelType: 'serverSide',
            paginationPageSize: ActionCenterTaskOnlyService.getPageSize(),
            cacheBlockSize: ActionCenterTaskOnlyService.getPageSize(),
            startRow: 0,
            endRow: ActionCenterTaskOnlyService.getPageSize(),
            sideBar: null,
            rowSelection: null,
            getChildCount: null,
            getRowId: null,
            rowClass: '',
            rowClassRules: {
                'due-date-expired': function (params) {
                    if (params.data) {
                        var dueDate = new Date(params.data.dueDate);
                        var today = new Date();

                        if (dueDate < today) {
                            return true;
                        }
                    }
                    return false;
                },
            },
            currentActionItem: {
                instance: null,
                actionType: ActionType.ACTION_TYPE_TASK_UI_SIDE
            },
        };
    },
    props: {
        dataUrl: {
            type: String,
            required: true
        },
        clearFilter: { type: Date, required: false },
    },
    created() {
        this.setDefaultColDef();
        this.rowSelection = 'multiple';
        this.rowModelType = 'serverSide';
        this.getChildCount = (data) => {
            return data ? data.childCount : undefined;
        };

        this.columnDefs = this.getColumnDefs();
        this.getRowId = (params) => {
            //Report API will add unique identifier to RowId property to use here
            return params.data.rowId;
        };
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressValues: true
                    }
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                }
            ],
            position: 'right',
            defaultToolPanel: ''
        };
        this.$watch('clearFilter', () => { resetFilterSortState() });

    },
    methods: {
        async onGridReady(params) {
            gridApi = params.api;
            gridColumnApi = params.columnApi;

            resetFilterSortState();
            gridApi.sizeColumnsToFit(params)

            await this.loadDataSource();
        },
        async loadDataSource() {
            const updateData = async (data) => {
                // create datasource with a reference to the service
                var datasource = await this.createActionCenterDatasourceTaskOnly(ActionCenterTaskOnlyService);
                // register the datasource with the grid
                gridApi.setServerSideDatasource(datasource);
            };

            updateData([]);
        },
        async createActionCenterDatasourceTaskOnly(service) {
            return {
                getRows: async (params) => {
                    latestRequestParams = params.request; //wasn't sure how else to get this from gridapi
                    // get data for request
                    await service.getDataTaskOnly(params.request).then((response) => {
                        params.success({
                            rowData: response.data || [],
                            rowCount: response.totalCount,
                        });
                    });
                },
            };
        },
        async handleDetailsLoaded() {
            await this.loadDataSource();
        },
        async handleUpdateError() {
            await this.loadDataSource();
        },

        onFilterChanged(params) {
            const setFilterEName = params.api.getFilterInstance('candidateName');
            setFilterEName.refreshFilterValues();
            const setFilterProcessFlow = params.api.getFilterInstance('processFlowName');
            setFilterProcessFlow.refreshFilterValues();
            const setFilterLocation = params.api.getFilterInstance('locationName');
            setFilterLocation.refreshFilterValues();
            const setFilterActivityType = params.api.getFilterInstance('activityType');
            setFilterActivityType.refreshFilterValues();
            const setFilterAssignedToName = params.api.getFilterInstance('assignedToName');
            setFilterAssignedToName.refreshFilterValues();
            const setFilterDueDate = params.api.getFilterInstance('dueDate');
            setFilterDueDate.refreshFilterValues();
        },
        async onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        },
        setDefaultColDef: function () {
            this.defaultColDef = ActionCenterTaskOnlyService.getDefaultColumnDef();
        },

        setCurrentActionItemData: function (actionUrl, taskType) {
            this.currentActionItem.instance = {
                method: 'GET',
                url: actionUrl
            };

            this.currentActionItem.actionType = taskType;
        },
        async clearCurrentActionItemData() {
            this.currentActionItem.instance = null;
            this.currentActionItem.actionType = ActionType.ACTION_TYPE_TASK_UI_SIDE;

            await this.loadDataSource();
        },
        async onCompleteActionBtnClick(data) {
            var actionUrl = '';

            if (data.activityType == ActionType.ACTION_TYPE_TASK_SERVER_SIDE) {
                actionUrl = await ActionCenterTaskOnlyService.getCandidateTaskUrl(data.candidateId, data.subjectRequisitionId, data.activityId);

                this.setCurrentActionItemData(actionUrl, ActionType.ACTION_TYPE_TASK_UI_SIDE);
            }
        },
        async actionItemStatusChanged() {
            this.hideCompleteActionModal();

            await this.loadDataSource();
        },
        hideCompleteActionModal() {
            this.$root.$emit("bv::hide::modal", "complete-action-modal");
        },
        showCompleteActionModal() {
            this.$root.$emit("bv::show::modal", "complete-action-modal");
        },

        getContextMenuItems() {
            var result = [
                'copy',
                'copyWithHeaders',
            ];
            return result;
        },
        getColumnDefs() {
            var colDefs = [
                {
                    field: 'candidateName',
                    headerName: 'Employee Name',
                    headerTooltip: 'Employee Name',
                    minWidth: 180,
                    ...ActionCenterTaskOnlyService.buildTextFilterParams({ field: 'candidateName' }),
                },
                {
                    field: 'processFlowName',
                    headerName: 'Process Flow',
                    headerTooltip: 'Process Flow',
                    minWidth: 140,
                    ...ActionCenterTaskOnlyService.buildSetFilterParams({ field: 'processFlowName' })
                },
                {
                    field: 'locationName',
                    headerName: 'Location',
                    headerTooltip: 'Location',
                    minWidth: 140,
                    ...ActionCenterTaskOnlyService.buildSetFilterParams({ field: 'locationName' })
                },
                {
                    field: 'activityName',
                    headerName: 'HR Activity',
                    headerTooltip: 'HR Activity',
                    minWidth: 140,
                    ...ActionCenterTaskOnlyService.buildSetFilterParams({ field: 'activityName' })
                },
                {
                    field: 'assignedToName',
                    headerName: 'Assigned To',
                    headerTooltip: 'Assigned To',
                    minWidth: 140,
                    ...ActionCenterTaskOnlyService.buildSetFilterParams({ field: 'assignedToName' })
                },
                {
                    field: 'dueDate',
                    headerName: 'Due Date',
                    headerTooltip: 'Due Date',
                    maxWidth: 140,
                    valueFormatter: (params) => (params.value == ' ' ? 'No Date' : formatDate(params.value)),
                    ...ActionCenterTaskOnlyService.buildDateFilterParams()
                },
                {
                    field: 'activityType',
                    headerName: 'Action',
                    headerTooltip: 'Action',
                    filter: false,
                    sortable: false,
                    cellRenderer: 'ActionButton',
                    minWidth: 150,
                    maxWidth: 170,
                    cellRendererParams: {
                        clicked: this.onCompleteActionBtnClick.bind(this),
                    },
                    enableRowGroup: false,
                }
            ];

            return colDefs;
        },
    },
    components: {
        AgGridVue,
        ActionButton,
        CompleteAction,
    }
};


</script>
<style lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

.due-date-expired {
    color: red;
}
</style>

