<template>
  <section v-if="localElements && localModel">
    <savage-form-fields-elements
      :elements="localElements"
      :model="localModel"
      :dateLocale="dateLocale"
      :formState="formState"
      :onUpdateModel="onUpdateModel"
      :onUpdateModelProperty="onUpdateModelProperty" />
  </section>
</template>
<script>
import SavageFormFieldsElements from "common/savage/savage-form-fields-elements.vue";
import clonedeep from "lodash.clonedeep";
import isequal from "lodash.isequal";

export default {
  name: "savage-form-fields",
  components: {
    SavageFormFieldsElements
  },
  props: {
    elements: {
      type: Array,
      required: true
    },
    model: {
      type: Object,
      required: false,
      default: () => ({})
    },
    dateLocale: {
      type: String,
      required: true
    },
    formState: {
      type: Object,
      required: true
    }
  },
  watch: {
    model: {
      immediate: true,
      deep: true,
      handler(val) {
        this.localModel = clonedeep(val);
      }
    },
    elements: {
      immediate: true,
      deep: true,
      handler(val) {
        this.localElements = clonedeep(val)
      }
    }
  },
  methods: {
    onUpdateModel (val) {
      this.localModel = val;
      this.emitUpdate(this.localModel);
    },
    onUpdateModelProperty (datafield, val) {
      this.$set(this.localModel, datafield, val);
      this.emitUpdate(this.localModel);
    },
    emitUpdate(val) {
      this.$emit("update", val);
    }
  },
  data() {
    return {
      localModel: null,
      localElements: null
    };
  }
};
</script>
