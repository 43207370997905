<template>
    <b-modal modal-class=""
             size="lg"
             ok-only
             id="personal-information-modal"
             centered
             static
             title="Personal Information">
        <div class="">
            <table class="fullscreen">
                <tr>
                    <td><b>{{ $t('common.firstName') }}</b></td>
                    <td>{{ firstName }}</td>
                </tr>
                <tr>
                    <td><b>{{ $t('common.middleName') }}</b></td>
                    <td>{{ middleName }}</td>
                </tr>
                <tr>
                    <td><b>{{ $t('common.lastName') }}</b></td>
                    <td>{{ lastName }}</td>
                </tr>
                <tr>
                    <td><b>{{ $t('common.emailAddress') }}</b></td>
                    <td>{{ email }}</td>
                </tr>
                <tr>
                    <td><b>{{ $t('common.phoneNumber') }}</b></td>
                    <td>{{ phone }}</td>
                </tr>
                <tr>
                    <td><b>{{ $t('common.ssoIdentifier') }}</b></td>
                    <td>{{ ssoIdentifier }}</td>
                </tr>
                <tr v-if="showSourceSystemId">
                    <td><b>{{ $t('common.sourceSystemId') }}</b></td>
                    <td>{{ sourceSystemId }}</td>
                </tr>
            </table>
        </div>
    </b-modal>
</template>

<script>
    import AppSettings from 'appSettings';

    export default {
        name: "personal-information",
        props: {
            firstName: {
                type: String,
            },
            middleName: {
                type: String,
            },
            lastName: {
                type: String,
            },
            email: {
                type: String,
            },
            phone: {
                type: String,
            },
            ssoIdentifier: {
                type: String,
            },
            sourceSystemId: {
                type: String,
            },
            showSourceSystemId: {
                type: Boolean,
            }
        },
        created () {
            this.showSourceSystemId = AppSettings.sourceSystemIdFeatureToggle === "true";
        },
        computed: {
            
        },
        methods: {
            
        },
    };
</script>

<style>
    .fullscreen {
        min-width: 100%;
        min-height: 100%;
    }
</style>