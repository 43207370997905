<template>
  <div class="action-center-task-todo-list">
    <div class="action-center-taskList-title-container">
      <span class="action-center-taskList-title">{{$t("actionCenter.task.todoList.title")}}</span>
    </div>
    <div class="action-center-task-todo-container" v-for="(todo, index) in todos" :key="index" >
      <action-center-task-todo class="action-center-task-todo" :todo="todo" v-on:todo-status-changed="handleTodoStatusChanged"/>
    </div>
  </div>
</template>

<script>
import ActionCenterTaskTodo from "action-center/task/action-center-task-todo.vue";

export default {
  name: "action-center-task-todo-list",
  components: {
    ActionCenterTaskTodo
  },
  props: {
    todos: {
      type: Array,
      required: true
    }
  },
  methods: {
    handleTodoStatusChanged(todoStatusChange) {
      this.$emit('task-changed', {
        todoStatusChange: todoStatusChange,
        isFinalTodoCompletion: this.isFinalTodoCompletion(todoStatusChange)
      });
    },
    isFinalTodoCompletion(todoStatusChange) {
      const allOtherTodos = this.todos.filter(todo => todo !== todoStatusChange.todo);
      return todoStatusChange.newStatus && allOtherTodos.every(todo => todo.completed);;
    }
  }
};
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.action-center-task-todo-list {
  display: flex;
  flex-direction: column;
  border: solid 1px $cb-light-grey-1;
  background-color: $cb-white;

  .action-center-task-todo-container {
    border-top: solid 1px $cb-light-grey-1;
    border-bottom: solid 1px $cb-light-grey-1;

    //these prevent the top/bottom todos doubling up the border
    //from the container
    margin-top: -1px;
    margin-bottom: -1px;

    .action-center-task-todo {
        border-left: 4px solid $cb-white;

      &:hover {
        background-color: $cb-light-blue-6;
        border-left: 4px solid $cb-brand-blue-1;
      }
    }
  }
}

.action-center-taskList-title-container {
    @include font-size-content-root;
    padding: 1rem .5rem;
    font-weight: bold;
}

.action-center-taskList-title {
    padding-left: 3.5rem;
}
</style>