<template>
  <savage-form-field-wrapper
    :label="label"
    :fieldName="name"
    :disabled="disabled"
    :validation="supportedValidation"
    :customValidation="enrichedCustomValidation">
      <savage-autosuggest-container ref="savageAutosuggestContainerInstance"
          class="savage-autosuggest-container"
          :name="name" v-model="localValue"
          v-bind="supportedValidationAttributes">
        <vue-autosuggest
          ref="vueAutoSuggestInstance"
          class="savage-autosuggest"
          :suggestions="wrappedFilteredSuggestions"
          :inputProps="autoSuggestInputProps"
          @selected="onOptionSelected"
          @keydown.tab="event => { inferSelectionAndClose(event.target.value) }"
          @focus="showSuggestions()"
          @blur="reEnableAutoComplete()"
          :componentAttrIdAutosuggest="autoSuggestComponentId"
          :componentAttrClassAutosuggestResultsContainer="'savage-autosuggest-results-container'"
          :componentAttrClassAutosuggestResults="'savage-autosuggest-results'">
        </vue-autosuggest>
      </savage-autosuggest-container>
  </savage-form-field-wrapper>
</template>
<script>
import 'common/polyfills/object.assign.polyfill';
import 'common/polyfills/smoothscroll.polyfill';
import { SavageFormFieldWrapper, SavageFormFieldMixin } from '@clickboarding/savage';
import { VueAutosuggest } from 'vue-autosuggest';
import uuid from 'uuid/v4';

export default {
  name: 'savage-form-autosuggest',
  components: {
    SavageFormFieldWrapper,
    VueAutosuggest,
    // renderless component to ensure validation library applies dirty state for scenarios
    // where we are validating in memory values rather than a traditional input
    'savage-autosuggest-container': {
      name: 'savage-autosuggest-container',
      props: ['name', 'value'],
      render(createElement) {
        return createElement('div', this.$slots.default);
      }
    }
  },
  mixins: [
    SavageFormFieldMixin
  ],
  props: {
    items: {
      type: Array,
      required: true,
    },
    maxItems: {
      type: Number,
      required: false,
      default: 5
    },
    defaultValue: {
      required: false,
      default: null
    }
  },
  created () {
    this.allowedValidationTypes = [ 'required' ];
    this.autoSuggestComponentId = uuid(); // used for container element of the autosuggest (used in the library for ARIA attributes)

    const initialValue = this.localValue || this.defaultValue;

    const currentItem = this.items.filter(item => {
      return item.value === initialValue;
    }).shift();

    this.initialText = currentItem ? currentItem.label : null;
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler (items) {
        this.suggestions = items.map(item => {
          return item.label;
        });
      }
    },
    value: {
      handler (newval) {
        const newItem = this.items.filter(item => {
          return item.value === newval;
        }).shift();

        let text = null;

        if (newItem) {
          text = newItem.label;
        } else if (!this.typedText) {
          text = "";
        }

        if (text !== null) {
          this.$refs.vueAutoSuggestInstance.searchInputOriginal = text;
          this.$refs.vueAutoSuggestInstance.searchInput = text;
        }
      }
    }
  },
  computed: {
    autoSuggestInputProps () {
      const classes = this.computedFieldClass || {};
      classes['input-text']  = true;

      return Object.assign({}, this.supportedValidationAttributes, {
        id: uuid(), // used for input id
        placeholder: this.computedPlaceholder,
        class: classes,
        name: this.name,
        title: this.computedTitle,
        disabled: this.disabled,
        onInputChange: this.onInputChange,
        initialValue: this.initialText,
        // We want the browser autocomplete feature to be disabled when we are showing
        // our suggestion results because ours and the browsers render at the the same time
        // in the same spot below the input. The vue-autosuggest library sets autocomplete to "off"
        // by default, so we override this to "on" in our initial options. According to spec, you
        // can disable the autocomplete with the value "no", but Chrome ignores this.
        // We use the value "nope" to fully disable (essentially an invalid value) as suggested
        // on StackExchange and in the MDN documentation for the autocomplete attribute:
        // https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion.
        // "nope" gets applied whenever we show our suggestions, and then reverted back to "on"
        // on blur.
        autocomplete: 'on',
        // Chrome has shown performance issues when putting large amounts of text in an input type text.
        // While we really don't care much what ends up on the text field since it doesn't reflect the value
        // being bound to, this is is a loose protection against the performance issue with the thought
        // that it is very unlikely we would ever display output larger than this for a selection
        maxlength: 400
      });
    },
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    wrappedFilteredSuggestions () {
      return [{ data: this.filteredSuggestions }];
    }
  },
  data () {
    return {
      suggestions: [],
      filteredSuggestions: [],
      typedText: null
    };
  },
  methods: {
    setValueByText (text) {
      text = text || "";

      let exactMatchIndex = null;
      const exactMatch = this.suggestions.filter((suggestion, index) => {
        const isExactMatch = suggestion.trim().toLowerCase() === text.trim().toLowerCase();

        if (isExactMatch) exactMatchIndex = index;

        return isExactMatch;
      }).shift();

      // exactMatchIndex of 0 is valid, so explicitly check if not null
      if (exactMatchIndex !== null) {
        this.typedText = null;
        this.localValue = this.items[exactMatchIndex].value;
      } else {
        this.typedText = text;
        this.localValue = null;
      }
    },
    onOptionSelected (suggestion) {
      if (suggestion) {
        this.setValueByText(suggestion.item)
      }
    },
    onInputChange (text, oldText) {
      if (text === oldText) return;

      this.filterSuggestions (text);
      this.setValueByText(text);
    },
    filterSuggestions (text) {
      text = text || this.$refs.vueAutoSuggestInstance.searchInput || "";
      this.filteredSuggestions = this.suggestions.filter(suggestion => {
          return suggestion.trim().toLowerCase().indexOf(text.trim().toLowerCase()) > -1;
        }).slice(0, this.maxItems);
    },
    async inferSelectionAndClose (text) {
      text = text || "";

      // if filtered to one result, infer they want that suggestion text
      if (this.filteredSuggestions.length === 1) {
        text = this.filteredSuggestions[0];

        // vue-autosuggest internal mechanism to set the text in the field
        // is to set searchInputOriginal and searchInput
        this.$refs.vueAutoSuggestInstance.searchInputOriginal = text;

        const searchInputHasChanged = this.$refs.vueAutoSuggestInstance.searchInput !== text;

        this.$refs.vueAutoSuggestInstance.searchInput = text;

        if (!searchInputHasChanged) {
          // if searchInput has not changed, watcher will not trigger in
          // vue-autosuggest and onInputChange will not fire
          this.onInputChange(text);
        } else {
          // if searchInput has changed, watcher will trigger in
          // vue-autosuggest and onInputChange will fire
          await this.$nextTick();
        }
      } else if (text !== "") {
        // call setValueByText to keep data in sync with inferred selection
        this.setValueByText(text);
      }

      // empty array to close suggestions
      this.filteredSuggestions = [];
    },
    showSuggestions () {
      this.$refs.savageAutosuggestContainerInstance.$emit('focus');
      this.$el.querySelector('.input-text').setAttribute('autocomplete', 'nope');

      // workaround for not having an API to manually open/close the results
      // per open issue https://github.com/Educents/vue-autosuggest/issues/52
      this.$refs.vueAutoSuggestInstance.loading = false;
      this.filterSuggestions();
    },
    reEnableAutoComplete () {
      this.$refs.savageAutosuggestContainerInstance.$emit('blur');
      this.$el.querySelector('.input-text').setAttribute('autocomplete', 'on');
    }
  }
}
</script>
