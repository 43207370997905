<template>
  <section class="cb-legal-terms">
    <h3>{{title}}</h3>
    <div v-html="terms"></div>
  </section>
</template>

<script>
export default {
  name: 'cb-legal-terms',
  props: {
    title: {
      type: String,
      required: false
    },
    terms: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .cb-legal-terms {
    background: $cb-light-grey-5;
    border-top: 1px solid $cb-dark-grey-6;
    border-bottom: 1px solid $cb-dark-grey-6;
    padding: 1rem 1.5rem;
    margin: .5rem 0 1.5rem;
    line-height: 1.5rem;

    h3 {
      @include font-size-content-root;
      font-weight: bold;
      margin: .5rem 0;
    }

    div {
      @include font-size-content-root;
      padding-top: .5rem;
    }
  }
</style>
