
import VideoIcon from 'forms/video.svg';
import HtmlIcon from 'forms/html.svg';
import ImageIcon from 'forms/image.svg';

function get (element) {
  const elementType = element.type;

  const mapping = {
    'Video': VideoIcon.id,
    'Html': HtmlIcon.id,
    'Image': ImageIcon.id,
  };

  if (!(elementType in mapping)) {
    console.error('Unexpected element type.');
  }

  return `#${mapping[elementType]}`;
};

export { get }