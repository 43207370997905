<template>
    <section class="forms-add-route vertical-flex">
        <cb-view-header class="vertical-flex-min" v-if="!selectedFormTypeEnum" :title="headerTitle" :return-link="returnLink"></cb-view-header>

        <cb-view-section class="vertical-flex-fill" type="primary" v-if="!selectedFormTypeEnum" :includePrimaryMessages="true">
          <cb-view-section type="secondary">
            <cb-grid-selector v-if="formTypes" :label="formTypesLabel" :items="formTypes" :onSelection="selectType">
              <cb-grid-selector-item-content slot="item-content" slot-scope="{ item }" :item="item" :iconNameProvider="getIconName" />
            </cb-grid-selector>
          </cb-view-section>
        </cb-view-section>

        <FormsContent
          class="vertical-flex-fill"
          v-if="selectedFormTypeEnum && selectedFormTypeEnum === 'Content'"
          :data-url="selectedFormTypeDataUrl"
          :on-back="onBack" />

        <FormsPolicyAgreement
          class="vertical-flex-fill"
          v-if="selectedFormTypeEnum && selectedFormTypeEnum === 'PolicyAgreement'"
          :data-url="selectedFormTypeDataUrl"
          :on-back="onBack" />

        <FormsDocumentUpload 
          class="vertical-flex-fill"
          v-if="selectedFormTypeEnum && selectedFormTypeEnum === 'DocumentUpload'"
          :data-url="selectedFormTypeDataUrl"
          :on-back="onBack" />

        <FormsDataEntry 
          class="vertical-flex-fill"
          v-if="selectedFormTypeEnum && selectedFormTypeEnum === 'DataEntry'"
          :data-url="selectedFormTypeDataUrl"
          :on-back="onBack" />
    </section>
</template>
<script>
import AppSettings from 'appSettings';
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import FormsContent from 'forms/forms-content.vue';
import FormsPolicyAgreement from 'forms/forms-policy-agreement.vue';
import FormsDocumentUpload from 'forms/forms-document-upload.vue';
import FormsDataEntry from 'forms/forms-data-entry.vue';
import CbGridSelector from 'common/components/cb-grid-selector.vue';
import CbGridSelectorItemContent from 'common/components/cb-grid-selector-item-content.vue';

export default {
    name: 'forms-add-route',
    components: {
        CbViewHeader,
        CbViewSection,
        FormsContent,
        FormsPolicyAgreement,
        FormsDocumentUpload,
        FormsDataEntry,
        CbGridSelector,
        CbGridSelectorItemContent
    },
    props: {
      dataUrl: String,
      required: true
    },
    mounted () {
      this.$http.get(this.dataUrl).then((response) => {
        this.formTypesLabel = response.body.formTypes.title;
        this.formTypes = response.body.formTypes.items;
        this.headerTitle = response.body.title;
        this.returnLink = {
          label: response.body.return.label,
          handler: () => {
            this.$_cb.router.changeView(AppSettings.viewForms, response.body.return.url);
          }
        };
      });
    },
    methods: {
      onBack () {
        this.selectedFormType = null;
      },
      selectType (form) {
        this.selectedFormType = form;
      },
      getIconName (item) {
        const mapping = {
          'Content': 'Form-Type-Content-Icon',
          'PolicyAgreement': 'Form-Type-Policy-Agreement-Icon',
          'DocumentUpload': 'Form-Type-Document-Upload-Icon',
          'DataEntry': 'Form-Type-Data-Entry-Icon'
        };

        return mapping[item.typeEnum];
      }
    },
    computed: {
      selectedFormTypeDataUrl () {
        return this.selectedFormType ? this.selectedFormType.url : null;
      },
      selectedFormTypeEnum () {
        return this.selectedFormType ? this.selectedFormType.typeEnum : null;
      }
    },
    data () {
      return {
        headerTitle: null,
        formTypesLabel: null,
        formTypes: null,
        selectedFormType: null,
        returnLink: null
      };
    }
}
</script>
<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    .forms-add-route {
      @include font-size-reset;
      @include box-sizing-reset;
    }
</style>
