import 'angular-agility-0.8.23/angular-agility'; //provides 'aa.formExtensions' and 'aa.notify' modules

import AppSettingsModule from 'common/app-settings.module';
import CandidatesModule from 'candidates/candidates.module'
import DashService from 'dashboard/dashboardService';
import cbCardCommon from 'dashboard/cards/card.directive';
import candidateCard from 'dashboard/cards/candidateCard';
import dashboardCard from 'dashboard/dashboardCard';
import sleepCard from 'dashboard/cards/sleepCard';
import dashboard from 'dashboard/dashboard';
import CbPageCallout from 'general/cb-page-callout.vue';

export default angular.module('dashboard-module', [AppSettingsModule, CandidatesModule, 'aa.notify'])
	.factory('DashService', DashService)
	.directive('cbCardCommon', cbCardCommon)
	.directive('candidateCard', candidateCard)
	.directive('dashboardCard', dashboardCard)
	.directive('sleepCard', sleepCard)
	.directive('dashboard', dashboard)
	.value('cb-page-callout', CbPageCallout)
	.name;