<template>
  <draggable class="cb-list" tag="ul" :value="localItems" v-bind="$_reorderOptions" @end="$_onReorderComplete"
    :class="{ 'reorderable': canReorder }">
    <li class="cb-list-item" v-for="(item, index) in localItems" :key="index">
      <div class="cb-list-item-content" :class="{ 'cb-draggable': item.canReorder, 'cb-undraggable': !item.canReorder }">
        <slot name="icon" :item="item.original">
          <svg v-if="iconNameProvider">
            <use v-bind="{ 'xlink:href': getIconPath(item.original) }"></use>
          </svg>
        </slot>
        <div class="cb-list-item-label" :class="{ 'cb-list-item-label-single-line': useSingleLineLabels }">
          <slot :item="item.original" />
        </div>
      </div>
      <div class="cb-list-item-action" v-if="hasActionSlot">
        <slot name="action" :item="item.original" :index="index" />
      </div>
    </li>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable';
import { IconProvider } from 'common/icon.module';
import uuid from 'uuid/v4';

export default {
  name: 'cb-list',
  components: {
    draggable
  },
  beforeCreate() {
    // establish a unique groupId for reordering so that elements can't be
    // dragged to other cb-list instances. If that behavior is needed, this
    // can be overriden via the reorderOptions group property.
    this.groupId = uuid();
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    iconNameProvider: {
      type: Function,
      required: false
    },
    useSingleLineLabels: {
      type: Boolean,
      required: false,
      default: false
    },
    canReorder: {
      type: Boolean,
      required: false,
      default: false
    },
    canReorderItem: {
      type: Function,
      required: false
    },
    onReorderComplete: {
      type: Function,
      required: false
    }
  },
  methods: {
    getIconPath(item) {
      return IconProvider.getPath(this.iconNameProvider(item));
    },
    async $_onReorderComplete(event) {
      this.reorderInProgress = true;

      //management of this list is done here rather than
      //v-model on draggable to prevent possible race conditions
      let newLocalItems = this.localItems.slice();
      let itemToMove = newLocalItems.splice(event.oldIndex, 1)[0];
      newLocalItems.splice(event.newIndex, 0, itemToMove);
      this.localItems = newLocalItems;

      if (this.onReorderComplete) {

        const eventData = {
          oldIndex: event.oldIndex,
          newIndex: event.newIndex,
          item: itemToMove.original
        };

        await this.onReorderComplete(eventData);
      }
      this.reorderInProgress = false;
    }
  },
  computed: {
    hasActionSlot() {
      return !!this.$scopedSlots['action'];
    },
    localItems: {
      get() {
        return this.items.map((item) => {
          return {
            original: item,
            canReorder: this.canReorder && (!this.canReorderItem || this.canReorderItem(item))
          };
        })
      },
      set(val) {
        let emitVal = val.map(item => {
          return item.original;
        });
        this.$emit('update:items', emitVal);
      }
    },
    $_reorderOptions() {
      return {
        disabled: !this.canReorder || this.reorderInProgress,
        animation: 100,
        group: this.groupId,
        ghostClass: 'cb-list-item-reorder-ghost',
        chosenClass: 'cb-list-item-reorder-chosen',
        handle: '.cb-list-item-content',
        filter: '.cb-list-item-content.cb-undraggable'
        // Options documentation info here: https://github.com/SortableJS/Sortable#options
        // Using the filter option here as opposed to the draggable option. This
        // allows items in a step to be moved above, below or in between undraggable items
        // in that step to maintain current behavior. Right now, the form activity types
        // cannot be added to sealed steps so sealed items will not be blocked by this
        // behavior.
      };
    }
  },
  data() {
    return {
      reorderInProgress: false
    };
  }
}
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

ul.cb-list {
  padding: 0;
  margin: 0;

  li.cb-list-item {
    display: flex;
    flex-direction: row;
    padding: .75rem 1.5rem;
    border-bottom: 1px solid $cb-light-grey-1;

    &:first-of-type {
      border-top: 1px solid $cb-light-grey-1;
    }

    &.cb-list-item-reorder-chosen {
      background: $cb-teal-6;
      box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.25);
      position: relative;
      top: -2px;
      left: 0;
      border: 1px solid $cb-teal-2;
    }

    &.cb-list-item-reorder-ghost {
      opacity: .8;
    }

    .cb-list-item-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1 1 auto;
      // Flex items get a min-width of 'auto'
      // by default, which doesn't allow the content
      // to become smaller than it's actual size
      // setting min-width to 0 allows that to happen
      // so we can hide overflow and apply the ellipsis
      // https://www.w3.org/TR/css-flexbox-1/#min-size-auto
      min-width: 0px;

      /deep/ svg {
        height: 3rem;
        width: 3rem;
        padding-right: 1rem;
        flex-shrink: 0;
      }

      .cb-list-item-label {
        flex-grow: 1;
        overflow: auto;
        word-break: break-all;

        &.cb-list-item-label-single-line {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .cb-list-item-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 auto;
      padding-left: 1rem;
    }
  }
}

ul.cb-list.reorderable {
  .cb-list-item-content.cb-draggable {
    cursor: move;
    -webkit-user-select: none;
    user-select: none;
  }

  .cb-list-item-content.cb-undraggable {
    cursor: not-allowed;
    -webkit-user-select: none;
    user-select: none;
  }
}

// When nested in a cb-view-section.secondary or cb-view-sub-section,
// use negative margin to get the borders to go full-width
.cb-view-section.secondary>ul.cb-list,
.cb-view-sub-section>ul.cb-list {
  margin-left: -$standard-cb-view-padding;
  margin-right: -$standard-cb-view-padding;

  &:not(:first-child) {
    padding-top: $standard-cb-view-padding;
  }

  &:not(:last-child) {
    padding-bottom: $standard-cb-view-padding;
  }
}
</style>
