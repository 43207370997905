import BulkImportsBaseComponent from 'bulk-imports/bulkImportsbase.vue';
import BulkImportsSpecsComponent from 'bulk-imports/bulk-imports-specs.vue'
import BulkImportsUpdateSpecsComponent from 'bulk-imports/bulk-imports-update-specs.vue'
import BulkImportsController from 'bulk-imports/bulk-imports.controller';

export default angular
    .module('bulk-imports-module', [])
    .controller('bulkImportsController', BulkImportsController)
    .value('bulk-imports-specs', BulkImportsSpecsComponent)
    .value('bulk-imports-update-specs', BulkImportsUpdateSpecsComponent)
    .value('bulk-imports-base', BulkImportsBaseComponent).name;
