export default class CandidateSpecs {
    constructor() {
        this.specs = [
            {
                field: 'Email Address',
                dataType: 'Text',
                required: 'Yes',
                description:
                    "Candidate's email address. Work email is recommended. Email must be unique.",
                validValues: 'Text up to 50 characters.',
            },
            {
                field: 'First Name',
                dataType: 'Text',
                required: 'Yes',
                description: "Candidate's first name.",
                validValues:
                    'Text up to 35 characters. Cannot contain html characters.',
            },
            {
                field: 'Last Name',
                dataType: 'Text',
                required: 'Yes',
                description: "Candidate's last name.",
                validValues:
                    'Text up to 35 characters. Cannot contain html characters.',
            },
            {
                field: 'Middle Name',
                dataType: 'Text',
                required: 'No',
                description:
                    "Candidate's middle name.",
                validValues:
                    'Text up to 35 characters. Cannot contain html characters.',
            },
            {
                field: 'Phone Number',
                dataType: 'Numeric',
                required: 'No',
                description: "Candidate's Phone Number.",
                validValues:
                    '10-20 numbers without any formatting. For example - 7775556584.',
            },
            {
                field: 'SSO Identifier',
                dataType: 'Text',
                required: 'No',
                description:
                    'Used to identify a user if using SAML SSO.',
                validValues:
                    'Text up to 100 characters. Cannot contain html characters.',
            },
            {
                field: 'Source System Identifier',
                dataType: 'Text',
                required: 'No',
                description:
                    'Unique client provided ID Number.',
                validValues:
                    'Text up to 100 characters. Cannot contain html characters.',
            },
        ];
    }
}
