import store from '../store'
import appSettings from "../appSettings";

const ReportingTypes = ['ProcessFlow', 'Forms', 'Candidates'];
function baseUrl() {
    let clientId = store.getters.clientId;
    if (!clientId) {
        throw new Error(
            "The clientId parameter is required."
        );
    }
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Reporting/`;
}

export default {
    async getReportDataUrl(reportType) {
        ValidateReportType(reportType)
        return `${baseUrl()}${reportType}`;
    },
    async getFilteredListByColumn(reportType, colName) {
        ValidateReportType(reportType);
        return `${baseUrl()}${reportType}/filterList/${colName}`;
    },
    async getExport(reportType, uri, downloadType) {
        ValidateReportType(reportType);
        return `${baseUrl()}${reportType}/${uri}/${downloadType}`;
    },
    async getColumnDescriptions(reportType) {
        ValidateReportType(reportType);
        return `${baseUrl()}ColumnDescriptions/${reportType}`;
    },
}

function ValidateReportType(reportType) {
    if (!ReportingTypes.includes(reportType)) {
        throw new Error($`Invalid report type value of param reportType: ${reportType}`);
    }
}


