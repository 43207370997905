<template>
  <b-modal
    v-model="modalShow"
    :title="modalTitle"
    :ok-title="data.saveLabel"
    centered
    static
    lazy
    size="lg"
    no-close-on-backdrop
    footer-class="add-user-footer"
    @ok="handleOk"
    @cancel="$emit('cancel')"
    @close="$emit('close')"
    @hide="handleHide"
  >
    <cb-primary-message></cb-primary-message>
    <b-form class="mb-2" @submit.stop.prevent="saveAndClose">
      <h2 class="text-left" style="border-bottom: solid 1px #ccc">
        {{ data.personal.title }}
      </h2>
      <div class="labelContent">
        <div class="d-flex">
          <div class="w-50 mr-2">
            <b-form-group>
              <template v-slot:label>
                {{ data.personal.firstNameLabel }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                tabindex="1"
                required
                class="first-name"
                :disabled="editItem && !editItem.canEdit.firstName"
                :state="validateState('firstName')"
                v-model="$v.model.firstName.$model"
              />
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ data.personal.usernameLabel }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                tabindex="3"
                class="user-name"
                required
                :state="validateState('userName')"
                :disabled="editItem && !editItem.canEdit.username"
                v-model="$v.model.userName.$model"
              />
            </b-form-group>
            <b-form-group v-if="showSourceSystemId">
              <template v-slot:label>
                {{ data.personal.sourceSystemIdLabel }}
              </template>
              <b-form-input
                tabindex="5"
                class="source-system-id"
                :disabled="editItem && !editItem.canEdit.sourceSystemId"
                :state="validateState('sourceSystemId')"
                v-model="$v.model.sourceSystemId.$model"
              />
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ editSettings.accessLevelSetting.label }}
                <span class="text-danger">*</span>
              </template>
              <b-form-select
                tabindex="7"
                class="access-level"
                required
                :disabled="editItem && !editItem.canEdit.accessLevel"
                :state="validateState('accessLevel')"
                :options="[
                  { value: '', text: 'Select an access level...' },
                  ...editSettings.accessLevelSetting.accessLevels.map(
                    (accessLevel) => {
                      return { value: accessLevel.id, text: accessLevel.name };
                    }
                  ),
                ]"
                v-model="$v.model.accessLevel.$model"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="w-50 ml-2">
            <b-form-group>
              <template v-slot:label>
                {{ data.personal.lastNameLabel }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                tabindex="2"
                class="last-name"
                required
                :disabled="editItem && !editItem.canEdit.lastName"
                :state="validateState('lastName')"
                v-model="$v.model.lastName.$model"
              />
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ data.personal.emailLabel }}
                <span class="text-danger">*</span>
              </template>
              <b-form-input
                tabindex="4"
                class="email"
                required
                :disabled="editItem && !editItem.canEdit.emailAddress"
                :state="validateState('email')"
                v-model="$v.model.email.$model"
              />
            </b-form-group>
            <b-form-group>
              <template v-slot:label>
                {{ data.personal.ssoIdentifierLabel }}
              </template>
              <b-form-input
                tabindex="6"
                class="sso-identifier"
                :disabled="editItem && !editItem.canEdit.ssoIdentifier"
                :state="validateState('ssoIdentifier')"
                v-model="$v.model.ssoIdentifier.$model"
              />
            </b-form-group>
          </div>
        </div>
      </div>

      <h2 class="text-left" style="border-bottom: solid 1px #ccc">
        {{ editSettings.title }}
      </h2>
      <div class="labelContent">
        <div class="d-flex">
          <div class="w-50 mr-2">
            <b-form-group>
              <template v-slot:label>
                {{ editSettings.brandSetting.label }}
                <span class="text-danger">*</span>
              </template>
              <b-form-select
                tabindex="8"
                required
                class="brand"
                :disabled="editItem && !editItem.canEdit.brand"
                :state="validateState('brand')"
                :options="[
                  {
                    value: '',
                    text: editSettings.brandSetting.defaultOptionLabel,
                  },
                  ...editSettings.brandSetting.brands.map((brand) => {
                    return { value: brand.id, text: brand.name };
                  }),
                ]"
                v-model="$v.model.brand.$model"
              >
              </b-form-select>
            </b-form-group>
          </div>
        </div>
        <div class="d-flex">
          <div class="w-100">
            <div class="d-flex align-items-baseline">
              <multi-select
                v-if="canEditLocationGroups"
                tabindex="9"
                class="assign-location-group"
                :options="editSettings.locationGroupSetting.locationGroups"
                :value="selectedLocationGroup"
                track-by="id"
                search-by="name"
                placement="bottomright"
                :search-placeholder="
                  $t('common.filterSearchPlaceholder', [
                    $t('locations.searchLocationGroupPlaceholder'),
                  ])
                "
                :close-on-select="true"
                :title="editSettings.locationGroupSetting.label"
                @select="selectLocationGroup($event)"
                @newSearchValue="doSearch"
                @clickAwayOnSearch="reloadClearedFilters('')"
              >
                <template v-slot:option="{ option }">
                  {{ option.name }}
                </template>
              </multi-select>
              <b-button
                v-if="canEditLocationGroups"
                class="add-location-group ml-3 small-button"
                tabindex="10"
                variant="primary"
                @click="assignLocationGroup(selectedLocationGroup)"
                >{{ $t("common.add") }}
              </b-button>
              <div
                class="d-flex flex-wrap"
                :class="{ 'ml-3': canEditLocationGroups }"
              >
                <p
                  v-if="!canEditLocationGroups"
                  class="col-form-label font-weight-bold"
                >
                  {{ $t("locations.assignedToLocations") }}
                </p>
                <div
                  v-for="(locationGroup, index) in model.locationGroups"
                  class="location-group-tag"
                  :title="locationGroup.name"
                  :key="locationGroup.id"
                >
                  <span>
                    {{ locationGroup.name }}
                  </span>
                  <b-button
                    v-if="canEditLocationGroups"
                    class="close"
                    variant="close"
                    @click="removeLocationGroup(index)"
                  ></b-button>
                </div>
              </div>
            </div>
            <b-form-checkbox
              v-if="Boolean(!editItem)"
              tabindex="11"
              class="send-email mt-3 pl-3"
              v-model="model.sendEmail"
            >
              {{ editSettings.sendEmailLabel }}
            </b-form-checkbox>
          </div>
        </div>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import AppSettings from 'appSettings';
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import CbPrimaryMessage from "../components/cb-primary-message";
import MultiSelect from "common/components/multi-select.vue";
import cloneDeep from "lodash.clonedeep";
import { mapGetters} from 'vuex';

export default {
  name: "user-add",
  mixins: [validationMixin],
  components: { CbPrimaryMessage, MultiSelect },
  props: {
    data: {
      required: true,
      type: Object,
    },
    editItem: {
      type: Object,
    },
    editSettings: {
      type: Object,
    },
    showSourceSystemId: {
      type: Boolean,
      required: false
    }
  },
  created () {
    this.showSourceSystemId = AppSettings.sourceSystemIdFeatureToggle === "true";
  },
  data() {
    return {
      modalShow: true,
      selectedLocationGroup: null,
      model: {
        firstName: "",
        lastName: "",
        userName: "",
        email: "",
        accessLevel: "",
        locationGroups: [],
        brand: "",
        sendEmail: false,
        ssoIdentifier: "",
        sourceSystemId: "",
      },
      validationMessages: {
        required: this.$t("common.validation.required"),
      },
    };
  },
  validations() {
    return {
      model: {
        firstName: {
          required,
          maxLength: maxLength(100),
        },
        lastName: {
          required,
          maxLength: maxLength(100),
        },
        userName: {
          required,
          maxLength: maxLength(254),
        },
        email: {
          required,
          email,
          maxLength: maxLength(50),
        },
        ssoIdentifier: {
          maxLength: maxLength(200),
        },
        sourceSystemId: {
          maxLength: maxLength(200),
        },
        accessLevel: {
          required,
        },
        brand: {
          required,
        },
      },
    };
  },
  computed: {
    modalTitle() {
      if (this.editItem) {
        return this.data.editTitle;
      } else {
        return this.data.addTitle;
      }
    },
    canEditLocationGroups() {
      return Boolean(!this.editItem || this.editItem.canEdit.locationGroups);
    },
  },
  methods: {
    selectLocationGroup(item) {
      this.selectedLocationGroup = item;
    },
    doSearch(searchData) {        
      this.originallocationoptions = this.originallocationoptions == undefined ? this.editSettings.locationGroupSetting.locationGroups: this.originallocationoptions;
       this.editSettings.locationGroupSetting.locationGroups=this.originallocationoptions.filter(item => {       
        if(item.name.toLowerCase().includes(searchData.searchValue.toLowerCase())){         
          return item;
        }      
      })           
    },
    reloadClearedFilters(){      
      if(this.originallocationoptions !=undefined)
      this.editSettings.locationGroupSetting.locationGroups= this.originallocationoptions;
    },
    assignLocationGroup(item) {
      if (item) {
        const exists = this.model.locationGroups.find(
          (locationGroup) => item.id === locationGroup.id
        );

        if (!exists) {
          this.model.locationGroups.push(item);
        }

        this.selectedLocationGroup = null;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.model[name];
      return $dirty ? !$error : null;
    },
    removeLocationGroup(index) {
      this.model.locationGroups.splice(index, 1);
    },
    handleHide(bvModelEvent) {
      if (bvModelEvent.trigger !== "ok") {
        // hide happen on esc, but it also happens whenever the window gets
        // closed (e.g., hitting the ok button), ok is handled differently (saveAndClose)
        // don't forward this event in that scenario
        this.$emit("hide");
      }
    },
    setData() {
      return {
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        userName: this.model.userName,
        emailAddress: this.model.email,
        accessLevelId: this.model.accessLevel,
        locationGroups: this.model.locationGroups.map((item) => item.id),       
        brandId: this.model.brand,
        sendEmail: this.model.sendEmail,
        ssoIdentifier: this.model.ssoIdentifier,
        sourceSystemId: this.model.sourceSystemId
      };
    },
    async handleOk(bvModelEvent) {
      // according to the docs (https://bootstrap-vue.js.org/docs/components/modal#prevent-closing), async isn't supported
      // so always prevent the default and then handle closing it ourselves if needed
      bvModelEvent.preventDefault();

      await this.saveAndClose();
    },
    async saveAndClose() {
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }

      let url, method;
      if (this.editItem) {
        url = this.editItem.edit.url;
        method = "put";
      } else {
        url = this.data.add.url;
        method = "post";
      }

      const { data } = await this.$http[method](url, this.setData());
      this.$emit("ok", data);
      this.modalShow = false;
    },
  },
  mounted() {
    if (this.editItem) {
      this.model = cloneDeep(this.editItem);
      this.$set(this.model, "firstName", this.editItem.firstName);
      this.$set(this.model, "lastName", this.editItem.lastName);
      this.$set(this.model, "userName", this.editItem.userName);
      this.$set(this.model, "email", this.editItem.emailAddress);
      this.$set(this.model, "accessLevel", this.editItem.accessLevelId);
      this.$set(this.model, "brand", this.editItem.brandId);
      this.$set(this.model, "sendEmail", this.editItem.sendEmail);
      this.$set(this.model, "ssoIdentifier", this.editItem.ssoIdentifier);
      this.$set(this.model, "sourceSystemId", this.editItem.sourceSystemId);
      
      if (this.editSettings.locationGroupSetting.locationGroups) {
        this.model.locationGroups = this.editItem.locationGroups
          ? this.editSettings.locationGroupSetting.locationGroups.filter(
              function (locationGroup) {
                return this.indexOf(locationGroup.id) > -1;
              },
              this.editItem.locationGroups
            )
          : [];          
      }
    }
  },
};
</script>
<style scoped lang="scss">
.assign-location-group::v-deep {
  width: 100%;
  max-width: 250px;

  .multiselect-picker-button {
    width: 100%;
    max-width: 250px;
  }
}
</style>
