export default {
  VALIDATION_READY: 1060,
  VALIDATION_STARTED: 1061,
  VALIDATION_FAILED: 1062,
  VALIDATION_COMPLETE: 1063,
  VALIDATION_COMPLETE_WITH_ERRORS: 1064,
  IMPORT_READY: 1065,
  IMPORT_STARTED: 1066,
  IMPORT_FAILED: 1067,
  IMPORT_COMPLETE: 1068,
  IMPORT_COMPLETE_WITH_ERRORS: 1069
}