<template>
  <section class="splash-route" v-if="apiData">
    <cb-splash
      :title="apiData.title"
      :message="apiData.message"
      :icon="iconType">

      <div class="buttons">
        <button class="cb-btn secondary back" @click="goBack()">
          {{apiData.data.returnAction.label}}
        </button>
        <button class="cb-btn secondary publish" :disabled="publishDisabled" @click="publishDraft()" >
          <svg>
            <use v-bind="{'xlink:href': publishIcon }" ></use>
          </svg>
          {{apiData.data.publishAction.label}}
        </button>
        <div class="h-line"></div>
        <button class="cb-btn primary edit" @click="editDraft()">
          {{apiData.data.editAction.label}}
        </button>
      </div>
    </cb-splash>
  </section>
</template>

<script>
import AppSettings from 'appSettings';
import CbSplash from 'common/components/cb-splash.vue';
import FormDraftIcon from 'common/assets/icons/forms/form-draft.svg';
import FormArchiveIcon from 'common/assets/icons/forms/form-archive.svg';
import PublishIcon from 'common/assets/icons/forms/published.svg';

export default {
  name: 'forms-splash-route',
  components: {
    CbSplash
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    iconType () {
      let icon;
      switch (this.pageType) {
        case 'DraftCreated':
          icon = '#' + FormDraftIcon.id
          break;
        case 'DraftUpdated':
          icon = '#' + FormDraftIcon.id
          break;
      }
      return icon;
    },
    publishDisabled(){
      return this.isPublishing;
    }
  },
  async created () {
    const response = await this.$http.get(this.dataUrl);
    this.apiData = response.body;
    this.pageType = response.body.type
  },
  methods: {
    async publishDraft () {
      try {
        this.isPublishing = true;
        await this.$http.put(this.apiData.data.publishAction.url);
        this.$_cb.router.changeView(AppSettings.viewForms, this.apiData.data.returnAction.url);
      }
      finally {
        this.isPublishing = false;
      }
    },
    goBack () {
      this.$_cb.router.changeView(AppSettings.viewForms, this.apiData.data.returnAction.url);
    },
    editDraft () {
      this.$_cb.router.changeView(AppSettings.viewFormsEdit, { type: this.apiData.data.type }, this.apiData.data.editAction.url);
    }
  },
  data () {
    return {
      apiData: null,
      pageType: null,
      publishIcon: '#' + PublishIcon.id,
      isPublishing: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';

  .splash-route {
    padding: 1rem 2rem 2rem;
  }

  .buttons .cb-btn {
    width: 100%;

    &.back {
      margin-bottom: 1rem;
    }

    &.publish svg {
      width: 1rem; height: 1rem;
      fill: $cb-brand-blue-1;
      margin-right: .25rem;
    }
  }
</style>
