import { getByType } from 'forms/data-entry-mappers/element-types/all';
import clonedeep from 'lodash.clonedeep';

function map (designerElement) {
  const modelElement = clonedeep(designerElement.availablePresentations[designerElement.defaultPresentationKey]);
  
  const elementType = getByType(modelElement.type);
  const childElementsKey = elementType && elementType.getChildElementsKey ? elementType.getChildElementsKey() : null;

  if (childElementsKey && modelElement[childElementsKey]) {
    modelElement[childElementsKey] = modelElement[childElementsKey].map(element => map(element));
  }
  
  return modelElement;
} 

export { map }