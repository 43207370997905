<template>
  <nav :aria-label="$t('common.breadcrumb')">
    <ol class="cb-breadcrumbs-list">
      <template v-for="(item, index) in items">
        <li :key="index" class="cb-breadcrumbs-item">
          <a v-if="item.link" href="#" @click.prevent="item.link" :aria-current="ariaCurrent(index)">{{item.label}}</a>
          <span v-else :aria-current="ariaCurrent(index)">{{item.label}}</span>
          <span v-if="!isLast(index)" class="cb-breadcrumbs-separator" aria-hidden="true">&rsaquo;</span>
        </li>
      </template>
    </ol>
  </nav>
</template>
<script>
export default {
  name: 'cb-breadcrumbs',
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  methods: {
    isLast (index) {
      return index === this.items.length - 1;
    },
    ariaCurrent (index) {
      return this.isLast(index) ? "location" : "false";
    }
  },
}
</script>
<style lang="scss" scoped>
  .cb-breadcrumbs-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap:  wrap;
  }

  .cb-breadcrumbs-item {
    // Prevent separator from wrapping independent of anchor;
    // flex vs inline prevents browser rendering extra whitespace
    // between anchor and span
    display: flex;
    flex-wrap: nowrap;
  }

  .cb-breadcrumbs-separator {
    // If anchor text wraps, make separator look like it wraps also
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    // Spacing between separator and text
    padding: 0 .5rem;
  }

</style>
