<template>
    <section>
        <div v-if="selectedView === 'specs'">
            <ImportsSpecs v-on:back="switchView" />
        </div>
        <div v-if="selectedView === 'grid'">
            <cb-admin-view-title class="cb-admin-view-title import-title-margin" title="Bulk Imports">
                <div class="ml-auto candidate-heading-buttons">
                    <b-button class="ml-auto" variant="green" @click="switchView('specs')">
                        View Import Specifications
                    </b-button>
                </div>
            </cb-admin-view-title>
            <div class="text-muted instructions">
                <div>
                    Use this page to bulk import data into the Admin Portal. View the Import Specifications to locate
                    the appropriate import template and upload your completed file below.
                </div>
                <a href="https://clickboarding.zendesk.com/hc/en-us/articles/13508453647123-Bulk-Imports-Overview"
                    target="_blank" style="color: #0aa991 !important;font-weight: bold;">
                    Learn more...
                </a>
            </div>
            <section class="cb-view-section pt-0 uploadAndGrid">
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-12">
                            <vue-dropzone ref="myVueDropzone" id="dropzone" v-on:vdropzone-file-added="sendingEvent"
                                :options="dropzoneOptions" :useCustomSlot="true">
                                <div class="dropzone-custom-content">
                                    <div class="subtitle">
                                        <div class="dropzone-custom-title">
                                            <i class="fa fa-cloud-upload"></i>
                                        </div>
                                        <div class="subtitle pt-2">
                                            Choose a CSV file to upload or drag
                                            and drop it here.
                                        </div>
                                    </div>
                                </div>
                            </vue-dropzone>
                        </div>
                    </div>
                    <div class="row pt-5">
                        <div class="col-sm-12">
                            <h2>Import History</h2>
                            <div class="text-muted">
                                After uploading a file it will automatically be
                                validated and the results will be shown below to
                                review before importing.
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="candidate-filters">
                                <b-button class="clear-filter-button small-button ml-auto" variant="outline-green"
                                    @click="clearFilters">
                                    Clear Filters
                                </b-button>
                                <b-button class="clear-filter-button small-button" variant="outline-green"
                                    @click="onRefresh">
                                    <span v-show="loading" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                    <i class="fa fa-refresh" v-show="!loading"></i>
                                    Refresh
                                </b-button>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <ag-grid-vue @grid-ready="onGridReady" class="ag-theme-alpine agGridSizing"
                                :columnDefs="columnDefs" :rowData="rowData" :pagination="true"
                                :paginationPageSize="paginationPageSize" :enableBrowserTooltips="true"
                                :animateRows="true" :sideBar="sideBar" :onFilterChanged="onFilterChanged"
                                :serverSideInfiniteScroll="true" :defaultColDef="defaultColDef"
                                :suppressContextMenu="false" :getContextMenuItems="getContextMenuItems"
                                :suppressMenuHide="true" :rowSelection="rowSelection"
                                :autoGroupColumnDef="autoGroupColumnDef" :getServerSideGroupLevelParams="
                                    getServerSideGroupLevelParams
                                " :serverSideFilterAllLevels="true" :getChildCount="getChildCount" :getRowId="getRowId"
                                @first-data-rendered="onFirstDataRendered">
                            </ag-grid-vue>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</template>
<script>
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import CbViewSection from 'general/cb-view-section.vue';
import ActionMenu from 'common/components/action-menu.vue';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import appSettings from 'appSettings';
import { AxiosLocal as axios } from 'common/services/axiosLocal.service';
import { mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue';
import ImportButton from './import-button.vue';
import LogButton from './log-button.vue';
import importsService from './imports.service';
import ImportFileType from './importFileType';
import ImportsSpecs from './importspecs.vue';

export default {
    name: 'imports-base',
    components: {
        CbAdminViewTitle,
        CbViewSection,
        ActionMenu,
        vueDropzone: vue2Dropzone,
        AgGridVue,
        ImportButton,
        LogButton,
        ImportsSpecs,
    },
    created() {
        this.setDefaultColDef();
        this.rowSelection = 'multiple';
        this.rowGroupPanelShow = 'always';
        this.rowModelType = 'serverSide';
        this.rowData = this.importData;

        this.getRowId = (params) => {
            //Report API will add unique identifier to RowId property to use here
            return params.data.importId;
        };
        this.sideBar = {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressValues: true,
                    },
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
            position: 'right',
        };
        this.$watch('clearFilter', () => {
            resetFilterSortState();
        });
    },
    data() {
        return {
            columnDefs: null,
            rowData: null,
            importData: null,
            loading: false,
            defaultColDef: null,
            filter: true,
            rowModelType: 'serverSide',
            paginationPageSize: importsService.getPageSize(),
            cacheBlockSize: importsService.getPageSize(),
            startRow: 0,
            endRow: importsService.getPageSize(),
            sideBar: null,
            rowSelection: null,
            rowGroupPanelShow: null,
            autoGroupColumnDef: null,
            getServerSideGroupLevelParams: null,
            getChildCount: null,
            getRowId: null,
            dropzoneOptions: {
                url: '/TBD',
                thumbnailWidth: 150,
                maxFilesize: 0.5,
                headers: { 'My-Awesome-Header': 'header value' },
                autoProcessQueue: false,
            },
            selectedView: 'grid',
            columnState: {
                state: [
                    {
                        colId: 'uploadDate',
                        sort: 'desc',
                    },
                ],
            },
        };
    },
    beforeMount() {
        const self = this;
        self.columnDefs = [
            {
                field: 'typeName',
                headerName: 'Import Type',
                headerTooltip: 'Import Type',
                tooltipField: 'typeName',
                maxWidth: 150,
            },
            {
                field: 'action',
                headerTooltip: 'Action',
                cellRenderer: 'ImportButton',
                minWidth: 118,
                maxWidth: 150,
                cellRendererParams: {
                    clicked: async function (field) {
                        const putUrl = `${appSettings.apiBaseUrl}Clients/${self.clientId
                            }/Sections/Imports/${field.data.type}`;
                        await importsService.putImport(putUrl, {
                            importId: field.data.importId,
                        });

                        self.loadImports();
                        self.startTimer();

                        self.$bvToast.toast(
                            `Import Started. Check back here later to see results or use the Refresh button.`,
                            {
                                title: self.$t('common.success'),
                                autoHideDelay: 8000,
                                variant: 'success',
                            }
                        );
                    },
                },
                filter: false,
            },
            {
                field: 'fileName',
                headerName: 'File Name',
                headerTooltip: 'File Name',
                tooltipField: 'fileName',
            },
            {
                field: 'statusName',
                headerName: 'Status',
                headerTooltip: 'Status',
                tooltipField: 'statusName',
                maxWidth: 255,
            },
            {
                field: 'records',
                headerName: 'Records',
                headerTooltip: 'Records',
                tooltipField: 'records',
                maxWidth: 120,
            },
            {
                field: 'validationErrors',
                headerName: 'Validation Errors',
                headerTooltip: 'Validation Errors',
                tooltipField: 'validationErrors',
                cellStyle: function (params) {
                    if (params.value > 0) {
                        return { color: 'red' };
                    } else {
                        return null;
                    }
                },
                maxWidth: 175,
            },
            {
                field: 'processingErrors',
                headerName: 'Processing Errors',
                headerTooltip: 'Processing Errors',
                tooltipField: 'processingErrors',
                cellStyle: function (params) {
                    if (params.value > 0) {
                        return { color: 'red' };
                    } else {
                        return null;
                    }
                },
                maxWidth: 175,
            },
            {
                field: 'userName',
                headerName: 'Uploaded By',
                headerTooltip: 'Uploaded By',
                tooltipField: 'userName',
            },
            {
                field: 'importId',
                hide: true,
                filter: false,
                suppressColumnsToolPanel: true,
                sort: 'desc',
            },
            {
                field: 'uploadDate',
                headerName: 'Date',
                headerTooltip: 'Date',
                tooltipField: 'uploadDate',
                comparator: (valueA, valueB) => {
                    if (valueA == valueB) return 0;
                    return new Date(valueA).getTime() >
                        new Date(valueB).getTime()
                        ? 1
                        : -1;
                },
                maxWidth: 190,
            },
            {
                field: 'validationResults',
                headerName: 'Validation Results',
                headerTooltip: 'Validation Results',
                cellStyle: { textAlign: 'center' },
                cellRenderer: 'LogButton',
                cellRendererParams: {
                    clicked: async function (field) {
                        self.$bvToast.toast(
                            self.$t(
                                'Please wait for result file to be downloaded'
                            ),
                            {
                                title: self.$t('common.success'),
                                autoHideDelay: 5000,
                                variant: 'success',
                            }
                        );
                        const url = `${appSettings.apiBaseUrl}Clients/${self.clientId
                            }/Sections/Imports/${field.data.importId}/FileType/${ImportFileType.VALIDATION
                            }`;
                        const response = await axios.get(url);
                        const type = response.headers['content-type'];
                        const blob = new Blob([response.data], {
                            type: type,
                            encoding: 'UTF-8',
                        });
                        const link = document.createElement('a');
                        const fileName = field.data.fileName.substring(
                            0,
                            field.data.fileName.indexOf('.')
                        );
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${fileName}_ValidationResult.csv`;
                        link.click();
                    },
                },
                maxWidth: 180,
                filter: false,
            },
            {
                field: 'importReport',
                headerName: 'Import Report',
                headerTooltip: 'Import Report',
                cellStyle: { textAlign: 'center' },
                cellRenderer: 'LogButton',
                cellRendererParams: {
                    clicked: async function (field) {
                        self.$bvToast.toast(
                            self.$t(
                                'Please wait for result file to be downloaded'
                            ),
                            {
                                title: self.$t('common.success'),
                                autoHideDelay: 5000,
                                variant: 'success',
                            }
                        );
                        const url = `${appSettings.apiBaseUrl}Clients/${self.clientId
                            }/Sections/Imports/${field.data.importId}/FileType/${ImportFileType.IMPORTRESULT
                            }`;
                        const response = await axios.get(url);
                        const type = response.headers['content-type'];
                        const blob = new Blob([response.data], {
                            type: type,
                            encoding: 'UTF-8',
                        });
                        const link = document.createElement('a');
                        const fileName = field.data.fileName.substring(
                            0,
                            field.data.fileName.indexOf('.')
                        );
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${fileName}_ImportResult.csv`;
                        link.click();
                    },
                },
                maxWidth: 160,
                filter: false,
            },
        ];

        this.loadImports();
    },
    computed: {
        ...mapGetters(['clientId']),
        offset() {
            return 0;
        },
        limit() {
            return 1000;
        },
    },
    methods: {
        startTimer() {
            clearInterval(this.timer);
            let timesRun = 0;
            const self = this;
            this.timer = setInterval(function () {
                timesRun += 1;
                if (timesRun === 120) {
                    clearInterval(this.timer);
                }

                self.loadImports();
            }, 5000);
        },
        async onRefresh() {
            await this.loadImports();
        },
        clearFilters() {
            this.gridApi.setFilterModel(null);
            this.gridApi.onFilterChanged();
        },
        switchView(view) {
            this.selectedView = view;
        },
        async sendingEvent(file, xhr, formData) {
            const file_size = (file.size / 1024 / 1024).toFixed(2);
            if (file_size > 3.5) {
                this.$refs.myVueDropzone.removeAllFiles();
                this.showErrorToast(
                    `The file size is larger than the acceptable size (3.5mb)`,
                    'File Size Exceeded'
                );
                return;
            }

            const extension = file.name
                .split('.')
                .pop()
                .toLowerCase();
            const allowedExtensions = ['csv'];
            if (!allowedExtensions.includes(extension)) {
                this.$refs.myVueDropzone.removeAllFiles();
                this.showErrorToast(
                    `Unsupported File: Only files with the following extension(s) are allowed: csv`,
                    'Unsupported File'
                );
                return;
            }

            const allowedPrefixes = [
                'candidate\_',
                'teammember\_',
                'locations\_',
            ];
            let importType;
            const isAllowed = allowedPrefixes.some((prefix) => {
                if (file.name.toLowerCase().startsWith(prefix)) {
                    importType = importsService.getImportTypeByName(
                        prefix.replace('\_', '')
                    );
                    return true;
                }
                return false;
            });
            if (!isAllowed) {
                this.$refs.myVueDropzone.removeAllFiles();
                this.showErrorToast(
                    `Unsupported File Name: Bulk Import files must use a prefix to match the import type. Example Candidate_Filename.csv. Click on Learn More… for additional support.`,
                    'Unsupported File Name'
                );
                return;
            }

            const apiUrl = `${appSettings.apiBaseUrl
                }Clients/${this.clientId}/Sections/Imports/${importType}`;
            let config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Content-Disposition': `form-data; name=${file.name
                        }; filename=${file.name}`,
                },
            };

            const data = new FormData();
            data.append('file', file);

            const res = await axios.post(apiUrl, data, config);
            if (res.status == 200) {
                this.$refs.myVueDropzone.removeAllFiles();
                this.showSuccessToast(
                    `File ${file.name} was uploaded successfully!`
                );

                await this.loadImports();
                this.startTimer();
            }
        },
        async loadImports() {
            this.loading = true;
            this.importData = await importsService.loadImports(
                this.clientId,
                this.offset,
                this.limit
            );
            this.rowData = this.importData;
            this.loading = false;
        },
        showErrorToast(message, title) {
            this.$bvToast.toast(message, {
                title: title,
                autoHideDelay: 8000,
                variant: 'danger',
            });
        },
        showSuccessToast(message) {
            this.$bvToast.toast(message, {
                title: this.$t('common.success'),
                autoHideDelay: 8000,
                variant: 'success',
            });
        },
        getContextMenuItems() {
            return ['copy', 'copyWithHeaders'];
        },
        setDefaultColDef: function () {
            this.defaultColDef = {
                sortable: true,
                filter: true,
                resizable: true,
                enableRowGroup: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
            };
        },
        onFilterChanged(params) {
            const setFilterFname = params.api.getFilterInstance('fileName');
            setFilterFname.refreshFilterValues();
            const setFilterSname = params.api.getFilterInstance('statusName');
            setFilterSname.refreshFilterValues();
            const setFilterUname = params.api.getFilterInstance('userName');
            setFilterUname.refreshFilterValues();
            const setFilterUdate = params.api.getFilterInstance('uploadDate');
            setFilterUdate.refreshFilterValues();
            //if grouped reloadgrouping
        },
        async onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        },
        async onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.setRowData(this.rowData);
            if (this.gridOptions && this.gridOptions.api) {
                this.gridOptions.api.setFilterModel(null);
            }
        },
        resetFilterSortState() {
            this.gridColumnApi.applyColumnState(this.columnState);
            this.gridApi.setFilterModel(null); //clears all filters
        },
    },
    watch: {},
};
</script>
<style scoped lang="scss">
.candidate-filters {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &>button {
        margin-right: 1rem;
    }

    &>div:last-of-type {
        margin-right: 0;
    }

    .filter-group {
        display: block;
        flex-grow: 1;
        margin-right: 1rem;

        .filter-item {
            input::placeholder {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                color: #838383;
            }
        }
    }
}

.vue-dropzone {
    background: whitesmoke;
}

.dropzone-custom-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.dropzone-custom-title {
    margin-top: 0;
    font-size: 46px;
    color: #0aa991;
}

.subtitle {
    color: #314b5f;
}

.import-title-margin {
    margin-left: 5px;
}

.instructions {
    margin: -15px 0px 30px 30px;
}

.instructions > div {
    margin-bottom: 1rem;
}

.uploadAndGrid {
    margin-left: 15px;
    margin-right: 15px;
}

.agGridSizing {
    width: 100%;
    height: 400px;
}
</style>
