import "oclazyload"; // provides 'oc.lazyLoad'
import ngAnimate from "angular-animate";
import NgSortable from "ng-sortable-1.3.1/index";
import Unsupported from "Unsupported";
import "imageupload-1.0.7/src/imageupload"; //provides 'imageupload' module
import "momentjs/angular/angular-moment.js"; //provides 'angularMoment' module
import "angular-datepicker/index"; //provides 'datePicker' module

// CSS imports
import "app-css.module.js";
import "css/navigation.scss";
import "css/filter.scss";
import "css/pagination.scss";
import "css/checkbox.scss";
import "css/table.scss";
import "css/button.scss";
import "css/title.scss";
import "css/tag.scss";
import "css/multi-edit.scss";
import "common/css/components.scss";

import { IconProvider } from "common/icon.module";
import DeviceDetectorModule from "common/device-detector.module";
import "general/progress-error.module"; //provides 'angular-loading-bar' module
import AuthModule from "auth.module";
import CbModal from "general/cb-modal.vue";
import AppRoutingModule from "common/app-routing.module";
import HeaderModule from "header/header.module";
import VueNgRoutingModule from "common/modules/vue-ng-routing.module";
import VueNgAuthService from "vue-ng-auth-service.module";

import AppSettingsModule from "common/app-settings.module";
import AppRoutesModule from "appRoutes.module";
import CbAppController from "cbApp.controller";
import ViewController from "view.controller";
import BrandsModule from "brands/brands.module";
import CandidateFormsModule from "candidates/forms/candidate-forms.module";
import CandidatesModule from "candidates/candidates.module";
import LocationGroupsModule from "locations/locationGroups.module";
import DashboardModule from "dashboard/dashboard.module";
import GeneralModule from "general/general.module";
import LocationsModule from "locations/locations.module";
import ProcessesModule from "processes/processes.module";
import FormsModule from "forms/forms.module";
import ScreeningModule from "setup/screening.module";
import UsersModule from "users/users.module";
import CompaniesModule from "companies/companies.module";
import ErrorModal from "errors/error.vue";
import ErrorModule from "errors/errors.module";
import ActionCenterModule from "action-center/action-center-module";
import ActionCenterTaskOnlyUserModule from "action-center/action-center-task-only-user-module";
import DatafieldsModule from "datafields/datafields.module";
import ImportsModule from "imports/imports.module";
import BulkImportsModule from "bulk-imports/bulk-imports.module";

import ClickHeader from 'common/components/header/click-header.vue'

import cbMenuComponent from "general/cb-admin-menu.vue";
import CbFooterComponent from "layout/cb-footer.vue";
import AngularRouteWrapper from "routing/angular-route-wrapper.vue";

import AnalyticsModule from "app-insights-analytics.module";
import 'analytics/user-sign-out-event-handler';

import store from './store';
import ngVueModuleFactory from 'common/ngVue.moduleFactory';
const ngVueModule = ngVueModuleFactory({ additionalRootVueInstanceProps: { store }});

import bootstrapSetup from 'common/bootstrap/setup';
bootstrapSetup();

import { LicenseManager } from 'ag-grid-enterprise';

import './validation.setup';

Unsupported.handleUnsupported();

var app = angular
  .module("cbAdminApp", [
    "ngVue",
    "ngVue.plugins",
    "oc.lazyLoad",
    ngAnimate,
    "aa.formExtensions",
    "aa.notify",
    NgSortable,
    "imageupload",
    "angularMoment",
    "datePicker",
    DeviceDetectorModule,
    "angular-loading-bar",
    AuthModule,
    AppRoutingModule,
    HeaderModule,
    AppSettingsModule,
    AnalyticsModule,
    AppRoutesModule,
    BrandsModule,
    CandidateFormsModule,
    CandidatesModule,
    LocationGroupsModule,
    DashboardModule,
    DatafieldsModule,
    GeneralModule,
    LocationsModule,
    ProcessesModule,
    FormsModule,
    ScreeningModule,
    UsersModule,
    CompaniesModule,
    ErrorModule,
    ActionCenterModule,
    ActionCenterTaskOnlyUserModule,
    VueNgRoutingModule,
    ImportsModule,
    BulkImportsModule,
    VueNgAuthService,
    ngVueModule
  ])
  .controller("cbAppCtrl", CbAppController)
  .controller("viewCtrl", ViewController)
  .value("cb-admin-menu", cbMenuComponent)
  .value("cb-footer", CbFooterComponent)
  .value("click-header", ClickHeader)
  .value("error-modal", ErrorModal)
  .value("cb-modal", CbModal)
	.value("angular-route-wrapper", AngularRouteWrapper)
  .config([
    "$locationProvider",
    function($locationProvider) {
      $locationProvider.hashPrefix("");
    }
  ])
  .config([
    "$compileProvider",
    function($compileProvider) {
      $compileProvider.debugInfoEnabled(false);
    }
  ])
  .config(["cfpLoadingBarProvider", function(cfpLoadingBarProvider) {}])
  .run([
    "$rootScope",
    "$location",
    "HeaderService",
    "locationSvc",
    "appSettings",
    async function($rootScope, $location, HeaderService, locationSvc, appSettings) {
      // clear the dataUrl from the url so that the app can re-init from scratch when browser is refreshed
      $location.search("u", null);

      if (!HeaderService.hasHeaderUrl()) {
          await store.dispatch('auth/logout');
          locationSvc.changeView(appSettings.viewLogin);
      }

      IconProvider.loadIconSprite();
      
      LicenseManager.setLicenseKey(appSettings.agGridLicense);

      await store.dispatch("initializeFromSession");
    }
  ]);

//needed for plupload-angular deps
window.app = app;

export default app.name;
