<template>
  <section>
    <div>
      <b-form-group v-if="labels.providers.length > 1"
                    class="w-50">
        <template v-slot:label>
          {{ labels.providerLabel }}
          <span aria-hidden class="text-danger">*</span>
        </template>
        <b-form-select
            class="event-provider"
            required
            text-field="name"
            value-field="guid"
            :state="validateState('guid')"
            v-model="model.provider.guid"
            @change="handleProviderChange"
            :options="[{ guid: null, name: labels.defaultProvider} ,...labels.providers]"
        >
        </b-form-select>
      </b-form-group>
      <div v-else
           class="mb-3">
        <p class="col-form-label font-weight-bold">
          {{ labels.providerLabel }}
        </p>
        {{ labels.providers[0].name }}
      </div>
    </div>
    <div v-if="model.provider.screeningProviderGuid"
         class="w-50">
      <b-form-group>
        <template v-slot:label>
          {{ labels.packageNameLabel }}
          <span aria-hidden class="text-danger">*</span>
        </template>
        <b-form-select
            class="screening-package"
            required
            text-field="displayName"
            value-field="id"
            :state="validateState('screeningPackageId')"
            v-model="model.provider.screeningPackageId"
            @change="handlePackageChange"
            :options="[{ screeningPackageId: null, displayName: labels.defaultPackage} ,...providerPackages]"
        >
        </b-form-select>
      </b-form-group>
    </div>
    <b-button class="d-block ml-auto mr-auto"
              variant="primary"
              @click="saveEvent">
      {{ saveButtonTitle }}
    </b-button>
  </section>
</template>

<script>
import {validationMixin} from "vuelidate"
import {required} from "vuelidate/lib/validators"
import cloneDeep from "lodash.clonedeep";

export default {
  name: "event-screening",
  mixins: [validationMixin],
  props: {
    labels: {
      required: true,
      type: Object
    },
    labelsTriggers: {
      required: true,
      type: Object
    },
    editData: {
      type: Object
    }
  },
  data() {
    return {
      model: {
        provider: {
          guid: null,
          screeningProviderGuid: null,
          screeningPackageId: null
        },
        typeEnum: "InitiateScreening",
        description: "",
        trigger: {
          typeEnum: null
        }
      },
      providerPackages: null
    }
  },
  computed: {
    saveButtonTitle() {
      return this.editData ? this.$t('common.done') : this.$t('common.add')
    }
  },
  validations() {
    const validation = {
      model: {
        provider: {
          screeningPackageId: {
            required
          }
        }
      }
    }

    if (this.labels.providers.length > 1) {
      validation.model.provider.guid = {
        required
      }
    }

    return validation
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.model.provider[name]
      return $dirty ? !$error : null
    },
    handleProviderChange(guid) {
      this.model.provider.screeningProviderGuid = guid
      this.model.provider.screeningPackageId = null
      this.providerPackages = this.labels.providers.find(provider => provider.guid === guid).packages
    },
    handlePackageChange(id) {
      const providerPackage = this.providerPackages.find(item => item.id === id)
      this.model.description = providerPackage.displayName //description is the display name
      this.model.provider.screeningPackageId = providerPackage.id
    },
    resetModel() {
      this.model = {
        provider: {
          guid: null,
          screeningProviderGuid: null,
          screeningPackageId: null
        },
        typeEnum: "InitiateScreening",
        description: "",
        trigger: {
          typeEnum: null
        }
      }

      if (this.labels.providers.length === 1) {
        this.model.provider.screeningProviderGuid = this.labels.providers[0].guid
        this.providerPackages = this.labels.providers[0].packages
      }
    },
    saveEvent() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      this.$emit('saveEvent', this.model)
    }
  },
  watch: {
    editData: {
      handler(newValue) {
        if (newValue) {
          this.model = cloneDeep(newValue)
          if (this.labels.providers.length === 1) {
            this.model.provider.screeningProviderGuid = this.labels.providers[0].guid
          }
          this.model.provider.guid = this.model.provider.screeningProviderGuid
          this.providerPackages = this.labels.providers.find(provider => provider.guid === this.model.provider.screeningProviderGuid).packages
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    if (!this.editData) {
      this.resetModel()
    }
  }
}
</script>

<style scoped>

</style>
