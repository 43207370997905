<template>
  <section class="vertical-flex">
    <cb-view-header
      class="vertical-flex-min"
      :title="stepName" 
      :return-link="returnLink" 
    />

    <activity-type-select
      class="vertical-flex-fill"
      v-if="!selectedActivityType && activityTypes"
      :activityTypes="activityTypes"
      :onSelection="selectTypeAsync"
    />
    
    <template v-else-if="activityList">
      <activity-select
        :class="{'vertical-flex-fill': showSelect}"
        v-show="showSelect"
        :activities="activityList.activities"
        :activityType="selectedActivityTypeEnum"
        :stepIsCandidate="stepIsCandidate"
        v-model="selectedActivities"
        @activity-select-cancel="resetSelectedActivities"
        @activity-select-submit="activitySelectedAsync"
        @selected-activities-changed="selectedActivitiesChanged"
      >
        <div v-if="searchEnabled" class="search-box" slot="activity-filter">
          <cb-search-container @cb-search="searchAsync" ref="search" :search-term="searchTerm" :search-result="searchResult" />
        </div>
      </activity-select>

      <activity-edit
        :class="{'vertical-flex-fill': !showSelect}"
        v-show="!showSelect"
        v-model="selectedActivities"
        @activity-edit-cancel="activityEditCancel"
        @activity-edit-submit="addActivitiesAsync">
        <cb-view-section slot="activity-header" slot-scope="{activity}" class="activity-title" type="secondary" :withFlushTop="true">
          <h2>{{getDisplayName(activity)}}</h2>
        </cb-view-section>
      </activity-edit>
    </template>
  </section>
</template>
<script>
import CbViewHeader from "general/cb-view-header.vue";
import CbViewSection from "general/cb-view-section.vue";
import CbSearchContainer from "general/cb-search-container.vue";
import ActivityTypeSelect from 'processes/steps/activities/activity-type-select.vue';
import ActivitySelect from 'processes/steps/activities/activity-select.vue';
import ActivityEdit from 'processes/steps/activities/activity-edit.vue';
import { ActivityIcons, ActivityConfig } from 'processes/steps/activities/activityTypes.js';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "activity-add",
  components: {
    CbViewHeader,
    CbViewSection,    
    CbSearchContainer,
    ActivityTypeSelect,
    ActivitySelect,
    ActivityEdit
  },
  props: {
    stepName: {
      type: String,
      required: true
    },
    returnLink: {
      type: Object,
      required: false
    },
    dataUrl: {
      type: String,
      required: true
    },
    existingActivities: {
      type: Array,
      required: true
    },
    stepIsSealed: {
      type: Boolean,
      required: true
    },
    stepIsCandidate: {
      type: Boolean,
      required: true
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getActivityTypesAsync(this.dataUrl);
  },
  methods: {
    ...mapActions('activities', ['resetActivities']),
    async getActivityTypesAsync (url) {
      var response = await this.$http.get(url);

      if(this.stepIsSealed) {
        this.activityTypes = response.data.activityTypes.filter(type => type.activityType !== 'Form');
        // nextTick necessary for initial page load, where the PrimaryMessageBus was emitting too early
        this.$nextTick(() => PrimaryMessageBus.$emit('send-warning-message', this.$t('processBuilder.activities.add.validation.sealedStepWarning')));
      }
      else {
        this.activityTypes = response.data.activityTypes;
      }
    },
    async selectTypeAsync (activityType) {
      this.selectedActivityType = activityType;
      this.activityList = await this.getActivitiesAsync(activityType.list.url);
      this.showSelect = true;
    },
    async getActivitiesAsync (url, searchTerm) {
      var response = await this.$http.get(this.urlWithSearchTerm(url, searchTerm));
      let activities = response.data.activities || [];
      let filteredActivities;
      
      if (!this.allowDuplicateActivities) {
        filteredActivities = activities
          .filter(activity => {
            let alreadyInStep = this.existingActivities
              .filter(existingActivity => ActivityConfig[this.selectedActivityTypeEnum]
                .activityMatchesExisting(existingActivity, activity)).length > 0;
            return !alreadyInStep;
          });
      }

      response.data.activities = filteredActivities || activities;

      return response.data;
    },
    async searchAsync(searchTerm) {
      this.searchTerm = searchTerm;
      this.activityList = await this.getActivitiesAsync(this.selectedActivityType.list.url, this.searchTerm);

      this.searchResult = {
        searchTerm: this.searchTerm, 
        count: this.activityList.activities.length
      }
    },
    async addActivitiesAsync () {
      const postBody = { activities: this.selectedActivities };
      var response = await this.$http.post(this.selectedActivityType.add.url, postBody);
      var data = response.data;
      this.$emit('activity-add-added', data);
    },
    selectedActivitiesChanged(atLeastOneActivitySelected) {
      if(!atLeastOneActivitySelected) {
        PrimaryMessageBus.$emit('send-error-message', this.$t('processBuilder.activities.add.errorNoSelection'));
      } else {
        PrimaryMessageBus.$emit('clear-all-messages');
      }
    },
    async activitySelectedAsync() {
      if(this.selectedActivities.length === 0) {
        PrimaryMessageBus.$emit('send-error-message', this.$t('processBuilder.activities.add.errorNoSelection'));
      }
      else if(ActivityConfig[this.selectedActivityTypeEnum].configureOnAdd(this.stepIsCandidate)) {
        window.scrollTo(0, 0);
        this.showSelect = false;
      }
      else {
        await this.addActivitiesAsync();
      }
    },
    activityEditCancel() {
      window.scrollTo(0, 0);
      this.showSelect = true;
      PrimaryMessageBus.$emit('clear-all-messages');
    },
    urlWithSearchTerm(url, searchTerm) {
      return url + (searchTerm ? '&searchTerm=' + encodeURIComponent(searchTerm) : '');
    },
    resetSelectedActivities() {
      this.selectedActivityType = null;
      this.selectedActivities = [];
      this.showSelect = false;
      this.activityList = null;
      this.searchTerm = null;
      this.searchResult = null;
      this.resetActivities();
    },
    getIconName (item) {
      return `#${ActivityIcons[item.activityType].id}`;
    },
    getDisplayName(activity) {
      return ( activity.label || this.$t(`processBuilder.activities.add.activityTypeEnumMap.${activity.typeEnum}.deleted`) );
    }
  },
  computed: {
    selectedActivityTypeEnum () {
      return this.selectedActivityType ? this.selectedActivityType.activityType : null;
    },
    searchEnabled() {
      let typeConfig = ActivityConfig[this.selectedActivityTypeEnum];
      return typeConfig && typeConfig.searchEnabled;
    },
    allowDuplicateActivities() {
      let typeConfig = ActivityConfig[this.selectedActivityTypeEnum];
      return typeConfig && typeConfig.allowDuplicateActivities;
    }
  },
  data() {
    return {
      activityTypes: null,
      activityTypesLabel: this.$t('processBuilder.activityBuilder.activityTypeLabel'),
      selectedActivityType: null,

      searchTerm: null,
      searchResult: null,

      activityList: null,
      selectedActivities: [],
      showSelect: false
    }
  }
};
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';

.activity-title {
  padding: 1rem;

  h2 {
    font-size: 1rem;
    font-weight: normal;
    width: 100%;
    text-align: center;
    color: $cb-dark-grey-1;
    margin: 0;
  }
}

.search-box .cb-search {
  padding-bottom: 1rem;
}

.button-container {
  display: flex;
  button {
    flex-grow: 1;
  }
}
</style>