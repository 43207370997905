<template>
  <cb-view-section type="primary" :includePrimaryMessages="true">
    <cb-view-section type="secondary">
      <cb-grid-selector :label="activityTypesLabel" :items="activityTypesWithLabels" :maxColumns="gridSelectorColumns" :onSelection="onSelection">
        <cb-grid-selector-item-content slot="item-content" slot-scope="{ item }" :item="item">
          <template slot="icon">
            <svg>
              <use v-bind="{'xlink:href': getIconName(item) }"></use>
            </svg>
          </template>
        </cb-grid-selector-item-content>
      </cb-grid-selector>
    </cb-view-section>
  </cb-view-section>
</template>
<script>
import CbViewSection from "general/cb-view-section.vue";
import CbGridSelector from 'common/components/cb-grid-selector.vue';
import CbGridSelectorItemContent from 'common/components/cb-grid-selector-item-content.vue';
import { ActivityIcons } from 'processes/steps/activities/activityTypes.js';

export default {
  name: "activity-type-select",
  components: {
    CbViewSection,
    CbGridSelector,
    CbGridSelectorItemContent
  },
  props: {
    activityTypes: {
      type: Array,
      required: true
    },
    onSelection: {
      type: Function,
      required: true
    }
  },
  methods: {
    getIconName (item) {
      return `#${ActivityIcons[item.activityType].id}`;
    },
    getActivityTypeLabel(activityType) {
      return this.$t(`processBuilder.activities.add.activityTypeEnumMap.${activityType}.typeSelectName`);
    }
  },
  computed: {
      activityTypesWithLabels() {
        return this.activityTypes.map((type) => {
          type.label = this.getActivityTypeLabel(type.activityType)
          return type;
        });
      },
      gridSelectorColumns() {
        return this.activityTypes ? this.activityTypes.length : 0;
      }
    },
  data() {
    return {
      activityTypesLabel: this.$t('processBuilder.activityBuilder.activityTypeLabel'),
    }
  }
};
</script>
<style scoped lang="scss">
</style>