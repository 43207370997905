<template>
  <savage-form-labeled-field
    :label="label"
    :disabled="disabled"
    :validation="supportedValidation">
      <div class="create-video-wrapper">
        <select class="savage-form-select" v-model="localValue.provider" :style="selectStyleOverrides">
          <option :key="index" v-for="(item, index) in providers" :value="item.value">
            {{item.label}}
          </option>
        </select>
        <savage-form-validated-field
          :field-name="name"
          :validation="supportedValidation"
          :customValidation="enrichedCustomVideoValidation"
          class="validated">
            <!--
              click.prevent prevents the click from bubbling up to the label
              and moving your focus to the provider dropdown in safari
            -->
            <input type="text" class="input-text video-web-address"
              :disabled="disabled"
              :class="computedFieldClass"
              :name="name"
              :title="computedTitle"
              :placeholder="computedPlaceholder"
              v-bind="supportedValidationAttributes"
              v-model="localValue.url"
              @click.prevent />
        </savage-form-validated-field>
      </div>
    </savage-form-labeled-field>
</template>
<script>
import { SavageFormLabeledField, SavageFormValidatedField, SavageFormFieldMixin } from '@clickboarding/savage';
import YoutubeService from 'common/services/youtube.service';
import VimeoService from 'common/services/vimeo.service';
// AsyncComputed used for asynchronous validation of Vimeo url
import Vue from 'vue';
import AsyncComputed from 'vue-async-computed'
Vue.use(AsyncComputed)

export default {
  name: 'forms-add-video',
  components: {
    SavageFormValidatedField,
    SavageFormLabeledField
  },
  mixins: [
    SavageFormFieldMixin
  ],
  props: {
    providers: {
      type: Array,
      required: true
    },
    defaultProvider: {
      type: String,
      required: true
    }
  },
  asyncComputed: {
    // asyncComputed necessary to trigger vue-form validation
    // to re-run when switching the provider select.  asyncComputed
    // would not be necessary if we needed to validate a single async
    // field (use a Promise instead)
    async isValidVideoUrl () {
      let isValid = false;
      if (this.localValue && this.localValue.url) {
        switch(this.localValue.provider) {
          case 'YouTube':
            isValid = YoutubeService.isValidUrl(this.localValue.url);
            break;
          case 'Vimeo':
            isValid = await VimeoService.isValidUrl(this.localValue.url);
            break;
        }
      }
      return isValid;
    }
  },
  computed: {
    localValue: {
      get() {
        return Object.assign(this.value, { provider: this.value.provider || this.defaultProvider });
      },
      set(v) {
        this.$emit('input', v);
      }
    },
    invalidProviderUrlMessages () {
      let messages = new Object()

      this.providers.forEach((provider) => {
        messages[provider.value] = provider.invalidUrlMessage
      })

      return messages
    },
    enrichedCustomVideoValidation () {
      let result = Object.assign({}, this.enrichedCustomValidation || {});

      // validate that the isValidVideoUrl asyncComputed has finished running (updating = false)
      // prior to calling the result valid.  This prevents a user from modifying
      // the url or provider and clicking update prior to the async method from
      // completing.
      this.$set(result, 'validVideoUrl', {
        label: this.label,
        value: !this.$asyncComputed.isValidVideoUrl.updating && this.isValidVideoUrl,
        message: this.invalidProviderUrlMessages[this.localValue.provider]
      });

      return result;
    },
    selectStyleOverrides () {
      // using inline styles to beat the the specificity of savage's scoped
      // styles without referencing internal classes from savage
      return {
        'width': '7rem !important',
        'border-radius': '4px 0 0 4px !important',
        'border-right': 'none !important'
      }
    }
  },
  data () {
    return {
      allowedValidationTypes: [ 'required' ]
    };
  }
}
</script>

<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    .create-video-wrapper {
      display: flex;
      flex-direction: row;
    }

    label {
      display: block;
    }

    .validated {
      flex-grow: 1;
    }

    .video-web-address {
      background-color: $cb-white;
      @include font-size-content-root;
      padding: .5rem;
      padding-top: calc(.5rem + 1px);
      padding-bottom: calc(.5rem + 1px);
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
</style>
