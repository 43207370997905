<template>
    <section class="vertical-flex action-center-route">
        <cb-admin-view-title-with-info-tooltip class="vertical-flex-min reports-header cb-admin-view-title" :title="displayTitle" :link="linkInfo" :infoDescription="displayInfoDescription" />
        <!--<b-alert show class="announcement with-icon" variant="warning">
            <b-icon aria-hidden="true" icon="alert-triangle" />
            <span>Attention Users! The New Candidate UI will be default in Production on May 21 at 12:15 a.m. CDT!
            Please continue to test in both your UAT and Production environments and provide feedback.
            For more information on how to test the refreshed Candidate Portal please see our 
            <a href="https://clickboarding.zendesk.com/hc/en-us/articles/28225299958291-Testing-the-New-Candidate-UI-Experience" target="_blank">Resource Center</a>.
            </span>
        </b-alert>-->
        <b-alert v-if="showNewAdminLink()" show class="announcement with-icon" variant="warning">
            <b-icon aria-hidden="true" icon="alert-triangle" />
            <span>The refreshed Admin Portal is now available!
            <a class="new-admin-link" @click="goToNewAdminUi()">Click here</a> to access the updated Admin Portal.
            </span>
        </b-alert>
        <div class="vertical-flex-min reports-header cb-admin-view-title">
            <p><b>{{ $t('common.welcome') }} {{ userName }},</b></p>
            <p>{{ $t('common.actionCenterDescription') }}</p>
        </div>
        <cb-view-section class="vertical-flex-fill" type="secondary" :includePrimaryMessages="true">
            <div class="report-filters">
                <b-button class="small-button ml-3" variant="outline-green" @click="clearFilters">
                    {{ $t('common.clearFilters') }}
                </b-button>
                <b-dropdown id="dropdown-1" variant="outline-green" text="Export" class="small-button ml-3">
                    <b-dropdown-item @click="downloadFileClicked('csv')">CSV</b-dropdown-item>
                    <b-dropdown-item @click="downloadFileClicked('excel')">Excel</b-dropdown-item>
                </b-dropdown>
            </div>
            <action-center-base class="vertical-flex-fill"
                                :dataUrl="dataUrl"
                                :clearFilter="clearFilterToggle"
                                :downloadFile="downloadTrigger"
                                :exportThreshold="exportThreshold"/>
        </cb-view-section>
    </section>    
</template>

<script>
    import CbAdminViewTitleWithInfoTooltip from 'general/cb-admin-view-title-with-info-tooltip.vue';
    import CbViewSection from "general/cb-view-section.vue";
    import store from "store";
    import appSettings from 'appSettings';
    import ActionCenterBase from './action-center-revamped-base.vue';
    import ActionCenterService from "./action-center-revamped.service";
    import MenuService from 'general/menu.service';

    export default {
        name: "action-center-revamped-route",
        data() {
            return {
                clearFilterToggle: new Date(),
                downloadTrigger: {},
                exportThreshold: 0,
            }
        },
        components: {
            CbAdminViewTitleWithInfoTooltip,
            CbViewSection,
            ActionCenterBase,
        },
        props: {
            dataUrl: {
                type: String,
                required: true
            }
        },
        created() {
            if (appSettings.hasOwnProperty("reportingExportThreshold")) {
                this.exportThreshold = JSON.parse(appSettings.reportingExportThreshold);
            }
        },
        methods: {
            clearFilters() {
                //hack to trigger the prop change event
                this.clearFilterToggle = new Date();
            },
            async downloadFileClicked(exportType) {
                this.downloadTrigger = { type: exportType, trigger: new Date() };
            },
            showNewAdminLink() {
                return store.getters['features/hasNewAdminUiMagicLinkFeature']();
            },
            async goToNewAdminUi() {
                window.open(await MenuService.clickMagicLink(await ActionCenterService.getBuildNewAdminUiMagicLinkUrl()), "_target");
            },
        },
        computed: {
            displayTitle() {
                return "Action Center";
            },
            displayInfoDescription() {
                return "Get additional information on how to manage your action center items and understand the Action Center Grid";
            },
            linkInfo() {
                return "https://clickboarding.zendesk.com/hc/en-us/articles/15097575687955";
            },
            userName() {
                var userName = store.getters['features/getUserName']();

                return userName || '';
            }
        }
    };
</script>
<style scoped lang="scss">
    @import "@clickboarding/style/colors";
    @import "@clickboarding/style/mixins";
    @import "~ag-grid-community/styles/ag-grid.css";
    @import "~ag-grid-community/styles/ag-theme-alpine.css";

    .action-center-route .reports-header {
        /deep/ .header-content {
            margin-top: 0.625rem;
            padding: 1.25rem;
            border-bottom: 0rem;
        }
    }

    section.action-center-route {

        div.report-name {
            padding: 1.5rem;
        }

        .cb-view-section {
            border-bottom: 0rem;
        }

        p.no-reports {
            border-top: 1px solid $cb-dark-grey-6;
            padding: 3rem;
            text-align: center;
            font-size: 0.8rem;
        }
        .announcement {
            margin-left: 1.5rem;
            margin-right: 1.5rem;
        }
    }

    .report-filters {
        display: flex;
        margin-bottom: 1rem;
        flex-direction: row-reverse;

        & > div {
            margin-right: 1rem;
        }

        & > div:last-of-type {
            margin-right: 0;
        }
    }

    .new-admin-link {
        color: #3A5B98 !important;
        text-decoration: underline !important;
    }
</style>