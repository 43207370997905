<template>
  <div>
    <forms-preview ref="preview" v-if="isSavageForm" @close="close" :data-url="activePreview.url" />
    <forms-preview-rose ref="previewRose" v-if="isRoseForm" @close="close" :data-url="activePreview.url" />
  </div>
</template>
<script>
import FormsPreview from 'forms/forms-preview.vue';
import FormsPreviewRose from 'forms/forms-preview-rose.vue';

export default {
  name: "activity-preview",
  components: {
    FormsPreview,
    FormsPreviewRose,
  },
  props: {
    activePreview: {
      type: Object,
      required: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
  computed: {
    isSavageForm() {
      return this.activePreview.renderingEngineType === 'Savage';
    },
    isRoseForm() {
      return this.activePreview.renderingEngineType === 'Rose';
    }
  }
};
</script>
<style scoped lang="scss">
</style>