<template>
  <section class="cb-view-sub-section">
    <slot></slot>
  </section>
</template>

<script>
  export default {
    name: "cb-view-sub-section"
  }

</script>

<style scoped lang="scss">
  @import '@clickboarding/style/mixins';

  .cb-view-sub-section + .cb-view-sub-section{
    margin-top: $standard-cb-view-margin;
  }
</style>
