<template>
  <div class="navigation" :class="{'open': menuShow}">
    <ul class="menu" v-if="menu && activeNav">
        <li @click="toggleMenu()" class="nav-item toggle" v-if="showToggle">
            <i v-if="!menuShow" class="cb-icon menu-expand"></i>
            <i v-if="menuShow" class="cb-icon menu-collapse"></i>
        </li>
        <li class="nav-item toggle" v-else></li>
        <li class="nav-item primarynav-item"
            v-for="(item, index) in menu"
            v-show="canShowMenuItem(item)"
            :key="index"
            :class="{'active': activeNav === item}">
          <a @click="selectMenuItem(item)" class="icon" style="border-bottom : none !important;">
            <label id="telemetryNameLabel" style="display:none"> {{ item.label }} </label>
            <i class="cb-icon" :class="getMenuIcon(item.type)" :title="item.label" data-name-id="item.label"></i>
          </a>
          <div v-show="menuShow" class="cb-secondary-nav cb-slide-left">
            <span class="view-title">{{item.label}}</span>
            <cb-page-callout v-if="showOptOut" class="callout" key="optOutCallout"
              :title="$t('actionCenter.list.optOut.title')"
              :content="$t('actionCenter.list.optOut.content')"
              :action="optOutAction"
            />
            <cb-page-callout v-else-if="showOptIn" class="callout" key="optInCallout"
              :title="'Action Center'"
              :content="'Our new Action Center is now available! Please read our release notes for a complete overview. Ready to step it up?'"
              :action="optInAction"
            />
            <div class="view-description" v-if="item.subNav.description">{{item.subNav.description}}</div>
            <ul class="list" v-if="item.subNav.items">
              <li class="subnav-item" :class="{'active': activeSubNav === subItem}" v-for="(subItem, index) in item.subNav.items" :key="index" @click="selectMenuItem(item, subItem)" >
                <a class="subnav-item-title" style="color: #062065 !important;">{{subItem.label}}</a>
              </li>
            </ul>
          </div>
        </li>
    </ul>
  </div>
</template>

<script>
  import CbPageCallout from 'general/cb-page-callout.vue';
  import MenuService from 'general/menu.service';
  import MenuBus from 'general/cb-admin-menu-bus';
  import appSettings from 'appSettings';
  var actionCenterVersion = "cb" + appSettings.appType + "actionCenterVersion";

  export default {
    name: 'cb-admin-menu',
    created () {
      MenuBus.onUpdateDataUrl(this.refreshMenu);
      MenuBus.updateMenuExpanded(this.menuShow);
    },
    components: {
      CbPageCallout
    },
    methods: {
      setActionCenterVersion (menu) {
        if (sessionStorage.getItem(actionCenterVersion)) {
          this.activeActionCenterVersion = parseInt(sessionStorage.getItem(actionCenterVersion))
          this.setActiveActionCenterItem(menu, this.activeActionCenterVersion);
        } else {
          this.setActiveActionCenterItem(menu, 2);
          this.activeActionCenterVersion = 2;
          sessionStorage.setItem(actionCenterVersion, this.activeActionCenterVersion)
        }
      },
      setActiveActionCenterItem (menu, version) {
        menu.forEach((item) => {
          if (item.type === 'ActionCenter') {
            if (item.version === version) {
              item.enabled = true;
            } else {
              item.enabled = false;
            }
          }
        });
      },
      shouldOpenAfterSelection: function () {
        if (!document.querySelector('.cb-secondary-nav')) return false;
        var cssMetaData = window.getComputedStyle(document.querySelector('.cb-secondary-nav'), ':before');
        var currentMode = cssMetaData && cssMetaData.getPropertyValue('content').replace(/\"/g, '');
        return currentMode === "show";
      },
      refreshMenu (dataUrl) {
        this.$http.get(dataUrl).then((response) => {
          this.menu = this.enrichMenuResponse(response.body.menu);

          let _showImportTab = false;
          if(appSettings.hasOwnProperty("showImportTab")){
            _showImportTab = JSON.parse(appSettings.showImportTab);
          }
          if(!_showImportTab) {
            this.menu && this.menu.forEach(function(o) {
              if(o.hasOwnProperty("subNav"))
                o.subNav.items = o.subNav.items && o.subNav.items.filter(x => x.route !== 'locations-imports');
            });
          }

          MenuBus.setMenu(this.menu);
          if (!(this.menu && this.menu.length)) {
            this.$_cb.router.changeView(appSettings.viewAccessDenied);
          } else {
            this.setActionCenterVersion(this.menu);
            
            let current;
            //if the current route is using the new vue-like routing,
            //use it's configuration to determine which subnav to load
            //rather than the route itself.
            if(this.$_cb.router.getDefaultComponent()) {
              var config = this.$_cb.router.getMenuProps();
              current = this.findMenuItemByConfig(config, this.menu)
            }
            else {
              current = this.findMenuItemByRoute();
            }            

            if (current) this.selectMenuItem(current.nav, current.subNav);
          }
        });
      },
      enrichMenuResponse(menuResponse) {
        if (menuResponse) {
          menuResponse.forEach((item) => {
            if (item.subNav.type === "DescriptionOnly") {
              item.route = item.subNav.route;
            } else {
              item.route = item.subNav.items[0].route;
            }
          });
        }
        return menuResponse;
      },
      isCurrentRouteWithin(item) {
        const currentRoute = this.$_cb.route.path;
        
        //extract first part of route (i.e. extract '/route' from '/route/child')
        const currentNavRoute = currentRoute ? `/${currentRoute.substring(1).split('/')[0]}` : null;

        return currentNavRoute === `/${item.route}`;
      },
      isCurrentRoute(item) {
        return this.$_cb.route.path === `/${item.route}`;
      },
      findMenuItemByConfig(config, menu) {
        let nav, subNav;

        nav = menu.filter( (item) => item.type === config.selectedNav)[0];

        //if we didn't find a matching nav, let subnav stay undefined,
        //and fallback to the first item in the list.
        if(nav && nav.subNav) {
          subNav = nav.subNav.items.filter( (item) => item.route === config.selectedSubnav)[0];
        }
        else {
          nav = menu[0];
        }

        return {
          nav: nav,
          subNav: subNav,
        };
      },
      findMenuItemByRoute() {
        // if no menu, there can't be a match
        if(!this.menu || !this.menu.length) return null;

        let itemToSelect;

        for (var i = 0; i < this.menu.length; i++) {
          // in the instance of company select
          if (this.menu[i].route === "companies") {
            itemToSelect = this.menu[i];
            break;
          // in the instance of choosing a company
          } else if (this.menu[i].enabled) {
            itemToSelect = this.menu[i];
            break;
          }
        }

        // default to the first menu item
        const selected = {
          nav: itemToSelect,
          subNav: null
        };

        for(var i = 0; i < this.menu.length; i++) {
          const item = this.menu[i];

          if(item.subNav.type === "DescriptionOnly") {
            if (!item.enabled) continue; // need to check if this item is enabled

            if(this.isCurrentRouteWithin(item)){
              selected.nav = item;
              selected.subNav = item.subNav;
              break;
            }
          } else {
            const matchingSubNav = item.subNav.items.filter((subNavItem) => {
              return this.isCurrentRouteWithin(subNavItem);
            });

            if(matchingSubNav.length) {
              selected.nav = item;
              selected.subNav = matchingSubNav[0];
              break;
            }
          }
        }

        return selected;
      },
      toggleMenu (show) {
        this.menuShow = show === undefined ? !this.menuShow : show;
        MenuBus.updateMenuExpanded(this.menuShow);
      },
      async selectMenuItem (item, subItem) {
        // need special handling for the magic links here
        if (item.type === 'Retain') {
          window.open(await MenuService.clickMagicLink(item.subNav.url), "_self");
        } else if (item.type === 'FormI9') {
          window.open(await MenuService.clickMagicLink(item.subNav.url), "_target");
        } else {
          // if initial request, a main nav item was clicked, or not on mobile
          let shouldOpenMenu = false;
          const hideActionCenter = (this.isInitialRequest && this.isSmallScreen) || 
            !!(this.isSmallScreen 
                && item
                && item.route === 'actioncenter' 
                && this.activeNav 
                && this.activeNav.route === 'companies');

          if (hideActionCenter) {
            shouldOpenMenu = false;
            setTimeout(() => {
              document.querySelector("html").scrollIntoView();
            }, 3000);
          } else {
            shouldOpenMenu = this.isInitialRequest || !subItem  || this.shouldOpenAfterSelection();
          }

          if (!subItem) {
            subItem = item.subNav && item.subNav.items && item.subNav.items.length 
              ? item.subNav.items[0] 
              : item.subNav;
          }
          
          var requiresReload = !this.isInitialRequest && this.isCurrentRoute(subItem);

          this.activeNav = item;
          this.activeSubNav = subItem;

          let activeTitle = subItem.label || item.label;
          MenuService.setActiveTitle(activeTitle);
          
          this.toggleMenu(shouldOpenMenu);
          this.$_cb.router.changeView(`/${subItem.route}`, subItem.url);

          if (requiresReload) {
            this.$_cb.router.reloadView();
          }
        }
      },
      getMenuIcon (type) {
          var iconType = "";
          switch (type) {
              case 'Home':
              case 'ActionCenter':
                  iconType = 'action-center';
                  break;
              case 'Company':
              case 'Companies':
                  iconType = 'building';
                  break;
              case 'Candidates':
                  iconType = 'add-user';
                  break;
              case 'Processes':
                  iconType = 'process-nav';
                  break;
              case 'Settings':
                  iconType = 'plug';
                  break;
              case 'Reports':
                  iconType = 'reports';
                  break;
              case 'Retain':
                  iconType = 'retain';
                  break;
              case 'BulkImportsAndUpdates':
                  iconType = 'bulk-imports';
                  break;
              case 'FormI9':
                  iconType = 'form-i9';
                  break;
          }
          return iconType;
      },
      //Supports feature toggled sections
      canShowMenuItem(item) {
        if (item.type !== 'ActionCenter') {
          return item.type !== 'None';
        } else {
          // currently only applied to Action Center
          return item.type !== 'None' && 
            (item.type === 'ActionCenter' && item.version == this.activeActionCenterVersion)
        }
      },
      manageActionCenterVersion (version) {
        this.activeActionCenterVersion = parseInt(version)

        let menuItem = this.menu.filter((item) => {
          return item.version === this.activeActionCenterVersion 
        }).shift();
        
        this.selectMenuItem(menuItem)
      }    
    },
    computed: {
      isSmallScreen () {
        return this.windowWidth < 800;
      },
      isInitialRequest () {
        return this.activeNav === null;
      },
      showOptOut () {
        let hasLegacy = this.menu.filter((item) => {
            return item.version === 1 && item.type === 'ActionCenter'
        }).shift();
        return hasLegacy && this.activeNav.type === 'ActionCenter' && this.activeActionCenterVersion === 2;
      },
      showOptIn () {
        let hasNew = this.menu.filter((item) => {
            return item.version === 2 && item.type === 'ActionCenter'
        }).shift();
        return hasNew && this.activeNav.type === 'ActionCenter' && this.activeActionCenterVersion === 1;
      }
    },
    data () {
      return {
        menuShow: false,
        showToggle: true,
        windowWidth: window.innerWidth,
        menu: null,
        activeNav: null,
        activeSubNav: null,
        activeActionCenterVersion: null,
        optOutAction: {
          label: this.$t('actionCenter.list.optOut.actionLabel'),
          action: () => this.manageActionCenterVersion('1')
        },
        optInAction: {
          label: 'Try New Experience',
          action: () => this.manageActionCenterVersion('2')
        }
      }
    }
  }
</script>

<style scoped lang="scss">
@import '@clickboarding/style/mixins';
@import '@clickboarding/style/colors';

.navigation {
  @include box-sizing-reset;

  height: 100%; width: 4rem;
  position: fixed;
  left:0; top: 0;
    background: #444;
    margin:0;
    z-index:1001;

  &.open {
    width: 20rem;
  }

  ul {
    width:100%;
    margin: 0; padding: 0;
    position: relative;
  }

  .nav-item {
    display: flex;
    align-items: center;
    height: 4em; width: 4em;
    position: relative;
    color: #e9e9e9;
    justify-content: center;
    cursor: pointer;

    .cb-icon.user {
      background-color: $cb-white;
    }

    &:hover {
        color: $cb-white;
        background-color: #303030;
    }

    &.active {
        background: #F37607;
        color: $cb-white;

      &:after, &:before {
        height: 0; width: 0;
        position: absolute;
        left: 100%; top: 50%;
        border: solid transparent;
        content: " ";
        pointer-events: none;
      }
      &:after {
        border-color: rgba(243, 118, 7, 0);
        border-left-color: #F37607;
        border-width: 5px;
        margin-top: -5px;
          z-index:1001;
      }
    }
  }

  .nav-item.active {
    .cb-secondary-nav {
      display: block;
    }
  }

  .toggle .cb-icon {
    height: 1.75rem; width: 1.75rem;
    margin: 0;    
  }
}

.cb-secondary-nav {
  @include box-sizing-reset;
  width: 16rem; height: 100%;
  position: fixed;
  top: 0; left: 4rem;
  box-shadow: 3px 0 3px -3px rgba(0,0,0,0.35);
  box-sizing: border-box;
  background: $cb-white;
  color: $cb-dark-grey-1;
  border-right: 1px solid #C4C4C4;
  z-index: 1000;
  padding: .75rem;
  display: none;
  cursor: default;

  .view-title {
    display: inline-block;
    padding: .25rem 0 0 .25rem;
  }

  .view-description {
    @include font-size-content;
    line-height: 1.25rem;
    padding: 1em 0 0 .25rem;
  }

  .list {
    @include font-size-content;
    border-top: 1px solid #C4C4C4;
    padding: 0;
    margin: 1rem 0 0 0;
  }

  .subnav-item {
    color: #3A5B98;
    border-bottom: 1px solid #C4C4C4;
    padding-left: .5rem;

    .subnav-item-title {
      display: block;
      margin-right: -1rem;
      padding: 1rem 0;
    }

    &.active {
      color: #062065;
      font-weight: bold;

      .subnav-item-title {
        border-right: 5px solid #F37607;
      }
    }

    &:hover {
      cursor: pointer;
      font-weight: bold;      
    }
  }
  
  a:hover{
    border-bottom : none !important;
}

  .callout {
    padding: 1rem 0 0 .25rem;
  }

  /* by default we will keep the sub nav visible after clicking */
  &:before {
    display: none;
    content: "show";
  }
  /* anything smaller than a large tablet (i.e. iPad Pro) will hide sub nav after clicking */
  @media (max-width: 800px) {
    &:before {
      content: "hide";
    }
  }
}
</style>