<template>
  <div>
    <template v-if="collectIdentifier">
      <savage-form-text-input class="datafields-add-edit-identifier-input"
        :label="$t('datafields.identifierLabel')"
        :validation="identifierValidation"
        :customValidation="identifierCustomValidation"
        v-model="localIdentifier" />
      <div class="datafields-add-edit-field-description">{{$t('datafields.create.identifierDescription')}}</div>
    </template>
    <savage-form-text-input class="datafields-add-edit-title-input"
      :label="$t('datafields.titleLabel')"
      :validation="titleValidation"
      v-model="localTitle" />
    <div class="datafields-add-edit-field-description">{{$t('datafields.create.titleDescription')}}</div>
  </div>
</template>

<script>
import { SavageFormTextInput } from "@clickboarding/savage";

export default {
  name: "datafields-add-edit-general",
  components: {
    SavageFormTextInput
  },
  props: {
    parentSchemaField: {
      type: Object,
      required: false
    },
    identifier: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    collectIdentifier () {
      return !this.editMode;
    },
    identifierInUse () {
      if (!this.localIdentifier) return false;
      if (!this.parentSchemaField) return false;

      const identifiersInUseLower = this.parentSchemaField.metadata.identifiersInUseLower;

      if (!identifiersInUseLower) return false;
      return identifiersInUseLower.has(this.localIdentifier.toLowerCase());
    },
    identifierCustomValidation () {
      return {
        identifierAvailable: {
          value: !this.identifierInUse,
          message: this.$t('datafields.validation.identifierConflict', [ this.localIdentifier ])
        }
      };
    },
    identifierValidation () {
      return {
        required: {
          value: true,
          message: this.$t('common.validation.required')
        },
        maxLength: {
          value: 200,
          message: this.$t('common.validation.maxLengthFormat', [200])
        },
        pattern: {
          value: /^(?!([ehxd]|du|dt|ja|jb|jf|ji|jo)_)[A-Za-z][A-Za-z0-9_]{2,}$/,
          message: this.$t('datafields.validation.identifierPattern', [ this.localIdentifier ])
        }
      };
    },
    localIdentifier: {
      get () { return this.identifier },
      set (localIdentifier) { this.$emit('update:identifier', localIdentifier); }
    },
    localTitle: {
      get () { return this.title },
      set (localTitle) { this.$emit('update:title', localTitle); }
    }
  },
  created () {
    this.titleValidation = {
      required: {
        value: true,
        message: this.$t('common.validation.required')
      },
      maxLength: {
        value: 200,
        message: this.$t('common.validation.maxLengthFormat', [200])
      }
    };
  }
}
</script>

<style lang="scss" scoped>

.datafields-add-edit-identifier-input,
.datafields-add-edit-title-input {
  margin-bottom: 0 !important; // Override the deep styles in savage-form form-field
}

.datafields-add-edit-field-description {
  font-size: .75rem;
  opacity: .9;
  margin-top: .25rem;
  margin-bottom: 1rem;
}
</style>
