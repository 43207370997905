<template>
    <section class="vertical-flex">
        <FormsContent 
          class="vertical-flex-fill"
          v-if="this.type === 'Content'"
          :data-url="dataUrl"
          :is-read-only="isReadOnly">
        </FormsContent>
        
        <FormsPolicyAgreement 
          class="vertical-flex-fill"
          v-if="this.type === 'PolicyAgreement'"
          :data-url="dataUrl" 
          :is-read-only="isReadOnly">
        </FormsPolicyAgreement>

        <FormsDocumentUpload
          class="vertical-flex-fill" 
          v-if="this.type === 'DocumentUpload'"
          :data-url="dataUrl"
          :is-read-only="isReadOnly">
        </FormsDocumentUpload>

        <FormsDataEntry 
          class="vertical-flex-fill"
          v-if="this.type === 'DataEntry'"
          :data-url="dataUrl"
          :is-read-only="isReadOnly">
        </FormsDataEntry>
    </section>
</template>
<script>
import FormsContent from 'forms/forms-content.vue';
import FormsPolicyAgreement from 'forms/forms-policy-agreement.vue';
import FormsDocumentUpload from 'forms/forms-document-upload.vue';
import FormsDataEntry from 'forms/forms-data-entry.vue';

export default {
  name: 'forms-edit-route',
  components: {
    FormsContent,
    FormsPolicyAgreement,
    FormsDocumentUpload,
    FormsDataEntry
  },
  props: {
    type: {
      type: String,
      required: true
    },
    dataUrl: {
      type: String,
      required: true
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>
