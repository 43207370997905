<template>
  <b-card-group class="primary-section">
    <b-card v-if="isPlatformControlled" no-body>
      <b-alert show class="with-icon w-100" variant="warning">
        <b-icon aria-hidden="true" icon="alert-triangle" />
        {{$t('datafields.platformControlledMessage')}}
      </b-alert>
    </b-card>
    <b-card>
      <b-card-primary-section-header class="datafield-general-card-header" slot="header" :title="$t('datafields.generalHeading')">
        <b-link v-if="canEditGeneral" slot="additional-content" slot-scope="{ variant }" :class="['b-link', 'with-icon', `text-${variant}`]" href="javascript:void(0)" @click="showEditGeneral">
          <b-icon icon="pencil" aria-hidden="true" />{{$t('common.edit')}}
        </b-link>
      </b-card-primary-section-header>
      <cb-readonly-data-list class="datafield-view-properties-list" :items="baseProperties" :hideNilValues="true" />
    </b-card>
    <b-card v-if="showLimits">
      <b-card-primary-section-header class="datafield-limits-card-header" slot="header" :title="$t('datafields.settingsHeading')" />
      <cb-readonly-data-list class="datafield-view-limits-list" v-if="hasLimits" :items="limitProperties">
        <template v-slot:value="{ item, value }">
          <cb-tag-list v-if="item.type === 'enum'"
            class="datafield-view-limits-enum-list"
            :items="value" />
          <template v-else>
            {{value}}
          </template>
        </template>
      </cb-readonly-data-list>
      <div v-if="!hasLimits" class="datafield-view-limits-no-limits">{{$t('datafields.noLimitsMessage')}}</div>
    </b-card>
    <b-modal
      :id="editGeneralModalId"
      size="lg"
      static
      lazy
      no-close-on-backdrop
      centered
      :title="$t('datafields.editGeneral.heading')"
      :cancel-title="$t('common.cancel')"
      :ok-title="$t('common.update')"
      @hidden="resetGeneralForm"
      @ok="submitGeneralForm">
        <savage-form ref="editGeneralForm"
            name="datafields-edit-general-form"
            :formstate="formstate"
            :on-submit="saveGeneral">
          <datafields-add-edit-general
            :edit-mode="true"
            :title.sync="editGeneralModel.title">
          </datafields-add-edit-general>
        </savage-form>
    </b-modal>
  </b-card-group>
</template>
<script>
import uuid from 'uuid/v4';
import LimitsAdapter from 'datafields/limitsAdapter';
import CbTagList from 'components/cb-tag-list.vue';
import 'common/polyfills/object.assign.polyfill';
import typeNameAdapter from "datafields/typeNameAdapter";
import { BCardPrimarySectionHeader } from '@clickboarding/vue-components';
import { SavageForm } from "@clickboarding/savage";
import DatafieldsAddEditGeneral from 'datafields/datafields-add-edit-general.vue';
import { mapActions, mapGetters } from 'vuex';
import CbReadonlyDataList from 'common/components/cb-readonly-data-list.vue';

export default {
  name: 'datafield-view',
  components: {
    CbTagList,
    BCardPrimarySectionHeader,
    SavageForm,
    DatafieldsAddEditGeneral,
    CbReadonlyDataList
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('datafields', [
      'userCanEditTitle'
    ]),
    canEditGeneral () {
      return this.userCanEditTitle && this.item.metadata.editableTitle;
    },
    isPlatformControlled () {
      return this.item.metadata.platformControlled;
    },
    baseProperties () {
      return [
        { label: this.$t('datafields.typeLabel'), value: typeNameAdapter(this.item.type) },
        { label: this.$t('datafields.identifierLabel'), value: this.item.identifier },
        { label: this.$t('datafields.fullJsonPointerFieldLabel'), value: this.item.metadata.fullJsonPointer },
        { label: this.$t('datafields.titleLabel'), value: this.item.title }
      ]
    },
    basePropertiesLabelClass () {
      return this.getDescriptionListLabelClass(this.baseProperties);
    },
    showLimits () {
      return this.hasLimits || // Displayed when field has limits
        !this.isDatafieldContainer; // Hidden for scalars (non-containers)
    },
    hasLimits () {
      return this.limitProperties && this.limitProperties.length;
    },
    limitProperties () {
      let limitsToView = Object.assign({}, this.item.limits);

      delete limitsToView.required; // Choosing not to convey required until we determine best way to allow setting it

      return LimitsAdapter(limitsToView);
    },
    limitPropertiesLabelClass () {
      return this.getDescriptionListLabelClass(this.limitProperties);
    },
    isDatafieldContainer() {
      return ['Object','Array'].some((val) => {
        return this.item && this.item.type === val;
      });
    }
  },
  data () {
    return {
      formstate: {},
      editGeneralModel: {}
    }
  },
  created () {
    this.editGeneralModalId = `edit-general-modal-${uuid()}`;
  },
  methods: {
    ...mapActions('datafields', [
      'updateDatafieldGeneralSettings'
    ]),
    getDescriptionListLabelClass (properties) {
      const labelLengths = properties.map(property => property.label.length);
      const maxLabelLength = Math.max(...labelLengths);

      return {
        'description-list-label-small': maxLabelLength <= 10,
        'description-list-label-medium': 10 < maxLabelLength && maxLabelLength <= 20,
        'description-list-label-large': 20 < maxLabelLength
      };
    },
    showEditGeneral () {
      this.editGeneralModel = {
        title: this.item.title
      };
      this.$bvModal.show(this.editGeneralModalId);
    },
    resetGeneralForm () {
      this.editGeneralModel = {};
    },
    async submitGeneralForm (evt) {
      evt.preventDefault();
      await this.$refs.editGeneralForm.submit();
    },
    async saveGeneral () {
      await this.updateDatafieldGeneralSettings([this.item.schemaFieldId, {
          title: this.editGeneralModel.title
      }]);
      this.$bvModal.hide(this.editGeneralModalId);
    },
  }
}
</script>
<style lang="scss" scoped>
  @import '@clickboarding/style/mixins';

  .datafield-view-limits-no-limits {
    @include font-size-content-root;
  }

  .datafield-view-limits-header {
    @include font-size-content-root;

    font-weight: bold;
    margin-bottom: 1rem;
  }
</style>
