<template>
  <section class="vertical-flex">
    <template v-if="apiData">
      <cb-view-header class="vertical-flex-min" :title="heading" :return-link="returnLink" />
      <b-card-group class="primary-section vertical-flex-fill">
        <b-card>
          <b-card-primary-section-header slot="header" :title="$t('candidate.processFlowHeading')">
            <b-link v-if="userHasEditPermission" slot="additional-content" slot-scope="{ variant }" :class="['b-link', 'with-icon', `text-${variant}`]" href="javascript:void(0)" @click="showModal">
              <b-icon icon="pencil" aria-hidden="true" />{{$t('common.edit')}}
            </b-link>
          </b-card-primary-section-header>
          <cb-readonly-data-list :items="processData" />
        </b-card>
        <validation-observer slim v-slot="{ handleSubmit }">
          <b-modal
            :id="editModalId"
            v-if="userHasEditPermission"
            size="lg"
            static
            lazy
            no-close-on-backdrop
            centered
            :title="$t('candidate.editProcessFlowHeading')"
            :cancel-title="$t('common.cancel')"
            :ok-title="$t('common.update')"
            @hidden="resetModal"
            @ok.prevent="handleSubmit(handleEditSubmit)">
              <b-form novalidate>
                <candidate-process-details-inputs
                  :coordinatorUsers="coordinatorUserOptions"
                  :hiringManagerUsers="hiringManagerUserOptions"
                  :recruiterUsers="recruiterUserOptions"
                  :candidateDueDate.sync="editModel.candidateDueDate"
                  :dueDate.sync="editModel.dueDate"
                  :recruiterUserId.sync="editModel.recruiterUserId"
                  :hiringManagerUserId.sync="editModel.hiringManagerUserId"
                  :coordinatorUserId.sync="editModel.coordinatorUserId"
                  />
              </b-form>
          </b-modal>
        </validation-observer>
      </b-card-group>
    </template>
  </section>
</template>

<script>
import uuid from 'uuid/v4';
import CbViewHeader from 'general/cb-view-header.vue';
import { BCardPrimarySectionHeader } from '@clickboarding/vue-components';
import AppSettings from 'appSettings';
import { mapGetters } from 'vuex';
import isnil from "lodash.isnil";
import candidatesManagementService from "api/candidatesManagementService";
import CbReadonlyDataList from 'common/components/cb-readonly-data-list.vue';
import CandidateProcessDetailsInputs from 'candidates/candidate-process-details-inputs.vue';
import dateParse from 'date-fns/parse';

export default {
  name: 'candidate-process-view',
  components: {
    CbViewHeader,
    BCardPrimarySectionHeader,
    CbReadonlyDataList,
    CandidateProcessDetailsInputs
  },
  props: {
    candidateId: {
      type: String,
      required: true
    },
    processId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      apiData: null,
      editModel: {},
      locationUsers: []
    }
  },
  created () {
    this.editModalId = `edit-modal-${uuid()}`;
  },
  computed: {
    heading () {
      return this.apiData && this.apiData.processFlow;
    },
    ...mapGetters(['clientId']),
    apiArgs () {
      // This computed is used by a watcher to trigger a refresh of the api data when
      // the component is loaded, or when the api parameters change.
      // This negates the need for separate watchers, as well as logic in the 'created'
      // lifecycle hook.
      // This should only return a value once all api arguments are valid
      return (isnil(this.clientId) || isnil(this.candidateId) || isnil(this.processId)) ?
        null :
        [ this.clientId, this.candidateId, this.processId ];

    },
    returnLink () {
      return {
        label: this.$t('candidate.returnToCandidate'),
        handler: () => {
          this.$_cb.router.changeView(AppSettings.viewCandidatesView, { candidateId: this.candidateId }, null);
        }
      };
    },
    processData () {
      return this.apiData ? [
        { label: this.$t('candidate.processFields.locationLabel'), value: this.apiData.location },
        { label: this.$t('candidate.processFields.processLabel'), value: this.apiData.processFlow },
        { label: this.$t('candidate.processFields.statusLabel'), value: this.apiData.status.label },
        { label: this.$t('candidate.processFields.candidateDueDateLabel'), value: this.apiData.shortCandidateDueDate },
        { label: this.$t('candidate.processFields.dueDateLabel'), value: this.apiData.shortDueDate },
        { label: this.$t('candidate.processFields.recruiterLabel'), value: this.apiData.recruiterUser && this.apiData.recruiterUser.name },
        { label: this.$t('candidate.processFields.hiringManagerLabel'), value: this.apiData.hiringManagerUser && this.apiData.hiringManagerUser.name },
        { label: this.$t('candidate.processFields.coordinatorLabel'), value: this.apiData.coordinatorUser && this.apiData.coordinatorUser.name }
      ] : [];
    },
    userHasEditPermission () {
      return (this.apiData && this.apiData.userPermissions && this.apiData.userPermissions.canEditCandidateProcess === true) || false;
    },
    recruiterUserOptions () {
      return this.getSelectableLocationUserOptions(this.locationUsers, this.editModel.recruiterUserId);
    },
    hiringManagerUserOptions () {
      return this.getSelectableLocationUserOptions(this.locationUsers, this.editModel.hiringManagerUserId);
    },
    coordinatorUserOptions () {
      return this.getSelectableLocationUserOptions(this.locationUsers, this.editModel.coordinatorUserId);
    }
  },
  methods: {
    async refreshApiData () {
      this.apiData = await candidatesManagementService.getCandidateProcess(...this.apiArgs);
    },
    getUserId(user) {
      return (user && user.id) || null;
    },
    async showModal () {
      // Need to query to determine the eligible users for this location
      const locationDetails = await candidatesManagementService.getLocation(this.clientId, this.apiData.locationId);
      this.locationUsers = (locationDetails && locationDetails.users) || [];

      this.editModel = {
        dueDate: dateParse(this.apiData.dueDate),
        candidateDueDate: dateParse(this.apiData.candidateDueDate),
        recruiterUserId: this.getUserId(this.apiData.recruiterUser),
        coordinatorUserId: this.getUserId(this.apiData.coordinatorUser),
        hiringManagerUserId: this.getUserId(this.apiData.hiringManagerUser),
      };

      this.$bvModal.show(this.editModalId);
    },
    resetModal () {
      this.editModel = {};
    },
    async handleEditSubmit (evt) {
      await candidatesManagementService.putCandidateProcess(...this.apiArgs, this.editModel)
      await this.refreshApiData();
      this.$bvModal.hide(this.editModalId);
    },
    getSelectableLocationUserOptions (allLocationUsers, selectedLocationUserId) {
      return !allLocationUsers ? []
        : allLocationUsers.filter(locationUser => {
          const isCurrentlySelected = locationUser.id === selectedLocationUserId;
          return locationUser.isSelectable || isCurrentlySelected;
        });
    }
  },
  watch: {
    apiArgs: {
      immediate: true,
      handler: async function (newVal, oldVal) {
        if (newVal != null && newVal !== oldVal) {
          await this.refreshApiData();
        }
      }
    },
  },
}
</script>

<style>

</style>
