<template>
  <section class="vertical-flex" v-if="apiData">
    <cb-view-header class="vertical-flex-min" :title="apiData.title" :return-link="returnLink" />
    <cb-view-section class="vertical-flex-fill" type="primary" :includePrimaryMessages="true">
      <cb-view-section type="secondary">
        <cb-grid-selector v-bind="apiData.dataCategoryComponent" :onSelection="selectType">
          <cb-grid-selector-item-content slot="item-content" slot-scope="{ item }" :item="item" :iconNameProvider="getIconName" />
        </cb-grid-selector>
      </cb-view-section>
      <div class="d-flex mt-4">
        <button type="button" @click="returnLink.handler()" class="cb-btn back primary flex-fill">{{apiData.back.label}}</button>
      </div>
    </cb-view-section>
  </section>
</template>

<script>
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import CbGridSelector from 'common/components/cb-grid-selector.vue';
import CbGridSelectorItemContent from 'common/components/cb-grid-selector-item-content.vue';

import CompanyInformationIcon from 'forms/company-information.svg';
import NameIcon from 'forms/name.svg';
import AddressIcon from 'forms/address.svg';
import PersonalInformationIcon from 'forms/personal-information.svg';
import GovernmentIssuedIdIcon from 'forms/government-issued-id.svg';
import CertificationIcon from 'forms/certification.svg';

export default {
  name: 'forms-data-entry-collect-data-category',
  components: {
    CbViewHeader,
    CbViewSection,
    CbGridSelector,
    CbGridSelectorItemContent
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    returnLink: {
      /*
        This object is passed directly to the cb-view-header
        component and in addition, its handler
        is called when clicking the back button
      */
      type: Object,
      required: true
    },
    onNext: {
      type: Function,
      required: true
    }
  },
  methods: {
    getIconName (item) {
      const mapping = {
        'Address': AddressIcon.id,
        'Company': CompanyInformationIcon.id,
        'Name': NameIcon.id,
        'PersonalInformation': PersonalInformationIcon.id,
        'GovernmentIssuedId': GovernmentIssuedIdIcon.id,
        'Certification': CertificationIcon.id
      };
      return mapping[item.type];
    },
    selectType (dataCategory) {
      this.onNext(dataCategory);
    }
  },
  mounted () {
    this.$http.get(this.dataUrl).then((response) => {
      this.apiData = response.body;
    });
  },
  data () {
    return {
      apiData: null
    };
  }
}
</script>