<template>
  <div>
    <cb-view-header :title="$t('datafields.create.title')" :subtitle="subtitle" />
    <template v-if="initialized && canCreateType && hasValidParentSchemaId">
      <cb-view-section :type="'secondary'">
        <datafield-crumbs :schemaFieldId="parentSchemaFieldId" allow-current-crumb-link />
      </cb-view-section>
      <savage-form  name="datafields-add-form" :formstate="formstate" :on-submit="save">
        <cb-view-section :type="'secondary'">
          <datafields-add-edit-general
              :parentSchemaField="parentSchemaField"
              :identifier.sync="model.identifier"
              :title.sync="model.title">
          </datafields-add-edit-general>
        </cb-view-section>
        <!--
          We may need a better abstraction for collecting
          limits as we add many more types. Perhaps an extracted
          component.
        -->
        <template v-if="selectedType === DatafieldTypes.string">
          <cb-view-section :type="'primary'">
            <h3 class="datafield-limits-header">{{$t('datafields.settingsHeading')}}</h3>
            <cb-view-section :type="'secondary'">
              <savage-form-text-input
                  :label="$t('datafields.minimumLengthFieldLabel')"
                  :customValidation="minimumLengthCustomValidation"
                  v-model="minimumLengthValue" />
              <savage-form-text-input
                  :label="$t('datafields.maximumLengthFieldLabel')"
                  :customValidation="maximumLengthCustomValidation"
                  v-model="maximumLengthValue" />
              <savage-form-text-input
                  :label="$t('datafields.patternFieldLabel')"
                  :customValidation="patternCustomValidation"
                  v-model="model.limits.pattern" />
            </cb-view-section>
          </cb-view-section>
        </template>
        <cb-view-section :type="'secondary'" class="datafields-add-button-bar">
          <button type="button" class="cb-btn primary" @click="back()">
            {{$t("common.back")}}
          </button>
          <button type="submit" class="cb-btn primary">
            {{$t("common.submit")}}
          </button>
        </cb-view-section>
        <cb-exit-confirmation ref="exitConfirmation" />
      </savage-form>
    </template>
  </div>
</template>

<script>
import AppSettings from 'appSettings';
import CbViewHeader from "general/cb-view-header.vue";
import CbViewSection from "general/cb-view-section.vue";
import { SavageForm, SavageFormTextInput } from "@clickboarding/savage";
import DatafieldTypes, { getDatafieldType } from 'datafields/datafield-types';
import DatafieldCrumbs from 'datafields/datafield-crumbs.vue';
import DatafieldsAddEditGeneral from 'datafields/datafields-add-edit-general.vue';
import { mapGetters, mapActions } from 'vuex';
import 'common/polyfills/integer.polyfill';
import CbExitConfirmation from 'common/components/cb-exit-confirmation.vue';
import isequal from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';

export default {
  name: "datafield-add",
  props: {
    parentSchemaFieldId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  components: {
    DatafieldCrumbs,
    DatafieldsAddEditGeneral,
    CbViewHeader,
    CbViewSection,
    SavageForm,
    SavageFormTextInput,
    CbExitConfirmation,
  },
  data() {
    return {
      formstate: {},
      initialized: false,
      model: {
        identifier: null,
        title: null,
        limits: {
          minLength: null,
          maxLength: null,
          pattern: null
        }
      }
    };
  },
  computed: {
    ...mapGetters('datafields', [
      'findBySchemaFieldId',
      'userCanCreate'
    ]),
    selectedType () {
      return getDatafieldType(this.type) || {};
    },
    canCreateType () {
      return this.selectedType.canCreate || false;
    },
    parentSchemaField () {
      return this.findBySchemaFieldId(this.parentSchemaFieldId);
    },
    hasValidParentSchemaId() {
      return !!(this.parentSchemaField && this.parentSchemaField.allowsNewDatafields);
    },
    typeValue () {
      return this.selectedType.value || null;
    },
    subtitle () {
      return this.selectedType.label || null;
    },
    minimumLengthValue: {
      get () {
        return this.model.limits.minLength;
      },
      set (val) {
        const newval = this.convertToIntIfPossible(val);
        this.model.limits.minLength = newval;
      }
    },
    maximumLengthValue: {
      get () {
        return this.model.limits.maxLength;
      },
      set (val) {
        const newval = this.convertToIntIfPossible(val);
        this.model.limits.maxLength = newval;
      }
    },
    minimumLengthIsInteger () {
      return Number.isSafeInteger(this.model.limits.minLength);
    },
    minimumLengthIsGreaterThanZero () {
      return this.isGreaterThanZero(this.model.limits.minLength);
    },
    maximumLengthIsInteger () {
      return Number.isSafeInteger(this.model.limits.maxLength);
    },
    maximumLengthIsGreaterThanZero () {
      return this.isGreaterThanZero(this.model.limits.maxLength);
    },
    patternIsPattern () {
      return this.isPattern(this.model.limits.pattern);
    },
    minimumLengthCustomValidation () {
      return {
        integer: {
          value: this.minimumLengthIsInteger,
          message: this.$t('datafields.validation.integer')
        },
        min: {
          value: this.minimumLengthIsGreaterThanZero,
          message: this.$t('datafields.validation.min', [0])
        }
      };
    },
    maximumLengthCustomValidation () {
      return {
        integer: {
          value: this.maximumLengthIsInteger,
          message: this.$t('datafields.validation.integer')
        },
        min: {
          value: this.maximumLengthIsGreaterThanZero,
          message: this.$t('datafields.validation.min', [0])
        }
      };
    },
    patternCustomValidation () {
      return {
        validPattern: {
          value: this.patternIsPattern,
          message: this.$t('datafields.validation.validPattern')
        }
      };
    }
  },
  methods: {
    ...mapActions('datafields', [
      'createDatafield',
      'loadIfNeeded'
    ]),
    convertToIntIfPossible (val) {
      const originalVal = val;

      if (!val) return val;

      const convertedVal = /^(-?[1-9]\d*|0)$/.test(val)
          ? parseInt(val, 10)
          : val;

      return convertedVal.toString() === originalVal
          ? convertedVal
          : originalVal;
    },
    isGreaterThanZero (val) {
      // if the value isn't a number, don't perform validation
      // as it leads to confusing false positives
      return typeof val !== "number" || val > 0;
    },
    isPattern (val) {
      if (!val) return false;

      let isValid = true;

      try {
        new RegExp(val);
      } catch(e) {
        isValid = false;
      }

      return isValid;
    },
    async back () {
      await this.handleExit(() => {
        this.$_cb.router.changeView(AppSettings.viewDatafieldsAddTypeSelect, { schemaFieldId: this.parentSchemaFieldId }, null);
      });
    },
    async save () {
      await this.createDatafield({
        ...this.model,
        parentSchemaFieldId: this.parentSchemaFieldId,
        type: this.typeValue
      });
      this.$_cb.router.changeView(AppSettings.viewDatafields, { schemaFieldId: this.parentSchemaFieldId }, null);
    },
    isModelDirty () {
      return !isequal(this.model, this.originalModel);
    },
    async handleExit (action) {
      if (this.isModelDirty()) {
        var confirmed = await this.$refs.exitConfirmation.open();
        if (confirmed) {
          action();
        }
      } else {
        action();
      }
    },
  },
  beforeCreate () {
    this.DatafieldTypes = DatafieldTypes;
  },
  async created() {
    await this.loadIfNeeded();

    if (!this.userCanCreate) {
      this.$_cb.router.changeView(AppSettings.viewAccessDenied, null);
    } else {
      this.originalModel = clonedeep(this.model);
      this.initialized = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@clickboarding/style/mixins';

.datafield-limits-header {
  @include font-size-content-root;

  font-weight: bold;
  margin-bottom: 1rem;
}

.datafields-add-button-bar {
  display: flex;

  button {
    flex: 1;
  }
}
</style>
