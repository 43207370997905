import * as DataEntryActionsProvider from "forms/element-list-item-providers/actions/dataEntry";
import * as DataEntryIconProvider from "forms/element-list-item-providers/icons/dataEntry";
import 'common/polyfills/array.includes.polyfill';

const supportedTypes = [
  'Group',
  'Address'
];

function canHandle (element) {
  return supportedTypes.includes(element.type);
}

function map (element, index, editActionHandler, deleteActionHandler) {
  return {
    label: element.title || element.designer.datafieldTitle,
    actions: DataEntryActionsProvider.get(element, index, editActionHandler, deleteActionHandler),
    icon: DataEntryIconProvider.get(element)
  };
}

export { canHandle, map }