import appSettingsModule from 'common/app-settings.module';
import AppRoutingModule from 'common/app-routing.module';
import 'angular-agility-0.8.23/angular-agility'; // provides 'aa.Notify' module
import UsersCtrl from 'users/users.controller';
import LocationsCtrl from 'locations/locations.controller';
import LocationGroupsCtrl from 'locations/locationGroups.controller';
import locationsListComponent from 'locations/locations-list';

export default angular.module('locations-module', [appSettingsModule, 'aa.notify', AppRoutingModule])
	.controller('UsersCtrl', UsersCtrl)
	.controller('LocationsCtrl', LocationsCtrl)
	.controller('LocationGroupsCtrl', LocationGroupsCtrl)
	.value('locations-list', locationsListComponent)
	.name;
