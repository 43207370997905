<template>
    <section class="access-denied-route">
        <cb-splash title="Access Denied"
                   message="You don't have permission to access this application. If you believe you should have access, contact your administrator."
                   :icon="accessDeniedIcon">
        </cb-splash>
    </section>
</template>
<script>
    import CbSplash from 'common/components/cb-splash.vue';
    import AccessDeniedIcon from 'common/assets/icons/status/access-denied.svg';

    export default {
        name: 'access-denied-route',
        components: {
            CbSplash
        },
        computed: {
            accessDeniedIcon() {
                return '#' + AccessDeniedIcon.id;
            }
        },
    }
</script>
<style scoped lang="scss">
    .access-denied-route {
        padding: 1rem 2rem 2rem;
    }
</style>
