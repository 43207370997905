<template>
  <section>
    <forms-builder :dataUrl="dataUrl" :isReadOnly="isReadOnly" :onBack="onBack" :canPreview="true" :onLoad="formsBuilderOnLoad" ref="formsBuilderInstance">
      <div slot-scope="formsBuilderProps" class="forms-content">
        <template v-if="hasElements">
          <cb-view-section type="secondary" :withFlushTop="true">
            <cb-view-sub-section>
              <savage-form-text-input
                name="formName"
                :label="formsBuilderProps.formLabels.nameLabel"
                :placeholder="formsBuilderProps.formLabels.namePlaceholder"
                :validation="buildFormNameValidation(formsBuilderProps)"
                v-model="formsBuilderProps.form.revision.sections[0].name" />
              <savage-form-text-input
                name="formTitle"
                :label="formsBuilderProps.formLabels.titleLabel"
                :placeholder="formsBuilderProps.formLabels.titlePlaceholder"
                :validation="buildFormTitleValidation(formsBuilderProps)"
                v-model="formsBuilderProps.form.revision.sections[0].pages[0].label" />
            </cb-view-sub-section>
            <cb-view-sub-section v-if="shouldRenderElementsList">
              <cb-list
                :items="formsBuilderProps.getElementListItems()"
                @update:items="formsBuilderProps.updateElementsFromList($event)"
                :useSingleLineLabels="true" 
                :canReorder="!isReadOnly">
                  <template slot="icon" slot-scope="{ item }">
                    <svg>
                      <use v-bind="{'xlink:href': item.icon }"></use>
                    </svg>
                  </template>
                  <template slot-scope="{ item }">
                    {{item.label}}
                  </template>
                  <template v-if="!isReadOnly" slot="action" slot-scope="{ item }">
                    <action-menu :toggle-orientation="'portrait'" :menu-position="'middle-left'" :items="item.actions" />
                  </template>
              </cb-list>
            </cb-view-sub-section>
            <cb-view-sub-section v-if="shouldRenderAddContentButton">
              <div class="d-flex">
                <button type="button"
                  @click="showFormsContentBuild(formsBuilderProps.formLabels.contentBuilder.addContent.url)"
                  class="cb-btn secondary forms-content-add-content-button flex-fill">{{$t('formsBuilder.addContentButton')}}
                </button>
              </div>
            </cb-view-sub-section>
          </cb-view-section>
        </template>
        <cb-view-section type="secondary" :withFlushTop="true" v-show="!shouldRenderElementsList" class="forms-content-build-choice-section">
          <div v-if="!hasElements" class="forms-content-build-title">{{formsBuilderProps.formLabels.contentBuilder.title}}</div>
          <savage-form-validated-container
            fieldName="content-container"
            :customValidation="buildHasContentValidation(formsBuilderProps.formLabels.contentBuilder.noContentMessage)"
            :value="formElements"
            >
            <div v-if="!hasElements" class="forms-content-start-building">
              <div class="background" @click.capture.stop="showFormsContentBuild(formsBuilderProps.formLabels.contentBuilder.start.url)">
                <svg>
                  <use v-bind="{'xlink:href': buildFormIconPath }"></use>
                </svg>
                <button type="button" class="cb-btn secondary startBuildingButton">{{formsBuilderProps.formLabels.contentBuilder.start.label}}</button>
              </div>
            </div>
            <div v-if="!hasElements" class="forms-content-build-or">- {{formsBuilderProps.formLabels.contentBuilder.orLabel}} -</div>
            <div v-if="shouldRenderUploadComponent">
              <cb-file-upload name="uploadfiles"
                @input="uploadedFilesUpdated"
                v-model="uploadedFiles"
                :upload-url="formsBuilderProps.formLabels.contentBuilder.upload.url"
                :max-files="formsBuilderProps.formLabels.contentBuilder.upload.validation.maxFileCount.value"
                :max-file-size-mb="formsBuilderProps.formLabels.contentBuilder.upload.validation.maxFileSizeMB.value"
                :max-file-size-mb-error-message="formsBuilderProps.formLabels.contentBuilder.upload.validation.maxFileSizeMB.message"
                :allowed-content-types="formsBuilderProps.formLabels.contentBuilder.upload.validation.allowedContentTypes.value"
                :allowed-content-types-error-message="formsBuilderProps.formLabels.contentBuilder.upload.validation.allowedContentTypes.message"
                :drop-area-label="formsBuilderProps.formLabels.contentBuilder.upload.message"
                :button-label="formsBuilderProps.formLabels.contentBuilder.upload.label"
                :view-label="formsBuilderProps.formLabels.contentBuilder.upload.viewLabel"
                :delete-label="formsBuilderProps.formLabels.contentBuilder.upload.deleteLabel">
              </cb-file-upload>
            </div>
          </savage-form-validated-container>
        </cb-view-section>
      </div>
      <template slot="add-element" slot-scope="addElementScope">
        <forms-content-build v-bind="addElementScope.componentProps" />
      </template>
      <template slot="edit-element" slot-scope="editElementScope">
        <component :is="editElementScope.componentProps.component" v-bind="editElementScope.componentProps" />
      </template>
    </forms-builder>
  </section>
</template>

<script>
import FormsBuilder from 'forms/forms-builder.vue';
import { SavageFormTextInput, SavageFormMixin, SavageFormValidatedContainer } from '@clickboarding/savage';
import CbFileUpload from 'common/components/cb-file-upload.vue';
import CbViewSection from 'general/cb-view-section.vue';
import CbViewSubSection from 'general/cb-view-sub-section.vue';
import FormsBuilderBus from 'forms/forms-builder-bus';
import clonedeep from 'lodash.clonedeep';
import BuildFormIcon from 'forms/build-form.svg';
import FormsContentBuild from 'forms/forms-content-build.vue';
import CbList from 'common/components/cb-list.vue';
import ActionMenu from 'common/components/action-menu.vue';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';

export default {
  name: 'form-content',
  components: {
    FormsBuilder,
    SavageFormTextInput,
    CbFileUpload,
    CbViewSection,
    CbViewSubSection,
    SavageFormValidatedContainer,
    FormsContentBuild,
    CbList,
    ActionMenu,
    PrimaryMessageBus,
  },
  mixins: [
    // provides the validation directive required by 
    // savage to validate the slot content of validated-container
    SavageFormMixin
  ],
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    onBack: {
      type: Function,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      uploadedFiles: null,
      form: null,
      formElements: null,
      defaultPdfElement: null
    }
  },
  created () {
    this.destroyOnUpdateParentForm = FormsBuilderBus.onUpdateParentForm(this.updateForm);

    this.buildFormIconPath = '#' + BuildFormIcon.id;
  },
  destroyed () {
    if (this.destroyOnUpdateParentForm) this.destroyOnUpdateParentForm();
  },
  computed: {
    hasElements () {
      return this.formElements ? this.formElements.length > 0 : false;
    },
    uploadedFile () {
      let uploadedFile = null;

      if (this.hasElements &&
        'type' in this.formElements[0] &&
        this.formElements[0]['type'] === 'DocumentTemplate') {
          uploadedFile = this.formElements[0];
        }

      return uploadedFile;
    },
    hasUploadedFile () {
      return this.uploadedFile != null;
    },
    shouldRenderElementsList () {
      return this.hasElements && !this.hasUploadedFile;
    },
    shouldRenderAddContentButton () {
      return !this.isReadOnly && this.hasElements && !this.hasUploadedFile;
    },
    shouldRenderUploadComponent () {
      return this.uploadedFiles && (!this.hasElements || this.hasUploadedFile);
    }
  },
  methods: {
      formsBuilderOnLoad(responseBody) {
      this.updateForm(responseBody.form);
      this.defaultPdfElement = this.form.defaultPdfElement;
      this.uploadedFiles = [];

      let uploadedFile = this.uploadedFile;
      if (uploadedFile !== null) {
        this.uploadedFiles.push({
          contentFilename: uploadedFile.designer.contentFilename,
          contentPath: uploadedFile.designer.contentPath
        });
      }
    },
    uploadedFilesUpdated() {
      if (this.uploadedFiles.length > 0) {
        const docTemplateElement = clonedeep(this.defaultPdfElement);
        docTemplateElement.designer.contentPath = this.uploadedFiles[0].contentPath;
        docTemplateElement.designer.contentFilename = this.uploadedFiles[0].contentFilename;

        this.$refs.formsBuilderInstance.addElements([docTemplateElement]);
      }
      else
        this.$refs.formsBuilderInstance.deleteElement(0);

      // Force cb-file-upload to re-render to show the new view
      this.$forceUpdate();
    },
    updateForm (newform) {
      this.form = newform;
      if (this.form) this.formElements = this.form.revision.sections[0].pages[0].elements;
    },
    buildFormNameValidation (props) {
      return {
        required: {
          value: true,
          label: props.formLabels.nameLabel,
          message: props.validationMessages.required
        },
        maxlength: {
          value: 100,
          label: props.formLabels.nameLabel,
          message: props.formLabels.nameLabel + ' must be 100 characters or less.'
        }
      };
    },
    buildFormTitleValidation (props) {
      return {
        maxlength: {
          value: 100,
          label: props.formLabels.titleLabel,
          message: props.formLabels.titleLabel + ' must be 100 characters or less.'
        }
      };
    },
    buildHasContentValidation (message) {
      return {
        'has-content' : {
          value: this.hasElements,
          message: message
        }
      };
    },
    showFormsContentBuild (url) {
      PrimaryMessageBus.$emit('clear-all-messages');
      this.$refs.formsBuilderInstance.showAddElement({
        dataUrl: url
      });
    },
    // showEditElement and deleteElement are needed because 
    // getContentElementActionMenuItems gets called when the template is compiled
    // at which point the $refs are not available.  These methods ensure that
    // the $refs won't be referenced until someone uses the action menu
    showEditElement () {
      this.$refs.formsBuilderInstance.showEditElement(...arguments);
    },
    deleteElement () {
      this.$refs.formsBuilderInstance.deleteElement(...arguments);
    },
  }
}
</script>
<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    .forms-content {
      @include box-sizing-reset;
    }

    .forms-content-build-choice-section {
      padding-top: 1rem;
    }

    .forms-content-build-title {
      font-weight: bold;
      text-align: center;
      margin-bottom: 1rem;
      @include font-size-content-root;
    }

    .forms-content-start-building {
      border: 1px solid $cb-light-grey-1;
      padding: 1rem;

      .background {
        background-color: $cb-teal-6;
        padding: 1rem;
        cursor: pointer;

        &:hover {
          background-color: $cb-teal-5;
        }

        svg {
          height: 8rem;
          width: 100%;
          color: $cb-teal-1;
          display: block;
          padding: .25rem 0 1.5rem 0;
        }

        .cb-btn {
          width: 100%;
          margin: 0 auto;
          display: block;
        }
      }
    }

    .forms-content-build-or {
      text-align: center;
      margin: .5rem;
      @include font-size-content-root;
    }
</style>
