export default function LinkService ($http) {
	return {
		handleClick: function (details, data) {
			return $http({
				method: details.method,
				url: details.url,
				data: data
			});
		}
	};
};

LinkService.$inject = ['$http'];