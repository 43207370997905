var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentItem)?_c('div',{staticClass:"vertical-flex"},[_c('cb-admin-view-title',{staticClass:"vertical-flex-min cb-admin-view-title",attrs:{"title":_vm.headerTitle,"subtitle":_vm.headerSubtitle}},[(_vm.userCanCreate && _vm.inRoot)?_c('b-button',{staticClass:"datafields-create-button",attrs:{"variant":"green","disabled":!_vm.allowsNewDatafields},on:{"click":_vm.create}},[_vm._v("\n      "+_vm._s(_vm.$t("datafields.createButton"))+"\n    ")]):_vm._e()],1),(!_vm.inRoot)?_c('div',{staticClass:"h-line"}):_vm._e(),(!_vm.inRoot)?_c('cb-view-section',{staticClass:"vertical-flex-min pt-0",attrs:{"type":"secondary"}},[_c('datafield-crumbs',{attrs:{"schemaFieldId":_vm.currentItem.schemaFieldId}})],1):_vm._e(),(_vm.inRoot)?[_c('cb-view-section',{staticClass:"vertical-flex-fill pt-0",attrs:{"type":"secondary"}},[_c('div',{staticClass:"toolbar"},[_c('div',{staticClass:"filter-group"},[_c('div',{staticClass:"search-item mr-3"},[_c('b-form-input',{staticClass:"search-input small-input",attrs:{"placeholder":_vm.$t("common.filterSearchPlaceholder", ["..."])},on:{"input":_vm.resetCurrentPage},model:{value:(_vm.searchCriteria),callback:function ($$v) {_vm.searchCriteria=$$v},expression:"searchCriteria"}})],1),(_vm.typeFilterList.length > 1)?_c('multi-select',{attrs:{"options":_vm.typeFilterList,"value":_vm.typeFilter,"search-placeholder":_vm.$t('common.filterSearchPlaceholder', [_vm.$t('datafields.typeColumnHeader').toLowerCase()]),"close-on-select":false,"title":_vm.$t('datafields.typeColumnHeader')},on:{"select":function($event){return _vm.selectTypeFilter($event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
var isSelected = ref.isSelected;
return [_c('b-form-checkbox',{staticClass:"pointer-none",attrs:{"checked":isSelected(option)}}),_c('span',[_vm._v(_vm._s(option))])]}}],null,false,3664405485)}):_vm._e(),_c('b-button',{staticClass:"small-button ml-3 clear-filters-datafields",attrs:{"variant":"outline-green"},on:{"click":_vm.clearFilters}},[_vm._v("\n              "+_vm._s(_vm.$t('common.clearFilters'))+"\n            ")])],1)]),_c('b-table',_vm._b({staticClass:"datafield-list-table new-table",attrs:{"per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage,"items":_vm.sortedCurrentItemList,"fields":_vm.datafieldsTableFields,"sort-by":"title"},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.metadata.displayName)+"\n        ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n          "+_vm._s(item.metadata.displayType)+"\n        ")]}},{key:"cell(view)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewDatafield(item)}}},[_vm._v(_vm._s(_vm.$t('common.view')))])]}}],null,false,703125818)},'b-table',_vm.tableSettings,false)),(_vm.showPagination)?_c('div',{staticClass:"pagination-container"},[_c('b-form-group',{staticClass:"per-page-options",attrs:{"label":"Per page","label-for":"per-page-select","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.paginationSettings.pageOptions,"size":"sm"},model:{value:(_vm.itemsPerOnePage),callback:function ($$v) {_vm.itemsPerOnePage=$$v},expression:"itemsPerOnePage"}})],1),_c('b-pagination',_vm._b({attrs:{"total-rows":_vm.datafieldsCount,"per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},'b-pagination',_vm.paginationSettings,false))],1):_vm._e()],1)]:[_c('datafield-view',{staticClass:"vertical-flex-min",attrs:{"item":_vm.currentItem}}),_c('b-card-group',{staticClass:"vertical-flex-fill primary-section"},[(_vm.isOneOfObject || _vm.allowsDynamicContent)?_c('b-card',{attrs:{"no-body":""}},[(_vm.isOneOfObject)?_c('b-alert',{staticClass:"with-icon w-100",attrs:{"show":"","variant":"info"}},[_c('b-icon',{attrs:{"icon":"alert-circle"}}),_vm._v("\n          "+_vm._s(_vm.$t('datafields.isOneOfMessage'))+"\n        ")],1):_vm._e(),(_vm.allowsDynamicContent)?_c('b-alert',{staticClass:"with-icon w-100",attrs:{"show":"","variant":"info"}},[_c('b-icon',{attrs:{"icon":"alert-circle"}}),_vm._v("\n          "+_vm._s(_vm.$t('datafields.allowsDynamicContentMessage'))+"\n        ")],1):_vm._e()],1):_vm._e(),(_vm.showDatafields)?_c('b-card',{attrs:{"no-body":""}},[_c('b-card-primary-section-header',{attrs:{"slot":"header","title":_vm.$t('datafields.nestedDataFieldsHeading')},slot:"header",scopedSlots:_vm._u([{key:"additional-content",fn:function(ref){
var variant = ref.variant;
return (_vm.userCanCreate)?_c('b-button',{class:['b-link'],attrs:{"href":"javascript:void(0)","variant":variant,"disabled":!_vm.allowsNewDatafields},on:{"click":_vm.create}},[_vm._v("\n              "+_vm._s(_vm.$t("datafields.createButton"))+"\n          ")]):_vm._e()}}],null,true)}),_c('div',{staticClass:"toolbar mb-3 mt-2"},[_c('div',{staticClass:"filter-group"},[_c('div',{staticClass:"search-item mr-3"},[_c('b-form-input',{staticClass:"search-input small-input",attrs:{"placeholder":_vm.$t("common.filterSearchPlaceholder", ["..."])},on:{"input":_vm.resetCurrentPage},model:{value:(_vm.searchCriteria),callback:function ($$v) {_vm.searchCriteria=$$v},expression:"searchCriteria"}})],1),(_vm.typeFilterList.length > 1)?_c('multi-select',{attrs:{"options":_vm.typeFilterList,"value":_vm.typeFilter,"search-placeholder":_vm.$t('common.filterSearchPlaceholder', [_vm.$t('datafields.typeColumnHeader').toLowerCase()]),"close-on-select":false,"title":_vm.$t('datafields.typeColumnHeader')},on:{"select":function($event){return _vm.selectTypeFilter($event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
var isSelected = ref.isSelected;
return [_c('b-form-checkbox',{staticClass:"pointer-none",attrs:{"checked":isSelected(option)}}),_c('span',[_vm._v(_vm._s(option))])]}}],null,false,3664405485)}):_vm._e(),_c('b-button',{staticClass:"small-button ml-3 clear-filters-datafields",attrs:{"variant":"outline-green"},on:{"click":_vm.clearFilters}},[_vm._v("\n                "+_vm._s(_vm.$t('common.clearFilters'))+"\n              ")])],1)]),_c('b-table',_vm._b({staticClass:"new-table",attrs:{"head-variant":null,"sort-by":"title","per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage,"items":_vm.sortedCurrentItemList,"fields":_vm.datafieldsTableFields},scopedSlots:_vm._u([{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.metadata.displayName)+"\n          ")]}},{key:"cell(type)",fn:function(ref){
var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.metadata.displayType)+"\n          ")]}},{key:"cell(view)",fn:function(ref){
var item = ref.item;
return [_c('b-link',{attrs:{"href":"#"},on:{"click":function($event){return _vm.viewDatafield(item)}}},[_vm._v(_vm._s(_vm.$t('common.view')))])]}}],null,false,2025228346)},'b-table',_vm.tableSettings,false)),(_vm.showPagination)?_c('div',{staticClass:"pagination-container"},[_c('b-form-group',{staticClass:"per-page-options",attrs:{"label":"Per page","label-for":"per-page-select","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-sub-page-select","options":_vm.paginationSettings.pageOptions,"size":"sm"},model:{value:(_vm.itemsPerOnePage),callback:function ($$v) {_vm.itemsPerOnePage=$$v},expression:"itemsPerOnePage"}})],1),_c('b-pagination',_vm._b({attrs:{"total-rows":_vm.datafieldsCount,"per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},'b-pagination',_vm.paginationSettings,false))],1):_vm._e()],1):_vm._e()],1)]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }