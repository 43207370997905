import { i18n } from 'common/lang-config';

export default function (limitsObject) {
  const supportedLimits = {
    required: {
      rank: 1,
      label: i18n.t('datafields.limits.required')
    }, 
    minLength: {
      rank: 2,
      label: i18n.t('datafields.limits.minLength')
    },
    maxLength: {
      rank: 3,
      label: i18n.t('datafields.limits.maxLength')
    },
    minItems: {
      rank: 4,
      label: i18n.t('datafields.limits.minItems')
    },
    maxItems: {
      rank: 5,
      label: i18n.t('datafields.limits.maxItems')
    },
    pattern: {
      rank: 6,
      label: i18n.t('datafields.limits.pattern')
    },
    enum: {
      rank: 7,
      label: i18n.t('datafields.limits.enum')
    }
  };



  return Object.keys(limitsObject).sort((a, b) => {
    // 999 as an unreasonably high
    // fallback places the limit
    // at the end if unsupported
    const aRank = supportedLimits[a] ? supportedLimits[a].rank : 999; 
    const bRank = supportedLimits[b] ? supportedLimits[b].rank : 999;

    return aRank - bRank;
  }).map(key => {
    return {
      type: key,
      label: supportedLimits[key] ? supportedLimits[key].label : key, // render key as label if unsupported
      value: limitsObject[key]
    };
  });
}