export default function candidateDetail ($http, $filter, $window, $timeout, aaNotify, appSettings, LinkService) {
  var linker = function (scope, element, attrs) {
  };

  return {
    restrict: "E",
    replace: true,
    link: linker,
    template: require('candidates/candidateDetail.html'),
    scope: {
      detailUrl: "=",
      closeClick: "&"
    }
  };
};

candidateDetail.$inject = ['$http', '$filter', '$window', '$timeout', 'aaNotify', 'appSettings', 'LinkService'];
