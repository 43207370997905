class FormNavigationService {
  constructor() {
    this.nextClickHandler = null;
    this.handleNextClick = this.handleNextClick.bind(this);
    this.setNextClickHandler = this.setNextClickHandler.bind(this);
  }

  handleNextClick () {
    if (this.nextClickHandler) this.nextClickHandler();
  }

  setNextClickHandler (handler) {
    this.nextClickHandler = handler;
  }

  deleteNextClickHandler () {
    this.nextClickHandler = null;
  }
}

export default new FormNavigationService();
