var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('cb-admin-view-title',{staticClass:"cb-admin-view-title",attrs:{"title":_vm.title}},[(_vm.button.label)?_c('b-button',{staticClass:"cb-btn secondary companies-add-button",class:{ 'disabled': _vm.buttonDisabled },attrs:{"variant":"green","disabled":_vm.buttonDisabled},on:{"click":_vm.callAction}},[_vm._v("\n      "+_vm._s(_vm.button.label)+"\n    ")]):_vm._e()],1),(_vm.hasCompanies || _vm.noDataContent)?_c('cb-view-section',{staticClass:"companies-view-banner",attrs:{"includePrimaryMessages":false}},[(_vm.showSearch)?_c('div',{staticClass:"filter-group"},[_c('div',{staticClass:"search-item"},[_c('b-form-input',{staticClass:"search-input company-filter small-input",attrs:{"debounce":300,"placeholder":_vm.$t("companies.filter.placeholder.company")},model:{value:(_vm.searchCriteria),callback:function ($$v) {_vm.searchCriteria=$$v},expression:"searchCriteria"}})],1)]):_vm._e()]):_vm._e(),(!_vm.hasCompanies && _vm.noDataContent)?_c('div',{staticClass:"no-data-container"},[_c('h2',[_vm._v(_vm._s(_vm.noDataContent.title))]),_c('div',{staticClass:"clipboard-pencil-icon"}),_c('p',{staticClass:"no-data-message"},[_vm._v(_vm._s(_vm.noDataContent.message))])]):_vm._e(),_c('cb-view-section',{staticClass:"companies-list-section",attrs:{"type":"secondary","includePrimaryMessages":true}},[(_vm.hasCompanies)?_c('b-table',_vm._b({staticClass:"new-table",attrs:{"per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage,"items":_vm.companies,"sort-by":"name","filter":_vm.searchCriteria,"filter-included-fields":['name', 'referenceName'],"show-empty":"","fields":[
            { key: 'name', label: _vm.columnHeaders.name, thAttr: { 'aria-sort': 'descending' }, sortable: true, sortDirection: 'desc' },
            { key: 'referenceName', label: _vm.columnHeaders.referenceName, sortable: true },
            { key: 'createDate', label: _vm.columnHeaders.createDate, class: 'date-column', sortable: true },
            { key: 'actions', label: '', class: 'action-menu-column', thAttr: { 'aria-label': _vm.$t('companies.actionsColumnHeaderScreenReader') }  }
        ]},on:{"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
        var item = ref.item;
return [_c('action-menu',{attrs:{"toggle-orientation":'landscape',"menu-position":'bottom-left',"items":item.actions}})]}}],null,false,3380603061)},'b-table',_vm.tableSettings,false)):_vm._e(),(_vm.showPagination)?_c('div',{staticClass:"pagination-container"},[_c('b-form-group',{staticClass:"per-page-options",attrs:{"label":"Per page","label-for":"per-page-select","label-align-sm":"right","label-size":"sm"}},[_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.paginationSettings.pageOptions,"size":"sm"},model:{value:(_vm.itemsPerOnePage),callback:function ($$v) {_vm.itemsPerOnePage=$$v},expression:"itemsPerOnePage"}})],1),_c('b-pagination',_vm._b({attrs:{"total-rows":_vm.totalRows,"per-page":_vm.itemsPerOnePage,"current-page":_vm.currentPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},'b-pagination',_vm.paginationSettings,false))],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }