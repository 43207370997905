<template>
 <cb-file-upload name="uploadfiles"
    v-model="localFileValue"
    :upload-url="uploadUrl"
    :max-files="maxFiles"
    :max-file-size-mb="maxFileSizeMb"
    :max-file-size-mb-error-message="maxFileSizeMbErrorMessage"
    :allowed-content-types="allowedContentTypes"
    :allowed-content-types-error-message="allowedContentTypesErrorMessage"
    :drop-area-label="dropAreaLabel"
    :button-label="buttonLabel"
    :view-label="viewLabel"
    :delete-label="deleteLabel">
  </cb-file-upload>
</template>

<script>
import CbFileUpload from 'common/components/cb-file-upload.vue';

export default {
 name: 'cb-single-file-upload',
 components: {
   CbFileUpload,
 },
 model: {
   prop: 'file'
 },
 props: {
   allowedContentTypes: {
     required: true,
     type: Array
   },
   allowedContentTypesErrorMessage: {
     required: true,
     type: String
   },
   buttonLabel: {
     required: true,
     type: String
   },
   deleteLabel: {
     required: true,
     type: String
   },
   dropAreaLabel: {
     required: true,
     type: String
   },
   file: {
     required: true
   },
   maxFileSizeMb: {
     required: true,
     type: Number,
   },
   maxFiles: {
     required: false,
     type: Number,
     default: 1
   },
   maxFilesErrorMessage: {
     required: false,
     type: String
   },
   maxFileSizeMbErrorMessage: {
     required: true,
     type: String
   },
   uploadUrl: {
     required: true,
     type: String
   },
   viewLabel: {
     required: true,
     type: String
   },
 },
 computed: {
   localFileValue: {
     get () {
       return this.file ? [this.file] : [];
     },
     set (v) {
       const updatedFileValue = v.length ? v[0] : null;
       this.$emit('input', updatedFileValue);
     }
   }
 }
}
</script>
