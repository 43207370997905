<template>
  <div>
    <div class="forms-preview-rose-modal-content-container-wrapper">
      <div class="forms-preview-rose-modal-content-container">
        <div class="forms-preview-rose-modal-content">
          <cb-view-title :title="title" :icon="previewIcon" :subtitle="pageTitle" />
          <div class="h-line"></div>
          <rose-acknowledgement
            :roseUrl="dataUrl"
            :onComplete="close"
            :overridePageData="overridePageData"
            ref="roseAcknowledgement"
          />
          <b-pagination
            class="pager"
            per-page="1"
            @change="changePage"
            v-model="currentPage"
            :total-rows="totalPages"
            align="center"
            v-if="multiplePages"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RoseAcknowledgement from "forms/forms-rose-acknowledgement.vue";
import PreviewOnlineIcon from "common/assets/icons/forms/form-preview-online.svg";
import CbViewTitle from "common/components/cb-view-title.vue";

export default {
  name: "forms-preview-rose",
  components: {
    RoseAcknowledgement,
    CbViewTitle,
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "",
      pageTitle: "",
      multiplePages: false,
      totalPages: 0,
      currentPage: 1,
      overridePageData: null,
      allPages: []
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    roseFormStart(form) {
      this.title = form.data.roseFormModel.Name;
      this.pageTitle = form.data.roseFormModel.Pages[0].Label;
      if (this.overridePageData === null) {
        this.multiplePages = form.data.roseFormModel.Pages.length > 1;
        this.totalPages = form.data.roseFormModel.Pages.length;
        this.currentPage = 1;
        this.allPages = form.data.roseFormModel.Pages.map(
          page => page.FormPageId
        );
      }
    },
    async changePage(pageIndex) {
      var response = await this.$http.get(
        this.dataUrl + "&formPageId=" + this.allPages[pageIndex - 1]
      );
      this.overridePageData = response.data;
    }
  },
  created() {
    window.addEventListener("rose:formStart", this.roseFormStart);
  },
  destroyed() {
    window.removeEventListener("rose:formStart", this.roseFormStart);
  },
  computed: {
    previewIcon() {
      return {
        id: "Activity-Title-Personal-Info-Icon"
      };
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";

.pager {
  padding-top: 1rem;
}

.forms-preview-rose-modal-content-container-wrapper {
  @include box-sizing-reset;
  background-color: $cb-slate-1;
  padding: 1.5rem 4rem;
  min-height: 100%;
}

.forms-preview-rose-modal-content-container {
  background-color: $cb-white;
  padding: 1.5rem;
}

.forms-preview-rose-modal-content {
  @include box-sizing-reset;
  width: 75%;
  margin: auto; // center the content
}

@media screen and (max-width: 979px) {
  .forms-preview-rose-modal-content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .forms-preview-rose-modal-content-container-wrapper {
    padding: 0;
  }
}
</style>