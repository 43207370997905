import * as ModelToPresentationMapper from 'forms/data-entry-mappers/model-to-presentation';
import FormsDataEntryElementsLoader from 'forms/forms-data-entry-elements-loader.vue';
import { i18n } from 'common/lang-config';

function get (element, index, editActionHandler, deleteActionHandler) {
  return [{
    label: i18n.t('common.edit'),
    icon: 'Action-Edit-Icon',
    action: async () => {
      const presentationElement = await ModelToPresentationMapper.map(element);

      editActionHandler({
        component: FormsDataEntryElementsLoader,
        elements: [
          {
            index: index,
            element: presentationElement
          }
        ]
      });
    }
  }, {
    label: i18n.t('common.delete'),
    icon: 'Action-Delete-Icon',
    action: () => { deleteActionHandler(index); }
  }];
};

export { get }