<template>
  <div class="action-center-task-todo">
    <button class="action-center-task-todo-toggle-button" :title="todoLabel" @click="toggleCompletion" :disabled="!canChangeStatus">
      <svg :class="[todo.completed ? 'action-center-task-todo-check-complete' : 'action-center-task-todo-check-incomplete', 'action-center-task-todo-check']">
          <use v-bind="{'xlink:href': icons.check }" ></use>
      </svg>
    </button>
    <span class="action-center-task-todo-description">{{ todo.label }}</span>
  </div>
</template>
<script>
import CheckSvg from '@clickboarding/assets/icons/check.svg';

export default {
  name: "action-center-task-todo",
  props: {
    todo: {
      type: Object,
      required: true
    }
  },
  created() {
    this.icons = {
      check: "#" + CheckSvg.id
    };
  },
  computed: {
    todoLabel() {
      if(this.canChangeStatus) {
        if(this.todo.completed) {
          return this.$i18n.t("actionCenter.task.todo.markIncompleteLabel");
        }
        else {
          return this.$i18n.t("actionCenter.task.todo.markCompleteLabel");
        }
      }
    },
    canChangeStatus() {
        return this.todo.url && this.todo.method;
    }
  },
  methods: {
    toggleCompletion() {
      this.$emit('todo-status-changed', {
        todo: this.todo,
        newStatus: !this.todo.completed
      });
    }
  }
};
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.action-center-task-todo {
  display: flex;
  align-items: stretch;
  background-color: $cb-white;
  padding: .75rem .5rem;

  .action-center-task-todo-toggle-button {
    border: none;
    background-color: transparent;
    margin-left: .5rem;
    margin-right: .5rem;

    .action-center-task-todo-check {
      height: 1rem;
      width: 1rem;
      display: block;
    }

    .action-center-task-todo-check-complete {
        color: $cb-green-1;
    }

    .action-center-task-todo-check-incomplete {
        color: $cb-light-grey-1;
    }
  }

  .action-center-task-todo-description {
    padding-left: .5rem;
    padding-right: .5rem;
    @include font-size-content-root;
  }
}
</style>
