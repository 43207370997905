<template>
    <section>
        <cb-admin-view-title class="cb-admin-view-title title-margin" title="Update Specifications">
            <div class="ml-auto candidate-heading-buttons">
                <!-- <b-button class="ml-auto" variant="green" @click="downloadTemplate()">
                    <i class="fa fa-download"></i> Download Template
                </b-button> -->
            </div>
        </cb-admin-view-title>
        <div class="text-muted instructions">
            <div>
                Use this page to add process flows to new or existing candidates.
            </div>
        </div>
        <div class="text-muted instructions">
            <ul class="mini-description">
                <li>Filter the grid to the candidates to whom you want to add process flows.</li>
                <li>Click Export to generate your import template with the data from the grid.</li>
                <li>Enter the required process flow information for each candidate: location, candidate due date, due date,
                    hiring manager, and process flow.</li>
                <ul>
                    <li class="nested-list-item"><strong>Important!</strong> Candidate ID should not be removed as the
                        unique identifier for your import.</li>
                </ul>

                <li>Save the import template as a CSV file.</li>
                <ul>
                    <li class="nested-list-item"><strong>Important!</strong> The CSV file name should be "AddProcessFlow_ "
                        (add your unique file name after the underscore).</li>
                </ul>

            </ul>
        </div>
        <cb-admin-view-title class="cb-admin-view-title title-margin">
            <div>
                <b-form-select id="specTypeDropDown" :options="specOptions" @change="onSpecChange"
                    v-model="selected"></b-form-select>
            </div>
        </cb-admin-view-title>
        <section>
            <div id="collapseIcon" v-on:click="headClickedHandler"><i class="fa fa-chevron-left base-transform rotate"></i>
            </div>
            <b-table class="specsTable" tbody-class="specsBody hidden" responsive="true" :fields="specHeaders"
                :items="specs" @head-clicked="headClickedHandler">                
                <template #cell(description)="data">
                    {{ data.value.text }} <i style="color: red; font-size: smaller">{{ data.value.info }}</i>
                </template>
                <template #cell(validValues)="data">
                    <b-form-group v-if="Array.isArray(specs[data.index].validValues)">
                        <multi-select v-if="specs[data.index].key === 'location'" id="location-select"
                            :options="locationOptions || []" :full-width="true" track-by="name" search-by="name"
                            :value="localLocation"
                            :search-placeholder="$t('common.filterSearchPlaceholder', [$t('locations.title').toLowerCase()])"
                            :close-on-select="true" :title="$t('candidate.processFields.locationLabel')"
                            @select="onChange($event, specs[data.index].key)" @newSearchValue="doLocationSearch"
                            @clickAwayOnSearch="reloadClearedFilters('')">
                            <template v-slot:option="{ option }">
                                {{ option.name }}
                            </template>
                        </multi-select>
                        <b-form-select v-else-if="specs[data.index].key === 'processFlowName'" id="process-flow-select"
                            @change="onChange($event, specs[data.index].key)" :options="processOptions || []"
                            v-bind:disabled="localLocation === null" :full-width="true" value-field="name" text-field="name"
                            :value="null" v-model="selectedProcessFlow">
                            <template #first>
                                <b-form-select-option :value="null">
                                    {{ $t('candidate.processFields.processEmptyOptionLabel') }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                        <b-form-select v-else-if="specs[data.index].key === 'recruiter'" id="recruiter-select"
                            @change="onChange($event, specs[data.index].key)" :options="recruiters || []"
                            v-bind:disabled="localLocation === null" :full-width="true" value-field="emailAddress"
                            text-field="nameWithEmailAddress" :value="null" v-model="selectedRecruiter">
                            <template #first>
                                <b-form-select-option :value="null">
                                    {{ $t('candidate.processFields.teamMemberEmptyOptionLabel') }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                        <b-form-select v-else-if="specs[data.index].key === 'hiringManager'" id="hiring-manager-select"
                            @change="onChange($event, specs[data.index].key)" :options="hiringManagers || []"
                            v-bind:disabled="localLocation === null" :full-width="true" value-field="emailAddress"
                            text-field="nameWithEmailAddress" :value="null" v-model="selectedHiringManager">
                            <template #first>
                                <b-form-select-option :value="null">
                                    {{ $t('candidate.processFields.teamMemberEmptyOptionLabel') }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                        <b-form-select v-else-if="specs[data.index].key === 'coordinator'" id="coordinator-select"
                            @change="onChange($event, specs[data.index].key)" :options="coordinators || []"
                            v-bind:disabled="localLocation === null" :full-width="true" value-field="emailAddress"
                            text-field="nameWithEmailAddress" :value="null" v-model="selectedCoordinator">
                            <template #first>
                                <b-form-select-option :value="null">
                                    {{ $t('candidate.processFields.teamMemberEmptyOptionLabel') }}
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                    </b-form-group>
                    <div v-else-if="!Array.isArray(specs[data.index].validValues)">{{ specs[data.index].validValues }}</div>
                </template>
            </b-table>
        </section>
        <section class="cb-view-section pt-0 uploadAndGrid">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="candidate-filters">
                            <b-button class="clear-filter-button small-button ml-auto" variant="outline-green"
                                @click="clearFilters">
                                Clear Filters
                            </b-button>
                            <b-button class="clear-filter-button small-button" variant="outline-green" @click="onExport">
                                Export
                            </b-button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <ag-grid-vue @grid-ready="onGridReady" class="ag-theme-alpine agGridSizing" :columnDefs="columnDefs"
                            :rowData="rowData" :pagination="true" :paginationPageSize="paginationPageSize"
                            :enableBrowserTooltips="true" :animateRows="true" :sideBar="sideBar"
                            :serverSideInfiniteScroll="true" :defaultColDef="defaultColDef" :suppressContextMenu="false"
                            :getContextMenuItems="getContextMenuItems" :suppressMenuHide="true" :rowSelection="rowSelection"
                            :autoGroupColumnDef="autoGroupColumnDef" :suppressDragLeaveHidesColumns="true"
                            :getServerSideGroupLevelParams="getServerSideGroupLevelParams" :serverSideFilterAllLevels="true"
                            :getChildCount="getChildCount" :getRowId="getRowId" @first-data-rendered="onFirstDataRendered">
                        </ag-grid-vue>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>
<script>
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import importsService from './imports.service';
import importTypes from './importTypes';
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import MultiSelect from 'common/components/multi-select.vue'
import { AgGridVue } from 'ag-grid-vue';

export default {
    name: 'bulk-imports-update-specs',
    components: {
        CbAdminViewTitle,
        MultiSelect,
        AgGridVue,
    },
    computed: {
        ...mapGetters(['clientId'])
    },
    created() {
        this.setDefaultColDef();
        this.rowSelection = 'multiple';
        this.rowGroupPanelShow = 'always';
        this.rowModelType = 'serverSide';
        this.rowData = this.candidateData;

        this.getRowId = (params) => {
            return params.data.rowId;
        };
        this.sideBar = {
            toolPanels: [
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                },
            ],
            position: 'right',
        };
        this.$watch('clearFilter', () => {
            resetFilterSortState();
        });
    },
    data() {
        return {
            columnDefs: null,
            rowData: null,
            candidateData: null,
            loading: false,
            defaultColDef: null,
            filter: true,
            rowModelType: 'serverSide',
            paginationPageSize: importsService.getPageSize(),
            cacheBlockSize: importsService.getPageSize(),
            startRow: 0,
            endRow: importsService.getPageSize(),
            sideBar: null,
            rowSelection: null,
            rowGroupPanelShow: null,
            autoGroupColumnDef: null,
            getServerSideGroupLevelParams: null,
            getChildCount: null,
            getRowId: null,
            columnState: {
                state: [
                    {
                        colId: 'uploadDate',
                        sort: 'desc',
                    },
                ],
            },
            selected: importTypes.PROCESS_FLOW,
            specHeaders: importsService.getUpdateSpecHeaders(),
            specs: [],
            specOptions: importsService.getImportUpdateSpecOptions(importTypes.PROCESS_FLOW),
            tableCollapsed: true,
            locationOptions: null,
            originallocationoptions: null,
            processOptions: null,
            recruiters: null,
            hiringManagers: null,
            coordinators: null,
            locationLoading: false,
            localLocation: null,
            selectedProcessFlow: null,
            selectedRecruiter: null,
            selectedHiringManager: null,
            selectedCoordinator: null
        };
    },
    beforeMount() {
        importsService.getImportSpecs(importTypes.PROCESS_FLOW, this.clientId)
            .then(specsData => {
                this.specs = specsData;
                this.locationOptions = specsData.find(s => s.key === "location").validValues;
                this.originallocationoptions = specsData.find(s => s.key === "location").validValues;
            });

        const self = this;
        self.columnDefs = [
            {
                field: 'candidateApiIdentifier',
                headerName: 'Candidate API Identifier',
                headerTooltip: 'Candidate API Identifier',
                tooltipField: 'candidateApiIdentifier',
                enableRowGroup: false,
            },
            {
                field: 'sourceSystemId',
                headerName: 'Source System ID',
                headerTooltip: 'Source System ID',
                tooltipField: 'sourceSystemId',
                enableRowGroup: false,
            },
            {
                field: 'employeeEmail',
                headerName: 'Employee Email',
                headerTooltip: 'Employee Email',
                tooltipField: 'employeeEmail',
                enableRowGroup: false,
            },
            {
                field: 'location',
                headerName: 'Location',
                headerTooltip: 'Location',
                tooltipField: 'location',
                enableRowGroup: false,
            },
            {
                field: 'processFlow',
                headerName: 'Process Flow',
                headerTooltip: 'Process Flow',
                tooltipField: 'processFlow',
                enableRowGroup: false,
            },
            {
                field: 'candidateDueDate',
                headerName: 'Candidate Due Date',
                headerTooltip: 'Candidate Due Date',
                tooltipField: 'candidateDueDate',
                filter: 'agDateColumnFilter',
                enableRowGroup: false,
                filterParams: {
                    buttons: ['clear'],
                    suppressAndOrCondition: true,
                    inRangeInclusive: true,
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        const dateAsString = cellValue;

                        if (dateAsString == null) {
                            return 0;
                        }

                        // We create a Date object for comparison against the filter date
                        const dateParts = dateAsString.split('/');
                        const year = Number(dateParts[2]);
                        const month = Number(dateParts[0]) - 1;
                        const day = Number(dateParts[1]);
                        const cellDate = new Date(year, month, day);

                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        }
                        return 0;
                    },
                    browserDatePicker: true,
                    includeBlanksInEquals: false,
                    includeBlanksInLessThan: false,
                    includeBlanksInGreaterThan: false,
                    includeBlanksInRange: false,
                },
            },
            {
                field: 'dueDate',
                headerName: 'Due Date',
                headerTooltip: 'Due Date',
                tooltipField: 'dueDate',
                filter: 'agDateColumnFilter',
                enableRowGroup: false,
                filterParams: {
                    buttons: ['clear'],
                    suppressAndOrCondition: true,
                    inRangeInclusive: true,
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        const dateAsString = cellValue;

                        if (dateAsString == null) {
                            return 0;
                        }

                        // We create a Date object for comparison against the filter date
                        const dateParts = dateAsString.split('/');
                        const year = Number(dateParts[2]);
                        const month = Number(dateParts[0]) - 1;
                        const day = Number(dateParts[1]);
                        const cellDate = new Date(year, month, day);

                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        }
                        return 0;
                    },
                    browserDatePicker: true,
                    includeBlanksInEquals: false,
                    includeBlanksInLessThan: false,
                    includeBlanksInGreaterThan: false,
                    includeBlanksInRange: false,
                },
            },
            {
                field: 'recruiter',
                headerName: 'Recruiter',
                headerTooltip: 'Recruiter',
                tooltipField: 'recruiter',
                enableRowGroup: false,
            },
            {
                field: 'hiringManager',
                headerName: 'Hiring Manager',
                headerTooltip: 'Hiring Manager',
                tooltipField: 'hiringManager',
                enableRowGroup: false,
            },
            {
                field: 'coordinator',
                headerName: 'Coordinator',
                headerTooltip: 'Coordinator',
                tooltipField: 'coordinator',
                enableRowGroup: false,
            },
            {
                field: 'candidateCreationDate',
                headerName: 'Candidate Creation Date',
                headerTooltip: 'Candidate Creation Date',
                tooltipField: 'candidateCreationDate',
                filter: 'agDateColumnFilter',
                enableRowGroup: false,
                filterParams: {
                    buttons: ['clear'],
                    suppressAndOrCondition: true,
                    inRangeInclusive: true,
                    comparator: (filterLocalDateAtMidnight, cellValue) => {
                        const dateAsString = cellValue;

                        if (dateAsString == null) {
                            return 0;
                        }

                        // We create a Date object for comparison against the filter date
                        const dateParts = dateAsString.split('/');
                        const year = Number(dateParts[2]);
                        const month = Number(dateParts[0]) - 1;
                        const day = Number(dateParts[1]);
                        const cellDate = new Date(year, month, day);

                        // Now that both parameters are Date objects, we can compare
                        if (cellDate < filterLocalDateAtMidnight) {
                            return -1;
                        } else if (cellDate > filterLocalDateAtMidnight) {
                            return 1;
                        }
                        return 0;
                    },
                    browserDatePicker: true,
                    includeBlanksInEquals: false,
                    includeBlanksInLessThan: false,
                    includeBlanksInGreaterThan: false,
                    includeBlanksInRange: false,
                },
            },
        ];

        this.loadCandidateData();
    },
    methods: {
        onChange(value, key) {
            if (key === "location") {
                document.querySelector("#process-flow-select").disabled = true;
                document.querySelector("#recruiter-select").disabled = true;
                document.querySelector("#hiring-manager-select").disabled = true;
                document.querySelector("#coordinator-select").disabled = true;
                this.setLocalLocation(value);
                this.loadDropDownData(value.id);
            }
        },
        async onSpecChange(importTypeId) {
            importsService.getImportSpecs(importTypeId, this.clientId)
                .then(specsData => {
                    this.specs = specsData;
                    this.locationOptions = specsData.find(s => s.key === "location").validValues;
                    this.originallocationoptions = specsData.find(s => s.key === "location").validValues;
                });
        },
        async downloadTemplate() {
            await importsService.downloadTemplate(this.selected);
        },
        async loadCandidateData() {
            this.loading = true;
            this.candidateData = await importsService.loadCandidateData(this.clientId);
            this.rowData = this.candidateData;
            this.loading = false;
        },
        headClickedHandler(key, field, event, isFooter) {
            $(".rotate").toggleClass("down");
            $(".rotate").toggleClass("base-transform");
            this.tableCollapsed = !this.tableCollapsed;
            if (this.tableCollapsed) {
                document.querySelector(".specsBody").classList.add("hidden");
            } else {
                document.querySelector(".specsBody").classList.remove("hidden");
            }
        },
        async loadDropDownData(locationId) {
            const data = await importsService.getProcessFlowAndTeamMemberData(this.clientId, locationId);
            this.specs.forEach(s => {
                if (s.key === "processFlowName") {
                    s.validValues = data.processFlows;
                    this.processOptions = data.processFlows;
                } else if (s.key === "recruiter") {
                    s.validValues = data.users;
                    this.recruiters = data.users;
                } else if (s.key === "hiringManager") {
                    s.validValues = data.users;
                    this.hiringManagers = data.users;
                } else if (s.key === "coordinator") {
                    s.validValues = data.users;
                    this.coordinators = data.users;
                }
            });

            document.querySelector("#process-flow-select").removeAttribute("disabled");
            this.selectedProcessFlow = null;
            document.querySelector("#recruiter-select").removeAttribute("disabled");
            this.selectedRecruiter = null;
            document.querySelector("#hiring-manager-select").removeAttribute("disabled");
            this.selectedHiringManager = null;
            document.querySelector("#coordinator-select").removeAttribute("disabled");
            this.selectedCoordinator = null;
        },
        setLocalLocation(location) {
            this.localLocation = location;
            this.localLocationId = location.id;
        },
        doLocationSearch(searchData) {
            this.locationOptions = this.originallocationoptions.filter(item => {
                if (item.name.toLowerCase().includes(searchData.searchValue.toLowerCase())) {
                    return item;
                }
            });
        },
        reloadClearedFilters() {
            if (this.originallocationoptions) {
                this.locationOptions = this.originallocationoptions;
            }
        },
        setDefaultColDef: function () {
            this.defaultColDef = {
                sortable: true,
                filter: true,
                resizable: true,
                enableRowGroup: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                menuTabs: ['generalMenuTab', 'filterMenuTab'],
            };
        },
        onExport() {
            const colsToExport = this.gridColumnApi.getAllGridColumns().filter(col => col.colDef.field !== 'candidateCreationDate');
            this.gridApi.exportDataAsCsv({
                fileName: `AddProcessFlow_ImportTemplate.csv`, allColumns: false, columnKeys: [...colsToExport],
                processHeaderCallback(params) {
                    return params.columnApi.getDisplayNameForColumn(params.column, null).replace(/\s/g, "");
                }
            });
        },
        clearFilters() {
            this.gridApi.setFilterModel(null);
            this.gridApi.onFilterChanged();
        },
        getContextMenuItems() {
            return ['copy', 'copyWithHeaders'];
        },
        async onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        },
        async onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.setRowData(this.rowData);
            if (this.gridOptions && this.gridOptions.api) {
                this.gridOptions.api.setFilterModel(null);
            }
        },
        resetFilterSortState() {
            this.gridColumnApi.applyColumnState(this.columnState);
            this.gridApi.setFilterModel(null); //clears all filters
        },
    },
    watch: {
        'localLocationId': {
            handler: debounce(async (newval, oldval) => {
                if (newval !== oldval) {
                    const locationSelected = newval !== null;

                    if (locationSelected) {
                        try {
                            this.locationLoading = true;
                            await this.loadDropDownData(newval);
                            if (this.localLocationId !== newval) return;

                        } finally {
                            this.locationLoading = false;
                        }
                    } else {
                        this.processOptions = null;
                        this.selectedProcessFlow = null
                        this.recruiters = null;
                        this.selectedRecruiter = null;
                        this.hiringManagers = null;
                        this.selectedHiringManager = null;
                        this.coordinators = null;
                        this.selectedCoordinator = null;
                    }
                }
            }, 250, { leading: true, trailing: true }) // debounce options
        }
    }
};
</script>
<style scoped lang="scss">
.candidate-filters {
    display: flex;
    margin-bottom: 1rem;
    margin-top: 1rem;

    &>button {
        margin-right: 1rem;
    }

    &>div:last-of-type {
        margin-right: 0;
    }

    .filter-group {
        display: block;
        flex-grow: 1;
        margin-right: 1rem;

        .filter-item {
            input::placeholder {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 18px;
                color: #838383;
            }
        }
    }
}

.title-margin {
    margin-left: 5px;
}

.instructions {
    margin: -15px 0px 30px 30px;
}

.instructions>div {
    margin-bottom: 1rem;
}

.candidate-heading-buttons {
    &>button {
        margin-right: 1rem;
    }
}

.specsTable {
    width: 97%;
    margin-left: 30px;
}

li.nested-list-item {
    list-style-type: circle;
}

li {
    list-style-type: disc;
}

div#collapseIcon {
    transform: translateX(-3%);
}

div#collapseIcon>i.fa.fa-chevron-left {
    float: right;
    position: relative;
}

div#collapseIcon>i.fa.fa-chevron-left.base-transform {
    transform: translateY(165%);
}

@media screen and (max-width: 1700px) {
    div#collapseIcon>i.fa.fa-chevron-left.base-transform {
        transform: translateY(254%);
    }

    .rotate.down {
        -ms-transform: rotate(-90deg) translateX(-366%) !important;
        -moz-transform: rotate(-90deg) translateX(-366%) !important;
        -webkit-transform: rotate(-90deg) translateX(-366%) !important;
        transform: rotate(-90deg) translateX(-366%) !important;
    }
}

.rotate {
    -moz-transition: all 0.25s linear;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
}

.rotate.down {
    -ms-transform: rotate(-90deg) translateX(-230%);
    -moz-transform: rotate(-90deg) translateX(-230%);
    -webkit-transform: rotate(-90deg) translateX(-230%);
    transform: rotate(-90deg) translateX(-230%);
}

.uploadAndGrid {
    margin-left: 15px;
    margin-right: 15px;
}

.agGridSizing {
    width: 100%;
    height: 400px;
}
</style>
<style lang="scss">
div.popover.b-popover {
    max-width: fit-content;
    width: 110%;
}

div.popover .arrow {
    display: none;
}

td.validValues {
    max-width: 100px !important;
}
</style>
