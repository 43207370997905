<template>
  <div class="cb-upload" v-if="dropAreaLabel" :class="{'with-files': hasFiles}">
    <vue-dropzone ref="myVueDropzone" id="cbDropzone" class="cb-dropzone"
        v-if="!maxFilesReached"
        v-on:vdropzone-sending="onSending"
        v-on:vdropzone-success="onSuccess"
        v-on:vdropzone-error="onError"
        v-on:vdropzone-complete="onComplete"
        v-on:vdropzone-canceled="onCanceled"
        :include-styling="true"
        :options="dropzoneOptions">
    </vue-dropzone>
    <div class="upload-result" v-if="hasFiles" v-for="(file, index) in files" :key="index">
      <svg class="icon">
          <use v-bind="{'xlink:href': fileUploadedIcon }"></use>
      </svg>
      <div class="content">
        {{file.contentFilename}}
      </div>
      <div class="action-menu">
        <action-menu
          :toggle-orientation="'portrait'"
          :menu-position="'middle-left'"
          :items="getActionMenuItems(file, index)">
        </action-menu>
      </div>
    </div>
  </div>
</template>

<script>
import { IconProvider } from 'common/icon.module';
import ActionMenu from 'common/components/action-menu.vue';
import vue2Dropzone from 'vue2-dropzone';
import { UploadXhrStrategy as HttpStrategy } from 'common/http-strategy';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';

//Polyfill for FileList.map to fix issue with Dropzone temporarily until their fix is merged in
//https://gitlab.com/meno/dropzone/merge_requests/31
if (!FileList.map) {
  FileList.prototype.map = function(handler) {
    return Array.from(this).map(handler);
  };
}

export default {
  name: 'cb-file-upload',
  components: {
    vueDropzone: vue2Dropzone,
    ActionMenu
  },
  props: {
    uploadUrl: {
      required: true,
      type: String
    },
    value: {
      type: Array
    },
    maxFileSizeMb: {
      required: true,
      type: Number,
    },
    maxFiles: {
      required: false,
      type: Number,
      default: 1
    },
    maxFilesErrorMessage: {
      required: false,
      type: String
    },
    maxFileSizeMbErrorMessage: {
      required: true,
      type: String
    },
    allowedContentTypesErrorMessage: {
      required: true,
      type: String
    },
    dropAreaLabel: {
      required: true,
      type: String
    },
    buttonLabel: {
      required: true,
      type: String
    },
    viewLabel: {
      required: true,
      type: String
    },
    deleteLabel: {
      required: true,
      type: String
    },
    allowedContentTypes: {
      required: true,
      type: Array
    }
  },
  mounted () {
    this.files = this.value;
    this.setHasFiles();
  },
  methods: {
    onSending (file, xhr, formData) {
      if (this.sendingFile) return;

      this.sendingFile = true;
      PrimaryMessageBus.$emit('clear-all-messages');
      this.loader = HttpStrategy.handlePreRequest(xhr);

      //Dropzone is not triggering their canceled event when an xhr request
      //times out https://gitlab.com/meno/dropzone/issues/23
      //so we will tie into the xhr ontimeout handler directly
      xhr.ontimeout = () => {
        PrimaryMessageBus.$emit('send-error-message', 'Timeout exceeded while attempting to upload the file.');
        HttpStrategy.handleRequestComplete(null, this.loader);
      };
    },
    onCanceled (file) {
      HttpStrategy.handleRequestComplete(null, this.loader);
      this.sendingFile = false;
    },
    onSuccess (file, xhr) {
      let response = JSON.parse(file.xhr.response);
      // slice creates a clone of the array so we aren't mutating the prop directly
      this.files = this.value ? this.value.slice() : [];
      this.files.push({
        contentFilename: file.name,
        contentPath: response.contentPath,
        contentUrl: response.contentUrl,
        contentIsNew: true
      });
      this.setHasFiles();

      this.$emit('input', this.files);
    },
    buildDropzoneButton () {
      let _dropzone = this.$el.querySelector('.cb-dropzone');
      let _button = document.createElement('a');
      let _buttonClass = document.createAttribute("class");
      _buttonClass.value = "cb-btn secondary";
      _button.setAttributeNode(_buttonClass)
      let _buttonText = document.createTextNode(this.buttonLabel);
      _button.appendChild(_buttonText);

      _button.onclick = function () {
        _dropzone.click();
      }

      if(_dropzone) _dropzone.appendChild(_button);
    },
    onError (file, message, xhr) {
      this.sendingFile = false;
      if (xhr) {
        if (file.status === 'error') {
          this.$refs.myVueDropzone.removeFile(file);
        }
        HttpStrategy.handleRequestError(xhr);
      } else if (message) {
        PrimaryMessageBus.$emit('send-error-message', message);
      }
    },
    onComplete (file) {
      this.sendingFile = false;
      HttpStrategy.handleRequestComplete(null, this.loader);
    },
    fileDelete (index) {
      this.files.splice(index);
      this.setHasFiles();
      this.$emit('input', this.files.slice(0));
    },
    async fileDownload (file) {
      const downloadUrl = file.contentIsNew ? file.contentUrl : file.contentPath;
      if (downloadUrl) {
        // IE 11 does not support the download attribute, as a workaround, we use msSaveBlob for browsers that
        // support this method (IE11 & Edge)
        if(window.navigator && window.navigator.msSaveBlob) {
          const response = await this.$http.get(downloadUrl, { responseType: 'blob' });
          const image = await response.blob();
          window.navigator.msSaveBlob(image, file.contentFilename);
        } else {
          var link = document.createElement('a');
          link.style.display = 'none';
          link.href = downloadUrl;
          link.download = file.contentFilename;
          this.$el.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    },
    getActionMenuItems (file, index) {
      let items = [
          {
            icon: 'Action-View-Icon',
            label: this.viewLabel,
            action: async () => { await this.fileDownload(file); }
          },
          {
            icon: 'Action-Delete-Icon',
            label: this.deleteLabel,
            action: () => { this.fileDelete(index); }
          }
      ];

      return items;
    },
    setHasFiles () {
      if (this.files) {
        this.hasFiles = this.files.length > 0 ? true : false;
        this.maxFilesReached = this.files.length === this.maxFiles ? true : false;
      } else {
        this.hasFiles = false;
        this.maxFilesReached = false;
      }
      if (!this.maxFilesReached) {
        this.$nextTick(() => {
            this.buildDropzoneButton();
        });
      }
    },
    renameUploadedFile(file) {
      // We need to encode the file.name when it contains non-ASCII characters
      return encodeURIComponent(file.name);
    }
  },
  computed: {
    dropzoneOptions () {
      return {
        url: this.uploadUrl,
        createImageThumbnails: false,
        maxFilesize: this.maxFileSizeMb,
        dictDefaultMessage: this.dropAreaLabel,
        dictFileTooBig: this.maxFileSizeMbErrorMessage,
        dictMaxFilesExceeded: this.maxFilesErrorMessage,
        dictInvalidFileType: this.allowedContentTypesErrorMessage,
        // acceptedFiles: '',
        maxFiles: this.maxFiles,
        uploadMultiple: false,
        timeout: null, //we are deferring to the API for timeouts for now
        renameFile: this.renameUploadedFile
      };
    }
  },
  data () {
    return {
      loader: null,
      fileUploadedIcon: IconProvider.getPath('Activity-Completed-Icon'),
      fileDownloadUrl: null,
      filesUpdated: false,
      maxFilesReached: false,
      hasFiles: false,
      files: null,
      sendingFile: false
    }
  }
}
</script>
<style lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    .cb-dropzone {
      @include font-size-reset;
      @include box-sizing-reset;

      background-image: url('../assets/icons/actions/upload-file.svg?background');
      background-size: 120px 120px;
      background-position: center 20px;
      background-repeat: no-repeat;
      background-color: $cb-medium-blue-6;
      border: none;
      color: $cb-brand-blue-1;
      min-height: 250px;
      padding: 1em;
      font-weight: bold;
      box-sizing: border-box;

      @media (max-width: 568px) {
        background-size: 100px 100px;
      }

      * {
        box-sizing: border-box;
      }

      &.dz-drag-hover,
      &:hover {
        background-color: $cb-medium-blue-5;
        cursor: pointer;
      }

      .cb-btn {
        width: 100%;
        margin: 0 auto;
        display: block;
      }
      .dz-preview {
        display: none;
      }
      &.dz-started .dz-message {
        text-align: center;
        display: block;
      }
    }
    .cb-dropzone .dz-message {
      text-align: center;
      margin: 8.75em 0 1em;

      @media (max-width: 568px) {
        margin-top: 7.25em;
      }
    }
    .cb-upload {
      padding: 1em;
      border: 1px solid $cb-light-grey-1;

      &.with-files {
        border: none;
        padding: 0;
      }
    }

    .upload-result {
      border: 1px solid $cb-light-grey-1;
      padding: 1em;
      display: flex;
      align-items: center;
      flex-direction: row;

      .form-content .cb-primary-message {
        display: none !important;
      }

      .icon {
        width: 64px; height: 64px;
        margin-right: 1em;
        flex-shrink: 0;
      }

      .action-menu {
        margin-left: auto;

        .action-menu-toggle.portrait {
          margin-left: 1em;
        }
      }
      
      .content {
        word-break: break-word;
      }
    }
    .form-field.disabled {
      // hide dropzone and all action menu items other than "view" when disabled
      .cb-dropzone,
      .action-menu ul li:not(:first-of-type) {
        display: none;
      }
      .cb-upload {
        border: none;
        padding: 0;
      }
      .upload-result .content {
        color: $disabled-text-color;
      }
    }
</style>
