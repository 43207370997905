<template>
    <div class="cb-view-title">
        <h1 v-if="title" class="cb-view-title-title">{{title}}<b-icon icon="info" class="lg-info" id="tooltip-info-target"></b-icon></h1>
        <b-tooltip target="tooltip-info-target" triggers="hover" placement="right" >
            <div>
                <p v-html="infoDescription">{{infoDescription}}</p>
                <a v-if="link" :href="link" target="_blank" style="color: #0aa991 !important; font-weight: bold;">
                    Learn More                    
                </a>
            </div>            
        </b-tooltip>
        <h2 v-if="subtitle" class="cb-view-title-subtitle">{{subtitle}}</h2>
    </div>
</template>

<script>
export default {
  name: 'cb-view-title-with-info-tooltip',
  props: {
    title: {
      type: String,
      required: false
    },
    link: {
      type: String,
      required: false
    },
    infoDescription: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  },
  computed: {
    shouldRenderIcon () {
      return !!this.$slots['icon'] || (this.icon !== null && this.icon !== undefined);
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/mixins';

  .cb-view-title {
    text-align: left;
    word-wrap: break-word;
  }

  .cb-view-title-title {
    font-size: 28px;
    font-family: Arial;
    font-weight: 700;
    margin: 0;
  }

  .cb-view-title-icon svg {
    height: 2rem;
    width: 2rem;
    margin-top: .5em;
    margin-left: .2em;
  }

  .cb-view-title-subtitle {
    @include font-size-content-root;
    font-weight: normal;
    margin: 0;
  }

  .cb-view-title-icon + .cb-view-title-subtitle {
    margin-top: .5rem;
  }
</style>
