export default function candidateCard ($http, $window, $timeout, $compile, appSettings, aaNotify) {
  var linker = function (scope, element, attrs) {
    scope.editView;

    scope.handleShowDetail = function () {
      $window.scrollTo(0, 0);
      scope.showDetail = true;
    };
    scope.handleCloseDetail = function (value) {
      // Call scope.$apply because this function is executed in the candidate-detail
      // vue component which is outside of the angular context.
      scope.$apply(function() {
        scope.showDetail = false;
      });
    };

    scope.handleCloseEdit = function (value) {
      scope.editView.remove();
      scope.editView = null;
      $timeout(function () {
        aaNotify.clearAll();
      }, 100);
    };
  }

  return {
    restrict: "E",
    replace: true,
    link: linker,
    template: require('dashboard/cards/candidateCard.html'),
    scope: {
      data: "=",
      actionDisplayed: "="
    }
  };
};
candidateCard.$inject = ['$http', '$window', '$timeout', '$compile', 'appSettings', 'aaNotify']
