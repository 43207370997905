<template>
  <section class="h-100 reports-route">
    <section class="vertical-flex">
      <cb-admin-view-title class="vertical-flex-min reports-header cb-admin-view-title" :title="displayTitle" />
      <cb-view-section class="vertical-flex-fill" type="secondary" :includePrimaryMessages="true">
        <div class="report-filters">
          <b-button class="small-button ml-3" variant="outline-green" @click="clearFilters">
            {{ $t('common.clearFilters') }}
          </b-button>
            <b-dropdown v-if="showExportButton" id="dropdown-1" variant="outline-green" text="Export" class="small-button ml-3">
              <b-dropdown-item @click="downloadFileClicked('csv')">CSV</b-dropdown-item>
              <b-dropdown-item @click="downloadFileClicked('excel')">Excel</b-dropdown-item>
            </b-dropdown>
        </div>
        <div v-if="loading" class="cfp-loading-bar"><div></div></div>
        <report-base class="vertical-flex-fill" 
          :reportType="reportType" 
          :dataUrl="dataUrl"
          :clearFilter="clearFilterToggle" 
          :downloadFile="downloadTrigger" 
          :exportThreshold="exportThreshold"
          @exportLimitExceeded="showErrorModal = !showErrorModal"
          @loadingExportResults="showSpinner"/>
        </cb-view-section>
    </section>
    <b-modal 
      no-close-on-backdrop 
      centered 
      ok-only
      v-model="showErrorModal">
       Exports cannot exceed {{this.exportThreshold}} rows.     
    </b-modal>
  </section>
</template>

<script>
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import CbViewSection from "general/cb-view-section.vue";
import appSettings from 'appSettings';
// import { AgGridVue } from "ag-grid-vue";
// import ReportsService from './reports.service';
import ReportBase from './reportbase.vue';
export default {
  name: "reports-route",
  data() {
    return {
      clearFilterToggle: new Date(),
      downloadTrigger: {},
      showExportButton: false,
      showErrorModal: false,
      exportThreshold: 0,
      loading: false,
    }
  },
  components: {
    CbAdminViewTitle,
    CbViewSection,
    // AgGridVue,
    ReportBase
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    reportType: {
      type: String,
      required: true
    }
  },
  created() {
    if (appSettings.hasOwnProperty("showReportExportButton")) {
      this.showExportButton = JSON.parse(appSettings.showReportExportButton);
    }
    if(appSettings.hasOwnProperty("reportingExportThreshold")) {
      this.exportThreshold = JSON.parse(appSettings.reportingExportThreshold);
    }
  },
  methods: {
    clearFilters() {
      //hack to trigger the prop change event
      this.clearFilterToggle = new Date();
    },
    downloadFileClicked(exportType) {
      this.downloadTrigger = { type: exportType, trigger: new Date() };
    },
    showSpinner(isLoading) {
      this.loading = isLoading;
    }
  },
  computed: {
    displayTitle() {
      return this.$t(`reports.reportTypeEnumMap.${this.reportType}.title`);
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";

.reports-route .reports-header {
  /deep/ .header-content {
    margin-top: 0.625rem;
    padding: 1.25rem;
    border-bottom: 0rem;
  }
}

section.reports-route {

  div.report-name {
    padding: 1.5rem;
  }

  .cb-view-section {
    border-bottom: 0rem;
  }

  p.no-reports {
    border-top: 1px solid $cb-dark-grey-6;
    padding: 3rem;
    text-align: center;
    font-size: 0.8rem;
  }
}

.report-filters {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: row-reverse;

  &>div {
    margin-right: 1rem;
  }

  &>div:last-of-type {
    margin-right: 0;
  }

}

</style>
      