<template>
  <div class="cb-view-title">
    <h1 v-if="title" class="cb-view-title-title">{{title}}</h1>
    <div class="cb-view-title-icon" v-if="shouldRenderIcon">
      <slot name="icon">
        <svg v-if="icon">
          <use v-bind="{'xlink:href': `#${icon.id}`}"></use>
        </svg>
      </slot>
    </div>
    <h2 v-if="subtitle" class="cb-view-title-subtitle">{{subtitle}}</h2>
  </div>
</template>

<script>
export default {
  name: 'cb-view-title',
  props: {
    title: {
      type: String,
      required: false
    },
    icon: {
      type: Object,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    }
  },
  computed: {
    shouldRenderIcon () {
      return !!this.$slots['icon'] || (this.icon !== null && this.icon !== undefined);
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/mixins';

  .cb-view-title {
    text-align: left;
    word-wrap: break-word;
  }

  .cb-view-title-title {
    font-size: 28px;
    font-family: Arial;
    font-weight: 700;
    margin: 0;
  }

  .cb-view-title-icon svg {
    height: 2rem;
    width: 2rem;
    margin-top: .5em;
  }

  .cb-view-title-subtitle {
    @include font-size-content-root;
    font-weight: normal;
    margin: 0;
  }

  .cb-view-title-icon + .cb-view-title-subtitle {
    margin-top: .5rem;
  }
</style>
