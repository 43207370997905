import CompaniesController from "companies/companies.controller";
import CompaniesRouteComponent from "companies/companies-route.vue";
import CompaniesAddController from "companies/companies-add.controller";
import CompaniesAddRouteComponent from "companies/companies-add-route.vue";

export default angular
    .module("companies-module", [])
    .controller("companiesController", CompaniesController)
    .controller("companiesAddController", CompaniesAddController)
    .value("companies-route", CompaniesRouteComponent)
    .value("companies-add-route", CompaniesAddRouteComponent)
    .name;
