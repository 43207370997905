import appSettings from "appSettings";
import { AxiosLocal as axios } from "common/services/axiosLocal.service";

function adminApiUsersControllerBaseUrl(clientId) {
  if (!clientId) {
    throw new Error(
      "The clientId parameter is required."
    );
  }

  return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Sections/User/Users`;
}

export default {
  async getViewTab(clientId) {
    const apiUrl = adminApiUsersControllerBaseUrl(clientId);
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async getUsers (url, params) {
    const response = await axios.get(url, { params });

    return response.data;
  },
  async getUsersFilters (url) {
    const response = await axios.get(url);

    return response.data;
  },
  async getEditSettings(url) {
    const response = await axios.get(url);

    return response.data;
  }
};
