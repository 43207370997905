<template>
  <section class="vertical-flex">
    <cb-view-header class="vertical-flex-min" :title="$t('datafields.create.title')" />
    <cb-view-section class="vertical-flex-min" type="secondary">
      <datafield-crumbs :schemaFieldId="parentSchemaFieldId" allow-current-crumb-link />
    </cb-view-section>
    <cb-view-section class="vertical-flex-fill" type="primary" :includePrimaryMessages="true">
      <cb-view-section type="secondary">
        <cb-grid-selector :label="$t('datafields.create.typeSelectQuestion')" :items="this.sortedDatafieldTypes" :onSelection="typeClick">
          <cb-grid-selector-item-content slot="item-content" slot-scope="{ item }" :item="item" :iconNameProvider="getIconName" />
        </cb-grid-selector>
      </cb-view-section>
      <button type="button" @click="backClick()" class="mt-4 w-100 cb-btn back primary flex-fill">{{$t('common.back')}}</button>
    </cb-view-section>
  </section>
</template>

<script>
import AppSettings from 'appSettings';
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import CbGridSelector from 'common/components/cb-grid-selector.vue';
import CbGridSelectorItemContent from 'common/components/cb-grid-selector-item-content.vue';
import DatafieldCrumbs from 'datafields/datafield-crumbs.vue';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import datafieldTypes from 'datafields/datafield-types';
import { mapGetters, mapActions } from 'vuex';
import DateIcon from 'datafields/date.svg';
import ObjectIcon from 'datafields/field-group-object.svg';
import StringIcon from 'datafields/text-form-field.svg';
import ArrayIcon from 'datafields/collection-array.svg';
import PhoneIcon from 'datafields/phone-number.svg';

export default {
  name: 'datafields-add-type-select',
  components: {
    CbViewHeader,
    CbViewSection,
    CbGridSelector,
    CbGridSelectorItemContent,
    DatafieldCrumbs
  },
  props: {
    parentSchemaFieldId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('datafields', [
      'userCanCreate',
      'findBySchemaFieldId'
    ]),
    parentSchemaField () {
      return this.findBySchemaFieldId(this.parentSchemaFieldId)
    },
    sortedDatafieldTypes() {
      const sortedTypes = Object.values(datafieldTypes);

      sortedTypes.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

      return sortedTypes.filter(t => t.canCreate && this.parentSchemaField.disallowedNestedTypes.indexOf(t.value) < 0);
    }
  },
  methods: {
    ...mapActions('datafields', [
      'loadIfNeeded'
    ]),
    backClick() {
      this.$_cb.router.changeView(AppSettings.viewDatafields, { schemaFieldId: this.parentSchemaFieldId }, null);
    },
    typeClick(item) {
      this.$_cb.router.changeView(AppSettings.viewDatafieldsAdd, { schemaFieldId: this.parentSchemaFieldId, type: item.normalizedValue }, null);
    },
    getIconName (item) {
      const mapping = {
        'Date': DateIcon.id,
        'Object': ObjectIcon.id,
        'String': StringIcon.id,
        'Array': ArrayIcon.id,
        'Phone': PhoneIcon.id
      };
      return mapping[item.value];
    }
  },
  async created () {
    await this.loadIfNeeded();

    if (!this.userCanCreate) this.$_cb.router.changeView(AppSettings.viewAccessDenied, null);
  },
  destroyed () {
    PrimaryMessageBus.$emit('clear-all-messages');
  }
}
</script>
