import appSettings from "appSettings";
import { AxiosLocal as axios } from "common/services/axiosLocal.service";

function adminApiCandidatesControllerBaseUrl(clientId) {
  if (!clientId) {
    throw new Error(
      "The clientId parameter is required."
    );
  }

  return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Sections/Candidate/Tabs/View`;
}

export default {
  async getViewTab(clientId) {
    const apiUrl = adminApiCandidatesControllerBaseUrl(clientId);
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async getProcessFlowCreateInfo(clientId) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/ProcessFlows`;
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async getCandidate(clientId, candidateId) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates/${encodeURIComponent(candidateId)}`;
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async getCandidatesFilters (url) {
    const response = await axios.get(url);

    return response.data;
  },
  async getCandidateFilterPage (url) {
    const response = await axios.get(url);
    return response.data;
  },
  async getCandidates (url, params) {
    const response = await axios.get(url, { params });

    return response.data;
  },
  async postCandidate(clientId, postBody) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates`;
    await axios.post(apiUrl, postBody);
  },
  async putCandidate(clientId, candidateId, putBody) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates/${encodeURIComponent(candidateId)}`;
    await axios.put(apiUrl, putBody);
  },
  async gotoCandidateUI(clientId, candidateId, ui) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates/${encodeURIComponent(candidateId)}/GoToCandidate/${ui}`;
    const response = await axios.get(apiUrl);
    return response.data;
  },
  async postCandidateProcess(clientId, candidateId, postBody) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates/${encodeURIComponent(candidateId)}/Processes`;
    await axios.post(apiUrl, postBody);
  },
  async getCandidateProcess(clientId, candidateId, processId) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates/${encodeURIComponent(candidateId)}/Processes/${encodeURIComponent(processId)}`;
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async putCandidateProcess(clientId, candidateId, processId, putBody) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates/${encodeURIComponent(candidateId)}/Processes/${encodeURIComponent(processId)}`;
    await axios.put(apiUrl, putBody);
  },
  // API CALL For getting Location
  async getLocation(clientId, locationId) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Locations/${encodeURIComponent(locationId)}`;
    const response = await axios.get(apiUrl);

    return response.data;
  },
  async postPasswordAndSecurityQuestionResetInvite(clientId, candidateId) {
    const apiUrl = `${adminApiCandidatesControllerBaseUrl(clientId)}/Candidates/${encodeURIComponent(candidateId)}/PasswordResetFull`;
    await axios.post(apiUrl);
  },
};
