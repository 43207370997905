<template>
  <div class="multi-edit">
    <p>{{ selectedIds.length }} selected items</p>
    <div class="buttons-wrapper">
      <b-button v-for="item in batch.actions"
                variant="outline-primary"
                :class="`action-${item.title.toLowerCase()}`"
                :key="item.title"
                @click="handleClick(item)">
        {{ buttonTitles[item.typeEnum] || item.title }}
      </b-button>
    </div>

    <b-modal v-if="data"
             id="multi-edit-list-prompt-modal"
             :hide-header="true"
             static
             lazy
             :ok-title="data.actLabel"
             :cancel-title="data.closeLabel"
             @ok="doneClick">
      <p class="my-4">{{ data.itemLabel }}</p>
      <form ref="form" @submit.stop.prevent="doneClick">
        <b-form-group :state="selectedTemplateState"
                      invalid-feedback="Email template is required">
          <b-form-select v-model="selectedTemplate"
                         required
                         :state="selectedTemplateState"
                         :options="data.items"
                         value-field="event"
                         text-field="name"
          ></b-form-select>
        </b-form-group>
      </form>
    </b-modal>

    <b-modal v-if="currentItem && currentItem.actionTypeEnum === 'Confirm'"
             id="multi-edit-confirm-modal"
             :hide-header="true"
             static
             lazy
             :ok-title="currentItem.data.okLabel"
             :cancel-title="currentItem.data.cancelLabel"
             @ok="action('put', currentItem, config, queryParams)">
      <p class="my-4">{{ currentItem.data.confirmLabel }}</p>
    </b-modal>
  </div>
</template>

<script>
import {AxiosLocal as axios} from "common/services/axiosLocal.service";

export default {
  name: "multiEdit",
  props: {
    batch: {
      type: Object,
      required: true
    },
    selectedIds: {
      type: Array
    },
    generateQuery: {
      type: [Function, undefined]
    },
    buttonTitles: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      currentItem: undefined,
      config: undefined,
      queryParams: undefined,
      selectedTemplate: undefined,
      selectedTemplateState: null,
      data: undefined
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.selectedTemplateState = valid
      return valid
    },
    async handleClick(item) {
      this.currentItem = item
      this.config = {ids: this.selectedIds, typeEnum: item.typeEnum, data: item.data}
      this.queryParams = this.generateQuery ? this.generateQuery() : null
      if (item.actionTypeEnum === 'Act') {
        if (item.event.verb === "PUT")
          await this.action('put', item, this.config, this.queryParams)
        else if (item.event.verb === "POST")
          await this.action('post', item, this.config, this.queryParams)
      }
      // Show popup with select
      else if (item.actionTypeEnum === "ListPrompt") {
        const {data} = await axios.get(item.event.url)
        this.data = data
        this.$nextTick(() => {
          this.$bvModal.show('multi-edit-list-prompt-modal');
        })
      } else if (item.actionTypeEnum === "Confirm") {
        this.$nextTick(() => {
          this.$bvModal.show('multi-edit-confirm-modal');
        })
      }
    },
    async action(actionType, item, config, params) {
      const {data} = await axios[actionType](item.event.url, config, {params: params})

      this.$emit('update', data)
    },
    async doneClick(bvModalEvt) {
      bvModalEvt.preventDefault()
      if (!this.checkFormValidity()) {
        return
      }

      if (this.selectedTemplate.verb === "POST") {
        await axios.post(this.selectedTemplate.url, {
          ids: this.selectedIds,
          typeEnum: this.currentItem.typeEnum,
          data: this.currentItem.data
        })

        this.$emit('update')
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
