export default function ProcessesListDirective(
  $location,
  appSettings,
  locationSvc,
  ProcessServices
) {
  var linker = function(scope) {
    scope.editItem = [];
  	scope.$watch("data", function(newval, oldval) {
  	  if (newval) {
    		scope.data = newval;
    		ProcessServices.processFlows = scope.data.processFlows;
  	  }
  	});

  	scope.editProcess = function(message) {
  	  alert(message);
  	  // scope.$apply();
  	};

  	scope.addProcess = function() {
  	  scope.$apply();
  	};

  	scope.handleClose = function (value, id) {
  	  scope.showAdd = false;

      if (value && value.getUrl) locationSvc.changeView(appSettings.viewProcess, value.getUrl);
  	};

  	scope.addProcessFlow = function () {
  	  scope.showAdd = true;
  	  scope.$apply();
  	};
  };

  return {
  	restrict: "E",
  	replace: true,
  	link: linker,
  	template: require('processes/processes.html'),
  	scope: {
  	  data: "="
  	}
  };
}

ProcessesListDirective.$inject = [
  "$location",
  "appSettings",
  "locationSvc",
  "ProcessServices"
];
