<template>
  <section class="vertical-flex">
    <template v-if="activity">
      <cb-view-header
        :title="activity.label" 
        :return-link="returnLink" 
        class="vertical-flex-min"
      />
      <activity-edit
        v-model="activities"
        @activity-edit-cancel="cancelEdit"
        @activity-edit-submit="editActivityAsync"
        class="vertical-flex-fill"
      />
    </template>
  </section>
</template>
<script>
import CbViewHeader from "general/cb-view-header.vue";
import CbViewSection from "general/cb-view-section.vue";
import AppSettings from 'appSettings';
import ActivityEdit from 'processes/steps/activities/activity-edit.vue';

export default {
  name: "activity-edit-route",
  components: {
    CbViewHeader,
    CbViewSection,
    ActivityEdit
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    }
  },
  async mounted() {      
    await this.loadDataAsync(this.dataUrl);
  },
  methods: {
    async loadDataAsync(url) {
      var response = await this.$http.get(url);
      this.activity = response.data;
    },
    async editActivityAsync (url) {
      const putBody = this.activity;
      await this.$http.put(this.activity.submit.url, putBody);
      this.$_cb.router.changeView(AppSettings.viewProcess, this.activity.returnLink.url);
    },
    cancelEdit() {
      this.$_cb.router.changeView(AppSettings.viewProcess, this.activity.returnLink.url);
    },
  },
  computed: {
    activities: {
      get() {
        return [this.activity];
      },
      set(v) {
        this.activity = v[0];
      }
    },
    returnLink() {
      return {
      label: this.activity.returnLink.label,
        handler: () => {
          this.$_cb.router.changeView(AppSettings.viewProcess, this.activity.returnLink.url);
        }
      }
    }
  },
  data() {
    return {
      activity: null
    }
  }
};
</script>
<style scoped lang="scss">

.button-container {
  display: flex;
  button {
    flex-grow: 1;
  }
}
</style>