var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('cb-admin-view-title',{staticClass:"cb-admin-view-title title-margin",attrs:{"title":"Import Specifications"}},[_c('div',{staticClass:"ml-auto candidate-heading-buttons"},[_c('b-button',{staticClass:"ml-auto",attrs:{"variant":"green"},on:{"click":function($event){return _vm.downloadTemplate()}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" Download Template\n            ")])],1)]),_vm._m(0),_c('cb-admin-view-title',{staticClass:"cb-admin-view-title title-margin"},[_c('div',[_c('b-form-select',{attrs:{"id":"specTypeDropDown","options":_vm.specOptions},on:{"change":function($event){return _vm.onSpecChange($event)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)]),_c('section',[_c('b-table',{staticClass:"specsTable",attrs:{"fields":[
            {
                key: 'field',
                label: 'Field',
                thStyle: { width: '50px' },
                sortable: false,
            },
            {
                key: 'dataType',
                label: 'Data Type',
                thStyle: { width: '50px' },
                sortable: false,
            },
            {
                key: 'required',
                label: 'Required',
                thStyle: { width: '50px' },
                sortable: false,
            },
            {
                key: 'description',
                label: 'Description',
                thStyle: { width: '50px' },
                sortable: false,
            },
            {
                key: 'validValues',
                label: 'Valid Values',
                thStyle: { width: '50px' },
                sortable: false,
                tdClass: 'validValues',
            } ],"items":_vm.specs}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-muted instructions"},[_c('div',[_vm._v("\n            For a successful import, please download and use the provided\n            templates. Complete the templates according to the information\n            provided below.\n        ")])])}]

export { render, staticRenderFns }