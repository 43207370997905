<template>
  <section class="cb-page-callout" v-if="show">
    <div class="cb-page-callout_content">
      <p v-if="content">
        {{content}}
        <button class="cb-btn link" v-if="action" @click="action.action()">{{action.label}}</button>
      </p>
    </div>
  </section>
</template>

<script>
import CloseIcon from '@clickboarding/assets/icons/x-large.svg';

export default {
  name: 'cb-page-callout',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: false
    },
    action: {
      type: Object,
      required: true,
      validator: function(value) {
        return 'action' in value && 'label' in value;
      }
    }
  },
  created () {
    this.closeIcon = '#' + CloseIcon.id;
  },
  methods: {
    close () {
      this.show = false;
    }
  },
  data () {
    return {
      show: true
    }
  },
  created () {
    this.closeIcon = '#' + CloseIcon.id;
  },
  methods: {
    close () {
      this.show = false;
    }
  },
  data () {
    return {
      show: true
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';

  .cb-page-callout {
    position: relative;
    width: 100%;
    @include font-size-content;
    display: flex;
    flex-direction: column;
    background: $cb-white;
    margin-bottom: .5rem;

    p {
      margin-bottom: 0;
    }

    .cb-btn.link {
      display: inline;
    }

    &_content {

      h3 {
        font-weight: bold;
        font-size: 1.25rem;
        margin-bottom: .5rem;
      }
    }
  }
</style>
