import store from '../store'
import appSettings from "../appSettings";

function baseUrl() {
    let clientId = store.getters.clientId;
    if (!clientId) {
        throw new Error(
            "The clientId parameter is required."
        );
    }
    return `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(clientId)}/Sections/Candidate/Tabs/View/Candidates`;
}

export default {
    async getCandidateInfo(candidateId) {
        return `${baseUrl()}/${encodeURIComponent(candidateId)}`;
    },
}
