<template>
  <section class="step-tile">
    <header>
      <action-menu
        :toggle-orientation="'landscape'"
        :menu-position="'bottom-right'"
        :items="stepActionMenuItems"
      ></action-menu>
      <h4 class="step-tile-header-content">
        <span class="step-number">{{step.ordinal}}</span>
        <span class="step-name">{{step.name}}</span>
      </h4>
      <div class="event-icons">
        <cb-event-icon-display v-if="hasStepEvents" :events="stepEvents" />
      </div>
    </header>
    <step-tile-activities
      :activities.sync="step.activityCollection.activities"
      :parent-id="step.stepId"
      :on-activity-delete-send="onActivityDeleteSend"
      :on-activity-edit="onActivityEdit"
      :on-activity-reorder="onFormReorder"
    ></step-tile-activities>
    <section class="step-tile-button-container">
      <div class="step-reorder d-flex">
        <div class="back flex-fill" v-if="!isFirst">
          <button class="step-reorder-button" @click="onReorder(step, step.ordinal - 1)">
            <svg>
              <use v-bind="{'xlink:href': reorderBackIconPath }" />
            </svg>
          </button>
        </div>
        <div class="forward flex-fill" v-if="!isLast">
          <button class="step-reorder-button" @click="onReorder(step, step.ordinal + 1)">
            <svg>
              <use v-bind="{'xlink:href': reorderForwardIconPath }" />
            </svg>
          </button>
        </div>
      </div>
      <button class="cb-btn primary" @click="activityAddOpen(step)">{{addActivitiesLabel}}</button>
    </section>
  </section>
</template>
<script>
import { IconProvider } from "common/icon.module";
import StepTileActivities from "processes/builder/step-tile-activities.vue";
import ActionMenu from "common/components/action-menu.vue";
import CbEventIconDisplay from "general/cb-event-icon-display.vue";

export default {
  name: "step-tile",
  components: {
    ActionMenu,
    StepTileActivities,
    CbEventIconDisplay
  },
  methods: {
    activityAddOpen(step) {
      this.$emit("on-activity-add", step);
    }
  },
  props: {
    step: {
      type: Object,
      required: true
    },
    addActivitiesLabel: {
      type: String,
      required: true
    },
    editLabel: {
      type: String,
      required: true
    },
    deleteLabel: {
      type: String,
      required: true
    },
    onEditOpen: {
      type: Function,
      required: true
    },
    onDeleteSend: {
      type: Function,
      required: true
    },
    onReorder: {
      type: Function,
      required: true
    },
    onActivityDeleteSend: {
      type: Function,
      required: true
    },
    onActivityEdit: {
      type: Function,
      required: true
    },
    onFormReorder: {
      type: Function,
      required: true
    },
    isFirst: {
      type: Boolean,
      required: true
    },
    isLast: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    reorderBackIconPath() {
      return IconProvider.getPath("Generic-Left-Arrow-Icon");
    },
    reorderForwardIconPath() {
      return IconProvider.getPath("Generic-Right-Arrow-Icon");
    },
    stepActionMenuItems() {
      return [
        {
          label: this.editLabel,
          icon: "Action-Edit-Icon",
          action: () => {
            this.onEditOpen(this.step);
          }
        },
        {
          label: this.deleteLabel,
          icon: "Action-Delete-Icon",
          action: e => {
            this.onDeleteSend(e, this.step.deleteUrl, this.stepDeleteLabels);
          }
        }
      ];
    },
    stepDeleteLabels() {
      const stepDeleteMessage =
        this.step && this.step.stepTypeId === this.eSigStepTypeId
          ? this.$t("processBuilder.deleteESigStepWarning")
          : this.$t("processBuilder.deleteStepWarning");

      return {
        title: "",
        message: stepDeleteMessage,
        okLabel: this.$t("common.delete"),
        closeLabel: this.$t("common.cancel")
      };
    },
    stepEvents() {
      return this.step && this.step.events && this.step.events.length > 0
        ? this.step.events
        : [];
    },
    hasStepEvents() {
      return this.stepEvents.length > 0;
    }
  },
  data() {
    return {
      eSigStepTypeId: 16001
    };
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";

.step-tile {
  @include font-size-content-root;
  background-color: $cb-white;
  border: 1px solid $cb-light-grey-1;
  display: flex;
  flex-direction: column;
  height: 100%;

  header {
    position: relative;

    & > .action-menu {
      position: absolute;
      bottom: 50%;
      left: 0;
      transform: translate(50%, 0);
    }

    h4.step-tile-header-content {
      margin-bottom: 0.5rem;
    }

    .event-icons {
      padding: 0 0.5rem 0.5rem 0.5rem;
      text-align: center;
      min-height: 17px;
    }

    section.action-menu {
      top: 1.75rem;
      bottom: 55%;
    }
  }

  .step-tile-header-content {
    @include font-size-content-root;

    text-align: center;
    font-weight: bold;
    display: block; /* overriding a global style on Site.scss of 'inline-block' */
    color: $cb-dark-grey-1;
    margin: 1rem;

    .step-number {
      border: 2px solid $cb-dark-grey-1;
      border-radius: 2rem;
      -moz-border-radius: 2rem;
      -webkit-border-radius: 2rem;
      display: inline-block;
      font-weight: bold;
      line-height: 1.6rem;
      text-align: center;
      min-width: 1.6rem;
    }

    .step-name {
      display: block;
      margin-top: 0.5rem;
    }
  }

  .step-tile-button-container {
    padding-top: 2px;
    margin-top: auto;
    width: 100%;
  }

  .cb-btn:last-child {
    width: 100%;
  }

  .step-reorder {
    .back .step-reorder-button {
      float: left;
    }

    .forward .step-reorder-button {
      float: right;
    }

    .step-reorder-button {
      background: none;
      border: none;
      cursor: default;
      outline: none;

      svg {
        height: 0.75rem;
        width: 0.75rem;
        margin: 1rem;
        cursor: pointer;

        use {
          fill: $cb-medium-blue-1;
        }

        &:hover {
          use {
            fill: $cb-brand-blue-1;
          }
        }

        &.disabled {
          cursor: not-allowed;
          use {
            fill: $cb-dark-grey-2;
          }
        }
      }
    }
  }
}
</style>
