<template>
  <section>
    <cb-admin-view-title :title="$t('locationGroup.title')" class="cb-admin-view-title">
      <b-button class="create-location-groups-button"
                variant="green"
                @click="addRenameLocationGroup(undefined)"
      >{{ $t('locationGroup.createLocationGroup') }}
      </b-button>
    </cb-admin-view-title>
    <cb-view-section v-if="data"
                     type="secondary"
                     class="pt-0"
                     :includePrimaryMessages="false">
      <div>
        <b-table v-if="data.locationGroups"
                 class="location-groups-list-container new-table"
                 hover
                 small
                 outlined
                 sort-icon-left
                 :items="data.locationGroups"
                 :fields="[
      { key: 'name', label: labels.headerName, thAttr: { 'aria-sort': 'descending' }, thStyle: { maxWidth: '250px' }, sortable: true },
      { key: 'useCount', label: labels.headerUsage, sortable: true },
      { key: 'view', label: '', class: 'link-column' }
    ]">
          <template v-slot:cell(view)="{ item }">
            <action-menu :toggle-orientation="'landscape'"
                         :menu-position="'bottom-left'"
                         :items="buildActionItems(item)"/>
          </template>
        </b-table>
        <p class="mt-3"
           v-else>
          {{ labels.messageNoData }}
        </p>
      </div>
      <rename-location-groups
          @ok="handleRenameComplete"
          @close="modalShow = false"
          @cancel="modalShow = false"
          @hide="modalShow = false"
          :item="currentItem"
          :add-url="data.addLocationGroup.add.url"
          v-if="modalShow"
      />
    </cb-view-section>
  </section>
</template>

<script>
import CbViewSection from "../general/cb-view-section";
import ActionMenu from 'common/components/action-menu.vue';
import RenameLocationGroups from './rename-location-groups';
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';

export default {
  name: "location-groups",
  components: {
    CbViewSection,
    ActionMenu,
    CbAdminViewTitle,
    RenameLocationGroups
  },
  props: {
    data: Object,
    labels: Object
  },
  data() {
    return {
      modalShow: false,
      currentItem: null
    }
  },
  methods: {
    buildActionItems(item) {
      let actions = [
        {
          icon: 'Action-Edit-Icon',
          label: this.labels.edit,
          action: () => {
            this.addRenameLocationGroup(item)
          }
        }
      ]

      if (item.useCount === 0) {
        actions.push(
            {
              icon: 'Action-Delete-Icon',
              label: this.labels.delete,
              action: () => {
                this.deleteLocationGroup(item)
              }
            }
        )
      }

      return actions
    },
    addRenameLocationGroup (item) {
      this.modalShow = true
      this.currentItem = item
    },
    async deleteLocationGroup(item) {
      const response = await this.$http.delete(item.deleteUrl, {disableProgress: true})
      this.data.locationGroups = response.body.locationGroups;
    },
    handleRenameComplete(locationGroups) {
      this.data.locationGroups = locationGroups
    }
  }
}
</script>

<style lang="scss" scoped>
.create-location-groups-button {
  margin-left: auto;
}

.location-groups-list-container {
  margin-top: 1rem;
}
</style>
