import { i18n } from 'common/lang-config';

export default function candidate ($rootScope, $http, $sce, $filter, $window, aaNotify, appSettings, locationSvc, cfpLoadingBar) {
  var linker = function (scope, element, attrs) {
    aaNotify.clearAll();
    scope.model = {selectedFilters:[], selectedCandidatesCount:0 };
    scope.labels = {
      addButtonLabel: i18n.t('candidates.addCandidateButtonLabel'),
      downloadButtonLabel: i18n.t('candidates.downloadButtonLabel')
    };

    scope.handleShowDetail = function (item) {
      $window.scrollTo(0, 0);
      scope.detailItem = item;
      scope.showDetail = true;
      scope.$apply();
    };
    scope.handleCloseDetail = function () {
      scope.detailItem = null;
      scope.showDetail = false;
      scope.$apply();
    };
  }

  return {
    restrict: "E",
    replace: true,
    link: linker,
    template: require('candidates/candidate.html'),
    scope: {
      data: '=',
    }
  };
};

candidate.$inject = ['$rootScope', '$http', '$sce', '$filter', '$window', 'aaNotify', 'appSettings', 'locationSvc', 'cfpLoadingBar'];
