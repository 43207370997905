<template>
  <component :is="component" v-bind="props"></component>
</template>
<script>

export default {
  name: "angular-route-wrapper",
  computed: {
    component () {
      return this.$_cb.router.getDefaultComponent();
    },
    props () {
      if(this.component.props && this.component.props.reportType){
        return {
          dataUrl: this.$_cb.router.getDataUrl(),
          reportType: this.$_cb.route.params.reportType
        };

      }
      if(this.component.props && this.component.props.dataUrl) {
        return {
          dataUrl: this.$_cb.router.getDataUrl()
        };
      }
      else {
        return this.$_cb.route.params;
      }
    },
  }
};
</script>

<style scoped lang="scss">
</style>