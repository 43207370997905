<template>
  <savage-form-text-input v-model="localTextValue" 
    v-bind="textInputProperties" 
    @change.native="formatLocalTextValue" />
</template>

<script>
import { SavageFormTextInput, SavageFormFieldMixin } from '@clickboarding/savage';
import clonedeep from 'lodash.clonedeep';

export default {
  name: 'savage-form-normalized-textinput',
  components: { SavageFormTextInput },
  mixins: [ SavageFormFieldMixin ],
  props: {
    formState: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      localTextValue: null,
    };
  },
  watch: {
    value: {
      handler (newValue) {
        this.localTextValue =  this.localTextValue || this.reformatTextValue(newValue, this.options.normalization.forDisplay);
      },
      immediate: true
    },
    currentInputValid (newValue) {
      const val =  (newValue) ? this.reformatTextValue(this.localTextValue, this.options.normalization.fromInput) : null;
      this.$emit("input", val);
    }
  },
  methods: {
    reformatTextValue (value, format) {
      if (!value || !format) return;
      return value.replace(new RegExp(format.regex, format.flags), format.replace);
    },
    formatLocalTextValue () {
      this.localTextValue = this.reformatTextValue(this.localTextValue, this.options.normalization.forDisplay) || this.localTextValue;
    }
  },
  computed: {
    currentInputValid () {
      if (this.formState[this.name]) {
        return this.formState[this.name]['$valid'];
      } else {
        return false;
      }
    },
    textInputProperties () {
      let newProps = clonedeep(this.$props);
      delete newProps.value;
      delete newProps.formState;
      delete newProps.options.normalization;

      // this returns the valid properties to pass into the textinput component - 
      // this allows us to bind a number of properties dynamically
      return newProps;
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
