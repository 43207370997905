import Vue from 'vue';
const Bus = new Vue();
import appSettings from 'appSettings';
var actionCenterVersion = "cb" + appSettings.appType + "actionCenterVersion";

let menuBus = {
  dataUrl: null,
  menuExpanded: null,
  menu: null,
  setMenu (val) {
    this.menu = val;
  },
  updateDataUrl (val) {
    this.dataUrl = val;
    Bus.$emit('cb-menu-update-data', val);
  },
  onUpdateDataUrl (fn) {
    Bus.$on('cb-menu-update-data', fn);
    return Bus.$off.bind(this, 'cb-menu-update-data');
  },
  updateMenuExpanded (val) {
    this.menuExpanded = val;
    Bus.$emit('cb-menu-update-expanded', val);
  },
  onUpdateMenuExpanded (fn) {
    Bus.$on('cb-menu-update-expanded', fn);
    return Bus.$off.bind(this, 'cb-menu-update-expanded');
  }
}

export default menuBus;
