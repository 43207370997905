<template>
  <section>
    <div>
      <p class="font-weight-bold mb-0">
        {{ labels.sendEmailToLabel }} <span aria-hidden class="text-danger">*</span>
      </p>
      <div v-if="model.recipients"
           class="d-flex mb-3 align-items-center recipients-wrapper">
        <div v-for="(recipient, index) in labels.recipients"
             class="recipient">
          <b-form-checkbox v-if="recipient.typeEnum !== 'EmailAddress'"
                           class="mr-3"
                           v-model="model.recipients[index].sendEmail"
                           :state="recipientsState"
                           @change="recipientsIsValid = null"
          >{{ recipient.name }}
          </b-form-checkbox>
          <div v-if="recipient.typeEnum === 'EmailAddress'"
               class="d-flex align-items-center">
            <b-form-checkbox v-model="model.recipients[index].sendEmail"
                             :state="recipientsState"
                             @change="recipientsIsValid = null"/>
            <b-form-input
                class="recipient-email"
                v-model="model.recipients[index].emailAddress"
                :placeholder="recipient.name"/>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-form-group class="w-50">
        <template v-slot:label>
          {{ labels.templateLabel }}
          <span aria-hidden class="text-danger">*</span>
        </template>
        <b-form-select
            class="email-template"
            required
            text-field="name"
            value-field="guid"
            v-model="model.templateGuid"
            :options="[{ guid: null, name: labels.defaultTemplate} ,...labels.templates]"
            :state="validateState(['templateGuid'])"
            @change="handleTemplateChange">
        </b-form-select>
      </b-form-group>
    </div>
    <div>
      <div class="d-flex">
        <b-form-group v-if="model.trigger"
                      class="w-50 mr-3">
          <template v-slot:label>
            {{ labelsTriggers.stepTriggerLabel }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-select
              class="email-trigger"
              required
              text-field="name"
              value-field="typeEnum"
              v-model="model.trigger.typeEnum"
              :options="triggers"
              @change="selectTrigger"
              :state="validateState(['trigger', 'typeEnum'])">
          </b-form-select>
        </b-form-group>
        <b-form-group v-if="model.trigger.typeEnum && model.trigger.typeEnum.indexOf('Overdue') > -1"
                      class="overdue-day-input">
          <template v-slot:label>
            {{ model.trigger.overdueAfterLabel }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-input
              v-model="model.trigger.daysOverdue"
              :state="validateState(['trigger', 'daysOverdue'])"/>
          <span class="overdue-day-text">{{ model.trigger.daysLabel }}</span>
        </b-form-group>
      </div>
    </div>
    <b-button class="d-block ml-auto mr-auto"
              variant="primary"
              @click="saveEvent">
      {{ saveButtonTitle }}
    </b-button>
  </section>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import {validationMixin} from "vuelidate"
import {maxLength, required, numeric} from "vuelidate/lib/validators"

export default {
  name: "event-email",
  mixins: [validationMixin],
  props: {
    labels: {
      required: true,
      type: Object
    },
    labelsTriggers: {
      required: true,
      type: Object
    },
    editData: {
      type: Object
    }
  },
  data() {
    return {
      model: {
        typeEnum: "Email",
        description: null,
        eventGuid: null,
        templateGuid: null,
        trigger: {
          typeEnum: null
        },
      },
      recipientsIsValid: null
    }
  },
  computed: {
    triggers() {
      const filteredTriggers = this.labelsTriggers.triggers.filter(l => l.typeEnum !== 'Started' && l.typeEnum !== 'Completed')
      return [{typeEnum: null, name: this.labelsTriggers.defaultTrigger}, ...filteredTriggers]
    },
    saveButtonTitle() {
      return this.editData ? this.$t('common.done') : this.$t('common.add')
    },
    recipientsState() {
      return this.$v.$anyDirty ? this.recipientsIsValid : null
    }
  },
  validations() {
    const validation = {
      model: {
        templateGuid: {
          required
        },
        trigger: {
          typeEnum: {
            required
          }
        }
      }
    }

    if (this.model.trigger.typeEnum && this.model.trigger.typeEnum.includes('Overdue')) {
      validation.model.trigger.daysOverdue = {
        required,
        maxLength: maxLength(4)
      }
    }

    return validation
  },
  methods: {
    resetModel() {
      this.model = {
        typeEnum: "Email",
        description: null,
        eventGuid: null,
        templateGuid: null,
        trigger: {
          typeEnum: null
        }
      }

      this.model.recipients = this.labels.recipients.map(recipient => {
        return {
          sendEmail: recipient.sendEmail || false,
          ...recipient
        }
      })
    },
    handleTemplateChange(guid) {
      this.model.description = this.labels.templates.find(template => template.guid === guid).name
    },
    selectTrigger(trigger) {
      this.model.trigger = cloneDeep(this.triggers.find(item => item.typeEnum === trigger))
    },
    validateState(namesArray) {
      const temp = namesArray.reduce((result, currentValue) => {
        if (result) {
          return result[currentValue]
        } else {
          return this.$v.model[currentValue]
        }
      }, undefined)

      const {$dirty, $error} = temp
      return $dirty ? !$error : null
    },
    saveEvent() {
      this.$v.$touch()
      this.recipientsIsValid = this.model.recipients.some(recipient => recipient.sendEmail)

      if (this.$v.$anyError || !this.recipientsIsValid) {
        return
      }

      this.$emit('saveEvent', this.model)
    }
  },
  watch: {
    editData: {
      handler(newValue) {
        if (newValue) {
          this.model = cloneDeep(newValue)
          this.model.trigger = cloneDeep(this.triggers.find(item => item.typeEnum === this.model.trigger.typeEnum))
          if ('daysOverdue' in newValue.trigger) {
            this.$set(this.model.trigger, 'daysOverdue', newValue.trigger.daysOverdue)
          }
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    if (!this.editData) {
      this.resetModel()
    }
  }
}
</script>

<style lang="scss" scoped>
.overdue-day {
  &-input {
    position: relative;
    max-width: 100px;
  }

  &-text {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
  }
}

.recipient {
  /deep/ .custom-control-label {
    white-space: nowrap;
  }
}

.recipients-wrapper {
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
}
</style>
