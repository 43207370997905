import * as DataEntryActionsProvider from "forms/element-list-item-providers/actions/dataEntry";
import * as DataEntryIconProvider from "forms/element-list-item-providers/icons/dataEntry";
import 'common/polyfills/array.includes.polyfill';

function canHandle (element) {
  return [
    "TextInput",
    "TextArea",
    "DateInput",
    "Select",
    "PhoneInput",
  ].includes(element.type);
}

function map (element, index, editActionHandler, deleteActionHandler) {
  return {
    label: element.label || element.type,
    actions: DataEntryActionsProvider.get(element, index, editActionHandler, deleteActionHandler),
    icon: DataEntryIconProvider.get(element)
  };
}

export { canHandle, map }