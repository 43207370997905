<template>
  <cb-view-section
      type="secondary"
      class="pt-0"
      :includePrimaryMessages="false"
  >
    <b-table v-if="content.providers" class="screening-list-container new-table table-hover mt-3"
             :items="content.providers"
             sort-by="name"
             sort-icon-left
             :fields="[{key: 'name', label: 'NAME', sortable: true }]">
      <template v-slot:cell(name)="{ item }">
        <a class="screening-link" 
              @click="viewScreening(item)" style="color: #2F72AC !important;">
        {{ item.name }}
      </a>
      </template>
    </b-table>
    <p v-else>
      {{ content.noDataLabel }}
    </p>
  </cb-view-section>
</template>

<script>
import CbViewSection from "../general/cb-view-section";
import AppSettings from "../appSettings";
import {mapActions} from 'vuex'

export default {
  name: "screening",
  components: {
    CbViewSection
  },
  props: {
    content: {
      required: true,
      type: Object
    },
    backUrl: {
      type: String
    }
  },
  data() {
    return {
    }
  },
  methods: {
    ...mapActions('screenings', ['setProvider', 'setBackUrl']),
    async viewScreening(item) {      
      await this.setProvider(item)
      await this.setBackUrl(this.backUrl)
      this.$_cb.router.changeView(AppSettings.viewScreening, null);
    }
  }
}
</script>

<style lang="scss" scoped>
.screening-link {
  cursor: pointer;
  font-weight: 600;
  color: #3656A6;
}
</style>