<template>
    <section class="task-information-section">
        <div class="task-information-container">
            <div class="task-user-container">
                <span class="task-user-label">{{content.user.label}}</span><br />
                <span class="task-user-displayName">{{content.user.value}}</span>
            </div>
            <p class="task-description">
                {{content.description}}
            </p>
        </div>
        <button v-if="content.moreInformation.url" type="button" class="cb-btn primary more-information" @click="openMoreInformationLink">
            {{$t("actionCenter.task.information.moreInformationLabel")}}
        </button>
    </section>
</template>

<script>
export default {
    name: 'action-center-task-complete',
    props: {
        content: {
            type: Object,
            required: true
        }
    },
    methods: {
        openMoreInformationLink() {
            window.open(this.content.moreInformation.url, '_blank');
        },
        save() {
            // there is no submit button
        }
    },
    data() {
        return {
            formstate: {}
        }
    }
}
</script>

<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.task-information-section {
    background-color: $cb-white;
    position: relative;
    border: solid 1px $cb-light-grey-1;
}

.task-information-container {
    @include font-size-content;
    text-align: center;
    padding: 1.5rem 1.5rem 4rem 1.5rem;
}

.task-user-container {
    margin-bottom: 1.5rem;

    .task-user-label {
        font-weight: bold;
        margin: 0 0 .5rem;
    }
}

.task-description {
    margin: 0;
}

.more-information {
    width: 100%;
    position: absolute;
    bottom: 0;
}
</style>
