import Vue from 'vue';

const BUILDER_FORM_UPDATE = "builder-form-update";
const PARENT_FORM_UPDATE = "parent-form-update";

export default new Vue({
  methods: {
    updateBuilderForm (form) {
      this.$emit(BUILDER_FORM_UPDATE, form);
    },
    onUpdateBuilderForm (handler) {
      this.$on(BUILDER_FORM_UPDATE, handler);
      return () => { this.$off(BUILDER_FORM_UPDATE, handler) };
    },
    updateParentForm (form) {
      this.$emit(PARENT_FORM_UPDATE, form);
    },
    onUpdateParentForm (handler) {
      this.$on(PARENT_FORM_UPDATE, handler)
      return () => { this.$off(PARENT_FORM_UPDATE, handler) };
    }
  }
});
