import ngRoute from 'angular-route'; //provides the 'ngRoute' module
import appSettingsModule from "common/app-settings.module";
import adminRoutes from "admin-routes";

export default angular
  .module("admin-app-routes-module", [appSettingsModule, ngRoute])
  .config([
    "$routeProvider",
    "appSettings",
    function($routeProvider, appSettings) {
      $routeProvider
        .when(appSettings.viewLogin, {
          redirectTo: function(obj, requestedPath) {
            window.location.replace(appSettings.loginUrl);
          }
        })
        .when(appSettings.viewAutoLogin, {
          template: '',
          controller: "autoLoginCtrl",
          controllerAs: "vm"
        })
        .when(appSettings.viewHome, {
          template: require("home/home.html"),
          controller: "viewCtrl",
          controllerAs: "vm"
        })
        .when(appSettings.viewActionCenter, {
          template: require("dashboard/dashboardView.html"),
          controller: "viewCtrl",
          controllerAs: "vm"
        })
        .when(appSettings.viewActionCenterV2, {
            template: require("action-center/action-center-view.html"),
            controller: "action-center-controller",
            controllerAs: "vm"
        })
        .when(appSettings.viewActionCenterTaskOnlyUser, {
          template: require("action-center/action-center-task-only-user-view.html"),
          controller: "action-center-task-only-user-controller",
          controllerAs: "vm"
        })
        .when(appSettings.viewCandidates, {
          template: require("candidates/candidatesView.html"),
          controller: "CandidatesCtrl",
          controllerAs: "vm"
        })
        .when(appSettings.viewCandidatesAdd, {
          template: require("candidates/candidate-add.html")
        })
        .when(appSettings.viewLocations, {
          template: require("locations/locationsView.html"),
          controller: "LocationsCtrl",
          controllerAs: "vm"
        })
        .when(appSettings.viewLocationGroups, {
          template: require("locations/locationGroupsView.html"),
          controller: "LocationGroupsCtrl",
          controllerAs: "vm"
        })
        .when(appSettings.viewTeamMembers, {
          template: require("users/usersView.html"),
          controller: "UsersCtrl",
          controllerAs: "vm"
        })
        .when(appSettings.viewBrands, {
          template: require("brands/brands-list.html"),
          controller: "brands-controller",
          controllerAs: "vm"
        })
        .when(appSettings.viewBrandsAdd, {
            template: require("brands/brands-add.html"),
            controller: "brandsAddController",
            controllerAs: "vm"
          })
        .when(appSettings.viewBrandsEdit, {
            template: require("brands/brands-edit.html"),
            controller: "brandsEditController",
            controllerAs: "vm"
          })
        .when(appSettings.viewProcesses, {
          template: require("processes/process-list.html"),
          controller: "processesController",
          controllerAs: "vm"
        })
        .when(appSettings.viewTasks, {
          template: require("processes/tasks/task-list.html"),
          controller: "tasksController",
          controllerAs: "vm"
        })
        .when(appSettings.viewForms, {
          template: require("forms/forms.html"),
          controller: "formsController",
          controllerAs: "vm"
        })
        .when(appSettings.viewFormsAdd, {
          template: require("forms/forms-add.html"),
          controller: "formsAddController",
          controllerAs: "vm"
        })
        .when(appSettings.viewFormsDraftSplashView, {
          template: require("forms/forms-splash.html"),
          controller: "formsSplashController",
          controllerAs: "vm"
        })
        .when(appSettings.viewFormsEdit, {
          template: require("forms/forms-edit.html"),
          controller: "formsEditController",
          controllerAs: "vm"
        })
        // viewFormsView shares its template with forms-edit and
        // controller extends formsEditController and sets isReadOnly to true
        .when(appSettings.viewFormsView, {
          template: require("forms/forms-edit.html"),
          controller: "formsViewController",
          controllerAs: "vm"
        })
        .when(appSettings.viewProcess, {
          template: require("processes/process.html"),
          controller: "processController",
          controllerAs: "vm"
        })
        .when(appSettings.viewScreenings, {
          template: require("setup/setupView.html"),
          controller: "viewCtrl",
          controllerAs: "vm"
          })
        .when(appSettings.viewCompanies, {
            template: require("companies/companies.html"),
            controller: "companiesController",
            controllerAs: "vm"
        })
        .when(appSettings.viewCompaniesAdd, {
            template: require("companies/companies-add.html"),
            controller: "companiesAddController",
            controllerAs: "vm"
        })
        .when(appSettings.viewAccessDenied, {
            template: require("errors/access-denied.html")
        })
        .when(appSettings.viewDatafields, {
          template: require("datafields/datafields.html"),
          controller: "datafieldsController",
          controllerAs: "vm"
        })
        .when(appSettings.viewDatafieldsAddTypeSelect, {
          template: require("datafields/datafields-add-type-select.html"),
          controller: "datafieldsAddTypeSelectController",
          controllerAs: "vm"
        })
        .when(appSettings.viewDatafieldsAdd, {
          template: require("datafields/datafields-add.html"),
          controller: "datafieldsAddController",
          controllerAs: "vm"
        })
        .when(appSettings.viewActivityEdit, {
          template: require("processes/steps/activities/activity-edit.html"),
          controller: "activityEditController",
          controllerAs: "vm"
        })
        .when(appSettings.viewImports, {
          template: require("imports/importsView.html"),
          controller: "importsController",
          controllerAs: "vm"
        })
        .when(appSettings.viewBulkImports, {
          template: require("bulk-imports/bulk-importsView.html"),
          controller: "bulkImportsController",
          controllerAs: "vm"
        })
        .when(appSettings.viewBulkImportsSpecs, {
          template: require("bulk-imports/bulkImportSpecsView.html"),
          controller: "bulkImportsController",
          controllerAs: "vm"
        })
        .when(appSettings.viewBulkImportsUpdateSpecs, {
          template: require("bulk-imports/bulkImportsUpdateSpecsView.html"),
          controller: "bulkImportsController",
          controllerAs: "vm"
        });

        let registerVueRoute = function($routeProvider, routeConfig) {
          $routeProvider.when(routeConfig.path, {
            template: require("routing/angular-route-wrapper.html"),
            $_cb: {
              component: routeConfig.components.default,
              menuSettings: routeConfig.props.menu
            }
          });
        };

        adminRoutes.forEach(routeConfig => {
          registerVueRoute($routeProvider, routeConfig);
        });

        $routeProvider.otherwise({
          redirectTo: appSettings.viewLogin
        });
    }
  ]).name;
