<template>
  <b-modal
      v-model="modalShow"
      :title="modalTitle"
      :ok-title="data.saveLabel"
      centered
      static
      lazy
      size="lg"
      no-close-on-backdrop
      @ok="handleOk"
      @cancel="$emit('cancel')"
      @close="$emit('close')"
      @hide="handleHide"
  >
    <cb-primary-message></cb-primary-message>
    <b-form
        @submit.stop.prevent="saveAndClose"
    >
      <b-form-group>
        <template v-slot:label>
          {{ data.general.nameLabel }}
          <span aria-hidden class="text-danger">*</span>
        </template>
        <b-form-input
            class="task-name"
            :placeholder="data.general.nameLabel"
            :state="validateState('name')"
            v-model="$v.model.name.$model"/>
      </b-form-group>
      <b-form-group :label="data.general.explanationLabel">
        <b-form-textarea
            class="task-explanation"
            :placeholder="data.general.explanationLabel"
            :state="validateState('explanation')"
            v-model="$v.model.explanation.$model"
        />
      </b-form-group>
      <b-form-group :label="data.general.additionalInformationHrefLabel">
        <b-form-input
            class="task-url"
            :placeholder="data.general.additionalInformationHrefPlaceholder"
            :state="validateState('additionalInformationHref')"
            v-model="$v.model.additionalInformationHref.$model"/>
      </b-form-group>
    </b-form>
    <div>
      <div class="h-line"></div>
      <h2>{{ data.activities.title }}</h2>
      <div class="d-flex align-items-baseline">
        <b-form-input
            class="task-add-activity-title"
            :placeholder="data.activities.descriptionPlaceholder"
            v-model="activityDesc"
            @keyup.enter="handleAddActivity"
        ></b-form-input>
        <a href=""
           class="task-add-activity-button"
           @click.prevent="handleAddActivity"
           :title="data.activities.addLabel">
          <i class="fa fa-plus-square-o"
             style="font-size:165%; vertical-align:middle; padding:.25em .5em;"></i></a>
      </div>
      <div>
        <div class="d-flex align-items-center"
             v-for="(item, index) in model.activities">
          <b-form-input :state="validateActivities(index)"
              v-model="$v.model.activities.$each[index].description.$model"
          />
          <a href=""
             class="delete-activity"
             @click.prevent="handleDeleteActivity(index)"
             :title="data.activities.deleteLabel">
            <i class="fa fa-trash-o red"
               style="font-size:165%; padding:.25em .5em;"></i>
          </a>
        </div>
        <p class="mt-3 text-danger" v-if="$v.model.activities.$dirty && !$v.model.activities.required">
          At least one activity is required for a task.
        </p>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, maxLength, url} from "vuelidate/lib/validators";
import CbPrimaryMessage from "../../components/cb-primary-message";

export default {
  name: "task-add",
  mixins: [validationMixin],
  components: {CbPrimaryMessage},
  props: {
    data: {
      required: true,
      type: Object,
    },
    editItem: {
      type: Object
    }
  },
  data() {
    return {
      modalShow: true,
      activityDesc: '',
      model: {
        name: "",
        explanation: "",
        additionalInformationHref: "",
        activities: []
      },
      validationMessages: {
        required: this.$t("common.validation.required")
      }
    }
  },
  validations() {
    return {
      model: {
        name: {
          required,
          maxLength: maxLength(35)
        },
        explanation: {
          maxLength: maxLength(225)
        },
        additionalInformationHref: {
          maxLength: maxLength(225),
          url
        },
        activities: {
          required,
          $each: {
            description: {
              required,
              maxLength: maxLength(50)
            }
          }
        }
      }
    }
  },
  computed: {
    modalTitle() {
      return this.editItem ? this.data.editTitle : this.data.addTitle
    }
  },
  methods: {
    validateState(name) {
      const {$dirty, $error} = this.$v.model[name]
      return $dirty ? !$error : null
    },
    validateActivities (index) {
      const {$dirty, $error} = this.$v.model.activities.$each.$iter[index]
      return $dirty ? !$error : null
    },
    handleHide(bvModelEvent) {
      if (bvModelEvent.trigger !== 'ok') {
        // hide happen on esc, but it also happens whenever the window gets
        // closed (e.g., hitting the ok button), ok is handled differently (saveAndClose)
        // don't forward this event in that scenario
        this.$emit('hide')
      }
    },
    handleAddActivity() {
      if (this.activityDesc && this.activityDesc.trim() !== '') {
        this.model.activities.push({
          description: this.activityDesc
        })
        this.activityDesc = ''
      }
    },
    handleEditActivity(item) {
      item.isEditing = true
      item.previousValue = item.description
    },
    handleCancelActivity(item) {
      if (item.isEditing) {
        item.isEditing = false
        item.description = item.previousValue
      }
    },
    handleSaveActivity(item) {
      item.isEditing = false
      item.previousValue = null
    },
    handleDeleteActivity(index) {
      this.model.activities.splice(index, 1)
    },
    async handleOk(bvModelEvent) {
      // according to the docs (https://bootstrap-vue.js.org/docs/components/modal#prevent-closing), async isn't supported
      // so always prevent the default and then handle closing it ourselves if needed
      bvModelEvent.preventDefault();

      await this.saveAndClose();
    },
    async saveAndClose() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      let url, method;
      if (this.editItem) {
        url = this.editItem.editUrl
        method = 'put'
      } else {
        url = this.data.add.url
        method = 'post'
      }

      const {data} = await this.$http[method](url, this.model)
      this.$emit('ok', data.tasks)
      this.modalShow = false
    }
  },
  async mounted() {
    if (this.editItem) {
      const {data} = await this.$http.get(this.editItem.getUrl)
      this.model = data;
    }
  }
}
</script>

<style scoped>

</style>
