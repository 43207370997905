<template>
    <candidate-detail v-if="showDetail" :closeClick="onModalClose" :detailUrl="detailsUrl"></candidate-detail>
</template>

<script>
    import CandidateDetail from "candidates/candidate-detail.vue";

    export default {
        name: "current-step",
        components: {
            CandidateDetail            
        },
        props: {
            showDetail: {
                type: Boolean,
                required: false
            },
            detailsUrl: {
                type: String,
                required: false
            }
        },
        methods: {
            onModalClose(bvModalEvent) {
                this.$emit('closed');
            }
        }
    };
</script>