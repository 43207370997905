<template>
  <b-modal
      v-model="modalShow"
      :title="message.title"
      static
      ok-only
      size="sm"
      no-close-on-backdrop
      modal-class="error-modal"
      :ok-title="message.btnLabel"
      @ok="handleClose"
      @close="handleClose"
      @hide="handleClose"
  >
    <div style="text-align:left;">{{ message.message }}</div>
    <div v-if="message.code" style="text-align:left; margin-top:1em">
      {{ message.codeMessage }}
      <h2 style="text-align:center;">{{ message.code }}</h2>
    </div>
    <div id="dContent" class="error-modal-contacts mt-3">
      <p>7am-7pm CT Monday-Friday</p>
      <p>phone: {{ contact.phone }}</p>
      <p>email: <a :href="`mailto:${this.contact.emailHref}`">{{ contact.emailAddress }}</a></p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "error",
  props: {
    message: {
      required: true,
      type: Object
    },
    errorClick: {
      required: true,
      type: Function
    }
  },
  data() {
    return {
      modalShow: true,
      contact: {}
    }
  },
  methods: {
    handleClose(e) {
      e.preventDefault()
      this.errorClick()
    }
  },
  beforeMount() {
    this.contact.emailAddress = this.$t('common.error.email');
    this.contact.emailHref = this.contact.emailAddress + '?subject=Candidate%20code%3a%20' + this.message.code
    this.contact.phone = '1-855-283-5583';
    const location = window.location.hostname
    if (location.indexOf("myonboarding") === -1 &&
        location.indexOf("myoffboarding") === -1) {
      this.contact.emailHref = this.contact.emailAddress + '?subject=Code%3a%20' + this.message.code
      this.contact.phone = '952-283-3454';
    }
  }
}
</script>

<style lang="scss">
.error-modal {
  .modal-dialog {
    min-width: 40%;
  }

  &-contacts {
    display: table;
    margin: 0 auto;

    p {
      margin: 0;
    }
  }
}
</style>