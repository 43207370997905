<template>
  <section>
    <cb-admin-view-title :title="$t('tasks.title')" class="cb-admin-view-title">
      <b-button
          class="create-task-button"
          variant="green"
          @click="addEditTask(undefined)"
      >{{ $t('tasks.createTask') }}
      </b-button>
    </cb-admin-view-title>
    <cb-view-section v-if="data" type="secondary" class="pt-0" :includePrimaryMessages="false">
      <b-table v-if="tasksCount"
               class="task-list-container new-table"
               sort-by="name"
               v-bind="tableSettings"
               :per-page="itemsPerOnePage"
               :current-page="currentPage"
               :items="tasks"
               :fields="[
      { key: 'name', label: data.processHeader, thAttr: { 'aria-sort': 'descending' }, sortable: true, sortDirection: 'desc' },
      { key: 'itemCount', label: data.itemsHeader, sortable: true },
      { key: 'view', label: '', class: 'link-column' }
    ]">
        <template v-slot:cell(view)="{ item }">
          <svg class="edit-icon" @click="editTask(item)">
            <use v-bind="{'xlink:href': getIconPath('Action-Edit-Icon') }"></use>
          </svg>
        </template>
      </b-table>
      <p class="no-tasks-message mt-3" v-else>
        {{ data.noDataLabel }}
      </p>
      <div v-if="showPagination" class="pagination-container">
        <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm" class="per-page-options">
          <b-form-select
              id="per-page-select"
              v-model="itemsPerOnePage"
              :options="paginationSettings.pageOptions"
              size="sm">
          </b-form-select>
        </b-form-group>
        <b-pagination
            v-if="showPagination"
            v-model="currentPage"
            :total-rows="tasksCount"
            :per-page="itemsPerOnePage"
            :current-page="currentPage"
            v-bind="paginationSettings">
        </b-pagination>
      </div>
    </cb-view-section>
    <task-add :data="data.addEdit"
              :edit-item="editItem"
              @ok="handleOk"
              @close="modalShow = false"
              @cancel="modalShow = false"
              @hide="modalShow = false"
              v-if="modalShow">
    </task-add>
  </section>
</template>

<script>
import CbViewSection from 'general/cb-view-section.vue';
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import {IconProvider} from 'common/icon.module';
import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../../constants/defaultTableSettings";
import TaskAdd from "./task-add";

export default {
  name: "tasks",
  components: {
    CbViewSection,
    CbAdminViewTitle,
    TaskAdd
  },
  props: {
    data: Object,
    add: Function
  },
  data() {
    return {
      modalShow: false,
      editItem: undefined,
      tasks: [],
      tableSettings: TABLE_SETTINGS,
      paginationSettings: PAGINATION_SETTINGS,
      currentPage: 1,
      itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0]
    };
  },
  computed: {
    tasksCount() {
      return this.data && this.data.tasks && this.data.tasks.length;
    },
    showPagination() {
      return this.tasksCount > PAGINATION_SETTINGS.pageOptions[0];
    }
  },
  methods: {
    addEditTask(item) {
      this.modalShow = true
      this.editItem = item
    },
    handleOk(tasks) {
      this.tasks = tasks
    },
    getIconPath(icon) {
      return IconProvider.getPath(icon);
    },
    editTask(task) {
      this.addEditTask(task)
    }
  },
  mounted() {
    this.tasks = this.data && this.data.tasks
  }
}
</script>

<style lang="scss" scoped>
.edit-icon {
  width: 15px;
  height: 15px;

  &:hover {
    cursor: pointer;
    fill: #478ECC;
  }
}

.create-task-button {
  margin-left: auto;
}

.task-list-container {
  margin-top: 1rem;
}
</style>
