<template>
  <section v-if="provider">
    <cb-view-header class="connections-header" :title="provider.name" :return-link="returnLink"/>
    <cb-view-section
        type="secondary"
        class="pt-3"
        :includePrimaryMessages="true"
    >
      <p v-if="!this.provider.contexts">
        {{ provider.noDataLabel }}
      </p>
      <div v-if="tablesData.length"
           v-for="data in tablesData">
        <p class="table-name">{{ data.tableName }}</p>
        <b-table v-if="data && data.rows.length"
                 class="screening-list-container new-table mt-3"
                 :items="data.rows"
                 sort-by="name"
                 small
                 :fields="data.columnHeaders">
          <template v-slot:[getCellName(index)]="{ item }" v-for="index in data.columnHeaders.length">
            <b-input v-if="data.editColumn.index === index - 1"
                     v-model="item.values[index - 1].name"
                     @input="change($event, item, data.editColumn)"
                     @blur="blur($event.target.value, item, data.editColumn)"
            ></b-input>
            <span v-else>
          {{ item.values[index - 1].name }}
            </span>
          </template>
        </b-table>
        <p v-else>
          {{ data.noDataLabel }}
        </p>
      </div>
      <div v-if="showSaveButton"
           class="multi-edit">
        <div class="buttons-wrapper">
          <b-button variant="outline-secondary"
                    class="cancel-button"
                    @click="returnLinkHandler">Cancel
          </b-button>
          <b-button variant="green"
                    class="save-screening"
                    @click="saveChanges">Save
          </b-button>
        </div>
      </div>
    </cb-view-section>
  </section>
</template>
<script>
import CbViewSection from "../general/cb-view-section";
import CbPrimaryMessage from "components/cb-primary-message.vue";
import CbViewHeader from "general/cb-view-header.vue";
import {mapGetters} from 'vuex'
import AppSettings from "../appSettings";

export default {
  name: "edit-screening",
  components: {
    CbPrimaryMessage,
    CbViewSection,
    CbViewHeader
  },
  data() {
    return {
      tablesData: [],
      listToUpdate: {}
    };
  },
  computed: {
    ...mapGetters('screenings', ['provider', 'backUrl']),
    returnLink() {
      return {
        label: this.$t('connections.backLabel', ['Screenings']),
        handler: this.returnLinkHandler
      }
    },
    showSaveButton() {
      return Object.entries(this.listToUpdate).length !== 0
    }
  },
  methods: {
    change(value, item, editColumn) {
      if (!this.listToUpdate[item.postUrl]) {
        this.setItemToUpdate(value, item, editColumn)
      }
    },
    blur(value, item, editColumn) {
      if (value) {
        this.setItemToUpdate(value, item, editColumn)
      }
    },
    setItemToUpdate(value, item, editColumn) {
      this.$set(this.listToUpdate, `${item.postUrl}`, {
        id: editColumn.id,
        value: value.trim()
      })
    },
    getCellName(key) {
      return `cell(name${key})`
    },
    returnLinkHandler() {
      this.$_cb.router.changeView(AppSettings.viewScreenings, this.backUrl);
    },
    async loadData() {
      if (this.provider.contexts && this.provider.contexts.length) {
        const response = await Promise.all(this.provider.contexts.map(context => this.$http.get(context.getUrl)))

        this.tablesData = this.provider.contexts.map((context, index) => {
          const data = response[index].body.data
          const columnHeaders = data.columns.map((item, index) => {
            const column = {key: `name${index + 1}`, label: item.name}
            if (item.contentType === 'TextBox') {
              column.thStyle = {width: '250px'}
            }
            return column
          })
          const editColumnIndex = data.columns.findIndex(column => column.contentType === 'TextBox')

          return {
            tableName: context.name,
            noDataLabel: data.noDataLabel,
            columnHeaders,
            editColumn: {
              index: editColumnIndex,
              id: data.columns[editColumnIndex].id
            },
            rows: data.rows
          }
        })
      }
    },
    async saveChanges() {
      await Promise.all(Object.keys(this.listToUpdate).map(key => {
        return this.$http.post(key, this.listToUpdate[key])
      }))

      this.listToUpdate = {}
    }
  },
  async mounted() {
    if (this.provider) {
      await this.loadData()
    } else {
      this.returnLinkHandler()
    }
  }
};
</script>
<style scoped lang="scss">
.table-name {
  font-weight: bold;
  font-size: 22px;
}
</style>
