<template>
  <section class="savage-form-content-element">
    <div class="savage-form-image-wrapper">
      <img src="" :alt="description" v-show="showImage" />
    </div>
  </section>
</template>

<script>
import loadImage from 'blueimp-load-image';

export default {
  name: 'savage-form-image',
  props: {
    description: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    }
  },
  mounted () {
    loadImage(
      this.url,
      this.renderImage,
      {
        orientation: true  // this setting ensures the image maintains it's original ratio and is not inverted or rotated incorrectly
        // This setting also implicitly sets canvas to true, which will render the image in a canvas tag
      }
    )
  },
  methods: {
    renderImage (source) {
      if (source.type !== 'error') {
        let img = this.$el.querySelector('.savage-form-image-wrapper img');
        img.src = source.toDataURL('image/png');
      }
      this.showImage = true
    }
  },
  data () {
    return {
      showImage: false
    }
  }
}
</script>
<style scoped lang="scss">
  .savage-form-content-element {
    text-align: center;
  }

  .savage-form-image-wrapper {
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }
</style>
