<template>
  <b-modal
      v-model="modalShow"
      :title="title"
      :ok-title="okTitle"
      centered
      no-close-on-backdrop
      @ok="handleOk"
      @cancel="$emit('cancel')"
      @close="$emit('close')"
      @hide="handleHide"
  >
    <cb-primary-message/>
    <savage-form
        ref="savageform"
        :formstate="formstate"
        name="form-rename"
        :on-submit="saveAndClose"
    >
      <savage-form-text-input
          name="formIdentifier"
          :label="$t('locationGroup.addLabelLocationGroupName')"
          :placeholder="$t('formsBuilder.formsAndContent.rename.referenceNamePlaceholder')"
          :fieldClass="{'show-success': true}"
          :validation="validation"
          v-model="currentIdentifier"/>
    </savage-form>
  </b-modal>
</template>
<script>
import {SavageFormTextInput} from '@clickboarding/savage';
import {SavageForm} from "@clickboarding/savage";
import CbPrimaryMessage from "components/cb-primary-message.vue";

export default {
  name: "rename-location-groups",
  components: {
    SavageFormTextInput,
    SavageForm,
    CbPrimaryMessage
  },
  props: {
    item: {
      type: Object,
      required: false
    },
    addUrl: {
      type: String
    }
  },
  data() {
    return {
      modalShow: true,
      formstate: {},
      validationMessages: {
        required: this.$t("common.validation.required"),
        noSemicolon: this.$t("common.validation.noSemicolon")
      },
      currentIdentifier: ''
    };
  },
  computed: {
    validation() {
      return {
        required: {
          value: true,
          message: this.validationMessages.required
        },
        maxlength: {
          value: 100,
          message: this.$t('common.validation.maxLengthFormat', [100])
        },
        pattern: {
          value: "^[^;]*$",
          message: this.validationMessages.noSemicolon
        }
      };
    },
    title() {
      return this.item ? this.$t('locationGroup.editLabelAddLocationGroup') : this.$t('locationGroup.createLocationGroup')
    },
    okTitle() {
      return this.item ? this.$t('common.update') : this.$t('common.create')
    }
  },
  methods: {
    handleHide(bvModelEvent) {
      if (bvModelEvent.trigger !== 'ok') {
        // hide happen on esc, but it also happens whenever the window gets
        // closed (e.g., hitting the ok button), ok is handled differently (saveAndClose)
        // don't forward this event in that scenario
        this.$emit('hide');
      }
    },
    async handleOk(bvModelEvent) {
      // according to the docs (https://bootstrap-vue.js.org/docs/components/modal#prevent-closing), async isn't supported
      // so always prevent the default and then handle closing it ourselves if needed
      bvModelEvent.preventDefault();

      await this.saveAndClose();
    },
    async saveAndClose() {
      if (this.$refs.savageform.validate()) {

        let method, url;

        if (this.item) {
          method = 'put'
          url = this.item.editUrl
        } else {
          method = 'post'
          url = this.addUrl
        }

        const response = await this.$http[method](url, {
          name: this.currentIdentifier.trim()
        });
        this.$emit("ok", response.body.locationGroups);
        this.modalShow = false;
      }
    }
  },
  mounted() {
    if (this.item) {
      this.currentIdentifier = this.item.name
    }
  }
};
</script>
<style scoped lang="scss">
</style>
