const pattern = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

function getMatch (url) {
  if (!url) return null;
  const matches = url.match(pattern);
  return matches && matches.length ? matches[1] : null;
}

export default {
  getVideoId (url) {
    const id = getMatch(url);
    if (!id) console.error('No valid ID was found');
    return id;
  },
  isValidUrl (url) {
    const id = getMatch(url);
    return id !== null;
  }
}
