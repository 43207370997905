<template>
  <section v-if="pageData" class="vertical-flex">
    <cb-view-header class="vertical-flex-min" :title="pageData.title" :return-link="returnLink"></cb-view-header>

    <savage-form
      ref="savageform"
      name="brand-add-edit"
      :formstate="formState"
      :on-submit="saveAndRedirectAsync"
      class="vertical-flex-fill vertical-flex"
    >
      <cb-view-section class="vertical-flex-min" type="secondary" :includePrimaryMessages="true">
        <savage-form-text-input
          :label="pageData.referenceName.label"
          :placeholder="pageData.referenceName.placeholder"
          :validation="pageData.referenceName.validation"
          v-model="localModel.referenceName"
        />
      </cb-view-section>

      <cb-view-section class="vertical-flex-fill" type="primary">
        <cb-view-section type="secondary" class="logo-section">
          <div class="logo-upload-title">
            <span>{{pageData.brandLogoLabel}}</span>
          </div>

          <savage-form-validated-container
            class="logo-upload"
            field-name="uploadImageFile"
            :validation="buildValidation()"
            :value="file"
          >
            <cb-single-file-upload
              name="uploadImageFile"
              class="upload-image"
              slot="form-field"
              v-model="file"
              v-bind="fileUploadProps"
            />
          </savage-form-validated-container>
        </cb-view-section>
        <div class="d-flex mt-4">
          <button
            type="button"
            @click="cancel()"
            class="cb-btn back primary flex-fill"
          >{{pageData.cancel.label}}</button>
          <button type="submit" class="cb-btn next primary flex-fill">{{pageData.submit.label}}</button>
        </div>
      </cb-view-section>
    </savage-form>
  </section>
</template>
<script>
import AppSettings from "appSettings";
import CbSingleFileUpload from "common/components/cb-single-file-upload.vue";
import CbViewHeader from "general/cb-view-header.vue";
import CbViewSection from "general/cb-view-section.vue";
import { SavageForm, SavageFormTextInput, SavageFormMixin, SavageFormValidatedContainer } from "@clickboarding/savage";

export default {
  name: 'brands-add-edit',
  components: {
    CbSingleFileUpload,
    CbViewHeader,
    CbViewSection,
    SavageForm,
    SavageFormTextInput,
    SavageFormValidatedContainer
  },
  mixins: [
    // provides the validation directive required by
    // savage to validate the slot content of validated-container
    SavageFormMixin
  ],
  props: {
    dataUrl: {
      type: String,
      required: false
    },
    edit: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    fileUploadProps() {
      return {
        required: true,
        dropAreaLabel: this.pageData.upload.message,
        buttonLabel: this.pageData.upload.label,
        viewLabel: this.pageData.upload.viewLabel,
        deleteLabel: this.pageData.upload.deleteLabel,
        uploadUrl: this.pageData.upload.url,
        maxFiles: this.pageData.upload.validation.maxFileCount.value,
        maxFileSizeMb: this.pageData.upload.validation.maxFileSizeMB.value,
        maxFileSizeMbErrorMessage: this.pageData.upload.validation.maxFileSizeMB.message,
        allowedContentTypes: this.pageData.upload.validation.allowedContentTypes.value,
        allowedContentTypesErrorMessage: this.pageData.upload.validation.allowedContentTypes.message
      };
    }
  },
  methods: {
    cancel() {
      this.$_cb.router.changeView(AppSettings.viewBrands, this.pageData.return.url);
    },
    buildValidation() {
      return {
        required: {
          value: true,
          message: this.pageData.upload.validation.minFileCount.message
        }
      };
    },
    async loadPageAsync(dataUrl) {
      var response = await this.$http.get(this.dataUrl);
      this.pageData = response.body;
      this.file = response.body.file;
      this.localModel.referenceName = response.body.referenceNameValue;
      this.returnLink = {
        label: this.pageData.return.label,
        handler: () => {
          this.$_cb.router.changeView(
            AppSettings.viewBrands,
            this.pageData.return.url
          );
        }
      };
    },
    async saveAndRedirectAsync() {
      let url = this.pageData.submit.url;
      let content = {
        name: this.localModel.referenceName
      };

      if (this.file.contentIsNew) {
        content.stagedContentId = this.file.contentPath;
      }

      if (this.pageData.submit.method === "PUT") {
        await this.$http.put(url, content);
      } else {
        await this.$http.post(url, content);
      }

      this.$_cb.router.changeView(AppSettings.viewBrands, this.pageData.return.url);
    }
  },
  data() {
    return {
      pageData: null,
      returnLink: null,
      formState: {},
      file: null,
      localModel: {
        referenceName: null
      }
    };
  },
  watch: {
    dataUrl: {
      immediate: true,
      handler(newDataUrl, oldDataUrl) {
        if (newDataUrl && newDataUrl !== oldDataUrl) {
          this.loadPageAsync(newDataUrl);
        }
      }
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/mixins";

.logo-section {
  text-align: center;
  padding-top: 0.5rem;
}

.logo-upload {
  padding-top: 1rem;
}

.logo-upload-title {
  @include font-size-content-root;
  font-weight: bold;
  padding-top: 0.5rem;
}
</style>
