<template>
  <b-modal
      v-model="modalShow"
      :title="title"
      :ok-title="labels.saveLabel"
      centered
      static
      lazy
      size="lg"
      no-close-on-backdrop
      @ok="handleOk"
      @cancel="$emit('cancel')"
      @close="$emit('close')"
      @hide="handleHide"
  >
    <div>
      <div class="labelContent">
        <h2 style="border-bottom:solid 1px #ccc;">{{ labels.general.titleLabel }}</h2>
        <div class="d-flex align-items-center">
          <b-form-group class="w-50 mr-2">
            <template v-slot:label>
              {{ labels.general.nameLabel }}
              <span aria-hidden class="text-danger">*</span>
            </template>
            <b-form-input
                class="step-name"
                v-model="$v.model.name.$model"
                :state="validateState('name')"/>
          </b-form-group>
          <b-form-checkbox class="w-50 ml-2 mt-2"
                      v-model="model.isCandidate"
          >{{ labels.general.isCandidateLabel }}
          </b-form-checkbox>
        </div>
        <div>
          <b-form-group>
            <template v-slot:label>
              {{ labels.general.explanationLabel }}
              <span aria-hidden class="text-danger">*</span>
            </template>
            <b-form-textarea
                class="step-explanation"
                v-model="$v.model.explanation.$model"
                :state="validateState('explanation')"
            />
          </b-form-group>
        </div>
      </div>


      <h2 style="border-bottom:solid 1px #ccc;">{{ labels.events.titleLabel }}</h2>
      <div class="labelContent">
        <div class="d-flex">
          <b-form-group class="w-50 pr-2" :label="labels.events.eventsLabel">
            <b-form-select
                class="step-event-type"
                required
                text-field="name"
                value-field="typeEnum"
                v-model="eventType.typeEnum"
                @change="setEvent"
                :options="[{ typeEnum: null, name: labels.events.eventsDefault} ,...labels.events.types]"
            >
            </b-form-select>
          </b-form-group>
        </div>
      </div>

      <div v-if="event && event.typeEnum !== undefined"
           class="event-wrapper mb-3">
        <event-email v-if="event.typeEnum === 'Email'"
                     :labels="event"
                     :labels-triggers="labels.events.triggerLabels"
                     :edit-data="editEvent"
                     @saveEvent="saveEvent"
        ></event-email>
        <event-screening v-if="event.typeEnum === 'InitiateScreening'"
                         :labels="event"
                         :labels-triggers="labels.events.triggerLabels"
                         :edit-data="editEvent"
                         @saveEvent="saveEvent"
        ></event-screening>
        <event-process-action v-if="event.typeEnum === 'ProcessAction'"
                              :labels="event"
                              :labels-triggers="labels.events.triggerLabels"
                              :edit-data="editEvent"
                              @saveEvent="saveEvent"
        ></event-process-action>
        <event-milestone v-if="event.typeEnum === 'Milestone'"
                         :labels="event"
                         :labels-triggers="labels.events.triggerLabels"
                         :edit-data="editEvent"
                         @saveEvent="saveEvent"
        ></event-milestone>
      </div>

      <b-table v-if="model.events && model.events.length"
               class="new-table"
               :items="model.events"
               :fields="[
               { key: 'typeEnum', label: labels.events.typeHeader },
               { key: 'description', label: labels.events.detailHeader},
               { key: 'actions', label: '' }
               ]">
        <template v-slot:cell(typeEnum)="{ item }">
          <i :class="getEventIcon(item.typeEnum)" style="font-size:125%; padding:.5em;"></i>
        </template>
        <template v-slot:cell(actions)="{ item }">
          <div class="ml-auto float-right">
            <a @click.prevent="handleEditEvent(item)"
               :class="{'text-disabled': item.editing}"
               href=""
               title="Edit"
               style="display:inline-block;">
              <i class="fa fa-pencil-square-o" style="font-size:150%; padding:.5em;"></i>
            </a>
            <a @click.prevent="handleDeleteEvent(item)"
               class="delete-event"
               href=""
               :title="labels.events.deleteLabel"
               style="display:inline-block;">
              <i class="fa fa-trash-o" style="font-size:150%; padding:.5em; margin-left:.5em;"></i>
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </b-modal>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, maxLength} from "vuelidate/lib/validators";
import CbPrimaryMessage from "../../components/cb-primary-message";
import EventEmail from "./events/event-email";
import EventScreening from "./events/event-screening";
import EventProcessAction from "./events/event-process-action";
import EventMilestone from "./events/event-milestone";
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'

export default {
  name: "step-add",
  components: {EventMilestone, EventProcessAction, EventScreening, EventEmail, CbPrimaryMessage},
  mixins: [validationMixin],
  props: {
    labels: {
      required,
      type: Object
    },
    editItem: {
      type: Object
    },
    newStep: {
      type: Boolean
    },
    addUrl: {
      type: String
    }
  },
  data() {
    return {
      modalShow: true,
      eventType: {
        typeEnum: null
      },
      editEvent: null,
      event: null,
      model: {
        name: "",
        explanation: "",
        isCandidate: false,
        events: null
      }
    }
  },
  computed: {
    title() {
      return this.newStep ? this.labels.addTitle : this.labels.editTitle
    }
  },
  validations() {
    return {
      model: {
        name: {
          required,
          maxLength: maxLength(35)
        },
        explanation: {
          required,
          maxLength: maxLength(250)
        }
      }
    }
  },
  methods: {
    saveEvent(item) {
      if (this.editEvent) {
        const editEventIndex = this.model.events.findIndex(event => event.eventGuid === item.eventGuid)
        this.$set(this.model.events, editEventIndex, item)
      } else {
        this.model.events.push(item)
      }

      this.editEvent = null
      this.eventType = {
        typeEnum: null
      }
      this.event = null
    },
    handleDeleteEvent(item) {
      const index = this.model.events.findIndex(event => isEqual(item, event))
      this.model.events.splice(index, 1)
    },
    handleEditEvent(item) {
      item.editing = true
      this.eventType = {typeEnum: item.typeEnum}
      this.setEvent(item.typeEnum)
      this.editEvent = item
    },
    setEvent(eventTypeEnum) {
      this.editEvent = null
      this.event = this.labels.events.types.find(event => event.typeEnum === eventTypeEnum)
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.model[name]
      return $dirty ? !$error : null
    },
    getEventIcon(typeEnum) {
      let eventClass = ""
      switch (typeEnum) {
        case "Email":
          eventClass = "fa fa-envelope-o"
          break;
        case "ProcessAction":
          eventClass = "fa fa-bolt"
          break;
        case "InitiateScreening":
          eventClass = "fa fa-binoculars"
          break
        case "Task":
          eventClass = "fa fa-check-circle-o"
          break
        case "Milestone":
          eventClass = "fa fa-map-marker"
          break
      }
      return eventClass
    },
    handleHide(bvModelEvent) {
      if (bvModelEvent.trigger !== 'ok') {
        // hide happen on esc, but it also happens whenever the window gets
        // closed (e.g., hitting the ok button), ok is handled differently (saveAndClose)
        // don't forward this event in that scenario
        this.$emit('hide')
      }
    },
    async handleOk(bvModelEvent) {
      // according to the docs (https://bootstrap-vue.js.org/docs/components/modal#prevent-closing), async isn't supported
      // so always prevent the default and then handle closing it ourselves if needed
      bvModelEvent.preventDefault()

      await this.saveAndClose()
    },
    async saveAndClose() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }

      let url = this.newStep ? this.addUrl : this.editItem.edit.url
      let method = this.newStep ? 'post' : 'put'

      const {data} = await this.$http[method](url, this.model)
      this.$emit('ok', data)
      this.modalShow = false
    }
  },
  beforeMount() {
    if (this.editItem) {
      this.model = cloneDeep(this.editItem)
      this.model.events = this.model.events || []
    }
  }
}
</script>

<style lang="scss" scoped>
.event-wrapper {
  padding: 1rem;
  border: 1px #ccc solid;
  border-radius: 1rem;
}

.table::v-deep {
  border-radius: 6px;

  th, td {
    padding: 0.5rem;
  }
}
</style>
