<template>
  <section>
    <cb-pdf-viewer v-if="pdfViewerLabels" class="forms-document-pdf" :url="url" :labels="pdfViewerLabels" />
    <button class="cb-btn primary" @click="onComplete">{{$t('common.done')}}</button>
  </section>
</template>
<script>
import CbPdfViewerDataProvider from "common/components/pdf/cb-pdf-viewer-data-provider";

export default {
  name: "forms-document",
  components: {
    CbPdfViewer: () => import('@clickboarding/pdf-viewer')
  },
  props: {
    url: {
      type: String,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  created () {
    CbPdfViewerDataProvider.getData().then(data => {
      this.pdfViewerLabels = data;
    });
  },
  methods: {
    shouldConfirmExit() {
      return false;
    }
  },
  data () {
    return {
      pdfViewerLabels: null
    }
  }
};
</script>
<style scoped lang="scss">
  .form-document-pdf {
    padding-bottom: 1.5rem;
    height: 40rem !important; // !important overrides the 100% height in cb-pdf-viewer
  }

  .cb-btn {
    width: 100%;
  }
</style>