<template>
  <div class="vertical-flex">
    <template v-if="apiData">
      <cb-view-header class="vertical-flex-min" :title="apiData.title" :return-link="returnLink"/>
      <cb-view-section class="vertical-flex-fill" type="primary" :includePrimaryMessages="true">
        <cb-view-section type="secondary">
          <cb-check-list
            :items="localItems"
            v-model="selectedValues"
            :header="apiData.elementsComponent.label"
          />
        </cb-view-section>
        <div class="d-flex mt-4">
          <button type="button" @click="onBack()" class="cb-btn back primary flex-fill">{{$t('common.back')}}</button>
          <button type="button" @click="submit()" class="cb-btn next primary flex-fill">{{$t('common.next')}}</button>
        </div>
      </cb-view-section>
    </template>
  </div>
</template>
<script>
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import clonedeep from 'lodash.clonedeep';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import CbCheckList from 'general/cb-check-list.vue';

export default {
  name: 'forms-data-entry-collect-data-category-elements',
  components: {
    CbViewHeader,
    CbViewSection,
    CbCheckList
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    returnLink: {
      /*
        This object is passed directly to the cb-view-header
        component and in addition, its handler
        is called when clicking the back button
      */
      type: Object,
      required: true
    },
    onBack: {
      type: Function,
      required: true,
    },
    onNext: {
      type: Function,
      required: true
    },
    selectedElements: {
      type: Array,
      required: false
    }
  },
  watch: {
    selectedElements: {
      immediate: true,
      handler (newValue) {
        this.selectedValues = newValue ? newValue.map(element => this.mapElementToCheckListValue(element)) : [];
      }
    },
  },
  mounted () {
    this.$http.get(this.dataUrl).then((response) => {
      this.apiData = response.body;
    });
  },
  destroyed () {
    PrimaryMessageBus.$emit('clear-all-messages');
  },
  data () {
    return {
      apiData: null,
      selectedValues: [],
    };
  },
  computed: {
    /*
      We do not bind the entire item.value to the checklist since
      the data is refreshed when this component is reloaded.  Each
      item in the apiData contains a dataUrl that is different
      each time it is requested, thus the checklist doesn't re-check
      the original items after the component is reloaded
    */
    localItems() {
      return this.apiData.elementsComponent.items.map(item => {
        return {
          label: item.label,
          value: this.mapElementToCheckListValue(item.value)
        };
      });
    }
  },
  methods: {
    mapElementToCheckListValue (element) {
      return element.datafield; // The datafield is a unique key across all items
    },
    submit () {
      if (this.selectedValues.length < 1) {
        PrimaryMessageBus.$emit('send-error-message', this.$t('formsBuilder.dataEntry.datafieldElementCollect.noneSelectedLabel'));
      } else {
        // reorder selected elements to be the same as displayed
        // as opposed to the order you selected them
        const reorderedElements = [];
        this.apiData.elementsComponent.items.map(item => {
          return this.selectedValues.map(selectedValue => {
            if (selectedValue === item.value.datafield) {
              reorderedElements.push(item.value);
            };
          });
        });
        this.onNext(reorderedElements);
      }
    }
  }
}
</script>