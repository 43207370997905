  export default function dashboard ($http, aaNotify, appSettings, DashService, locationSvc) {
	var link = function (scope, element, attrs) {
		aaNotify.clearAll();
		var storage = "cb" + appSettings.appType + "attentionFilter";

		scope.$watch(function() { return DashService.data.itemSnoozed; }, function(newVal, oldVal) {
			if (newVal === oldVal) return;

			for (var key in scope.records.items) {
				if(scope.records.items[key].id === DashService.data.itemSnoozed.id) {
					scope.records.items[key].isSnoozed = DashService.data.itemSnoozed.bool;
					break;
				}
			}
		}, true);

		scope.$watch('data', function () {
			if (typeof scope.data != 'undefined') fetchFilteredData(scope.data);
		});

		scope.filterClick = function (bucket) {
			bucket.isSelected = !bucket.isSelected;
			var params = {};
			if ( bucket.isSelected) params[scope.records.bucketFilterKey] = bucket.typeEnum;

			sessionStorage.setItem(storage, angular.toJson(params));
			fetchFilteredData(scope.data);
		};

		var fetchFilteredData = function (data) {
			$http.get(data.getUrl, { params: angular.fromJson(sessionStorage.getItem(storage)) }).then(function (response) {
				var data = response.data;
				scope.records = data;
				// Reset snooze flag on filter
				DashService.data.itemSnoozed.bool = false;
			});
		};
	};

	return {
		restrict: "E",
		replace: true,
		link: link,
		template: require('dashboard/dashboard.html'),
		scope: {
			data: '='
		}
	};
};

dashboard.$inject = ['$http', 'aaNotify', 'appSettings', 'DashService', 'locationSvc']
