/**
 * This function allows you to map many
 * props from on a Vue component to computed 
 * values that support the .sync prop modifier.
 * 
 * @param {string} prefix - A prefix to be applied to the computed values (i.e.
 * given the prefix "local" and prop name "age", the computed will be "localAge") 
 * @param {string[]} propNames - The names of the props that need to support .sync
 */
function mapPropsForSync (prefix, propNames) {
  return propNames.reduce((localProps, propName) => {
    const localPropName = `${prefix}${propName[0].toUpperCase()}${propName.substring(1)}`;
    return {
      ...localProps,
      [localPropName]: {
        get () {
          return this[propName];
        },
        set (v) {
          this.$emit(`update:${propName}`, v);
        }
      }
    };
  }, {});
}

export { mapPropsForSync }