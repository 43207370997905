<template>
  <section class="savage-form-agreement-radio-group">
    <cb-legal-terms :title="legal.title" :terms="legal.message">
    </cb-legal-terms>
    <!-- 
      Needs to be wrapped in a container element or
      else the radio group will get re-rendered
      when toggling values with arrow keys, which causes
      focus to be lost: https://github.com/vuejs/vue/issues/6929
    -->
    <div>
      <cb-message-bar v-if="showDisagreeWarning" class="agreement-message-bar" :message="this.disagree.warning" :type="'warning'" />
    </div>
    <savage-form-validated-container :field-name="name"
      :custom-validation="agreementRequired"
      :value="localValue">
      <cb-radio-group :name="name"
        slot="form-field"
        class="agreement-cb-radio-group"
        :items="radioItems"
        v-model="localValue" />
    </savage-form-validated-container>
    <img v-if="localValue === true" :src="SignatureImage" :alt="signatureDescription" />
  </section>
</template>

<script>
import { CbMessageBar } from '@clickboarding/vue-components';
import { CbRadioGroup, SavageFormMixin, SavageFormValidatedContainer } from '@clickboarding/savage';
import CbLegalTerms from 'common/components/cb-legal-terms.vue';
import isBoolean from 'lodash.isboolean';
import uuid from 'uuid/v4';

export default {
  name: 'savage-form-agreement-radio-group',
  props: {
    name: {
      type: String,
      required: false,
      default: () => {
        return uuid();
      }
    },
    agree: {
      type: Object,
      required: true
    },
    disagree: {
      type: Object,
      required: true
    },
    validation: {
      type: Object,
      required: false
    },
    // provide either signatureImageContent & signatureImageContentType
    // or signatureImageString, but not all three
    signatureImageContent: {
      type: String,
      required: false
    },
    signatureImageContentType: {
      type: String,
      required: false
    },
    signatureImageString: {
      type: String,
      required: false
    },
    signatureDescription: {
      type: String,
      required: true
    },
    legal: {
      type: Object,
      required: true
    },
    value: {
      type: Boolean,
      required: false
    }
  },
  components: {
    CbRadioGroup,
    CbMessageBar,
    CbLegalTerms,
    SavageFormValidatedContainer
  },
  mixins: [
    // provides the validation directive required by 
    // savage to validate the slot content of validated-container
    SavageFormMixin
  ],
  computed: {
    SignatureImage () {
      if (this.signatureImageString) {
        return this.signatureImageString;
      }
      else if (this.signatureImageContentType && this.signatureImageContent) {
        return 'data:' + this.signatureImageContentType + ';base64,' + this.signatureImageContent;
      }
      else
        console.error('signature image not provided');
    },
    radioItems () {
      return [
        {label: this.disagree.label, value: this.disagree.value},
        {label: this.agree.label, value: this.agree.value}
      ]
    },
    agreementRequired () {
      return {
        'required' : {
          value: isBoolean(this.localValue), // confirm they have made some selection in the radio
          message: this.validation.required.message
        }
      };
    },
    showDisagreeWarning () {
      if (this.localValue === this.disagree.value) {
        return true
      } else {
        return false
      }
    },
    localValue: {
      get() {
        return this.value;
      },
      set(v) {
        const newValue = v;
        this.$emit('input', newValue);
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .savage-form-agreement-radio-group {
    text-align: center;
  }
  
  .agreement-cb-radio-group,
  .agreement-message-bar {
    margin-bottom: 1rem;
  }
</style>
