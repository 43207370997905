import { AxiosLocal as axios } from "common/services/axiosLocal.service";

export default {
  async getFilters (url) {
    const response = await axios.get(url);

    return response.data;
  },
  async getLocations (url, params) {
    const response = await axios.get(url, { params });

    return response.data;
  },
};
