<template>
  <div class="cb-dropdown-menu">
    <!-- {{list}} -->
    <button class="cb-btn secondary" :class="{ active: showActions }" @click="toggleList()" @blur="hideList()">
      {{label}}
    </button>
    <ul class="action-list text-l" v-if="showActions">
      <li v-for="item in list" class="item" @mousedown="callAction(item)">
        {{item.label}}
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'cb-button-dropdown',
    props: [
      'list',
      'label',
      'action'
    ],
    methods: {
      toggleList () {
        this.showActions = !this.showActions;
      },
      hideList () {
        this.showActions = false;
      },
      callAction (item) {
        this.action(item);
        this.hideList();
      }
    },
    data() {
      return {
        showActions: false
      }
    }
  }

</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';

  .cb-dropdown-menu {
    position: relative;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */

    .cb-btn {
      width: 100%;
    }

    .cb-btn.active {
      background: $cb-medium-blue-6;
      border: 1px solid $cb-brand-blue-2;
    }

    .action-list {
      z-index: 1000;
      position: absolute;
      top: 100%; right: 0;
      background: white;
      padding: 0;
      width: 100%;
      box-shadow: 1px 2px 5px rgba(0,0,0,0.35);
      cursor: pointer;

      .item {
        margin-left: 2px;
        color: $cb-brand-blue-2;
        padding: .75em;

        &:hover {
          background: $cb-medium-blue-6;
          margin-left: 0;
          border-left: 2px solid $cb-brand-blue-1;
        }
      }
    }
  }
</style>
