<template>
  <section class="vertical-flex">
    <!-- With the v-if on the section element above, the markup would never render in tests. -->
    <template v-if="apiData">
      <cb-view-header class="vertical-flex-min" :title="apiData.title" :return-link="returnLink" />
      <cb-view-section class="vertical-flex-fill"  type="primary" :includePrimaryMessages="true">
        <cb-view-section type="secondary">
          <cb-grid-selector :label="apiData.contentTypes.title" :items="apiData.contentTypes.items" :onSelection="onNext">
            <cb-grid-selector-item-content slot="item-content" slot-scope="{ item }" :item="item" :iconNameProvider="getIconName" />>
          </cb-grid-selector>
        </cb-view-section>
        <div class="d-flex mt-4">
          <button type="button" @click="returnLink.handler()" class="cb-btn back primary flex-fill">{{apiData.back.label}}</button>
        </div>
      </cb-view-section>
    </template>
  </section>
</template>

<script>
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import CbGridSelector from 'common/components/cb-grid-selector.vue';
import CbGridSelectorItemContent from 'common/components/cb-grid-selector-item-content.vue';
import VideoIcon from 'forms/video.svg';
import HtmlIcon from 'forms/html.svg';
import ImageIcon from 'forms/image.svg';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';

export default {
  name: 'forms-content-build-element-type-selector',
  components: {
    CbViewHeader,
    CbViewSection,
    CbGridSelector,
    CbGridSelectorItemContent
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    returnLink: {
      /*
        This object is passed directly to the cb-view-header
        component and in addition, its handler
        is called when clicking the back button
      */
      type: Object,
      required: true
    },
    onNext: {
      type: Function,
      required: true
    }
  },
  methods: {
    getIconName (item) {
      const mapping = {
        'Video': VideoIcon.id,
        'Html': HtmlIcon.id,
        'Image': ImageIcon.id
      };
      return mapping[item.type];
    },
  },
  created () {
    window.scrollTo(0, 0);
  },
  mounted () {
    this.$http.get(this.dataUrl).then((response) => {
      this.apiData = response.body;
    });
  },
  destroyed () {
    PrimaryMessageBus.$emit('clear-all-messages');
  },
  data () {
    return {
      apiData: null
    };
  }
}
</script>