<template>
  <cb-breadcrumbs :items="crumbs" />
</template>
<script>
import AppSettings from 'appSettings';
import { mapGetters } from 'vuex';
import CbBreadcrumbs from 'common/components/cb-breadcrumbs.vue'

export default {
  name: 'datafield-crumbs',
  components: {
    CbBreadcrumbs,
  },
  props: {
    schemaFieldId: {
      type: String,
      required: false
    },
    allowCurrentCrumbLink: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters('datafields', [
      'findBySchemaFieldId'
    ]),
    currentItem () {
      return this.findBySchemaFieldId(this.schemaFieldId) || null;
    },
    crumbs () {
      function getParentOrNull (item) {
        return item.metadata.parent || null;
      }

      function getItemLineage(toItem) {
        let itemLineage = [];
        for (let item = toItem; item; item = getParentOrNull(item)) {
          itemLineage.push(item);
        }
        return itemLineage.reverse(); // want array ordered from root -> leaf
      }

      return getItemLineage(this.currentItem).map((item, index) => 
        this.buildCrumb(item, index));
    }
  },
  methods: {
    buildCrumb(item, index) {
      const label = (index === 0) ? this.$t('datafields.title') : item.metadata.displayName;
      let link = () => this.$_cb.router.changeView(AppSettings.viewDatafields, { schemaFieldId: item.schemaFieldId }, null);

      if (!this.allowCurrentCrumbLink && item === this.currentItem)
        link = null;

      return {
        label,
        link
      };
    }
  }
}
</script>