<template>
  <savage-form-validated-container :field-name="name"
    :customValidation="buildCustomValidation"
    :value="localValue"
    >
    <div class="savage-form-html-editor">
      <textarea contenteditable="true" class="savage-html-editor" slot="form-field" :id="name" :name="name" v-model="localValue"></textarea>
    </div>

  </savage-form-validated-container>
</template>

<script>
  import { SavageFormFieldMixin, SavageFormValidatedContainer } from '@clickboarding/savage';
  import 'common/polyfills/array.includes.polyfill';

  export default {
    name: 'savage-form-html-editor',
    mixins: [ SavageFormFieldMixin ],
    components: {
      SavageFormValidatedContainer
    },
    props: {
      label: {
        type: String,
        required: false
      },
      hiddenToolbarItems: {
        type: Array,
        required: false
      }
    },
    mounted () {
      window.CKEDITOR.disableAutoInline = true;

      let toolbarElement = document.getElementById('htmlToolbar');
      this.toolbar.items = [
        "Format",
        "Bold",
        "Italic",
        "Blockquote",
        "BulletedList",
        "NumberedList",
        "JustifyLeft",
        "JustifyCenter",
        "JustifyRight",
        "JustifyBlock",
        "Link",
        "Unlink"
      ];
      this.toolbar.items = this.toolbar.items
        .filter(value => !(this.hiddenToolbarItems || []).includes(value)); //removes all hidden toolbar items from editor

      window.CKEDITOR.replace(this.name, {
          startupFocus: true,
          disableNativeSpellChecker: false,
          placeholder: this.computedPlaceholder,
          contentsCss: ['app/common/css/html-editor-content.css'],
          format_tags: "p;h1;h2;h3",
          toolbar: [ this.toolbar.items ],
          removePlugins: [
            "htmlwriter", //removes all white space and line breaks from html output
            "magicline" //removes the red line "insert paragraph here" from editor
          ],
          extraPlugins: ['sharedspace', 'justify'],
          linkShowAdvancedTab: false,
          linkShowTargetTab: false
      });

      window.CKEDITOR.on('dialogDefinition', this.styleAllDialogs);
      window.CKEDITOR.on('dialogDefinition', this.configureLinkDialogs);

      this.editor = window.CKEDITOR.instances[this.name];
      this.editor.on('change', this.editorUpdate);
    },
    beforeDestroy() {
      window.CKEDITOR.removeListener('dialogDefinition', this.styleAllDialogs);
      window.CKEDITOR.removeListener('dialogDefinition', this.configureLinkDialogs);
      this.editor.removeListener('change', this.editorUpdate);
    },
    computed: {
      buildCustomValidation () {
        return {
          'required' : {
            value: this.hasHtmlContent,
            message: this.validation.required.message
          },
          'max-length': {
            value: this.lessThanMaxLength,
            message: this.validation.maxLength.message
          }
        };
      },
      hasHtmlContent () {
        return !!(this.localValue && this.localValue.length > 0)
      },
      lessThanMaxLength () {
        return !!(this.localValue && this.localValue.length <= this.validation.maxLength.value)
      }
    },
    methods: {
      editorUpdate () {
        this.editor.updateElement();

        let inputEvent;
        let element = this.$el.querySelector('.savage-html-editor');
        if (typeof(window.Event) === 'function') {
          inputEvent = new window.Event('input');
        } else {
          inputEvent = document.createEvent('Event');
          inputEvent.initEvent('input', true, true);
        }
        element.dispatchEvent(inputEvent);
      },
      styleAllDialogs(ev) {
        ev.data.definition.dialog.getElement().addClass("savage-form-html-editor-dialog");
      },
      configureLinkDialogs(ev) {
        var dialogName = ev.data.name;
        var dialogDefinition = ev.data.definition;

        if (dialogName === 'link') {
          var self = this;
          dialogDefinition.minHeight = 0;
          dialogDefinition.buttons = [
            CKEDITOR.dialog.cancelButton.override({ label: self.$t("common.cancel"), className: 'cb-btn primary' }),
            CKEDITOR.dialog.okButton.override({ label: self.$t("common.update"), className: 'cb-btn primary' })
          ];
          dialogDefinition.resizable = CKEDITOR.DIALOG_RESIZE_NONE;
          dialogDefinition.getContents('target').get('linkTargetType')['default'] = '_blank';
          dialogDefinition.getContents('info').get('linkDisplayText')['required'] = true;
          dialogDefinition.getContents('info').get('linkDisplayText')['validate'] = function() {
            if(!this.getValue() || this.getValue().length === 0) {
              alert(self.$t('formsBuilder.link.displayTextRequiredMessage'));
              return false;
            }
            else {
              return true;
            }
          };
          dialogDefinition.onShow = (function() {
            var cached_function = dialogDefinition.onShow;
            return function() {
              cached_function.apply(this, arguments);
              this.getContentElement('info', 'url').getInputElement().setAttribute('placeholder', self.$t("formsBuilder.link.placeholder"));
              this.getContentElement('info', 'protocol').getElement().getParent().hide();
              this.getContentElement('info', 'linkType').getElement().getParent().hide();
            };
          }());
        }
      }
    },
    data () {
      return {
        editor: null,
        htmlContent: null,
        toolbar: {
          items: null
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';

  .savage-form-html-editor {
    @import 'common/css/html-editor';
  }

  .savage-form-html-editor-dialog {
    @import 'common/css/html-editor';
  }
</style>
