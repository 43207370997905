import appSettings from "appSettings";
import { AxiosLocal as axios } from "common/services/axiosLocal.service";
import { each, map } from "lodash";
import dateFns from "date-fns";
import reportsUrlHelper from './reportsUrlHelper';

const pageSize = 50;
const filterLists = {'foo': 'bar'};
const filterIdColumns = {'assignedTo': 'assignedToUserNameId'};
//Can be a filterObj or filterModel
function transformAGFilter(filterObj) {
  //transform filters into an object from dictionary oriented shape
  let filters = new Array();
  let reportingAGServerSideRequestPto = {
    startRow: 0,
    endRow: pageSize,
    filterModel: [],
    sortModel: [],
    groupModel: [],
    rowGroupColumns: [],
    groupKeys: []
  };
  if (filterObj) {
console.log(filterObj);
    reportingAGServerSideRequestPto.startRow = filterObj.startRow || 0;
    reportingAGServerSideRequestPto.endRow = filterObj.endRow || pageSize;
    reportingAGServerSideRequestPto.skip = filterObj.startRow > 0 ? filterObj.startRow : 0;
    reportingAGServerSideRequestPto.take = filterObj.endRow ? (filterObj.endRow - filterObj.startRow) : pageSize;
    reportingAGServerSideRequestPto.sortModel = filterObj.sortModel;
    if (filterObj.rowGroupCols) {
      var groups = filterObj.rowGroupCols.map(function(obj) {
        return obj.id;
      }
      );
    }
    reportingAGServerSideRequestPto.groupModel = groups;
    reportingAGServerSideRequestPto.rowGroupColumns = filterObj.rowGroupCols;
    reportingAGServerSideRequestPto.groupKeys = filterObj.groupKeys;

    const filterModelTemp = filterObj.filterModel || filterObj; 
    if (filterModelTemp) {
        each(filterModelTemp, (value, key) => {
        let curFilter = { "colName": key, filter: value };
        if (filterIdColumns[key] && value.values &&  value.values.length>0){
          let a = filterLists[key];
          //translate set list from string to ID
          let ids = value.values.map(x =>  a.find(y => y.name == x || y.id == x ).id)
          curFilter.filter.values = ids;
          curFilter.colName = filterIdColumns[key];
        }
        filters.push(curFilter);
      });
      reportingAGServerSideRequestPto.filterModel = filters;
    }
  }
  return reportingAGServerSideRequestPto;
}

export default {
  getPageSize() { return pageSize },
  async getReportColumnDescriptions(reportType) {
    var reportingUrl = await reportsUrlHelper.getColumnDescriptions(reportType);
      
    const response = await axios.post(reportingUrl);

    return response.data;
  },
  getColumnDefinition(columnArray, type) {
      var columnDefinitionArray = [];

      columnArray.forEach((columnDescriptionItem) => {
          var columnDefinition = {
              field: columnDescriptionItem.fieldName,
              headerName: columnDescriptionItem.headerName,
              headerTooltip: columnDescriptionItem.headerTooltip,
              enableRowGroup: columnDescriptionItem.enableRowGroup,
              hide: columnDescriptionItem.hide,
              sortable: columnDescriptionItem.sortable,
              filter: columnDescriptionItem.filter
          }

          switch (columnDescriptionItem.filterType) {
              case 'text':              
                  if (columnDescriptionItem.filter) {
                      columnDefinition = { ...columnDefinition, ...this.buildTextFilterParams() };
                  }
                  
                  break;
              case 'date':
                  columnDefinition.valueFormatter = (params) => (params.value == ' ' ? 'No Date' : formatDate(params.value));

                  if (columnDescriptionItem.filter) {
                      columnDefinition = { ...columnDefinition, ...this.buildDateFilterParams() };
                  }
                  
                  break;
              case 'set':
                  columnDefinition.valueFormatter = (params) => (params.value == ' ' ? 'No ' + columnDescriptionItem.headerName : params.value);

                  if (columnDescriptionItem.filter) {
                      columnDefinition = { ...columnDefinition, ...this.buildSetFilterParams({ field: columnDescriptionItem.fieldName, reportType: type }) };
                  }
                  
                  break;
              case 'setId':
                  columnDefinition.valueFormatter = (params) => (params.value == ' ' ? 'No ' + columnDescriptionItem.headerName : params.value);

                  if (columnDescriptionItem.filter) {
                      columnDefinition = { ...columnDefinition, ...this.buildSetIdFilterParams({ field: columnDescriptionItem.fieldName, reportType: type }) };
                  }

                  break;
              default:
                  break;
          }

          columnDefinitionArray.push(columnDefinition);
      });   

      return columnDefinitionArray;
  },
  async getData(filterObj, reportType) {
    var reportingUrl = await reportsUrlHelper.getReportDataUrl(reportType);
    let reportingAGServerSideRequestPto = transformAGFilter(filterObj);
      
    const response = await axios.post(reportingUrl, reportingAGServerSideRequestPto);

    return response.data;
  },
  async getExport(filterObj, colOrder, reportType, downloadType) {
    var reportingUrl = await reportsUrlHelper.getExport(reportType, 'Export', downloadType);
    let reportingAGServerSideRequestPto = transformAGFilter(filterObj);
    reportingAGServerSideRequestPto = { ...reportingAGServerSideRequestPto, colOrder };
    return await axios.post(reportingUrl, reportingAGServerSideRequestPto, { responseType: 'blob' });
  },
  getColumnDefs(type) {
    var colDefs = {
      "ProcessFlow": [
        { 
          field: 'formattedName', 
          headerName: 'Name', 
          headerTooltip: 'Name', 
          ...this.buildTextFilterParams() 
        },
        { 
          field: 'userApiIdentifier', 
          headerName: 'User API Identifier', 
          headerTooltip: 'User API Identifier', 
          ...this.buildTextFilterParams() 
        },
        { 
          field: 'candidateId', 
          headerName: 'Candidate API Identifier', 
          headerTooltip: 'Candidate API Identifier', 
          ...this.buildTextFilterParams() 
        },
        { 
          field: 'employeeExperience', 
          headerName: 'Employee Experience', 
          headerTooltip: 'Employee Experience', 
          ...this.buildSetFilterParams({field: 'employeeExperience', reportType: type }) 
        },
        { 
          field: 'processFlowName', 
          headerName: 'Process Flow', 
          headerTooltip: 'Process Flow Name', 
          ...this.buildSetFilterParams({field: 'processFlowName', reportType: type }) 
        },
        { 
          field: 'currentStep', 
          headerName: 'Current Step',  
          headerTooltip: 'Current Step Within the Process Flow', 
          valueFormatter: (params) => (params.value ==' ' ? 'No Current Step' : params.value), 
            ...this.buildSetFilterParams({
                filterParams: { valueFormatter: (params) => (params.value == ' ' ? 'No Current Step' : params.value) },
                field: 'currentStep',
                reportType: type
            }) 
        },
        { 
          field: 'locationName', 
          headerName: 'Location',
          headerTooltip: 'Location Name',  
          ...this.buildSetFilterParams({field: 'locationName', reportType: type }) 
        },
        { 
          field: 'processFlowStatus', 
          headerName: 'Status', 
          headerTooltip: 'Status of the Process Flow', 
          ...this.buildSetFilterParams({field: 'processFlowStatus', reportType: type }) 
        },  
        { 
          field: 'processDueDate', 
          headerName: 'Due Date', 
          headerTooltip: 'Due Date of the Process Flow',
           valueFormatter: (params) => formatDate(params.value), 
           ...this.buildDateFilterParams() 
        },
        { 
          field: 'completionDate', 
          headerName: 'Completion Date', 
          headerTooltip: 'Completion Date of the Process Flow',
           valueFormatter: (params) => (params.value ==' ' ? 'No Date' : formatDate(params.value)), 
           ...this.buildDateFilterParams()
        }
      ],
      "Forms": [
        { 
          field: 'formattedName', 
          headerName: 'Name', 
          headerTooltip: 'Name', 
          ...this.buildTextFilterParams() 
        },
        { 
          field: 'userApiIdentifier', 
          headerName: 'User API Identifier', 
          headerTooltip: 'User API Identifier', 
          ...this.buildTextFilterParams() 
        },
        { 
          field: 'candidateId', 
          headerName: 'Candidate API Identifier', 
          headerTooltip: 'Candidate API Identifier', 
          ...this.buildTextFilterParams() 
        },
        { 
          field: 'processFlowName', 
          headerName: 'Process Flow', 
          headerTooltip: 'Process Flow Name', 
          ...this.buildSetFilterParams({field: 'processFlowName', reportType: type }) 
        },
        { 
          field: 'formName', 
          headerName: 'Form Name', 
          headerTooltip: 'Form Name', 
          ...this.buildSetFilterParams({field: 'formName', reportType: type }) 
        },
        { 
          field: 'formStatus', 
          headerName: 'Status', 
          headerTooltip: 'Status of the Form',
          ...this.buildSetFilterParams({field: 'formStatus', reportType: type }) 
        }, 
        { 
          field: 'assignedTo', 
          headerName: 'Assigned To', 
          headerTooltip: 'Assigned To', 
          ...this.buildSetIdFilterParams({field: 'assignedTo', reportType: type }) 
        },
        {
          field: 'formDueDate', 
          headerName: 'Due Date', 
          headerTooltip: 'Due Date of the Form', 
          valueFormatter: (params) => (params.value ==' ' ? 'No Date' : formatDate(params.value)), 
          ...this.buildDateFilterParams()
       },
        { 
          field: 'completionDate', 
          headerName: 'Completion Date', 
          headerTooltip: 'Completion Date of the Process Flow',
          enableRowGroup: false,
           valueFormatter: (params) => (params.value ==' ' ? 'No Date' : formatDate(params.value)), 
           ...this.buildDateFilterParams()
        }
      ],
      "Candidates": [
            {
                field: 'formattedName',
                headerName: 'Full Name',
                headerTooltip: 'Full Name',
                ...this.buildTextFilterParams(),
                enableRowGroup: false
            },
            {
              field: 'emailAddress',
              headerName: 'Email Address',
              headerTooltip: 'Email Address',
              ...this.buildTextFilterParams(),
              enableRowGroup: false
            },
            {
              field: 'sourceSystemId',
              headerName: 'Source System Id',
              headerTooltip: 'Source System Id',
              ...this.buildTextFilterParams(),
              enableRowGroup: false,
              hide: appSettings.sourceSystemIdFeatureToggle.toLowerCase() != "true",
            },
            {
                field: 'userApiIdentifier',
                headerName: 'User API Identifier',
                headerTooltip: 'User API Identifier',
                ...this.buildTextFilterParams(),
                enableRowGroup: false
            },
            {
                field: 'candidateId',
                headerName: 'Candidate API Identifier',
                headerTooltip: 'Candidate API Identifier',
                ...this.buildTextFilterParams(),
                enableRowGroup: false
            },
            {
                field: 'ssoIdentifier',
                headerName: 'SSO Identifier',
                headerTooltip: 'SSO Identifier',
                ...this.buildTextFilterParams(),
                enableRowGroup: false
            },
            {
                field: 'userName',
                headerName: 'Username',
                headerTooltip: 'Username',
                ...this.buildTextFilterParams(),
                enableRowGroup: false
            },
            {
                field: 'dateOfBirth',
                headerName: 'Date of Birth',
                headerTooltip: 'Date of Birth',
                valueFormatter: (params) => (params.value == ' ' ? 'No Date' : formatDate(params.value)),
                ...this.buildDateFilterParams()
            },
            {
                field: 'ssn',
                headerName: 'SSN',
                headerTooltip: 'SSN',
                ...this.buildTextFilterParams()
            },
            {
                field: 'createDate',
                headerName: 'Created Date',
                headerTooltip: 'Created Date',
                valueFormatter: (params) => (params.value == ' ' ? 'No Date' : formatDate(params.value)),
                ...this.buildDateFilterParams()
            },
            {
                field: 'updateDate',
                headerName: 'Updated Date',
                headerTooltip: 'Updated Date',
                valueFormatter: (params) => (params.value == ' ' ? 'No Date' : formatDate(params.value)),
                ...this.buildDateFilterParams()
            }
      ]
    }

    return colDefs[type];
  },
  async getDynamicColumnDefs(type) {    
    var reportColumnDescriptions = await this.getReportColumnDescriptions(type);
    var columnDefinition = this.getColumnDefinition(reportColumnDescriptions, type);

    let promise = new Promise(function (resolve, reject) {
      resolve(columnDefinition);
    });

    return promise;
  },
  buildTextFilterParams(filterParams = {}) {
    return this.getAgFilterParamDefs({ filter: 'agTextColumnFilter', filterParams: filterParams})
  }, 
  buildSetFilterParams({filterParams, reportType, field, bUseIdField}) {
     return this.getAgFilterParamDefs({ filter: 'agSetColumnFilter', filterParams: filterParams, reportType: reportType, field: field});
  }, 
  buildSetIdFilterParams({filterParams, reportType, field, bUseIdField}) {
    return this.getAgFilterParamDefs({ filter: 'agSetColumnIdFilter', filterParams: filterParams, reportType: reportType, field: field});
  },
  buildDateFilterParams(filterParams = {}) {
    return this.getAgFilterParamDefs({filter: 'agDateColumnFilter', filterParams: filterParams });
  },
  filterFormatter(param) {
    return param.data.name;
  },
  getAgFilterParamDefs({filter, filterParams, field, reportType}) {
    const filterTypes = {
      'agTextColumnFilter' : {
        filter: filter, 
        filterParams: {
          ...filterParams,
          suppressAndOrCondition: true, 
          buttons: ['clear'],
          filterOptions: ['contains']
        }
      },
      'agSetColumnFilter' : {
        filter: filter,
        filterParams: {
          ...filterParams,
          values: async params => {
            const fm = params.api.getFilterModel();
            let list = await this.getFilteredList(fm, reportType, field); 
            params.success(list);
          },
          applyMiniFilterWhileTyping: true,
          buttons: ['clear'] ,
          refreshValuesOnOpen: true
        } 
      },
      'agSetColumnIdFilter' : {
        filter: 'agSetColumnFilter',
        filterParams: {
          ...filterParams,
          values: async params => {
            const fm = params.api.getFilterModel();
            let list = await this.getFilteredIdList(fm, reportType, field); 
            filterLists[field] = list;
            params.success(list.map (x => x.name || x));           
          },
          applyMiniFilterWhileTyping: true,
          buttons: ['clear'] ,
          refreshValuesOnOpen: true
        } 
      },
      'agDateColumnFilter' : {
        filter : filter,
        filterParams: {
          ...filterParams,
          buttons: ['clear'],
          suppressAndOrCondition: true,
        }
      }
    }
    return filterTypes[filter];
  },
  async getFilteredList(fm, reportType, field) {
    let reportingAGServerSideRequestPto = transformAGFilter(fm);  
    var reportingUrl = await reportsUrlHelper.getFilteredListByColumn(reportType, field); 
    const response = await axios.post(reportingUrl, reportingAGServerSideRequestPto );
    return map(response.data, (x) => {
      return x.name || x;
    });
  },
  async getFilteredIdList(fm, reportType, field) {
    let reportingAGServerSideRequestPto = transformAGFilter(fm);  
    var reportingUrl = await reportsUrlHelper.getFilteredListByColumn(reportType, field); 
    const response = await axios.post(reportingUrl, reportingAGServerSideRequestPto );
    return response.data;
  },
  getDefaultColumnDef() {
    //later if needed this can be report specific by passing in report type
    return {
      sortable: true,
      filter: true,
      resizable: true, 
      enableRowGroup: true 
    }
}
}

window.formatDate = function formatDate(dateString) {
  if (dateString)
    return dateFns.format(dateString, 'MM/DD/YYYY');
  else return null;
}