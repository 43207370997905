<template>
  <div class="vertical-flex">
    <cb-view-header class="vertical-flex-min" :title="$t('candidate.addCandidateHeading')" :return-link="returnLink" />
    <validation-observer slim v-slot="{ handleSubmit }">
      <b-form class="vertical-flex-fill" @submit.prevent="handleSubmit(save)" novalidate>
        <b-card-group class="primary-section h-100">
          <cb-primary-message class="mb-0" />
          <b-card>
            <b-card-primary-section-header slot="header" :title="$t('candidate.personalInformationHeading')" />
            <candidate-personal-info-inputs
              :firstName.sync="model.personalInfo.firstName"
              :middleName.sync="model.personalInfo.middleName"
              :lastName.sync="model.personalInfo.lastName"
              :email.sync="model.personalInfo.email"
              :phone.sync="model.personalInfo.phone"
              :ssoIdentifier.sync="model.personalInfo.ssoIdentifier"
              :sourceSystemId.sync="model.personalInfo.sourceSystemId"
              :isTest.sync="model.personalInfo.isTest"
              :showSourceSystemId="showSourceSystemId"
              :lockIsTest=false
              >
            </candidate-personal-info-inputs>
          </b-card>
          <b-card>
            <b-card-primary-section-header slot="header" :title="$t('candidate.processFlowHeading')" />
            <candidate-process-inputs 
              :locationId.sync="model.process.locationId" 
              :processId.sync="model.process.processId"
              :candidateDueDate.sync="model.process.candidateDueDate"
              :dueDate.sync="model.process.dueDate"
              :recruiterUserId.sync="model.process.recruiterUserId"
              :hiringManagerUserId.sync="model.process.hiringManagerUserId"
              :coordinatorUserId.sync="model.process.coordinatorUserId" />
          </b-card>
          <b-card no-body>
            <b-button-group>
              <b-button type="button" variant="primary" class="flex-fill" @click="backToCandidatesList">{{$t("common.back")}}</b-button>
              <b-button type="submit" variant="primary" class="flex-fill">{{$t("common.create")}}</b-button>
            </b-button-group>
          </b-card>
        </b-card-group>
      </b-form>
    </validation-observer>
  </div>
</template>
<script>
import AppSettings from 'appSettings';
import CbViewHeader from 'general/cb-view-header.vue';
import CbPrimaryMessage from "components/cb-primary-message.vue";
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import { BCardPrimarySectionHeader } from '@clickboarding/vue-components';
import CandidatePersonalInfoInputs from 'candidates/candidate-personal-info-inputs.vue';
import CandidateProcessInputs from 'candidates/candidate-process-inputs.vue';
import CandidatesManagementService from "api/candidatesManagementService";
import { mapGetters } from 'vuex';

export default {
  name: 'candidate-add',
  components: {
    CbViewHeader,
    BCardPrimarySectionHeader,
    CandidatePersonalInfoInputs,
    CandidateProcessInputs,
    CbPrimaryMessage
  },
  props: {
    showSourceSystemId: {
      type: Boolean,
      required: false
    }
  },
  created () {
    this.showSourceSystemId = AppSettings.sourceSystemIdFeatureToggle === "true";
  },
  computed: {
    ...mapGetters(['clientId']),
    returnLink () {
      return {
        label: this.$t("candidate.returnToCandidatesList"),
        handler: this.backToCandidatesList
      };
    }
  },
  methods: {
    backToCandidatesList () {
      this.$_cb.router.changeView(AppSettings.viewCandidates, null);
    },
    async save () {
      PrimaryMessageBus.$emit('clear-all-messages');

      const flows = [];
      const locationId = this.model.process.locationId;
      const processFlowId = this.model.process.processId;
      
      if (locationId && processFlowId) {
        flows.push({
          locationId,
          processFlowId,
          dueDate: this.model.process.dueDate,
          candidateDueDate: this.model.process.candidateDueDate,
          recruiterId: this.model.process.recruiterUserId,
          hiringManagerId: this.model.process.hiringManagerUserId,
          coordinatorId: this.model.process.coordinatorUserId,
        });
      }

      try {
        await CandidatesManagementService.postCandidate(this.clientId, {
          firstName: this.model.personalInfo.firstName,
          middleName: this.model.personalInfo.middleName,
          lastName: this.model.personalInfo.lastName,
          phone: this.model.personalInfo.phone,
          email: this.model.personalInfo.email,
          ssoIdentifier: this.model.personalInfo.ssoIdentifier,
          sourceSystemId: this.model.personalInfo.sourceSystemId,
          isTest: this.model.personalInfo.isTest,
          processFlows: flows
        });

        // If the post was successful, change view to viewCandidates
        this.$_cb.router.changeView(AppSettings.viewCandidates, null);
      } catch (error) { /* Nothing to do; but this catch prevents a Vue console warning */ }
    }
  },
  data () {
    return {
      model: {
        personalInfo: {
          firstName: null,
          middleName: null,
          lastName: null,
          email: null,
          phone: null,
          ssoIdentifier: null,
          sourceSystemId: null,
          isTest: null
        },
        process: {
          locationId: null,
          processId: null,
          candidateDueDate: null,
          dueDate: null,
          recruiterUserId: null,
          hiringManagerUserId: null,
          coordinatorUserId: null
        },
      }
    };
  }
}
</script>