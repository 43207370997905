import ViewController from 'view.controller';

export default class TasksController extends ViewController {
    constructor($http, $scope, $route, $timeout, locationSvc, $window, appSettings, aaNotify) {
        super($http, $scope, $route, $timeout, locationSvc, $window, appSettings, aaNotify);
        aaNotify.clearAll();
    }
}

TasksController.$inject = ['$http', '$scope', '$route', '$timeout', 'locationSvc', '$window', 'appSettings', 'aaNotify'];
