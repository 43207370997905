import FormsController from "forms/forms.controller";
import FormsSplashController from "forms/forms-splash.controller";
import FormsAddController from "forms/forms-add.controller";
import FormsEditController from "forms/forms-edit.controller";
import FormsViewController from "forms/forms-view.controller";
import FormsRouteComponent from "forms/forms-route.vue";
import FormsAddRouteComponent from "forms/forms-add-route.vue";
import FormsEditRouteComponent from "forms/forms-edit-route.vue";
import FormsSplashRouteComponent from "forms/forms-splash-route.vue";

export default angular
  .module("forms-module", [])
  .controller("formsController", FormsController)
  .controller("formsSplashController", FormsSplashController)
  .controller("formsAddController", FormsAddController)
  .controller("formsEditController", FormsEditController)
  .controller("formsViewController", FormsViewController)
  .value("forms-route", FormsRouteComponent)
  .value("forms-splash-route", FormsSplashRouteComponent)
  .value("forms-add-route", FormsAddRouteComponent)
  .value("forms-edit-route", FormsEditRouteComponent)
  .run(['$rootScope', 'appSettings', async function($rootScope, appSettings) {
    // Clear filters when goes away from the Forms & Content
    $rootScope.$on('$routeChangeStart', function($event, next, current){
      if(!next.$$route.originalPath.startsWith(appSettings.viewForms)) {
        sessionStorage.formsTypeFilter = '';
        sessionStorage.formsStatusFilter = '';
      }
    });
  }])
  .name;
