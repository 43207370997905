<template>
  <div class="connection-description-section">
    <div class="connection-description-container">
      <div class="connection-description-content">{{displayConnectionDescription}}</div>
    </div>
    <div class="connections-actions">
      <button
        v-if="displayLogsButton"
        class="cb-btn primary more-information"
        @click="$emit('logs-opened')"
      >{{$t("connections.viewLogLabel")}}</button>
      <button
        v-if="connection.isComponentConfigured && connection.userHasEditPermission"
        class="cb-btn primary more-information"
        @click="$emit('connection-opened')"
      >{{displaySettingsButtonText}}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "connection-description",
  props: {
    connection: {
      type: Object,
      required: true
    }
  },
  computed: {
    displayConnectionDescription() {
      let descriptionText = this.$t('connections.connectionDefaultDescriptionText');
      if (this.connection && this.connection.description) {
        descriptionText = this.connection.description;
      } 
      return descriptionText; 
    },
    displaySettingsButtonText() {
      return this.connection.isConfigured ? this.$t('connections.viewSettingLabel') : this.$t('connections.enableLabel');
    },
    displayLogsButton() {
      return this.connection.isConfigured && this.connection.activityLog;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";

.connection-description-section {
  background-color: $cb-white;
  position: relative;
  border: solid 1px $cb-light-grey-1;
}

.connection-description-container {
  @include font-size-content-root;
  padding: $standard-cb-view-padding;
}

.connections-actions {
  display: flex;
}

.more-information {
  width: 100%;
}
</style>