<template>
  <div class="savage-form-pdf-viewer">
    <cb-pdf-viewer :url="url" :labels="pdfViewerLabels" />
  </div>
</template>
<script>
import CbPdfViewerDataProvider from "common/components/pdf/cb-pdf-viewer-data-provider";

export default {
  name: "savage-form-pdf-viewer",
  components: {
    CbPdfViewer: () => import('@clickboarding/pdf-viewer')
  },
  props: {
    url: {
      type: String,
      required: true
    }
  },
  created () {
    CbPdfViewerDataProvider.getData().then(data => {
      this.pdfViewerLabels = data;
    });
  },
  data () {
    return {
      pdfViewerLabels: null
    }
  }
};
</script>
<style scoped lang="scss">
  .savage-form-pdf-viewer {
    height: 32rem;
  }
</style>