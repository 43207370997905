export default function ProcessesService ($http, $q) {

  var service = {
    processFlows: null,
    activeProcessFlow: null,
    getProcess: function(url) {
      var deferred = $q.defer();

      $http.get(url).then(function (response) {
        deferred.resolve(response.data);
        service.activeProcessFlow = response.data;
      }, function(err) {
        deferred.reject(err);
      });

      return deferred.promise;
    }
  };

  return service;
}

ProcessesService.$inject = ['$http', '$q'];
