<template>
  <section class="savage-form-content-element">
    <div v-html="content" />
  </section>
</template>
<script>

export default {
  name: "savage-form-html",
  props: {
    content: {
      type: String,
      required: true
    },
  },
};
</script>
<style scoped lang="scss">
  .savage-form-content-element /deep/ {
    @import 'common/css/html-editor-content';
  }
</style>
