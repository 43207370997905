var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadedData)?_c('section',[_c('cb-admin-view-title',{staticClass:"brands-header cb-admin-view-title",attrs:{"title":_vm.title}},[(_vm.loadedData.add.url)?_c('b-button',{staticClass:"back create-brand-button",attrs:{"variant":"green"},on:{"click":_vm.navigateToAdd}},[_vm._v("\n        "+_vm._s(_vm.loadedData.add.label)+"\n      ")]):_vm._e()],1),_c('cb-view-section',{staticClass:"pt-0",attrs:{"type":"secondary","includePrimaryMessages":true}},[(_vm.hasMultipleBrands)?_c('b-table',{staticClass:"brand-list-table new-table",attrs:{"sort-by":"name","sort-icon-left":"","hover":"","small":"","outlined":"","items":_vm.brands,"fields":[
            { key: 'logo', label: _vm.columnHeaders.logo, thStyle: { minWidth: '20%' } },
            { key: 'name', label: _vm.columnHeaders.name, class: 'hide-sm-down', sortable: true, sortDirection: 'desc' },
            { key: 'isCompanyDefault', label: '', class: 'primary-indicator', thStyle: { minWidth: '15%' }, thAttr: { 'aria-label': _vm.$t('brands.primaryColumnHeaderScreenReader') } },
            { key: 'actions', label: '', class: 'action-menu-column', thAttr: { 'aria-label': _vm.$t('brands.actionsColumnHeaderScreenReader') } }
        ]},scopedSlots:_vm._u([{key:"cell(logo)",fn:function(ref){
        var item = ref.item;
return [_c('img',{attrs:{"height":"40","alt":item.logoAlt,"src":item.logoUrl}})]}},{key:"cell(isCompanyDefault)",fn:function(ref){
        var item = ref.item;
return [(item.isCompanyDefault)?_c('div',[_c('svg',[_c('use',{attrs:{"xlink:href":_vm.MarkPrimaryIconPath}})]),_c('span',[_vm._v(_vm._s(_vm.$t("brands.primaryLabel")))])]):_vm._e()]}},{key:"cell(actions)",fn:function(ref){
        var item = ref.item;
return [_c('action-menu',{attrs:{"toggle-orientation":'landscape',"menu-position":'bottom-left',"items":item.actions}})]}}],null,false,1565399610)}):_c('single-brand-component',{attrs:{"brand":_vm.brands[0],"edit-label":_vm.loadedData.editSingleBrandLabel}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }