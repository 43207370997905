<template>
    <a class="candidate-name-cell" v-if="hasCandidateId" @click="showCandidateDetails()" style="color: #2F72AC !important;">
        {{ candidateName }}
    </a>
    <p v-else-if="!hasCandidateId">{{ candidateName }}</p>
</template>
    
    
    
<script>
export default {
    name: "CandidateNameHyperlink",
    mounted() { },
    methods: {
        showCandidateDetails() {
            if (this.params.data.candidateId) {
                this.params.clicked(this.params.data.candidateId);
            }
        }
    },
    computed: {
        candidateName() {
            return this.params.data.candidateName;
        },
        hasCandidateId() {
            if (this.params.data.candidateId) {
                return true;
            }
            return false;
        },
    }
};
</script>