<template>
  <forms-content-build-element-type-selector
    v-if="state && state.showElementSelector"
    :data-url="dataUrl"
    :return-link="returnLink"
    :on-next="selectElementType" />
  <component v-else-if="state && state.showElementDesigner && state.elementDesignerData"
    :is="state.elementDesignerData.component"
    :data-url="state.elementDesignerData.dataUrl"
    :return-link="returnLink"
    :on-back="reset"
    :wrapped-element="state.elementDesignerData.wrappedElement"
    :on-complete="extractElementAndComplete" />
</template>

<script>
import FormsContentBuildElementTypeSelector from 'forms/forms-content-build-element-type-selector.vue';
import FormsContentService from 'forms/forms-content-service';

function initialData () {
  return {
    showElementSelector: true,
    showElementDesigner: false,
    elementDesignerData: null
  };
}

export default {
  name: 'forms-content-build',
  components: {
    FormsContentBuildElementTypeSelector
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    },
    returnLink: {
      type: Object,
      required: true
    },
    onComplete: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      state: initialData()
    };
  },
  methods: {
    extractElementAndComplete (wrappedElement) {
      this.onComplete(wrappedElement.element);
    },
    reset () {
      this.state = initialData();
    },
    selectElementType (elementType) {
      //find designer component for selected element type
      const component = FormsContentService.getElementTypeDesignerComponent(elementType.type);

      //if not found, stay on the selector component (this would only happen if the api returned a new type not yet implemented in the front end)
      if (component !== null) {
        //hide data category selector (step 1)
        this.state.showElementSelector = false;

        //setup the data needed for the element designer component
        this.state.elementDesignerData = {
          component,
          dataUrl: elementType.url,
          wrappedElement: {
            index: null,
            element: elementType.element
          }
        };

        //show the element designer (step 2)
        this.state.showElementDesigner = true;
      }
    }
  }
}
</script>
