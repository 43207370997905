import store from 'store';

export default class CompaniesController {
    constructor(locationSvc, HeaderService) {
        this.HeaderService = HeaderService;
        this.changeCompany = this.changeCompany.bind(this);

        this.dataUrl = locationSvc.fetchQuery();
    }

    async changeCompany(companyHeaderUrl) {
        // Changing the header effectively changes the context of the site, causing it to reload everything.
        // Eventually it will load the right page for the user as if they had access to only one company.
        const response = await this.HeaderService.reloadHeaderUrl(companyHeaderUrl);
        await store.dispatch('setClient', {
            id: response.clientSelection.client.clientApiIdentifier
        });
    }
}

CompaniesController.$inject = ['locationSvc', 'HeaderService'];
