<template>
  <div class="cb-expandable-rows">
    <div class="cb-expandable-row-container">
      <div class="cb-expandable-row-summary">
          <slot name="summary" />
          <div class="cb-expandable-row-details-toggle">
            <button class="cb-expandable-row-details-toggle-button" v-if="details" :title="toggle.label" @click="toggleExpanded()">
              <svg>
                <use v-bind="{'xlink:href': toggle.icon }" ></use>
              </svg>
            </button>
          </div>
      </div>
    </div>
    <div class="cb-expandable-row-container cb-expandable-row-details" v-if="isExpanded">
      <slot name="details"></slot>
    </div>
  </div>
</template>
<script>
import MinusSvg from '@clickboarding/assets/icons/minus-icon.svg';
import PlusSvg from '@clickboarding/assets/icons/plus-icon.svg';

export default {
  name: "expandable-row-item",
  props: {
    details: {
        type: Object,
        required: false
    }
  },
  created() {
    this.icons = {
      plus: "#" + PlusSvg.id,
      minus: "#" + MinusSvg.id
    };
  },
  data() {
    return {
      isExpanded: false
    };
  },
  computed: {
      toggle() {
        if(this.isExpanded) {
            return {
                icon: this.icons.minus,
                label: this.$i18n.t("general.rows.collapseLabel")
            };
        }
        else {
            return {
                icon: this.icons.plus,
                label: this.$i18n.t("general.rows.expandLabel")
            };
        }
      }
  },
  methods: {
    toggleExpanded() {
      this.isExpanded = !this.isExpanded;
      this.$emit('is-expanded-toggled', this.isExpanded);
    }
  }
};
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.cb-expandable-rows {

  .cb-expandable-row-container {
    border-top: solid 1px $cb-light-grey-1;
  }

  .cb-expandable-row-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-left: 4px solid $cb-white;

    &:hover {
      background-color: $cb-light-blue-6;
      border-left: 4px solid $cb-brand-blue-1;
    }

    .cb-expandable-row-details-toggle {
      padding: 0.75rem 1rem;
      flex: none;

      .cb-expandable-row-details-toggle-button {
        border: none;
        background-color: transparent;

        svg {
          height: .75rem;
          width: .75rem;
          color: $cb-medium-blue-1;
        }

        &:hover, &:focus {
          svg {
            color: $cb-brand-blue-1;
          }
        }
      }
    }
  }

  .cb-expandable-row-details {
    background-color: $cb-light-grey-2;
    padding: 1.5rem;
  }
}
</style>
