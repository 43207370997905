import importTypes from "./importTypes";
import { AxiosLocal as axios } from "common/services/axiosLocal.service";
import appSettings from "appSettings";
import CandidateSpecs from "./imports-candidateSpecs";
import TeamMemberSpecs from "./imports-teamMemberSpecs";
import ProcessFlowSpecs from "./imports-processFlowSpecs";
import moment from "moment";
import dateFns from "date-fns";
import ImportStatus from "./importStatus";
const pageSize = 50;

export default {
  async loadImports(clientId, offset, limit) {
    let data = [];
    const url = `${appSettings.apiBaseUrl}Clients/${encodeURIComponent(
      clientId
    )}/Sections/Imports?offset=${offset}&limit=${limit}`;
    const response = await axios.get(url);
    const importData = response.data.imports;

    if (importData) {
      const finalRowData = [];
      importData.forEach((element) => {
        finalRowData.push({
          fileName: element.fileName,
          type: element.importTypeId,
          records:
            element.statusId == ImportStatus.VALIDATION_READY ||
            element.statusId == ImportStatus.VALIDATION_STARTED
              ? "Pending"
              : element.totalRecords,
          validationErrors:
            element.statusId == ImportStatus.VALIDATION_READY ||
            element.statusId == ImportStatus.VALIDATION_STARTED
              ? ""
              : element.failedValidationRecords,
          processingErrors:
            element.statusId == ImportStatus.VALIDATION_READY ||
            element.statusId == ImportStatus.VALIDATION_STARTED
              ? ""
              : element.failedImportRecords,
          statusName: element.statusName,
          userName: element.userName,
          importId: element.importId,
          uploadDate: element.createdOn.includes("0001-01-01T00:00:00+00:00")
            ? " "
            : moment(String(element.createdOn)).format("MM/DD/YYYY  h:mm A"),
          action: element.statusId,
          validationResults: element.statusId,
          importReport: element.statusId,
          typeName: getImportDisplayName(element.importTypeId),
          statusId: element.statusId,
          validationBlobId : element.validationBlobId,
        });
      });

      data = finalRowData;
    }
    return data;
  },
  async downloadTemplate(importType) {
    const importName = getImportName(importType);
    const response = await axios.get(
      `${appSettings.apiBaseUrl}Downloads/${importName}ImportTemplate`
    );
    const type = response.headers["content-type"];
    const blob = new Blob([response.data], {
      type: type,
      encoding: "UTF-8",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `${importName}_ImportTemplate.csv`;
    link.click();
  },
  getImportSpecs(importType, clientId) {
    switch (importType) {
      case importTypes.EMPLOYEE:
        return this.getCandidateSpecs();
      case importTypes.TEAM_MEMBER:
        return this.getTeamMemberSpecs();
      case importTypes.PROCESS_FLOW:
        return this.getProcessFlowSpecs(clientId);
      default:
        return [];
    }
  },
  shouldShowSourceSystemId() {
    if (appSettings.hasOwnProperty("sourceSystemIdFeatureToggle")) {
      return JSON.parse(appSettings.sourceSystemIdFeatureToggle);
    }
    return false;
  },
  getCandidateSpecs() {
    const specs = new CandidateSpecs();
    if (!this.shouldShowSourceSystemId()) {
      specs.specs = specs.specs.filter(
        (s) => s.field !== "Source System Identifier"
      );
    }
    return specs.specs;
  },
  getTeamMemberSpecs() {
    const specs = new TeamMemberSpecs();
    if (!this.shouldShowSourceSystemId()) {
      specs.specs = specs.specs.filter(
        (s) => s.field !== "Source System Identifier"
      );
    }
    return specs.specs;
  },
  async getProcessFlowSpecs(clientId) {
    const locationData = await this.getLocationData(clientId);
    let specs = new ProcessFlowSpecs().specs;
    specs.find((s) => s.key === "location").validValues = locationData;
    return specs;
  },
  getImportSpecOptions() {
    return [
      { value: importTypes.EMPLOYEE, text: "Candidate" },
      { value: importTypes.TEAM_MEMBER, text: "Team Member" },
    ];
  },
  getImportUpdateSpecOptions() {
    return [{ value: importTypes.PROCESS_FLOW, text: "Add Process Flow" }];
  },
  getSpecHeaders() {
    return [
      {
        key: "field",
        label: "Field",
        thStyle: { width: "50px" },
        sortable: false,
      },
      {
        key: "dataType",
        label: "Data Type",
        thStyle: { width: "50px" },
        sortable: false,
      },
      {
        key: "required",
        label: "Required",
        thStyle: { width: "50px" },
        sortable: false,
      },
      {
        key: "description",
        label: "Description",
        thStyle: { width: "50px" },
        sortable: false,
      },
      {
        key: "validValues",
        label: "Valid Values",
        thStyle: { width: "50px" },
        sortable: false,
      },
    ];
  },
  getUpdateSpecHeaders() {
    return [
      {
        key: "field",
        label: "Field",
        thStyle: { width: "50px", cursor: "pointer" },
        sortable: false,
      },
      {
        key: "dataType",
        label: "Data Type",
        thStyle: { width: "50px", cursor: "pointer" },
        sortable: false,
      },
      {
        key: "required",
        label: "Required",
        thStyle: { width: "50px", cursor: "pointer" },
        sortable: false,
      },
      {
        key: "description",
        label: "Description",
        thStyle: { width: "50px", cursor: "pointer" },
        sortable: false,
      },
      {
        key: "validValues",
        label: "Valid Values",
        thStyle: { width: "50px", cursor: "pointer" },
        sortable: false,
        tdClass: "validValues",
      },
    ];
  },
  getImportTypeByName(name) {
    switch (name) {
      case "candidate":
        return importTypes.EMPLOYEE;
      case "teammember":
        return importTypes.TEAM_MEMBER;
      case "addprocessflow":
        return importTypes.PROCESS_FLOW;
      default:
        return "";
    }
  },
  getPageSize() {
    return pageSize;
  },
  async putImport(url, body) {
    const response = await axios.put(url, body);
    return true;
  },
  async getProcessFlowAndTeamMemberData(clientId, locationApiIdentifier) {
    const result = await axios.get(
      `${
        appSettings.apiBaseUrl
      }Clients/${clientId}/Sections/Imports/Locations/${locationApiIdentifier}`
    );
    return result.data;
  },
  async getLocationData(clientId) {
    const result = await axios.get(
      `${
        appSettings.apiBaseUrl
      }Clients/${clientId}/Sections/Imports/ProcessFlows`
    );
    return result.data.locations;
  },
  async loadCandidateData(clientId) {
    const result = await axios.get(
      `${appSettings.apiBaseUrl}Clients/${clientId}/Sections/Imports/Candidates`
    );
    let candidates = result.data.candidates;
    candidates.forEach((c) => {
      c.candidateDueDate = c.candidateDueDate.includes(
        "0001-01-01T00:00:00+00:00"
      )
        ? null
        : formatDate(c.candidateDueDate);
      c.dueDate = c.dueDate.includes("0001-01-01T00:00:00+00:00")
        ? null
        : formatDate(c.dueDate);
      c.candidateCreationDate = c.candidateCreationDate.includes(
        "0001-01-01T00:00:00+00:00"
      )
        ? null
        : formatDate(c.candidateCreationDate);
    });
    return candidates;
  },
  async checkInProgressImportAndUpdate(importId, clientId, importType) {
    const result = await axios.patch(`${appSettings.apiBaseUrl}Clients/${clientId}/Sections/Imports/${importId}/${importType}`);
  }
};

function getImportDisplayName(importType) {
  switch (importType) {
    case importTypes.EMPLOYEE:
      return "Candidate";
    case importTypes.PROCESS_FLOW:
      return "Process Flow";
    case importTypes.TEAM_MEMBER:
      return "Team Member";
    default:
      return "";
  }
}

function getImportName(importType) {
  switch (importType) {
    case importTypes.EMPLOYEE:
      return "Candidate";
    case importTypes.PROCESS_FLOW:
      return "ProcessFlow";
    case importTypes.TEAM_MEMBER:
      return "TeamMember";
    default:
      return "";
  }
}

window.formatDate = function formatDate(dateString) {
  if (dateString) return dateFns.format(dateString, "MM/DD/YYYY");
  else return null;
};
