<template>
  <section v-if="process" class="h-100">
    <section v-if="!showActivityAdd" class="vertical-flex process-route">
      <cb-view-header
          class="vertical-flex-min"
          :return-link="returnLink"
          :action-menu-items="processActionMenuItems"
          :title="process.detail.name">
        <section v-if="hasProcessLevelEvents" slot="sub-title" class="action-item-icons hide-xs-down">
          <cb-event-icon-display :events="processLevelEvents"/>
        </section>
      </cb-view-header>
      <cb-view-section type="secondary" class="vertical-flex-min container-fluid">
        <div class="row justify-content-end">
          <div class="col-12 page-actions-step-templates">
            <cb-button-dropdown :action="handleAddStepTemplate"
                                :label="process.stepLabels.addEdit.addTitle"
                                :list="process.stepTemplates">
            </cb-button-dropdown>
          </div>
        </div>
      </cb-view-section>
      <cb-view-section type="primary" class="vertical-flex-fill">
        <step-tile-list
            :steps="process.steps"
            :step-labels="process.stepLabels"
            :on-step-edit-open="editStep"
            :on-step-delete-send="onStepDeleteSend"
            @on-activity-add="onActivityAdd"
            :on-activity-delete-send="onActivityDeleteSend"
            :on-activity-edit="onActivityEdit"
            :on-step-reorder="onStepReorder"
            :on-form-reorder="onFormReorder"/>
      </cb-view-section>
    </section>
    <activity-add
        v-else
        :dataUrl="currentStep.addActivity.url"
        :stepName="currentStep.name"
        :returnLink="addFormReturnLink"
        :existingActivities="currentStep.activityCollection.activities"
        :stepIsSealed="currentStep.activityCollection.sealed"
        :stepIsCandidate="currentStep.isCandidate"
        @activity-add-cancel="onActivityAddClose"
        @activity-add-added="onActivityAddClose"
    />
    <step-add :labels="process.stepLabels.addEdit"
              :edit-item="editStepItem"
              :new-step="addNewStep"
              :add-url="process.steps.addUrl"
              @ok="handleOk"
              @close="hideStepModal"
              @cancel="hideStepModal"
              @hide="hideStepModal"
              v-if="modalStepShow"></step-add>
    <process-add :data="process.addEdit"
                 :edit-item="process.detail"
                 :is-copy="isCopy"
                 @ok="handleOk"
                 @close="hideProcessModal"
                 @cancel="hideProcessModal"
                 @hide="hideProcessModal"
                 v-if="modalProcessShow">>
    </process-add>
  </section>
</template>

<script>
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import CbButtonDropdown from 'general/button-dropdown.vue';
import StepTileList from 'processes/builder/step-tile-list.vue';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import CbEventIconDisplay from 'general/cb-event-icon-display.vue';
import ActivityAdd from 'processes/steps/activities/activity-add.vue';
import {mapActions} from 'vuex';
import StepAdd from "../steps/step-add";
import ProcessAdd from '../process-add';

export default {
  name: 'process-builder',
  components: {
    StepAdd,
    CbEventIconDisplay,
    CbViewHeader,
    CbViewSection,
    CbButtonDropdown,
    StepTileList,
    ActivityAdd,
    ProcessAdd
  },
  props: {
    process: Object,
    backToProcessesLabel: String,
    onBackToProcesses: Function,
    onProcessDeleteSend: Function,
    onStepEditOpen: Function,
    onStepDeleteSend: Function,
    onActivityDeleteSend: Function,
    onActivityEdit: Function,
    onStepReorder: Function,
    onFormReorder: Function,
    onActivityAdded: Function
  },
  mounted() {
    this.resetActivities();
  },
  computed: {
    processActionMenuItems() {
      return this.process
          ? [
            {label: this.process.editLabel, icon: 'Action-Edit-Icon', action: this.editProcess},
            {label: this.process.copyLabel, icon: 'Action-Copy-Icon', action: this.copyProcess},
            {label: this.process.deleteLabel, icon: 'Action-Delete-Icon', action: this.onProcessDeleteSend}
          ]
          : [];
    },
    returnLink() {
      return {
        label: this.backToProcessesLabel,
        route: null,
        dataUrl: null,
        handler: this.onBackToProcesses
      }
    },
    processLevelEvents() {
      return this.process.detail && this.process.detail.events && this.process.detail.events.length > 0 ? this.process.detail.events : [];
    },
    hasProcessLevelEvents() {
      return this.processLevelEvents.length > 0;
    }
  },
  watch: {
    process: {
      deep: true,
      immediate: true,
      handler(newval) {
        this.setESignatureWarningMode(newval);
      }
    }
  },
  methods: {
    ...mapActions('activities', ['resetActivities']),
    getESigWarningMode(data) {
      var mode = null;
      if (data && data.steps) {
        mode = data.steps.eSignatureWarningMode;
      }
      return mode;
    },
    setESignatureWarningMode(newval) {
      const mode = this.getESigWarningMode(newval);
      if (mode === 'Missing') {
        // nextTick necessary for initial page load, where the PrimaryMessageBus was emitting too early
        this.$nextTick(() => PrimaryMessageBus.$emit('send-warning-message', this.$t("processBuilder.eSigMissingWarning")));
      } else if (mode === 'Late') {
        // nextTick necessary for initial page load, where the PrimaryMessageBus was emitting too early
        this.$nextTick(() => PrimaryMessageBus.$emit('send-warning-message', this.$t("processBuilder.eSigLateWarning")));
      } else {
        PrimaryMessageBus.$emit('clear-warning-message');
      }
    },
    onActivityAdd(step) {
      this.saveScrollPosition();
      this.addFormReturnLink.label = this.$t('processBuilder.activities.add.backToProcessLinkTitle', [this.process.detail.name])
      this.currentStep = step;
      this.showActivityAdd = !this.showActivityAdd;
    },
    onActivityAddClose(addedItemData) {
      if (addedItemData) {
        this.onActivityAdded(addedItemData);
      }
      this.showActivityAdd = !this.showActivityAdd;
      this.setESignatureWarningMode(this.process);
      this.resetScrollPosition();
      this.resetActivities();
    },
    saveScrollPosition() {
      this.lastScrollPosition = {
        x: window.scrollX,
        y: window.scrollY
      };
    },
    resetScrollPosition() {
      this.$nextTick(() => {
        window.scrollTo(this.lastScrollPosition.x, this.lastScrollPosition.y);
      });
    },
    handleOk(data) {
      this.addNewStep = false
      this.modalStepShow = false
      this.process.steps = data.steps
      this.process.stepTemplates = data.stepTemplates
      this.process.detail = data.detail
    },
    editStep(item) {
      this.modalStepShow = true
      this.editStepItem = item
    },
    editProcess () {
      this.modalProcessShow = true
    },
    hideProcessModal () {
      this.modalProcessShow = false
      this.isCopy = false
    },
    hideStepModal () {
      this.modalStepShow = false
      this.addNewStep = false
    },
    copyProcess () {
      this.modalProcessShow = true
      this.isCopy = true
    },
    async handleAddStepTemplate(item) {
      const {data} = await this.$http.get(item.url)
      this.addNewStep = true
      this.editStepItem = data
      this.modalStepShow = true
    }
  },
  data() {
    return {
      modalStepShow: false,
      modalProcessShow: false,
      editStepItem: null,
      editProcessItem: null,
      showActivityAdd: false,
      addFormReturnLink: {
        label: null,
        route: null,
        dataUrl: null,
        handler: this.onActivityAddClose
      },
      currentStep: null,
      addNewStep: false,
      isCopy: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.page-actions-step-templates > :first-child {
  margin-left: auto;
  max-width: 16rem;
}

.process-route .cb-view-section.primary {
  padding: .75rem; /* In this scenario we are overriding the default padding from cb-view-section because the tile grid has .75rem around its perimiter already */
}

.process-route {
  @include font-size-content-root;

  /deep/ .cb-primary-message {
    padding: .75rem; /* This adds back the difference of the reduced .75rem padding applied above, birging it back to 1.5rem total */
    margin-bottom: 0; /* Normally the messages space themselves from the content below them, but in this case the combined padding on the tile grid that which we applied to the messages takes care of this */
  }
}

.cb-view-header .action-item-icons {
  padding-top: 0.5rem;
  text-align: left;
}
</style>
