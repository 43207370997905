import * as DataEntryActionsProvider from "forms/element-list-item-providers/actions/dataEntry";
import * as DataEntryIconProvider from "forms/element-list-item-providers/icons/dataEntry";

function canHandle (element) {
  return element.type === "GroupArray";
}

function map (element, index, editActionHandler, deleteActionHandler) {
  return {
    label: element.title || element.itemTitle,
    actions: DataEntryActionsProvider.get(element, index, editActionHandler, deleteActionHandler),
    icon: DataEntryIconProvider.get(element)
  };
}

export { canHandle, map }