import { getByPresentation } from 'forms/data-entry-mappers/element-types/all';

function map (element, updateModelFn) {
  const availableDesigners = Object.keys(element.availablePresentations).reduce((designers, presentationKey) => {
    let mapPresentationToDesigner = null;

    const elementType = getByPresentation(presentationKey);
    mapPresentationToDesigner = elementType ? elementType.mapPresentationToDesigner : null;

    if (mapPresentationToDesigner) {
      if (designers === null) designers = [];

      const designer = mapPresentationToDesigner(
        element, 
        presentationKey,
        updateModelFn
      );

      designers.push(designer);
    }

    return designers;
  }, null);

  if (!availableDesigners) throw new Error ("No supported designers found for this element.");

  availableDesigners.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });

  return availableDesigners;
}

export { map }