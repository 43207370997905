import EventBus from 'common/event-bus'

export const AuthServiceEvents = Object.freeze({
  HeaderChanged: 'auth-header-changed',
  AuthenticatedUserChanged: 'authenticated-user-changed',
  SessionCleared: 'session-cleared',
});

export const authServiceEventBus = new EventBus(AuthServiceEvents);

export default function AuthService($timeout, $rootScope, locationSvc, appSettings) {
  var authHeaderKey = "cb" + appSettings.appType + "authHdr";
  var authUserKey = "cb" + appSettings.appType + "authUsr";
  var memoryStore = {};
  var firstTime = true; // Header is "loaded" on bootstrapping

  return {
    getAuthHeader: function () {
      return this._getSessionValue(authHeaderKey);
    },
    setAuthHeader: function (val) {
      this._setSessionValue(authHeaderKey, val);
      authServiceEventBus.emit(AuthServiceEvents.HeaderChanged);

      // When the app is first bootstrapped, the header is set
      // but the user may just be hanging out in session storage,
      // so we want to "emit" that it changed the first time.
      if (firstTime) {
        this.setAuthenticatedUser(this.getAuthenticatedUser());
        firstTime = false;
      }
    },
    getAuthenticatedUser: function () {
      let user = this._getSessionValue(authUserKey);
      return user ? JSON.parse(user) : null;
    },
    setAuthenticatedUser: function (val) {
      this._setSessionValue(authUserKey, val ? JSON.stringify(val) : null);
      authServiceEventBus.emit(AuthServiceEvents.AuthenticatedUserChanged, { user: val });
    },
    clearAuthenticatedUser: function () {
      this.setAuthenticatedUser(null);
    },
    getAuthenticatedUserId: function () {
      var user = this.getAuthenticatedUser();
      return (user) ? user.id : null;
    },
    setAuthenticatedUserId: function (authUserId) {
      this.setAuthenticatedUser({ id: authUserId });
    },
    clearSession: function () {
      memoryStore = {};
      if (Modernizr.sessionstorage) {
        var i = sessionStorage.length;
        while (i--) {
          var key = sessionStorage.key(i);
          if (key.indexOf(appSettings.appType) !== -1)
            sessionStorage.removeItem(key);
        }
      }
      authServiceEventBus.emit(AuthServiceEvents.SessionCleared);
    },
    logout: function () {
      this.clearSession();
      this.setAuthenticatedUser(null); // explicitly clear the authenticated user (so an event fires)

      locationSvc.changeView(appSettings.viewLogin);

      // this timeout is in place to avoid a digest colision in NG
      $timeout(function () {
        $rootScope.$apply();
      });
    },

    _getSessionValue: function (key) {
      return Modernizr.sessionstorage ? sessionStorage.getItem(key) : memoryStore[key];
    },
    _setSessionValue: function (key, value) {
      if (value !== null && typeof value !== 'undefined') {
        if (Modernizr.sessionstorage) {
          sessionStorage.setItem(key, value);
        } else {
          memoryStore[key] = value;
        }
      } else {
        delete memoryStore[key];
        if (Modernizr.sessionStorage) {
          sessionStorage.removeItem(key);
        }
      }
    }
  }
};

AuthService.$inject = ['$timeout', '$rootScope', 'locationSvc', 'appSettings'];
