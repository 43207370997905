import { adminAppEventBus, AdminAppEvents } from 'admin-app-event-bus';
import analyticsService from 'common/services/analytics.service';
import { USER_SIGNED_OUT } from 'analytics/event-types';

adminAppEventBus.on(AdminAppEvents.UserSignedOut, (event) => {
  if(event) {
    analyticsService.track(USER_SIGNED_OUT, {});
    analyticsService.reset();
  }
});
