//text to html
var htmlFilter = function ($sce) {
	return function (text) {
		return $sce.trustAsHtml(text);
	};
};

htmlFilter.$inject = ['$sce'];

//filter repeater from array
var inArrayFilter = function ($filter) {
	return function (list, arrayFilter, element) {
		if (arrayFilter) {
			return $filter("filter")(list, function (listItem) {
				return arrayFilter.indexOf(listItem[element]) != -1;
			});
		}
	};
};

inArrayFilter.$inject = ['$filter'];

export default angular.module('filters-module', [ ])
	.filter('html', htmlFilter)
    .directive('inArray', inArrayFilter)
	.name;
