import BrandsController from "brands/brands.controller";
import BrandsAddController from "brands/brandsAddController";
import BrandsEditController from "brands/brandsEditController";
import BrandsRouteComponent from "brands/brands-route.vue";
import BrandsAddEditComponent from "brands/brands-add-edit-route.vue";

export default angular
    .module("brands-module", [])
    .controller("brands-controller", BrandsController)
    .controller("brandsAddController", BrandsAddController)
    .controller("brandsEditController", BrandsEditController)
    .value("brands-route", BrandsRouteComponent)
    .value("brands-add-edit", BrandsAddEditComponent)
    .name;
