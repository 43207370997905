<template>
  <section v-if="data" class="processes">
    <cb-admin-view-title :title="data.title" class="cb-admin-view-title">
      <b-button class="create-process-button"
                variant="green"
                @click="toggleProcessAddModal">
        {{ data.addLabel }}
      </b-button>
    </cb-admin-view-title>
    <cb-view-section type="secondary" class="pt-0">
      <div id="process-list-no-data" v-if="data.noData" class="text-c margin-lrg">
        <h2>{{ data.noData.title }}</h2>
        <div class="clipboard-pencil-icon"></div>
        <p class="no-data-message">{{ data.noData.message }}</p>
      </div>

      <div class="toolbar" id="process-list-add-process">
        <div class="filter-group">
          <div class="search-item mr-3">
            <b-form-input
                class="search-input small-input"
                v-model="searchCriteria"
                @update="resetCurrentPage"
                :debounce="500"
                :placeholder='$t("processes.filter.placeholder.search")'
            ></b-form-input>
          </div>
          <multi-select v-if="processFilterList.length > 1"
                        :options="processFilterList"
                        :value="processFilter"
                        :search-placeholder="$t('common.filterSearchPlaceholder', [$t('processes.columnHeaders.employeeExperience').toLowerCase()])"
                        :close-on-select="false"
                        :title="$t('processes.columnHeaders.employeeExperience')"
                        @select="selectProcessFilter($event)"
          >
            <template v-slot:option="{ option, isSelected }">
              <b-form-checkbox :checked="isSelected(option)" class="pointer-none">
              </b-form-checkbox>
              <span>{{ option }}</span>
            </template>
          </multi-select>
        </div>
      </div>
      <b-table class="process-list-container new-table"
               v-if="processFlowsCount"
               v-bind="tableSettings"
               :per-page="itemsPerOnePage"
               :current-page="currentPage"
               sort-by="name"
               :items="filteredProcessFlows" :fields="[
        { key: 'name', label: data.processHeader, thAttr: { 'aria-sort': 'descending' }, sortable: true, sortDirection: 'desc' },
        { key: 'tags', label: $t('processes.columnHeaders.employeeExperience'), formatter: tagFormatter, sortable: true },
        { key: 'icons', label: $t('processes.columnHeaders.event') },
        { key: 'view', label: '', class: 'link-column', thAttr: { 'aria-label': $t('processes.columnHeaders.actionsScreenReader') } }
      ]">
        <template v-slot:cell(icons)="{ item }">
          <cb-event-icon-display :events="itemEvents(item)"/>
        </template>
        <template v-slot:cell(view)="{ item }">
          <b-link href="#" @click="viewProcess(item)">{{ $t('common.view') }}</b-link>
        </template>
      </b-table>
      <div v-if="error">
        <p class="mt-3">{{ error }}</p>
      </div>
      <div v-if="showPagination" class="pagination-container">
        <b-form-group label="Per page" label-for="per-page-select" label-align-sm="right" label-size="sm"
                      class="per-page-options">
          <b-form-select
              id="per-page-select"
              v-model="itemsPerOnePage"
              :options="paginationSettings.pageOptions"
              size="sm">
          </b-form-select>
        </b-form-group>
        <b-pagination
            v-model="currentPage"
            :total-rows="processFlowsCount"
            :per-page="itemsPerOnePage"
            :current-page="currentPage"
            v-bind="paginationSettings">
        </b-pagination>
      </div>
    </cb-view-section>
    <process-add :data="data.addEdit"
                 @ok="handleOk"
                 @close="modalShow = false"
                 @cancel="modalShow = false"
                 @hide="modalShow = false"
                 v-if="modalShow">>
    </process-add>
  </section>
</template>

<script>
import CbAdminViewTitle from 'general/cb-admin-view-title.vue';
import CbViewSection from "general/cb-view-section.vue";
import CbEventIconDisplay from 'general/cb-event-icon-display.vue';
import {TABLE_SETTINGS, PAGINATION_SETTINGS} from "../constants/defaultTableSettings";
import MultiSelect from 'common/components/multi-select.vue'
import ProcessAdd from "./process-add.vue";
import AppSettings from 'appSettings';

export default {
  name: 'processList',
  components: {
    ProcessAdd,
    CbEventIconDisplay,
    CbAdminViewTitle,
    CbViewSection,
    MultiSelect
  },
  props: {
    data: Object
  },
  data() {
    return {
      processFilter: [],
      searchCriteria: null,
      processFilterList: [],
      processFlows: null,
      tableSettings: TABLE_SETTINGS,
      paginationSettings: PAGINATION_SETTINGS,
      currentPage: 1,
      itemsPerOnePage: PAGINATION_SETTINGS.pageOptions[0],
      modalShow: false
    }
  },
  created() {
    this.processFilter = sessionStorage.processFilter ? JSON.parse(sessionStorage.processFilter) : [];
  },
  methods: {
    async handleOk(value) {
      if (value && value.getUrl) this.$_cb.router.changeView(AppSettings.viewProcess, value.getUrl);
    },
    itemEvents(item) {
      return item && item.events ? item.events : [];
    },
    viewProcess (item) {
      this.$_cb.router.changeView(AppSettings.viewProcess, item.view.url);
    },
    tagFormatter(value) {
      return value.join();
    },
    resetCurrentPage(){
      this.currentPage = 1
    },
    selectProcessFilter(item) {
      this.resetCurrentPage()
      const itemIndex = this.processFilter.indexOf(item)
      if (itemIndex > -1) {
        this.processFilter.splice(itemIndex, 1)
      } else {
        this.processFilter.push(item)
      }

      sessionStorage.processFilter = JSON.stringify(this.processFilter)
    },
    toggleProcessAddModal () {
      this.modalShow = !this.modalShow
    }
  },
  watch: {
    data: {
      handler(newVal) {
        if (newVal && newVal.processFlows) {
          let experiences = new Set()

          newVal.processFlows.forEach(process => {
            experiences.add(process.tags.join())
          })

          this.processFilterList = [...experiences].sort()
          this.processFlows = newVal.processFlows
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    filteredProcessFlows() {
      let processFilter = this.processFilter;
      const searchCriteriaLowerCase = this.searchCriteria && this.searchCriteria.toLowerCase();
      return this.processFlows && this.processFlows.filter(process => {
        return (searchCriteriaLowerCase ? process.name.toLowerCase().includes(searchCriteriaLowerCase) : true) &&
        (processFilter.length ? processFilter.indexOf(process.tags.join()) > -1 : true)
      })
    },
    error() {
      if(!this.hasProcesses){
        return this.$i18n.t('general.searchResultsFormat.noData');
      }
    },
    processFlowsCount() {
      return this.data && this.data.processFlows && this.filteredProcessFlows.length;
    },
    hasProcesses() {
      return this.processFlowsCount > 0
    },
    showPagination() {
      return this.processFlowsCount > PAGINATION_SETTINGS.pageOptions[0];
    }
  }
}
</script>

<style scoped lang="scss">
@import '@clickboarding/style/colors';

.no-data-message {
  width: 260px;
  margin: 0 auto;
  line-height: 20px;
}

.process-list-container {
  margin-top: 1em;
}

.toolbar {
  display: flex;
  align-items: baseline;
}

  .create-process-button {
    margin-left: auto;
  }
</style>
