<template>
  <div class="cb-view-header">
    <div class="header-content container-fluid">
      <div class="row no-gutters">
        <div class="col-6 cb-view-header-title">
            <a class="cb-link return-link" v-if="returnLink" @click="returnLink.handler()"><span class="cb-icon arrow-left"></span> {{returnLink.label}}</a>
            <cb-admin-view-title :title="title" :subtitle="subtitle" />
            <slot name="sub-title" />
        </div>
        <div class="col-6 text-r cb-view-header-actions">
            <action-menu v-if="hasActionMenuItems" :toggle-orientation="'landscape'" :menu-position="'bottom-left'" :items="actionMenuItems"></action-menu>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ActionMenu from 'common/components/action-menu.vue';
  import CbAdminViewTitle from 'general/cb-admin-view-title.vue';

  export default {
    name: 'cb-view-header',
    components: {
      ActionMenu,
      CbAdminViewTitle
    },
    computed: {
      hasActionMenuItems () {
        return this.actionMenuItems && this.actionMenuItems.length > 0;
      }
    },
    props: {
      actionMenuItems: {
        type: Array
      },
      returnLink: {
        type: Object
      },
      title: {
        type: String
      },
      subtitle: {
        type: String
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .cb-view-header {
    margin-top: -1em; /* compensate bottom margin on header */
    @include font-size-reset;

    .header-content {
      @include cb-view-padding;
      @include font-size-content;
    }

    .header-content {
      border-bottom: 1px solid $cb-dark-grey-6;
    }
  }

  .cb-view-header-title {
    min-height: 70px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
  }

  .cb-view-header-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
</style>
