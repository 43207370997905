<template>
  <svg class="cb-loading" :style="{ width: width, height: height}" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" style="background: none;">
    <circle class="cb-loading-circle" cx="50" cy="50" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" />
  </svg>
</template>
<script>

export default {
  name: 'cb-loading',
  props: {
    height: {
      type: String,
      default: "6.25rem"
    },
    width: {
      type: String,
      default: "6.25rem"
    },
  }
}

</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";


  .cb-loading {
    -webkit-animation: progress 1100ms linear infinite;
    -moz-animation: progress 1100ms linear infinite;
    -ms-animation: progress 1100ms linear infinite;
    animation: progress 1100ms linear infinite;
    stroke: $cb-brand-blue-1;
    fill: none;
  }
</style>
