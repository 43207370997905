<template>
    <a class="current-step-cell" v-if="hasCandidateId" @click="showCurrentStepDetails()" style="color: #2F72AC !important;">
        {{ currentStepName }}
    </a>
    <p v-else-if="!hasCandidateId">{{ currentStepName }}</p>
</template>
    
    
    
<script>
export default {
    name: "CurrentStepHyperlink",
    mounted() { },
    methods: {
        showCurrentStepDetails() {
            if (this.params.data.subjectRequisitionId) {
                this.params.clicked(this.params.data);
            }
        }
    },
    computed: {
        currentStepName() {
            return this.params.data.currentStepName;
        },
        hasCandidateId() {
            if (this.params.data.candidateId) {
                return true;
            }
            return false;
        },
    }
};
</script>