<script>
import clonedeep from 'lodash.clonedeep';
import isequal from 'lodash.isequal';
import FormsDataEntryElement from 'forms/forms-data-entry-element.vue';

export default {
  name: 'forms-data-entry-elements',
  functional: true,
  render (createElement, context) {
    const elements = context.props.elements;

    return elements.map((element, index) => {
      return createElement(FormsDataEntryElement, {
        key: index,
        props: {
          element: element,
          isGrouped: context.props.isGrouped,
        },
        on: {
          'update:element': function (element) {
            if ('update:elements' in context.listeners) {
              const updatedElements = clonedeep(context.props.elements);
              updatedElements[index] = element;
              context.listeners['update:elements'](updatedElements);
            }
          }
        },
      });
    });
  },
  props: {
    elements: {
      type: Array,
      required: true
    },
    isGrouped: {
      type: Boolean,
      required: false,
      default: false
    }
  },
}
</script>
