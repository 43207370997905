import ViewController from 'view.controller';
import {i18n} from 'common/lang-config';

export default class LocationGroupsController extends ViewController {
    constructor($http, $scope, $route, $timeout, locationSvc, $window, appSettings, aaNotify) {
        super($http, $scope, $route, $timeout, locationSvc, $window, appSettings, aaNotify);
        this.addLocationGroup = false;
        aaNotify.clearAll();
        this.$http = $http;
        this.$scope = $scope;
        this.aaNotify = aaNotify;
        this.colors = ["#3656A6", "#296FA5", "#237FA5", "#1d90A5", "#17A2A5", "#11A593"];
        this.labels = {
            addLabelAdd: i18n.t('locationGroup.addLabelAdd'),
            editLabelEdit: i18n.t('locationGroup.editLabelEdit'),
            addLabelAddAdditional: i18n.t('locationGroup.addLabelAddAdditional'),
            addLabelAddLocationGroup: i18n.t('locationGroup.addLabelAddLocationGroup'),
            editLabelAddLocationGroup: i18n.t('locationGroup.editLabelAddLocationGroup'),
            addLabelClose: i18n.t('locationGroup.addLabelClose'),
            addLabelLocationGroupName: i18n.t('locationGroup.addLabelLocationGroupName'),
            errorEmptyName: i18n.t('locationGroup.errorEmptyName'),
            headerName: i18n.t('locationGroup.headerName'),
            headerUsage: i18n.t('locationGroup.headerUsage'),
            messageNoData: i18n.t('locationGroup.messageNoData'),
            edit: i18n.t('common.edit'),
            delete: i18n.t('common.delete')
        };
    }
}

LocationGroupsController.$inject = ['$http', '$scope', '$route', '$timeout', 'locationSvc', '$window', 'appSettings', 'aaNotify'];
