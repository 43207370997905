<template>
  <cb-view-section type="primary" :includePrimaryMessages="true" class="activity-edit-section">
    <savage-form name="activity-list-edit" :formstate="formstate" :on-submit="submitActivityEdit">      
      <section v-for="(activity, index) in this.localActivities" :key="index" class="single-activity">
        <slot name="activity-header" :activity="activity"></slot>
        <component
          :is="activity.typeEnum"
          v-model="localActivities[index]">
        </component>
      </section>
      <div class="button-container">
        <button type="button" @click="cancelActivityEdit" class="cb-btn back primary">{{ $t('processBuilder.activities.add.cancelButton') }}</button>
        <button type="submit" class="cb-btn next primary">{{ $t('processBuilder.activities.add.saveButton') }}</button>
      </div>
    </savage-form>
  </cb-view-section>
</template>
<script>
  import CbViewSection from "general/cb-view-section.vue";
  import { SavageForm } from '@clickboarding/savage';
  import { ActivityEditComponents } from 'processes/steps/activities/activityTypes.js';
  
  export default {
    name: "activity-edit",
    components: {
      CbViewSection,
      SavageForm,
      ...ActivityEditComponents
    },
    model: {
      prop: 'activities'
    },
    props: {
      activities: {
        type: Array,
        required: true
      }
    },
    mounted() {
      window.scrollTo(0, 0);
    },
    computed: {
      localActivities: {
        get() {
          return this.activities;
        },
        set(v) {
          this.$emit('input', v);
        }
      }
    },
    methods: {
      cancelActivityEdit() {
        this.$emit('activity-edit-cancel');
      },
      submitActivityEdit() {
        this.$emit('activity-edit-submit');
      }
    },
    data() {
      return {
        formstate: {},
      }
    },
  }
</script>
<style scoped lang="scss">
  .button-container {
    display: flex;
    border-bottom: none !important;

    button {
      flex: 1;
    }
  }

  .single-activity:not(:last-child) {
    padding-bottom: 1.5rem;
  }

  .activity-edit-section {
    margin-top: 0 !important;
    border-top: 0 !important;
  }

</style>