import AppRoutingModule from 'common/app-routing.module';
import ModalModule from "common/modal.module";
import KeyboardModule from "components/keyboard.module";
import processListDirective from "processes/processes-list.directive";
import processAdd from "processes/process-add.vue";
import tasksController from "processes/tasks/tasks.controller";
import ProcessServices from "processes/processes.service.js";
import processController from "processes/process.controller";
import processesController from "processes/processes.controller";
import activityEditController from "processes/steps/activities/activity-edit.controller";
import processListComponent from "processes/process-list.vue";
import processRouteComponent from "processes/builder/process-route.vue";
import activityEditRoute from "processes/steps/activities/activity-edit-route.vue";
import tasksListComponent from "processes/tasks/tasks-list.vue"


export default angular
  .module("processes-module", [AppRoutingModule, KeyboardModule, ModalModule])
  .directive("cbProcessesList", processListDirective)
  .directive("processAdd", processAdd)
  .controller("tasksController", tasksController)
  .controller("processController", processController)
  .controller("processesController", processesController)
  .controller("activityEditController", activityEditController)
  .factory("ProcessServices", ProcessServices)
  .value("process-list", processListComponent)
  .value("process-route", processRouteComponent)
  .value("activity-edit", activityEditRoute)
  .value("tasks-list", tasksListComponent)
  .run(['$rootScope', 'appSettings', async function($rootScope, appSettings) {
    // Clear filters when goes away from the Processes
    $rootScope.$on('$routeChangeStart', function($event, next, current) {
      const path = next.$$route.originalPath;
      const processesPath = [appSettings.viewProcesses,
                              appSettings.viewProcess,
                              appSettings.viewActivityEdit];

      if(!processesPath.includes(path)) {
        sessionStorage.processFilter = '';
      }
    });
  }])
  .name;
