<template>
  <section class="cb-grid-selector">
    <div v-if="label" class="cb-grid-selector-label">{{label}}</div>
    <ul class="cb-grid-selector-list" :class="listClass">
      <li
        v-for="(gridSelectorItem, index) in items"
        :key="index"
        class="cb-grid-selector-item"
        @click="onSelection(gridSelectorItem)"
      >
        <div class="cb-grid-selector-item-content-wrapper">
          <slot name="item-content" :item="gridSelectorItem"></slot>
        </div>
      </li>
    </ul>
  </section>
</template>
<script>
export default {
  name: "cb-grid-selector",
  props: {
    label: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    onSelection: {
      type: Function,
      required: true
    },
    maxColumns: {
      type: Number,
      default: 4
    }
  },
  computed: {
    listClass() {
      return {
        "cb-grid-selector-list-columns-1": this.maxColumns <= 1,
        "cb-grid-selector-list-columns-2": this.maxColumns === 2,
        "cb-grid-selector-list-columns-3": this.maxColumns === 3,
        "cb-grid-selector-list-columns-4": this.maxColumns >= 4
      };
    }
  }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import "@clickboarding/style/mixins";

@mixin cb-grid-selector-hover-style {
  background: $cb-teal-6;
  border-color: $cb-teal-3;
  border-left-width: 0.25em;
  padding-left: calc(1em - 3px);
}

.cb-grid-selector-list {
  @include font-size-reset;
  @include box-sizing-reset;

  display: -ms-grid;
  display: grid;
  grid-gap: 1em;
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);

  &.cb-grid-selector-list-columns-2,
  &.cb-grid-selector-list-columns-3,
  &.cb-grid-selector-list-columns-4 {
    @media screen and (min-width: 48em) {
      -ms-grid-columns: 1fr 1fr;
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &.cb-grid-selector-list-columns-3,
  &.cb-grid-selector-list-columns-4 {
    @media screen and (min-width: 64em) {
      -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &.cb-grid-selector-list-columns-4 {
    @media screen and (min-width: 80em) {
      -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  padding: 0;
  margin: 0;
}

.cb-grid-selector-item {
  @include box-sizing-reset;

  padding: 1em;
  border: 1px solid $cb-light-grey-1;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    @include cb-grid-selector-hover-style;
  }
}

.cb-grid-selector-label {
  font-weight: bold;
  text-align: center;
  margin-bottom: 1em;
  @include font-size-content;
}

//IE11/Edge fallback
//Source for selector to target microsoft browsers: https://stackoverflow.com/questions/32940965/how-to-target-microsoft-edge-with-css/32942177#32942177
//Source for fallback styling: https://stackoverflow.com/questions/46743722/items-in-css-grid-in-ie-superimposed-on-each-other/46745140#46745140
_:-ms-lang(x),
.cb-grid-selector-list {
  display: block;

  .cb-grid-selector-item,
  .cb-grid-selector-item:hover {
    display: inline-block;
    width: 100%;
    border: none;
    padding: 0.5em;
    cursor: default;
    background: $cb-white;

    .cb-grid-selector-item-content-wrapper {
      border: 1px solid $cb-light-grey-1;
      padding: 1em;
      cursor: pointer;

      &:hover {
        @include cb-grid-selector-hover-style;
      }
    }
  }

  // This media query will apply for grids of two, three or four columns
  &.cb-grid-selector-list-columns-2,
  &.cb-grid-selector-list-columns-3,
  &.cb-grid-selector-list-columns-4 {
    @media all and (min-width: 48em) {
      .cb-grid-selector-item,
      .cb-grid-selector-item:hover {
        width: 50%;
      }
    }
  }

  // This breakpoint will apply for grids of three or four columns only
  &.cb-grid-selector-list-columns-3,
  &.cb-grid-selector-list-columns-4 {
    @media all and (min-width: 64em) {
      .cb-grid-selector-item,
      .cb-grid-selector-item:hover {
        width: 33%;
      }
    }
  }

  // This media query will apply only for a grid of four columns 
  &.cb-grid-selector-list-columns-4 {
    @media all and (min-width: 80em) {
      .cb-grid-selector-item,
      .cb-grid-selector-item:hover {
        width: 25%;
      }
    }
  }
}
</style>
