<template>
  <cb-view-section type="primary" :includePrimaryMessages="true" class="activity-select-section">
    <cb-view-section type="secondary">
      <template v-if="activitiesExist">
        <slot name="activity-filter"></slot>
        <cb-check-list
          v-if="hasActivities"
          :header="headerString"
          :items="checklistActivities"
          v-model="localSelectedActivities"
          v-slot="slotProps"
        >
          <template v-if="slotProps.item.value.preview">
            <span class="item-label">{{slotProps.item.label}}</span>
            <span class="item-preview">
              <b-link v-if="slotProps.item.value.preview.url" href="#" @click="showPreview(slotProps.item.value.preview)" class="cb-link">
                <svg>
                    <use v-bind="{'xlink:href': previewSvg }"></use>
                </svg>{{ $t('processBuilder.activities.select.previewLabel') }}
              </b-link>
              <span v-else>{{ $t('processBuilder.activities.select.noPreviewLabel') }}</span>
            </span>
          </template>
        </cb-check-list>
      </template>
      <div v-else id="activity-list-no-data" class="text-c margin-lrg">
        <h2>{{ noDataStrings.header }}</h2>
        <div class="clipboard-pencil-icon"></div>
        <p class="no-data-message">{{ noDataStrings.message }}</p>
      </div>
    </cb-view-section>
    <div class="d-flex mt-4">
      <button type="button" @click="$emit('activity-select-cancel')" class="cb-btn back primary flex-fill">{{ $t('processBuilder.activities.add.cancelButton') }}</button>
      <button type="submit" @click="$emit('activity-select-submit')" class="cb-btn next primary flex-fill" v-if="hasActivities">{{ submitText }}</button>
    </div>
    <b-modal
      id="activity-select-preview-modal"
      hide-footer
      scrollable
      no-close-on-backdrop
      :modal-class="['full-screen-modal', 'no-padding-modal','slate-background-modal']">
      <activity-preview ref="preview" v-if="activePreview" :activePreview="activePreview" />
    </b-modal>
  </cb-view-section>
</template>
<script>
  import CbViewSection from "general/cb-view-section.vue";
  import CbCheckList from "general/cb-check-list.vue";
  import { ActivityConfig } from "processes/steps/activities/activityTypes.js";
  import ActivityPreview from 'processes/steps/activities/activity-preview.vue';
  import PreviewSvg from 'processes/steps/activities/preview.svg';

  export default {
    name: "activity-select",
    components: {
      CbViewSection,
      CbCheckList,
      ActivityPreview
    },
    model: {
      prop: 'selectedActivities'
    },
    props: {
      activityType: {
        type: String,
        required: true
      },
      activities: {
        type: Array,
        required: true
      },
      selectedActivities: {
        type: Array,
        required: true
      },
      stepIsCandidate: {
        type: Boolean,
        required: true
      }
    },
    mounted() {
      this.activitiesExist = this.activities && this.activities.length > 0;
    },
    watch: {
      activities: {
        immediate: true,
        handler: function(val, oldVal) {
          if(val !== oldVal) {
            let filteredActivities = val.filter(activity => {
                let activitySelected = this.localSelectedActivities
                  .filter(selectedActivity => ActivityConfig[this.activityType].activityMatchesExisting(selectedActivity, activity)).length > 0;
                return !activitySelected;
              });
            this.checklistActivities = this.localSelectedActivities.concat(filteredActivities).map(activity => {
              return {
                label: activity.label,
                value: activity
              }
            });
          }
        }
      }
    },
    computed: {
      hasActivities() {
        return this.checklistActivities.length > 0;
      },
      localSelectedActivities: {
        get() {
          return this.selectedActivities;
        },
        set(v) {
          this.$emit('input', v);
          this.$emit('selected-activities-changed', v && v.length > 0)
        }
      },
      previewSvg() {
        return '#' + PreviewSvg.id;
      },
      submitText() {
        if(ActivityConfig[this.activityType].configureOnAdd(this.stepIsCandidate)) {
          return this.$t('common.next');
        }
        else {
          return this.$t('common.update');
        }
      }
    },
    methods: {
      showPreview(itemPreview) {
        this.activePreview = itemPreview;
        this.$bvModal.show('activity-select-preview-modal');
      }
    },
    data () {
      return {
        noDataStrings: this.$t(`processBuilder.activities.add.activityTypeEnumMap.${this.activityType}.noData`),
        headerString: this.$t(`processBuilder.activities.add.activityTypeEnumMap.${this.activityType}.header`),
        checklistActivities: [],
        activitiesExist: null,
        activePreview: null
      }
    }
  }
</script>
<style scoped lang="scss">
@import "@clickboarding/style/mixins";
@import "@clickboarding/style/colors";

span.item-label {
  flex: auto;
}

span.item-preview {
  a {
    display: flex;
    align-items: center;
    
    svg {
      vertical-align: middle;
      height: 1rem;
      width: 1rem;
      margin-right: 0.1875rem;
      use {
        fill: $cb-brand-blue-1;
      }
    }
  }
  span {
    color: $disabled-text-color;
  }
}

.activity-select-section {
  margin-top: 0 !important;
  border-top: 0 !important;
}

.no-data-message {
  width: 16.25rem;
  margin: 0 auto;
  line-height: 1.25rem;
}

.button-container {
  display: flex;
  border-bottom: none !important;

  button {
    flex: 1;
  }
}
</style>