<template>
  <savage-form-labeled-field
    :label="label"
    :disabled="disabled"
    :validation="supportedValidation">
    <div class="d-flex subdomain-wrapper">
      <savage-form-validated-field 
        field-name="subdomain"
        :validation="supportedValidation"
        :customValidation="enrichedCustomValidation"
        class="flex-fill validated">
        <input type="text"
          name="subdomain"
          :placeholder="computedPlaceholder"
          :title="label"
          class="input-text subdomain"
          v-bind="supportedValidationAttributes"
          v-model="localValue"
          :disabled="disabled"
          />
      </savage-form-validated-field>
      <div class="flex-fill">
        <input type="text" disabled="disabled" v-model="domainSuffix" class="input-text subdomain-suffix">
      </div>
    </div>
  </savage-form-labeled-field>
</template>
<script>
import { SavageFormLabeledField, SavageFormValidatedField, SavageFormFieldMixin } from '@clickboarding/savage';

export default {
  name: 'companies-subdomain',
  components: {
   SavageFormValidatedField,
   SavageFormLabeledField,
   SavageFormFieldMixin
  },
  mixins: [ SavageFormFieldMixin ],
  props: {
    domainSuffix: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      allowedValidationTypes: [ 'required', 'minlength', 'maxlength' ]
    };
  }
}
</script>

<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    div.subdomain-wrapper input.subdomain-suffix:disabled {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      background-color: $cb-light-grey-3;
      border-left: 0px !important;
      color: inherit;
    }

    label.wrapper div.subdomain-wrapper input.subdomain {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
</style>
