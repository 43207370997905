<template>
  <dl class="cb-readonly-data-list">
    <template v-for="(item, index) in items">
      <template v-if="shouldRenderItem(item)">
        <dt :key="'dt-' + index" class="cb-readonly-data-list-label-large">
          <slot name="label" :item="item" :label="item[labelKey]">
            {{item[labelKey]}}
          </slot>
        </dt>
        <dd :key="'dd-' + index">
          <slot name="value" :item="item" :value="item[valueKey]">
            {{item[valueKey]}}
          </slot>
        </dd>
      </template>
    </template>
  </dl>
</template>

<script>
import isnil from "lodash.isnil";

export default {
  name: 'cb-readonly-data-list',
  props: {
    items: {
      required: true,
      type: Array
    },
    labelKey: {
      required: false,
      type: String,
      default: 'label'
    },
    valueKey: {
      required: false,
      type: String,
      default: 'value'
    },
    hideNilValues: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  methods: {
    shouldRenderItem (item) {
      return !(this.hideNilValues && isnil(item[this.valueKey]));
    }
  }
}
</script>
<style scoped lang="scss">
  @import '@clickboarding/style/mixins';
  
  $value-left-margin: .5rem;

  .cb-readonly-data-list {
    margin: 0;
    width: 100%;
    @include font-size-content-root;
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    dt, dd {
      margin: 0;
      flex: 0 0 100%;
      padding: 0;
      padding-bottom: .25rem;

      // no padding on last row
      &:last-child {
        padding-bottom: 0;
      }
    }

    dt {
      font-weight: bold;
    }

    dd {
      margin: 0 0 0 $value-left-margin;
    }

    @media screen and (min-width: 768px) {
      dd, dt {
        // no padding on last row
        &:not(:nth-last-child(n+3)) {
          padding-bottom: 0;
        }
      }

      dt {
        display: flex;
      }

      @mixin description-list-inline-label-value ($label-width, $space-between) {
        $label-width: $label-width + 2ch; // add 2ch cushion to allow for wider characters

        flex: 0 0 $label-width;

        + dd {
          // flex shorthand with calc() doesn't work in IE11, need to define each
          flex-grow: 1;
          flex-shrink: 0;
          flex-basis: calc(100% - #{$label-width} - #{$space-between});
        }
      }

      dt.cb-readonly-data-list-label-large {
        @include description-list-inline-label-value(30ch, $value-left-margin);
      }
    }
  }
</style>