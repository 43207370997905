<template>
  <div>
    <div v-if="apiData" class="forms-preview-overlay-content">
      <div class="forms-preview-savage-form-content-wrapper">
        <div class="forms-preview-savage-form-content">
          <cb-view-title :title="title" :icon="previewIcon" :subtitle="subtitle" />
          <div class="h-line"></div>
          <savage-form name="forms-preview-savage-form" :formstate="previewState" :on-submit="submit">
            <savage-form-fields class="form-fields"
                  :model="localModel"
                  :elements="localElements"
                  :formState="previewState"
                  :dateLocale="'en-US'"
                  @update="val => { localModel = val }" />
              <div class="d-flex forms-preview-button-bar">
                <button type="button" class="cb-btn primary flex-fill" disabled>{{backLabel}}</button>
                <button type="submit" class="cb-btn primary flex-fill">{{submitLabel}}</button>
              </div>
          </savage-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CbViewTitle from 'common/components/cb-view-title.vue';
import { SavageForm } from '@clickboarding/savage';
import SavageFormFields from 'common/savage/savage-form-fields.vue';

export default {
  name: 'forms-preview',
  components: {
    CbViewTitle,
    SavageForm,
    SavageFormFields,
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    }
  },
  created () {
    this.previewIcon = {
      id: 'Activity-Title-Personal-Info-Icon'
    };

    this.$http.get(this.dataUrl).then((response) => {
      this.apiData = response.body;
    });
  },
  computed: {
    title () {
      return this.apiData.revision.sections[0].name;
    },
    subtitle () {
      return this.apiData.revision.sections[0].pages[0].label;
    },
    backLabel () {
      return this.apiData.backLabel;
    },
    onlineLabel () {
      return this.apiData.onlineLabel;
    },
    submitLabel () {
      return this.apiData.submitLabel;
    },
    localElements () {
      return this.apiData.revision.sections[0].pages[0].elements;
    },
    localModel: {
      get () {
        return this.apiData.model;
      },
      set (v) {
        this.apiData.model = v;
      }
    }
  },
  methods: {
    submit () {
      //do nothing, just a preview for now
    }
  },
  data () {
    return {
      apiData: null,
      previewState: {} // it appears state needs to be reactive
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@clickboarding/style/mixins';
  @import '@clickboarding/style/colors';

.forms-preview-header-buttons {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.forms-preview-header-buttons button {
  border: none;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $cb-white;

  &:hover,
  &.forms-preview-header-buttons-active {
    background: $cb-medium-blue-6;
  }

  svg {
    width: 4.5rem; height: 2.5rem;
    margin-right: .5rem;
  }
}

.forms-preview-overlay-content {
  min-height: 100%;
  background-color: $cb-slate-1;
  // The padding below emulates the margin applied to .cb-body in Candidate Portal.
  // Had to use padding instead of margin on forms-preview-savage-form-content-wrapper
  // because of collapsing margins
  padding: 2rem;
}

.forms-preview-savage-form-content-wrapper {
  background-color: $cb-white;
  // The padding below is to emulate the .view-content styles in Candidate Portal
  padding: 1.25rem 3%;
}

.forms-preview-savage-form-content {
  // The max-width and margin below are to emulate the .view-content-form-container
  // styles in candidate portal
  max-width: 80rem;
  margin: 0 auto;
}

@media screen and (max-width: 767px) {
  .forms-preview-overlay-content {
    // The padding below is to emulate the margin being applied to .cb-body in
    // Candidate Portal on Site.scss for mobile devices .cb-body
    padding: .5rem 0;
  }
}

.forms-preview-button-bar {
  margin-top: 2rem
}
</style>
