<template>
  <section class="cb-view-section" :class="{ primary: type === 'primary', secondary: type === 'secondary', 'with-flush-top': withFlushTop}">
    <cb-primary-message v-if="includePrimaryMessages"></cb-primary-message>
    <slot></slot>
  </section>
</template>

<script>
  import CbPrimaryMessage from 'components/cb-primary-message.vue';

  export default {
    name: "cb-view-section",
    components: {
      CbPrimaryMessage
    },
    props: {
      type:{
        type: String,
        required: false,
        validator: value => {
            return value === 'primary' || value === 'secondary';
        }
      },
      withFlushTop: {
        type: Boolean,
        required: false,
        default: false
      },
      includePrimaryMessages: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }

</script>

<style scoped lang="scss">
  @import '@clickboarding/style/colors';
  @import '@clickboarding/style/mixins';

  .cb-view-section {
    @include cb-view-padding;

    &.primary {
      margin-top: 1.5rem; // applies only to the first "root" level primary section on a given page. Ensures spacing between gray background and content above.
      border-top: 1px solid $cb-light-grey-1;
      border-bottom: 1px solid $cb-light-grey-1;
      background: $cb-light-grey-3;
    }
    &.secondary {
      background: $cb-white;
      border-bottom: 1px solid $cb-light-grey-1;
    }
  }

  .cb-view-section + .cb-view-section.primary,
  .cb-view-header + .cb-view-section.primary {
    border-top: none;
    margin-top: 0;
  }

  .cb-view-section .cb-view-section {
    margin-top: 0;
    border: 1px solid $cb-light-grey-1;
  }

  .cb-view-section.primary {
    .cb-view-section.secondary + .cb-view-section.secondary:not(.with-flush-top) {
      margin-top: 1.5rem;
    }
    .cb-view-section.secondary + .cb-view-section.secondary.with-flush-top {
      border-top: none;
    }
  }
</style>
