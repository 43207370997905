import en from "lang/en-US.json";

export const TABLE_SETTINGS = {
  hover: true,
  small: true,
  outlined: true,
  'sort-icon-left': true,
  fixed: true
}

export const PAGINATION_SETTINGS = {
  align: 'right',
  'first-number': true,
  'last-number': true,
  'prev-text': en.general.pagination.previousButtonText,
  'next-text': en.general.pagination.nextButtonText,
  pageOptions: [50, 100, 200, 500]
}