<template>
  <nav class="user-menu">
    <div class="user-menu-items">
      <div class="user-menu-navigation-item">
        <button type="button" class="user-menu-support-button" @click="showPopout('support')" data-name-id="support">
          <svg class="user-menu-icon" :class="{'active': supportSelected}">
            <use class="header-support-icon" v-bind="{'xlink:href': iconPaths.support }"></use>
            <use class="header-support-icon-active" v-bind="{'xlink:href': iconPaths.supportActive }"></use>
          </svg>
        </button>

        <div class="user-menu-popout popout-support" id="userSupportInformation" v-if="supportSelected">
          <span class="menu-prompt-title">{{supportTitle}}</span>
          <slot name="support" />
        </div>
      </div>

      <div class="user-menu-navigation-item">
        <button type="button" class="user-menu-account-button" @click="showPopout('account')" data-name-id="account">
          <svg class="user-menu-icon" :class="{'active': accountSelected}">
            <use class="header-account-icon" v-bind="{'xlink:href': iconPaths.account }"></use>
            <use class="header-account-icon-active" v-bind="{'xlink:href': iconPaths.accountActive }"></use>
          </svg>
        </button>

        <div class="user-menu-popout popout-account" id="userAccountInformation" v-if="accountSelected">
          <span class="menu-prompt-title">{{greeting}}, {{name}}</span>

          <slot name="client" />

          <button class="cb-btn secondary" @click="logout()" data-name-id="logout">{{logoutLabel || 'Logout'}}</button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import SupportIcon from 'common/assets/icons/header/support-badge.svg'
import SupportActiveIcon from 'common/assets/icons/header/support-badge-active.svg'
import UserIcon from 'common/assets/icons/header/user-badge.svg'
import UserActiveIcon from 'common/assets/icons/header/user-badge-active.svg'
import EmailIcon from 'common/assets/icons/contact/email.svg'
import PhoneIcon from 'common/assets/icons/contact/phone.svg'


export default {
  name: 'cb-user-menu',
  props: {
    supportTitle: {
      type: String,
      required: false
    },
    logoutLabel: {
      type: String,
      required: false
    },
    logout: {
      type: Function,
      required: false
    },
    greeting: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    }
  },
  created () {
    this.iconPaths = {
      support: '#' + SupportIcon.id,
      supportActive: '#' + SupportActiveIcon.id,
      account: '#' + UserIcon.id,
      accountActive: '#' + UserActiveIcon.id,
      email:'#' + EmailIcon.id,
      phone:'#' + PhoneIcon.id
    }
  },
  destroyed () {
    document.removeEventListener('keydown', this.closeWithEsc);
  },
  computed: {
    accountSelected () {
      return this.popoutToShow === 'account'
    },
    supportSelected () {
      return this.popoutToShow === 'support'
    }
  },
  methods: {
    showPopout (type) {
      if (this.popoutToShow === type || type === null) {
        this.popoutToShow = null;
        document.removeEventListener('keydown', this.closeWithEsc);
        this.previousElement = null;
      } else {
        this.popoutToShow = type;
        document.addEventListener('keydown', this.closeWithEsc);
        this.previousElement = document.activeElement;
      }
    },
    closeWithEsc (e) {
      if (!this.popoutToShow) return;
			e = e || window.event;
			var isEscape = false;

			isEscape = (e.keyCode == 27);

			if (isEscape) {
        this.popoutToShow = null;
        if (this.previousElement) this.previousElement.focus();
      }
    }
  },
  data () {
    return {
      popoutToShow: null,
      previousElement: null
    };
  }
}
</script>

<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';

    .user-menu {
      display: flex;
      flex-direction: row;
      flex-flow: row-reverse;
      padding-right: .5rem;
    }
    .user-menu-items {
      display: flex;
      justify-content: space-around;
      width: 6rem;
      font-size: .875rem;
    }
    .user-menu-icon {
      height: 2rem;
      width: 2rem;
    }
    .user-menu-account-button,
    .user-menu-support-button {
        background: none;
        border: none;
        display: inline-block;
        width: 2rem;
        height: 2rem;
        padding: 0;
    }
    .user-menu-popout {
      background: $cb-white;
    }

    .user-menu-button-icon {
      height: 2rem; width: 2rem;
    }

    .user-menu-navigation-item {
      position: relative;
      height: 2rem;
      display: inline-block;

      .user-menu-popout {
        background: $cb-light-grey-3;
        border: 1px solid $cb-dark-grey-5;
        position: absolute;
        top: calc(100% + 1rem);
        right: -.5rem;
        z-index: 100;
        padding: 1rem;
        min-width: 18rem;
        max-width: 28rem;

        &:before,
        &:after {
            position: absolute;
            bottom: 100%;
            left: calc(100% - 1.5rem + 1px); // input right side padding + icon width + right border width
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            pointer-events: none;
        }

        $popout-border-width: .1rem;
        $popout-arrow-height: .75rem; // calculates the space above the input
        $popout-arrow-inner-height: $popout-arrow-height - $popout-border-width;

        &:before {
            border-color: rgba(196, 196, 196, 0);
            border-bottom-color: $cb-dark-grey-5;
            border-width: $popout-arrow-height;
            margin-left: -$popout-arrow-height;
        }

        &:after {
            border-color: rgba(244, 244, 244, 0);
            border-bottom-color: $cb-light-grey-3;
            border-width: $popout-arrow-inner-height;
            margin-left: -$popout-arrow-inner-height;
        }

        &.popout-support {
          right: -3.5rem; // align it to the same place as the user menu
          
          &:before,
          &:after {
            left: calc(100% - 4.5rem + 1px); // put the arrow under the support button
          }
        }

        &.popout-support:before {
          border-width: $popout-arrow-height;
          margin-left: -$popout-arrow-height;
        }
        &.popout-support:after {
          margin-left: -$popout-arrow-inner-height;
        }

        .menu-prompt-title {
          display: block;
          font-weight: bold;
          border-bottom: 1px solid $cb-light-grey-1;
          width: 100%;
          padding-bottom: .5rem;
          margin-bottom: 1rem;
        }

        .cb-btn {
          width: 100%;
        }
      }
    }

    svg .header-support-icon,
    svg .header-account-icon,
    svg .header-support-icon-active,
    svg .header-account-icon-active {
      fill: $cb-brand-blue-1;
    }

    .user-menu-account-button .header-account-icon-active,
    .user-menu-support-button .header-support-icon-active {
      display: none;
    }
    .user-menu-account-button:hover .header-account-icon-active,
    .user-menu-support-button:hover .header-support-icon-active,
    .user-menu-account-button svg.active .header-account-icon-active,
    .user-menu-support-button svg.active .header-support-icon-active {
      display: block;
    }
    .user-menu-account-button:hover .header-account-icon,
    .user-menu-support-button:hover .header-support-icon,
    .user-menu-account-button svg.active .header-account-icon,
    .user-menu-support-button svg.active .header-support-icon {
      display: none;
    }

</style>
