import Vue from "vue";
import isFunction from "lodash.isfunction";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  setInteractionMode
} from "vee-validate";
import {
  required,
  min,
  max,
  email
} from "vee-validate/dist/rules";

const noHtml = {     
  validate: value => { 
      let x = new String(value);
      if(x.includes("<"))
        return false
      return true
    } 
  };

  extend('noHtml', {
    ...noHtml,
    message: i18n.t(`common.validation.noHtml`)

  });


import { i18n } from 'common/lang-config';
import flushPromises from "flush-promises";

/**
 * Don't validate until they have blurred the field
 * at least once, and afterward valdiate aggressively
 */
const customInteractionMode = args => {
  const { errors, flags } = args;
  return { 
    on: flags.validated ? ['input', 'change'] : ['change', 'blur'] 
  };
};

// set the global default mode
setInteractionMode('eagerDirty', customInteractionMode);

extend('required', {
  ...required,
  message: i18n.t(`common.validation.required`)
});





extend('min', {
  ...min,
  message: (fieldName, placeholders) => {
    return i18n.t(`common.validation.minLengthFormat`, [placeholders.length]);
  }
});

extend('max', {
  ...max,
  message: (fieldName, placeholders) => {
    return i18n.t(`common.validation.maxLengthFormat`, [placeholders.length]);
  }
});

extend('email', {
  ...email,
  message: i18n.t(`common.validation.email`)
});

extend('lessThanOrEqual', {
  params: ['target', 'overrideMessageKey'],
  validate(value, { target }) {
    return value <= target;
  },
  message: (fieldName, placeholders) => {
    return i18n.t(placeholders.overrideMessageKey || 'common.validation.lessThanFormat',
    [
      i18n.t(fieldName),
      i18n.t(placeholders.target)
    ]);
  }
});

extend("custom", {
  params: ["validators"],
  validate: (value, { validators }) => {
    const failedValidator = validators.find(validator => {
      return validator.validate(value) === false;
    });
    return failedValidator === undefined ? true : false;
  },
  message: (fieldName, { _value_, validators }) => {
    const failedValidator = validators.find(validator => {
      return validator.validate(_value_) === false;
    });

    let outputMessage = null;

    if (failedValidator) {
      outputMessage = isFunction(failedValidator.message)
        ? failedValidator.message(_value_)
        : failedValidator.message;
    }

    return outputMessage;
  }
});

// Install components globally
Vue.component("ValidationObserver", {
  extends: ValidationObserver,
  methods: {
    async handleSubmit(cb) {
      const isValid = await this.validate();
      
      if (isValid) {
        return await cb();
      } else {
        const firstInvalidFormGroup = this.$el.querySelector('.form-group[aria-invalid=true]');
        const firstInvalidControl = firstInvalidFormGroup && firstInvalidFormGroup.querySelector([
          'input[aria-invalid="true"]',
          'textarea[aria-invalid="true"]' ,
          'select[aria-invalid="true"]',
        ].join(','));

        if (firstInvalidControl) {
          firstInvalidControl.focus();
        } else if (firstInvalidFormGroup) {
          firstInvalidFormGroup.scrollIntoView();
        }

        return;
      }
    }
  }
});

Vue.component("ValidationProvider", ValidationProvider);
