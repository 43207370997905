<template>
  <b-modal
      v-model="modalShow"
      :title="modalTitle"
      :ok-title="data.saveLabel"
      centered
      static
      lazy
      size="lg"
      no-close-on-backdrop
      footer-class="add-process-footer"
      @ok="handleOk"
      @cancel="$emit('cancel')"
      @close="$emit('close')"
      @hide="handleHide"
  >
    <cb-primary-message></cb-primary-message>
    <b-form class="mb-2"
            @submit.stop.prevent="saveAndClose"
    >
      <div class="d-flex">
        <div class="w-50 mr-2">
          <b-form-group>
            <template v-slot:label>
              {{ data.identifierLabel }}
              <span aria-hidden class="text-danger">*</span>
            </template>
            <b-form-input
                tabindex="1"
                class="process-identifier"
                :placeholder="data.identifier"
                :state="validateState('identifier')"
                v-model="$v.model.identifier.$model"/>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              {{ data.tags.label }}
              <span v-if="isCopy || !Boolean(editItem)" aria-hidden class="text-danger">*</span>
            </template>
            <b-form-select
                tabindex="3"
                class="process-employee-experience"
                required
                :state="validateState('tag')"
                :disabled="!isCopy && Boolean(editItem)"
                v-model="$v.model.tag.$model"
                :options="[{value: '', text: 'Select an experience...'},...data.tags.items.map(tag => {return {value: tag, text: tag}})]"
            >
            </b-form-select>
          </b-form-group>
          <b-form-group>
            <template v-slot:label>
              {{ data.titleLabel }}
              <span aria-hidden class="text-danger">*</span>
            </template>
            <b-form-input
                tabindex="4"
                class="process-title"
                placeholder="example: Welcome {FirstName}!"
                :state="validateState('title')"
                v-model="$v.model.title.$model"/>
          </b-form-group>
        </div>
        <div class="w-50 ml-2">
          <b-form-group>
            <template v-slot:label>
              {{ data.nameLabel }}
              <span aria-hidden class="text-danger">*</span>
            </template>
            <b-form-input
                tabindex="2"
                class="process-name"
                :state="validateState('name')"
                v-model="$v.model.name.$model"
            />
          </b-form-group>
        </div>
      </div>

      <b-form-group>
        <template v-slot:label>
          {{ data.explanationLabel }}
          <span aria-hidden class="text-danger">*</span>
        </template>
        <b-form-textarea
            tabindex="5"
            class="process-explanation"
            :state="validateState('explanation')"
            v-model="$v.model.explanation.$model"
        />
      </b-form-group>

      <div class="d-flex align-items-end">
        <multi-select
            tabindex="6"
            class="assign-location-group"
            :options="data.associateLocationGroup.locationGroups"
            :value="selectedLocationGroup"
            track-by="id"
            search-by="name"
            :search-placeholder="$t('common.filterSearchPlaceholder', [$t('locations.searchLocationGroupPlaceholder')])"
            :close-on-select="true"
            placement="bottomright"
            :title="$t('common.select.emptyOption')"
            :label="data.associateLocationGroup.label"
            @select="selectLocationGroup($event)"
        >
          <template v-slot:option="{ option }">
            {{ option.name }}
          </template>
        </multi-select>
        <b-button class="add-location-group ml-3 small-button"
                  tabindex="7"
                  variant="primary"
                  @click="assignLocationGroup(selectedLocationGroup)"
        >{{ $t('common.add') }}
        </b-button>
        <div class="d-flex flex-wrap ml-3">
          <div v-for="(locationGroup, index) in model.locationGroups"
               class="location-group-tag"
               :title="locationGroup.name"
               :key="locationGroup.id"
          ><span>
            {{ locationGroup.name }}
          </span>
            <b-button class="close"
                      variant="close"
                      @click="removeLocationGroup(index)"
            ></b-button>
          </div>
        </div>
      </div>
    </b-form>

    <h2 class="mt-4" style="border-bottom:solid 1px #ccc;">{{ data.events.titleLabel }}</h2>
    <div class="labelContent">
      <div class="d-flex">
        <b-form-group class="w-50 pr-2" :label="data.events.eventsLabel">
          <b-form-select
              tabindex="8"
              class="step-event-type"
              required
              text-field="name"
              value-field="typeEnum"
              v-model="eventType.typeEnum"
              @change="setEvent"
              :options="[{ typeEnum: null, name: data.events.eventsDefault} ,...data.events.types]"
          >
          </b-form-select>
        </b-form-group>
      </div>
    </div>

    <div class="event-wrapper mb-3" v-if="event && event.typeEnum !== undefined">
      <event-email v-if="event.typeEnum === 'Email'"
                   :labels="event"
                   :labels-triggers="data.events.triggerLabels"
                   :edit-data="editEvent"
                   @saveEvent="saveEvent"
      ></event-email>
      <event-screening v-if="event.typeEnum === 'InitiateScreening'"
                       :labels="event"
                       :labels-triggers="data.events.triggerLabels"
                       :edit-data="editEvent"
                       @saveEvent="saveEvent"
      ></event-screening>
      <event-process-action v-if="event.typeEnum === 'ProcessAction'"
                            :labels="event"
                            :labels-triggers="data.events.triggerLabels"
                            :edit-data="editEvent"
                            @saveEvent="saveEvent"
      ></event-process-action>
      <event-milestone v-if="event.typeEnum === 'Milestone'"
                       :labels="event"
                       :labels-triggers="data.events.triggerLabels"
                       :edit-data="editEvent"
                       @saveEvent="saveEvent"
      ></event-milestone>
    </div>

    <b-table v-if="model.events && model.events.length"
             class="new-table"
             :items="model.events"
             :fields="[
               { key: 'typeEnum', label: data.events.typeHeader },
               { key: 'description', label: data.events.detailHeader},
               { key: 'actions', label: '' }
               ]">
      <template v-slot:cell(typeEnum)="{ item }">
        <i :class="getEventIcon(item.typeEnum)" style="font-size:125%; padding:.5em;"></i>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="ml-auto float-right">
          <a @click.prevent="handleEditEvent(item)"
             :class="{'text-disabled': item.editing}"
             href=""
             title="Edit"
             style="display:inline-block;">
            <i class="fa fa-pencil-square-o" style="font-size:150%; padding:.5em;"></i>
          </a>
          <a @click.prevent="handleDeleteEvent(item)"
             class="delete-event"
             href=""
             :title="data.events.deleteLabel"
             style="display:inline-block;">
            <i class="fa fa-trash-o" style="font-size:150%; padding:.5em; margin-left:.5em;"></i>
          </a>
        </div>
      </template>
    </b-table>
  </b-modal>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, maxLength} from "vuelidate/lib/validators";
import CbPrimaryMessage from "../components/cb-primary-message";
import MultiSelect from 'common/components/multi-select.vue'
import EventEmail from "./steps/events/event-email";
import EventScreening from "./steps/events/event-screening";
import EventProcessAction from "./steps/events/event-process-action";
import EventMilestone from "./steps/events/event-milestone";
import isEqual from "lodash.isequal";
import cloneDeep from 'lodash.clonedeep'

export default {
  name: "process-add",
  mixins: [validationMixin],
  components: {CbPrimaryMessage, MultiSelect, EventMilestone, EventProcessAction, EventScreening, EventEmail},
  props: {
    data: {
      required: true,
      type: Object,
    },
    editItem: {
      type: Object
    },
    isCopy: {
      type: Boolean
    }
  },
  data() {
    return {
      modalShow: true,
      eventType: {
        typeEnum: null
      },
      selectedLocationGroup: null,
      event: null,
      editEvent: null,
      activityDesc: '',
      model: {
        name: "",
        explanation: "",
        identifier: "",
        tag: '',
        title: '',
        locationGroups: [],
        events: []
      },
      validationMessages: {
        required: this.$t("common.validation.required")
      }
    }
  },
  validations() {
    return {
      model: {
        identifier: {
          required,
          maxLength: maxLength(50)
        },
        explanation: {
          required,
          maxLength: maxLength(250)
        },
        name: {
          required,
          maxLength: maxLength(35),
        },
        title: {
          required,
          maxLength: maxLength(35),
        },
        tag: {
          required
        }
      }
    }
  },
  computed: {
    modalTitle() {
      if (this.isCopy) {
        return this.data.copyTitle
      } else if (this.editItem) {
        return this.data.editTitle
      } else {
        return this.data.addTitle
      }
    }
  },
  methods: {
    setEvent(eventTypeEnum) {
      this.editEvent = null
      this.event = this.data.events.types.find(event => event.typeEnum === eventTypeEnum)
    },
    saveEvent(item) {
      if (this.editEvent) {
        const editEventIndex = this.model.events.findIndex(event => event.eventGuid === item.eventGuid)
        this.$set(this.model.events, editEventIndex, item)
      } else {
        this.model.events.push(item)
      }

      this.editEvent = null
      this.eventType = {
        typeEnum: null
      }
      this.event = null
    },
    selectLocationGroup(item) {
      this.selectedLocationGroup = item
    },
    assignLocationGroup(item) {
      if (item) {
        const exists = this.model.locationGroups.find(locationGroup => item.id === locationGroup.id)

        if (!exists) {
          this.model.locationGroups.push(item)
        }

        this.selectedLocationGroup = null
      }
    },
    validateState(name) {
      const {$dirty, $error} = this.$v.model[name]
      return $dirty ? !$error : null
    },
    validateActivities(index) {
      const {$dirty, $error} = this.$v.model.activities.$each.$iter[index]
      return $dirty ? !$error : null
    },
    removeLocationGroup(index) {
      this.model.locationGroups.splice(index, 1)
    },
    handleDeleteEvent(item) {
      const index = this.model.events.findIndex(event => isEqual(item, event))
      this.model.events.splice(index, 1)
    },
    handleEditEvent(item) {
      item.editing = true
      this.eventType = {typeEnum: item.typeEnum}
      this.setEvent(item.typeEnum)
      this.editEvent = item
    },
    handleHide(bvModelEvent) {
      if (bvModelEvent.trigger !== 'ok') {
        // hide happen on esc, but it also happens whenever the window gets
        // closed (e.g., hitting the ok button), ok is handled differently (saveAndClose)
        // don't forward this event in that scenario
        this.$emit('hide')
      }
    },
    handleAddActivity() {
      if (this.activityDesc && this.activityDesc.trim() !== '') {
        this.model.activities.push({
          description: this.activityDesc
        })
        this.activityDesc = ''
      }
    },
    handleEditActivity(item) {
      item.isEditing = true
      item.previousValue = item.description
    },
    handleCancelActivity(item) {
      if (item.isEditing) {
        item.isEditing = false
        item.description = item.previousValue
      }
    },
    handleSaveActivity(item) {
      item.isEditing = false
      item.previousValue = null
    },
    handleDeleteActivity(index) {
      this.model.activities.splice(index, 1)
    },
    setData() {
      return {
        identifier: this.model.identifier,
        name: this.model.name,
        title: this.model.title,
        explanation: this.model.explanation,
        locationGroups: this.model.locationGroups.map(item => item.id),
        events: this.model.events,
        tags: this.model.tag ? [this.model.tag] : []
      };

    },
    async handleOk(bvModelEvent) {
      // according to the docs (https://bootstrap-vue.js.org/docs/components/modal#prevent-closing), async isn't supported
      // so always prevent the default and then handle closing it ourselves if needed
      bvModelEvent.preventDefault();

      await this.saveAndClose();
    },
    async saveAndClose() {
      this.$v.$touch()

      if (this.$v.$anyError) {
        return
      }


      let url, method;
      if (this.editItem && !this.isCopy) {
        url = this.editItem.editUrl
        method = 'put'
      } else if (this.isCopy) {
        url = this.editItem.copyUrl
        method = 'post'
      } else {
        url = this.data.add.url
        method = 'post'
      }

      const {data} = await this.$http[method](url, this.setData())
      this.$emit('ok', data)
      this.modalShow = false
    },
    getEventIcon(typeEnum) {
      let eventClass = ""
      switch (typeEnum) {
        case "Email":
          eventClass = "fa fa-envelope-o"
          break;
        case "ProcessAction":
          eventClass = "fa fa-bolt"
          break;
        case "InitiateScreening":
          eventClass = "fa fa-binoculars"
          break
        case "Task":
          eventClass = "fa fa-check-circle-o"
          break
        case "Milestone":
          eventClass = "fa fa-map-marker"
          break
      }
      return eventClass
    }
  },
  mounted() {
    if (this.editItem) {
      this.model = cloneDeep(this.editItem)
      this.model.identifier = this.isCopy ? "" : this.editItem.identifier
      this.model.name = this.isCopy ? this.editItem.copyName : this.editItem.name
      this.model.events = this.model.events || []
      this.model.locationGroups = this.data.associateLocationGroup.locationGroups && this.editItem.locationGroups ? this.data.associateLocationGroup.locationGroups.filter(
          function (locationGroup) {
            return this.indexOf(locationGroup.id) > -1
          },
          this.editItem.locationGroups
      ) : []
      this.model.tag = this.editItem.tags && this.editItem.tags.length >= 1 ? this.editItem.tags[0] : null
    }
  }
}
</script>

<style lang="scss" scoped>
.event-wrapper {
  padding: 1rem;
  border: 1px #ccc solid;
  border-radius: 1rem;
}

.assign-location-group::v-deep {
  width: 100%;
  max-width: 250px;

  .multiselect-picker-button {
    width: 100%;
    max-width: 250px;
  }
}
</style>
