<template>
    <div class="action-center-item-action">
      <template v-if="apiData">
        <div class="status-icon">
          <svg>
            <use v-bind="{'xlink:href': statusIconPath }"></use>
          </svg>
        </div>

        <button type="button" class="cb-btn primary action-button" @click="getForm">
          {{buttonLabel}}
        </button>

          <b-modal
          :id="modalId"
          hide-footer
          scrollable
          no-close-on-backdrop
          :modal-class="['full-screen-modal', 'no-padding-modal','slate-background-modal']"
          :title="headerText"
          @hide=handleClose>
            <div class="action-center-hrform-overlay-content-container-wrapper">
              <div class="action-center-hrform-overlay-content-container">
                <div class="action-center-hrform-overlay-content">
                  <rose-acknowledgement 
                    v-if="formType === 'RoseAcknowledgement'"
                    :roseUrl="formContentUrl"
                    :onComplete="close"
                    ref="roseAcknowledgement"
                    />
                  <hrform-document 
                    v-else-if="formType === 'Document'"
                    :url="formContentUrl"
                    :onComplete="close"
                    ref="hrformDocument"
                    />
                  <hrform-standard 
                    v-else-if="formType === 'Standard'"
                    :contentUrl="formContentUrl"
                    :onComplete="close"
                    ref="hrformStandard"
                    />
                </div>
              </div>
            </div>
          </b-modal>
          <cb-exit-confirmation ref="exitConfirmation" />
      </template>
    </div>
</template>
<script>
import ActivityCompletedIcon from 'common/assets/icons/activities/activity-completed.svg';
import ActivityGetStartedIcon from 'common/assets/icons/activities/activity-get-started.svg';
import FormRoseAcknowledgement from 'forms/forms-rose-acknowledgement.vue'
import FormDocument from 'forms/forms-document.vue';
import CbExitConfirmation from 'common/components/cb-exit-confirmation.vue';
import FormStandard from 'forms/forms-standard.vue';
import PrimaryMessageBus from 'common/components/cb-primary-message-bus';
import uuid from 'uuid/v4';

export default {
    name: 'action-center-hrform',
    components: {
      CbExitConfirmation,
      'rose-acknowledgement': FormRoseAcknowledgement,
      'hrform-document': FormDocument,
      'hrform-standard': FormStandard
    },
    props: {
      instanceRequest: {
        type: Object,
        required: true,
        validator: function(value) {
          return 'method' in value && 'url' in value;
        }
      }
    },
    beforeCreate() {
      // This component is used in a list of similar components.  In order
      // to open the correct modal, we need to give it a unqiue id so that
      // the $bvModal.show() function knows which modal to open
      this.modalId = `action-center-hr-form-preview-modal-${uuid()}`;
    },
    data() {
      return {
        apiData: null,
        headerText: null,
        formType: null,
        formContentUrl: null
      };
    },
    computed: {
      statusIconPath () {
        let iconId = null;
        switch (this.apiData.actionItem.status.value) {
          case 'Completed':
            iconId = ActivityCompletedIcon.id;
            break;
          case 'Due':
            iconId = ActivityGetStartedIcon.id;
            break;
          case 'Overdue':
            iconId = ActivityGetStartedIcon.id;
            break;
          default:
            iconId = ActivityGetStartedIcon.id;
            break;
        }
        return '#' + iconId;
      },
      buttonLabel () {
        let labelId = null;
        switch (this.apiData.actionItem.status.value) {
          case 'Completed':
            labelId = 'actionCenter.form.review';
            break;
          case 'Due':
            labelId = 'actionCenter.form.get-started';
            break;
          case 'Overdue':
            labelId = 'actionCenter.form.get-started';
            break;
          default:
            labelId = 'actionCenter.form.get-started';
            break;
        }
        return this.$t(labelId);
      },
      showForm () {
        return this.formType && this.formContentUrl ? true : false;
      }
    },
    watch: {
      instanceRequest: {
        immediate: true,
        deep: true,
        handler: async function(newVal, oldVal) {
          await this.refreshApiData();
        }
      }
    },
    methods: {
      async getForm () {
        let formData = await this.$http.get(this.apiData.getFormUrl);
        this.headerText = formData.body.formName;
        this.formType = formData.body.type;
        this.formContentUrl = formData.body.contentUrl;

        // clear messages when loading a form
        PrimaryMessageBus.$emit('clear-all-messages');
        this.$bvModal.show(this.modalId);
      },
      async refreshApiData () {
        const response = await this.$http(this.instanceRequest);

        if(response) {
          this.apiData = response.body;

          if(this.apiData) {
            //always emit on refresh, in case status has changed.
            this.emitForStatusChange(this.instanceRequest, this.apiData);
          }
        }
      },
      async emitForStatusChange (instanceRequest, item) {
        const status = {
            value: item.actionItem.status.value
          };

        if(item.actionItem.status.date) {
          status.date = item.actionItem.status.date;
        }

        this.$emit('action-item-status-changed', instanceRequest, {
          status: status
        });
      },
      async close() {
        // clear messages when closing a form
        PrimaryMessageBus.$emit('clear-all-messages');

        this.$bvModal.hide(this.modalId);
        this.headerText = null;
        this.formType = null;
        this.formContentUrl = null;
        await this.refreshApiData();
      },
      async closeWithConfirm() {
        var closeConfirmed = await this.$refs.exitConfirmation.open();
        if (closeConfirmed) {
          await this.close();
        }
      },
      async handleClose(bvModalEvent) {
        // Because this component uses the exit confirmation, we need to look for
        // events that are coming from the user like the header close click or the
        // escape key being pressed and prevent the modal from closing.  If the trigger
        // for the event is "event" instead this means that the user has confirmed that
        // they're leaving the page from the exit confirmation.
        if (bvModalEvent.trigger !== "event") {
          if (this.shouldConfirmExit()) {
            bvModalEvent.preventDefault();
            await this.closeWithConfirm();
          } else {
            await this.close();
          }
        }
      },
      shouldConfirmExit () {
        if (this.formType === 'Document') {
          return this.$refs.hrformDocument.shouldConfirmExit();
        }
        else if (this.formType === 'RoseAcknowledgement') {
          return this.$refs.roseAcknowledgement.shouldConfirmExit();
        }
        else if (this.formType === 'Standard') {
          return this.$refs.hrformStandard.shouldConfirmExit();
        }
        else return false;
      }
    }
};
</script>
<style scoped lang="scss">
@import "@clickboarding/style/colors";
@import '@clickboarding/style/mixins';

.action-center-item-action {
  background-color: $cb-white;
  position: relative;
  border: solid 1px $cb-light-grey-1;
}

.action-center-hrform-overlay-header {
  background: $cb-medium-blue-6;
  color: $cb-brand-blue-1;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.action-center-hrform-overlay-content-container-wrapper {
  @include box-sizing-reset;
  background-color: $cb-slate-1;
  padding: 1.5rem 4rem;
  min-height: 100%;
}

.action-center-hrform-overlay-content-container {  
  background-color: $cb-white;
  padding: 1.5rem;
}

.action-center-hrform-overlay-content {
  @include box-sizing-reset;
  width: 75%;
  margin: auto; // center the content
}

@media screen and (max-width:979px) {
  .action-center-hrform-overlay-content {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .action-center-hrform-overlay-content-container-wrapper{
    padding: 0;
  }
}

.status-icon {
  text-align: center;
  padding: 1.5rem;
}

.action-button {
  width: 100%;
}
</style>