import axios from 'axios';

// API Documentation: https://developer.vimeo.com/api/oembed/videos
// OEmbed Url: https://vimeo.com/api/oembed.json?url={video_url}
async function getVimeoOEmbedResponseByUrl(url) {
  const response = await axios.get('https://vimeo.com/api/oembed.json', {
    params: {
      url: url,
      portrait: false, // Whether to display the video owner's portrait
      title: false, // Whether to display the video's title.
      autoplay: false // Whether to start playback of the video automatically. This feature might not work on all devices.
    }
  });
  return response.data;
}
async function getVimeoOEmbedResponseByUrlOrNull(url) {
  try {
    await getVimeoOEmbedResponseByUrl(url)
  } catch (error) {
    // Just return null here
    return null;
  }
}

export default {
  async getEmbedHtml (url) {
    var response = await getVimeoOEmbedResponseByUrl(url);
    return response.html;
  },
  async isValidUrl (url) {
    var response = await getVimeoOEmbedResponseByUrlOrNull(url);
    return response !== null;
  }
}
