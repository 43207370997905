import FormsDataEntrySimpleField from 'forms/forms-data-entry-element-simplefield.vue';
import FormsDataEntryFieldContainer from 'forms/forms-data-entry-field-container.vue';
import get from 'lodash.get';

function map (element, presentationKey, updateModelFn) {  
  const model = element.availablePresentations[presentationKey];
  // if the model already has this set, let's not overwrite it with the element level values
  // this is really just dealing with adding a new element vs editing an existing element
  if (model && !model.availableConditionalFields) {
    model.availableConditionalFields = element.availableConditionalFields;
  }

  return {
    key: presentationKey,
    container: {
      component: FormsDataEntryFieldContainer,
      props: {
        title: get(element, 'label', null),
      },
    },
    fields: {
      component: FormsDataEntrySimpleField,
      props: {
        label: get(model, 'label', null),
        placeholder: get(model, 'placeholder', null),
        isConditionallyShown: get(model, 'isConditionallyShown', false),
        availableConditionalFields: get(model, 'availableConditionalFields', []),
        conditionalLogicField: get(model, 'conditionalLogic.field', ''),
        conditionalLogicOperator: get(model, 'conditionalLogic.operator', null),
        conditionalLogicValues: get(model, 'conditionalLogic.values', []),
        isRequired: get(model, 'validation.required.value', false),
        isAlwaysRequired: get(model, 'designer.presentationOptions.isAlwaysRequired', false),
        hidePlaceholder: get(model, 'designer.presentationOptions.hidePlaceholder', false),
        hasDependencies: get(model, 'hasDependencies', false),
      },
      events: {
        'update:label': (v) => updateModelFn('label', v),
        'update:placeholder': (v) => updateModelFn('placeholder', v),
        'update:isRequired': (v) => updateModelFn('validation.required.value', v),
        'update:isConditionallyShown': (v) => updateModelFn('isConditionallyShown', v),
        'update:conditionalLogicField': (v) => updateModelFn('conditionalLogic.field', v),
        'update:conditionalLogicOperator': (v) => updateModelFn('conditionalLogic.operator', v),
        'update:conditionalLogicValues': (v) => updateModelFn('conditionalLogic.values', v),
      },
    },
  };
}

export { map }
