<template>
<savage-form-text-input
    name="formIdentifier"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :fieldClass="{'show-success': true}"
    :validation="validation"
    :customValidation="customValidation"
    :title="computedTitle"
    v-model="localValue" />
</template>
<script>
import IsArray from 'lodash.isarray';
import EscapeRegExp from 'lodash.escaperegexp';
import { SavageFormTextInput } from '@clickboarding/savage';

export default {
  name: 'forms-builder-identifierinput',
  components: {
    SavageFormTextInput
  },
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      required: true
    },
    identifierValidation: {
      type: Object,
      required: true,
      validator: (value) => {
        return value.existingIdentifiers === null ||
          ('conflictMessage' in value
          && 'existingIdentifiers' in value
          && IsArray(value.existingIdentifiers));
      }
    },
    validationMessages: {
      type: Object,
      required: true,
      validator: (value) => {
        return 'required' in value;
      }
    }
  },
  mounted () {
    if (!this.value) this.newIdentifier = true;
  },
  computed: {
    computedTitle () {
      // Show localValue as title/hover when field is disabled as the value
      // may be longer than the visible text input
      return this.disabled && this.localValue ? this.localValue : this.label;
    },
    validation () {
      return {
        required: {
          value: true,
          message: this.validationMessages.required
        },
        maxlength: {
          value: 100,
          message: this.$t('common.validation.maxLengthFormat', [100])
        }
      };
    },
    customValidation () {
      return {
        'identifier-available': {
          value: this.isIdentifierAvailable,
          message: this.identifierValidation.conflictMessage
        }
      };
    },
    isIdentifierAvailable () {
      let re = new RegExp("^" + EscapeRegExp(this.value) + "$", "i");
      return this.identifierValidation.existingIdentifiers && this.identifierValidation.existingIdentifiers.filter((identifier) => {
        return re.test(identifier);
      }).length > 0 ? false : true;
    },
    localValue: {
      get () { return this.value; },
      set (val) { this.$emit('input', val) }
    }
  }
}
</script>
