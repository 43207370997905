<template>
    <cb-view-section type="secondary" class="single-brand">
        <div class="single-brand-logo">
            <img :src="brand.logoUrl" :alt="brand.logoAlt" height="42">
        </div>
        <div class="single-brand-name">
            <span>{{brand.name}}</span>
        </div>
        <div class="single-brand-edit link" v-if="editUrl">
            <button @click="navigateToEdit"><svg><use v-bind="{'xlink:href': EditIconPath}"></use></svg><span class="edit-label">{{editLabel}}</span></button>
        </div>
    </cb-view-section>
</template>
<script>
    import CbViewSection from 'general/cb-view-section.vue';
    import EditIconSvg from 'common/assets/icons/actions/edit.svg';
    import AppSettings from 'appSettings';

    export default {
        name: 'single-brand-component',
        components: {
            CbViewSection
        },
        props: {
            brand: {
                type: Object,
                required: true
            },
            editLabel: {
                type: String,
                required: true
            }
        },
        computed: {
            editUrl() {
                var editUrl = null;
                if (this.brand.actions) {
                    this.brand.actions.forEach((action) => {
                        if (action.typeEnum === "Edit") {
                            editUrl = action.url;
                        }
                    });
                }
                return editUrl;
            },
            EditIconPath () {
                return '#' + EditIconSvg.id;
            },
        },
        methods: {
            navigateToEdit() {
                this.$_cb.router.changeView(AppSettings.viewBrandsEdit, this.editUrl);
            }
        }
    }
</script>
<style scoped lang="scss">
@import '@clickboarding/style/colors';
@import '@clickboarding/style/mixins';

.cb-view-section .single-brand {
    margin-top: 1rem;
}

.single-brand {
    text-align: center;
    @include font-size-content-root;
}

.single-brand-logo {
    margin-bottom: .5rem;
}

.single-brand-name {
    margin-bottom: .5rem;
}

.single-brand-edit {
    @include cb-link;
    svg {
        vertical-align: middle;
        height: 16px;
        width: 16px;
        padding-right: 5px;

        use {
            fill: $cb-brand-blue-2;
        }
    }

    span {
        line-height: 16px;
        vertical-align: middle;
    }

    button {
        border: none;
        background: transparent;
    }
}

.single-brand-edit:hover {
    svg use {
        fill: $cb-brand-blue-1;
    }
}

.edit-label {
    vertical-align: middle;
}
</style>
