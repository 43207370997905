import appSettings from 'appSettings';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import store from "store";

class AppInsightsAnalytics {
  constructor() {
    // explicitly create this.enabled as read-only since the rest of this class
    // is written to assume it won't change after initialization
    Object.defineProperty(this, 'enabled', {
      value: appSettings.appInsights.enabled,
      writable: false,
      configurable: false,
    });

    this.appName = null;
    this.globalContextProperties = {};

    if (this.enabled) {

      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: appSettings.appInsights.instrumentationKey,
          enableAutoRouteTracking: true,
          cookieCfg: {
            ignoreCookies: ['ai_session', 'ai_user'],
            blockedCookies: ['ai_session', 'ai_user']
          }
        }
      });
      
      this.appInsights.getCookieMgr().setEnabled(false);

      this.appInsights.loadAppInsights();
      //It will log the Click Analytics Auto-collection
      this.logCustomEvent();

      const registerTelemetryInitializer = () => {
        this.appInsights.addTelemetryInitializer((envelope) => {
          // Update the anonymous userId generated by the SDK
          // with our more meaningful application provided one.
          // If not possible, at least decorate the SDK ones with a prefix.
          const tags = envelope.tags;
          let generatedUserId;
          if (tags) {
            generatedUserId = tags['ai.user.id'] ? 'generated-' + tags['ai.user.id'] : null;

            if (tags['ai.user.authUserId']) {
              tags['ai.user.id'] = tags['ai.user.authUserId'];
            } else {
              tags['ai.user.id'] = generatedUserId;
            }
          }
          

          // Apply global (default) context properties if the item
          // doesn't provide one itself
          const telemetryItem = envelope.baseData;
          if (telemetryItem) {
            if (this.globalContextProperties) {
              telemetryItem.properties = telemetryItem.properties || {};
              Object.keys(this.globalContextProperties)
                .forEach(key => telemetryItem.properties[key] = telemetryItem.properties[key] || this.globalContextProperties[key]);
            }

            // Always record the original SDK generated userId as well, just
            // in case we ever need to correlate in cases where there's a race
            // condition and we don't yet have an authenticatedUserId.
            if (generatedUserId) {
              telemetryItem.properties['generatedUserId'] = generatedUserId;
            }

            if ('name' in telemetryItem) {
              telemetryItem.properties['origName'] = telemetryItem.name;
              telemetryItem.name = this._enrichNameWithAppName(telemetryItem.name);
            }

            if (Array.isArray(telemetryItem.exceptions)) {
              telemetryItem.exceptions.forEach((ex) => {
                if ('message' in ex) {
                  ex.message = this._enrichNameWithAppName(ex.message);
                }
              })
            }
                 
          }
        });
      };
      if (this.appInsights.queue) {
        this.appInsights.queue.push(registerTelemetryInitializer);
      } else {
        registerTelemetryInitializer();
      }
    }
  }

  setApp(appName) {
    this.appName = appName;
    this.setDefaultContextProperty('app', appName);
  }

  setAuthenticatedUserId(userId, clientId = '') {   
    userId = (userId ==null) ? store.getters['auth/getAuthenticatedUserId']: userId;
    if (userId) {
      this.enabled && this.appInsights.setAuthenticatedUserContext(userId, clientId, true);
    }
  }

  clearAuthenticatedUser() {
    this.enabled && this.appInsights.clearAuthenticatedUserContext();
  }

  setDefaultContextProperty(key, value) {
    this.globalContextProperties[key] = value;
  }

  clearDefaultContextProperty(key) {
    delete this.globalContextProperties[key];
  }

  logPageView(name, url, properties, measurements, duration) {
    this.enabled && this.appInsights.trackPageView(
      {
        name: name,
        uri: url,
        properties: properties,
        duration: duration
      }
    );

    this.enabled && measurements && this.appInsights.trackMetric(measurements);
  }

  logEvent(name, properties, measurements) {
    this.enabled && this.appInsights.trackEvent(
      {
        name: name
      },
      properties
    );

    this.enabled && measurements && this.appInsights.trackMetric(measurements);
  }

  logException(exception, properties, measurements, severityLevel) {
    this.enabled && this.appInsights.trackException(
      {
        exception: exception,
        severityLevel: severityLevel
      },
      properties
    );

    this.enabled && measurements && this.appInsights.trackMetric(measurements);
  }

  _enrichNameWithAppName(name) {
    name = name || '[empty]';
    return this.appName ? `${this.appName}: ${name}` : name;
  }

  logCustomEvent() {
    const clickPluginInstance = new ClickAnalyticsPlugin();
    // Click Analytics configuration

    const clickPluginConfig = {
      autoCapture: true,
      dataTags: {
        useDefaultContentNameOrId: true
      }
    };
    // Application Insights Configuration

    const configObj = {
      instrumentationKey: cbSettings.appInsights.instrumentationKey,
      extensions: [clickPluginInstance],
      extensionConfig: {
        [clickPluginInstance.identifier]: clickPluginConfig
      },
    };

    this.appInsights = new ApplicationInsights({ config: configObj });
    this.appInsights.loadAppInsights();
  }
}

// Export a singleton so we can ensure the same one is used
// for context tracking.
export default new AppInsightsAnalytics();