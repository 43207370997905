import set from 'lodash.set';
import * as PresentationToSimplefieldDesigner from 'forms/data-entry-mappers/element-types/base/presentation-to-simplefield-designer';
import { i18n } from 'common/lang-config';

function canHandlePresentation (presentationKey) {
  return presentationKey === "PhoneInput";
}

function canHandleType (type) {
  return type === "PhoneInput";
}

function mapPresentationToDesigner (element, presentationKey, updateModelFn) {
  const designer = PresentationToSimplefieldDesigner.map(...arguments);

  designer.label = i18n.t('formsBuilder.dataEntry.designerLabels.phoneInput');
  set(designer, 'fields.props.hidePlaceholder', true);

  return designer;
}

export { canHandlePresentation, canHandleType, mapPresentationToDesigner }