export default {
  componentUpdated (el, binding, vnode) {
    const shouldDisable = binding.value;
    const allFormFields = el.querySelectorAll('.savage-form .form-field');

    for (let i=0;i<allFormFields.length;i++) {
      const currentFormField = allFormFields[i];
      const toggleLabelClass = false;      
      
      // if the field hasn't been evaluated yet, and it's disabled, ignore it going forward
      if (!currentFormField.dataset.cbDisableFormDirectiveHasRunOnce) {
        // if container element already has disabled, ignore it when applying changes below
        if (currentFormField.classList.contains('disabled')) {
          currentFormField.dataset.cbDisableFormDirectiveIgnore = true;
        }
        // if any form fields are already disabled, ignore then when applying changes below
        const alreadyDisabledFields = currentFormField.querySelectorAll(':disabled');
        for(let j=0;j<alreadyDisabledFields.length;j++) {
          alreadyDisabledFields[j].dataset.cbDisableFormDirectiveIgnore  = true;
        }
      }
      
      // if appropriate, apply disabled class to the container element
      if (!currentFormField.dataset.cbDisableFormDirectiveIgnore) {
        if (shouldDisable) {
          currentFormField.classList.add('disabled');
        } else {
          currentFormField.classList.remove('disabled');
        }
      }
      
      const currentFormFieldElements = currentFormField.querySelectorAll('input, select, textarea');
      for(let k=0;k<currentFormFieldElements.length;k++) {
        const element = currentFormFieldElements[k];

        if (!element.dataset.cbDisableFormDirectiveIgnore) {
          // find the main label for the "input" element or group of "input" elements (i.e. radio, checkbox)
          const elementLabel = currentFormField.querySelector('.label');
          if (shouldDisable) {
            // apply disabled property to "input" element
            element.setAttribute('disabled', 'disabled');
            // apply disabled class to main label
            if (elementLabel) elementLabel.classList.add('disabled');
          } else {
            // remove disabled property from "input" element
            element.removeAttribute('disabled');
            // remove disabled class to main label
            if (elementLabel) elementLabel.classList.remove('disabled');
          }
        }
      }
      
      currentFormField.dataset.cbDisableFormDirectiveHasRunOnce = true;
    }
  }
};