<template>
  <section class="apply-global-styles">
    <header v-if="show" id="hdrMaster" class="cb-header margin-med-B">
      <div class="header-logo padding-med-L">
        <img :src="header.logoUrl || ''" height="36" :alt="header.logoAlt">
      </div>
      <cb-app-user-menu
          v-if="header"
          :company-select="companySelect"
          :logout="logout"
          :client-selection="clientSelection"
          :header="header">
      </cb-app-user-menu>
    </header>
  </section>

</template>

<script>
import {headerService} from 'header/header.module'
import {headerServiceEventBus, HeaderServiceEvents} from 'header/header.service'
import CbLocaleStore from "../cb-locale-store";
import AppSettings from "appSettings";
import CbAppUserMenu from 'header/cb-user-menu.vue';
import {$ngVue} from 'auth.module'

export default {
  name: "click-header",
  components: {
    CbAppUserMenu
  },
  data() {
    return {
      AppSettings,
      headerService,
      show: false,
      header: null,
      clientSelection: null
    }
  },
  watch: {
    AppSettings: {
      async handler(newValue, oldValue) {
        const headerData = await this.headerService.getHeaderContent()
        this.setDataFromHeader(headerData)
      },
      deep: true
    }
  },
  methods: {
    logout () {
      this.headerService.logout()
    },
    async companySelect() {
      return await this.headerService.reloadHeaderUrl(this.header.clientSelection.userHeaderUrl)
    },
    setDataFromHeader(headerData) {
      this.header = headerData.data || headerData;

      this.clientSelection = this.header.clientSelection;

      if (this.header && this.header.dateLocale) CbLocaleStore.setDateLocale(this.header.dateLocale);
      if (this.header && this.header.locale) {
        // ngVue maintains a list of root properties that are injected into each
        // new vue-component created by ngVue. Get and set the i18n.locale property
        // to update the locale when the header service returns the data
        const props = $ngVue.getRootProps();
        props['i18n'].locale = this.header.locale;
      }

      this.show = this.headerService.shouldShowHeader();
    }
  },
  async beforeMount() {
    // Candidate Portal:
    //
    // in the Candidate Portal, the property is "headerData". So check if it exists to
    // see if we are in the Candidate Portal
    if (this.headerService.headerData) {
      const headerData = await this.headerService.getHeaderContent()
      this.setDataFromHeader(headerData)
    }

    // Admin Portal:
    //
    // in the Admin Portal, the property is "headerContent". So check if it exists to
    // see if we are in the Admin Portal
    if (this.headerService.headerContent) {
      const headerData = await this.headerService.getHeaderContent()
      this.setDataFromHeader(headerData)
    }

    if (headerServiceEventBus) {
      headerServiceEventBus.on(HeaderServiceEvents.HeaderChanged, ({header}) => {
        this.header = header;
        this.clientSelection = this.header.clientSelection;
      });
    }
  }
}
</script>

<style scoped>

</style>
