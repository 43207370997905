<template>
  <section class="companies-add-route vertical-flex">
    <cb-view-header class="vertical-flex-min" :title="headerTitle" :return-link="returnLink"></cb-view-header>
    <savage-form class="vertical-flex-fill vertical-flex company-add-form" name="company-add" v-disable-form="isReadOnly" :formstate="formstate" :on-submit="save">
      <cb-view-section v-if="fields" class="vertical-flex-min company-add-primary" type="secondary" :includePrimaryMessages="true">
        <savage-form-text-input
          :name="'fields.name.label'"
          :label="fields.name.label"
          :placeholder="fields.name.placeholder"
          :validation="fields.name.validation"
          v-model="localModel.name" />
        <savage-form-text-input
          :name="'fields.referenceName.label'"
          :label="fields.referenceName.label"
          :placeholder="fields.referenceName.placeholder"
          :validation="fields.referenceName.validation"
          v-model="localModel.referenceName" />
      </cb-view-section>
      <cb-view-section v-if="fields" class="vertical-flex-fill company-add-settings" type="primary">
        <cb-view-section class="company-add-sub-section company-add-account-settings company-add-settings-sub-section" type="secondary">
          <div class="company-add-section-header">{{fields.accountSettings.title}}</div>
          <div class="company-add-section-content">
            <companies-subdomain
              :label="fields.accountSettings.userSubdomain.label"
              name="fields.accountSettings.userSubdomain.label"
              :domainSuffix="fields.accountSettings.userDomainSuffix"
              :validation="fields.accountSettings.userSubdomain.validation"
              :customValidation="userSubdomainValidation"
              :placeholder="fields.accountSettings.userSubdomain.placeholder"
              :disabled="fields.isEdit"
              v-model="localModel.settings.subdomain"
              class="company-add-account-settings"
              ></companies-subdomain>
            <savage-form-labeled-field v-if="fields.isEdit"
              :label="fields.accountSettings.apiIdentifier.label"
              :disabled="true">
              <div class="api-key-container">
                <input type="text" :title="fields.accountSettings.apiIdentifier.label" disabled="disabled" :value="fields.accountSettings.apiIdentifier.value" class="input-text api-key">
                <button type="button"
                v-clipboard:copy="fields.accountSettings.apiIdentifier.value"
                v-clipboard:success="onCopy"
                class="cb-btn primary"
                :class="{stuckDown: identifierCopied}">{{identifierCopied ? fields.accountSettings.copy.copied : fields.accountSettings.copy.copy}}</button>
              </div>
            </savage-form-labeled-field>

          </div>
        
        </cb-view-section>

        <cb-view-section class="company-add-sub-section company-add-settings" type="secondary">
          <div class="company-add-section-header">Automatic Data Cleanup</div>
          <div class="container-fluid company-add-section-content">
            <div class="row">
              <td>
                <label>Data cleanup type:</label>
              </td>
            </div>
            <div class="row">
              <td>
                <select type="text" v-model="dataCleanupType">
                  <option value="none">None</option>
                  <option value="prune">Prune</option>
                  <option value="anonymize">Anonymize</option>
                </select>
              </td>
            </div>
            <div class="row mt-3">
              <td>
                <label>Number of days of inactivity before cleanup:</label>
              </td>
            </div>
            <div class="row">
              <td>
                <input type="text" v-model="daysDataCleanup" :disabled="dataCleanupType=='none'" />
              </td>
            </div>
          </div>
         </cb-view-section>

        <cb-view-section class="company-add-sub-section company-add-product-settings" type="secondary">
          <div class="company-add-section-header">{{$t('addCompany.productsTitle')}}</div>
          <div class="container-fluid company-add-section-content company-product-settings-content">
            <div class="row">
              <div class="col-6" :class="{'left-field': index % 2 === 0, 'right-field': index % 2 === 1}" v-for="(product, index) in products" :key="product.referenceIdentifier">
                <div>
                  <label class="cb-checkbox-container">
                    <input type="checkbox" v-model="product.enabled" :disabled="product.referenceIdentifier === 'onboarding'" :aria-describedby="product.id">
                    <span class="cb-checkbox" :class="{'cb-checkbox-checked': product.enabled, 'cb-checkbox-unchecked': !product.enabled }"></span>
                    {{$t('addCompany.products.' + product.referenceIdentifier + '.title')}}
                  </label>
                  <ul class="product-includes" :id="product.id">
                    <li v-for="(include, index) in $t('addCompany.products.' + product.referenceIdentifier + '.includes')" :key="index">{{include}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </cb-view-section>
        <cb-view-section class="company-add-sub-section company-add-email-settings" type="secondary">
          <div class="company-add-section-header">{{fields.emailSettings.title}}</div>
          <div class="container-fluid company-add-section-content company-email-settings-content">
            <div class="row">
              <savage-form-text-input
                class="col-6 left-field"
                :name="'fields.emailSettings.candidateFrom.label'"
                :label="fields.emailSettings.candidateFrom.label"
                :placeholder="fields.emailSettings.candidateFrom.placeholder"
                :validation="fields.emailSettings.candidateFrom.validation"
                :customValidation="emailSettingsCandidateEmailValidation"
                v-model="localModel.settings.email.candidate.fromAddress" />
              <savage-form-text-input
                class="col-6 right-field"
                :name="'fields.emailSettings.userFrom.label'"
                :label="fields.emailSettings.userFrom.label"
                :placeholder="fields.emailSettings.userFrom.placeholder"
                :validation="fields.emailSettings.userFrom.validation"
                :customValidation="emailSettingsUserEmailValidation"
                v-model="localModel.settings.email.user.fromAddress" />
              <savage-form-text-input
                class="col-6 left-field"
                :name="'fields.emailSettings.candidateDisplay.label'"
                :label="fields.emailSettings.candidateDisplay.label"
                :placeholder="fields.emailSettings.candidateDisplay.placeholder"
                :validation="fields.emailSettings.candidateDisplay.validation"
                v-model="localModel.settings.email.candidate.fromDisplayName" />
              <savage-form-text-input
                class="col-6 right-field"
                :name="'fields.emailSettings.userDisplay.label'"
                :label="fields.emailSettings.userDisplay.label"
                :placeholder="fields.emailSettings.userDisplay.placeholder"
                :validation="fields.emailSettings.userDisplay.validation"
                v-model="localModel.settings.email.user.fromDisplayName" />
            </div>
          </div>
        </cb-view-section>
        <cb-view-section id="company-add-support-section" class="container-fluid">
          <div class="row">
            <div class="col-6 company-add-support-panel">
              <cb-view-section class="company-add-sub-section company-add-support-sub-section" type="secondary">
                <div class="company-add-section-header">{{fields.candidateSupport.title}}</div>
                <div class="company-add-section-content">
                  <savage-form-text-input
                    :name="'fields.candidateSupport.hours.label'"
                    :label="fields.candidateSupport.hours.label"
                    :placeholder="fields.candidateSupport.hours.placeholder"
                    :validation="fields.candidateSupport.hours.validation"
                    v-model="localModel.settings.support.candidate.hours" />
                  <savage-form-text-input
                    :name="'fields.candidateSupport.email.label'"
                    :label="fields.candidateSupport.email.label"
                    :placeholder="fields.candidateSupport.email.placeholder"
                    :validation="fields.candidateSupport.email.validation"
                    :customValidation="candidateSupportEmailValidation"
                    v-model="localModel.settings.support.candidate.email" />
                  <savage-form-text-input
                    :name="'fields.candidateSupport.phone.label'"
                    :label="fields.candidateSupport.phone.label"
                    :placeholder="fields.candidateSupport.phone.placeholder"
                    :validation="fields.candidateSupport.phone.validation"
                    v-model="localModel.settings.support.candidate.phone" />
                </div>
              </cb-view-section>
            </div>
            <div class="col-6 company-add-support-panel">
              <cb-view-section id="company-add-user-support-section" class="company-add-sub-section company-add-support-sub-section" type="secondary">
                <div class="company-add-section-header">{{fields.userSupport.title}}</div>
                <div class="company-add-section-content">
                  <savage-form-text-input
                    :name="'fields.userSupport.hours.label'"
                    :label="fields.userSupport.hours.label"
                    :placeholder="fields.userSupport.hours.placeholder"
                    :validation="fields.userSupport.hours.validation"
                    v-model="localModel.settings.support.user.hours" />
                  <savage-form-text-input
                    :name="'fields.userSupport.email.label'"
                    :label="fields.userSupport.email.label"
                    :placeholder="fields.userSupport.email.placeholder"
                    :validation="fields.userSupport.email.validation"
                    :customValidation="userSupportEmailValidation"
                    v-model="localModel.settings.support.user.email" />
                  <savage-form-text-input
                    :name="'fields.userSupport.phone.label'"
                    :label="fields.userSupport.phone.label"
                    :placeholder="fields.userSupport.phone.placeholder"
                    :validation="fields.userSupport.phone.validation"
                    v-model="localModel.settings.support.user.phone" />
                </div>
              </cb-view-section>
            </div>
          </div>
        </cb-view-section>
        <div class="d-flex mt-4 company-add-formButtons">
          <button type="button" @click="cancel()" class="cb-btn back primary flex-fill">{{cancelAction.label}}</button>
          <button type="submit" class="cb-btn next primary flex-fill">{{submitAction.label}}</button>
        </div>
      </cb-view-section>
    </savage-form>
    <cb-exit-confirmation ref="exitConfirmation">
    </cb-exit-confirmation>
  </section>
</template>

<script>
import _ from 'lodash';
import AppSettings from 'appSettings';
import CbViewHeader from 'general/cb-view-header.vue';
import CbViewSection from 'general/cb-view-section.vue';
import DisableForm from 'common/directives/disable-form';
import { SavageForm, SavageFormLabeledField, SavageFormTextInput } from '@clickboarding/savage';
import CbExitConfirmation from 'common/components/cb-exit-confirmation.vue';
import CompaniesSubdomain from 'companies/companies-subdomain.vue';
import VueClipboard from 'vue-clipboard2';
import Vue from 'vue';
import uuid from 'uuid/v4';
import emailValidation from 'general/email-validation.js';
Vue.use(VueClipboard);


export default {
    name: 'companies-add-route',
    components: {
        CbViewHeader,
        CbViewSection,
        SavageForm,
        SavageFormTextInput,
        CbExitConfirmation,
        CompaniesSubdomain,
        SavageFormLabeledField
    },
    directives: {
        DisableForm
    },
    props: {
      dataUrl: {
        type: String,
        required: true
      }
    },
    mounted () {
      return this.$http.get(this.dataUrl).then((response) => {      
        this.emailValidationMessage = response.body.emailValidation.message;
        this.subdomainValidationMessage = response.body.subdomainValidation.message;
        this.headerTitle = response.body.title;
        this.returnLink = {
          label: response.body.return.label,
          handler: this.return
        };
        this.fields = response.body.formLabels;
        this.returnAction = response.body.return;
        this.cancelAction = response.body.cancel;
        this.submitAction = response.body.submit;
        function removeEmptyObjects(obj) {
          return _(obj)
            .pickBy(_.isObject)
            .mapValues(removeEmptyObjects)
            .omitBy(_.isEmpty)
            .assign(_.omitBy(obj, (n) => { return _.isObject(n) || _.isEmpty(n) } ))
            .value();
        };
        if (response.body.isEditMode) {
          this.fields.isEdit = true;
          this.dataCleanupTypeInitial = response.body.company.settings.dataCleanupSettings.dataCleanupType;
          this.dataCleanupType = response.body.company.settings.dataCleanupSettings.dataCleanupType;
          this.daysDataCleanup = response.body.company.settings.dataCleanupSettings.days;
          this.daysDataCleanupInitial = response.body.company.settings.dataCleanupSettings.days;
          _.merge(this.localModel, removeEmptyObjects(response.body.company));
          this.products.forEach(function(product) {
            product.enabled = _.some(response.body.company.products, { referenceIdentifier: product.referenceIdentifier });
          });
        }
      });
    },
    methods: {
      onCopy () {
        this.identifierCopied = true;
      },
      save (){
        function convertEmptyToNull (v) {
          return _.isObject(v)
            ? _.mapValues(v, v => convertEmptyToNull(v))
            : v == "" ? null : v;
        };

        let mappedModel = _(this.localModel).mapValues(convertEmptyToNull).value();
        mappedModel.products = this.products
          .filter(product => product.enabled)
          .map(product => { return { referenceIdentifier: product.referenceIdentifier } });

        var continueSave = true;
        var settingsExist = (this.daysDataCleanupInitial != null);
        var settingsChanged = (this.dataCleanupTypeInitial != this.dataCleanupType)   ||
                              (this.daysDataCleanupInitial != this.daysDataCleanup)
        if(!settingsExist && this.dataCleanupType=='none') settingsChanged = false;

        if(this.dataCleanupType=='none') {
          this.daysDataCleanup = 0;
        }
        else {
          if(isNaN(this.daysDataCleanup) || this.daysDataCleanup == null ) {
            alert("Number of days of inactivity before cleaning must be a valid number between 1 and 5000.");
            continueSave = false;
          }
        } 

        if(continueSave && this.dataCleanupType!=='none') {
          if(this.daysDataCleanup < 1 || this.daysDataCleanup > 5000 ) {
            alert("Number of days of inactivity before cleanup must be between 1 and 5000.");
            continueSave = false;
          }
        }
        if(continueSave) {
          if(settingsChanged) {
            const response = confirm("Automatic data cleanup settings have changed, are you sure you want to save?");
            if(!response) {
              continueSave = false;
            }
          }
        }

        if(continueSave) {
          mappedModel.settings.daysDataCleanup = this.daysDataCleanup;
          mappedModel.settings.dataCleanupType = this.dataCleanupType;
          mappedModel.settings.dataCleanupChanged = settingsChanged;

          if (this.submitAction.method === "POST") {
            this.$http.post(this.submitAction.url, mappedModel).then((response) => {
            this.$_cb.router.changeView(AppSettings.viewCompanies, this.returnAction.url);
          }).catch((error) => {
            // we don't have any special error handling, putting this in to avoid the "unhandled exception" console log
          });
          } else {
            this.$http.patch(this.submitAction.url, mappedModel).then((response) => {
              this.$_cb.router.changeView(AppSettings.viewCompanies, this.returnAction.url);
            }).catch((error) => {
              // we don't have any special error handling, putting this in to avoid the "unhandled exception" console log
            });
          }
        }
      },
      cancel(){
        this.confirmExit(() => { this.$_cb.router.changeView(AppSettings.viewCompanies, this.cancelAction.url); });
      },
      return() {
        this.confirmExit(() => { this.$_cb.router.changeView(AppSettings.viewCompanies, this.returnAction.url); });
      },
      async confirmExit (action) {
        if (this.formstate.$dirty) {
          var confirmed = await this.$refs.exitConfirmation.open();
          if (confirmed) {
            action();
          }
        } else {
          action();
        }
      },
      validEmailObject(value) {
        return emailValidation(value, this.emailValidationMessage)
      },
      validSubdomainObject(value) {
        let re = /^[A-Za-z0-9](?:[A-Za-z0-9\\-]{0,61}[A-Za-z0-9])?$/;
        return {
          'valid-subdomain': {
            value: re.test(value),
            message: this.subdomainValidationMessage
          }
        }
      }
    },
    computed: {
      emailSettingsCandidateEmailValidation() {
        return this.validEmailObject(this.localModel.settings.email.candidate.fromAddress);
      },
      emailSettingsUserEmailValidation() {
        return this.validEmailObject(this.localModel.settings.email.user.fromAddress);
      },
      candidateSupportEmailValidation() {
        return this.validEmailObject(this.localModel.settings.support.candidate.email);
      },
      userSupportEmailValidation() {
        return this.validEmailObject(this.localModel.settings.support.user.email);
      },
      userSubdomainValidation() {
        return this.validSubdomainObject(this.localModel.settings.subdomain);
      }
    },
    data () {
      return {
        identifierCopied: false,
        headerTitle: null,
        returnLink: null,
        fields: null,
        dataCleanupType: null,
        daysDataCleanup: null,
        dataCleanupChanged: null,
        products: [
          {
            id: uuid(),
            referenceIdentifier: 'onboarding',
            enabled: true
          },
          {
            id: uuid(),
            referenceIdentifier: 'offboarding',
            enabled: false
          },
          {
            id: uuid(),
            referenceIdentifier: 'employeeLifecycleEvents',
            enabled: false
          }
        ],
        localModel: {
          "id": null,
          "name": null,
          "referenceName": null,
          "settings": {
              "subdomain": null,
              "dataCleanupType": null,
              "daysDataCleanup": null,
              "dataCleanupChanged": null,
              "email": {
                "candidate": {
                  "fromAddress": null,
                  "fromDisplayName": null
                },
                "user": {
                  "fromAddress": null,
                  "fromDisplayName": null
                }
              },
              "support": {
                "candidate": {
                  "hours": null,
                  "email": null,
                  "phone": null
                },
                "user": {
                  "hours": null,
                  "email": null,
                  "phone": null
                }
              }
            }
          },
        isReadOnly: false,
        formstate: {},
        cancelAction: null,
        submitAction: null
      };
    }
}
</script>
<style scoped lang="scss">
    @import '@clickboarding/style/mixins';
    @import '@clickboarding/style/colors';
    @import 'common/css/components';
    @import 'css/cb-checkbox';
    @import 'common/css/buttons-mixins';

    .companies-add-route {
      @include font-size-reset;
      @include box-sizing-reset;
    }

    .cb-view-section .company-add-sub-section {
      padding: 0;
    }

    #company-add-support-section {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      border: 0;
    }

    .left-field {
      padding-right: .75rem;
    }

    .right-field {
      padding-left: .75rem;
    }
    #company-add-user-support-section {
      margin-top: 0;
    }

    #company-add-support-section .company-add-support-sub-section {
      padding-bottom: 1rem;
    }

    .company-add-sub-section.company-add-settings-sub-section {
      padding-bottom: 1rem;
    }

    #company-add-support-section .company-add-support-panel:not(:first-child) {
      padding-left: .75rem;
    }

    #company-add-support-section .company-add-support-panel:not(:last-child) {
      padding-right: .75rem;
    }

    .company-add-section-header {
      @include font-size-content-root;
      text-align: center;
      font-weight: bold;
      padding: 1rem;
      border-bottom: 1px solid $cb-light-grey-1;
      margin-bottom: 1rem;
    }

    .company-add-section-content {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: .5rem;
    }

    .api-key-container {
      display:flex;
    }

    .api-key-container > input.input-text.api-key {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .cb-btn.primary { 
      @include cbPrimaryButtons;
    }

    .api-key-container > .cb-btn.primary {
      border-right: 1px solid $cb-dark-grey-4;
      border-top: 1px solid $cb-dark-grey-4;
      border-bottom: 1px solid $cb-dark-grey-4;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      line-height: normal; // override bootstrap to ensure button is same height as input field
    }

    .cb-btn.primary.stuckDown {
      @extend .cb-btn.primary:hover:enabled;
    }

    .product-includes {
      @include font-size-content-root;
      line-height: calc(150%);
      padding-left: 3.5rem;

      li {
        list-style: disc;
      }
    }
</style>
