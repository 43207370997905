import * as ContentActionsProvider from "forms/element-list-item-providers/actions/content";
import * as ContentIconProvider from "forms/element-list-item-providers/icons/content";
import has from 'lodash.has';

function canHandle (element) {
  return element.type === "Html";
}

function map (element, index, editElementHandler, deleteElementHandler) {
  const strippedHtmlValue = bleachHtmlString(element.value);

  return {
    label: has(element, 'designer.label') 
      ? `${element.designer.label}: ${strippedHtmlValue}` 
      : strippedHtmlValue,
    actions: ContentActionsProvider.get(element, index, editElementHandler, deleteElementHandler),
    icon: ContentIconProvider.get(element)
  };
}

function bleachHtmlString (value) {
  let tempElement = document.createElement('div');
  tempElement.setAttribute('id', 'temporaryHtmlElement');
  tempElement.innerHTML = value;
  // this replace method is replacing all html tags, or nbsp's with a space
  // using a space instead of nbsp to avoid multiple spaces when there are 2 or more tags next to eachother
  let bleachedValue = tempElement.innerHTML.replace(/(<\/.*?>)/ig,' ');
  bleachedValue = bleachedValue.replace(/((<([^>]+)>))/ig,'');
  bleachedValue = bleachedValue.replace(/(&nbsp;)/ig,' ');
  if (tempElement.parentNode) tempElement.parentNode.removeChild(tempElement);
  return bleachedValue.trim();
}

export { canHandle, map }