export default function cbCardCommon (appSettings) {
	return {
		restrict: "E",
		replace: true,
		templateUrl: function(elem, attrs) {
			return appSettings.baseUrl + (attrs.template);
			// return appSettings.baseUrl + (attrs.cbConfig.template || '/app/partial-views/default-card.html');
		},
		scope: {
			data: "=",
			actions: "=cbActions",
			config: "=cbConfig"
		},
		link: function(scope, element, attrs) {

			var listenData = scope.$watch('data', function(newVal, oldVal) {
				if(newVal === oldVal) return;
				// re-apply scope, it is dependant on data binding resolving
				scope.data = newVal;
				listenData();
			});
			// abstraction of functions passed in
			scope.primaryAction = scope.actions.primary;
			scope.secondaryAction = scope.actions.secondary;
			scope.revokeAction = scope.actions.revoke;
			scope.numberOnly = /^[1-9][0-9]*$/;
		}
	};
};

cbCardCommon.$inject = ['appSettings'];
