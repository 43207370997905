<template>
    <div class="action-center-task" v-if="apiData" v-reset-tab tabindex="-1">
        <action-center-task-information class="action-center-task-information" :content="apiData" />
        <action-center-task-complete class="action-center-task-complete" v-if="finalTodo" v-on:canceled="handleFinalTodoCancel" v-on:confirmed="handleFinalTodoComplete" v-reset-tab tabindex="-1"/>
        <action-center-task-todo-list v-if="!finalTodo" :todos="apiData.todos" v-on:task-changed="handleTaskChanged" v-reset-tab tabindex="-1"/>
    </div>
</template>
<script>
import ActionCenterTaskInformation from "action-center/task/action-center-task-information.vue";
import ActionCenterTaskTodoList from "action-center/task/action-center-task-todo-list.vue";
import ActionCenterTaskComplete from "action-center/task/action-center-task-complete.vue";

export default {
    name: "action-center-task",
    components: {
        ActionCenterTaskInformation,
        ActionCenterTaskTodoList,
        ActionCenterTaskComplete
    },
    directives: {
        resetTab: {
            inserted: function(el) {
                el.focus();
                el.blur();
            }
        }
    },
    props: {
        instanceRequest: {
            type: Object,
            required: true,
            validator: function(value) {
                return "method" in value && "url" in value;
            }
        }
    },
    watch: {
        instanceRequest: {
            immediate: true,
            deep: true,
            handler: async function(newVal, oldVal) {
                await this.refreshApiData();
            }
        }
    },
    data() {
        return {
            apiData: null,
            finalTodo: null
        };
    },
    methods: {
        refreshApiData: async function() {
            const response = await this.$http(this.instanceRequest);
            
            if(response) {
                this.apiData = response.body;
                this.$emit('action-item-details-loaded');
            }
        },
        handleTaskChanged: async function(taskChange) {
            if(taskChange.isFinalTodoCompletion) {
                this.finalTodo = taskChange.todoStatusChange.todo;
            }
            else {
                await this.setTodoStatus(taskChange.todoStatusChange.todo, taskChange.todoStatusChange.newStatus);
            }
        },
        handleFinalTodoCancel: function() {
            this.finalTodo = null;
        },
        handleFinalTodoComplete: async function() {
            const taskDetails = await this.setTodoStatus(this.finalTodo, true, true);
            //refresh so that todos are marked as readonly.
            await this.refreshApiData();
            this.finalTodo = null;
            
            this.$emit('action-item-status-changed', this.instanceRequest, taskDetails.body);
        },
        setTodoStatus: async function(todo, todoCompleted, taskCompleted) {
            let taskDetails = null;
            if(todo.url && todo.method) {

                todo.completed = todoCompleted;
                const body = {
                    todo: {
                        completed: todoCompleted
                    }
                };

                if(taskCompleted) {
                    body.task = {
                        completed: taskCompleted
                    };
                }

                try {
                    taskDetails = await this.$http({
                        method: todo.method,
                        url: todo.url,
                        body: body
                    });
                }
                catch(error) {
                    await this.refreshApiData();
                    this.$emit('action-item-update-error');
                }
            }
            return taskDetails;
        },
    }
};
</script>
<style scoped lang="scss">

.action-center-task {
    display: flex;
    flex-wrap: wrap;

    //remove the existing padding in the details row to
    //retain spacing when items are stacked vertically.
    margin-right: -1.5rem;
    margin-bottom: -1.5rem;

    .action-center-task-information, .action-center-task-todo-list, .action-center-task-complete {
        flex: 1 1;
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
    }
}
</style>