import * as TextInputElementMapper from 'forms/data-entry-mappers/element-types/textinput';
import * as TextAreaElementMapper from 'forms/data-entry-mappers/element-types/textarea';
import * as SelectElementMapper from 'forms/data-entry-mappers/element-types/select';
import * as DateInputElementMapper from 'forms/data-entry-mappers/element-types/dateinput';
import * as GroupElementMapper from 'forms/data-entry-mappers/element-types/group';
import * as GroupArrayElementMapper from 'forms/data-entry-mappers/element-types/grouparray';
import * as PhoneInputElementMapper from 'forms/data-entry-mappers/element-types/phoneinput';

/*

These mappers configure how data entry elements are added/updated/configured
when building a form. 

Expected implementation of a mapper is as follows:

// REQUIRED FUNCTIONS

        function canHandleType(type) {
          // will be passed element.type when working with model elements. This is typically used 
          // over the presentationKey unless in a context where it is not available.

          // Returns Boolean
        }

        function canHandlePresentation(presentationKey) {
          // will be passed the presentationKey when working with presentation elements. This is used
          // when the model.type is unknown and only the presentation type is available. 

          // Returns Boolean
        }

        function mapPresentationToDesigner(element, presentationKey, updateModelFn) {
          // Assembles the components/props/events needed to configure the element.

          // Returns
          {
            // Label for the presentation selector dropdown
            label: 'Single line of text' 
            key: presentationKey,
            // The container component to be wrap around the configuration fields for the element
            container: {
              component: FormsDataEntryGroupContainer,
              props: {
                title: get(element, 'label', null),
              },
            },
            // The fields designer component to configure the element. Should be passed
            // individual props and update events for all properties that can be configured
            // within the designer component
            fields: {
              component: FormsDataEntryElementGroup,
              props: {
                title: get(model, 'title', null),
              },
              events: {
                'update:title': (v) => updateModelFn('title', v),
              },
            },
            // OPTIONAL (only if the element suppports children - i.e. Group)
            // The child elements component. Typically this will just be 
            // a recursive instance of FormsDataEntryElements as shown below
            children: {
              component: FormsDataEntryElements,
              props: {
                isGrouped: true,
                elements: get(model, 'elements', null),
              },
              events: {
                'update:elements': (v) => updateModelFn('elements', v),
              },
            }
          }
        }

// OPTIONAL FUNCTIONS

        function getChildElementsKey() {
          // parameterless function that returns the property key where child elements
          // are stored (i.e. "elements"). This function only needs to be defined when
          // the element type supports child elements

          // Returns String
        }

*/

const allElementTypes = [
  TextInputElementMapper,
  TextAreaElementMapper,
  SelectElementMapper,
  DateInputElementMapper,
  GroupElementMapper,
  GroupArrayElementMapper,
  PhoneInputElementMapper,
];

function getByType (type) {
  let match = null;

  for(let elementType of allElementTypes) {
    if (elementType.canHandleType(type)) {
      match = elementType;
      break;
    }
  }

  return match;
}


function getByPresentation (presentationKey) {
  let match = null;

  for(let elementType of allElementTypes) {
    if (elementType.canHandlePresentation(presentationKey)) {
      match = elementType;
      break;
    }
  }

  return match;
}

export { getByType, getByPresentation }