<template>
  <cb-view-section type="secondary" :withFlushTop="true" v-if="this.clientUsers.length">
    <savage-form-select v-if="!form.isAssignmentLocked"
      :label="$t('processBuilder.activities.add.labels.formAssignedTo')"
      :items="userTypes"
      v-model="assignedTo"
      />

    <template v-if="showUsersDropdown">
      <savage-form-select
        :label="$t('processBuilder.activities.notification.roleTypeEnumMap.TeamMember')"
        :validation="buildValidation()"
        :customValidation="teamMemberValidation"
        :items="getSelectableUserOptions(clientUsers, form.formAssignment.userId)"
        :emptyItemLabel="$t('processBuilder.activities.add.labels.teamMemberSelectPrompt')"
        v-model="assignedUserId"
        />
      
      <b-form-group
        :label-for="grantsAccessInputId"
        :description="$t('processBuilder.activities.form.grantsAccessCheckDescription')">
        <!-- size sm so it matches the savage inputs -->
        <b-form-checkbox
          size="sm"
          :id="grantsAccessInputId"
          v-model="grantsAccess"
          switch>
          {{$t('processBuilder.activities.form.grantsAccessCheckLabel')}}
        </b-form-checkbox>
      </b-form-group>
    </template>
    
    <template v-if="!form.isSkipLogicLocked">
      <hr v-if="!form.isAssignmentLocked" />
      <savage-form-text-area 
        v-model="form.configuration.processConditions.skip.condition"
        :label="$t('processBuilder.activities.form.processConditionSkipLabel')"
        :placeholder="$t('processBuilder.activities.form.processConditionSkipPlaceholder')" />
    </template>
  </cb-view-section>
</template>

<script>
  import { SavageFormSelect, SavageFormTextArea, SavageFormMixin } from '@clickboarding/savage';
  import CbViewSection from 'general/cb-view-section.vue';
  import { mapActions, mapGetters } from 'vuex';
  import uuid from 'uuid/v4';
  
  export default {
    name: 'form-configure',
    components: {
      CbViewSection,
      SavageFormSelect,
      SavageFormTextArea
    },
    created() {
      this.grantsAccessInputId = `grants-access-${uuid()}`;

      // handles the case of loading from the list
      // if the form object comes from the form edit link
      // all of this is filled in already
      if (this.form.formGuid == null) {
        this.form.formGuid = this.form.value;
      }

      if (!this.form.configuration) {
        this.$set(this.form, 'configuration', {
          processConditions: {
            skip: {
              condition: ''
            }
          }
        });
      }

      if (!this.form.formAssignment) {
        this.$set(this.form, 'formAssignment', {
          typeEnum: 'None'
        });
      } else {
        if (this.form.formAssignment.typeEnum === 'User') {
          // Forms are always UserWithAccessGrant, but have already been saved
          // the old way, the backend doesn't know that but if the type isn't
          // set right on save the object won't deserialize correctly
          this.form.formAssignment.typeEnum = 'UserWithAccessGrant'
        }
      }
    },
    async mounted() {
      await this.getUserListAsync(this.form.userList.url);
    },
    model: {
      prop: 'activity'
    },
    props: {
      activity: {
        type: Object,
        required: true
      }
    },
    watch: {
      form: {
        deep: true,
        handler: function(val, oldVal) {
          this.$emit('input', val);
        }
      }
    },
    computed: {
      ...mapGetters('activities', ['clientUsers']),
      form() {
        return this.activity;
      },
      assignedTo: {
        get: function() {
          let formAssignmentObj;
          formAssignmentObj = JSON.parse(JSON.stringify(this.form.formAssignment));
          if (formAssignmentObj.typeEnum === 'UserWithAccessGrant') {
              formAssignmentObj.userId = null;
              formAssignmentObj.grantsAccess = false;
              delete formAssignmentObj.userName;
            }
          return formAssignmentObj;
        },
        set: function(value) {
          if(value !== null) {
            this.form.formAssignment = JSON.parse(JSON.stringify(value));
          }
        }
      },
      showUsersDropdown() {
        return this.assignedTo && this.assignedTo.typeEnum === 'UserWithAccessGrant';
      },
      assignedUserId: {
        get: function() {
          const formAssignmentObj = JSON.parse(JSON.stringify(this.form.formAssignment));
          return formAssignmentObj.userId;
        },
        set: function(value) {
          if(value !== null) {
            this.form.formAssignment.userId = JSON.parse(JSON.stringify(value));
          }
          // If there are options in the dropdown but nothing is selected,
          // set userId to null to trigger the validations on the Savage dropdown.
          else if(value === null && this.clientUsers.length > 0) {
            this.form.formAssignment.userId = null;
          }
        }
      },
      grantsAccess: {
        get: function() {
          return this.form.formAssignment.grantsAccess === true;
        },
        set: function(value) {
          this.form.formAssignment.grantsAccess = value === true;
        }
      },
      teamMemberValidation() {
        return {
          'team-member-validation': {
            value: this.selectedTeamMemberIsValid(),
            message: this.$t('processBuilder.activities.form.invalidTeamMember')
          }
        }
      }
    },
    methods: {
      ...mapActions('activities', ['getUserListAsync']),
      buildValidation() {
        return {
          required: {
            value: true,
            message: this.$t('common.validation.required')
          }
        };
      },
      selectedTeamMemberIsValid() {
        // in the case of edit, it's possible the currently selected team member isn't valid anymore
        let selectedMemeberIsValid = true;
        if (this.assignedTo.typeEnum === 'UserWithAccessGrant') {
          selectedMemeberIsValid = this.clientUsers.filter(user => {
              return  user.id === this.form.formAssignment.userId 
                && (user.isActive === false || user.hasCandidateUpdatePermission === false);
            }, this).length === 0;
        }
        return selectedMemeberIsValid;
      },
      getSelectableUserOptions (allClientUsers, selectedId) {
        const selectableUsers = !allClientUsers ? []
          : allClientUsers.filter(user => {
            const isCurrentlySelected = user.id === selectedId;
            return (user.isActive && user.hasCandidateUpdatePermission) || isCurrentlySelected;
          });

        return selectableUsers.map((user) => {
          return {
            label: user.name,
            value: user.id
          }
        });
      }
    },
    data() {
      return {
        userTypes: [
          { label: this.$t('processBuilder.activities.notification.recipientTypeEnumMap.HrTeam'), value: { typeEnum: 'None' } },
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.Coordinator'), value: { typeEnum: 'Role', role: 'Coordinator' } },
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.HiringManager'), value: { typeEnum: 'Role', role: 'HiringManager' }  },
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.Recruiter'), value: { typeEnum: 'Role', role: 'Recruiter' } },
          { label: this.$t('processBuilder.activities.notification.roleTypeEnumMap.TeamMember'), value: { typeEnum: 'UserWithAccessGrant', userId: null, grantsAccess: false } }
        ],
      }
    }
  }
</script>
