<template>
  <section class="vertical-flex-dynamic-content connection-edit">
    <cb-view-header class="vertical-flex-min connections-header" :title="displayTitle" :return-link="returnLink" />
    <cb-vue-component-loader
      v-if="connection"
      :src="connection.componentConfiguration.uri"
      :integrity="connection.componentConfiguration.integrity"
      :name="connection.componentConfiguration.name"
      :$_cb="services"
      class="custom-connection-config">
    </cb-vue-component-loader>
  </section>
</template>
<script>
import CbViewHeader from "general/cb-view-header.vue";
import CbViewSection from "general/cb-view-section.vue";
import CbVueComponentLoader from "general/cb-vue-component-loader.vue";
import ConnectionsService from "api/connectionsService.js";
import AppSettings from "appSettings";
import { mapGetters } from 'vuex';

export default {
  name: "connection-edit",
  components: {
    CbViewHeader,
    CbViewSection,
    CbVueComponentLoader
  },
  props: {
    connectionType: {
      type: String,
      required: true,
    },
    connectionIdentifier: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters(['clientId']),
    connectionTypeTitle() {
      return this.$t(`connections.connectionTypeEnumMap.${this.connectionType}.title`);
    },
    displayTitle() {
      return (this.connection && this.connection.connectionName) || this.connectionTypeTitle;
    },
    returnLink() {
      return {
        label: this.$t('connections.backLabel', [this.connectionTypeTitle]),
        handler: this.returnLinkHandler
      }
    },
    services() {
      return {
        connectionService: {
          get: () => {
            return ConnectionsService.getSettings(this.clientId, this.connectionIdentifier);
          },
          update: (connectionSettings) => {
            return ConnectionsService.updateSettings(this.clientId, this.connectionIdentifier, connectionSettings);
          }
        }
      };
    }
  },
  data() {
    return {
      connection: null
    };
  },
  async created() {
    this.connection = await ConnectionsService.get(this.clientId, this.connectionIdentifier);
  },
  methods: {
    returnLinkHandler() {
      this.$_cb.router.changeView(AppSettings.viewConnections, {
        connectionType: this.connectionType
      }, null);
    }
  }
};
</script>