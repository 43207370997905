import Vue from 'vue';
import appSettings from 'appSettings';

class CbExitConfirmationDataProvider {
  constructor() {
    this.data = null;
    this.getData = this.getData.bind(this);
  }

  async getData () {
    if (this.data === null) {
      const response = await Vue.http.get(appSettings.apiBaseUrl + "SharedResources/ExitConfirm");
      this.data = response.body;
    }

    return this.data;
  }
}

export default new CbExitConfirmationDataProvider();
