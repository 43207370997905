import AppRoutingModule from 'common/app-routing.module';
import ModalModule from "common/modal.module";
import usersList from 'users/users-list';
import userAdd from 'users/user-add.vue';
import appSettingsModule from 'common/app-settings.module';
import 'angular-agility-0.8.23/angular-agility'; // provides "aa.notify" module

export default angular.module('users-module', [AppRoutingModule, appSettingsModule, 'aa.notify', ModalModule])
	.directive('userAdd', userAdd)
	.value('users-list', usersList)
	.run(['$rootScope', 'appSettings', function ($rootScope, appSettings) {
        // Clear filters when goes away from the Candidates
        $rootScope.$on('$routeChangeStart', function ($event, next, current) {
            if (!next.$$route.originalPath.startsWith(appSettings.viewTeamMembers)) {
                sessionStorage[`cb${appSettings.appType}userFilter`] = '';
            }
        });
    }])
	.name;
