<template>
  <b-modal
    v-model="modalShow"
    :title="$t('formsBuilder.formsAndContent.rename.title')"
    :ok-title="$t('common.update')"
    centered
    no-close-on-backdrop
    @ok="handleOk"
    @cancel="$emit('cancel')"
    @close="$emit('close')"
    @hide="handleHide"
  >
    <cb-primary-message />
    <savage-form
      ref="savageform"
      :formstate="formstate"
      name="form-rename"
      :on-submit="saveAndClose"
    >
      <forms-builder-identifierinput
        :label="$t('formsBuilder.formsAndContent.rename.referenceNameLabel')"
        :placeholder="$t('formsBuilder.formsAndContent.rename.referenceNamePlaceholder')"
        v-model="renameBody.currentIdentifier"
        :identifierValidation="renameBody.identifierValidation"
        :validationMessages="validationMessages"
        :disabled="false"
      />
    </savage-form>
  </b-modal>
</template>
<script>
import FormsBuilderIdentifierinput from "forms/forms-builder-identifierinput.vue";
import { SavageForm } from "@clickboarding/savage";
import CbPrimaryMessage from "components/cb-primary-message.vue";

export default {
  name: "forms-rename",
  components: {
    FormsBuilderIdentifierinput,
    SavageForm,
    CbPrimaryMessage
  },
  props: {
    dataUrl: {
      type: String,
      required: true
    }
  },
  watch: {
    dataUrl: {
      immediate: true,
      async handler(newDataUrl, oldDataUrl) {
        if (newDataUrl && newDataUrl !== oldDataUrl) {
          var results = await this.$http.get(newDataUrl);
          this.renameBody = results.body;
          this.modalShow = true;
        }
      }
    }
  },
  methods: {
    handleHide(bvModelEvent) {
      if (bvModelEvent.trigger !== 'ok') {
        // hide happen on esc, but it also happens whenever the window gets
        // closed (e.g., hitting the ok button), ok is handled differently (saveAndClose)
        // don't forward this event in that scenario
        this.$emit('hide');
      }
    },
    async handleOk(bvModelEvent) {
      // according to the docs (https://bootstrap-vue.js.org/docs/components/modal#prevent-closing), async isn't supported
      // so always prevent the default and then handle closing it ourselves if needed
      bvModelEvent.preventDefault();

      await this.saveAndClose();
    },
    async saveAndClose() {
      if (this.$refs.savageform.validate()) {
        await this.$http.put(this.renameBody.renameForm.url, {
          referenceName: this.renameBody.currentIdentifier
        });
        this.$emit("ok");
        this.modalShow = false;
      }
    }
  },
  data() {
    return {
      modalShow: false,
      formstate: {},
      validationMessages: {
        required: this.$t("common.validation.required")
      },
      renameBody: {
        currentIdentifier: null,
        identifierValidation: {},
        renameForm: {}
      }
    };
  }
};
</script>
<style scoped lang="scss">
</style>
