<template>
  <div>
    <div class="form-row">
      <validation-provider immediate name="candidate.processFields.candidateDueDateLabel" slim :rules="candidateDueDateRules" v-slot="{ passed, failed, errors }">
        <b-form-group
          class="col-md-6"
          :label-for="$id('candidateDueDate')"
          :state="passed"
          :invalid-feedback="errors[0]"
        >
          <template v-slot:label>
            {{ $t('candidate.processFields.candidateDueDateLabel') }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-datepicker
            :id="$id('candidateDueDate')"
            :locale="dateLocale"
            v-model="localCandidateDueDate"
            :state="failed ? false : null"
            required
          />
        </b-form-group>
      </validation-provider>
      <validation-provider name="candidate.processFields.dueDateLabel" slim rules="required" v-slot="{ passed, failed, errors }">
        <b-form-group
          class="col-md-6"
          :label-for="$id('dueDate')"
          :state="passed"
          :invalid-feedback="errors[0]"
        >
          <template v-slot:label>
            {{ $t('candidate.processFields.dueDateLabel') }}
            <span aria-hidden class="text-danger">*</span>
          </template>
          <b-form-datepicker
            :id="$id('dueDate')"
            :locale="dateLocale"
            v-model="localDueDate"
            :state="failed ? false : null"
            required
          />
        </b-form-group>
      </validation-provider>
    </div>
    <hr class="mt-0" />
    <b-form-group
      :label="$t('candidate.processFields.recruiterLabel')"
      :label-for="$id('recruiter')"
    >
      <b-form-select :id="$id('recruiter')"
        :options="recruiterUsers"
        :text-field="'name'"
        :value-field="'id'"
        v-model="localRecruiterUserId">
        <template v-slot:first>
          <b-form-select-option :value="null">{{$t('candidate.processFields.teamMemberEmptyOptionLabel')}}</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group
      :label="$t('candidate.processFields.hiringManagerLabel')"
      :label-for="$id('hiringManager')"
    >
      <b-form-select :id="$id('hiringManager')"
        :options="hiringManagerUsers"
        :text-field="'name'"
        :value-field="'id'"
        v-model="localHiringManagerUserId">
        <template v-slot:first>
          <b-form-select-option :value="null">{{$t('candidate.processFields.teamMemberEmptyOptionLabel')}}</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group
      :label="$t('candidate.processFields.coordinatorLabel')"
      :label-for="$id('coordinator')"
    >
      <b-form-select :id="$id('coordinator')"
        :options="coordinatorUsers"
        :text-field="'name'"
        :value-field="'id'"
        v-model="localCoordinatorUserId">
        <template v-slot:first>
          <b-form-select-option :value="null">{{$t('candidate.processFields.teamMemberEmptyOptionLabel')}}</b-form-select-option>
        </template>
      </b-form-select>
    </b-form-group>
  </div>
</template>

<script>
  import isnil from "lodash.isnil";
  import dateParse from "date-fns/parse";
  import CbLocaleStore from "common/components/cb-locale-store";

  export default {
    name: 'candidate-process-details-inputs',
    props: {
      recruiterUsers: {
        type: Array,
        required: false
      },
      hiringManagerUsers: {
        type: Array,
        required: false
      },
      coordinatorUsers: {
        type: Array,
        required: false
      },
      candidateDueDate: {
        type: Date,
        required: false,
        default: null
      },
      dueDate: {
        type: Date,
        required: false,
        default: null
      },
      recruiterUserId: {
        type: String,
        required: false,
        default: null
      },
      hiringManagerUserId: {
        type: String,
        required: false,
        default: null
      },
      coordinatorUserId: {
        type: String,
        required: false,
        default: null
      }
    },
    data () {
      return {
        dateLocale: CbLocaleStore.state.dateLocale,
        candidateDueDateRules: {
          required: true,
          lessThanOrEqual: {
            target: '@candidate.processFields.dueDateLabel',
            overrideMessageKey: 'candidate.processFields.candidateDueDateBeforeErrorFormat'
          }
        }
      };
    },
    computed: {
      localCandidateDueDate: {
        get () {
          return this.getDateAsUTCYearMonthDate(this.candidateDueDate);
        },
        set (v) {
          this.$emit('update:candidateDueDate', this.getDateWithOriginalTime(this.candidateDueDate, v));
        }
      },
      localDueDate: {
        get () {
          return this.getDateAsUTCYearMonthDate(this.dueDate);
        },
        set (v) {
          this.$emit('update:dueDate', this.getDateWithOriginalTime(this.dueDate, v));
        }
      },
      localRecruiterUserId: {
        get () {
          return this.recruiterUserId;
        },
        set (v) {
          this.$emit('update:recruiterUserId', v);
        }
      },
      localHiringManagerUserId: {
        get () {
          return this.hiringManagerUserId;
        },
        set (v) {
          this.$emit('update:hiringManagerUserId', v);
        }
      },
      localCoordinatorUserId: {
        get () {
          return this.coordinatorUserId;
        },
        set (v) {
          this.$emit('update:coordinatorUserId', v);
        }
      }
    },
    methods: {
      getDateWithOriginalTime (originalDate, selectedDateYMD) {
        // Initialize a new date with the original date + time
        const outputDate = new Date(originalDate.getTime());
        const selectedDate = dateParse(selectedDateYMD);

        // The intention is that the user is choosing dates in UTC to be consistent
        // with the rest of the platform. Since originalDate and outputDate are both 
        // local dates, we need to use the selectedDate year/month/day to update the UTC
        // yearh/month/day of outputDate
        outputDate.setUTCFullYear(selectedDate.getFullYear());
        outputDate.setUTCMonth(selectedDate.getMonth());
        outputDate.setUTCDate(selectedDate.getDate());
        return outputDate;
      },
      getDateAsUTCYearMonthDate (dateObj) {
        if (!dateObj)
          return null;

        var year = dateObj.getUTCFullYear();
        var month = '' + (dateObj.getUTCMonth() + 1);
        var date = '' + dateObj.getUTCDate();

        // 0 pad Month & date if necessary
        if (month.length < 2)
            month = '0' + month;
        if (date.length < 2)
            date = '0' + date;

        return [year, month, date].join('-');
      }
    }
  }
</script>