<template>
  <section class="connection-activity-log">
    <cb-view-header class="connections-header" :title="displayTitle" :return-link="returnLink" />
    <template v-if="logs">
      <cb-view-section v-if="!hasLogs" :includePrimaryMessages="true" type="secondary">
        <p class="no-logs">{{$t('connections.activityLog.noLogsLabel')}}</p>
      </cb-view-section>
      <section class="connection-activity-log-container" v-else>
        <b-table hover small outlined sort-icon-left sort-by="logDate" :sort-desc="false" :items="logs" :fields="[
          { key: 'logDate', formatter: logDateFormatter, label: $t('connections.activityLog.columnHeaders.dateLabel'), class: 'date-column', 
            thAttr: { 'aria-sort': 'descending' }, thStyle: { width: '20%' }, sortable: true, sortDirection: 'desc' },
          { key: 'level', label: $t('connections.activityLog.columnHeaders.levelLabel'), thStyle: { width: '15%' }, class: 'hide-sm-down', sortable: true },
          { key: 'message', label: $t('connections.activityLog.columnHeaders.messageLabel'), sortable: true }
        ]" />
        <p  class="result-count">{{$t('connections.activityLog.resultsLabel')}}</p>
      </section>
    </template>
  </section>
</template>
<script>
import CbViewHeader from "general/cb-view-header.vue";
import CbViewSection from "general/cb-view-section.vue";
import ConnectionsService from "api/connectionsService.js";
import moment from 'moment';
import AppSettings from "appSettings";
import { mapGetters } from 'vuex';

export default {
  name: "connection-activity-log",
  components: {
    CbViewHeader,
    CbViewSection
  },
  props: {
    connectionType: {
      type: String,
      required: true,
    },
    connectionIdentifier: {
      type: String,
      required: true,
    }
  },
  async created() {
    const connectionActivityLog = await ConnectionsService.getActivityLog(this.clientId, this.connectionIdentifier);
    this.connectionName = connectionActivityLog.connectionName;
    this.logs = connectionActivityLog.activityLog || [];
  },
  computed: {
    ...mapGetters(['clientId']),
    hasLogs() {
      return this.logs && this.logs.length ? true : false;
    },
    connectionTypeTitle() {
      return this.$t(`connections.connectionTypeEnumMap.${this.connectionType}.title`);
    },
    displayTitle() {
      return this.connectionName || this.connectionTypeTitle;
    },
    returnLink() {
      return {
        label: this.$t('connections.backLabel', [this.connectionTypeTitle]),
        handler: this.returnLinkHandler
      }
    }
  },
  methods: {
    logDateFormatter (value, key, item) {
      return moment
        .utc(item.logDate)
        .format(this.$t("connections.activityLogFormat"));
    },
    returnLinkHandler() {
      this.$_cb.router.changeView(AppSettings.viewConnections, {
        connectionType: this.connectionType
      }, null);
    }
  },
  data() {
    return {
      logs: null,
      connectionName: null
    };
  }
};
</script>
<style scoped lang="scss">
.connection-activity-log-container {
  padding: 1.5rem;
}

.no-logs {
  padding: 3rem;
  text-align: center;
  font-size: 1rem;
}

.result-count {
  padding: .5rem;
  text-align: center;
  font-size: .875rem;
}
</style>